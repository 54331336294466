<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("application.Create New Application") }}
      <div
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12 dtp-chart-box-20" style="display: grid">
          <span class="dtp-mb--1 dtp-display-block"
            >{{ $t("application.Please create an application name in order to connect and deploy models within following rules") }} </span
          ><span class="dtp-mb--1 dtp-display-block"
            ><span style="font-weight: bold">1-</span> {{ $t("application.20 characters") }}</span
          ><span class="dtp-mb--1 dtp-display-block"
            ><span style="font-weight: bold">2-</span> {{ $t("application.Only use letters and numbers") }}</span
          >
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('application.Application Name')"
                class="eds-input_#input"
                maxlength="99"
                v-model="newApplicationName"
              />
            </div>
            <div
              v-if="!$v.newApplicationName.maxLength"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("validation.x can not be longer than y characters", [$t("application.Application Name"), $v.newApplicationName.$params.maxLength.max + 1]) }}
            </div>
            <div
              v-if="
                !$v.newApplicationName.required && $v.newApplicationName.$dirty
              "
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("validation.x can not be empty", [$t("application.Application Name")]) }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <select
                v-model="selectedProject"
                class="eds-input_#input eds-input_#input.dropdown"
              >
                <option selected value="" disabled>{{ $t("project.Choose a Project") }}</option>
                <option
                  v-for="item in projects"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="eds-field_#control eds-field_.eds-input eds-input h-128">
            <textarea
              :placeholder="$t('Description')"
              class="eds-input_#input"
              maxlength="500"
              v-model="newDescription"
            ></textarea>
          </div>
          <div
            v-if="!$v.newDescription.maxLength"
            class="eds-field_#assistive-text eds-field_#assistive-text.error"
          >
            {{ $t("validation.x can not be longer than y characters", [$t("Description"), $v.newDescription.$params.maxLength.max + 1]) }}
          </div>
        </div>
        <div class="dtp-col dtp-col-12 dtp-text--right">
          <button
            @click="checkFieldsAndCreate"
            class="eds-button eds-button.basic"
          >
            {{ $t("buttons.create") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="vuelidate/dist/vuelidate.min.js"></script>
<script>
import { maxLength, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';
import { APPLICATION_ACTIONS } from '../../../store/modules/AutoML/Application/types';

export default {
  props: {
    requestComp: {
      type: String,
      required: true,
    },
  },
  validations: {
    newApplicationName: {
      required,
      maxLength: maxLength(32),
    },
    newDescription: {
      maxLength: maxLength(499),
    },
  },
  data() {
    return {
      newApplicationName: "",
      newDescription: "",
      selectedProject: "",
    };
  },
  computed: {
    ...mapGetters({
      projects: PROJECT_GETTERS.GET_PROJECTS,
    }),
  },
  methods: {
    ...mapActions({
      createApplication: APPLICATION_ACTIONS.CREATE_APPLICATION,
      fetchApplications: APPLICATION_ACTIONS.FETCH_APPLICATIONS,
    }),
    callFetchApplications() {
      this.fetchApplications({
        page: this.currentPage,
        count: this.pageSize,
      });
    },
    async checkFieldsAndCreate() {
      this.$v.newApplicationName.$touch();
      this.$v.newDescription.$touch();

      if (!this.$v.$invalid) {
        const res = await this.createApplication({
          name: this.newApplicationName,
          description: this.newDescription,
          projectId: this.selectedProject,
          requestComp: this.requestComp,
        });
        
        if (res) {
         this.$emit("closePopup", false);
         this.callFetchApplications(); 
        } 
      }
    },
  },
};
</script>
