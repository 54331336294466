<template>
  <div class="dtp-row">
    <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1">
      <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column
          prop="group"
          :label="$t('table.header.Group')"
          column-key='group'
          width="150"
        >
        </el-table-column>
        <el-table-column prop="accs" label="#Accs" width="150">
        </el-table-column>
        <el-table-column :label="$t('table.header.Model Development Sample')"> 
          <el-table-column
            prop="model_deployment_sample"
            :label="$t('table.header.Average Score')"
            column-key='model_deployment_sample'
          >
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('table.header.New Sample')">
          <el-table-column
            prop="new_sample"
            :label="$t('table.header.Average Score')"
            column-key='new_sample'
          >
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1 dtp-mt--1">
      <span style="font-weight: bold; margin-left: 5px"
        >{{ $t("modelGovernance.Student Paired T-Test P-Value") }}:<span
          style="font-weight: normal"
          >&nbsp;{{ pValue }}
        </span>
      </span>
    </div>
    <div class="dtp-col dtp-col-12 dtp-pa--0">
      <span style="color: #436da9; font-size: 20px">*</span>
      <span style="font-weight: bold; margin-left: 5px"
        >If p > 0,05:<span style="font-weight: normal"
          >&nbsp;{{
            $t(
              "modelGovernance.Accept null hypothesis that the means are not equal"
            )
          }}</span
        >
      </span>
    </div>
    <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1">
      <span style="font-weight: bold; margin-left: 13px"
        >If p &lt;= 0,05:<span style="font-weight: normal"
          >&nbsp;{{
            $t(
              "modelGovernance.Reject null hypothesis that the means are equal"
            )
          }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tTestData: {
      type: Object,
    },
  },
  computed: {
    tableData() {
      return (
        this.tTestData?.number_in_each_group?.map((n, index) => {
          return {
            group: index,
            accs: n,
            model_deployment_sample:
              this.tTestData?.observation_mean_list?.[index],
            new_sample: this.tTestData?.prediction_mean_list?.[index],
          };
        }) ?? []
      );
    },
    pValue() {
      return this.tTestData?.p_value;
    },
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (column.columnKey === 'group') {
          sums[index] = this.$t('table.header.Total');
          return;
        }
        if (column.columnKey === 'model_deployment_sample') {
          sums[index] = this.tTestData?.total_mean_observation_y;
          return;
        }
        if (column.columnKey === 'new_sample') {
          sums[index] = this.tTestData?.total_mean_prediction_y;
          return;
        } else {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped>
::v-deep .el-table__footer .cell {
  font-weight: bold;
}
::v-deep thead tr th {
  background-color: #b2c4dc !important;
  border: 1px solid #dcdfe6 !important;
}

::v-deep thead tr th .cell {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
}
</style>
