import { GENERAL_MUTATIONS } from "../General/types";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from "./types";
import { NAVIGATION_MUTATIONS } from "../Navigation/types";
import { ASYNC_PROCESS_ACTIONS } from "../../../../helper/asyncStatusChecker";
import { OPERATION_STATUSES } from "../../../../models/operationStatus";

export const dataStore = {
    state: {
      explorations: [],
      dataList: [],
      importances: [],
      status: null,
      operationStatus: OPERATION_STATUSES.AVAILABLE,
      balanceConfigurationResult: {},
      imbalanceInspection: {},
      resetImbalanceCount: 0,
      previousStep: "",
      applychanges: false,
      grayFields: false,
      selectedModels: [],
      progress: 0,
    },
    getters: {
      [DATA_GETTERS.GET_EXPLORATIONS]: (state) => {
        return state.explorations;
      },
      [DATA_GETTERS.GET_DATA]: (state) => {
        return state.dataList;
      },
      [DATA_GETTERS.GET_IMPORTANCES]: (state) => {
        return state.importances;
      },
      [DATA_GETTERS.GET_STATUS]: (state) => {
        return state.status;
      },
      [DATA_GETTERS.GET_BALANCE_CONFIGURATION_RESULT]: (state) => {
        return state.balanceConfigurationResult;
      },
      [DATA_GETTERS.GET_IMBALANCE_INSPECTION]: (state) => {
        return state.imbalanceInspection;
      },
      [DATA_GETTERS.GET_RESET_IMBALANCE_COUNT]: (state) => {
        return state.resetImbalanceCount;
      },
      [DATA_GETTERS.GET_APPLY_CHANGES]: (state) => {
        return state.applychanges;
      },
      [DATA_GETTERS.GET_GRAY_FIELDS]: (state) => {
        return state.grayFields;
      },
      [DATA_GETTERS.GET_SELECTED_MODELS]: (state) => {
        return state.selectedModels;
      },
      [DATA_GETTERS.GET_PROGRESS]: (state) => {
        return state.progress;
      },
    },
    mutations: {
      [DATA_MUTATIONS.SET_EXPLORATIONS]: (state, val) => {
        Vue.set(state, "explorations", val);
      },
      [DATA_MUTATIONS.SET_DATA]: (state, val) => {
        Vue.set(state, "dataList", val);
      },
      [DATA_MUTATIONS.SET_IMPORTANCES]: (state, items) => {
        Vue.set(state, "importances", items);
      },
      [DATA_MUTATIONS.SET_STATUS]: (state, items) => {
        Vue.set(state, "status", items);
      },
      [DATA_MUTATIONS.SET_OPERATION_STATUS]: (state, items) => {
        Vue.set(state, "operationStatus", items);
      },
      [DATA_MUTATIONS.BALANCE_CONFIGURATION_RESULT](state, obj) {
        Vue.set(state, "balanceConfigurationResult", obj);
      },
      [DATA_MUTATIONS.INSPECT_IMBALANCE](state, obj) {
        Vue.set(state, "imbalanceInspection", obj);
      },
      [DATA_MUTATIONS.RESET_IMBALANCE](state) {
        state.resetImbalanceCount = state.resetImbalanceCount + 1;
      },
      [DATA_MUTATIONS.RESET_EXPLORATIONS]: (state) => {
        Vue.set(state, "explorations", []);
      },
      [DATA_MUTATIONS.RESET_IMPORTANCES]: (state) => {
        Vue.set(state, "importances", []);
      },
      [DATA_MUTATIONS.SET_PREVIOUS_PIPELINE_STEP]: (state, val) => {
        state.previousStep = val;
      },
      [DATA_MUTATIONS.SET_APPLY_CHANGES](state, val) {
        state.applychanges = val;
      },
      [DATA_MUTATIONS.SET_GRAY_FIELDS]: (state, val) => {
        Vue.set(state, "grayFields", val);
      },
      [DATA_MUTATIONS.SET_SELECTED_MODELS]: (state, val) => {
        Vue.set(state, "selectedModels", val);
      },
      [DATA_MUTATIONS.SET_PROGRESS]: (state, val) => {
        state.progress = val
      },
    },
    actions: {
    [DATA_ACTIONS.FETCH_EXPLORATIONS]: async ({ commit }, { requestComp, experimentId, withLoading }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.getExplorationAllList,
        queryParam: experimentId,
        loadingComponent: requestComp,
        withSuccessNotify: false,
        withLoading,
        successMutation: DATA_MUTATIONS.SET_EXPLORATIONS,
        resultSelector: (result) => result.data?.data?.explore
      });
    },
    [DATA_ACTIONS.FETCH_DATA]: async ({ commit }, { requestComp, experiment_id, page, count, withLoading }) => {
      const query = `?page=${page}&count=${count}`;

      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.getData,
        queryParam: { id: experiment_id, query },
        loadingComponent: requestComp,
        withSuccessNotify: false,
        withLoading,
        successMutation: DATA_MUTATIONS.SET_DATA,
        resultSelector: (result) => result.data?.data,
        successCallback: (result) => {
          commit(GENERAL_MUTATIONS.SET_PAGINATION, {
            tableName: "dataList",
            pageInfo: result.data?.stats,
          });
        }
      });
    },
    [DATA_ACTIONS.RENAME_COLUMN]: async ({ commit }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.renameColumn,
        queryParam: { experimentId: data.experinentInfo.experiment_id, columnname: data.experinentInfo.columnname },
        bodyParam: data.name,
        loadingComponent: data.requestComp,
      });
    },
    [DATA_ACTIONS.CONVERT_API]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.convertApi,
        queryParam: data.experiment_id,
        bodyParam: data.convert_data_type,
        loadingComponent: data.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName,
            action: ASYNC_PROCESS_ACTIONS.typeConversion, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.DELETE_COLUMNS]: async ({ commit }, data) => {
      const encoded = encodeURIComponent(data.names);
      const query = `?names=${encoded}&pipeline_step=${data.pipeline_step}`;
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.deleteColumns,
        queryParam: { id: data.experiment_id, query },
        loadingComponent: data.requestComp,
      });
    },
    [DATA_ACTIONS.MISSING_VALUE]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.missingValue,
        queryParam: data.experiment_id,
        bodyParam: data.fill_missing_values,
        loadingComponent: data.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.updateMissingValue, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.ENCODING]: async ({ rootState, commit }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.encoding,
        queryParam: data.experiment_id,
        bodyParam: data.encoding,
        loadingComponent: data.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.encoding, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.SCALING]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.scaling,
        queryParam: data.experiment_id,
        bodyParam: data.scaling,
        loadingComponent: data.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.scaling, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.REMOVE_NA_ROWS]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.removeNaRows,
        queryParam: data.experiment_id,
        bodyParam: data.scaling,
        loadingComponent: data.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName,
            action: ASYNC_PROCESS_ACTIONS.removeNaRows, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.FILTER_DATA]: async ({ commit, rootState }, params) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataFilter,
        queryParam: { experimentId: params.experiment_id, pipelineStep: params.pipeline_step },
        bodyParam: params.payload,
        loadingComponent: params.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName,
            action: ASYNC_PROCESS_ACTIONS.filter, 
            operationId: result?.data?.data?.operation_id,
            experimentId: params.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.POST_FEATURE_GENERATION]: async ({ commit, rootState }, params) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataFeatureGeneration,
        queryParam: params.experiment_id,
        bodyParam: params.payload,
        loadingComponent: params.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.postFeatureGeneration, 
            operationId: result?.data?.data?.operation_id,
            experimentId: params.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.UPDATE_FEATURE_GENERATION]: async ({ commit, rootState }, params) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataUpdateFeatureGeneration,
        queryParam: params.experiment_id,
        bodyParam: params.payload,
        loadingComponent: params.requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.updateFeatureGeneration, 
            operationId: result?.data?.data?.operation_id,
            experimentId: params.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.DATA_VERSIONING]: async ({ commit }, { withLoading, ...params }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataVersioning,
        queryParam: params.experiment_id,
        bodyParam: params,
        withLoading,
        loadingComponent: params.requestComp,
        withSuccessNotify: false,
      });
    },
    [DATA_ACTIONS.FEATURE_IMPORTANCES_POST]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.featureImportancesPost,
        queryParam: data.experiment_id,
        bodyParam: data,
        loadingComponent: data.requestComp,
        withSuccessNotify: false,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName,
            action: ASYNC_PROCESS_ACTIONS.featureImportances, 
            operationId: result?.data?.data?.operation_id,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.FEATURE_IMPORTANCES_GET]: async ({ commit }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.featureImportancesGet,
        queryParam: data.experiment_id,
        loadingComponent: data.requestComp,
        withSuccessNotify: false,
        successMutation: DATA_MUTATIONS.SET_IMPORTANCES,
        resultSelector: (result) => result.data?.data?.importances
      });
    },
    [DATA_ACTIONS.DATA_SORT]: async ({ commit, rootState }, params) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataSort,
        queryParam: params.experimentId,
        bodyParam: params.payload,
        loadingComponent: params.requestComp,
        withSuccessNotify: false,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.sort, 
            operationId: result?.data?.data?.operation_id,
            experimentId: params.experimentId
          });
        }
      });
    },
    [DATA_ACTIONS.GET_STATUS]: async ({ commit }, { withLoading, ...data }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.getStatus,
        queryParam: data.experiment_id,
        loadingComponent: data.requestComp,
        successMutation: DATA_MUTATIONS.SET_STATUS,
        errorMutation: DATA_MUTATIONS.SET_STATUS,
        withLoading,
        resultSelector: (result) => result.data
      });
    },
    [DATA_ACTIONS.GET_OPERATION_STATUS]: async ({ commit }, data) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.getOperationStatus,
        queryParam: { experimentId: data.experiment_id, operationId: data.operation_id },
        loadingComponent: data.requestComp,
        successMutation: DATA_MUTATIONS.SET_OPERATION_STATUS,
        withSuccessNotify: false,
        resultSelector: (result) => result.data?.status
      });

      return result?.data?.status;
    },
    [DATA_ACTIONS.INSPECT_IMBALANCE]: async ({ commit }, params) => {
      const { requestComp, ...others } = params;
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.imbalanceInspection,
        queryParam: others.experiment_id,
        bodyParam: others,
        loadingComponent: requestComp,
        successMutation: DATA_MUTATIONS.INSPECT_IMBALANCE,
        errorMutation: DATA_MUTATIONS.RESET_IMBALANCE,
        resultSelector: (result) => result.data?.data
      });
    },
    [DATA_ACTIONS.BALANCE_CONFIGURATION]: async ({ commit }, params) => {
      const { id, experimentId, requestComp, ...others } = params;
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.balanceConfiguration,
        queryParam: experimentId,
        bodyParam: others,
        loadingComponent: requestComp,
        successMutation: DATA_MUTATIONS.BALANCE_CONFIGURATION_RESULT,
        resultSelector: (result) => result.data?.data
      });
    },
    [DATA_ACTIONS.PUT_DATA_BINNING]: async ({ commit, rootState }, params) => {
      const { requestComp, experimentId, payload } = params;
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.dataBinning,
        queryParam: experimentId,
        bodyParam: payload,
        loadingComponent: requestComp,
        successCallback: (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName,
            action: ASYNC_PROCESS_ACTIONS.dataBinning, 
            operationId: result?.data?.data?.operation_id,
            experimentId: params.experimentId
          });
        }
      });
    },
    [DATA_ACTIONS.GET_OPERATION_STATUS_BY_ID]: async ({ commit }, params) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.getOperationStatusById,
        queryParam: { experimentId: params.experiment_id, operationId: params.operation_id },
        loadingComponent: params.requestComp,
        withSuccessNotify: false,
      });
    },
    [DATA_ACTIONS.PREVIOUS_PIPELINE_STEP]: async ({ commit, rootState }, data) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.data,
        serviceMethodSub: serviceMethodSub.previousPipelineStep,
        queryParam: data.experiment_id,
        bodyParam: data,
        loadingComponent: data.requestComp,
        withSuccessNotify: false,
        successCallback: async (result) => {
          commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, { 
            sectionName: rootState.navigation.routeName, 
            action: ASYNC_PROCESS_ACTIONS.previousPipelineStep, 
            operationId: result?.data,
            experimentId: data.experiment_id
          });
        }
      });
    },
    [DATA_ACTIONS.FETCH_APPLY_CHANGES]: async ({ commit }, data) => {
      try {
        commit(DATA_MUTATIONS.SET_APPLY_CHANGES, data);
      } catch (error) {
        commit(GENERAL_MUTATIONS.ADD_NOTIFY, { ...error, isNotify: false });
      } finally {
        commit(GENERAL_MUTATIONS.SET_LOADING, false);
      }
    },
    }
}