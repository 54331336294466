export const scalingOptions = [
    {
        value: "min_max",
        label: "Min / Max Scaling",
    },
    {
        value: "standard_scaler",
        label: "Standard Scaler",
    },
    {
        value: "robust_scaler",
        label: "Robust Scaler",
    },
    {
        value: "mean_normalization",
        label: "Mean Normalization",
    },
    
]