<template>
  <div class="vgt-table-head-tree">
    <TreeTableElementUi
      :scoreNames="scoreNames"
      :rows="rows"
      @rowChecked="(params) => $emit('rowChecked', params)"
    />
  </div>
</template>
<script>
import TreeTableElementUi from "./TreeTableElementUi.vue";

export default {
  components: {
    TreeTableElementUi,
  },
  props: {
    scoreNames: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.feature-image {
  position: relative;
  width: 100%;
  object-fit: cover;
}

.VGT-row:hover {
  background-color: yellow;
}
</style>
