var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{ref:"modelResultsTable",staticClass:"dtp-modelresults-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.rows,"cell-class-name":_vm.getCellClassName,"row-class-name":"no-hover","cell-style":_vm.handleStyle},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Model'),"prop":"model","width":"150"}}),(!_vm.isCluster)?_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Scores'),"align":"center"}},_vm._l((_vm.scoreNames),function(scoreName){return _c('el-table-column',{key:scoreName,attrs:{"label":_vm.metricLabels[scoreName],"prop":"scores","align":"center"}},[_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Train'),"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Train_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Train')}}),_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Test'),"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Test_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Test')}})],1)}),1):_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Scores'),"align":"center"}},_vm._l((_vm.scoreNames),function(scoreName){return _c('el-table-column',{key:scoreName,attrs:{"label":_vm.metricLabels[scoreName],"prop":"scores","align":"center"}},[_c('el-table-column',{attrs:{"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Train_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Train')}})],1)}),1),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Training Time'),"prop":"trainingtime","width":"150"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }