<template>
  <div class="dtp-section">
    <div class="dtp-pageviewtopbar">
      <PredictionsHeader
        :files="files"
        :InfoList="InfoList"
        @selectChangeDataSet="selectedDataset = $event"
        @predictClicked="predictClicked"
        @changedSearch="changedSearch"
        @refresh="callRefresh"
      />
    </div>
    <div class="dtp-row">
      <div v-loading="loading || loadingLocal" class="dtp-col dtp-col-12">
        <el-table
          id="prediction-table"
          class="dtp-accordion-table"
          :data="predictions"
          style="width: 100%"
        >
          >
          <el-table-column type="expand">
            <template slot-scope="props">
              <PredictionsTestTable
                v-if="hasValidation(props.row)"
                :row="props.row"
                :validations="filterValidationsByPrediction(props.row.prediction_id)"
                :dataType="VALIDATION_DATA_TYPES.NEW_DATA"
              />
            </template>
          </el-table-column>

          <el-table-column :label="$t('table.header.Experiment Name')" prop="experiment_name">
          </el-table-column>
          <el-table-column :label="$t('table.header.Dataset Name')" prop="dataset_name" width="250">
          </el-table-column>
          <el-table-column :label="$t('table.header.Started At')" prop="created_at" width="250">
            <template slot-scope="scope">
              {{ getFormattedDate(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('table.header.Ended At')" prop="ended_at" width="250">
            <template slot-scope="scope">
              {{ getFormattedDate(scope.row.ended_at) }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Prediction Status')"
            prop="prediction_status"
            width="250"
          >
            <template slot-scope="scope">
              {{ $t("status." + getPredictionStatus(scope.row.status)) }}

              <InfoPopup
                v-if="scope.row.status == predictionStatuses.FAILURE"
                :prediction="true"
                :failure_cause="scope.row.failure_cause"
              />

              <i
                v-if="scope.row.status == predictionStatuses.ACTIVE"
                class="el-icon-loading"
                aria-hidden="true"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Validation Status')"
            prop="validation_status"
            width="250"
          >
            <template slot-scope="scope">
              {{ (loading || loadingLocal) ? '' : $t("status." + getValidationStatus(scope.row.prediction_id)) }}

              <InfoPopup
                v-if="getValidationStatus(scope.row.prediction_id) == VALIDATION_STATUSES.FAILED"
                :prediction="true"
                :failure_cause="scope.row.failure_cause"
              />

              <i
                v-if="getValidationStatus(scope.row.prediction_id) == VALIDATION_STATUSES.IN_PROGRESS"
                class="el-icon-loading"
                aria-hidden="true"
              ></i>
            </template>
          </el-table-column>
          <el-table-column :label="$t('table.header.Actions')" prop="path" width="250">
            <template slot-scope="scope">
              <el-tooltip :content="$t('tooltip.download')" placement="top" effect="light">
                <img
                  :class="{
                    'dtp-cursor-not':
                      scope.row.status != predictionStatuses.PASSIVE,
                  }"
                  class="dtp-vgt-actions-icon"
                  src="../../../assets/images/icons/DownloadModel.svg"
                  alt=""
                  @click="downloadURI(scope.row.path, scope.row.status)"
                />
              </el-tooltip>
              <el-tooltip content="Model Drift Report" placement="top" effect="light">

                <Icon
                  v-if="scope.row.status== predictionStatuses.PASSIVE && scope.row.drift_report_path !== null"
                  icon="driftReport" size="sm"
                  @onClick="showDriftReport(scope.row.drift_report_path)"
                />

              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters } from "vuex";
import PredictionsHeader from "./PredictionsHeader.vue";
import CustomPagination from "../CustomPagination";
import notify from "../../../mixins/AutoML/notify";
import InfoPopup from "../ModelGovernance/InfoPopup";
import { formatDate } from "../../../helper/format";
import PredictionsTestTable from "./PredictionsTestTable.vue";
import { DATASET_ACTIONS, DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../store/modules/AutoML/Models/types';
import { VALIDATION_DATA_TYPES, VALIDATION_STATUSES } from "../../../models/modelGovernance";
import Icon from "../../Icons/Icon.vue"

export default {
  mixins: [notify],
  data() {
    return {
      selectedDataset: "",
      searchName: "",
      pageSize: 10,
      currentPage: 1,
      files: [
        {
          value: "LocalFile",
          label: "File From Disk",
        },
        {
          value: "JdbcConnection",
          label: "Relational Database",
        },
      ],
      InfoList: [
        { title: "Project Name", text: "Test" },
        { title: "Experiment Name", text: "Test2" },
        { title: "Model Name", text: "Test3" },
      ],
      datasetCount: 100,
      predictionStatuses: {
        PASSIVE: "PASSIVE",
        ACTIVE: "ACTIVE",
        FAILURE: "FAILURE",
      },
      VALIDATION_DATA_TYPES,
      VALIDATION_STATUSES,
      loadingLocal: false
    };
  },
  watch: {
    pageSize: {
      handler() {
        this.init();
      },
    },
    predictions: {
      deep: true,
      handler(val) {
        const containsActive = val.filter(
          (v) => v.status === this.predictionStatuses.ACTIVE
        ).length;
        const self = this;
        if (containsActive)
          setTimeout(function () {
            self.init();
          }, 3000);
      },
    },
  },
  components: {
    PredictionsTestTable,
    PredictionsHeader,
    CustomPagination,
    InfoPopup,
    Icon
  },
  async mounted() {
    this.requestComp = "predictions";
    await this.fetchDatasets({ page: 1, count: this.datasetCount });
    await this.init();
  },
  computed: {
    ...mapGetters({
      models: MODELS_GETTERS.GET_MODELS,
      dataSetList: DATASET_GETTERS.GET_DATASETS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      predictions: MODELS_GETTERS.GET_PREDICTIONS,
      modelValidations: MODELS_GETTERS.GET_MODEL_VALIDATIONS,
    }),
    lastPage() {
      return this.pagination.predictions?.last_page ?? 1;
    },
    modelId: {
      get() {
        return this.$route.query.model_id;
      },
    },
  },
  methods: {
    ...mapActions({
      fetchModelsByModelId: MODELS_ACTIONS.FETCH_MODELS_BY_MODEL_ID,
      fetchModelValidations: MODELS_ACTIONS.FETCH_MODEL_VALIDATIONS,
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
      fetchPredictions: MODELS_ACTIONS.FETCH_PREDICTIONS,
      makePrediction: MODELS_ACTIONS.MAKE_PREDICTION,
      makeModelValidations: MODELS_ACTIONS.MAKE_MODEL_VALIDATIONS,
    }),
    filterValidationsByPrediction(predictionId) {
      return this.modelValidations.filter(i => i.prediction_id === predictionId);
    },
    getPredictionStatus(status) {
      if (status === this.predictionStatuses.PASSIVE) return "SUCCESS";
      else if (status === this.predictionStatuses.ACTIVE) return "IN PROGRESS";
      return status;
    },
    getValidationStatus(predictionId) {
      
      const validations = this.modelValidations?.filter?.(i => i.prediction_id === predictionId);
      
      if (validations?.length) {
        const statuses = validations.map(i => i.status);

        if (statuses.includes(VALIDATION_STATUSES.IN_PROGRESS))
          return "IN PROGRESS";
        
        if (statuses.includes(VALIDATION_STATUSES.SUCCESS))
          return "SUCCESS";

        return "FAILURE";
      }
      
        return "NONE";     
    },
    hasValidation(row) {
      const validationFound = this.modelValidations?.find(
        (d) => d.prediction_id === row.prediction_id
      );

      if (validationFound) return true;
      return false;
    },
    async callRefresh() {
      await this.init();
    },
    getFormattedDate(value) {
      return formatDate(
        value,
        this.$store.getters["Settings/Get/Preference"].longDateFormat
      );
    },
    downloadURI(uri, status) {
      if (status == this.predictionStatuses.PASSIVE) {
        var link = document.createElement("a");
        link.download = uri;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        return false;
      }
    },
    async init() {
      this.loadingLocal= true;
      const payload = {
        page: 1,
        count: 10,
        model_id: this.modelId,
      };
      await this.fetchModelValidations({
        requestComp: this.requestComp,
        model_id: this.modelId,
      });
      await this.fetchModelsByModelId(payload);
      await this.fetchPredictions({
        page: this.currentPage,
        count: this.pageSize,
        requestComp: this.requestComp,
        name: this.searchName,
        modelId: this.modelId,
      });
      this.InfoList[0].text = this.models[0].project;
      this.InfoList[1].text = this.models[0].experiment;
      this.InfoList[2].text = this.models[0].model;

      this.$nextTick(() => {
        this.disableExpansions();
      });
      this.loadingLocal= false;
    },
    disableExpansions() {
      const tableRef = document.getElementById("prediction-table");
      
      this.predictions.forEach((p, index) => {
        const hasValidationEnabled = this.getValidationStatus(p.prediction_id);
        let rowElement = tableRef?.querySelectorAll(".el-table__row")?.[index]?.querySelector(".el-table__expand-icon");
        
        if (rowElement) {
          if (hasValidationEnabled === "NONE") {
            rowElement.style.display = "none";
          } else {
            rowElement.style.display = "block";
          }
        }
      })
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
    async predictClicked(params) {
      if (params.isValidationsSelected) {
        await this.makeModelValidations({
          model_id: this.modelId,
          data: {
            type: "new_data",
            methods: ["t_test", "binomial_test"],
            dataset_id: this.selectedDataset,
          },
          requestComp: this.requestComp,
        });
      } else {
        await this.makePrediction({
          model_id: this.modelId,
          data: {
            dataset_id: this.selectedDataset,
          },
          requestComp: this.requestComp,
        });
      }

      this.setLoading(true);
      setTimeout(() => this.init(), 5000);
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
    showDriftReport(driftReportPath) {
     window.open(driftReportPath)
    }
  },
};
</script>
