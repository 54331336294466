
import createPersistedState from "vuex-persistedstate"
import Vue from "vue";
import { NAVIGATION_GETTERS, NAVIGATION_MUTATIONS } from "./types";

export const navigation = {
    plugins: [createPersistedState()],
    state: {
        navigationList: [],
        lastStep: {
        },
        featureImportanceOpId: "",
        onGoingOperations: {},
        routeName: ""
    },
    getters: {
        [NAVIGATION_GETTERS.GET_NAVIGATION_LIST]: (state) => {
            return state.navigationList;
        },
        [NAVIGATION_GETTERS.GET_NAVIGATION_LAST_STEP]: (state) => {
            return state.lastStep;
        },
        [NAVIGATION_GETTERS.GET_FEATURE_IMPORTANCE_OPERATION_ID]: (state) => {
            return state.featureImportanceOpId;
        },
        [NAVIGATION_GETTERS.GET_ON_GOING_OPERATIONS]: (state) => {
            return state.onGoingOperations;
        },
        [NAVIGATION_GETTERS.GET_ROUTE_NAME]: (state) => {
            return state.routeName;
        },
    },
    actions: {
    },
    mutations: {
        [NAVIGATION_MUTATIONS.SET_NAVIGATION_LIST]: (state, val) => {
            state.navigationList.push(val);
        },
        [NAVIGATION_MUTATIONS.RESET_NAVIGATION_LIST]: (state) => {
            state.navigationList = [];
            Vue.set(state, "lastStep", {});
        },
        [NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST]: (state, val) => {
            state.navigationList = Array(val).fill(1)
        },
        [NAVIGATION_MUTATIONS.SET_LAST_STEP]: (state, val) => {
            Vue.set(state, "lastStep", val);
        },
        [NAVIGATION_MUTATIONS.SET_FEATURE_IMPORTANCE_OPERATION_ID]: (state, val) => {
            state.featureImportanceOpId = val;
        },
        [NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION]: (state, { sectionName, operationId, action, experimentId }) => {
            const operations = state.onGoingOperations[experimentId]?.[sectionName]?.filter(i => i.action !== action);

            if (operations) {
                Vue.set(state.onGoingOperations[experimentId], sectionName, [{ action, operationId }, ...operations]);
            } else {
                if (!state.onGoingOperations[experimentId]) {
                    Vue.set(state.onGoingOperations, experimentId, {});
                }

                Vue.set(state.onGoingOperations[experimentId], sectionName, [{ action, operationId }]);
            }
        },
        [NAVIGATION_MUTATIONS.REMOVE_ON_GOING_OPERATION]: (state, { sectionName, action, experimentId  }) => {
            const operations = state.onGoingOperations[experimentId]?.[sectionName]?.filter(i => i.action !== action);

            if (operations) {
                Vue.set(state.onGoingOperations[experimentId], sectionName, operations);
            }
        },
        [NAVIGATION_MUTATIONS.CLEAR_ON_GOING_OPERATIONS]: (state, experimentId) => {
            const operations = { ...state.onGoingOperations };

            delete operations[experimentId];

            Vue.set(state, "onGoingOperations", operations);
        },
        [NAVIGATION_MUTATIONS.SET_ROUTE_NAME]: (state, data) => {
            Vue.set(state, "routeName", data);
        },
    },
};
