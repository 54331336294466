<template>
  <div id="dtp-topbar" class="dtp-fcolor-FFFFFF">
    <div class="flex-item">
      <img
        v-if="compName"
        v-show="$route.name != 'AutoMl'"
        id="hamburger"
        class="dtp-cursor-pointer"
        style="margin-left: 10px"
        src="../../assets/images/HamburgerIcon.svg"
        alt=""
      />
      <span class="dtp-fsize--10" v-if="compName">
        <span v-if="$route.meta.layout != 'AutoMlLayout'">Data Plateau</span>
        <span class="dtp-font-bold dtp-fsize--10">{{
          $t(moduleName)
        }}</span></span
      >
    </div>

    <div class="flex-item dtp-text--right dtp-topbar-select">
      <div v-if="TopbarColor != 'AutoMl'" class="dtp-name-box dtp-mr--2">
        <el-dropdown>
          <span class="el-dropdown-link dtp-font-bold">
            <svg
              viewBox="0 0 24 24"
              class="eds-primary-nav-item_#icon dtp-modules-icon"
            >
              <path
                d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"
              ></path>
            </svg>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in modules" :key="index">
              <router-link :to="{ name: item.link }" v-if="compName">
                <div
                  class="dtp-modules-icon-box dtp-ma--0"
                  :class="{
                    'dtp-modules-icon-back-active': urlLink == item.layout,
                  }"
                >
                  <div class="dtp-modules-icon-back">
                    <span>{{ item.name[0] }}</span>
                  </div>
                </div>
                <span class="dtp-mr--2">{{ item.name }}</span>
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="dtp-language-change-box">
        <el-select
          @change="changeLanguage()"
          v-model="$i18n.locale"
          placeholder="Select"
          class="dtp-custom-select-topBar"
        >
          <el-option
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :label="lang.toUpperCase()"
            :value="lang"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dtp-name-box dtp-mr--1">
        <el-dropdown>
          <span class="el-dropdown-link dtp-font-bold">
            <div class="dtp-user-image">
              <span>Account</span>
              <!--DURUMA GÖRE IMAGE GELECEK-->
            </div>
            <div class="dtp-dropdown-back">
              <i class="el-icon-arrow-down"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><span>{{ username }}</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ name: 'Modules' }" v-if="compName">
                <span class="dtp-mr--2">{{ $t("modules.see_modules") }}</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item
              ><span @click="logout()" class="dtp-display-block">{{
                $t("layout.logout")
              }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <span> {{ username[0].toUpperCase() }} </span> -->
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";

export default {
  name: "locale-changer",
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    TopbarColor: {
      type: String,
      required: false,
    },
    ConstantMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      langs: ["tr", "en"],
      modules: [
        { name: "Visualize", link: "Visualization", layout: "Layout" },
        { name: "Auto ML", link: "AutoMl", layout: "AutoMlLayout" },
        { name: "Scenario", link: "Scenario", layout: "ScenarioLayout" },
        { name: "Ingest", link: "Ingest", layout: "IngestLayout" },
      ],
      urlLink: this.$route.meta.layout,
    };
  },

  mounted() {
    var menuClick = document.getElementById("hamburger");
    var $body = document.getElementsByTagName("body")[0];

    if (menuClick)
      menuClick.addEventListener("click", function() {
        $body.classList.toggle("constantMenu");
      });
  },
  methods: {
    changeLanguage() {
      eventBus.$emit("drawChart", "");
      eventBus.$emit("reload", "");
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    logout() {
      const logoutFunc = this.$store.getters[
        "Security/Get/Keycloak/LogoutFunc"
      ];
      logoutFunc();
      this.$store.dispatch("Security/Set/Keycloak/Reset");
    },
    constantToggle() {
      if (!this.constant) {
        this.constant = true;
        this.hamburgericon = "el-icon-s-fold";
      } else {
        this.constant = false;
        this.hamburgericon = "el-icon-s-unfold";
      }
    },
  },
  computed: {
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    compName() {
      let compName = false;
      if (
        this.$route.meta.layout == "Layout" ||
        this.$route.meta.layout == "IngestLayout" ||
        this.$route.meta.layout == "ScenarioLayout" ||
        this.$route.meta.layout == "AutoMlLayout"
      ) {
        compName = true;
      }
      return compName;
    },
  },
};
</script>

<style>
.dtp-dropdown-back {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: #3a5d72;
  vertical-align: middle;
}
.dtp-dropdown-back i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 8px;
}
.dtp-user-image {
  position: relative;
  width: 70px;
  /* height: 31px; */
  padding: 0;
  margin-right: 5px;
  border-radius: 100%;
  background-color: transparent;
  display: inline-block;
  /* vertical-align: middle;
  overflow: hidden; */
}
.dtp-user-image img {
  height: 100%;
  width: 100%;
}
.dtp-user-image span {
  margin-right: 5px;
}
#dtp-topbar .flex-item {
  width: 50%;
}
.dtp-language-change-box {
  min-width: 75px;
  width: 8%;
  display: inline-block;
}
.dtp-name-box {
  display: inline-block;
}
.dtp-name-box .el-input__inner {
  width: auto;
  padding: 0;
}
.dtp-topbar-select .el-input__inner {
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 0;
}

.dtp-topbar-select .el-select .el-input .el-select__caret {
  color: #ffffff;
}

.dtp-topbar-select .el-input__suffix {
  right: 30px;
}
.el-dropdown {
  display: inline-block;
  position: relative;
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}
.dtp-modules-icon {
  transform: translateY(7px);
}
.dtp-modules-icon-box {
  display: inline-block;
}
.dtp-modules-icon-back {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 100%;
  background-color: #b2b2b2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dtp-modules-icon-back-active .dtp-modules-icon-back {
  background-color: #164f73;
}
.dtp-modules-icon-back-active + span {
  color: #164f73;
  font-weight: 600;
}

@media only screen and (max-width: 999px) {
  #dtp-topbar .flex-item:nth-child(1) {
    width: 10%;
  }
  #dtp-topbar .flex-item:nth-child(2) {
    width: 90%;
  }
}
</style>
