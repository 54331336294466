<template>
  <svg
    id="information-variant"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Boundary"
      fill="#1d4e91"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="20" height="20" stroke="none" />
      <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
    </g>
    <path
      id="Path_information-variant"
      data-name="Path / information-variant"
      d="M12.679,4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12.679,4m-.3,3.975c-.992.083-3.7,2.242-3.7,2.242-.167.125-.117.117.017.35s.117.242.275.133.442-.283.9-.567c1.767-1.133.283,1.483-.475,5.892-.3,2.183,1.667,1.058,2.175.725s1.842-1.25,1.975-1.342c.183-.125.05-.225-.092-.433-.1-.142-.2-.042-.2-.042-.542.358-1.533,1.108-1.667.633-.158-.475.858-3.733,1.417-5.975C13.1,9.058,13.346,7.892,12.379,7.975Z"
      transform="translate(-1.073 -0.667)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
