
export const experimentColumns = [
    {
        label: "table.header.experiment_name",
        field: "name",
    },
    {
        label: "table.header.dataset_name",
        field: "dataset_name",
    },
    {
        label: "table.header.target",
        field: "target_column",
    },
    {
        label: "table.header.performance_metric",
        field: "scorer",
    },
    {
        label: "table.header.train_score",
        field: "train_score",
    },
    {
        label: "table.header.test_score",
        field: "test_score",
    },
    {
        label: "table.header.total_training_time",
        field: "total_training_time",
    },
    {
        label: "table.header.created_user",
        field: "username",
    },
    {
        label: "table.header.created_date",
        field: "formatted_created_at",
    },
    {
        label: "table.header.device_type",
        field: "device",
    },
    {
        label: "table.header.status",
        field: "status",
    },
    {
        label: "table.header.actions",
        field: "actions",
        sortable: false
    }
]