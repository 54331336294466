export const responseStatusMapping = {
    200: {
      title: "alert.success",
      type: "success",
    },
    201: {
      title: "alert.success",
      type: "success",
    },
    202: {
      title: "alert.success",
      type: "success",
    },
    403: {
      title: "alert.error",
      type: "error",
    },
    400: {
      title: "alert.error",
      type: "error",
    },
    404: {
      title: "alert.error",
      type: "error",
    },
    405: {
      title: "alert.warning",
      type: "warning",
    },
    412: {
      title: "alert.error",
      type: "error",
    },
    422: {
      title: "alert.error",
      type: "error",
    },
    500: {
      title: "alert.error",
      type: "error",
    },
    503: {
      title: "alert.error",
      type: "error",
    },
    507: {
      title: "alert.error",
      type: "error",
    },
  }