<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')">
    <div slot="title">
      {{ `${title}` }}
      <span style="font-weight: bold">{{ "/ " + $t("Logs") }}</span>
    </div>

    <div class="dtp-aml-modelSelection-card dtp-aml-h-760 dtp-bgcolor-2E3138">
      <div class="dtp-aml-modelSelection-card-content">
        <div class="dtp-aml-log-content dtp-scrollbar">
          <div class="dtp-row">
            <div
              v-for="(log, index) in logsArray"
              :key="index"
              class="dtp-col dtp-col-12 dtp-pl--0"
            >
              <log-item
                class="dtp-mb--3"
                :class="{
                  'dtp-aml-logItemRed': log.level == 'ERROR',
                  'dtp-aml-logItemGreen': log.level != 'ERROR',
                }"
                :message="log.message"
                :date="log.date"
                :level="log.level"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LogItem from "../ModelRunning/LogItem";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
export default {
  components: {
    LogItem,
  },
  props: {
    visible: {
      type: Boolean,
    },
    selectedRow: {
      type: Object,
    },
    title: {
      type: String,
    },
    experimentId: {
      type: Number,
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
    selectedRow: {
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          const { experiment_id } = val;
          this.logs(this.experimentId);
        }
      },
    },
  },
  data() {
    return {
      text: "",
      dialogVisible: false,
      logsArray: [],
    };
  },
  methods: {
    ...mapActions({
      fetchTrainingsByExperimentId: EXPERIMENT_ACTIONS.FETCH_TRAININGS_BY_EXPERIMENT_ID,
      fetchTrainingLogs: EXPERIMENT_ACTIONS.FETCH_TRAINING_LOGS,
    }),
    async logs(experiment_id) {
      await this.fetchTrainingsByExperimentId(experiment_id);

      await this.fetchTrainingLogs({
        experimentId: experiment_id,
        trainID: this.experimentTrainings.trainings[0].train_id,
      });
      this.logsArray = this.trainingLogs.logs;
    },
  },
  computed: {
    ...mapGetters({
      experimentTrainings: EXPERIMENT_GETTERS.GET_EXPERIMENT_TRAININGS,
      trainingLogs: EXPERIMENT_GETTERS.GET_TRAINING_LOGS,
    }),
  },
};
</script>

<style lang="scss" scoped>
.bigger {
  font-size: 30px;
  padding: 10px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
.dtp-aml-logItemBlue {
  color: #1275ff;
}
.dtp-aml-logItemGreen {
  color: #69ff9d;
}
.dtp-aml-logItemRed {
  color: #ff5858;
}
</style>