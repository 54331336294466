export const domain = "AUTOML/DATASET"

export const DATASET_ACTIONS = {
    FETCH_DATASETS: `${domain}/fetchDatasets`,
    FETCH_VISUALIZATION: `${domain}/fetchVisualization`,
    DELETE_DATASET_BY_ID: `${domain}/deleteDatasetByID`,
    GET_DATASET_BY_ID: `${domain}/getDatasetById`,
    UPLOAD_CSV: `${domain}/UPLOAD_CSV`,
    UPLOAD_PREVIEW_CSV: `${domain}/UPLOAD_PREVIEW_CSV`,
    SET_DATASET_NAME: `${domain}/setDatasetName`,
}

export const DATASET_MUTATIONS = {
    SET_DATASETS: `${domain}/setDatasets`,
    SET_VISUALIZATION: `${domain}/setVisualization`,
    GET_DATASET_BY_ID: `${domain}/getDatasetById`,
    RESET_DATASET: `${domain}/resetDataset`,
    DELETE_DATASET_BY_ID: `${domain}/deleteDatasetByID`,
    SET_DATASETS_FILTER: `${domain}/setDatasetsFilter`,
    SET_DATASET_NAME: `${domain}/setDatasetName`,
}

export const DATASET_GETTERS = {
    GET_DATASETS: `${domain}/getDatasets`,
    GET_VISUALIZATION: `${domain}/getVisualization`,
    GET_DATASET: `${domain}/getDataset`,
    GET_DATASETS_FILTER: `${domain}/getDatasetsFilter`,
    GET_DATASET_NAME: `${domain}/getDatasetName`,
}