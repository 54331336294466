<template>
  <div>
    <h6 class="dtp-font-bold dtp-mb--2 dtp-text--center">{{ title }}</h6>
    <component
      style="display: block; margin-left: auto; margin-right: auto"
      :is="component"
      :progress="perc"
      :determinate="false"
      color="#7579ff"
      empty-color="#324c7e"
      :size="size"
      :thickness="8"
      :empty-thickness="5"
      lineMode="in 10"
      animation="loop 700 1000"
      fontSize="1.5rem"
      font-color="black"
      dash="3"
      :loading="false"
      :no-data="false"
    >
    </component>
  </div>
</template> 

<script>
import Interval from "./interval";

export default {
  name: "ProgressCircle",
  props: {
    title: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      required: true,
    },
    timerNumber: {
      type: Number,
    },
  },
  data() {
    return {
      perc: 0,
    };
  },
  computed: {
    component() {
      return "vue-ellipse-progress";
    },
  },
  methods: {
    randomizeOptions() {
      if (this.timerNumber < 100) {
        this.perc = this.timerNumber;
      }
      else if(this.timerNumber == 100){
        if(this.title=="Process Information")
        {
          this.perc = 100;
        }else{
          this.perc = 0;
        }
      }
    },
  },
  mounted() {
    Interval.addTask(this.randomizeOptions);
    Interval.run();
  },
};
</script>

<style scoped></style>