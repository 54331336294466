<template>
  <el-dialog
    width="450px"
    :visible.sync="dialogVisible"
    @closed="$emit('closePopup')"
  >
    <div slot="title">
      <span style="font-weight: bold; text-align. center">{{ $t("Retrain") }}</span>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12 dtp-chart-box-20 dtp-pa--0">
        <span class="componentLabels">{{ $t("Data Set") }}</span>
        <DatasetFiltering @onSelected="callSelectedId" />
      </div>

      <span class="componentLabels">{{ $t("Train Ratio") }}</span>
      <div class="dtp-chart-box dtp-chart-box-20">
        <div class="eds-field_#control eds-field_.eds-input eds-input">
          <input
            type="number"
            :value="form.train_ratio"
            class="eds-input_#input dtp-floating-input"
            :placeholder="$t('Train Ratio')"
            @change="(e) => (form.train_ratio = e.target.value)"
          />
        </div>
      </div>

      <span class="componentLabels">{{ $t("Cross Validation") }}</span>
      <div class="dtp-chart-box dtp-chart-box-20">
        <div class="eds-field_#control eds-field_.eds-input eds-input">
          <el-tooltip
            :content="$t('Cross validation should be between 0 - 10')"
            placement="top"
            effect="light"
          >
            <input
              :min="crossValidationNumberofFoldsRange.min"
              :max="crossValidationNumberofFoldsRange.max"
              type="number"
              :value="form.cross_validation_number_of_folds"
              class="eds-input_#input dtp-floating-input"
              :placeholder="$t('Cross Validation')"
              @change="
                (e) => (form.cross_validation_number_of_folds = e.target.value)
              "
            />
          </el-tooltip>
        </div>
      </div>

      <div
        v-if="isProblemTypeNotRegression"
        class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
      >
        <div class="dtp-panel-formbox dtp-mr--0">
          <label class="eds-checkbox">
            <input
              type="checkbox"
              class="eds-checkbox_#input"
              v-model="form.stratify"
            />
            <span class="eds-checkbox_#label">{{ $t("Stratify") }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="dtp-text--right dtp-mt--2">
      <button
        class="eds-button eds-button.basic dtp-mr--1"
        @click="
          $emit('closePopup');
          dialogVisible = false;
        "
      >
        {{ $t("commons.cancel") }}
      </button>
      <button class="eds-button eds-button.basic" @click="callRetrain">
        {{ $t("Retrain") }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { DATASET_ACTIONS } from "../../../../store/modules/AutoML/Dataset/types";
import DatasetFiltering from "../../utils/DatasetFiltering.vue";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  components: {
    DatasetFiltering,
  },
  data() {
    return {
      problemTypes: {
        Classification: "classification",
        Regression: "regression",
        Clustering: "clustering",
      },
      datasetCount: 100,
      form: {
        dataset_id: null,
        stratify: false,
        train_ratio: 80,
        cross_validation_number_of_folds: 3,
        shuffle: true,
      },
      crossValidationNumberofFoldsRange: {
        min: 0,
        max: 10,
      },
      dialogVisible: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
  },
  mounted() {
    this.fetchDatasets({ page: 1, count: this.datasetCount });
  },
  computed: {
    isProblemTypeNotRegression() {
      return (
        this.row?.problemType &&
        this.row?.problemType !== this.problemTypes.Regression
      );
    },
  },
  methods: {
    ...mapActions({
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
    }),
    callSelectedId(value) {
      this.form.dataset_id = value;
    },
    callRetrain() {
      this.$emit("retrain", this.form);
    },
  },
};
</script>

<style scoped>
.componentLabels {
  display: block;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-top: 10px;
}
</style>
