<template>
  <div class="dtp-section">
    <div class="dtp-row">
      <div class="aml-section-header dtp-align-center">
          <div class="dtp-panel-formbox">
            <div class="dtp-page-title">
              <span>
               <Icon icon="experiments" size="xl"></Icon>
              </span>
              {{ $t("experiments.page_title") }}
            </div>
          </div>
        <PageViewBar
          :title="''"
          :routerLink="''"
          :activeFilters="true"
          :activeSearch="true"
          :tooltipSearchName="'Experiment'"
          :headerArray="filterArray"
          helperLink="list-experiments"
          @filterSaved="filterSaved"
          @deleteFiltered="deleteFilter"
          :filterList="filterList"
          :createBtn="false"
          :calculateview="false"
          @changedSearch="changedSearch"
        >
        <el-tooltip :content="$t('Refresh Table')" placement="top" effect="light" slot="bar-right">
          <button
            class="aml-btn eds-button.primary"
            :loading="loading"
            @click="init()"
          >
            <img src="../../assets/images/dtprefreshicon.svg" alt="" />
          </button>
        </el-tooltip>
        </PageViewBar>
      </div>
    </div>
    <!-- Modal starts here -->
    <div id="modelGovernanceModals">
      <logs
        :visible="logsVisible"
        :selectedRow="logsParams.selectedRow"
        :title="logsParams.title"
        :experimentId="logsParams.experimentId"
        @modalClosed="() => (logsVisible = false)"
      />
    </div>
    <div class="dtp-row">
      <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="enhancedExperiments"
          :row-style-class="rowStyleClassFn"
          @on-sort-change="(params) => onSortChange(params, init)"
        >
          <!-- for header localization -->
          <template slot="table-column" slot-scope="props">
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <el-tooltip
                :content="
                  $t(props.row.type === 'LIVE' && props.row.status !== 'COMPLETED'
                    ? 'Please finish experiment in order to use this functionality'
                    : 'Challenger Experiment')
                "
                placement="top"
                effect="light"
                v-if="isChallengerExperimentActionVisible(props.row)"
              >
                <img
                  src="../../assets/images/icons/challengerExperiment.svg"
                  alt="visualize"
                  @click.prevent="
                    isChallengerExperimentActionClickable(props.row)
                      ? challengerExperimentClicked(props.row)
                      : null
                  "
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  :class="
                    isChallengerExperimentActionClickable(props.row)
                      ? ''
                      : 'dtp-cursor-not'
                  "
                />
              </el-tooltip>
              <el-tooltip
                :content="$t('tooltip.start')"
                placement="top"
                effect="light"
                v-if="isExperimentStartAvailable(props.row)"
              >
                <img
                  src="../../assets/images/icons/automl-memory-icon.svg"
                  alt="visualize"
                  @click.prevent="start(props.row.project_id, props.row.id)"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-memory-icon
                  "
                />
              </el-tooltip>
              <el-tooltip
                :content="$t('tooltip.play')"
                placement="top"
                effect="light"
                v-if="isExperimentPlayAvailable(props.row)"
              >
                <img
                  @click.stop="exploreData(props.row)"
                  src="../../assets/images/icons/automl-play-icon.svg"
                  alt="door"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-play-icon
                  "
                />
                <!-- yerine yeni icon eklenicek  -->
              </el-tooltip>
              <el-tooltip
                :content="$t('tooltip.stop')"
                placement="top"
                effect="light"
                v-if="isExperimentStopAvailable(props.row)"
              >
                <img
                  src="../../assets/images/icons/automl-memory-stop-icon.svg"
                  alt="visualize"
                  @click.prevent="
                    stop(
                      props.row.project_id,
                      props.row.id,
                      props.row.type,
                      props.row.username
                    )
                  "
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-memory-stop-icon
                  "
                />
              </el-tooltip>
              <el-tooltip
                v-if="isExperimentResetAvailable(props.row)"
                :content="$t('buttons.reset')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../assets/images/icons/automl-clear-icon.svg"
                  @click.prevent="
                    clear({
                      requestComp: requestComp,
                      experimentId: props.row.id,
                    })
                  "
                  alt="visualize"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-clear-icon
                  "
                />
              </el-tooltip>
              <el-tooltip
                v-if="isDeleteExperimentButtonAvailable"
                :content="$t('buttons.delete')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../assets/images/icons/automl-delete-icon.svg"
                  @click.prevent="
                    deleteX(props.row.project_id, props.row.id, currentPage)
                  "
                  alt="visualize"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-delete-icon
                  "
                />
              </el-tooltip>
              <el-tooltip
                v-if="isModelGovernanceButtonAvailable"
                :content="$t('tooltip.model_governance')"
                placement="top"
                effect="light"
              >
                <img
                  v-if="
                    props.row.status == 'TRAINED' ||
                    props.row.status == 'TUNED' ||
                    props.row.status == 'COMPLETED'
                  "
                  src="../../assets/images/icons/DownloadModel.svg"
                  @click.prevent="goGovernance(props.row)"
                  alt="visualize"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-download-model-icon
                  "
                />
              </el-tooltip>
              <el-tooltip
                v-if="isModelLogsButtonAvailable && props.row.status == 'COMPLETED'"
                :content="$t('tooltip.model_logs')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../assets/images/icons/ExperimentLogs.svg"
                  @click.prevent="goLogs(props.row)"
                  alt="visualize"
                  class="
                    dtp-cursor-pointer dtp-vgt-actions-icon
                    experiments-logs-icon
                  "
                /> </el-tooltip
              ><el-tooltip
                v-if="isModelResultsButtonAvailable"
                :content="$t('Display Model Results')"
                placement="top"
                effect="light"
              >
                <img
                  class="dtp-vgt-actions-icon experiments-predict-icon"
                  src="../../assets/images/icons/Predict.svg"
                  @click.prevent="
                    openModelResultsPage(props.row.id, props.row.name)
                  "
                  alt=""
                />
              </el-tooltip>
            </span>
            <span
              class="dtp-display-flex"
              v-else-if="props.column.field == 'status'"
            >
              <!-- <el-badge is-dot :type="props.row.state.type"></el-badge> -->
              <div class="dtp-table-status-icon"></div>
              <span v-if="props.row.state.type == 'success'"> ({{ $t("status.Ready") }}) </span>
              <span v-else-if="props.row.state.type == 'warning'">
                ({{ $t("status.Loading") }})
              </span>
              <span v-else-if="props.row.state.type == 'danger'">
                ({{ $t("status.Error") }})
              </span>
              <span v-else> ({{ $t("status.Created") }}) </span> </span
            ><span v-else-if="props.column.field == 'name'">
              <span>{{ props.formattedRow[props.column.field] }}</span>
              <span style="color: transparent; margin-left: 2px"
                >({{ props.row.id }})</span
              >
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          :totalItem="totalItem"
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageViewBar from "../AutoMl/Helper/Pageviewbar";
import privileges from "./../../mixins/privileges";
import checkAuth from "../../mixins/authorization";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { datasetCol } from "../AutoMl/Services/data/experiments";
import { ACTION, GENERAL_GETTERS, GETTER, MUTATION } from "../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import notify from "../../mixins/AutoML/notify";
import preferences from "../../mixins/AutoML/preferences";
import CustomPagination from "./CustomPagination";
import { formatDate } from "../../helper/format";
import pipeline from "../../mixins/AutoML/pipeline";
import remoteDataSort from "../../mixins/AutoML/remoteDataSort";
import helperLink from "../../mixins/AutoML/helperLink";
import Logs from "./ModelGovernance/Logs";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../store/modules/AutoML/Projects/types';
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS, EXPERIMENT_MUTATIONS } from '../../store/modules/AutoML/Experiments/types';
import { MODELS_MUTATIONS } from '../../store/modules/AutoML/Models/types';
import { NAVIGATION_MUTATIONS } from '../../store/modules/AutoML/Navigation/types';
import componentAvailabilityCheck from '../../mixins/AutoML/componentAvailabilityCheck';
import { PRIVILEGES } from '../../helper/authorization';
import { mapFilterResult } from './Popup/filterPopupTypes';
import Icon from "../Icons/Icon.vue";

export default {
  components: {
    PageViewBar,
    VueGoodTable,
    CustomPagination,
    Logs,
    Icon
  },
  mixins: [
    checkAuth,
    privileges,
    notify,
    preferences,
    pipeline,
    remoteDataSort,
    helperLink,
    componentAvailabilityCheck
  ],
  data() {
    return {
      statusTypes: {
        RUNNING: {
          type: "success",
          key: "RUNNING",
        },
        TERMINATED: {
          type: "danger",
          key: "TERMINATED",
        },
        FAILURE: {
          type: "danger",
          key: "FAILURE",
        },
        WAITING: {
          type: "warning",
          key: "WAITING",
        },
        INACTIVE: {
          type: "primary",
          key: "INACTIVE",
        },
      },
      pageSize: 20,
      logsVisible: false,
      logsParams: {},
      currentPage: 1,
      test: true,
      statusInfo: "",
      filterArray: [],
      table: {
        data: [],
      },
      filterPopup: false,
      columns: datasetCol,
      searchName: "",
      filterables: [
        { label: "Project Name", key: "project_name", type: "input" },
        { label: "Experiment Name", key: "name", type: "input" },
        { label: "Data Set Name", key: "dataset_name", type: "input" },
        { label: "Target", key: "target", type: "input" },
        {
          label: "Performance Metric",
          key: "performance_metric",
          type: "input",
        },
        {
          label: "Test Score Lower",
          key: "test_score_lower_bound",
          type: "input",
        },
        {
          label: "Test Score Upper",
          key: "test_score_upper_bound",
          type: "input",
        },
        {
          label: "Total Trainin Upper",
          key: "total_training_time_upper_bound",
          type: "input",
        },
        {
          label: "Total Trainin Lower",
          key: "total_training_time_lower_bound",
          type: "input",
        },
        {
          label: "Created At",
          key: ["created_at_lower_bound", "created_at_upper_bound"],
          type: "dateRange",
        },
        {
          label: "Type",
          key: "type",
          type: "select",
          values: ["LIVE", "BATCH"],
        },
        {
          label: "Active",
          key: "active",
          type: "select",
          values: ["true", "false"],
        },

        {
          label: "Status",
          key: "status",
          type: "select",
          values: [
            "PREPROCESSING",
            "TRAINING",
            "TRAINED",
            "TUNING",
            "TUNED",
            "COMPLETED",
            "CREATED",
          ],
        },
      ],
    };
  },
  mounted() {
    this.requestComp = "experimentList";
    this.init();
    this.initFilterArray();
  },
  computed: {
    ...mapGetters({
      experiments: EXPERIMENT_GETTERS.GET_EXPERIMENTS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      experimentsSessionStatus: EXPERIMENT_GETTERS.GET_EXPERIMENTS_SESSION_STATUS,
      pipeline: PROJECT_GETTERS.GET_PIPELINE,
      experimentsFilter: EXPERIMENT_GETTERS.GET_EXPERIMENTS_FILTER,
    }),
    filterList() {
      let list = [];
      Object.keys(this.experimentsFilter).forEach((mdf) => {
        list.push({
          column_name: mdf,
          conditional_operator: "equal",
          value: this.experimentsFilter[mdf],
        });
      });
      return list;
    },
    lastPage() {
      return this.pagination.experiments?.last_page ?? 1;
    },
    totalItem() {
      return this.pagination.experiments?.total_item;
    },
    enhancedExperiments() {
      const activeExperimentsCount = this.experiments.filter((e) => e.active);
      if (activeExperimentsCount.length == 0)
        this.setExperimentSessionStatus([]);

      return this.experiments.map((ex) => {
        const found = this.experimentsSessionStatus.find(
          (ess) => ess.identifier === ex.id
        );

        return {
          ...ex,
          state: found
            ? this.statusTypes[found.status.state]
            : this.statusTypes.INACTIVE,
          created_at: formatDate(
            ex.created_at,
            this.preferences.longDateFormat
          ),
        };
      });
    },
    userPrivileges: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Privileges"];
      },
    },
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    isChallengerExperimentButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_CHALLENGER_EXPERIMENT_BUTTON
      )
    },
    isAuthorizedForResetExperimentButton() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_RESET_EXPERIMENT_BUTTON
      )
    },
    isDeleteExperimentButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DELETE_EXPERIMENT_BUTTON
      )
    },
    isModelGovernanceButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_BUTTON
      )
    },
    isModelLogsButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_LOGS_BUTTON
      )
    },
    isModelResultsButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DISPLAY_MODEL_RESULTS_BUTTON
      )
    },
    isAuthorizedForStartExperimentButton() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_START_REFRESH_EXPERIMENT_BUTTON
      )
    },
  },
  watch: {
    pageSize: {
      handler() {
        this.currentPage = 1;
        this.init();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchExperimentSessionStatus: EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_SESSION_STATUS,
      fetchExperiments: EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS,
      deleteExperiment: PROJECT_ACTIONS.DELETE_EXPERIMENTS,
      clearExperiment: EXPERIMENT_ACTIONS.CLEAR_EXPERIMENTS,
      startSession: PROJECT_ACTIONS.START_SESSION,
      stopSession: PROJECT_ACTIONS.STOP_SESSION,
    }),
    ...mapMutations({
      setExperimentSessionStatus: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_SESSION_STATUS,
      setExperimentsFilter: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_FILTER,
      resetNavigationList: NAVIGATION_MUTATIONS.RESET_NAVIGATION_LIST,
      setNavigationCount: NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST,
      resetProblemConf: MODELS_MUTATIONS.RESET_PROBLEM_CONFIGURATION,
    }),
    isChallengerExperimentActionVisible(row) {
      return row.type === "LIVE" && this.isChallengerExperimentButtonAvailable;
    },
    isChallengerExperimentActionClickable(row) {
      return row.type === "LIVE" && row.status === "COMPLETED";
    },
    getChallengerExperimentActionTooltip(row) {
      return row.type === "LIVE" && row.status !== "COMPLETED"
        ? "Please finish experiment in order to use this functionality."
        : "Challenger Experiment";
    },
    challengerExperimentClicked(row) {
      this.$router.push({
        name: "ChallengerModelResults",
        query: { experimentName: row.name, experimentId: row.id },
      });
    },
    closeLogsModal() {
      this.logsVisible = false;
    },
    initFilterArray() {
      const alreadyFiltered = Object.keys(this.experimentsFilter);
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        if (alreadyFiltered.includes(fs.key)) {
          x = {
            isActive: true,
            data: this.experimentsFilter[fs.key],
            operator: fs.type === "input" ? "equal_input" : "equal_select",
          };
        }

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
    filterSaved(filters) {
      const mappedFilters = mapFilterResult(filters);

      if (Object.keys(mappedFilters).length > 0) {
        this.setExperimentsFilter(mappedFilters);
        this.currentPage = 1;
        this.init();
      } else {
        this.$alert("Please enter a search condition!", "Warning", {
          confirmButtonText: "OK",
        });
      }
    },
    deleteFilter(value) {
      const x = Object.assign({}, this.experimentsFilter);
      delete x[value.column_name];
      this.setExperimentsFilter(x);
      this.filterArray.forEach((element) => {
        if (element.name == value.column_name) {
          element.operator = "";
          element.data = "";
          element.isActive = false;
        }
      });
      this.currentPage = 1;
      this.init();
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
    async init() {
      await this.fetchExperiments({
        page: this.currentPage,
        count: this.pageSize,
        name: this.searchName,
        ...this.sortConfig,
        ...this.experimentsFilter,
      });
      this.fetchSessionStatus();
    },
    fetchSessionStatus() {
      const identifiers = this.experiments
        .filter((ex) => ex.active)
        .map((e) => e.id);

      if (identifiers.length)
        this.fetchExperimentSessionStatus({
          identifiers,
        });
    },
    goGovernance(data) {
      this.$router.push({
        name: "ModelGovernance",
        query: { experimentName: data.name },
      });
    },
    openModelResultsPage(experimentId, experimentName) {
      this.$router.push({
        name: "ModelResultsExperiments",
        query: { experimentId, experimentName },
      });
    },
    goLogs(data) {
      this.logsVisible = true;
      this.logsParams = {
        title: data.name,
        experimentId: data.id,
        selectedRow: data,
      };
    },
    async start(projectId, experimentId) {
      await this.startSession({
        requestComp: this.requestComp,
        projectId,
        experimentId,
      });
      this.init();
    },
    async exploreData(data) {
      //console.log(data);
      this.resetNavigationList();
      this.resetProblemConf();
      //this.routePage("DataPreview", data.id);
      if (data.status == "CREATED") {
        this.routePage("DataPreview", data.id);
      } else if (data.status == "PREPROCESSING") {
        await this.getPipeline(data.id, data.project_id);
        // console.log(this.lastStep);
        // console.log(this.lastStepDetailList);
        // console.log(this.routingName);
        // console.log(this.linkCount);
        this.setNavigationCount(this.linkCount);
        this.routePage(this.routingName, data.id);
      } else if (data.status == "TRAINED") {
        this.setNavigationCount(5);
        this.routePage("ModelResults", data.id);
      } else if (data.status == "COMPLETED") {
        this.setNavigationCount(4);
        this.routePage("ModelSelection", data.id);
      } else if (data.status == "TRAINING") {
        this.setNavigationCount(4);
        this.routePage("ModelRunning", data.id);
      } else if (data.status == "TUNING") {
        this.setNavigationCount(4);
        this.routePage("ModelRunning", data.id);
      } else if (data.status == "TUNED") {
        this.setNavigationCount(6);
        this.routePage("TuneModel", data.id);
      }
    },
    routePage(pageName, id) {
      this.$router.push({
        name: pageName,
        query: {
          experimentId: id,
        },
      });
    },
    async deleteX(projectId, experimentId, page, type) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      await this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t("Experiment")]),
        this.$t("confirmation.Delete Experiment"),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      await this.deleteExperiment({
        requestComp: this.requestComp,
        projectId,
        experimentId,
        page,
        type,
      });
      this.init();
    },
    async clear(payload) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      await this.$confirm(
        this.$t("confirmation.resetExperimentWarning"),
        this.$t("confirmation.Reset Experiment"),
        {
          confirmButtonText: this.$t("confirmation.Reset Experiment"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      await this.clearExperiment(payload);
      this.init();
    },
    async stop(project_id, id, type, username) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (this.username == username) {
        (async () => {
          await this.stopSession({
            requestComp: this.requestComp,
            projectId: project_id,
            experimentId: id,
            type: type,
          });
          this.init();
        })();
      } else {
        this.$confirm(
          this.$t("confirmation.stopExperimentWarning"),
          this.$t("tooltip.stop"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            callback: (confirm) => {
              if (confirm == "confirm") {
                (async () => {
                  await this.stopSession({
                    requestComp: this.requestComp,
                    projectId: project_id,
                    experimentId: id,
                    type: type,
                  });
                  this.init();
                })();
              }
            },
          }
        );
      }
    },
    rowStyleClassFn(row) {
      return row.state.type == "success"
        ? "successColor"
        : row.state.type == "warning"
        ? "warningColor"
        : row.state.type == "danger"
        ? "dangerColor"
        : "infoColor";
    },
    isExperimentStartAvailable(row) {
      return this.isAuthorizedForStartExperimentButton &&
        row.type !== 'CUSTOM' &&
        row.active != true &&
        row.status != 'COMPLETED' &&
        !row.challenger_root_id
    },
    isExperimentPlayAvailable(row) {
      return this.isAuthorizedForStartExperimentButton &&
        row.type !== 'CUSTOM' &&
        row.state.type == 'success' &&
        row.started_by == this.username &&
        !row.challenger_root_id
    },
    isExperimentStopAvailable(row) {
      return this.isAuthorizedForStartExperimentButton &&
        row.type !== 'CUSTOM' &&
        row.active == true &&
        row.status != 'COMPLETED' &&
        !row.challenger_root_id
    },
    isExperimentResetAvailable(row) {
      return this.isAuthorizedForResetExperimentButton &&
        row.type !== 'CUSTOM' &&
        (row.status == 'CREATED' ||
        row.status == 'PREPROCESSING' ||
        row.status == 'TRAINING' ||
        row.active == false)
    }
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-badge__content--primary {
//   background-color: #858585 !important;
// }
</style>
