<template>
  <div class="dtp-section" v-loading="loading" :element-loading-text="$t('helper.loading_data')">
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="dtp-row">
            <div class="dtp-col dtp-col-12 dtp-col-l12">
              <div class="dtp-row">
                <div
                  class="
                    dtp-col dtp-col-12
                    dtp-pa--0
                    dtp-align-center dtp-flex-horizontal-end
                  "
                >
                  <span class="dtp-aml-page-title dtp-mr--auto"
                    >{{ $t("experiment.Data Preparation") }}</span
                  >
                  <div class="dtp-panel-formbox">
                    <span class="dtp-aml-font-bold dtp-fcolor-333333"
                      >{{ $t("experiment.Total Data Size") }}: {{ formatSize(datasetData.size) }}</span
                    >
                  </div>
                  <div class="dtp-panel-formbox">
                    <span class="dtp-aml-font-bold dtp-fcolor-333333"
                      >{{ $t("table.columns") }}: {{ datasetData.column }}</span
                    >
                  </div>
                  <div class="dtp-panel-formbox">
                    <span class="dtp-aml-font-bold dtp-fcolor-333333"
                      >{{ $t("table.rows") }}: {{ datasetData.row }}</span
                    >
                  </div>
                  <RefreshTable
                    :loading="loading"
                    @refreshTable="refreshTable"
                  />
                </div>
              </div>
            </div>
            <div
              class="dtp-col dtp-col-12 vgt-custom-colon dtp-freeze-header"
            >
              <vue-good-table
                :columns="dataColumns"
                :rows="rawData"
                mode="remote"
                @on-sort-change="(val) => onSortChange(experimentId, val)"
              >
                <template slot="table-row"> </template>
              </vue-good-table>
              <CustomPagination
                :lastPage="lastPage"
                :pageSize.sync="pageSize"
                :showPageSizeSelect="true"
                @change="changePage"
              />
            </div>
          </div>
        </div>
      </pane>

      <pane min-size="30">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="dtp-pageviewtopbar">
            <el-collapse
              v-model="activeCollapseItem"
              accordion
              style="margin-bottom: 30px; font-weight: bold"
            >
              <el-collapse-item
                :title="$t('Types of Machine Learning Problem')"
                name="1"
              >
                <data-preview-cards
                  :experimentId="experimentId"
                  @checkImbalanceDataControl="checkImbalanceDataControl"
                />
              </el-collapse-item>
            </el-collapse>
            <div
              v-if="
                !activeCollapseItem &&
                !!Object.keys(getImbalanceInspection).length
              "
            >
              <div
                v-if="!imbalanceInspection.is_balanced"
                style="margin-bottom: 30px; padding-left: 16px"
              >
                {{ $t("experiment.Unequal class distribution detected in selected target", [target]) }}
              </div>
              <div
                v-if="imbalanceInspection.is_balanced"
                style="margin-bottom: 30px; padding-left: 16px"
              >
                {{ $t("experiment.Equal class distribution detected in selected target", [target]) }}
              </div>

              <div>
                <div
                  class="classDistributionTableArea"
                  style="margin-bottom: 30px; width: 280px; margin-left: 16px"
                >
                  <el-table :data="classDistributionTableData" stripe>
                    <el-table-column prop="class_names" :label="$t('experiment.class names')">
                    </el-table-column>
                    <el-table-column
                      prop="class_distribution"
                      :label="$t('experiment.class distribution')"
                    >
                    </el-table-column
                  ></el-table>
                </div>
              </div>

              <data-preview-imbalance-cards/>
              
            </div>
          </div>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { formatBytes } from "../../../helper/format";
import privileges from "../../../mixins/privileges";
import checkAuth from "../../../mixins/authorization";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import CustomPagination from "../CustomPagination";
import notify from "../../../mixins/AutoML/notify";
import liveData from "../../../mixins/AutoML/liveData";
import { Splitpanes, Pane } from "splitpanes";
import DataPreviewCards from "./DataPreviewCards";
import DataPreviewImbalanceCards from "./DataPreviewImbalanceCards";
import { refreshOperationStatus, checkIfOperationStatusIsAvailable, CALL_REASONS } from '../../../helper/asyncStatusChecker';
import RefreshTable from "../Helper/RefreshTable.vue";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_ACTIONS, DATASET_GETTERS, DATASET_MUTATIONS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [checkAuth, privileges, notify, liveData],
  data() {
    return {
      activeCollapseItem: "1",
      pageSize: 20,
      currentPage: 1,
      table: {
        data: [],
      },
      filterPopup: false,
      data: {
        problem_type: "classification",
        target: "",
        stratify: false,
        train_ratio: 80,
        cross_validation_number_of_folds: null,
      },
    };
  },
  components: {
    VueGoodTable,
    CustomPagination,
    Splitpanes,
    Pane,
    DataPreviewCards,
    DataPreviewImbalanceCards,
    RefreshTable
  },
  created() {
    this.setLoading(true);
  },
  async mounted() {
    this.resetImbalance();
    this.inspectImbalance({});
    this.setBalanceConfiguration({});
    this.resetProblemConfiguration();
    this.resetDataset();
    this.setData([]);
    this.requestComp = "basic_transformation";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )
  },
  watch: {
    pageSize: {
      handler(val) {
        this.fetchData({
          experiment_id: this.experimentId,
          page: this.currentPage,
          count: val,
        });
      },
    },
    resetImbalanceCount() {
      setTimeout(() => {
        this.activeCollapseItem = "1";
      }, 1000);
    },
  },
  methods: {
    checkImbalanceDataControl() {
      this.activeCollapseItem = "";
    },
    formatSize(size) {
      return formatBytes(size);
    },
    ...mapActions({
      fetchExplorations: DATA_ACTIONS.FETCH_EXPLORATIONS,
      fetchData: DATA_ACTIONS.FETCH_DATA,
      getDatasetById: DATASET_ACTIONS.GET_DATASET_BY_ID,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
    }),
    ...mapMutations({
      setProblemConfigurationParams: MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION_PARAMS,
      setData: DATA_MUTATIONS.SET_DATA,
      resetDataset: DATASET_MUTATIONS.RESET_DATASET,
      resetProblemConfiguration: MODELS_MUTATIONS.RESET_PROBLEM_CONFIGURATION,
      resetImbalance: DATA_MUTATIONS.RESET_IMBALANCE,
      inspectImbalance: DATA_MUTATIONS.INSPECT_IMBALANCE,
      setBalanceConfiguration: MODELS_MUTATIONS.SET_BALANCE_CONFIGURATION,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    stratifyChanged(value) {
      this.data.stratify = value;
    },
    changePage(page) {
      this.fetchData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        page: page,
        count: this.pageSize,
      });
      this.currentPage = page;
    },
  },
  computed: {
    ...mapGetters({
      datasetName: DATASET_GETTERS.GET_DATASET_NAME,
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      rawData: DATA_GETTERS.GET_DATA,
      datasetData: DATASET_GETTERS.GET_DATASET,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      problem_configuration: MODELS_GETTERS.GET_PROBLEM_CONFIGURATION_PARAMS,
      imbalanceInspection: DATA_GETTERS.GET_IMBALANCE_INSPECTION,
      resetImbalanceCount: DATA_GETTERS.GET_RESET_IMBALANCE_COUNT,
      getImbalanceInspection: DATA_GETTERS.GET_IMBALANCE_INSPECTION,
      loading: GENERAL_GETTERS.GET_LOADING
    }),
    classDistributionTableData() {
      const res = [];

      if (this.imbalanceInspection?.class_distribution)
        Object.keys(this.imbalanceInspection.class_distribution).forEach((cd) =>
          res.push({
            class_names: cd,
            class_distribution: this.imbalanceInspection.class_distribution[cd],
          })
        );

      return res;
    },
    target: {
      get() {
        return this.problem_configuration.target;
      },
    },
    userPrivileges: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Privileges"];
      },
    },
    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
    dataColumns: {
      get() {
        let result = this.explorations.map((exp) => ({
          label: exp.name,
          field: exp.name,
        }));
        return result;
      },
    },
    lastPage() {
      return Math.ceil(this.datasetData.row / this.pageSize);
    },
  },
};
</script>

<style scoped>
.dtp-custom-notify {
  position: fixed;
  right: 20px;
  top: 20px;
  padding: 20px 40px;
  width: auto;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  font-weight: bold;
  z-index: 9999;
}

::v-deep .el-collapse-item__wrap {
  background: transparent;
  border: 0;
}

::v-deep .el-collapse-item__header {
  padding-left: 16px;
  background: transparent;
}

::v-deep .el-collapse {
  border-top: 0;
  border-bottom: 2px solid #333333;
  border: 0;
}

::v-deep .el-table__row td {
  padding: 0 !important;
}
::v-deep .el-table__header th {
  padding: 0 !important;
  color: black;
}

::v-deep .el-collapse-item__header {
  font-weight: bold;
  font-size: 16px;
}
</style>
