<template>
    <span :class="['dtp-icon-wrapper', this.size]" @click="handleClick">
        <component :is="selectedIconComponent"></component>
    </span>
</template>
<script>
import { iconComponentMap } from "./iconComponentMap";

export default {
  props: {
    icon: {
      type: String,
      required: true,
      // TODO: check eslint for build time error when validator is invalid
      validator: (value) => {
        const icon = iconComponentMap[value];
        return icon ? true : false;
      }
    },
    // sm: 12px, md: 16px, lg: 20px, xl: 24px
    size: {
      type: String, 
      default: "md"
    }
  },
  computed: {
    selectedIconComponent() {
      const component = iconComponentMap[this.icon];

      if (!component)
        throw new Error(`Icon not found. Recheck iconComponentMap variable if icon '${this.icon}' exists.`);

      return component;
    }
  },
  methods: {
    handleClick() {
      this.$emit("onClick");
    }
  }
};
</script>
<style>
.dtp-icon-wrapper svg,
.dtp-icon-wrapper path,
.dtp-icon-wrapper rect { 
  color: currentColor;
  fill: currentColor;
}
.dtp-icon-wrapper svg {
  width: 100%;
  height: 100%;
}
.dtp-icon-wrapper.sm,
.dtp-icon-wrapper.sm svg {
  width: 12px;
  height: 12px;
}
.dtp-icon-wrapper.md,
.dtp-icon-wrapper.md svg {
  width: 16px;
  height: 16px;
}
.dtp-icon-wrapper.lg,
.dtp-icon-wrapper.lg svg {
  width: 20px;
  height: 20px;
}
.dtp-icon-wrapper.xl,
.dtp-icon-wrapper.xl svg {
  width: 24px;
  height: 24px;
}
</style>
<style scoped>
.dtp-icon-wrapper {
    color: currentColor;
}
</style>
