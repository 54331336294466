import { formatDate } from "../../../helper/format";

const OperatorType = {
    INPUT: 'input',
    NOTHING: 'nothing'
}

export const OperatorKey = {
    EQUAL: "EQUAL",
    NOT_EQUAL: "NOT_EQUAL",
    IN: "IN",
    NOT_IN: "NOT_IN",
    GREATER_THAN: "GREATER_THAN",
    GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
    LESS_THAN: "LESS_THAN",
    LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
    IS_NULL: "IS_NULL",
    IS_NOT_NULL: "IS_NOT_NULL",
    STARTS_WITH: "STARTS_WITH",
    ENDS_WITH: "ENDS_WITH",
    BETWEEN: "BETWEEN",
}

const OperatorLabel = {
    EQUAL: "Equal",
    NOT_EQUAL: "Not Equal",
    IN: "In",
    NOT_IN: "Not In",
    GREATER_THAN: "Greater Than",
    GREATER_THAN_OR_EQUAL: "Greater Than or Equal",
    LESS_THAN: "Less Than",
    LESS_THAN_OR_EQUAL: "Less Than or Equal",
    IS_NULL: "Is Null",
    IS_NOT_NULL: "Is Not Null",
    STARTS_WITH: "Starts With",
    ENDS_WITH: "Ends With",
    BETWEEN: "Between",
}

export const NumericOperators = [
    { label: OperatorLabel.EQUAL, key: OperatorKey.EQUAL, type: OperatorType.INPUT },
    { label: OperatorLabel.NOT_EQUAL, key: OperatorKey.NOT_EQUAL, type: OperatorType.INPUT },
    { label: OperatorLabel.IN, key: OperatorKey.IN, type: OperatorType.INPUT, tooltip: '123,123.4' },
    { label: OperatorLabel.NOT_IN, key: OperatorKey.NOT_IN, type: OperatorType.INPUT, tooltip: '123,123.4' },
    { label: OperatorLabel.GREATER_THAN, key: OperatorKey.GREATER_THAN, type: OperatorType.INPUT },
    {
        label: OperatorLabel.GREATER_THAN_OR_EQUAL,
        key: OperatorKey.GREATER_THAN_OR_EQUAL,
        type: OperatorType.INPUT,
    },
    { label: OperatorLabel.LESS_THAN, key: OperatorKey.LESS_THAN, type: OperatorType.INPUT },
    {
        label: OperatorLabel.LESS_THAN_OR_EQUAL,
        key: OperatorKey.LESS_THAN_OR_EQUAL,
        type: OperatorType.INPUT,
    },
];

export const CategoricalOperators = [
    { label: OperatorLabel.EQUAL, key: OperatorKey.EQUAL, type: OperatorType.INPUT },
    { label: OperatorLabel.NOT_EQUAL, key: OperatorKey.NOT_EQUAL, type: OperatorType.INPUT },
    { label: OperatorLabel.IN, key: OperatorKey.IN, type: OperatorType.INPUT, tooltip: '"abc","def"' },
    { label: OperatorLabel.NOT_IN, key: OperatorKey.NOT_IN, type: OperatorType.INPUT, tooltip: '"abc","def"' },
    { label: OperatorLabel.IS_NULL, key: OperatorKey.IS_NULL, type: OperatorType.NOTHING },
    { label: OperatorLabel.IS_NOT_NULL, key: OperatorKey.IS_NOT_NULL, type: OperatorType.NOTHING },
    { label: OperatorLabel.STARTS_WITH, key: OperatorKey.STARTS_WITH, type: OperatorType.INPUT },
    { label: OperatorLabel.ENDS_WITH, key: OperatorKey.ENDS_WITH, type: OperatorType.INPUT },
];

export const DateRangeOperators = [
    { label: OperatorLabel.BETWEEN, key: OperatorKey.BETWEEN, type: OperatorType.INPUT, tooltip: "" },
];

// TODO: create a parser object that will call seperate actions that will depend on type of the filter.
export const mapFilterResult = (filters) => {
    const mappedFilters = {};

      (filters || [])
        .filter((v) => v.isActive && v.data && v.operator)
        .forEach((af) => {
          if (af.type === "dateRange") {
            af.name.forEach((name, index) => {
              mappedFilters[name] = formatDate(af.data[index], "YYYY-MM-DD HH:mm:ss.000000");
            });
          } else {
            mappedFilters[af.name] = af.data;
          }
        });

    return mappedFilters;
}