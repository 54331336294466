<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("Create New " + `${title}`) }} 
      <div
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12 dtp-chart-box-20">
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t(`${title}`) + ' ' + $t('name')"
                class="eds-input_#input"
                maxlength="99"
                v-model="newTagName"
              />
            </div>
            <div
              v-if="!$v.newTagName.maxLength"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
            {{ $t("validation.x can not be longer than y characters", [$t("Name"), $v.newTagName.$params.maxLength.max + 1]) }}
            </div>
            <div
              v-if="!$v.newTagName.required && $v.newTagName.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("validation.x can not be empty", [$t("Name")]) }}
            </div>
          </div>
          <div class="eds-field_#control eds-field_.eds-input eds-input h-128">
            <textarea
              :placeholder="$t('Description')"
              class="eds-input_#input"
              maxlength="500"
              v-model="newDescription"
            ></textarea>
          </div>
          <div
            v-if="!$v.newDescription.maxLength"
            class="eds-field_#assistive-text eds-field_#assistive-text.error"
          >
          {{ $t("validation.x can not be longer than y characters", [$t("Description"), $v.newDescription.$params.maxLength.max + 1]) }}
          </div>
        </div>
        <div class="dtp-col dtp-col-12 dtp-text--right">
          <button
            @click="checkFieldsAndCreate"
            class="eds-button eds-button.basic"
          >
            {{ $t("buttons.create") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="vuelidate/dist/vuelidate.min.js"></script>
<script>
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  validations: {
    newTagName: {
      required,
      maxLength: maxLength(98),
    },
    newDescription: {
      maxLength: maxLength(499),
    },
  },
  data() {
    return {
      newTagName: "",
      newDescription: "",
    };
  },
  methods: {
    checkFieldsAndCreate() {
      this.$v.newTagName.$touch();
      this.$v.newDescription.$touch();

      if (!this.$v.$invalid) {
        this.$emit("create", {
          name: this.newTagName,
          description: this.newDescription,
        });
      }
    },
  },
};
</script>
