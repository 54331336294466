var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dtp-section"},[_c('div',{staticClass:"dtp-pageviewtopbar"},[_c('div',{staticClass:"dtp-row dtp-align-center"},[_c('div',{staticClass:"dtp-col dtp-col-2 dtp-col-xl3"},[_c('div',{staticClass:"dtp-panel-formbox"},[_c('span',{staticClass:"dtp-aml-page-title"},[_vm._v(_vm._s(_vm.$t("automl.featureListTitle")))])])]),_c('div',{staticClass:"dtp-col dtp-col-2 dtp-col-xl3 dtp-aml-multi-select"},[_c('multiselect',{attrs:{"options":_vm.availableOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":false,"placeholder":_vm.$t('placeholder.Choose Columns'),"label":"name","track-by":"name","preselect-first":false},on:{"input":function($event){return _vm.changeStatus()}},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" "+_vm._s(_vm.$t("automl.statusSelected")))]):_vm._e()]}}]),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('div',{staticClass:"dtp-col dtp-col-6 dtp-col-xl6 dtp-pa--0"},[_c('button',{staticClass:"eds-button eds-button.basic",attrs:{"disabled":!_vm.isActive},on:{"click":function($event){return _vm.excludeFeatures()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.excludefeatures"))+" ")])]),_c('div',{staticClass:"dtp-col dtp-col-2 dtp-text--right"},[_c('RefreshTable',{attrs:{"loading":_vm.loading},on:{"refreshTable":_vm.refreshTable}})],1)])]),_c('div',{staticClass:"dtp-row"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dtp-col dtp-col-12 dtp-freeze-header",attrs:{"element-loading-text":_vm.$t('helper.loading_data')}},[_c('vue-good-table',{attrs:{"columns":_vm.featureColsProcessed,"rows":_vm.featureRow,"row-style-class":_vm.rowStyleClassFn,"select-options":{
          enabled: true,
          disableSelectInfo: true,
          selectOnCheckboxOnly: true,
        }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("table.header." + props.column.label)))])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'importance')?_c('span',[_c('span',[(
                  props.row.importance != 'HIGH' &&
                  props.row.importance != 'LOW' &&
                  props.row.importance != 'MEDIUM'
                )?_c('div',{staticClass:"dtp-table-status-icon"}):_c('div',[_c('div',{staticClass:"dtp-table-status-icon"})])])]):_vm._e()]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }