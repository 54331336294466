<template>
  <div class="dtp-row">
    <div class="dtp-col dtp-col-12 dtp-chart-box-20">
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <select
          v-model="dataset"
          class="eds-input_#input eds-input_#input.dropdown"
        >
          <option value="" disabled>{{
            $t("dataset.please_select_dataset")
          }}</option>
          <option
            v-for="item in datasets"
            :key="item.value"
            :value="item.value"
          >
            {{ $t(`${item.label}`) }}
          </option>
        </select>
      </div>
    </div>
    <div class="dtp-col dtp-col-12 dtp-text--right">
      <button
        v-if="showBackButton"
        @click="$emit('back')"
        class="eds-button eds-button.basic dtp-mr--1"
      >
        {{ $t("commons.back") }}
      </button>

      <button
        @click="continueSelectDataSets()"
        class="eds-button eds-button.basic"
      >
        {{ $t("buttons.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";

export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataset: "",
      datasets: [
        {
          value: "LocalFile",
          label: "File From Disk",
        },
        {
          value: "JdbcConnection",
          label: "Relational Database",
        },
      ],
    };
  },
  methods: {
    continueSelectDataSets() {
      if (this.dataset == "") {
        MessageBox.alert(this.$t("dataSource.Please choose a data source"), this.$t("alert.warning"), {
          confirmButtonText: this.$i18n.t("alert.ok"),
        });
      } else if (this.dataset == "LocalFile") {
        this.$router.push({ name: "CsvUploadAmL" });
      } else {
        this.$store.dispatch("Query/Set/FormData/Sql", "");
        this.$store.dispatch("Query/Set/FormData/Name", "");
        this.$router.push({ name: "NewEditViewquery" });
      }
    },
  },
};
</script>
<style></style>
