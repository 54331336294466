import { PROJECT_ACTIONS, PROJECT_MUTATIONS, PROJECT_GETTERS } from "./types";
import { GENERAL_MUTATIONS } from "../General/types";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { sortControl } from "../../../../api/AutoMlServices/utils";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";

export const projectsStore = {
    state: {
      selectedProject: {},
      pipeline: {},
      selectedProjectId: 0,
      projects: [],
      projectsFilter: {},
    },
    getters: {
      [PROJECT_GETTERS.GET_PROJECTS]: (state) => {
        return state.projects;
      },
      [PROJECT_GETTERS.GET_SELECTED_PROJECT_ID]: (state) => {
        return state.selectedProjectId;
      },
      [PROJECT_GETTERS.GET_PIPELINE]: (state) => {
        return state.pipeline;
      },
      [PROJECT_GETTERS.GET_SELECTED_PROJECT]: (state) => {
        return state.selectedProject;
      },
      [PROJECT_GETTERS.GET_PROJECTS_FILTER]: (state) => {
        return state.projectsFilter;
      },
    },
    mutations: {
        [PROJECT_MUTATIONS.SET_PROJECTS](state, items) {
          Vue.set(state, "projects", items);
        },
        [PROJECT_MUTATIONS.SET_SELECTED_PROJECT_ID]: (state, val) => {
          state.selectedProjectId = val;
        },
        [PROJECT_MUTATIONS.SET_PIPELINE]: (state, items) => {
          Vue.set(state, "pipeline", items);
        },
        [PROJECT_MUTATIONS.SET_SELECTED_PROJECT]: (state, val) => {
          Vue.set(state, "selectedProject", val);
        },
        [PROJECT_MUTATIONS.SET_PROJECTS_FILTER](state, filterObj) {
          Vue.set(state, "projectsFilter", filterObj);
        },
    },
    actions: {
        [PROJECT_ACTIONS.FETCH_PROJECTS]: async ({ commit }, params) => {
            const { page = 1, count = 20, ...others } = params;
            const controlledParams = sortControl({ page, count, ...others });
            await vuexActionHandler({
              commit,
              serviceMethodParent: serviceMethodParent.projects,
              serviceMethodSub: serviceMethodSub.getProjectList,
              queryParam: controlledParams,
              successMutation: PROJECT_MUTATIONS.SET_PROJECTS,
              withSuccessNotify: false,
              resultSelector: (result) => result.data?.data,
              successCallback: (result) => {
                commit(GENERAL_MUTATIONS.SET_PAGINATION, {
                  tableName: "projects",
                  pageInfo: result.data.pagination_info,
                });
              }
            });
        },
        async [PROJECT_ACTIONS.DELETE_PROJECT_BY_ID]({ commit, dispatch }, { requestComp, id, page, count }) {
            await vuexActionHandler({
              commit,
              serviceMethodParent: serviceMethodParent.projects,
              serviceMethodSub: serviceMethodSub.deleteProject,
              queryParam: id,
              loadingComponent: requestComp,
              resultSelector: (result) => result.data,
              successCallback: () => {
                dispatch(PROJECT_ACTIONS.FETCH_PROJECTS, { page, count });
              }
            });
        },
        async [PROJECT_ACTIONS.CREATE_PROJECT]({ commit, dispatch }, { requestComp, data, page, count }) {
            await vuexActionHandler({
              commit,
              serviceMethodParent: serviceMethodParent.projects,
              serviceMethodSub: serviceMethodSub.createProject,
              bodyParam: data,
              successMutation: PROJECT_MUTATIONS.SET_SELECTED_PROJECT_ID,
              loadingComponent: requestComp,
              resultSelector: (result) => result.data?.data,
              successCallback: () => {
                dispatch(PROJECT_ACTIONS.FETCH_PROJECTS, { page, count });
              }
            });
        },
        async [PROJECT_ACTIONS.FETCH_PIPELINE]({ commit }, { requestComp, project_id, experiment_id, withLoading }) {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.getPipeline,
            queryParam: { projectId: project_id, experimentId: experiment_id },
            successMutation: PROJECT_MUTATIONS.SET_PIPELINE,
            loadingComponent: requestComp,
            withSuccessNotify: false,
            withLoading,
            resultSelector: (result) => result.data
          });
        },
        async [PROJECT_ACTIONS.CREATE_EXPERIMENTS]({ commit }, params) {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.createExperiment,
            queryParam: params.projectId,
            bodyParam: params.data,
            loadingComponent: params.requestComp
          });
        },
        [PROJECT_ACTIONS.GET_PROJECT_BY_PROJECT_ID]: async ({ commit }, projectId) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.getProjectByProjectId,
            queryParam: projectId,
            successMutation: PROJECT_MUTATIONS.SET_SELECTED_PROJECT,
            withSuccessNotify: false,
            resultSelector: (result) => result.data?.data
          });
        },
        [PROJECT_ACTIONS.UPDATE_DESCRIPTION_BY_PROJECT_ID]: async ({ commit }, { requestComp, projectId, descriptionData }) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.updateDescriptionByProjectId,
            queryParam: projectId,
            bodyParam: descriptionData,
            loadingComponent: requestComp,
          });
        },
        [PROJECT_ACTIONS.DELETE_EXPERIMENTS]: async ({ commit }, params) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.deleteExperiment,
            queryParam: { projectId: params.projectId, experimentId: params.experimentId },
            loadingComponent: params.requestComp,
          });
        },
        [PROJECT_ACTIONS.START_SESSION]: async ({ commit }, params) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.startSession,
            queryParam: { projectId: params.projectId, experimentId: params.experimentId },
            bodyParam: {},
            loadingComponent: params.requestComp,
          });
        },
        [PROJECT_ACTIONS.STOP_SESSION]: async ({ commit }, params) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.stopSession,
            queryParam: { projectId: params.projectId, experimentId: params.experimentId },
            loadingComponent: params.requestComp,
          });
        },
        [PROJECT_ACTIONS.COMPLETE_EXPERIMENT]: async ({ commit }, { requestComp, projectId, experimentId }) => {
          await vuexActionHandler({
            commit,
            serviceMethodParent: serviceMethodParent.projects,
            serviceMethodSub: serviceMethodSub.completeExperiment,
            queryParam: { projectId, experimentId },
            bodyParam: {},
            loadingComponent: requestComp,
          });
        },
        [PROJECT_ACTIONS.SET_SELECTED_PROJECT]: ({ commit }, val) => {
          commit(PROJECT_MUTATIONS.SET_SELECTED_PROJECT, val);
        },
    }
}