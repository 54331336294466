<template>
  <svg
    id="model-governance-cog-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="22" height="22" stroke="none" />
      <rect x="0.5" y="0.5" width="21" height="21" fill="none" />
    </g>
    <path
      id="Path_cog-outline"
      data-name="Path / cog-outline"
      d="M11,7.4A3.6,3.6,0,1,1,7.411,11,3.594,3.594,0,0,1,11,7.4m0,1.8A1.8,1.8,0,1,0,12.794,11,1.8,1.8,0,0,0,11,9.2M9.206,20a.454.454,0,0,1-.449-.378l-.332-2.385a6.227,6.227,0,0,1-1.516-.891l-2.234.909a.45.45,0,0,1-.547-.2L2.333,13.943a.445.445,0,0,1,.108-.576l1.893-1.494L4.271,11l.063-.9L2.441,8.633a.445.445,0,0,1-.108-.576L4.127,4.943a.44.44,0,0,1,.547-.2l2.234.9a6.5,6.5,0,0,1,1.516-.882l.332-2.385A.454.454,0,0,1,9.206,2h3.589a.454.454,0,0,1,.449.378l.332,2.385a6.5,6.5,0,0,1,1.516.882l2.234-.9a.44.44,0,0,1,.547.2l1.794,3.114a.445.445,0,0,1-.108.576L17.666,10.1l.063.9-.063.9,1.893,1.467a.445.445,0,0,1,.108.576l-1.794,3.114a.44.44,0,0,1-.547.2l-2.234-.9a6.5,6.5,0,0,1-1.516.882l-.332,2.385a.454.454,0,0,1-.449.378H9.206M10.327,3.8,10,6.149a4.925,4.925,0,0,0-2.719,1.6L5.114,6.815l-.673,1.17L6.334,9.38a5.01,5.01,0,0,0,0,3.24l-1.9,1.4.673,1.17,2.18-.936a4.881,4.881,0,0,0,2.7,1.584l.332,2.358h1.364l.332-2.349a4.948,4.948,0,0,0,2.7-1.593l2.18.936.673-1.17-1.9-1.395a5.037,5.037,0,0,0,0-3.249l1.893-1.395-.673-1.17-2.162.936A4.86,4.86,0,0,0,12,6.158L11.673,3.8Z"
      transform="translate(-0.271)"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
