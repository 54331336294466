<template>
  <svg
    id="experiments-flask-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="24" height="24" stroke="none" />
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path
      id="Path_flask-outline"
      data-name="Path / flask-outline"
      d="M5,19a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1,1.015,1.015,0,0,0-.18-.57L13,8.35V4H11V8.35L5.18,18.43A1.015,1.015,0,0,0,5,19m1,3a3,3,0,0,1-3-3,2.88,2.88,0,0,1,.5-1.63L9,7.81V6A1,1,0,0,1,8,5V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V5a1,1,0,0,1-1,1V7.81l5.5,9.56A2.88,2.88,0,0,1,21,19a3,3,0,0,1-3,3H6m7-6,1.34-1.34L16.27,18H7.73l2.66-4.61L13,16"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
