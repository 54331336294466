 <template>
  <div>
    <input
      class="dtp-vgt-table-form-input dtp-input"
      :type="inputType"
      :placeholder="cellPlaceholder"
      :class="{
        'dtp-vgt-table-form-input-active': editState,
      }"
      :disabled="!editState"
      v-model="rowData[fieldName]"
      required
      @click.stop
    />
    <img
      v-if="!editState"
      @click.stop="editable ? (editState = true) : null"
      src="../../../assets/images/icons/automl-edit-icon.svg"
      class="dtp-vgt-actions-icon dtp-float--right dtp-pr--1"
      :class="!editable ? 'dtp-cursor-not' : 'dtp-cursor-pointer'"
      alt="editIcon"
    />
    <span
      v-else
      class="
        dtp-cursor-pointer
        dtp-float--right
        dtp-font-bold dtp-span
        dtp-pr--1
      "
      @click.stop="onApplyClick"
      >{{ $t('alert.ok') }}</span
    >
  </div>
</template>
<script>
import "vue-good-table/dist/vue-good-table.css";
import { cellInfo as cellInfos } from "./cellTypes";

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    cellPlaceholder: {
      type: String,
    },
    inputType: {
      type: String,
    },
    fieldName: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rowData: {},
      editState: false,
    };
  },
  watch: {
    row: {
      deep: true,
      handler() {
        this.setRowData();
      },
    },
  },
  mounted() {
    this.setRowData();
  },
  computed: {
    cellInfo() {
      return cellInfos;
    },
  },
  methods: {
    setRowData() {
      this.rowData = JSON.parse(JSON.stringify(this.row));
    },
    onApplyClick() {
      this.$emit("applyClicked", this.rowData);
      this.editState = false;
    },
  },
};
</script>
<style scoped>
.dtp-input {
  display: inline-block;
  width: 80%;
}
.dtp-span {
  position: relative;
  top: 7px;
}
</style>