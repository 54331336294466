<template>
  <div>
    <div v-for="(attachment, index) in attachments" :key="index">
      <div class="dtp-row dtp-mt--1">
        <div class="dtp-col dtp-col-8 dtp-pa--0">
          <span @click="downloadAudit(attachment.path)" class="hrefStr dtp-cursor-pointer">{{
            attachment.name
          }}</span>
        </div>
        <div class="dtp-col dtp-col-4 dtp-pa--0 helperArea">
          <span>{{ attachment.date }}</span>
          <span>{{ attachment.reviewer }}</span>
          <!-- attachment actions -->
          <!--<div>
            <img
              src="../../../assets/images/icons/automl-delete-icon.svg"
              @click.prevent="() => null"
              alt="visualize"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
            />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions} from "vuex";
import { MODELS_ACTIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  props: {
    attachments: {
      type: Array,
      required: true,
    },
    requestComp: {
      type: String,
      required: true,
    },
  },
  methods: {
     ...mapActions({
     getDownloadAudit:MODELS_ACTIONS.GET_DOWNLOAD_AUDIT
    }),
    async downloadAudit(filePath) {
      const minioPath = await this.getDownloadAudit({ filePath, requestComp: this.requestComp });
        if (minioPath) {
          window.open(minioPath);
        }
    }
  },
};
</script>
<style scoped>
.hrefStr {
  text-decoration: underline;
  font: normal normal normal 13px/15px Roboto;
  letter-spacing: 0px;
  color: #436da9;
}
.helperArea {
  margin-top: 10px;
  column-gap: 10px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  font: normal normal normal 12px/14px Roboto;
  color: #8c8c8c;
}
</style>
