import { MessageBox } from "element-ui";
import i18n from '../i18n';

export const areFieldsValid = (requiredFields, formData) => {
  const missingFields = [];

  for (const f of requiredFields) {
    if (formData[f.value] === "") {
      missingFields.push(i18n.t(f.label));
    }
  }

  if (missingFields.length) {
    MessageBox.alert(
      i18n.t(
        missingFields.length > 1
          ? "alert.fields_are_missing"
          : "alert.field_is_missing",
        [missingFields.join(", ")]
      ),
      "",
      {
        confirmButtonText: i18n.t("alert.ok"),
        type: "warning",
        dangerouslyUseHTMLString: true,
      }
    );
    return false;
  }

  return true;
}

export const trimFormData = (formData) => {
  for (const f in formData) {
    if (formData[f] && typeof formData[f].trim === "function") {
      formData[f] = formData[f].trim();
    }
  }
}