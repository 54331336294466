<template>
  <div>
    <div class="dtp-autoMl-welcome">
      <div class="dtp-connection">
        <div class="dtp-connection-title dtp-text--center">Connection</div>
        <div class="dtp-connection-content">
          <div class="dtp-row">
            <div class="dtp-col dtp-col-12 dtp-text--center dtp-mb--2">
              <div class="dtp-aml-tab-bg">
                <button
                  @click="selectedComponent = components.DATA_SOURCE"
                  class="dtp-aml-tab-btn"
                  :class="{
                    active: selectedComponent == components.DATA_SOURCE,
                  }"
                >
                  Data Source
                </button>
                <button
                  @click="selectedComponent = components.CONFIGURATION"
                  class="dtp-aml-tab-btn"
                  :class="{
                    active: selectedComponent == components.CONFIGURATION,
                  }"
                >
                  Configuration
                </button>
              </div>
            </div>
          </div>
          <component :is="selectedComponent"> </component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import DataSource from "./CreateDataSource/DataSource.vue";
import Configuration from "./CreateDataSource/Configuration";
import { mapActions } from "vuex";
import { PROJECT_ACTIONS } from '../../../store/modules/AutoML/Projects/types';
export default {
  components: {
    DataSource,
    Configuration,
  },
  data() {
    return {
      projectName: "",
      selectedComponent: "DataSource",
      components: {
        DATA_SOURCE: "DataSource",
        CONFIGURATION: "Configuration",
      },
    };
  },
  methods: {
    ...mapActions({
      setSelectedProject: PROJECT_ACTIONS.SET_SELECTED_PROJECT,
    }),
    createProject() {
      if (this.projectName == "") {
        MessageBox.alert(this.$t("validation.Project name can't be empty"), this.$t("alert.error"), {
          confirmButtonText: this.$i18n.t("alert.ok"),
        });
      } else {
        this.setSelectedProject({ name: this.projectName });
        this.$router.push({
          name: "ProjectDetails",
          query: { name: this.projectName },
        });
      }
    },
  },
};
</script>
<style>
.dtp-connection {
  position: relative;
  width: 436px;
  height: auto;
  margin: 0;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  color: #515f67;
}
.dtp-connection-title {
  position: relative;
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  background-color: #f5f7f7;
  border-bottom: 1px solid #d6d6d6;
}
.dtp-connection-content {
  position: relative;
  width: 100%;
  margin: 0;
  margin-bottom: 30px;
  padding: 0 50px;
  font-size: 12px;
  color: #515f67;
}
.dtp-connection-content > div > div > p {
  font-size: 12px;
  line-height: 20px;
  color: #515f67;
}
.el-message-box .el-button {
  border-radius: 3px !important;
}
.dtp-aml-tab-bg {
  position: relative;
  width: 100%;
  padding: 0 5px;
  height: 32px;
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dtp-aml-tab-btn {
  position: relative;
  padding: 5px 25px;
  color: #436da9;
  font-size: 12px;
  border: none;
  border-radius: 16px;
  background-color: transparent;
  cursor: pointer;
}
.dtp-aml-tab-btn.active {
  background-color: #436da9;
  color: #ffffff;
}
</style>
