<template>
  <div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12 dtp-pa--0">
        <div class="dtp-chart-box" v-if="!autoMl">
          <el-select
            v-if="!editMode"
            v-model="selected"
            :placeholder="$t('dataset.please_select_dataset')"
            filterable
          >
            <el-option
              v-for="data in initialTableData"
              :key="data.id"
              :value="data.id"
              :label="data.name"
            ></el-option>
          </el-select>
          <ul class="dtp-query-dataSource--List" v-if="editMode">
            <li>
              <span>
                {{ selectedDataSource.name }}
                <i class="el-icon-arrow-right dtp-float--right"></i>
              </span>
            </li>
          </ul>
        </div>
        <div class="dtp-chart-box" v-if="autoMl">
          <div
            @click="activeDataSourceBox = !activeDataSourceBox"
            class="dtp-aml-custom-select"
          >
            <span>{{ $t(`${selectBoxName}`) }}</span>
            <i
              class="el-icon-caret-bottom dtp-fcolor-416ca8"
              aria-hidden="true"
            ></i>
          </div>
          <div
            class="dtp-aml-custom-select-box"
            :class="{ 'dtp-display-block': activeDataSourceBox }"
          >
            <ul class="dtp-aml-custom-select-box-list">
              <li
                @click.stop="
                  (activeList = index),
                    selectDB(item.id),
                    (selectBoxName = item.name),
                    (activeDataSourceBox = false)
                "
                :class="{
                  'dtp-aml-custom-select-box-list-active': activeList == index,
                }"
                v-for="(item, index) in initialTableData"
                :key="index"
              >
                <span>{{ item.name }}</span>
                <div>
                  <img
                    @click.stop="edit(item, 'Edit Data Source')"
                    height="12"
                    src="../../../assets/images/icons/automl-edit-icon.svg"
                    alt="edit-icon"
                    class="dtp-mr--1"
                  />
                  <img
                    @click.stop="remove(item)"
                    height="12"
                    src="../../../assets/images/icons/automl-delete-icon.svg"
                    alt="delete-icon"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DATASOURCE_ACTIONS, DATASOURCE_GETTERS } from "../../../store/modules/AutoML/DataSource/type";
import cloneDeep from "clone-deep";

export default {
  props: {
    editMode: {
      type: Boolean,
    },
    autoMl: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeDataSourceBox: false,
      activeList: null,
      initialTableData: [],
      selectedDataSource: {},
      selectBoxName: "dataSource.Choose Data Source",
      requestComp: ""
    };
  },
  async created() {
    this.initQueryDataSource();
  },
  methods: {
     ...mapActions({
      fetchDataSource: DATASOURCE_ACTIONS.FETCH_DATASOURCE,
    }),
    async initQueryDataSource() {
      await this.fetchDataSource({ requestComp: this.requestComp, page: 1, count: 100 });
      this.initialTableData = cloneDeep(this.dataSources);
      for (let d of this.initialTableData) {
        if (d.id === this.selected) {
          this.selectedDataSource = d;
          break;
        }
      }
      this.sortTable(this.initialTableData);

      this.$store.dispatch(
        "Query/Set/FormData/Datasources",
        this.initialTableData
      );
    },
    edit(item, value) {
      this.$emit("selectedDbInfo", item);
      this.$emit("editDataSource", value);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    selectDB(value) {
      this.$emit("selectedDB", value);
    },
    sortTable(initialTableData) {
      initialTableData.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), "tr", {
          sensitivity: "base",
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      dataSources: DATASOURCE_GETTERS.GET_DATASOURCE 
    }),
    selected: {
      get() {
        return this.$store.getters["Query/Get/FormData/DatasourceId"];
      },
      set(value) {
        return this.$store.dispatch("Query/Set/FormData/DatasourceId", value);
      },
    },
  },
};
</script>
<style scoped>
.dtp-query-dataSource--List li {
  color: #585858;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  padding-top: 20px;
  cursor: pointer;
}
.dtp-query-dataSource--List li:nth-child(1) {
  padding-top: 0;
}
.dtp-query-dataSource--List li a {
  position: relative;
  background-color: #f2f2f2;
  display: block;
  padding: 13px 10px 13px 0;
  border-radius: 0;
  font-weight: 500;
}
.dtp-query-dataSource--List li a i {
  transform: translateY(5px);
}
.dtp-query-dataSource--List li a::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  height: 47px;
  width: 10px;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
}
.dtp-query-dataSource--List li a::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  height: 47px;
  width: 10px;
  border-radius: 0 4px 4px 0;
  background-color: #f2f2f2;
}
.dtp-aml-custom-select {
  position: relative;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
}
.dtp-aml-custom-select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: #426da9;
}
.dtp-aml-custom-select-box {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: auto;
  background-color: #f8f8f8;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  display: none;
}

.dtp-aml-custom-select-box-list {
  position: relative;
  width: 100%;
  padding: 9px;
  margin: 0;
  cursor: auto;
}
.dtp-aml-custom-select-box-list li {
  position: relative;
  width: 100%;
  height: 32px;
  margin-bottom: 7px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  cursor: pointer;
}
.dtp-aml-custom-select-box-list li span {
  color: #8c8c8c;
  font-size: 12px;
}
.dtp-aml-custom-select-box-list-active {
  background-color: #f2f8ff !important;
  border: 1px solid #bcceea !important;
}
.dtp-aml-custom-select-box-list-active span,
.dtp-aml-custom-select-box-list-active i {
  color: #436da9 !important;
}
.dtp-aml-custom-select span {
  font-size: 12px;
  color: #8c8c8c;
}
</style>
