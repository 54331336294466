<template>
  <svg
    id="datasets-database-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="24" height="24" stroke="none" />
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path
      id="Path_database-outline"
      data-name="Path / database-outline"
      d="M12,3C7.58,3,4,4.79,4,7V17c0,2.21,3.59,4,8,4s8-1.79,8-4V7c0-2.21-3.58-4-8-4m6,14c0,.5-2.13,2-6,2s-6-1.5-6-2V14.77A14.032,14.032,0,0,0,12,16a14.032,14.032,0,0,0,6-1.23V17m0-4.55A10.786,10.786,0,0,1,12,14a10.786,10.786,0,0,1-6-1.55V9.64A12.555,12.555,0,0,0,12,11a12.555,12.555,0,0,0,6-1.36v2.81M12,9C8.13,9,6,7.5,6,7s2.13-2,6-2,6,1.5,6,2S15.87,9,12,9Z"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
