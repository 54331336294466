<template>
  <div>
    <div>
      <div
        v-if="!predictionsTestTableVisible"
        id="modelGovernancePage"
        class="dtp-section"
      >
        <div class="aml-section-header">
          <div class="dtp-panel-formbox">
            <div class="dtp-page-title">
              <span>
                <Icon icon="modelGovernance" size="xl"></Icon>
              </span>
              {{ $t("modelGovernance.title") }}
            </div>
          </div>
          <PageViewBar
            :isImportEnabled="false"
            :title="''"
            :routerLink="''"
            :activeFilters="true"
            :headerArray="filterArray"
            helperLink="model-governance"
            @filterSaved="filterSaved"
            @deleteFiltered="deleteFilter"
            :filterList="filterList"
            :createBtn="false"
            :activeSearch="true"
            tooltipSearchName="Experiment"
            @changedSearch="changedSearch"
          >
            <div class="dtp-panel-formbox dtp-display-flex" slot="bar-left">
              <router-link
                v-if="isApplicationButtonAvailable"
                to="/model-governance/applications"
              >
                <button class="aml-btn eds-button.basic applicationButton">
                  {{ $t("applications") }}
                </button>
              </router-link>
            </div>
            <div class="bar-right-container" slot="bar-right">
              <el-tooltip
                :content="$t('Refresh Table')"
                placement="top"
                effect="light"
                class="dtp-ml--auto"
              >
                <button
                  :loading="loading"
                  @click="refreshWithFilters()"
                  class="aml-btn eds-button.primary"
                >
                  <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
                </button>
              </el-tooltip>

              <el-dropdown :hide-on-click="false" trigger="click">
                <el-tooltip
                  :content="$t('table.columns')"
                  placement="top"
                  effect="light"
                >
                  <button class="aml-icon-btn">
                    <Icon icon="tableColumn"></Icon>
                  </button>
                </el-tooltip>
                <el-dropdown-menu
                  slot="dropdown"
                  class="selectable-column-dropdown dtp-scrollbar dtp-scrollbar-scroll"
                >
                  <span class="selectable-column-dropdown-title">{{
                    $t("Column Selection")
                  }}</span>
                  <el-checkbox-group v-model="selectedColumnNames">
                    <el-dropdown-item
                      v-for="item in columnNames"
                      :key="item.value"
                    >
                      <el-checkbox :label="item.value">
                        <span
                          :class="[
                            'dropdown-label',
                            {
                              selected: selectedColumnNames.includes(
                                item.value
                              ),
                            },
                          ]"
                          >{{ $t("table.header." + item.label) }}</span
                        >
                      </el-checkbox>
                    </el-dropdown-item>
                  </el-checkbox-group>
                  <el-dropdown-item divided></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </PageViewBar>
        </div>
        <BuildPopup
          v-if="buildPopupState.opened"
          :dialogVisible="buildPopupState.opened"
          :applications="applications"
          @buildApplication="callBuildApplication"
          @closed="resetBuildPopup"
        />
        <div id="modelGovernanceModals">
          <equation
            :visible="equationVisible"
            :model="equationParams.model"
            :experiment="equationParams.experiment"
            :equation="getModelEquation"
            @modalClosed="() => (equationVisible = false)"
          />
          <pipeline
            :visible="pipelineVisible"
            :selectedRow="pipelineParams.selectedRow"
            :model="pipelineParams.model"
            :experiment="pipelineParams.experiment"
            @modalClosed="() => (pipelineVisible = false)"
          />
          <logs
            :visible="logsVisible"
            :selectedRow="logsParams.selectedRow"
            :title="logsParams.title"
            :experimentId="logsParams.experimentId"
            @modalClosed="() => (logsVisible = false)"
          />
          <notes-and-attachments-popup
            :requestComp="requestComp"
            :visible="notesAndAttachmentVisible"
            :params="notesAndAttachmenParams"
            @modalClosed="closeNotesAndAttachmentModal()"
          />
        </div>
        <!-- Modal ends here -->
        <div class="dtp-row">
          <div
            v-loading="modelGovernanceLoading || loading"
            :element-loading-text="$t('helper.loading_data')"
            class="dtp-col dtp-col-12 dtp-freeze-header"
          >
            <vue-good-table
              mode="remote"
              ref="MyCoolTable"
              :columns="columnsFilteredByEnv"
              :rows="enhancedModels"
              @on-sort-change="(params) => onSortChange(params, init)"
            >
              <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'health_monitor'">
                  <el-popover
                    :visible-arrow="false"
                    placement="bottom-start"
                    trigger="click"
                  >
                    <div class="dtp-ma--1">
                      {{ $t("table.header.indexIsCalculated") }}
                    </div>
                    <div
                      slot="reference"
                      style="display: inline-block; margin-right: 5px"
                    >
                      <i
                        class="mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0"
                        style="font-size: 12px"
                      ></i>
                    </div> </el-popover
                  >{{ $t("table.header." + props.column.label) }}
                </span>
                <span v-else>
                  {{ $t("table.header." + props.column.label) }}
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == modelFields.Check">
                  <label
                    v-if="props.column.field == modelFields.Check"
                    class="eds-checkbox"
                  >
                    <input
                      type="checkbox"
                      class="eds-checkbox_#input"
                      v-model="props.row.check"
                    />
                  </label>
                </span>

                <span v-else-if="props.column.field == modelFields.Project">
                  {{ props.row.project }}
                  <InfoPopup :project_id="props.row.project_id" />
                </span>
                <span v-else-if="props.column.field == modelFields.Model">
                  {{ props.row.model }}
                </span>
                <span v-else-if="props.column.field == modelFields.User">
                  {{ props.row.user }}
                </span>
                <span
                  v-else-if="props.column.field == modelFields.ModelHealthIndex"
                >
                  <img
                    width="120"
                    :src="props.row.ModelHealthIndex"
                    alt="Model Health Index"
                  />
                </span>
                <span v-else-if="props.column.field == modelFields.Status">
                  <img
                    width="20"
                    :src="props.row.Status"
                    :alt="modelFields.Status"
                  />
                </span>
                <span v-else-if="props.column.field == modelFields.Experiment">
                  <el-tooltip
                    :content="$t('Show model results of experiment')"
                    placement="top"
                    effect="light"
                  >
                    <span
                      class="linkbutton"
                      @click="
                        openModelResultsPage(
                          props.row.experiment_id,
                          props.row.experiment
                        )
                      "
                      >{{ props.row.experiment }}</span
                    >
                  </el-tooltip>
                  <InfoPopup :experiment_id="props.row.experiment_id" />
                  <span style="color: transparent; margin-left: 2px"
                    >({{ props.row.experiment_id }})</span
                  >
                </span>
                <span
                  v-else-if="
                    props.column.field == modelFields.Portfolio ||
                    props.column.field == modelFields.Usecase
                  "
                >
                  <span>{{
                    props.formattedRow[props.column.field]
                      ? props.formattedRow[props.column.field]
                      : "-"
                  }}</span>
                </span>
                <span
                  v-else-if="
                    props.column.field == modelFields.ModelAuditLog &&
                    isModelAuditLogEditableTextAvailable
                  "
                >
                  <EditableText
                    :row="props.row"
                    :cellPlaceholder="$t('placeholder.Type Here')"
                    :fieldName="modelFields.ModelAuditLog"
                    idName="model_id"
                    inputType="text"
                    @applyClicked="changeDescName"
                  >
                  </EditableText>
                </span>
                <span
                  v-else-if="props.column.field == modelFields.ModelDescription"
                >
                  <EditableText
                    :row="props.row"
                    :cellPlaceholder="$t('placeholder.Type Here')"
                    :fieldName="modelFields.ModelDescription"
                    idName="model_id"
                    inputType="text"
                    @applyClicked="changeDescName"
                  >
                  </EditableText>
                </span>
                <span v-else-if="props.column.field == 'deployment'">
                  {{
                    formatDeploymentCol(
                      getDeploymentStatusByBuildId(props.row.build_id)
                    )
                  }}
                  <DeploymentInfoPopup
                    :deployment="
                      getDeploymentStatusByBuildId(props.row.build_id)
                    "
                    v-if="
                      getDeploymentStatusByBuildId(props.row.build_id) &&
                      !isDeploymentStatusDraft(
                        formatDeploymentCol(
                          getDeploymentStatusByBuildId(props.row.build_id)
                        )
                      )
                    "
                  />
                </span>

                <span v-else-if="props.column.field === 'health_monitor'">
                  <health-index-cell
                    :modelValue="props.row.model_value"
                    :healthIndex="props.row.health_index"
                    :algorithmCode="props.row.algorithm_code"
                  />
                </span>

                <span v-else-if="props.column.field === 'benchmark_value'">
                  <benchmark-cell :benchmark="props.row.benchmark" />
                </span>
                <span
                  v-else-if="
                    props.column.field === modelFields.Build && isBuildAvailable
                  "
                  class="dtp-cursor-pointer dtp-item-center margin-top-8"
                >
                  <el-tooltip
                    :content="$t(buildTooltip(props.row))"
                    placement="top"
                    effect="light"
                  >
                    <Icon
                      @onClick="buildActionClicked(props.row)"
                      :icon="
                        props.row.build_status === buildStatusTypes.IN_PROGRESS
                          ? 'refresh'
                          : 'wrench'
                      "
                      size="md"
                      :class="buildActionImage(props.row)"
                    />
                  </el-tooltip>
                </span>
                <span
                  v-else-if="
                    props.column.field === modelFields.Interpret &&
                    isInterpretAvailable &&
                    !interpretInvisibleAlgorithmCodes.includes(
                      props.row.algorithm_code
                    )
                  "
                  class="dtp-cursor-pointer dtp-item-center margin-top-8"
                >
                  <el-tooltip
                    :content="$t(interpretTooltip(props.row))"
                    placement="top"
                    effect="light"
                  >
                    <div class="icon-container">
                      <Icon
                        @onClick="interpretClicked(props.row)"
                        icon="interpret"
                        size="md"
                        :class="interpretActionImage(props.row)"
                      />
                      <Icon
                        v-if="
                          props.row.interpretStatus ===
                          INTERPRET_STATUS.IN_PROGRESS
                        "
                        icon="refresh"
                        size="sm"
                        class="primary"
                        @onClick="refreshInterpretClicked(props.row)"
                      />
                    </div>
                  </el-tooltip>
                </span>
                <span
                  v-else-if="
                    props.column.field === modelFields.ReportGeneration
                  "
                >
                  <ModelReportsAction
                    :row="props.row"
                    @generateValidationReportClicked="
                      generateValidationReportClicked
                    "
                    @showValidationReportClicked="showValidationReportClicked"
                  ></ModelReportsAction>
                </span>

                <span v-else-if="props.column.field === modelFields.Actions">
                  <ModelGovernanceActions
                    v-if="!modelGovernanceLoading"
                    :row="props.row"
                    :requestComp="requestComp"
                    @equationClicked="
                      (val) => {
                        equationVisible = true;
                        equationParams = val;
                      }
                    "
                    @pipelineClicked="
                      (val) => {
                        pipelineVisible = true;
                        pipelineParams = val;
                      }
                    "
                    @logsClicked="
                      (val) => {
                        logsVisible = true;
                        logsParams = val;
                      }
                    "
                    @auditLogClicked="
                      (val) => {
                        notesAndAttachmentVisible = true;
                        notesAndAttachmenParams = val;
                      }
                    "
                  />
                </span>
              </template>
            </vue-good-table>
            <CustomPagination
              :lastPage="lastPage"
              :pageSize.sync="pageSize"
              :showPageSizeSelect="true"
              :totalItem="totalItem"
              @change="changePage"
            />
          </div>
        </div>
      </div>
      <div v-else class="dtp-section">
        <div class="dtp-pageviewtopbar">
          <div class="dtp-row dtp-align-center">
            <div class="dtp-col dtp-col-8 dtp-align-center">
              <el-tooltip :content="$t('commons.back')" placement="top" effect="light">
                <button
                  @click="predictionsTestTableVisible = false"
                  style="
                    margin-right: 24px;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                  "
                >
                  <img
                    src="../../../assets/images/icons/Automlback.svg"
                    alt=""
                  />
                </button>
              </el-tooltip>
              <div class="dtp-panel-formbox">
                <span class="dtp-aml-page-title"
                  ><span
                    style="
                      font: normal normal medium 16px/50px;
                      letter-spacing: 0px;
                      color: #8c8c8c;
                      opacity: 1;
                    "
                  >
                    {{ $t("modelGovernance.title") }} /
                  </span>
                  {{ $t("Validation Test Report") }} -
                  {{ selectedRowForValidationReport.model }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <PredictionsTestTable
          :inModal="false"
          :row="selectedRowForValidationReport"
          :validations="selectedRowForValidationReport.validations"
          :dataType="VALIDATION_DATA_TYPES.TEST_DATA"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { modelGovernanceColumns } from "./data/dataset";
import PageViewBar from "../Helper/Pageviewbar";
import InfoPopup from "./InfoPopup";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomPagination from "../CustomPagination";
import notify from "../../../mixins/AutoML/notify";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import preferences from "../../../mixins/AutoML/preferences";
import remoteDataSort from "../../../mixins/AutoML/remoteDataSort";
import helperLink from "../../../mixins/AutoML/helperLink";
import ModelGovernanceActions from "./ModelGovernanceActions.vue";
import { modelFields as fields } from "./modelTypes";
import EditableText from "../EditableCell/EditableText.vue";
import Equation from "./Equation";
import Pipeline from "./Pipeline";
import Logs from "./Logs";
import { PRIVILEGES } from "../../../helper/authorization";
import BuildPopup from "./BuildPopup.vue";
import { DeploymentStatus } from "./data/constants";
import DeploymentInfoPopup from "../Popup/DeploymentInfoPopup.vue";
import HealthIndexCell from "./HealthIndexCell.vue";
import BenchmarkCell from "./BenchmarkCell.vue";
import NotesAndAttachmentsPopup from "./NotesAndAttachmentsPopup.vue";
import PredictionsTestTable from "../Predictions/PredictionsTestTable";
import cloneDeep from "clone-deep";
import { PROJECT_ACTIONS } from "../../../store/modules/AutoML/Projects/types";
import {
  EXPERIMENT_ACTIONS,
  EXPERIMENT_GETTERS,
} from "../../../store/modules/AutoML/Experiments/types";
import {
  MODELS_ACTIONS,
  MODELS_GETTERS,
  MODELS_MUTATIONS,
} from "../../../store/modules/AutoML/Models/types";
import {
  TAG_ACTIONS,
  TAG_GETTERS,
} from "../../../store/modules/AutoML/Tags/types";
import {
  BUILD_ACTIONS,
  BUILD_GETTERS,
} from "../../../store/modules/AutoML/Build/types";
import {
  APPLICATION_ACTIONS,
  APPLICATION_GETTERS,
} from "../../../store/modules/AutoML/Application/types";
import { mapFilterResult } from "../Popup/filterPopupTypes";
import { VALIDATION_DATA_TYPES } from "../../../models/modelGovernance";
import Icon from "../../Icons/Icon.vue";
import ModelReportsAction from "./ModelReportsAction.vue";
import debounce from "lodash.debounce";

export default {
  components: {
    VueGoodTable,
    PageViewBar,
    InfoPopup,
    CustomPagination,
    ModelGovernanceActions,
    EditableText,
    Equation,
    Pipeline,
    Logs,
    BuildPopup,
    DeploymentInfoPopup,
    HealthIndexCell,
    BenchmarkCell,
    NotesAndAttachmentsPopup,
    PredictionsTestTable,
    Icon,
    ModelReportsAction,
  },
  mixins: [
    notify,
    preferences,
    helperLink,
    remoteDataSort,
    componentAvailabilityCheck,
  ],
  data() {
    return {
      predictionsTestTableVisible: false,
      selectedRowForValidationReport: null,
      notesAndAttachmentVisible: false,
      notesAndAttachmenParams: {},
      searchName: "",
      modelGovernanceLoading: false, //use mixin
      equationVisible: false,
      pipelineVisible: false,
      logsVisible: false,
      equationParams: {},
      pipelineParams: {},
      logsParams: {},
      filterArray: [],
      filterables: [
        { label: "Experiment Id", key: "experiment_id", type: "input" },
        { label: "Project Id", key: "project_id", type: "input" },
        { label: "Experiment Name", key: "experiment_name", type: "input" },
        { label: "Project Name", key: "project_name", type: "input" },
        {
          label: "Audit State",
          key: "audit_state",
          type: "select",
          values: ["PASS", "FAIL", "REVIEWING", "CANCELLED"],
        },
        { label: "Portfolio", key: "portfolio_name", type: "input" },
        {
          label: "Creation Date",
          key: ["creation_date_lower_bound", "creation_date_upper_bound"],
          type: "dateRange",
        },
        { label: "Use Case", key: "use_case_name", type: "input" },
        {
          label: "Build Status",
          key: "build_status",
          type: "select",
          values: ["IN_PROGRESS", "FINISHED", "FAILED"],
        },
      ],
      experimentName: this.$route.query.experimentName,
      dummyProjectInfoPopup: [
        { title: "Proje Creation Date", content: "" },
        { title: "Description", content: "" },
      ],
      dummyModelControl: {
        "Income Model": "0,90",
        "Experiment 2": "0,89",
        "Experiment 3": "0,80",
        "Experiment 4": "0,85",
        "Experiment 5": "0,95",
      },
      TagTypes: {
        USE_CASE: "USE_CASE",
        PORTFOLIO: "PORTFOLIO",
      },
      columns: modelGovernanceColumns,
      currentPage: 1,
      pageSize: 20,
      tableDescId: null,
      tableAuditId: null,
      tableUsecaseId: null,
      tablePortfolioId: null,
      projectInfosData: {},
      experimentInfosData: {},
      buildPopupState: {
        opened: false,
        experimentId: -1,
        modelId: -1,
        app_id: -1,
        artifactUri: null,
      },
      fieldsToBeHiddenWithModelMonitoringEnv: [
        "health_monitor",
        "benchmark_value",
      ],
      enhancedModels: [],
      selectedColumnNames: [],
      columnNames: [],
      MODEL_GOVERNANCE_SELECTED_COLUMNS: "MODEL_GOVERNANCE_SELECTED_COLUMNS",
      VALIDATION_DATA_TYPES,
      INTERPRET_STATUS: {
        SUCCESS: "SUCCESS",
        FAILED: "FAILED",
        IN_PROGRESS: "IN_PROGRESS",
        PENDING: "PENDING",
      },
      buildStatus: null,
      buildStatusTypes: {
        FINISHED: "FINISHED",
        IN_PROGRESS: "IN_PROGRESS",
        FAILED: "FAILED",
      },
      STATUSES: {
        SUCCESS: "SUCCESS",
        IN_PROGRESS: "IN_PROGRESS",
      },
    };
  },
  watch: {
    models: {
      handler() {
        this.enhancedModels = cloneDeep(
          this.models.map((i) => ({ ...i, interpretStatus: null }))
        );
      },
      deep: true,
    },
    pageSize: {
      handler() {
        this.refreshWithFilters();
      },
    },
    selectedColumnNames: {
      handler(newVal) {
        localStorage.setItem(
          this.MODEL_GOVERNANCE_SELECTED_COLUMNS,
          JSON.stringify(newVal)
        );
        this.columns = modelGovernanceColumns.filter((i) =>
          newVal.includes(i.field)
        );
      },
      deep: true,
    },
  },
  mounted() {
    this.requestComp = this.modelFields.Title;
    this.columnNames = this.columnsFilteredByEnv.map((i) => ({
      value: i.field,
      label: i.label,
    }));

    const selectedCols = localStorage.getItem(
      this.MODEL_GOVERNANCE_SELECTED_COLUMNS
    );

    if (selectedCols) {
      this.selectedColumnNames = JSON.parse(selectedCols);
      this.columns = this.columnsFilteredByEnv.filter((i) =>
        this.selectedColumnNames.includes(i.field)
      );
    } else {
      this.selectedColumnNames = modelGovernanceColumns.map((i) => i.field);
    }

    if (!this.isBuildAvailable)
      this.columns = this.columns
        .filter((x) => x.field !== "deployment")
        .map((c) => ({
          ...{
            sortable: false,
          },
          ...c,
        }));
    else
      this.columns = this.columns.map((c) => ({
        ...{
          sortable: false,
        },
        ...c,
      }));

    this.refreshWithFilters();
    this.initFilterArray();
  },
  computed: {
    ...mapGetters({
      deploymentStatusOfBuilds: BUILD_GETTERS.GET_DEPLOYMENT_STATUS_OF_BUILDS,
      applications: APPLICATION_GETTERS.GET_APPLICATIONS,
      models: MODELS_GETTERS.GET_MODELS,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      modelGovernanceFilter: MODELS_GETTERS.GET_MODEL_GOVERNANCE_FILTER,
      getModelEquation: MODELS_GETTERS.GET_MODEL_EQUATION,
      tags: TAG_GETTERS.GET_TAGS,
    }),
    isApplicationButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_APPLICATION_BUTTON
      );
    },
    isBuildAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_BUILD_BUTTON
      );
    },
    columnsFilteredByEnv() {
      let filteredColums = this.columns;

      if (
        !this.isComponentAvailable(
          PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON
        )
      ) {
        filteredColums = filteredColums.filter(
          (x) => x.field != this.modelFields.ModelAuditLog
        );
      }

      if (
        !this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_MODEL_MONITORING)
      ) {
        filteredColums = filteredColums.filter(
          (x) => x.field != this.modelFields.ModelMonitoring
        );
      }

      if (
        !this.isComponentAvailable(
          PRIVILEGES.AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON
        )
      ) {
        filteredColums = filteredColums.filter(
          (x) =>
            x.field != this.modelFields.Portfolio &&
            x.field != this.modelFields.Usecase
        );
      }

      return filteredColums;
    },
    isModelAuditLogEditableTextAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON
      );
    },
    filterList() {
      let list = [];
      Object.keys(this.modelGovernanceFilter).forEach((mdf) => {
        list.push({
          column_name: mdf,
          conditional_operator: "equal",
          value: this.modelGovernanceFilter[mdf],
        });
      });
      return list;
    },
    lastPage() {
      return this.pagination.models?.last_page ?? 1;
    },
    totalItem() {
      return this.pagination.models?.total_item;
    },
    modelFields() {
      return fields;
    },
    interpretInvisibleAlgorithmCodes() {
      return [300, 301];
    },
    isInterpretAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_INTERPRET_BUTTON
      );
    },
  },

  methods: {
    ...mapActions({
      fetchModelExplanationStatus:
        MODELS_ACTIONS.FETCH_MODEL_EXPLANATION_STATUS,
      fetchApplications: APPLICATION_ACTIONS.FETCH_APPLICATIONS,
      fetchModels: MODELS_ACTIONS.FETCH_MODELS,
      fetchSingleModel: MODELS_ACTIONS.FETCH_SINGLE_MODEL,
      fetchDeploymentStatusByBuilds:
        BUILD_ACTIONS.FETCH_DEPLOYMENT_STATUS_BY_BUILDS,
      updateDescriptionModel: MODELS_ACTIONS.UPDATE_DESCRIPTION_BY_MODEL_ID,
      fetchTags: TAG_ACTIONS.FETCH_TAGS,
      fetchProjects: PROJECT_ACTIONS.FETCH_PROJECTS,
      buildApplication: APPLICATION_ACTIONS.BUILD_APPLICATION,
      fetchDeploymentStatusBySingleBuild:
        BUILD_ACTIONS.FETCH_DEPLOYMENT_STATUS_BY_SINGLE_BUILD,
      makeModelValidations: MODELS_ACTIONS.MAKE_MODEL_VALIDATIONS,
      fetchExperimentByExperimentId:
        EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      fetchTTestResult: MODELS_ACTIONS.FETCH_T_TEST_RESULT,
      fetchBinomialTestResult: MODELS_ACTIONS.FETCH_BINOMIAL_TEST_RESULT,
      postModelExplanationPlot: MODELS_ACTIONS.POST_MODEL_EXPLANATION_PLOT,
    }),
    ...mapMutations({
      setModelGovernanceFilter: MODELS_MUTATIONS.SET_MODEL_GOVERNANCE_FILTER,
    }),
    async generateValidationReportClicked(row) {
      await this.fetchExperimentByExperimentId({
        experimentId: row.experiment_id,
      });

      if (this.selectedExperiment && this.selectedExperiment.length) {
        const datasetId = this.selectedExperiment[0].dataset_id;

        this.enhancedModels.forEach((x) => {
          if (x.model_id === row.model_id) {
            this.$set(x, "isLoadingState", true);
          }
        });
        await this.makeModelValidations({
          model_id: row.model_id,
          data: {
            type: "test_data",
            methods: ["t_test", "binomial_test"],
            dataset_id: datasetId,
          },
          requestComp: this.requestComp,
        });

        setTimeout(() => this.refreshWithFilters(), 2000);
      }
    },
    showValidationReportClicked(row) {
      this.predictionsTestTableVisible = true;
      this.selectedRowForValidationReport = row;
    },
    openModelResultsPage(experimentId, experimentName) {
      this.$router.push({
        name: "ModelResultsGovernance",
        query: { experimentId, experimentName },
      });
    },
    changedSearch: debounce(function (searchText) {
      this.searchName = searchText;
      this.refreshWithFilters();
    }, 900),

    async refreshRowDeploymentStatus({ build_id, model_id }) {
      await this.fetchSingleModel({ model_id, requestComp: this.requestComp });
      await this.fetchDeploymentStatusBySingleBuild({
        build_id,
        requestComp: this.requestComp,
      });
    },
    formatDeploymentCol(deployment) {
      /*	Draft -> deployments array boş veya hiç gelmemesi (found: true ; unknown: false iken)
				Live -> deployments array var, sync -> true
				Ready to live -> deployments array var, sync -> false
			*/

      if (deployment) {
        if (deployment?.deployments?.length) {
          if (deployment.deployments.filter((i) => i.sync === true).length)
            return DeploymentStatus.Live;
          else return DeploymentStatus.ReadyToLive;
        } else if (deployment.found === true && deployment.unknown === false) {
          return DeploymentStatus.Draft;
        }
      }
      return "";
    },
    refreshWithFilters() {
      this.init(this.modelGovernanceFilter);
    },
    getDeploymentStatusByBuildId(buildId) {
      return this.deploymentStatusOfBuilds?.find((i) => i.id === buildId);
    },
    isDeploymentStatusDraft(deploymentStatus) {
      return (
        deploymentStatus === DeploymentStatus.Draft || deploymentStatus === ""
      );
    },
    callBuildApplication(appId) {
      this.setBuildPopupAppId(appId);
      const { opened, ...others } = this.buildPopupState;
      this.buildApplication({ ...others, requestComp: this.requestComp });
      this.resetBuildPopup();
      this.refreshWithFilters();
    },
    async toggleBuildPopup({
      opened,
      experimentId,
      projectId,
      modelId,
      artifactUri,
    }) {
      if (opened)
        await this.fetchApplications({
          page: 1,
          count: 100,
          project: projectId,
        });
      this.$set(this.buildPopupState, "opened", opened);
      this.$set(this.buildPopupState, "experimentId", experimentId);
      this.$set(this.buildPopupState, "modelId", modelId);
      this.$set(this.buildPopupState, "artifactUri", artifactUri);
    },
    setBuildPopupAppId(appId) {
      this.$set(this.buildPopupState, "app_id", appId);
    },
    resetBuildPopup() {
      this.$set(this.buildPopupState, "opened", false);
      this.$set(this.buildPopupState, "experimentId", -1);
      this.$set(this.buildPopupState, "modelId", -1);
      this.$set(this.buildPopupState, "app_id", -1);
      this.$set(this.buildPopupState, "artifactUri", null);
    },
    async goInterpret({ experiment_id, model_id }) {
      this.$router.push({
        name: "Interpret",
        query: { experiment_id, model_id },
      });
    },
    closeEquationModal() {
      this.equationVisible = false;
    },
    closePipelineModal() {
      this.pipelineVisible = false;
    },
    closeLogsModal() {
      this.logsVisible = false;
    },
    closeNotesAndAttachmentModal() {
      this.notesAndAttachmentVisible = false;
    },
    filterSaved(filters) {
      const mappedFilters = mapFilterResult(filters);

      if (Object.keys(mappedFilters).length > 0) {
        this.setModelGovernanceFilter(mappedFilters);
        this.currentPage = 1;
        this.init(mappedFilters);
      } else {
        this.$alert("Please enter a search condition!", "Warning", {
          confirmButtonText: "OK",
        });
      }
    },
    initFilterArray() {
      const alreadyFiltered = Object.keys(this.modelGovernanceFilter);
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        if (alreadyFiltered.includes(fs.key)) {
          x = {
            isActive: true,
            data: this.modelGovernanceFilter[fs.key],
            operator: fs.type === "input" ? "equal_input" : "equal_select",
          };
        }

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    deleteFilter(value) {
      const x = Object.assign({}, this.modelGovernanceFilter);
      delete x[value.column_name];
      this.setModelGovernanceFilter(x);
      this.filterArray.forEach((element) => {
        if (element.name == value.column_name) {
          element.operator = "";
          element.data = "";
          element.isActive = false;
        }
      });
      this.currentPage = 1;
      this.refreshWithFilters();
    },
    async init(otherFilters = {}) {
      this.modelGovernanceLoading = true;
      this.fetchTags({});
      const payload = {
        page: this.currentPage,
        count: this.pageSize,
        experimentName: this.experimentName ? this.experimentName : "",
      };

      const modelFetchBody = {
        ...payload,
        ...otherFilters,
        ...this.sortConfig,
      };

      if (this.searchName) modelFetchBody["experiment_name"] = this.searchName;

      await this.fetchModels(modelFetchBody);

      const buildIds = this.models
        ?.filter((d) => d.build_id)
        .map((d) => d.build_id);
      await this.fetchDeploymentStatusByBuilds(buildIds);

      //will be fixed with autocomplete
      await this.fetchProjects({
        page: 1,
        count: 300,
      });

      this.modelGovernanceLoading = false;
    },
    changePage(page) {
      this.currentPage = page;
      this.refreshWithFilters();
    },
    changeDescName(row) {
      this.tableDescId = null;
      this.updateDescriptionModel({
        requestComp: this.requestComp,
        modelId: row.model_id,
        descriptionData: { description: row.model_description },
      });
    },
    getColorStyle(color) {
      return `background-color: ${color}`;
    },
    async buildActionClicked(row) {
      if (row.build_status === this.buildStatusTypes.IN_PROGRESS) {
        this.refreshRowDeploymentStatus(row);
      } else if (row.build_status !== this.buildStatusTypes.FINISHED) {
        this.toggleBuildPopup({
          experimentId: row.experiment_id,
          projectId: row.project_id,
          modelId: row.model_id,
          artifactUri: row.artifact_uri,
          opened: true,
        });
      } else {
        return null;
      }
    },
    async refreshInterpretClicked(row) {
      if (row.interpretStatus === this.INTERPRET_STATUS.IN_PROGRESS) {
        await this.checkInterpretStatus(row);
      }
    },
    async interpretClicked(row) {
      if (
        !row.interpretStatus ||
        row.interpretStatus === this.INTERPRET_STATUS.FAILED
      ) {
        const res = await this.checkSummary(row);

        if (res?.status === 200)
          row.interpretStatus = this.INTERPRET_STATUS.SUCCESS;
        else row.interpretStatus = this.INTERPRET_STATUS.IN_PROGRESS;
      } else if (row.interpretStatus === this.INTERPRET_STATUS.SUCCESS) {
        this.goInterpret(row);
      }
    },
    async checkInterpretStatus(row) {
      const fetchPayload = {
        requestComp: this.requestComp,
        model_id: row.model_id,
      };

      const statusResult = await this.fetchModelExplanationStatus(fetchPayload);

      if (statusResult?.data?.status === this.STATUSES.SUCCESS) {
        this.$notify({
          title: this.$t("status." + statusResult?.data?.status),
          message: this.$t("alert.Model interpretation details are ready"),
          type: "info",
        });
        row.interpretStatus = this.INTERPRET_STATUS.SUCCESS;
      } else if (statusResult?.data?.status === this.STATUSES.IN_PROGRESS) {
        this.$notify({
          title: this.$t("status." + statusResult?.data?.status),
          message: `<span style="color: var(--color-primary);"> ${this.$t("alert.Model interpretation details are preparing")} </span>`,
          type: "info",
          dangerouslyUseHTMLString: true
        });
        row.interpretStatus = this.INTERPRET_STATUS.IN_PROGRESS;
      } else {
        this.$notify({
          title: this.$t("status." + statusResult?.data?.status),
          message: this.$t("alert.An error occured while plotting graphs"),
          type: "error",
        });
        row.interpretStatus = this.INTERPRET_STATUS.FAILED;
      }
    },
    async checkSummary(row) {
      row.interpretStatus = this.INTERPRET_STATUS.PENDING;

      return await this.postModelExplanationPlot({
        requestComp: this.requestComp,
        model_id: row.model_id,
        plot_type: "shap_summary",
        params: {},
      });
    },
    interpretActionImage(row) {
      if (row.interpretStatus === this.INTERPRET_STATUS.IN_PROGRESS) {
        return "yellow";
      } else if (row.interpretStatus === this.INTERPRET_STATUS.SUCCESS) {
        return "green";
      } else if (row.interpretStatus === this.INTERPRET_STATUS.FAILED) {
        return "red";
      }
      return "primary";
    },
    interpretTooltip(row) {
      if (row.interpretStatus === this.INTERPRET_STATUS.IN_PROGRESS) {
        return "CheckInterpretStatus";
      } else if (row.interpretStatus === this.INTERPRET_STATUS.SUCCESS) {
        return "Interpret";
      } else if (row.interpretStatus === this.INTERPRET_STATUS.FAILED) {
        return "FailedInterpret";
      } else if (row.interpretStatus === this.INTERPRET_STATUS.PENDING) {
        return "InterpretLoading";
      }
      return "Interpret";
    },
    buildActionImage(row) {
      if (row.build_status === this.buildStatusTypes.IN_PROGRESS) {
        return "yellow";
      } else if (row.build_status !== this.buildStatusTypes.FINISHED) {
        return "red";
      } else {
        return "green";
      }
    },
    buildTooltip(row) {
      if (row.build_status === this.buildStatusTypes.IN_PROGRESS) {
        return "CheckBuildStatus";
      } else if (row.build_status === this.buildStatusTypes.FAILED) {
        return "BuildFailed";
      }
      return "modelGovernance.build";
    },
  },
};
</script>

<style lang="scss" scoped>
.linkbutton {
  cursor: pointer;
  text-decoration: underline;
  color: #436da9;
  &:hover {
    font-weight: bold;
  }
}
.primary {
  color: var(--color-primary);
}
.green {
  color: var(--color-green);
}
.red {
  color: var(--color-red);
}
.yellow {
  color: var(--color-yellow);
}
.applicationButton {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: unset;
}
.page-options-bar {
  justify-content: space-between;
  padding: 8px 14px;
  margin-bottom: 25px;
}
.selectable-column-dropdown {
  max-height: 250px;
  overflow-y: auto;
  .selected {
    color: var(--color-primary);
  }
  .dropdown-label {
    color: var(--main-title-color);
    font-size: 12px;
  }
}
::v-deep .el-dropdown-menu__item {
  line-height: 20px;
}
.selectable-column-dropdown-title {
  padding: 5px 20px;
  font-size: 12px;
}
.margin-top-8 {
  margin-top: 8px;
}
.bar-right-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon-container {
  display: flex;
  gap: 10px;
}
::v-deep .el-checkbox {
  display: block;
}
</style>
