<template>
  <svg
    id="comment-quote-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g id="MDI_comment-quote-outline" data-name="MDI / comment-quote-outline">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="18" height="18" stroke="none" />
        <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
      </g>
      <path
        id="Path_comment-quote-outline"
        data-name="Path / comment-quote-outline"
        d="M7.25,17a.709.709,0,0,1-.75-.75V14h-3A1.5,1.5,0,0,1,2,12.5v-9A1.5,1.5,0,0,1,3.5,2h12A1.5,1.5,0,0,1,17,3.5v9A1.5,1.5,0,0,1,15.5,14H10.925L8.15,16.775A.681.681,0,0,1,7.625,17H7.25M8,12.5v2.325L10.325,12.5H15.5v-9H3.5v9H8M12.725,5l-1.05,2.25H13.25v3h-3V7.1L11.225,5h1.5m-4.5,0L7.175,7.25H8.75v3h-3V7.1L6.725,5Z"
        transform="translate(-0.5 -0.5)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
