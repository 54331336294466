<template>
  <div
    @mouseover="panelWidthControl()"
    class="dtp-section"
  >
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="aml-section-header aml-p-0">
            <PageViewBar
              :title="$t('automl.basicTransformationTitle')"
              :activeFilters="true"
              :activeSearch="false"
              :deletableFilter="false"
              :filterList="filterHistory"
              :headerArray="filterArray"
              @filterSaved="filterSaved"
            />
          </div>
          <data-preparation-table
            v-loading="loading"
            :dataColumns="dataColumns"
            :rawData="rawData"
            :lastPage="lastPage"
            :pageSize="pageSize"
            :isPaginationVisible="isPaginationVisible"
            @changePage="changePage"
            @changePageSize="changePageSize"
            @onSortChange="(val) => onSortChange(experimentId, val)"
          />
        </div>
      </pane>
      <pane min-size="30">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="aml-section-header">
            <div>
                <div class="eds-field_#label">{{ $t("experiment.Source Data Type") }}</div>
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <select
                    v-model="selectedColumnType"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="changeType()"
                  >
                    <option value="" disabled>
                      {{ $t("automl.chooseDataColumnType") }}
                    </option>
                    <option
                      :value="item.value"
                      v-for="item in columnTypeOptions"
                      :key="item.id"
                    >
                      {{ $t(`${item.label}`) }}
                    </option>
                  </select>
                </div>
            </div>
            <div>
                <div
                  v-if="selectedColumnType && selectedColumnType != 'None'"
                  class="eds-field_#label"
                >
                  {{ $t("experiment.Target Data Type") }}
                </div>
                <div
                  v-if="selectedColumnType && selectedColumnType != 'None'"
                  class="eds-field_#control eds-field_.eds-input eds-input"
                >
                  <select
                    v-model="selectedBulk"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="changeBulk()"
                  >
                    <option value="" selected disabled>
                      {{ $t("automl.bulkActions") }}
                    </option>
                    <option
                      :value="item.value"
                      v-for="item in bulkActionsList"
                      :key="item.id"
                    >
                       {{ $t(`${item.label}`) }}
                    </option>
                  </select>
                </div>
            </div>
            <div class="aml-horizontal-flex-end">
                <button
                  @click.stop="applyChanges()"
                  class="eds-button eds-button.basic"
                  :disabled="!applyButton"
                >
                  {{ $t("automl.applyChanges") }}
                </button>
                <RefreshTable :loading="loading" @refreshTable="refreshTable" />
            </div>
          </div>
          <div class="dtp-row">
            <div class="dtp-col" v-loading="loading">
              <vue-good-table
                ref="MyCoolTable"
                :columns="optionsTableHeaders"
                :rows="optionsRows"
                :class="{ 'vgt-custom-checkbox-column': (optionsRows || []).length }"
              >
                <template slot="table-column" slot-scope="props">
                  <span>{{ $t("table.header." + props.column.label) }}</span>
                </template>
                <template slot="table-row" slot-scope="props">
                  <label v-if="props.column.field == 'check'">
                    <input
                      type="checkbox"
                      :value="props.row.check"
                      v-model="props.row.check"
                      @change="resetValue(props.row.id)"
                      :disabled="!props.row.column_type"
                    />
                  </label>
                  <span v-else-if="props.column.field == 'field'">
                    <input
                      class="dtp-vgt-table-form-input"
                      type="text"
                      :class="{
                        'dtp-vgt-table-form-input-active':
                          props.row.originalIndex == tableoriginalIndex,
                      }"
                      :disabled="props.row.originalIndex != tableoriginalIndex"
                      v-model="props.row.label"
                      required
                    />
                    <el-tooltip
                      v-if="props.row.originalIndex != tableoriginalIndex"
                      :content="$t('tooltip.edit_column')"
                      placement="top"
                      effect="light"
                    >
                      <img
                        @click.stop="
                          tableoriginalIndex = props.row.originalIndex
                        "
                        alt="editIcon"
                        class="
                          dtp-cursor-pointer dtp-vgt-actions-icon
                          dtp-float--right
                        "
                        src="../../../assets/images/icons/automl-edit-icon.svg"
                      />
                    </el-tooltip>
                    <span
                      class="dtp-cursor-pointer dtp-float--right dtp-font-bold"
                      v-else
                      @click.stop="changeColumnName(props.row)"
                      >{{ $t("automl.ok") }}</span
                    >
                  </span>
                  <span
                    class="aml-table-row-radioInput"
                    v-else-if="props.column.field == 'fill_missing_value'"
                  >
                    <label
                      v-if="props.row.type == columnTypes.CATEGORICAL || props.row.type == columnTypes.NUMERIC"
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.column_type"
                        value="Date"
                        class="eds-radio_#input"
                        @click="(e) => checkRadio(props.row.id, e.target.value)"
                      />
                      <span class="eds-radio_#label">{{
                        $t("automl.date")
                      }}</span>
                    </label>
                    <label
                      v-if="
                        props.row.type == columnTypes.NUMERIC || props.row.type == columnTypes.DATE
                      "
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.column_type"
                        value="Categorical"
                        class="eds-radio_#input"
                        @click="(e) => checkRadio(props.row.id, e.target.value)"
                      />
                      <span class="eds-radio_#label">{{
                        $t("automl.categoric")
                      }}</span>
                    </label>
                    <label
                      v-if="props.row.type == columnTypes.CATEGORICAL || props.row.type == columnTypes.DATE"
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.column_type"
                        value="Numeric"
                        class="eds-radio_#input"
                        @click="(e) => checkRadio(props.row.id, e.target.value)"
                      />
                      <span class="eds-radio_#label">{{
                        $t("automl.numeric")
                      }}</span>
                    </label>
                  </span>
                  <span v-else-if="props.column.field == 'actions'">
                    <el-tooltip
                      :content="$t('tooltip.delete_column')"
                      placement="top"
                      effect="light"
                    >
                      <img
                        class="dtp-cursor-pointer dtp-vgt-actions-icon"
                        src="../../../assets/images/icons/automl-delete-icon.svg"
                        alt=""
                        @click.stop="deleteColumn(props.row)"
                      />
                    </el-tooltip>
                  </span>

                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div></pane
      >
    </splitpanes>
  </div>
</template>

<script>
/* eslint-disable */
import DataPreparationTable from "./DataPreparationTable";
import { VueGoodTable } from "vue-good-table";
import PageViewBar from "../Helper/Pageviewbar";
import RefreshTable from "../Helper/RefreshTable.vue";
import { mapActions, mapGetters } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import {
  optionsTableHeaders,
  conversionMapping,
  COLUMN_TYPES,
  typeConversionOptions
} from "./data/data.js";
// import Pagination from "../Pagination";
import { Splitpanes, Pane } from "splitpanes";
import notify from "../../../mixins/AutoML/notify";
import pipeline from "../../../mixins/AutoML/pipeline";
import liveData from "../../../mixins/AutoML/liveData";
import "splitpanes/dist/splitpanes.css";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from '../../../helper/asyncStatusChecker';
import { DATA_ACTIONS } from '../../../store/modules/AutoML/Data/types';

export default {
  mixins: [notify, liveData, pipeline],
  components: {
    // Pagination,
    VueGoodTable,
    DataPreparationTable,
    PageViewBar,
    RefreshTable,
    Splitpanes,
    Pane,
  },
  data() {
    return {
      filterList: [],
      records: 100,
      perPage: 10,
      currentPage: 3,
      columnType: "",
      convertObj: [],
      tableoriginalIndex: null,
      optionsTableHeaders: optionsTableHeaders,
      selectedColumnType: "",
      columnTypeOptions: typeConversionOptions,
      bulkActionsList: [],
      selectedBulk: "",
      updatedRows: [],
      columnTypes: COLUMN_TYPES
    };
  },
  async mounted() {
    this.requestComp = "basic_transformation";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )
  },
  computed: {
    ...mapGetters({
      loading: GENERAL_GETTERS.GET_LOADING
    }),
    targetVal() {
      return this.problem_conf?.target;
    },
    applyButton() {
      this.fetchApplyChanges(this.optionsRows.filter((item) => item.column_type).length > 0);
      return this.optionsRows.filter((item) => item.column_type).length > 0;
    },
    optionsRows() {
      this.updatedRows = this.explorations.map((exp, index) => ({
        id: index,
        label: exp.name,
        field: exp.name,
        type: exp.type,
        column_type: null,
        check: false,
      }));
      return this.updatedRows.filter((x) => x.label != this.targetVal);
    },
  },
  methods: {
    ...mapActions({
      renameColumn: DATA_ACTIONS.RENAME_COLUMN,
      convertApi: DATA_ACTIONS.CONVERT_API,
      deleteColumns: DATA_ACTIONS.DELETE_COLUMNS,
      fetchApplyChanges:DATA_ACTIONS.FETCH_APPLY_CHANGES,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    async changeColumnName(row) {
      this.tableoriginalIndex = null;

      let reqObj = {};
      reqObj.requestComp = this.requestComp;
      reqObj.name = {};
      reqObj.name.name = row.label;
      reqObj.experinentInfo = {};
      reqObj.experinentInfo.columnname = row.field;
      reqObj.experinentInfo.experiment_id = this.experimentId;

      await this.renameColumn(reqObj);
      this.initLiveData(this.requestComp);
    },
    async applyChanges() {
      let convertObj = [];
      this.updatedRows.forEach((dc) => {
        let req = {};
        if (dc.type != dc.column_type && dc.column_type != null && dc.label != this.targetVal) {
          req.column = dc.field;
          req.type = dc.column_type;
          convertObj.push(req);
        }
      });

      let reqObj = {};
      reqObj.requestComp = this.requestComp;
      reqObj.convert_data_type = {};
      reqObj.convert_data_type.convert_data_type = convertObj;
      reqObj.experiment_id = this.experimentId;

      await this.convertApi(reqObj);

      await checkIfOperationStatusIsAvailable(
        {
          requestComp: this.requestComp,
          experiment_id: this.experimentId,
          action: ASYNC_PROCESS_ACTIONS.typeConversion
        },
        () => {
          this.initLiveData(this.requestComp);
          this.selectedColumnType = "";
          this.fetchApplyChanges(false);
        },
        { callAsyncStatus: this.callAsyncStatus }
      )
    },
    checkRadio(id, value) {
      var row = this.updatedRows.find((x) => x.id == id);
      row.check = true;
      row.column_type = value;
      this.fetchApplyChanges(true);
    },
    resetValue(id) {
      var row = this.updatedRows.find((x) => x.id == id);
      row.check = !row.check;
      row.column_type = null;
    },
    changeType() {
      this.selectedBulk = "";
      this.updatedRows.forEach((element) => {
        element.check = false;
        element.column_type = null;
        element.type == this.selectedColumnType && (element.check = true);
      });
      this.bulkActionsList = conversionMapping[this.selectedColumnType];
    },

    changeBulk() {
      this.updatedRows.forEach((element) => {
        element.type == this.selectedColumnType &&
          (element.column_type = this.selectedBulk);
      });
    },
    async deleteColumn(row) {
      let data = {};
      data.requestComp = this.requestComp;
      data.experiment_id = this.experimentId;
      data.names = row.field;
      data.pipeline_step = this.requestComp;
      await this.deleteColumns(data);
      this.initLiveData(this.requestComp);
    },
  },
};
</script>

<style scoped>
.aml-section-header > div {
  min-width: 175px;
}
.dtp-col {
  margin-bottom: 0;
}
</style>
