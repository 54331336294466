<template>
  <svg
    id="filter-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="MDI_filter-outline" data-name="MDI / filter-outline" opacity="0.995">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="16" height="16" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
      </g>
      <path
        id="Path_filter-outline"
        data-name="Path / filter-outline"
        d="M11.479,14.4a.645.645,0,0,1-.2.561.68.68,0,0,1-.959,0L7.6,12.254a.665.665,0,0,1-.2-.561V8.235L4.142,4.094a.673.673,0,0,1,.116-.946A.712.712,0,0,1,4.679,3H14.2a.712.712,0,0,1,.422.149.673.673,0,0,1,.116.946L11.479,8.235V14.4M6.066,4.351,8.759,7.769V11.5l1.36,1.351V7.762l2.692-3.411Z"
        transform="translate(-1.438 -1.079)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
