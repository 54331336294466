<template>
  <svg
    id="makePredictionWithNewDataset"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      id="Boundary"
      fill="#1d4e91"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="22" height="22" stroke="none" />
      <rect x="0.5" y="0.5" width="21" height="21" fill="none" />
    </g>
    <path
      id="Path_head-dots-horizontal-outline"
      data-name="Path / head-dots-horizontal-outline"
      d="M10.946,2.626a5.655,5.655,0,0,1,5.69,5.69,5.584,5.584,0,0,1-3.252,5.121v3.821H7.694V14.819H6.881a1.631,1.631,0,0,1-1.626-1.626V10.755H4.036c-.325,0-.569-.406-.325-.65L5.255,8.072a5.658,5.658,0,0,1,5.69-5.446m0-1.626A7.232,7.232,0,0,0,3.711,7.422L2.41,9.129A2.025,2.025,0,0,0,3.63,12.3v.894a3.316,3.316,0,0,0,2.439,3.17v2.52H15.01V14.413A7.325,7.325,0,0,0,10.946,1M8.507,8.316A.768.768,0,0,1,7.694,7.5a.768.768,0,0,1,.813-.813A.768.768,0,0,1,9.32,7.5a.768.768,0,0,1-.813.813m2.439,0a.768.768,0,0,1-.813-.813.813.813,0,0,1,1.626,0,.768.768,0,0,1-.813.813m2.439,0A.813.813,0,1,1,14.2,7.5.815.815,0,0,1,13.384,8.316Z"
      transform="translate(0.849 1.058)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
