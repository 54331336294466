var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"width":"450px","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event},"closed":function($event){return _vm.$emit('onClose')}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("tooltip.retest")))])]),_c('div',{staticClass:"dtp-row"},[_c('div',{staticClass:"dtp-col dtp-col-12 dtp-chart-box-20 dtp-pa--0"},[_c('span',{staticClass:"componentLabels"},[_vm._v(_vm._s(_vm.$t("Data Set")))]),_c('div',{staticClass:"dtp-aml-el-select"},[_c('el-select',{directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.callLoadMoreDatasets),expression:"callLoadMoreDatasets"}],attrs:{"filterable":"","placeholder":_vm.$t('Data Set')},model:{value:(_vm.form.dataset_id),callback:function ($$v) {_vm.$set(_vm.form, "dataset_id", $$v)},expression:"form.dataset_id"}},_vm._l((_vm.filterDataSets),function(item){return _c('el-option',{key:item.id,attrs:{"label":`${item.name}  (${item.username}  - ${_vm.callFormatDate(
              item.created_at
            )})`,"value":item.id}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.styleDataSet(
                  item.name,
                  item.username,
                  _vm.callFormatDate(item.created_at)
                )
              )}})])}),1)],1)])]),_c('div',{staticClass:"dtp-text--right dtp-mt--2"},[_c('button',{staticClass:"eds-button eds-button.basic dtp-mr--1",on:{"click":function($event){_vm.$emit('onClose');
        _vm.dialogVisible = false;}}},[_vm._v(" "+_vm._s(_vm.$t("commons.cancel"))+" ")]),_c('button',{staticClass:"eds-button eds-button.basic",on:{"click":_vm.onConfirm}},[_vm._v(" "+_vm._s(_vm.$t("commons.confirm"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }