<template>
  <svg
    id="Run"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Boundary" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none" />
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <g
      id="Group_18241"
      data-name="Group 18241"
      transform="translate(10.88 9.659)"
    >
      <path
        id="Path_play-outline"
        data-name="Path / play-outline"
        d="M8.128,7.964,12.419,10.7,8.128,13.435V7.964M6.5,5V16.4l8.957-5.7"
        transform="translate(-6.5 -5)"
        fill="#1d4e91"
      />
    </g>
    <g
      id="Group_18242"
      data-name="Group 18242"
      transform="translate(4.163 2.942)"
    >
      <path
        id="Path_9633"
        data-name="Path 9633"
        d="M10.843,1.286H1.286v9H5.191v1.286H1.286v.019H0V0H12.129V8.427l-1.286-.705Z"
        transform="translate(0 0)"
        fill="#1d4e91"
      />
      <rect
        id="Rectangle_5116"
        data-name="Rectangle 5116"
        width="2.328"
        height="2.328"
        transform="translate(3.026 6.518)"
        fill="#1d4e91"
      />
      <rect
        id="Rectangle_5118"
        data-name="Rectangle 5118"
        width="2.328"
        height="2.328"
        transform="translate(3.026 2.744)"
        fill="#1d4e91"
      />
      <rect
        id="Rectangle_5119"
        data-name="Rectangle 5119"
        width="2.328"
        height="2.328"
        transform="translate(6.777 2.744)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
