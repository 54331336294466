export const modelStatus = {
    Completed: 'COMPLETED',

}
export const modelFields = {
    Title: 'modelGovernance',
    TooltipSearchName: 'tooltipSearchName',
    ModelHealthIndex: 'ModelHealthIndex',
    Check: 'check',
    Project: 'project_name',
    Status: 'Status',
    Experiment: 'experiment_name',
    Portfolio: 'portfolio_name',
    Usecase: 'use_case_name',
    ModelAuditLog: 'model_audit_log',
    ModelDescription: 'model_description',
    ModelMonitoring: 'monitoring_date',
    Actions: 'Actions',
    TypeHere: 'Type Here',
    HealthMonitor: "health_monitor",
    Benchmark: "benchmark_value",
    ChooseUseCase: "Choose a Use Case",
    ChoosePortfolio: "Choose a Portfolio",
    Build: "Build",
    Interpret: "Interpret",
    GenerateValidationReport: "GenerateValidationReport",
    ReportGeneration: "ReportGeneration",
    Model: 'model_name',
    User: 'username'
}

export const actionsType = {
    Predict: 'predict',
    Download: 'download',
    Export: 'export',
    PredictResult: 'predictResult',
    DownloadScores: 'downloadScores',
    ModelDiagnostic: 'ModelDiagnostic',
    Interpret: 'Interpret',
    Build: 'Build',
    BuildFailed: 'BuildFailed',
    CheckBuildStatus: 'CheckBuildStatus',
    ModelAuditLog: 'ModelAuditLog',
    Pipeline: 'Pipeline',
    Logs: 'Logs',
    Equation: 'Equation'
}

export const portfolioItems = [
    {
        value: "Retail_CreditCard",
        label: "Retail_Credit Card",
    },
    {
        value: "Retail_PersonalLoan",
        label: "Retail_Personal Loan",
    },
    {
        value: "Retail_Car Loan",
        label: "Retail_Car Loan",
    },
    {
        value: "Retail_Mortgage",
        label: "Retail_Mortgage",
    },
    {
        value: "Retail_Overdraft",
        label: "Retail_Overdraft",
    },
    {
        value: "SME",
        label: "SME",
    },
    {
        value: "Agriculture",
        label: "Agriculture",
    },

]

export const useCaseItems = [
    {
        value: "Acquisition",
        label: "Acquisition",
    },
    {
        value: "PortfolioManagement",
        label: "Portfolio Management",
    },
    {
        value: "Collections",
        label: "Collections",
    },
    {
        value: "LegalRecovery",
        label: "Legal Recovery",
    },
    {
        value: "Fraud",
        label: "Fraud",
    },
    {
        value: "Marketing",
        label: "Marketing",
    },
]