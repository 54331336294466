import { Service } from "./BaseService";
import { callPost, callGet, callDelete, callPut, callPostFormData } from "./ServiceMethodWrapper"
import { serviceMethodParent, serviceMethodSub } from "./ApiConstants"

export const ServiceContainer = (() => {
    let instance;

    const init = () => {
        const autoMLService = new Service(process.env.VUE_APP_AUTOML_MAIN_API_ENDPOINT);
        const autoMLLiveService = new Service(process.env.VUE_APP_AUTOML_LIVE_API_ENDPOINT);
        const appApiService = new Service(process.env.VUE_APP_API_ENDPOINT);
        const autoMLIngestService = new Service(process.env.VUE_APP_AUTOML_INGEST_API_ENDPOINT);
        
        return {
            [serviceMethodParent.projects]: {
                [serviceMethodSub.getProjectList]: (query) => callGet(autoMLService)(`/projects?${query.toString()}`),
                [serviceMethodSub.createProject]: (body) => callPost(autoMLService)("/projects", body),
                [serviceMethodSub.deleteProject]: (id) => callDelete(autoMLService)("/projects/", id),
                [serviceMethodSub.getProjectByProjectId]: (id) => callGet(autoMLService)("/projects/", id),
                [serviceMethodSub.updateDescriptionByProjectId]: (id, body) => callPut(autoMLService)("/projects/", id, body),
                [serviceMethodSub.createExperiment]: (id, body) => callPost(autoMLService)(`/projects/${id}/experiments`, body),
                [serviceMethodSub.getPipeline]: ({ projectId, experimentId }) => callGet(autoMLService)(`/projects/${projectId}/experiments/${experimentId}/pipeline`),
                [serviceMethodSub.deleteExperiment]: ({ projectId, experimentId }) => callDelete(autoMLService)(`/projects/${projectId}/experiments/${experimentId}`),
                [serviceMethodSub.startSession]: ({ projectId, experimentId }, body) => callPost(autoMLService)(`/projects/${projectId}/experiments/${experimentId}/session`, body),
                [serviceMethodSub.stopSession]: ({ projectId, experimentId }) => callDelete(autoMLService)(`/projects/${projectId}/experiments/${experimentId}/session`),
                [serviceMethodSub.completeExperiment]: ({ projectId, experimentId }, body) => callPut(autoMLService)(`/projects/${projectId}/experiments/${experimentId}/complete`, "", body),
            },
            [serviceMethodParent.experiments]: {
                [serviceMethodSub.getExperiments]: (params) => callGet(autoMLService)(`/experiments?${params.toString()}`),
                [serviceMethodSub.getExperimentsByProjectId]: (query) => callGet(autoMLService)("/experiments", query),
                [serviceMethodSub.getExperimentsById]: (id) => callGet(autoMLService)("/experiments?experiment_id=", id),
                [serviceMethodSub.getAllTrainingsByExperimentId]: (id) => callGet(autoMLService)(`/experiments/${id}/trainings`),
                [serviceMethodSub.getTrainingLogs]: ({ trainId, experimentId }) => callGet(autoMLService)(`/experiments/${experimentId}/trainings/${trainId}/logs`),
                [serviceMethodSub.clearExperiments]: (id, body) => callPost(autoMLService)(`/experiments/${id}/reset`, body),
                [serviceMethodSub.getExperimentSessionStatus]: (body) => callPost(autoMLService)("/experiments/session/status", body),
                [serviceMethodSub.trainedStatusUpdate]: (id, body) => callPut(autoMLService)(`/experiments/${id}/trained-status-reversion`, "", body),
            },
            [serviceMethodParent.datasets]: {
                [serviceMethodSub.getDatasetsList]: (params) => callGet(autoMLService)(`/datasets?${params.toString()}`),
                [serviceMethodSub.createDataset]: (body) => callPost(autoMLService)("/datasets", body),
                [serviceMethodSub.deleteDataset]: (id) => callDelete(autoMLService)("/datasets/", id),
                [serviceMethodSub.getVisualization]: ({ datasetId, visualizationType }) => callGet(autoMLService)(`/datasets/${datasetId}/visualization/${visualizationType}`),
                [serviceMethodSub.getDatasetById]: (id) => callGet(autoMLService)(`/datasets/`, id),
                [serviceMethodSub.uploadCsv]: (formData) => callPostFormData(autoMLIngestService)(`/datasets/csv-upload`, formData),
                [serviceMethodSub.uploadPreviewCsv]: (formData) => callPostFormData(autoMLIngestService)(`/datasets/csv-upload/preview`, formData),
            },
            [serviceMethodParent.models]: {
                [serviceMethodSub.getModelsList]: (params) => callGet(autoMLService)(`/models?${params.toString()}`),
                [serviceMethodSub.getModelsListByExperimentId]: (query) => callGet(autoMLService)("/models/", query),
                [serviceMethodSub.postModelPlots]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/model/plots`, body),
                [serviceMethodSub.makePredicNewData]: (id, body) => callPut(autoMLService)(`/models/${id}/predictions`, "", body),
                [serviceMethodSub.getModelResulsList]: (id) => callGet(autoMLLiveService)(`/experiments/${id}/model/results`),
                [serviceMethodSub.downloadPredictionResults]: (id) => callGet(autoMLService)(`/models/download/test?artifact_uri=${id}`),
                [serviceMethodSub.runModels]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/model/training`, "", body), 
                [serviceMethodSub.problemConfiguration]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/model/problem-configuration`, "", body),
                [serviceMethodSub.deleteModelTraining]: (id) => callDelete(autoMLLiveService)(`/experiments/${id}/model/training`),
                [serviceMethodSub.modelDownload]: ({ artifactUri, experimentId }) => callGet(autoMLService)(`/models/download?artifact_uri=${artifactUri}&&experiment_id=${experimentId}`), 
                [serviceMethodSub.modelTuning]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/model/tuning`, "", body),
                [serviceMethodSub.fetchPredictions]: (params) => callGet(autoMLService)(`/models/predictions?${params}`),
                [serviceMethodSub.getModelsListByModelId]: (query) => callGet(autoMLService)("/models", query),
                [serviceMethodSub.updateDescriptionByModelId]: (id, body) => callPut(autoMLService)("/models/", id, body),
                [serviceMethodSub.getModelEquation]: (id) => callGet(autoMLService)(`/models/${id}/equation`),
                [serviceMethodSub.fetchModelResultsMain]: (id) => callGet(autoMLService)(`/experiments/${id}/model-results`), 
                [serviceMethodSub.fetchModelExplanationStatus]: (id) => callGet(autoMLService)(`/models/${id}/explanation/status`),
                [serviceMethodSub.fetchModelExplanationSamples]: (id) => callGet(autoMLService)(`/models/${id}/explanation/samples`),
                [serviceMethodSub.postModelExplanationPlot]: (id, body) => callPost(autoMLService)(`/models/${id}/explanation/plot`, body),
                [serviceMethodSub.fetchModelPlotMain]: (id, body) => callPost(autoMLService)(`/experiments/${id}/model-plots`, body),
                [serviceMethodSub.uploadAudit]: (id, formData) => callPostFormData(autoMLService)(`/models/${id}/audit`, formData),
                [serviceMethodSub.fetchAudit]: (id) => callGet(autoMLService)(`/models/${id}/audit`),
                [serviceMethodSub.makeModelValidations]: (id, body) => callPost(autoMLService)(`/models/${id}/validations`, body),
                [serviceMethodSub.getTTestResult]: ({ modelId, validationId }) => callGet(autoMLService)(`/models/${modelId}/validations/${validationId}/t-test-results`),
                [serviceMethodSub.getBinomialTestResult]: ({ modelId, validationId }) => callGet(autoMLService)(`/models/${modelId}/validations/${validationId}/binomial-test-results`),
                [serviceMethodSub.getModelValidations]: (id) => callGet(autoMLService)(`/models/${id}/validations`),
                [serviceMethodSub.fetchCustomModels]: (params) => callGet(autoMLService)(`/custom-models${params}`),
                [serviceMethodSub.defineCustomModel]: (formData) => callPostFormData(autoMLService)("/custom-models", formData),
                [serviceMethodSub.retryCustomModel]: (id, body) => callPost(autoMLService)(`/custom-models/${id}/retry`, body),
                [serviceMethodSub.deleteCustomModel]: (id) => callDelete(autoMLService)(`/custom-models/`, id),
                [serviceMethodSub.fetchModelRequestInfo]: (id) => callGet(autoMLService)(`/models/${id}/request-info`),
                [serviceMethodSub.retrainCustomModel]: (id, body) => callPost(autoMLService)(`/models/${id}/retrain`, body),
                [serviceMethodSub.retestCustomModel]: (id, body) => callPost(autoMLService)(`/models/${id}/retest`, body),
                [serviceMethodSub.fetchChallengerModelResults]: (id) => callGet(autoMLService)(`/experiments/${id}/challenger-results`),
                [serviceMethodSub.createChallengerModel]: (id, body) => callPost(autoMLService)(`/experiments/${id}/challenger`, body),
                [serviceMethodSub.fetchChallengerModelStatus]: (id) => callGet(autoMLService)(`/experiments/${id}/challenger-status`),
                [serviceMethodSub.fetchLogExports]: (query) => callGet(autoMLService)("/models/log-export", query),
                [serviceMethodSub.postLogExport]: (body) => callPost(autoMLService)(`/models/log-export`, body),
                [serviceMethodSub.deleteLogExport]: (id) => callDelete(autoMLService)(`/models/log-export/`, id),
                [serviceMethodSub.checkLogExportStatus]: (id) => callGet(autoMLService)(`/models/log-export/${id}/status`),
                [serviceMethodSub.getDownloadAudit]: (filePath) => callGet(autoMLService)(`/models/download-audit?file_path=${filePath}`),
                [serviceMethodSub.getGenericModels]: ({ id, query }) => callGet(autoMLService)(`/models/${id}/generic?set_to=${query}`)
            },
            [serviceMethodParent.data]: {
                [serviceMethodSub.getExplorationAllList]: (id) => callGet(autoMLLiveService)(`/experiments/${id}/data/exploration`), 
                [serviceMethodSub.getData]: ({ id, query }) => callGet(autoMLLiveService)(`/experiments/${id}/data`, query), 
                [serviceMethodSub.renameColumn]: ({ experimentId, columnname }, body) => callPut(autoMLLiveService)(`/experiments/${experimentId}/data/columns/${columnname}`, "", body),
                [serviceMethodSub.convertApi]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/type-conversion`, "", body),
                [serviceMethodSub.deleteColumns]: ({ id, query }) => callDelete(autoMLLiveService)(`/experiments/${id}/data/columns`, query),
                [serviceMethodSub.missingValue]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/missing-value`, "", body),
                [serviceMethodSub.encoding]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/encoding`, "", body),
                [serviceMethodSub.scaling]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/scaling`, "", body), 
                [serviceMethodSub.removeNaRows]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/remove-na-rows`, "", body),
                [serviceMethodSub.dataFilter]: ({ experimentId, pipelineStep }, body) => callPost(autoMLLiveService)(`/experiments/${experimentId}/data/filter?pipeline_step=${pipelineStep}`, body),
                [serviceMethodSub.dataFeatureGeneration]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/data/feature-generation`, body),
                [serviceMethodSub.dataUpdateFeatureGeneration]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/feature-generation`, "", body),
                [serviceMethodSub.dataVersioning]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/data/versioning`, body),
                [serviceMethodSub.featureImportancesPost]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/data/feature-importances`, body),
                [serviceMethodSub.featureImportancesGet]: (id) => callGet(autoMLLiveService)(`/experiments/${id}/data/feature-importances`),
                [serviceMethodSub.dataSort]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/sort`, "", body), 
                [serviceMethodSub.getStatus]: (id) => callGet(autoMLLiveService)(`/experiments/${id}/status`), 
                [serviceMethodSub.getOperationStatus]: ({ experimentId, operationId }) => callGet(autoMLLiveService)(`/experiments/${experimentId}/status?operation_id=${operationId}`),
                [serviceMethodSub.getOperationStatusById]: ({ experimentId, operationId }) => callGet(autoMLLiveService)(`/experiments/${experimentId}/operation/status?operation_id=${operationId}`),
                [serviceMethodSub.imbalanceInspection]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/data/imbalance-inspection`, body),
                [serviceMethodSub.balanceConfiguration]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/data/balance-configuration`, body),
                [serviceMethodSub.dataBinning]: (id, body) => callPut(autoMLLiveService)(`/experiments/${id}/data/binning`, "", body),
                [serviceMethodSub.previousPipelineStep]: (id, body) => callPost(autoMLLiveService)(`/experiments/${id}/pipeline/previous-step`, body)
            },
            [serviceMethodParent.dataSource]: {
                [serviceMethodSub.createAutoml]: (body) => callPost(appApiService)("nifi/ingest/automl", body),
                [serviceMethodSub.fetchDataSource]: (query) => callGet(autoMLIngestService)("/datasource", query),
                [serviceMethodSub.deleteDataSource]: (id) => callDelete(autoMLIngestService)("/datasource/", id),
                [serviceMethodSub.createDataSource]: (body) => callPost(autoMLIngestService)("/datasource", body),
                [serviceMethodSub.uploadRdbPreview]: (body) => callPost(autoMLIngestService)("/datasets/rdb-upload/preview", body),
                [serviceMethodSub.uploadRdb]: (body) => callPost(autoMLIngestService)("/datasets/rdb-upload", body),
            },
            [serviceMethodParent.stats]: {
                [serviceMethodSub.getStats]: () => callGet(autoMLService)("/stats/resource-usage")
            },
            [serviceMethodParent.tags]: {
                [serviceMethodSub.createTag]: (body) => callPost(autoMLService)("/tags", body),
                [serviceMethodSub.deleteTag]: (id) => callDelete(autoMLService)("/tags/", id),
                [serviceMethodSub.getTagList]: (query) => callGet(autoMLService)("/tags?", query),
                [serviceMethodSub.updateTag]: (id, body) => callPut(autoMLService)(`/tags/`, id, body),
            },
            [serviceMethodParent.build]: {
                [serviceMethodSub.fetchBuilds]: (query) => callGet(autoMLService)("/builds?", query),
                [serviceMethodSub.deleteBuild]: (id) => callDelete(autoMLService)("/builds/", id),
                [serviceMethodSub.fetchBuildById]: (id) => callGet(autoMLService)("/builds/", id),
                [serviceMethodSub.deployBuild]: (id, body) => callPut(autoMLService)(`/builds/${id}/deployment`, "", body),
                [serviceMethodSub.fetchDeploymentStatusByBuilds]: (body) => callPost(autoMLService)("/builds/status", body)
            },
            [serviceMethodParent.clusters]: {
                [serviceMethodSub.createCluster]: (body) => callPost(autoMLService)("/clusters", body),
                [serviceMethodSub.deleteCluster]: (id) => callDelete(autoMLService)("/clusters/", id),
                [serviceMethodSub.fetchClusters]: () => callGet(autoMLService)("/clusters"),
                [serviceMethodSub.repairCluster]: (id, body) => callPut(autoMLService)(`/clusters/${id}/repair`, "", body),
                [serviceMethodSub.updateCluster]: (id, body) => callPut(autoMLService)(`/clusters/${id}`, "", body)
            },
            [serviceMethodParent.applications]: {
                [serviceMethodSub.buildApplication]: (id, body) => callPut(autoMLService)(`/applications/${id}/build`, "", body),
                [serviceMethodSub.createApplication]: (body) => callPost(autoMLService)("/applications", body),
                [serviceMethodSub.fetchApplications]: (query) => callGet(autoMLService)("/applications?", query),
                [serviceMethodSub.deleteApplication]: (id) => callDelete(autoMLService)("/applications/", id),
            },
            [serviceMethodParent.device]: {
                [serviceMethodSub.fetchDeviceType]: () => callGet(autoMLService)("/device/type") 
            },
            [serviceMethodParent.license]: {
                [serviceMethodSub.fetchLicenseInfo]: () => callGet(autoMLService)("/license")
            },
        };
    };

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }

            return instance;
        }
    };
})();