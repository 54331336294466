/* import pipeline from "../../../mixins/AutoML/pipeline";
export default {
    mixins: [pipeline],
    mounted() {
        this.getPipeline(this.experimentId, this.selectedExperiment[0].project_id);
    },
    methods: {
        pipelineType(){
            return this.getProblemType
        }
    }
} */

export const TUNE_MODEL_COLUMNS = [
    {
        label: "",
        field: "check",
        sortable: false
    },
    {
        label: "Model",
        field: "model",
    },
    {
        label: "Actions",
        field: "actions",
        sortable: false,
    },
    {
        label: "RMSE",
        field: "rmse",
    },
    {
        label: "MAE",
        field: "mae",
    },
    {
        label: "MSE",
        field: "mse",
    },
    {
        label: "MedAE",
        field: "medae",
    },
    {
        label: "R2 Score",
        field: "r2score",
    },
    {
        label: "Training Time",
        field: "trainingtime",
    },
    {
        label: "Iteration Count",
        field: "n_iteration",
        sortable: false,
        tdClass: 'text-center',
    },

]


export const TUNE_MODEL_COLUMNS_CLASSFICATION = [
    {
        label: "",
        field: "check",
        sortable: false
    },
    {
        label: "Model",
        field: "model",
    },
    {
        label: "Actions",
        field: "actions",
        sortable: false,
    },
    {
        label: "Accuracy",
        field: "Accuracy",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Auc",
        field: "AUC",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Precision",
        field: "Precision",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Recall",
        field: "Recall",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "F-Measure",
        field: "F1",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Kappa",
        field: "Kappa",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "MCC",
        field: "MCC",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Training Time",
        field: "trainingtime",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Iteration Count",
        field: "n_iteration",
        sortable: false,
        tdClass: 'text-center',
    },
]

export const TUNE_MODEL_COLUMNS_REGRESSION = [
    {
        label: "",
        field: "check",
        sortable: false
    },
    {
        label: "Model",
        field: "model",
    },
    {
        label: "Actions",
        field: "actions",
        sortable: false,
    },
    {
        label: "MAE",
        field: "MAE",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "MSE",
        field: "MSE",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "RMSE",
        field: "RMSE",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "R2 Score",
        field: "R2",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "RMSLE",
        field: "RMSLE",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "MAPE",
        field: "MAPE",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Training Time",
        field: "trainingtime",
        sortable: false,
        tdClass: 'text-center',
    },
    {
        label: "Iteration Count",
        field: "n_iteration",
        sortable: false,
        tdClass: 'text-center',
    },
]
