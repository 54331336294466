export const metricLabels = {
    "Gini": "Gini",
    "Accuracy": "Accuracy",
    "AUC": "AUC",
    "Recall": "Recall",
    "Precision": "Precision",
    "F1": "F-Measure",
    "Kappa": "Kappa",
    "MCC": "MCC",
    "MAE": "MAE",
    "MSE": "MSE",
    "RMSE": "RMSE",
    "R2": "R2 Score",
    "RMSLE": "RMSLE",
    "MAPE": "MAPE",
    "Silhouette": "Silhouette",
    "Calinski-Harabasz": "Calinski-Harabasz",
    "Davies-Bouldin": "Davies-Bouldin"
}

const components = {
    feature: "feature",
    auc: "auc",
    confusion_matrix: "confusion_matrix",
    residuals: "residuals",
    error: "error",
    summary: "summary",
    correlation: "correlation",
    reason: "reason",
    cluster: "cluster",
    silhouette: "silhouette",
    tsne: "tsne",
    distance: "distance",
    distribution: "distribution",
    elbow: "elbow",
    pr: "pr",
}

export const classification = [
    "Accuracy",
    "AUC",
    "Gini",
    "Recall",
    "Precision",
    "F1",
    "Kappa",
    "MCC",
];

export const regression = ["MAE", "MSE", "RMSE", "R2", "RMSLE", "MAPE"];

export const cluster = ["Silhouette", "Calinski-Harabasz", "Davies-Bouldin"];

export const tabs = [
    {
        component: components.feature,
        problemType: {
            classification: "Feature Importance",
        },
    },
    {
        component: components.auc,
        problemType: {
            classification: "Roc Curve",
        },
    },
    {
        component: components.confusion_matrix,
        problemType: {
            classification: "Confusion Matrix",
        },
    },
    {
        component: components.pr,
        problemType: {
            classification: "AUCPR",
        },
    },


    {
        component: components.feature,
        problemType: {
            regression: "Feature Importance",
        },
    },
    {
        component: components.residuals,
        problemType: {
            regression: "Residuals Plot",
        },
    },
    {
        component: components.error,
        problemType: {
            regression: "Error Plot",
        },
    },

    {
        component: components.cluster,
        problemType: {
            cluster: "Cluster PCA Plot (2d)",
        },
    },
    {
        component: components.tsne,
        problemType: {
            cluster: "Cluster TSnE (3d)",
        },
    },
    {
        component: components.elbow,
        problemType: {
            cluster: "Elbow Plot",
        },
    },
    {
        component: components.silhouette,
        problemType: {
            cluster: "Silhouette Plot",
        },
    },
    {
        component: components.distance,
        problemType: {
            cluster: "Distance Plot",
        },
    },
    {
        component: components.distribution,
        problemType: {
            cluster: "Distribution Plot",
        },
    },
];