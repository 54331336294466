<template>
  <div>
    <el-table
      ref="modelResultsTable"
      id="challenger-table"
      class="dtp-modelresults-table"
      :data="rows"
      style="width: 100%"
      :cell-class-name="getCellClassName"
      row-class-name="no-hover"
      row-key="model_id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" :reserve-selection="true" :selectable="isRowSelectable"> </el-table-column>
      <el-table-column
        align="center"
        :label="$t('table.header.Experiment Name')"
        prop="experiment_name"
        width="150"
      >
      </el-table-column>
      <el-table-column align="center" :label="$t('table.header.Model')" prop="model" width="150">
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('table.header.Model Status')"
        prop="model_status"
        width="150"
      >
        <template slot-scope="scope">
          <div
            class="dtp-display-flex"
            style="
              column-gap: 10px;
              align-items: center;
              justify-content: center;
            "
          >
            <span>{{ $t("status." + `${scope.row.model_status}`)}}</span>
            <el-popover
              v-if="scope.row.failure_cause"
              :visible-arrow="false"
              placement="bottom-start"
              width="314"
              trigger="hover"
            >
              <div class="dtp-ma--1">{{ scope.row.failure_cause }}</div>
              <div slot="reference">
                <i
                  @click="$emit('init')"
                  class="
                    mdi mdi-information-outline
                    dtp-color-light-blue
                    dtp-cursor-pointer
                    dtp-mr--0
                  "
                  style="font-size: 12px"
                ></i>
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="!isCluster" :label="$t('table.header.Scores')" align="center">
        <el-table-column
          :label="metricLabels[scoreName]"
          prop="scores"
          align="center"
          v-for="scoreName in scoreNames"
          :key="scoreName"
        >
          <el-table-column
            :label="$t('table.header.Train')"
            align="center"
            :prop="
              JSON.stringify({
                scoreName,
                scoreHighlightType: 'Train_Highlight',
              })
            "
            :formatter="(row) => cellFormatter(row, scoreName, 'Train')"
          >
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Test')"
            align="center"
            :prop="
              JSON.stringify({
                scoreName,
                scoreHighlightType: 'Test_Highlight',
              })
            "
            :formatter="(row) => cellFormatter(row, scoreName, 'Test')"
          >
          </el-table-column>
        </el-table-column>
      </el-table-column>

      <el-table-column v-else :label="$t('table.header.Scores')" align="center">
        <el-table-column
          :label="metricLabels[scoreName]"
          prop="scores"
          align="center"
          v-for="scoreName in scoreNames"
          :key="scoreName"
        >
          <el-table-column
            align="center"
            :prop="
              JSON.stringify({
                scoreName,
                scoreHighlightType: 'Train_Highlight',
              })
            "
            :formatter="(row) => cellFormatter(row, scoreName, 'Train')"
          >
          </el-table-column>
        </el-table-column>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('table.header.Training Time')"
        prop="trainingtime"
        width="150"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { metricLabels } from "./metricTypes";

export default {
  props: {
    scoreNames: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    isCluster: {
      type: Boolean,
    },
  },
  data() {
    return {
      metricLabels: metricLabels,
      tableId: null,
    };
  },
  computed: {
    allModels() {
      return this.rows.map((rs) => rs.model);
    },
  },
  methods: {
    cellFormatter(row, scoreName, scoreType) {
      return row.scores?.[scoreName]?.[scoreType];
    },
    isRowSelectable(row) {
      return row.model_status === "SUCCESS";
    },
    clearSelection() {
      this.$refs.modelResultsTable.clearSelection();
    },

    getCellClassName({ row, column }) {
      if (column.property) {
        try {
          const parsed = JSON.parse(column.property);

          if (row[parsed.scoreHighlightType]?.includes(parsed.scoreName)) {
            return "table-cell-highlighted";
          }
        } catch (e) {
          return "";
        }
      }
    },
    handleSelectionChange(val) {
      this.$emit("selectionChanged", val);
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep .no-hover:hover > td {
  background-color: transparent;
}
::v-deep .table-cell-highlighted {
  color: #ffffff;
  background-color: #436da9 !important;
}

::v-deep thead tr th {
  background-color: #b2c4dc !important;
  border: 1px solid #dcdfe6 !important;
}
.el-table {
  font-size: 12px !important;
  border: 1px solid #d6d6d6 !important;
}

.el-table td {
  border: 1px solid #dcdfe6 !important;
  padding: 10px 0 !important;
}
.el-table th > .cell {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.el-table th.is-leaf {
  border-bottom: none !important;
  border-top: none !important;
}
::v-deep thead tr th div {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
}

::v-deep .el-table__row:nth-of-type(2n) {
  background-color: rgba(246, 246, 246, 1);
}

::v-deep .el-table__expand-icon {
  display: none;
}

::v-deep .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #939393;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #426da9;
  border-color: #426da9;
}
::v-deep .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 7px;
}
::v-deep .el-table--border .el-table__cell:first-child .cell {
  padding-left: 14px;
}
::v-deep .el-table-column--selection .el-checkbox.is-disabled {
  display: none;
}
</style>
