<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("Create New Cluster") }}
      <div
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12 dtp-chart-box-20">
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('cluster.Name')"
                class="eds-input_#input"
                maxlength="99"
                v-model="newName"
              />
            </div>
            <div
              v-if="!$v.newName.maxLength"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Name can not be longer than x characters", [$v.newName.$params.maxLength.max + 1]) }}
            </div>
            <div
              v-if="!$v.newName.required && $v.newName.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Name can not be empty") }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('cluster.Server Address')"
                class="eds-input_#input"
                v-model="newServerAddress"
              />
            </div>
            <div
              v-if="!$v.newServerAddress.required && $v.newServerAddress.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Server Address can not be empty") }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('cluster.Domain')"
                class="eds-input_#input"
                v-model="newDomain"
              />
            </div>
            <div
              v-if="!$v.newDomain.required && $v.newDomain.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Domain can not be empty") }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('cluster.Token')"
                class="eds-input_#input"
                v-model="newToken"
              />
            </div>
            <div
              v-if="!$v.newToken.required && $v.newToken.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Token can not be empty") }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                :placeholder="$t('cluster.Certificate')"
                class="eds-input_#input"
                v-model="newCertificate"
              />
            </div>
            <div
              v-if="!$v.newCertificate.required && $v.newCertificate.$dirty"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("cluster.Certificate can not be empty") }}
            </div>
          </div>
        </div>

        <div class="dtp-col dtp-col-12 dtp-text--right">
          <button
            @click="checkFieldsAndCreate"
            class="eds-button eds-button.basic"
          >
            {{ $t("buttons.create") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="vuelidate/dist/vuelidate.min.js"></script>
<script>
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  validations: {
    newName: {
      required,
      maxLength: maxLength(20),
    },
    newServerAddress: {
      required,
    },
    newDomain: {
      required,
    },
    newToken: {
      required,
    },
    newCertificate: {
      required,
    },
  },
  data() {
    return {
      newName: "",
      newServerAddress: "",
      newDomain: "",
      newToken: "",
      newCertificate: "",
    };
  },
  methods: {
    checkFieldsAndCreate() {
      this.$v.newName.$touch();
      this.$v.newServerAddress.$touch();
      this.$v.newDomain.$touch();
      this.$v.newToken.$touch();
      this.$v.newCertificate.$touch();

      if (!this.$v.$invalid) {
        this.$emit("create", {
          name: this.newName,
          server: this.newServerAddress,
          host: this.newDomain,
          bearerToken: this.newToken,
          caData: this.newCertificate,
        });
      }
    },
  },
};
</script>
