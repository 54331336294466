export default {
    methods: {
        checkAuth(userPrivileges, transaction) {
            if (process.env.VUE_APP_KEYCLOAK_ENABLED === "true") {
                return userPrivileges.indexOf(transaction) > -1;
            } else {
                return true;
            }
        }
    }
};
