import { formatDate } from "../../../../helper/format";
import store from '../../../../store';

export const DeploymentStatus = {
	Draft: "Draft",
	Live: "Live",
	ReadyToLive: "Ready to live",
}

export const COLUMNS = [
	{
		label: "Portfolio Name",
		field: "portfolioName",
		width: "200px",
		sortable: false,
	},
	{
		label: "Use Case",
		field: "useCaseName",
		width: "200px",
		sortable: false,
	},
	{
		label: "Experiment Name",
		field: "experimentName",
		width: "200px",
		sortable: false,
	},
	{
		label: "Model Name",
		field: "modelName",
		width: "250px",
		sortable: false,
	},
	{
		label: "Version (Tag)",
		field: "version",
		width: "200px",
		sortable: false,
	},
	{
		label: "Started By",
		field: "startedBy",
		width: "200px",
		sortable: false,
	},
	{
		label: "Built Date",
		field: "endTime",
		formatFn: value => formatDate(
			value,
			store.getters["Settings/Get/Preference"].longDateFormat
		),
		sortable: false,
		width: "200px"
	},
	{
		label: "Deployment Status",
		field: "deployment",
		formatFn: deployment => {
			/*	Draft -> deployments array boş veya hiç gelmemesi (found: true ; unknown: false iken)
				Live -> deployments array var, sync -> true
				Ready to live -> deployments array var, sync -> false
			*/

			if (deployment?.deployments?.length) {
				if (deployment.deployments.filter(i => i.sync === true).length)
					return DeploymentStatus.Live;
				else
					return DeploymentStatus.ReadyToLive;
				
			} else if (deployment.found === true && deployment.unknown === false) {
				return DeploymentStatus.Draft;
			}

			return "";
		},
		sortable: false,
		width: "200px"
	},
	{
		label: "Actions",
		field: "actions",
		sortable: false,
		width: "150px"
	},
	
];
export const PLOT_STATUS = {
	FAILED: "FAILED",
	NOT_APPLICABLE: "NOT APPLICABLE",
	NOT_AVAILABLE: "NOT_AVAILABLE"
}