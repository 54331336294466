<template>
  <div v-loading="loading" class="dtp-section">
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="dtp-row">
            <div
              class="dtp-col dtp-col-12 dtp-col-l12"
              style="display: flex; align-items: center"
            >
              <el-tooltip :content="$t('commons.back')" placement="top" effect="light">
                <button
                  @click="backButtonClicked"
                  style="
                    margin-right: 24px;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                  "
                >
                  <img
                    src="../../../../assets/images/icons/Automlback.svg"
                    alt=""
                  />
                </button>
              </el-tooltip>
              <div class="dtp-panel-formbox">
                <span class="dtp-aml-page-title dtp-mr--auto"
                  ><span
                    style="
                      font: normal normal medium 16px/50px;
                      letter-spacing: 0px;
                      color: #8c8c8c;
                      opacity: 1;
                    "
                    >{{ $t("modelGovernance.title") }} /</span
                  >
                  {{ $t("modelGovernance.Interpret") }}</span
                >
              </div>
              <div class="dtp-align-center dtp-ml--auto">
                <!--<div class="dtp-panel-formbox">
                  <span class="dtp-aml-font-bold dtp-fcolor-333333"
                    >Total Data Size: {{ formatSize(datasetData.size) }}</span
                  >
                </div>-->
                <div class="dtp-panel-formbox">
                  <span class="dtp-aml-font-bold dtp-fcolor-333333"
                    >{{ $t("table.columns") }}: {{ datasetData.column }}</span
                  >
                </div>
                <div class="dtp-panel-formbox">
                  <span class="dtp-aml-font-bold dtp-fcolor-333333"
                    >{{ $t("table.rows") }}: {{ datasetData.row }}</span
                  >
                </div>
              </div>
            </div>
            <div
              v-loading="loading"
              :element-loading-text="$t('helper.loading_data')"
              class="dtp-col dtp-col-12 vgt-custom-colon dtp-freeze-header"
            >
              <vue-good-table :columns="columns" :rows="rows">
                <template slot="table-row"> </template>
              </vue-good-table>
              <CustomPagination
                :lastPage="lastPage"
                :pageSize.sync="pageSize"
                :showPageSizeSelect="true"
                @change="(page) => (currentPage = page)"
              />
            </div>
          </div>
        </div>
      </pane>

      <pane min-size="30">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="dtp-pageviewtopbar">
            <interpret-right-panel
              :correlationColumns="columns.map((c) => c.field)"
              :summaryPlot="summaryPlot"
              :correlationPlot="correlationPlot"
              :reasonPlot="reasonPlot"
              @summaryOpened="summaryOpened"
              @correlationOpened="correlationOpened"
              @reasonOpened="reasonOpened"
            />
          </div>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { formatBytes } from "../../../../helper/format";
import privileges from "../../../../mixins/privileges";
import checkAuth from "../../../../mixins/authorization";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import CustomPagination from "../../CustomPagination";
import notify from "../../../../mixins/AutoML/notify";
import { Splitpanes, Pane } from "splitpanes";
import InterpretRightPanel from "./InterpretRightPanel.vue";
import { mapActions, mapGetters } from "vuex";
import { plotTypes } from "./data/types";
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../../store/modules/AutoML/Models/types';

export default {
  mixins: [checkAuth, privileges, notify],
  components: {
    VueGoodTable,
    CustomPagination,
    Splitpanes,
    Pane,
    InterpretRightPanel,
  },
  data() {
    return {
      pageSize: 20,
      currentPage: 1,
      plotTypes: plotTypes,
      summaryPlot: [],
      correlationPlot: [],
      reasonPlot: [],
    };
  },
  watch: {
    pageSize: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
  mounted() {
    this.requestComp = "Interpret";
    this.init();
  },
  computed: {
    ...mapGetters({
      modelExplanationSamples: MODELS_GETTERS.GET_MODEL_EXPLANATION_SAMPLES,
    }),
    totalItems() {
      return this.datasetData.row;
    },

    datasetData() {
      return {
        column: this.columns?.length ?? 0,
        row: this.modelExplanationSamples?.length ?? 0,
        size: 118,
      };
    },
    experimentId() {
      return this.$route.query.experiment_id;
    },
    modelId() {
      return this.$route.query.model_id;
    },
    columns() {
      try {
        return Object.keys(this.rows[0]).map((d) => ({
          label: d,
          field: d,
        }));
      } catch (e) {
        return [];
      }
    },
    rows() {
      try {
        return this.modelExplanationSamples.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        );
      } catch (e) {
        return [];
      }
    },
    lastPage() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },
  methods: {
    ...mapActions({
      fetchModelExplanationSamples: MODELS_ACTIONS.FETCH_MODEL_EXPLANATION_SAMPLES,
      postModelExplanationPlot: MODELS_ACTIONS.POST_MODEL_EXPLANATION_PLOT,
    }),
    async summaryOpened() {
      this.resetPlots();
      this.summaryPlot = await this.postModelExplanationPlot({
        requestComp: this.requestComp,
        model_id: this.modelId,
        plot_type: "shap_summary",
        params: {},
      });
    },
    async correlationOpened(features_list) {
      this.resetPlots();
      this.correlationPlot = await this.postModelExplanationPlot({
        requestComp: this.requestComp,
        model_id: this.modelId,
        plot_type: "shap_correlation",
        params: { features_list },
      });
    },
    async reasonOpened(rows_list) {
      this.resetPlots();
      this.reasonPlot = await this.postModelExplanationPlot({
        requestComp: this.requestComp,
        model_id: this.modelId,
        plot_type: "shap_reason",
        params: { rows_list },
      });
    },

    resetPlots() {
      this.summaryPlot = [];
      this.correlationPlot = [];
      this.reasonPlot = [];
    },
    refreshButtonClicked() {
      this.init();
    },
    async init() {
      const fetchPayload = {
        requestComp: this.requestComp,
        model_id: this.modelId,
      };

      this.fetchModelExplanationSamples(fetchPayload);
    },
    formatSize(size) {
      return formatBytes(size);
    },
    backButtonClicked() {
      this.$router.push({
        name: "ModelGovernance",
      });
    },
  },
};
</script>

