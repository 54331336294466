<template>
  <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
    <vue-good-table
      mode="remote"
      :ref="`${refName}-Table`"
      :columns="columns"
      :rows="rows"
      class="vgt-cursor-cell"
    >
      <template slot="table-column" slot-scope="props">
        <span>{{ $t("table.header." + props.column.label) }}</span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'description'">
          <EditableText
            :row="props.row"
            :cellPlaceholder="$t('placeholder.Type Here')"
            fieldName="description"
            idName="id"
            inputType="text"
            @applyClicked="updateDescription"
          >
          </EditableText>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <el-tooltip
            :content="$t('tooltip.delete')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../../assets/images/icons/automldeleteicon.svg"
              @click.stop="deleteTag(props.row.id)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
              alt="deleteIcon"
            />
          </el-tooltip>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import EditableText from "../../EditableCell/EditableText.vue";

export default {
  components: {
    VueGoodTable,
    EditableText,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    refName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRowIdForUpdate: null,
    };
  },
  methods: {
    deleteTag(id) {
      this.$emit("deleteTag", id);
    },
    updateDescription(r) {
      this.selectedRowIdForUpdate = null;
      const {id, description, name, type} = r;
      this.$emit("updateDescription", {id, description, name, type});
    },
  },
};
</script>

<style scoped></style>
