<template>
  <div class="dtp-row">
    <div
      v-if="currentState === formStates.AddNewDatasetNotClicked"
      class="dtp-col dtp-col-12 dtp-chart-box-20"
    >
      <span class="componentLabels">{{ $t('placeholder.Custom Model Name') }}</span>
      <div class="dtp-chart-box dtp-chart-box-20">
        <div class="eds-field_#control eds-field_.eds-input eds-input">
          <input
            type="text"
            :placeholder="$t('placeholder.Custom Model Name')"
            class="eds-input_#input"
            maxlength="99"
            v-model="formCustomModelName"
          />
        </div>
        <div
          v-if="!$v.formCustomModelName.maxLength"
          class="eds-field_#assistive-text eds-field_#assistive-text.error"
        >
          {{ $t("customModel.Custom model name can not be longer than x characters", [$v.formCustomModelName.$params.maxLength.max + 1]) }}
        </div>
      </div>

      <span class="componentLabels">{{ $t('placeholder.Description') }} ({{ $t("commons.optional") }})</span>
      <div class="eds-field_#control eds-field_.eds-input eds-input h-128">
        <textarea
          :placeholder="$t('placeholder.Description')"
          class="eds-input_#input"
          maxlength="500"
          v-model="formDescription"
        ></textarea>
      </div>
      <div
        v-if="!$v.formDescription.maxLength"
        class="eds-field_#assistive-text eds-field_#assistive-text.error"
      >
        {{ $t("customModel.Description can not be longer than x characters", [$v.formDescription.$params.maxLength.max + 1]) }}
      </div>

      <span class="componentLabels">{{ $t('placeholder.Model Object') }}</span>
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action=""
        :file-list="fileList"
        :auto-upload="false"
      >
        <div class="el-upload-dragger-content">
          <div class="el-upload__text">
            <span class="dtp-fcolor-3F88DE">{{
              $t("customModel.a valid model from local to upload") 
            }}</span>
          </div>
        </div>
      </el-upload>

      <span class="componentLabels">{{ $t('placeholder.Project') }}</span>
      <div class="dtp-aml-el-select">
        <el-select
          v-model="formSelectedProjectId"
          filterable
          :placeholder="$t('placeholder.Project')"
          v-loadmore="callLoadMoreProjects"
        >
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            {{ item.name }}
          </el-option>
        </el-select>
      </div>

      <span class="componentLabels">{{ $t('placeholder.Data Set') }}</span>
      <div class="dtp-aml-el-select" style="display: flex; column-gap: 12px">
        <DatasetFiltering
          @onSelected="callSelectedId"
        />
        <el-tooltip :content="$t('Add New Data Set')" placement="top" effect="light">
          <button
            @click="currentState = formStates.AddNewDatasetClicked"
            class="aml-btn eds-button.basic"
          >
            <Icon icon="add"></Icon>
          </button>
        </el-tooltip>
      </div>

      <span class="componentLabels">{{ $t('placeholder.Target Column') }}</span>
      <div class="dtp-chart-box dtp-chart-box-20">
        <div class="eds-field_#control eds-field_.eds-input eds-input">
          <input
            type="text"
            :placeholder="$t('placeholder.Target Column')"
            class="eds-input_#input"
            maxlength="99"
            v-model="formTargetColumn"
          />
        </div>
        <div
          v-if="!$v.formTargetColumn.maxLength"
          class="eds-field_#assistive-text eds-field_#assistive-text.error"
        >
          {{ $t("customModel.Target column can not be longer than x characters", [$v.formTargetColumn.$params.maxLength.max + 1]) }}
        </div>
      </div>

      <span class="componentLabels">{{ $t('placeholder.Problem Type') }}</span>
      <div class="dtp-aml-el-select">
        <el-select
          v-model="formSelectedProblemType"
          filterable
          :placeholder="$t('placeholder.Problem Type')"
        >
          <el-option
            v-for="item in problemTypes"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div v-if="selectDeviceTypeVisible">
        <span class="componentLabels">{{ $t('placeholder.Device Type') }} ({{ $t('commons.optional') }})</span>
        <div class="dtp-aml-el-select">
          <el-select
            v-model="formSelectedDeviceType"
            filterable
            :placeholder="$t('placeholder.Device Type')"
          >
            <el-option
              v-for="item in deviceTypes"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="dtp-text--right dtp-mt--2">
        <button
          class="eds-button eds-button.basic dtp-mr--1"
          @click="$emit('closePopup')"
        >
          {{ $t('commons.cancel') }}
        </button>
        <button
          class="eds-button eds-button.basic"
          @click="callDefineCustomModel"
        >
          {{ $t('commons.add') }}
        </button>
      </div>
    </div>

    <div v-else class="dtp-col dtp-col-12 dtp-chart-box-20">
      <div class="dtp-aml-el-select">
        <el-select
          v-model="formSelectedAddDatasetType"
          filterable
          :placeholder="$t('placeholder.Data Source')"
        >
          <el-option
            v-for="item in addDatasetTypes"
            :key="item.id"
            :label="$t(`${item.label}`)"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="dtp-text--right dtp-mt--2">
        <button
          class="eds-button eds-button.basic dtp-mr--1"
          @click="currentState = formStates.AddNewDatasetNotClicked"
        >
          {{ $t('commons.back') }}
        </button>
        <button
          class="eds-button eds-button.basic"
          @click="continueSelectDataSets()"
        >
          {{ $t('commons.continue') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script src="vuelidate/dist/vuelidate.min.js"></script>
<script>
import { MessageBox } from "element-ui";
import { maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { GENERAL_GETTERS } from "../../../../store/modules/AutoML/General/types";
import notify from "../../../../mixins/AutoML/notify";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../../store/modules/AutoML/Projects/types';
import { DATASET_ACTIONS } from '../../../../store/modules/AutoML/Dataset/types';
import { MODELS_ACTIONS } from '../../../../store/modules/AutoML/Models/types';
import Icon from "../../../Icons/Icon.vue"
import DatasetFiltering from "../../utils/DatasetFiltering.vue"

export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    Icon,
    DatasetFiltering,
  },
  validations: {
    formCustomModelName: {
      maxLength: maxLength(98),
    },
    formDescription: {
      maxLength: maxLength(499),
    },
    formTargetColumn: {
      maxLength: maxLength(98),
    },
  },
  mixins: [notify],
  data() {
    return {
      fileList: [],
      deviceTypes: [
        {
          id: "CPU",
          label: "CPU",
        },
        {
          id: "GPU",
          label: "GPU",
        },
      ],
      formStates: {
        AddNewDatasetNotClicked: "AddNewDatasetNotClicked",
        AddNewDatasetClicked: "AddNewDatasetClicked",
      },
      currentState: null,
      dataset: "",
      addDatasetTypes: [
        {
          id: "LocalFile",
          label: "File From Disk",
        },
        {
          id: "JdbcConnection",
          label: "Relational Database",
        },
      ],
      problemTypes: [
        {
          id: "classification",
          label: "Classification",
        },
        {
          id: "regression",
          label: "Regression",
        },
      ],
      datasetCount: 100,
      projectCount: 100,
      pageSize: 20,
      formCustomModelName: "",
      formDescription: "",
      formSelectedDatasetId: "",
      formSelectedDeviceType: "GPU",
      formSelectedAddDatasetType: "",
      formSelectedProblemType: "",
      formTargetColumn: "",
      formSelectedProjectId: "",
      selectDeviceTypeVisible: true,
    };
  },
  mounted() {
    this.requestComp = "experimentList";
    this.fetchDatasets({ page: 1, count: this.datasetCount });
    this.fetchProjects({ page: 1, count: this.projectCount });
    this.currentState = this.formStates.AddNewDatasetNotClicked;

    if (this.deviceType === this.deviceTypes[0].id) {
      this.formSelectedDeviceType = this.deviceTypes[0].id;
      this.selectDeviceTypeVisible = false;
    }
  },
  computed: {
    ...mapGetters({
      projectList: PROJECT_GETTERS.GET_PROJECTS,
      deviceType: GENERAL_GETTERS.GET_DEVICE_TYPE,
    }),
  },
  methods: {
    ...mapActions({
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
      fetchProjects: PROJECT_ACTIONS.FETCH_PROJECTS,
      defineCustomModel: MODELS_ACTIONS.DEFINE_CUSTOM_MODEL,
      fetchCustomModels: MODELS_ACTIONS.FETCH_CUSTOM_MODELS,
    }),
    creatDefineCustomModelPayload() {
      const fd = new FormData();
      const { uploadFiles } = this.$refs.upload;

      if (uploadFiles[0]?.raw) fd.append("file", uploadFiles[0]?.raw);

      fd.append(
        "data",
        JSON.stringify({
          name: this.formCustomModelName,
          description: this.formDescription,
          projectId: this.formSelectedProjectId,
          datasetId: this.formSelectedDatasetId,
          deviceType: this.formSelectedDeviceType,
          problemType: this.formSelectedProblemType,
          targetColumn: this.formTargetColumn,
        })
      );

      return fd;
    },
    async callDefineCustomModel() {
      if (!this.formCustomModelName?.length) {
        this.$notify.warning({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Custom model name field cannot be empty"),
          showClose: false,
        });
      }

      const result = await this.defineCustomModel({
        requestComp: this.requestComp,
        formData: this.creatDefineCustomModelPayload(),
      });
       
      if (result?.status === 200) {
        this.$emit("closePopup");
      }
      this.fetchCustomModels({ page: 1, count: this.pageSize });
    },
    continueSelectDataSets() {
      if (this.formSelectedAddDatasetType == "") {
        MessageBox.alert(this.$t("dataSource.Please choose a data source"), "Warning", {
          confirmButtonText: this.$i18n.t("alert.ok"),
        });
      } else if (this.formSelectedAddDatasetType == "LocalFile") {
        this.$router.push({ name: "CsvUploadAmL" });
      } else {
        this.$store.dispatch("Query/Set/FormData/Sql", "");
        this.$store.dispatch("Query/Set/FormData/Name", "");
        this.$router.push({ name: "NewEditViewquery" });
      }
    },
    callSelectedId(value){
      this.formSelectedDatasetId= value;
    },
    callLoadMoreProjects() {
      this.projectCount += 100;
      this.fetchProjects({ page: 1, count: this.projectCount });
    },
  },
};
</script>

<style scoped>
.orSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 16px/50px Roboto;
  letter-spacing: 0px;
  color: #9b9b9b;
}
.addNewDataSetSpan {
  letter-spacing: 0px;
  color: #436da9;
  font-weight: 500;
  font-size: 14px;
}
.addNewDataSetDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
::v-deep .el-upload-dragger {
  height: 120px !important;
  border-radius: 0px;
}
.componentLabels {
  display: block;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-top: 10px;
}

::v-deep .el-upload {
  display: block;
}
::v-deep .el-upload-list {
  padding: 0 !important;
}
::v-deep .el-upload-list__item {
  padding: 8px 5px;
  border-radius: 3px !important;
  background-color: #39a35f;
  border: 1px solid #d6d6d6;
}
::v-deep .el-upload-list__item:hover {
  background-color: #39a35f;
}
::v-deep .el-upload-list__item-name {
  color: #ffffff !important;
  font-size: 12px;
}
::v-deep .el-upload-list__item .el-icon-close,
::v-deep .el-upload-list__item .el-icon-upload-success {
  color: #ffffff !important;
}
::v-deep .el-upload-list__item-name [class^="el-icon"] {
  color: #ffffff;
}
::v-deep .el-upload-list__item .el-icon-close {
  top: 13px !important;
  right: 10px !important;
  display: block !important;
}
/* F0A7A */
::v-deep .el-upload-list__item .el-progress__text {
  position: absolute;
  right: 35px !important;
  top: -5px !important;
  color: #ffffff;
}
::v-deep .el-upload-list__item-status-label {
  top: 10px !important;
}
</style>
