var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('vue-good-table',{attrs:{"columns":_vm.tableCol,"rows":_vm.enhancedExperiments,"row-style-class":_vm.rowStyleClassFn},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(props.column.label))+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(props.row.active != true && props.row.status != 'COMPLETED')?_c('el-tooltip',{attrs:{"content":_vm.$t('tooltip.start'),"placement":"top","effect":"light"}},[_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-memory-icon",attrs:{"src":require("../../../../assets/images/icons/automl-memory-icon.svg"),"alt":"visualize"},on:{"click":function($event){$event.preventDefault();return _vm.start(props.row.project_id, props.row.id)}}})]):_vm._e(),(
            props.row.state.type == 'success' &&
              props.row.started_by == _vm.username
          )?_c('el-tooltip',{attrs:{"content":_vm.$t('tooltip.play'),"placement":"top","effect":"light"}},[_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-play-icon",attrs:{"src":require("../../../../assets/images/icons/automl-play-icon.svg"),"alt":"door"},on:{"click":function($event){$event.stopPropagation();return _vm.exploreData(props.row)}}})]):_vm._e(),(props.row.active == true && props.row.status != 'COMPLETED')?_c('el-tooltip',{attrs:{"content":_vm.$t('tooltip.stop'),"placement":"top","effect":"light"}},[_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-memory-stop-icon",attrs:{"src":require("../../../../assets/images/icons/automl-memory-stop-icon.svg"),"alt":"visualize"},on:{"click":function($event){$event.preventDefault();return _vm.stop(props.row.project_id, props.row.id, 'dtl')}}})]):_vm._e(),_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.clear'),"placement":"top","effect":"light"}},[(
              props.row.status == 'CREATED' ||
                props.row.status == 'PREPROCESSING' ||
                props.row.status == 'TRAINING' ||
                props.row.active == false
            )?_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-clear-icon",attrs:{"src":require("../../../../assets/images/icons/automl-clear-icon.svg"),"alt":"visualize"},on:{"click":function($event){$event.preventDefault();return _vm.clear({
                requestComp: _vm.requestComp,
                experimentId: props.row.id,
                type: 'dtl',
              })}}}):_vm._e()]),_c('el-tooltip',{attrs:{"content":_vm.$t('tooltip.delete'),"placement":"top","effect":"light"}},[_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-delete-icon",attrs:{"src":require("../../../../assets/images/icons/automl-delete-icon.svg"),"alt":"visualize"},on:{"click":function($event){$event.preventDefault();return _vm.deleteX(props.row.project_id, props.row.id, _vm.currentPage, 'dtl')}}})]),_c('el-tooltip',{attrs:{"content":_vm.$t('tooltip.model_governance'),"placement":"top","effect":"light"}},[(
              props.row.status == 'TRAINED' ||
                props.row.status == 'TUNED' ||
                props.row.status == 'COMPLETED'
            )?_c('img',{staticClass:"dtp-cursor-pointer dtp-vgt-actions-icon experiments-governance-icon",attrs:{"src":require("../../../../assets/images/icons/automlExperiment5.svg"),"alt":"visualize"},on:{"click":function($event){$event.preventDefault();return _vm.goGovernance(props.row)}}}):_vm._e()])],1):(props.column.field == 'status')?_c('span',{staticClass:"dtp-display-flex"},[_c('div',{staticClass:"dtp-table-status-icon"}),(props.row.state.type == 'success')?_c('span',[_vm._v(" ("+_vm._s(_vm.$t("status.Ready"))+") ")]):(props.row.state.type == 'warning')?_c('span',[_vm._v(" ("+_vm._s(_vm.$t("status.Loading"))+") ")]):(props.row.state.type == 'danger')?_c('span',[_vm._v(" ("+_vm._s(_vm.$t("status.Error"))+") ")]):_c('span',[_vm._v(" ("+_vm._s(_vm.$t("status.Created"))+") ")])]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"lastPage":_vm.lastPage,"pageSize":_vm.pageSize,"showPageSizeSelect":true},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"change":_vm.changePage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }