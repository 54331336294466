import store from '../../../../store';
import { formatDate } from "../../../../helper/format";

export const modelGovernanceColumns = [
  {
    label: "portfolio",
    field: "portfolio_name",
    tdClass: "vertical-center",
  },
  {
    label: "useCase",
    field: "use_case_name",
    tdClass: "vertical-center",
  },
  {
    label: "projectName",
    field: "project_name",
    tdClass: "vertical-center",
    width: "180px",
    sortable: true,
  },
  {
    label: "experimentName",
    field: "experiment_name",
    tdClass: "vertical-center",
    width: "150px",
    sortable: true,
  },
  {
    label: "model",
    field: "model_name",
    tdClass: "vertical-center",
    sortable: true,
  },
  {
    label: "modelDescription",
    field: "model_description",
    tdClass: "vertical-center",
  },
  {
    label: "performanceMetric",
    field: "performance_metric",
    tdClass: "vertical-center",
  },
  {
    label: "performanceValue",
    field: "performance_metric_value",
    tdClass: "vertical-center",
    sortable: true,
  },
  {
    label: "createdUser",
    field: "username",
    tdClass: "vertical-center",
    sortable: true,
  },
  {
    label: "creationDate",
    field: "creation_date",
    tdClass: "vertical-center",
    formatFn: value => formatDate(
      value,
      store.getters["Settings/Get/Preference"].longDateFormat
    ),
    sortable: true,
  },
  {
    label: "monitoringDate",
    field: "model_monitoring_date",
    tdClass: "vertical-center",
    formatFn: value => formatDate(
      value,
      store.getters["Settings/Get/Preference"].longDateFormat
    ),
    sortable: true,
  },
  {
    label: "comparisonIndex",
    field: "health_monitor",
    tdClass: "vertical-center",
    sortable: true,
  },
  {
    label: "benchmarkValue",
    field: "benchmark_value",
    tdClass: "vertical-center",
    sortable: true,
  },
  {
    label: "lastActivity",
    field: "last_activity",
    tdClass: "vertical-center",
  },
  {
    label: "deploymentStatus",
    field: "deployment",
    tdClass: "vertical-center",
  },
  {
    label: "reportGeneration",
    field: "ReportGeneration",
    tdClass: "vertical-center",
    width: "110px",
    sortable: false
  },
  {
    label: "Interpret",
    field: "Interpret",
    tdClass: "vertical-center",
    width: "110px",
    sortable: false
  },
  {
    label: "Build",
    field: "Build",
    tdClass: "vertical-center",
    width: "100px",
    sortable: false
  },
  {
    label: "actions",
    field: "Actions",
    tdClass: "vertical-center",
    width: "100px",
    sortable: false
  },
];
