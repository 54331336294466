export const podNames= [
  {
    value: "keycloak",
    label: "keycloak",
  },
  {
    value: "main_node",
    label: "main_node",
  },
  {
    value: "minio",
    label: "minio",
  },
  {
    value: "mlflow",
    label: "mlflow",
  },
  {
    value: "ingest-api-java",
    label: "ingest-api-java",
  },
  {
    value: "scheduler-java",
    label: "scheduler-java",
  },
  {
    value: "nginx",
    label: "nginx",
  },
  {
    value: "main-api-java",
    label: "main-api-java",
  },
  {
    value: "shap-explainer",
    label: "shap-explainer",
  },
  {
    value: "session",
    label: "session",
  },
  {
    value: "nifi",
    label: "nifi",
  },
  {
    value: "visualizer",
    label: "visualizer",
  },
  {
    value: "model",
    label: "model",
  },
  {
    value: "session-training-log",
    label: "session-training-log",
  },
  {
    value: "custom-model",
    label: "custom-model",
  },
  {
    value: "custom-experiment-session",
    label: "ncustom-experiment-session",
  },
];
