<template>
  <div>
    <div class="dtp-system-popup">
      <div class="dtp-system-info-box">
        <div class="dtp-row progress-container">
          <div class="dtp-chart-box-20">
            <span>{{ $t("systemResource.System Usage") }}</span>
          </div>
          <div class="dtp-chart-box-15">
            <div class="resource-title">
              <span>{{ $t("systemResource.CPU") }}</span>
              <span>{{ data.cpu }}%</span>
            </div>
            <div>
              <el-progress :percentage="data.cpu" :color="colorBar" :show-text= "false"></el-progress>
            </div>
          </div>
          <div class="dtp-chart-box-15">
            <div class="resource-title">
              <span>{{ $t("systemResource.CPU Memory") }}</span>
              <span>{{ data.cpuMemory }}%</span>
            </div>
            <div>
              <el-progress :percentage="data.cpuMemory" :color="colorBar" :show-text= "false"></el-progress>
            </div>
          </div>
          <div class="dtp-chart-box-15">
            <div class="resource-title">
              <span>{{ $t("systemResource.GPU") }}</span>
              <span>{{ data.gpu }}%</span>
            </div>
            <div>
              <el-progress :percentage="data.gpu" :color="colorBar" :show-text= "false"></el-progress>
            </div>
          </div>
          <div class="dtp-chart-box-15">
            <div class="resource-title">
              <span>{{ $t("systemResource.GPU Memory") }}</span>
              <span>{{ data.gpuMemory }}%</span>
            </div>
            <div>
              <el-progress :percentage="data.gpuMemory" :color="colorBar" :show-text= "false"></el-progress>
            </div>
          </div>
          <div class="dtp-chart-box-15">
            <div class="resource-title">
              <span>{{ $t("systemResource.Storage") }}</span>
              <span>{{ data.disk }}%</span>
            </div>
            <div>
              <el-progress :percentage="data.disk" :color="colorBar" :show-text= "false"></el-progress>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GENERAL_ACTIONS, GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters } from "vuex";
export default {

  mounted() {
    this.getStats();
  },
  computed: {
    ...mapGetters({
      data: GENERAL_GETTERS.FETCH_STATS,
    }),
  },
  methods: {
    ...mapActions({
      getStats: GENERAL_ACTIONS.FETCH_STATS,
    }),
    colorBar(value) {
     if(value < 24){
      return '#44ff44'
     }
     else if(value < 25){
      return '#ffc144'
     }
     else if(value < 49){
      return '#ff7744'
     }
     else if(value < 74){
      return '#ff4444'
     }
    },
  },
};
</script>
<style scoped>
.dtp-system-popup {
  position: fixed;
  top: 51px;
  right: 100px;
  margin: 0;
  padding: 0;
  border: 1px solid #d6d6d6;
}

.dtp-system-info-box {
  position: relative;
  width: 215px;
  height: 255px;
  margin: 0;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  color: #5f6164;
  border-bottom: 1px solid #d6d6d6;
}
.dtp-system-info-title {
  font-weight: 600;
}
.dtp-system-info-content {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.dtp-system-info-content p {
  margin-bottom: 0 !important;
  font-size: 14px;
}
.demo-progress .el-progress--line {
  margin-bottom: 15px;
  width: 350px;
}
.resource-title {
  display: flex;
  justify-content: space-between;
}
.progress-container {
  display: flex;
  flex-direction: column;
}
</style>
