<template>
  <div class="dtp-section">
    <div class="dtp-row">
      <div class="aml-section-header dtp-align-center">
        <div class="dtp-panel-formbox">
          <div class="dtp-page-title">
            <span>
              <Icon icon="customModels" size="xl"></Icon>
            </span>
            {{ $t("customModel.title") }}
          </div>
        </div>
        <div class="dtp-panel-formbox">
          <el-tooltip
            :content="$t('Add New Custom Model')"
            placement="top"
            effect="light"
          >
            <button
              @click="addNewCustomModel = !addNewCustomModel"
              class="aml-icon-btn"
            >
              <Icon icon="add"></Icon>
            </button>
          </el-tooltip>
          <CustomModelCreate
            v-if="isAddCustomModelButtonAvailable && addNewCustomModel"
            @closePopup="addNewCustomModel = $event"
          />
        </div>
        <!-- CREATE -->
        <PageViewBar
          :title="''"
          :activeFilters="true"
          :activeSearch="true"
          :tooltipSearchName="'Model'"
          :headerArray="filterArray"
          helperLink="list-datasets"
          @filterSaved="filterSaved"
          @deleteFiltered="deleteFilter"
          :filterList="filterList"
          :createBtn="false"
          :calculateview="false"
          @changedSearch="changedSearch"
        >
          <el-tooltip
            :content="$t('Refresh Table')"
            placement="top"
            effect="light"
            slot="bar-right"
          >
            <button
              class="aml-btn eds-button.primary"
              :loading="loading"
              @click="init()"
            >
              <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
            </button>
          </el-tooltip>
        </PageViewBar>
      </div>
    </div>
    <!-- ROW -->

    <div class="dtp-row">
      <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="customModels"
          @on-sort-change="(params) => onSortChange(params, init)"
        >
          <template slot="table-column" slot-scope="props">
            <span>{{ $t("table.header." + props.column.label) }}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <el-tooltip
                v-if="
                  isRetrainCustomModelButtonAvailable &&
                  isStatusFailed(props.row.status)
                "
                :content="$t('Retry')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/refreshicon.svg"
                  @click.stop="callRetryCustomModel(props.row.id)"
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  alt="refreshIcon"
                />
              </el-tooltip>
              <el-tooltip
                v-if="
                  isRetrainCustomModelButtonAvailable &&
                  isStatusReady(props.row.status)
                "
                :content="$t('Retrain')"
                placement="top"
                effect="light"
              >
                <!-- retrain iconu ile degistir -->
                <img
                  src="../../../assets/images/icons/retrain.svg"
                  @click.stop="
                    showRetrainCustomModelPopup(props.row.id, props.row)
                  "
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  alt="retrainIcon"
                />
              </el-tooltip>
              <el-tooltip
                v-if="
                  isRetrainCustomModelButtonAvailable &&
                  isStatusReady(props.row.status)
                "
                :content="$t('tooltip.retest')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/retrain.svg"
                  @click="showRetestCustomModelPopup(props.row)"
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  alt="retest icon"
                />
              </el-tooltip>
              <el-tooltip
                v-if="
                  isDeleteCustomModelButtonAvailable &&
                  isStatusReady(props.row.status)
                "
                :content="$t('tooltip.delete')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/automldeleteicon.svg"
                  @click.stop="callDeleteCustomModel(props.row.id)"
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  alt="deleteIcon"
                />
              </el-tooltip>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <div style="display: flex; column-gap: 5px">
                {{ $t("status." + getStatusTitle(props.row.status)) }}
                <el-popover
                  v-if="
                    isStatusBuildLogsShown(props.row.status) ||
                    isStatusErrorCauseShown(props.row.status)
                  "
                  :visible-arrow="true"
                  placement="bottom-start"
                  width="fit-content"
                  trigger="click"
                >
                  <div
                    v-if="isStatusBuildLogsShown(props.row.status)"
                    class="dtp-ma--0"
                  >
                    {{ props.row.buildLogs }}
                  </div>
                  <div
                    v-if="isStatusErrorCauseShown(props.row.status)"
                    class="dtp-ma--0"
                  >
                    {{ props.row.errorCause }}
                  </div>
                  <div slot="reference">
                    <i
                      class="mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0"
                      style="font-size: 12px"
                    ></i>
                  </div>
                </el-popover></div
            ></span>
            <span v-else-if="props.column.field == 'name'">
              <el-tooltip
                :content="$t('Model Id') + ': ' + `${props.row.id}`"
                placement="top"
                effect="light"
              >
                <span>{{ props.row.name }}</span>
              </el-tooltip>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          :totalItem="totalItem"
          @change="changePage"
        />
      </div>
    </div>
    <CustomModelRetrainPopup
      :row="selectedRow"
      :visible="customModelRetrainPopupVisible"
      @closePopup="customModelRetrainPopupVisible = false"
      @retrain="callRetrainCustomModel"
    />
    <CustomModelRetestPopup
      :row="selectedRow"
      :visible="isRetestPopupVisible"
      @onClose="customModelRetestPopupVisible = false"
      @onConfirm="callRetestCustomModel"
    />
  </div>
</template>

<script>
/* eslint-disable */
import privileges from "../../../mixins/privileges";
import checkAuth from "../../../mixins/authorization";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import preferences from "../../../mixins/AutoML/preferences";
import CustomPagination from "../CustomPagination";
import PageViewBar from "../Helper/Pageviewbar.vue";
import remoteDataSort from "../../../mixins/AutoML/remoteDataSort";
import helperLink from "../../../mixins/AutoML/helperLink";
import { customModelsColDef } from "./CustomModels.config";
import CustomModelCreate from "./Components/CustomModelCreate.vue";
import CustomModelRetrainPopup from "./Components/CustomModelRetrainPopup";
import CustomModelRetestPopup from "./Components/CustomModelRetestPopup";
import {
  MODELS_ACTIONS,
  MODELS_GETTERS,
  MODELS_MUTATIONS,
} from "../../../store/modules/AutoML/Models/types";
import Icon from "../../Icons/Icon.vue";
import { PRIVILEGES } from "../../../helper/authorization";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import { CUSTOM_MODEL_STATUSES } from "../../../models/customModel";

export default {
  mixins: [
    checkAuth,
    privileges,
    notify,
    helperLink,
    preferences,
    remoteDataSort,
    componentAvailabilityCheck,
  ],
  data() {
    return {
      selectedRow: {},
      customModelRetrainPopupVisible: false,
      customModelRetestPopupVisible: false,
      selectedDatasetSize: null,
      pageSize: 20,
      currentPage: 1,
      filterPopup: false,
      columns: customModelsColDef,
      ready: false,
      addNewCustomModel: false,
      dsName: "",
      searchName: "",
      filterArray: [],
      filterables: [
        { label: "Data Set Name", key: "datasetName", type: "input" },
        { label: "Model Name", key: "name", type: "input" },
        { label: "Creation Date", key: "createdTime", type: "input" },
        {
          label: "Status",
          key: "status",
          type: "select",
          values: [
            CUSTOM_MODEL_STATUSES.IMPORTED,
            CUSTOM_MODEL_STATUSES.BUILD_FAILED,
            CUSTOM_MODEL_STATUSES.INITIATE_FAILED,
            CUSTOM_MODEL_STATUSES.IMPORT_CANCELLED,
            CUSTOM_MODEL_STATUSES.VALIDATION_FAILED,
            CUSTOM_MODEL_STATUSES.IMPORT_FAILED,
            CUSTOM_MODEL_STATUSES.INITIATED,
            CUSTOM_MODEL_STATUSES.BUILDING,
            CUSTOM_MODEL_STATUSES.INITIATING,
            CUSTOM_MODEL_STATUSES.IMPORTING,
          ],
        },
      ],
    };
  },
  components: {
    VueGoodTable,
    CustomPagination,
    PageViewBar,
    CustomModelCreate,
    CustomModelRetrainPopup,
    Icon,
    CustomModelRetestPopup,
  },
  mounted() {
    this.requestComp = "customModels";
    this.init();
    this.initFilterArray();
  },
  computed: {
    ...mapGetters({
      customModels: MODELS_GETTERS.GET_CUSTOM_MODELS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      customModelsFilter: MODELS_GETTERS.GET_CUSTOM_MODELS_FILTER,
    }),
    lastPage() {
      return this.pagination.customModels?.lastPage ?? 1;
    },
    totalItem() {
      return this.pagination.customModels?.totalItem;
    },
    filterList() {
      let list = [];
      Object.keys(this.customModelsFilter).forEach((mdf) => {
        list.push({
          column_name: mdf,
          conditional_operator: "equal",
          value: this.customModelsFilter[mdf],
        });
      });
      return list;
    },
    isAddCustomModelButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_ADD_NEW_CUSTOM_MODEL
      );
    },
    isDeleteCustomModelButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DELETE_CUSTOM_MODEL_BUTTON
      );
    },
    isRetrainCustomModelButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_RETRAIN_CUSTOM_MODEL_BUTTON
      );
    },
    isRetestPopupVisible() {
      return this.customModelRetestPopupVisible;
    },
  },
  methods: {
    ...mapActions({
      fetchCustomModels: MODELS_ACTIONS.FETCH_CUSTOM_MODELS,
      retryCustomModel: MODELS_ACTIONS.RETRY_CUSTOM_MODEL,
      retrainCustomModel: MODELS_ACTIONS.RETRAIN_CUSTOM_MODEL,
      retestCustomModel: MODELS_ACTIONS.RETEST_CUSTOM_MODEL,
      deleteCustomModel: MODELS_ACTIONS.DELETE_CUSTOM_MODEL,
    }),
    ...mapMutations({
      setCustomModelsFilter: MODELS_MUTATIONS.SET_CUSTOM_MODELS_FILTER,
    }),
    callRetryCustomModel(id) {
      this.retryCustomModel({
        requestComp: this.requestComp,
        id,
      });
      this.init();
    },
    callRetrainCustomModel(form) {
      this.retrainCustomModel({
        requestComp: this.requestComp,
        id: this.selectedRow.id,
        bodyParams: form,
      });
      this.init();
      this.customModelRetrainPopupVisible = false;
      this.$nextTick(() => {
        this.selectedRow = {};
      });
    },
    showRetrainCustomModelPopup(id, bodyParams) {
      this.customModelRetrainPopupVisible = true;
      this.selectedRow = bodyParams;
    },
    showRetestCustomModelPopup(bodyParams) {
      this.customModelRetestPopupVisible = true;
      this.selectedRow = bodyParams;
    },
    callRetestCustomModel(params) {
      this.retestCustomModel({
        requestComp: this.requestComp,
        id: this.selectedRow.id,
        bodyParams: params,
      });
      this.init();
      this.customModelRetestPopupVisible = false;
      this.selectedRow = {};
    },
    callDeleteCustomModel(id) {
      this.deleteCustomModel({
        requestComp: this.requestComp,
        id,
      });
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
    getStatusTitle(status) {
      if (this.isStatusReady(status)) {
        return "Ready";
      } else if (this.isStatusFailed(status)) {
        return "Failed";
      } else if (this.isStatusInProgress(status)) {
        return "In Progress";
      }
    },
    isStatusReady(status) {
      return [CUSTOM_MODEL_STATUSES.IMPORTED].includes(status);
    },
    isStatusFailed(status) {
      return [
        CUSTOM_MODEL_STATUSES.BUILD_FAILED,
        CUSTOM_MODEL_STATUSES.INITIATE_FAILED,
        CUSTOM_MODEL_STATUSES.IMPORT_CANCELLED,
        CUSTOM_MODEL_STATUSES.VALIDATION_FAILED,
        CUSTOM_MODEL_STATUSES.IMPORT_FAILED,
      ].includes(status);
    },
    isStatusInProgress(status) {
      return [
        CUSTOM_MODEL_STATUSES.BUILDING,
        CUSTOM_MODEL_STATUSES.INITIATING,
        CUSTOM_MODEL_STATUSES.IMPORTING,
        CUSTOM_MODEL_STATUSES.INITIATED,
      ].includes(status);
    },
    isStatusBuildLogsShown(status) {
      return [CUSTOM_MODEL_STATUSES.BUILD_FAILED].includes(status);
    },
    isStatusErrorCauseShown(status) {
      return [
        CUSTOM_MODEL_STATUSES.INITIATE_FAILED,
        CUSTOM_MODEL_STATUSES.IMPORT_CANCELLED,
        CUSTOM_MODEL_STATUSES.VALIDATION_FAILED,
        CUSTOM_MODEL_STATUSES.IMPORT_FAILED,
      ].includes(status);
    },
    async changePage(page) {
      this.currentPage = page;
      this.init();
    },
    async init() {
      const pageObject = {
        page: this.currentPage,
        count: this.pageSize,
        name: this.searchName,
        ...this.sortConfig,
        ...this.customModelsFilter,
      };
      await this.fetchCustomModels({ params: pageObject });
      // await this.changePage(this.currentPage);
    },
    initFilterArray() {
      const alreadyFiltered = Object.keys(this.customModelsFilter);
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        if (alreadyFiltered.includes(fs.key)) {
          x = {
            isActive: true,
            data: this.customModelsFilter[fs.key],
            operator: fs.type === "input" ? "equal_input" : "equal_select",
          };
        }

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    filterSaved(val) {
      var filter = val.filter((x) => x.data && x.operator);
      if (filter.length > 0) {
        const otherFilters = {};
        val
          .filter((v) => v.isActive)
          .forEach((af) => {
            otherFilters[af.name] = af.data;
          });
        this.setCustomModelsFilter(otherFilters);
        this.currentPage = 1;
        this.init();
      } else {
        this.$alert("Please enter a search condition!", "Warning", {
          confirmButtonText: "OK",
        });
      }
    },
    deleteFilter(value) {
      const x = Object.assign({}, this.customModelsFilter);
      delete x[value.column_name];
      this.setCustomModelsFilter(x);
      this.filterArray.forEach((element) => {
        if (element.name == value.column_name) {
          element.operator = "";
          element.data = "";
          element.isActive = false;
        }
      });
      this.currentPage = 1;
      this.init();
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
  },
};
</script>
