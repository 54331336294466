
export default {
    data() {
        return {
            sortConfig: {
                sort_by: "",
                ascending: false,
            },
        };
    },
    methods: {
        onSortChange(params, callbackFunction) {
            if (params?.length) {
                if (params[0].type === 'none') {
                    this.sortConfig = {
                        sort_by: "",
                        ascending: false,
                    };
                } else {
                    this.sortConfig.sort_by = params[0].field;
                    this.sortConfig.ascending = params[0].type === 'asc';
                }
            }
            callbackFunction();
        },
    }
};
