<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')"
    >
    <div slot="title">
      {{ `${experiment} - ${model}` }}
      <span style="font-weight: bold">{{ "/ " + $t("Pipeline") }}</span>
      <el-button
        class="copyBtn"
        data-clipboard-action="copy"
        :data-clipboard-text="pipelineText"
        icon="el-icon-copy-document"
      ></el-button>
    </div>

    <div class="dtp-chart-box">
      <div class="dtp-view-card-content">
        <textarea
          disabled="false"
          id="pipelinetext"
          class="dtp-rows-textarea"
          rows="37"
          v-model="pipelineText"
        ></textarea>
      </div>

      <!-- {{ (pipeline, null, '\t') }}-->
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Clipboard from "clipboard";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';

export default {
  props: {
    visible: {
      type: Boolean,
    },
    selectedRow: {
      type: Object,
    },
    experiment: {
      type: String,
    },
    model: {
      type: String,
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
    selectedRow: {
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          const { project_id, experiment_id } = val;

          this.fetchPipeline({
            project_id,
            experiment_id,
          });
        }
      },
    },
  },
  data() {
    return {
      pipelineData: {},
      text: "",
      dialogVisible: false,
    };
  },

  mounted() {
    this.clipboard = new Clipboard(".copyBtn");
    this.clipboard.on("success", this.successFunc);
    this.clipboard.on("error", this.errorFunc);
  },
  methods: {
    ...mapActions({
      fetchPipeline: PROJECT_ACTIONS.FETCH_PIPELINE,
    }),
    successFunc(e) {
      console.info("Action:", e.action);
      console.info("Text:", e.text);
      console.info("Trigger:", e.trigger);
      // You can get the custom attributes on the target element (you can do some more processing based on this)
      e.trigger.dataset.test && console.log(e.trigger.dataset.test);
      // Clear selected state
      e.clearSelection();

      this.$notify({
        title: this.$t("alert.success"),
        message: this.$t("alert.Copied to clipboard"),
        type: "success",
        showClose: false,
      });
    },
    errorFunc(e) {
      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);

      this.$notify.error({
        title: this.$t("alert.error"),
        message: this.$t("alert.operationFailed"),
        showClose: false,
      });
    },
  },

  computed: {
    pipelineText() {
      return JSON.stringify(this.pipeline, null, "\t");
    },
    ...mapGetters({
      pipeline: PROJECT_GETTERS.GET_PIPELINE,
    }),
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  padding: 9px 10px;
  margin-left: 10px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
</style>