import { OPERATION_STATUSES } from "../models/operationStatus";
import store from "../store";
import { DATA_ACTIONS } from "../store/modules/AutoML/Data/types";
import { GENERAL_MUTATIONS } from "../store/modules/AutoML/General/types";
import { NAVIGATION_GETTERS, NAVIGATION_MUTATIONS } from "../store/modules/AutoML/Navigation/types";
import i18n from "../i18n";
import { getNotifyMessage } from "./notifyMessageFormat";

export async function checkIfOperationStatusIsAvailable(
  statusCheckPayload,
  successCallback = () => {},
  options = {}
) {
  let { 
    intervalCount = 120, 
    timeInMsBetweenRequests = 4000, 
    callReason = CALL_REASONS.POLLING, 
    callOperationStatus = true,
    callAsyncStatus = true 
  } = options;

  store.commit(GENERAL_MUTATIONS.SET_OPERATION_RUNNING_STATUS, true);

  while (intervalCount > 0) {
    const currentOperation = queryOperationByAction({ action: statusCheckPayload.action, experimentId: statusCheckPayload.experiment_id });

    if (!currentOperation) {
      callReason === CALL_REASONS.REFRESH && successCallback();
      break;
    }

    const operationStatus = await store.dispatch(
      DATA_ACTIONS.GET_OPERATION_STATUS,
      {
        ...statusCheckPayload,
        operation_id: currentOperation.operationId
      }
    );

    if (operationStatus != OPERATION_STATUSES.AVAILABLE) {
      intervalCount--;

      if (!callAsyncStatus) break;

      await new Promise((r) => setTimeout(r, timeInMsBetweenRequests));
    } else {
      if (currentOperation.operationId && callOperationStatus) { //! currentOperation.operationId: temp if check to prevent exceptions when calling async services backend haven't deployed yet. (filter, binning)
        const operationResult = await store.dispatch(
          DATA_ACTIONS.GET_OPERATION_STATUS_BY_ID,
          {
            experiment_id: statusCheckPayload.experiment_id,
            operation_id: currentOperation.operationId,
            requestComp: statusCheckPayload.requestComp
          }
        ); 
        
        const { status, result } = operationResult?.data?.data;
        const messageList = result.filter(i => i.message).map(i => { 
          const statusAndColumn = i.column ? `<span>${i.status} </span><b>${i.column}:</b>` : "";
          return `<div> ${ statusAndColumn } <span>${ getNotifyMessage(i) || i18n.t(i.message) }</span></div>` 
        }) 
        if (messageList.length) {
          store.commit(GENERAL_MUTATIONS.ADD_NOTIFY, { message: messageList.join(" "), requestComp: statusCheckPayload.requestComp, type: "warning", title: "Warning", dangerouslyUseHTMLString: true });
        }
        if (status === OPERATION_STATUSES.SUCCESS) {
          successCallback();
          clearOperation(statusCheckPayload, currentOperation);
        } else if (status === OPERATION_STATUSES.PROCESSING) {
          store.commit(GENERAL_MUTATIONS.ADD_NOTIFY, { message: i18n.t("processingChanges"), requestComp: statusCheckPayload.requestComp, type: "warning", title: "Warning" });
        } else if (status === OPERATION_STATUSES.FAILED) {
          store.commit(GENERAL_MUTATIONS.ADD_NOTIFY, { message: messageList.join(" "), requestComp: statusCheckPayload.requestComp, type: "error", title: i18n.t("alert.error"), dangerouslyUseHTMLString: true });
          clearOperation(statusCheckPayload, currentOperation);
        }
      } else {
        successCallback();
        clearOperation(statusCheckPayload, currentOperation);
      }

      break;
    }
  }

  store.commit(GENERAL_MUTATIONS.SET_OPERATION_RUNNING_STATUS, false);
}

export const refreshOperationStatus = async (
  statusCheckPayload,
  successCallback,
  options = {}
) => {
  const operationToQuery = queryOperationByAction({ experimentId: statusCheckPayload.experiment_id });
    
  if (operationToQuery) {
    await checkIfOperationStatusIsAvailable(
      {
        ...statusCheckPayload,
        action: operationToQuery.action
      },
      successCallback,
      {
        intervalCount: 1,
        callReason: CALL_REASONS.REFRESH,
        ...options
      },
    );
  } else {
    successCallback();
  }
};

const queryOperationByAction = ({ action, experimentId }) => {
  const routeName = store.getters[NAVIGATION_GETTERS.GET_ROUTE_NAME];
  const operations = store.getters[NAVIGATION_GETTERS.GET_ON_GOING_OPERATIONS]?.[experimentId]?.[routeName];

  if (action) {
    return operations?.find(i => i.action === action);
  }

  return operations?.[0];
}

const clearOperation = (payload, operation) => {
  store.commit(NAVIGATION_MUTATIONS.REMOVE_ON_GOING_OPERATION, {
    sectionName: store.getters[NAVIGATION_GETTERS.GET_ROUTE_NAME],
    action: payload.action || operation.action,
    experimentId: payload.experiment_id
  });
}

export const ASYNC_PROCESS_ACTIONS = {
  postFeatureGeneration: "postFeatureGeneration",
  dataBinning: "dataBinning",
  updateFeatureGeneration: "updateFeatureGeneration",
  typeConversion: "typeConversion",
  encoding: "encoding",
  featureImportances: "featureImportances",
  updateMissingValue: "updateMissingValue",
  scaling: "scaling",
  removeNaRows: "removeNaRows",
  updateProblemConfiguration: "updateProblemConfiguration",
  filter: "filter",
  sort: "sort",
  previousPipelineStep: "previousPipelineStep"
};

export const CALL_REASONS = {
  POLLING: "POLLING",
  REFRESH: "REFRESH"
};