<template>
  <div class="dtp-section">
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12 dtp-mb--0">
        <div class="dtp-chart-box">
          <span class="dtp-aml-page-title">
            {{ $t("csv_upload.title") }}
          </span>
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12">
        <div class="dtp-row">
          <div
            class="
              dtp-col dtp-col-4 dtp-col-l12
              dtp-pl--0
              dtp-aml-custom-csvCard dtp-aml-csv-card
            "
            style="height: fit-content"
          >
            <div class="dtp-chart-box">
              <div class="dtp-aml-csv-card-title">
                <span class="dtp-aml-font-medium">{{
                  $t("csv_upload.choose_your_file")
                }}</span>
              </div>
              <div
                class="dtp-row"
                style="padding-left: 15px; padding-right: 15px"
              >
                <div class="dtp-col dtp-col-4">
                  <div
                    class="eds-field_#control eds-field_.eds-input eds-input"
                    :class="{
                      'eds-input.error':
                        delimiterRequired && !csvUploadBodyData.delimiter,
                    }"
                  >
                    <input
                      type="text"
                      class="eds-input_#input"
                      :placeholder="$t('placeholder.Separator')"
                      ref="delimiter"
                      v-model="csvUploadBodyData.delimiter"
                      :class="{
                        'dtp-required':
                          delimiterRequired && !csvUploadBodyData.delimiter,
                      }"
                    />
                  </div>
                  <div
                    v-if="delimiterRequired && !csvUploadBodyData.delimiter"
                    class="
                      eds-field_#assistive-text eds-field_#assistive-text.error
                    "
                  >
                    {{ $t("csvUpload.Separator field can not be empty") }}
                  </div>
                </div>
                <div class="dtp-col dtp-col-4">
                  <div
                    class="eds-field_#control eds-field_.eds-input eds-input"
                  >
                    <el-tooltip
                      :content="$t('tooltip.nanvalues')"
                      placement="top"
                      effect="light"
                    >
                      <input
                        type="text"
                        class="eds-input_#input"
                        :placeholder="$t('placeholder.Na Values')"
                        v-model="csvUploadBodyData.naValue"
                      />
                    </el-tooltip>
                  </div>
                </div>
                <div class="dtp-col dtp-col-4">
                  <div
                    class="eds-field_#control eds-field_.eds-input eds-input"
                  >
                    <input
                      type="text"
                      @keydown.space.prevent
                      class="eds-input_#input"
                      :placeholder="$t('placeholder.Dataset Name')"
                      v-model="csvUploadBodyData.name"
                    />
                  </div>
                </div>
                <div class="dtp-col dtp-col-6 dtp-mt--1">
                  <label class="eds-checkbox">
                    <input
                      type="checkbox"
                      v-model="csvUploadBodyData.firstLineHeader"
                      class="eds-checkbox_#input"
                      checked=""
                    />
                    <span class="eds-checkbox_#label">{{
                      $t("csv_upload.use_first_row_as_header")
                    }}</span>
                  </label>
                </div>
              </div>
              <el-upload
                ref="upload"
                class="upload-demo"
                drag
                :limit="1"
                action=""
                :file-list="fileList"
                :on-change="handleImport"
                :on-progress="handleProgress"
                :on-remove="handleRemove"
                :auto-upload="false"
              >
                <div class="el-upload-dragger-content">
                  <div class="dtp-csv-filecontent">
                    <div class="el-upload__text">
                      {{ $t("csv_upload.drag") }}
                      <span class="dtp-fcolor-3F88DE dtp-font-bold">{{
                        $t("select")
                      }}</span>
                      {{ $t("csv_upload.file_to_upload") }}
                    </div>
                  </div>
                </div>
              </el-upload>
              <div class="dtp-row dtp-mt--1" style="padding-right: 15px">
                <div class="dtp-col dtp-col-12 dtp-text--right">
                  <button
                    v-if="!uploading"
                    :disabled="
                      uploadButtonStatus || !csvUploadBodyData.delimiter
                    "
                    class="eds-button eds-button.basic dtp-mr--1"
                    @click="checkWithPapaparse()"
                  >
                    {{ $t("csvUpload.Preview") }}
                  </button>

                  <button
                    v-if="uploading"
                    class="eds-button eds-button.basic"
                    @click="cancelUpload"
                  >
                    {{ $t("commons.cancel") }}
                  </button>
                  <button
                    v-else
                    :disabled="
                      uploadButtonStatus || !csvUploadBodyData.delimiter
                    "
                    class="eds-button eds-button.basic"
                    @click="submitUpload()"
                  >
                    {{ $t("csvUpload.Upload") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="dtp-col dtp-col-8 dtp-col-l12 dtp-pr--0 dtp-mb--3">
            <div class="dtp-mt--0 dtp-aml-csv-card">
              <div class="dtp-aml-csv-card-title">
                <span class="dtp-aml-font-medium">{{
                  $t("csv_upload.file_upload_progress")
                }}</span>
              </div>
              <div class="dtp-aml-csv-card-content">
                <div class="dtp-row dtp-text--center">
                  <div v-for="step in steps" :key="step" class="dtp-col">
                    <div
                      :style="calculateStepColor(step)"
                      :v-model="currentStep + step"
                      :class="{
                        test: step == currentStep || currentStep > step,
                        test2:
                          (step == 4 && allertMessage.length > 0) ||
                          errorMessage.length > 0,
                      }"
                      class="step"
                    ></div>
                  </div>
                </div>
                <div class="dtp-row dtp-text--center dtp-fontsize-12">
                  <div class="dtp-col">
                    <span class="dtp-aml-font-medium">{{
                      $t("csv_upload.select_file")
                    }}</span>
                  </div>
                  <div class="dtp-col">
                    <span class="dtp-aml-font-medium">{{
                      $t("csv_upload.upload")
                    }}</span>
                  </div>
                  <div class="dtp-col">
                    <span class="dtp-aml-font-medium">{{
                      $t("csv_upload.ready_to_use")
                    }}</span>
                  </div>
                </div>

                <div class="dtp-col-12 dtp-mt--3 dtp-text--right">
                  <span v-if="currentStep == 4 && allertMessage.length > 0"
                    >{{ $t("csv_upload.index_created_pattern_failed") }}
                    <br />{{ allertMessage }}</span
                  >
                  <span
                    v-if="uploading"
                    class="dtp-cursor-pointer dtp-font-bold"
                    >{{ $t("csv_upload.please_wait") }} %
                    {{ formDataProgress }}</span
                  >
                  <span
                    v-if="errorMessage.length > 0"
                    class="dtp-csv-err-box"
                    >{{ errorMessage }}</span
                  >
                  <router-link :to="{ name: 'DataSets' }">
                    <button
                      v-if="currentStep == 3 && errorMessage == ''"
                      class="eds-button eds-button.primary"
                    >
                      {{ $t("buttons.continue") }}
                    </button>
                  </router-link>
                </div>
              </div>
            </div>

            <div
              v-if="previewVisible"
              class="dtp-mt--0 dtp-aml-csv-card"
              style="margin-top: 20px !important"
            >
              <div class="dtp-aml-csv-card-title" style="margin-bottom: 0 !important">
                <span class="dtp-aml-font-medium"> {{ $t("csvUpload.Preview") }} </span>
              </div>
              <div
                class="dtp-aml-csv-card-content"
                style="padding: 0 !important"
              >
                <div class="dtp-scrollbar">
                  <div class="dtp-row dtp-text--center dtp-fontsize-12">
                    <table class="dtp-table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            v-for="(key, index) in papaparseFirstRows[0]"
                            :key="index"
                          >
                            {{ key }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in papaparseFirstRows.slice(1)"
                          :key="index"
                        >
                          <td v-for="(key, index) in item" :key="index">
                            {{ key }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GENERAL_GETTERS, GENERAL_MUTATIONS } from "../../../store/modules/AutoML/General/types";
import notify from "../../../mixins/AutoML/notify";
import { DATASET_ACTIONS } from '../../../store/modules/AutoML/Dataset/types';

const papa = require("papaparse");

export default {
  mixins: [notify],
  data() {
    return {
      previewVisible: false,
      uploadButtonStatus: true,
      delimiterController: false,
      fileController: false,
      fileList: [],
      indexName: "",
      patternName: "",
      errorMessage: "",
      allertMessage: "",
      uploading: false,
      currentStep: 1,
      //   upload: false,
      fileContent: null,
      widthInPercent: "",
      defaultColor: "#d8d8d8",
      errorColor: "#FF8888",
      successColor: "#6fc98f",
      expanded: 1,
      csvUploadBodyData: {
        name: "",
        delimiter: "",
        firstLineHeader: true,
        naValue: "",
        enforcedQuotation: false,
      },
      delimiterRequired: false,
      papaparseFirstRows: [],
    };
  },
  watch: {
    "csvUploadBodyData.delimiter": {
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.csvUploadBodyData.delimiter =
            this.csvUploadBodyData.delimiter.replace(/\s/g, "");
        }
      },
    },
  },
  mounted() {
    this.requestComp = "csvUpload";
  },
  computed: {
    ...mapGetters({
      formDataProgress: GENERAL_GETTERS.GET_FORMDATA_PROGRESS,
      axiosSource: GENERAL_GETTERS.GET_AXIOS_SOURCE,
    }),
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    steps: {
      get() {
        return 3;
      },
    },
  },
  methods: {
    ...mapActions({
      uploadCsv: DATASET_ACTIONS.UPLOAD_CSV,
      uploadPreviewCsv: DATASET_ACTIONS.UPLOAD_PREVIEW_CSV,
    }),
    ...mapMutations({
      setAxiosSource: GENERAL_MUTATIONS.SET_AXIOS_SOURCE,
    }),

    cancelUpload() {
      this.axiosSource?.cancel("CSV upload is canceled.");
      this.errorMessage = "CSV upload is canceled.";
      this.uploading = false;
      this.currentStep = 2;
    },
    // Return array of string values, or NULL if CSV string not well formed.
    CSVtoArray(text) {
      var re_valid =
        /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value =
        /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a;
    },
    createCsvPayload() {
      const fd = new FormData();
      const { uploadFiles } = this.$refs.upload;

      fd.append("file", uploadFiles[0].raw);

      const { naValue, ...others } = this.csvUploadBodyData;

      const arrayNaValues = this.CSVtoArray(naValue);
      fd.append("data", JSON.stringify({ ...others, naValues: arrayNaValues }));

      return fd;
    },
    createCsvPreviewPayload() {
      const fd = new FormData();

      const { name, delimiter } = this.csvUploadBodyData;

      fd.append("data", JSON.stringify({ name, delimiter }));

      return fd;
    },
    checkWithPapaparse() {
      if (!this.csvUploadBodyData.delimiter) {
        this.delimiterRequired = true;
        this.$refs.delimiter.focus();
        return;
      }

      this.previewVisible = true;
      const { delimiter } = this.csvUploadBodyData;
      const { uploadFiles } = this.$refs.upload;
      this.papaparseFirstRows = [];
      const self = this;

      return new Promise((resolve, reject) => {
        papa.parse(uploadFiles[0].raw, {
          delimiter,
          preview: 10,
          worker: true,
          header: false,
          error: function (error, file) {
            reject(error);
          },
          step: function (results, parser) {
            self.papaparseFirstRows.push(results.data);
          },
          complete: function (results, file) {
            resolve(results);
          },
        });
      });
    },
    async submitUpload() {
      this.setAxiosSource();
      this.currentStep = 1;
      this.allertMessage = "";
      this.errorMessage = "";

      const previewResult = await this.uploadPreviewCsv({
        requestComp: this.requestComp,
        formData: this.createCsvPreviewPayload(),
      });

      if (previewResult?.status === 200) {
        this.handleProgress();
        const result = await this.uploadCsv({
          requestComp: this.requestComp,
          formData: this.createCsvPayload(),
        });

        if (result?.status)
          result.status === 200
            ? this.handleSuccess(result)
            : this.handleError(result.code);
      } else {
        this.currentStep = 2;
        this.errorMessage =
          "Dataset name already exist! please change the name and try again.";
      }
    },
    encodeB64(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    calculateStepColor(step) {
      if (this.currentStep < step)
        return {
          backgroundColor: this.defaultColor,
        };
      if (step == 4 && this.allertMessage.length > 0)
        return {
          backgroundColor: this.errorColor,
        };
      if (step == 5)
        return {
          backgroundColor: this.defaultColor,
        };
      if (this.errorMessage.length > 0)
        return {
          backgroundColor: this.errorColor,
        };
      if (step === this.currentStep || this.currentStep > step) {
        return {
          backgroundColor: this.successColor,
        };
      } else {
        return {
          backgroundColor: this.defaultColor,
        };
      }
    },
    handleProgress: function () {
      this.uploading = true;
      this.errorMessage = "";
      this.allertMessage = "";
    },
    handleError(errorCode) {
      this.errorMessage = this.$t(`automl.errorCode.${errorCode}`);
      this.uploading = false;
      this.currentStep = 2;
    },
    handleSuccess() {
      this.currentStep = 3;
      this.uploading = false;
    },
    handleRemove(file, fileList) {
      if (fileList.length !== 0 && this.delimiterController) {
        this.fileController = true;
        this.uploadButtonStatus = false;
      } else {
        this.fileController = false;
        this.uploadButtonStatus = true;
        this.errorMessage = "";
      }
    },
    handleImport(file, fileList) {
      if (file.name.split(".")[1] != "csv") {
        this.errorMessage = this.$i18n.t("csv_upload.invalid_file_format");
      } else {
        if (fileList.length !== 0) {
          this.fileController = true;
          this.uploadButtonStatus = false;

          if (!this.csvUploadBodyData.name) {
            try {
              this.csvUploadBodyData.name = file.name.split(".")[0];
            } catch (err) {
              this.csvUploadBodyData.name = file.name;
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.el-table th.is-leaf {
  background: #f9fbfc !important;
  border: 1px solid #dcdfe6 !important;
}
::v-deep .el-progress-bar {
  display: none !important;
}
</style>


<style>
.disableUpload .el-upload-dragger {
  cursor: not-allowed !important;
}
.el-upload {
  display: block;
}

.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.test::before {
  content: "";
  position: absolute;
  left: 21px;
  top: 14px;
  width: 7px;
  height: 15px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  transform: rotate(45deg);
}
.test2::before {
  content: "";
  position: absolute;
  top: 23px;
  left: 15px;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(45deg);
  border: none;
  outline: none;
}
.test2::after {
  content: "";
  position: absolute;
  top: 23px;
  left: 15px;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(-45deg);
  border: none;
  outline: none;
}
.steps-line {
  position: absolute;
  width: 80%;
  top: 34%;
  left: 118px;
  right: 0;
  height: 3px;
}

.step-progression-line {
  position: absolute;
  width: 0%;
  height: 3px;
  background-color: #73b98b;
}
.step-progression-error {
  position: absolute;
  width: 0%;
  height: 3px;
  background-color: #ff8888;
}

.step {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 3px;
  z-index: 2;
  border: 1px solid #d6d6d6;
}
.el-upload-list {
  padding: 0 30px !important;
}
.dtp-aml-csv-card .el-upload-list__item {
  padding: 8px 5px;
  border-radius: 3px !important;
  background-color: #39a35f;
  border: 1px solid #d6d6d6;
}

.dtp-aml-csv-card .el-upload-list__item:hover {
  background-color: #39a35f;
}
.dtp-aml-csv-card .el-upload-list__item-name {
  color: #ffffff !important;
  font-size: 12px;
}
.dtp-aml-csv-card .el-upload-list__item .el-icon-close,
.dtp-aml-csv-card .el-upload-list__item .el-icon-upload-success {
  color: #ffffff !important;
}
.dtp-aml-csv-card .el-upload-list__item-name [class^="el-icon"] {
  color: #ffffff;
}
.el-upload-list__item .el-icon-close {
  top: 13px !important;
  right: 10px !important;
  display: block !important;
}
/* F0A7A */
.el-upload-list__item .el-progress__text {
  position: absolute;
  right: 35px !important;
  top: -5px !important;
  color: #ffffff;
}
.el-upload-list__item-status-label {
  top: 10px !important;
}
.dtp-csv-err-box {
  background-color: #e88185;
  padding: 8px 30px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid #d6d6d6;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}
.popconfirmcsv {
  max-width: 750px;
  max-height: 750px;
}
</style>
