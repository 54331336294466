import { mapGetters, mapMutations } from "vuex";
import { GENERAL_GETTERS, GENERAL_MUTATIONS } from "../../store/modules/AutoML/General/types";
import { MessageBox } from "element-ui";
import { responseStatusMapping } from "../../models/responseStatus";
import { getNotifyMessage } from "../../helper/notifyMessageFormat";

export default {
  data() {
    return {
      apiResponse: {},
      requestComp: ""
    };
  },
  watch: {
    notify: {
      deep: true,
      handler(val) {
        if (val) {
          if (val.status == "507") {
            MessageBox.alert(this.$i18n.t("experimentGPUOutOfSpace"), this.$i18n.t("insufficentGPUMemory"), {
              confirmButtonText: this.$i18n.t("alert.ok"),
            });
          } else {
            this.showNotifier(val);
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({ loading: GENERAL_GETTERS.GET_LOADING, notify: GENERAL_GETTERS.GET_NOTIFY }),
  },
  methods: {
    ...mapMutations({
      setLoading: GENERAL_MUTATIONS.SET_LOADING,
      resetNotify: GENERAL_MUTATIONS.RESET_NOTIFY,
    }),
    showNotifier(notify) {
      try {
        const message = getNotifyMessage(notify)
        const responseStatus = responseStatusMapping[notify.status];
        const resp = {
          type: notify.type || responseStatus?.type,
          title: this.$t(notify.title || responseStatus?.title),
          message: message || this.$t(notify.message),
          data: notify.data ?? "",
          serviceType: notify.serviceType,
          duration: 3000,
          status: notify.status,
          dangerouslyUseHTMLString: notify.dangerouslyUseHTMLString
        };
        (notify.isNotify ?? true) && notify.requestComp === this.requestComp && this.$notify(resp);
        this.apiResponse = resp;
      } catch (err) {
        //console.log(err)
      }
      //this.resetNotify();
    },
  },
};
