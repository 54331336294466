<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('helper.loading_data')"
    class="dtp-section"
  >
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-12">
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title">{{ $t(`${tabName}`) }}</span>
          </div>
          <div class="dtp-panel-formbox">
            <div
              class="eds-field_#control eds-field_.eds-input eds-input"
              :class="{
                'dtp-alertSelect': checkedRows.length > 0 && alertSelect,
              }"
            >
              <select
                v-model="selectedMetric"
                class="eds-input_#input eds-input_#input.dropdown"
                @change="(e) => changeMetric(e.target.value)"
              >
                <option value="" disabled>{{ $t("Target Tune Metric") }}</option>
                <option
                  v-for="m in selectedMetricList"
                  :key="`metric-${m}`"
                  :value="m"
                >
                  {{ metricLabels[m] }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12" v-loading="loading">
        <div>
          <FeautureImportance
            :scoreNames="scoreNames"
            :rows="enhancedRows"
            @rowChecked="rowChecked"
            @selectAllCheck="selectAllCheck"
          >
          </FeautureImportance>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  TUNE_MODEL_COLUMNS_CLASSFICATION,
  TUNE_MODEL_COLUMNS_REGRESSION,
  TUNE_MODEL_COLUMNS,
} from "../MultiTable//ExperianModel/Data/ExperianModelData";
import FeautureImportance from "./FeatureImportance.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { GETTER, ACTION, MUTATION } from "../../../store/modules/AutoML/General/types";
import pipeline from "../../../mixins/AutoML/pipeline";
import notify from "../../../mixins/AutoML/notify";
import { classification, regression, metricLabels } from "./metricTypes";
import EditableText from "../EditableCell/EditableText.vue";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { MODELS_ACTIONS, MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [pipeline, notify],
  components: {
    FeautureImportance,
    EditableText,
  },
  props: {},
  data() {
    return {
      tabName: "Model Tuning",
      modelColumns: TUNE_MODEL_COLUMNS,
      n_iterationvalue: 10,
      plot_path: "",
      checkedRows: [],
      metricList: {
        classification,
        regression,
      },
      metricLabels: metricLabels,
      selectedMetric: "",
      alertSelect: true,
      algorithmType: {
        Classification: "classification",
        Regression: "regression",
      },
      statusType: {
        Failed: "FAILED",
      },
      enhancedRows: [],
      scoreNames: [],
    };
  },
  watch: {
    modelResults: {
      handler() {
        this.initEnhancedModelResults();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
    await this.fetchPipeline({
      project_id: this.selectedExperiment[0].project_id,
      experiment_id: this.experimentId,
    });
    if (this.getProblemType === this.algorithmType.Classification) {
      this.modelColumns = TUNE_MODEL_COLUMNS_CLASSFICATION;
    } else {
      this.modelColumns = TUNE_MODEL_COLUMNS_REGRESSION;
    }
    await this.getResults();
  },
  computed: {
    ...mapGetters({
      datasetName: DATASET_GETTERS.GET_DATASET_NAME,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      modelResults: MODELS_GETTERS.GET_MODEL_RESULTS,
      getTuneModel: MODELS_GETTERS.GET_MODEL_TUNING,
    }),
    selectedMetricList() {
      return this.getProblemType ? this.metricList[this.getProblemType] : [];
    },

    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
  },

  methods: {
    ...mapActions({
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      fetchResultsList: MODELS_ACTIONS.FETCH_MODELS_RESULT,
    }),
    ...mapMutations({
      setModelTuning: MODELS_MUTATIONS.SET_MODEL_TUNING,
    }),
    initEnhancedModelResults() {
      if (this.modelResults) {
        const successResults = JSON.parse(
          JSON.stringify(this.modelResults)
        ).filter((item) => item.status !== this.statusType.Failed);

        if (
          this.getProblemType === this.algorithmType.Classification ||
          this.getProblemType === this.algorithmType.Regression
        ) {
          this.enhancedRows = successResults.map((item) => {
            let scores = {};
            this.scoreNames = [];

            this.selectedMetricList.forEach((sm) => {
              try {
                Object.assign(scores, {
                  [sm]: {
                    Train: item?.train[sm]?.toFixed(4),
                    Test: item?.test[sm]?.toFixed(4),
                  },
                });
                this.scoreNames.push(sm);
              } catch (e) {}
            });

            return {
              model: item?.model,
              model_id: item?.model_id,
              check: false,
              trainingtime: `${item?.train?.training_time}`,
              actionType: "auto",
              scores,
              Train_Highlight: item?.train?.highlights,
              Test_Highlight: item?.test?.highlights,
            };
          });
        }
      }
    },
    rowChecked(checkedModelArray) {
      this.enhancedRows.forEach((er) => {
        if (checkedModelArray.includes(er.model)) {
          this.$set(er, "check", true);
        } else {
          this.$set(er, "check", false);
          this.$set(er, "actionType", "auto");
        }
      });
    },
    selectAllCheck(value) {
      this.enhancedRows.forEach((er) => (er.check = value));
    },
    changeMetric(val) {
      this.alertSelect = false;
      this.selectedMetric = val;
      let tuneModel = JSON.parse(JSON.stringify(this.getTuneModel));
      tuneModel.metric = val;
      this.setModelTuning(tuneModel);
    },
    async getResults() {
      await this.fetchResultsList({
        requestComp: this.requestComp,
        datasetName: this.datasetName,
        experimentId: this.experimentId,
      });
    },
  },
};
</script>
<style scoped>
.dtp-alertSelect {
  border-color: #426da9;
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 1px #426da9;
}
</style>
