import Service from "../AutomlService";
let serviceLive = new Service(process.env.VUE_APP_AUTOML_LIVE_API_ENDPOINT);
let service = new Service(process.env.VUE_APP_AUTOML_MAIN_API_ENDPOINT);

export async function previousPipelineStep(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/pipeline/previous-step`,data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getExperimentStatus(projectId, experimentId) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/projects/${projectId}/experiments/${experimentId}/session/status`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
