import { GENERAL_MUTATIONS, GENERAL_ACTIONS, GENERAL_GETTERS } from "./types";
import axios from "axios";
import Vue from "vue";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const generalStore = {
  state: {
    axiosSource: null, //axiosSource for cancellation
    formDataProgress: 0,
    notify: {},
    loading: false,
    operationRunningStatus: false,
    stats: {},
    deviceType: "",
    /**
     * pagination eklenilecek table icin objeye onun adında bir item eklenilecek
     * asagidaki gibi ilgili methodda commit yapılması custompaginationin hangi sayfada oldugunu anlamasını saglar
        commit(GENERAL_MUTATIONS.SET_PAGINATION, {
          tableName: "experiments",pageInfo: result.data.pagination_info,
        });
     */
    pagination: {
      builds: {
        last_page: 1,
      },
      experiments: {
        last_page: 1,
      },
      projects: {
        last_page: 1,
      },
      models: {
        last_page: 1,
      },
      datasets: {
        last_page: 1,
      },
      dataList: {
        last_page: 1,
      },
      predictions: {
        last_page: 1,
      },
      customModels: {
        last_page: 1,
      },
      logExports: {
        last_page: 1,
      },
      applications: {
        last_page: 1,
      }
    },
  },
  getters: {
    [GENERAL_GETTERS.GET_FORMDATA_PROGRESS]: (state) => {
      return state.formDataProgress;
    },
    [GENERAL_GETTERS.GET_NOTIFY]: (state) => {
      return state.notify;
    },
    [GENERAL_GETTERS.GET_LOADING]: (state) => {
      return state.loading || state.operationRunningStatus;
    },
    [GENERAL_GETTERS.GET_DEVICE_TYPE]: (state) => {
      return state.deviceType;
    },
    [GENERAL_GETTERS.GET_AXIOS_SOURCE]: (state) => {
      return state.axiosSource;
    },
    [GENERAL_GETTERS.FETCH_STATS]: (state) => {
      return state.stats;
    },
    [GENERAL_GETTERS.GET_PAGINATION]: (state) => {
      return state.pagination;
    },
  },
  actions: {
    [GENERAL_ACTIONS.FETCH_STATS]: async ({ commit }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.stats,
        serviceMethodSub: serviceMethodSub.getStats,
        withSuccessNotify: false,
        withErrorNotify: false,
        successMutation: GENERAL_MUTATIONS.SET_STATS,
        resultSelector: (result) => result.data.data
      });
    },
    [GENERAL_ACTIONS.FETCH_DEVICE_TYPE]: async ({ commit }, { requestComp }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.device,
        serviceMethodSub: serviceMethodSub.fetchDeviceType,
        successMutation: GENERAL_MUTATIONS.SET_DEVICE_TYPE,
        resultSelector: (result) => result.data?.device_type,
        withSuccessNotify: false,
        loadingComponent: requestComp
      });
    },
    [GENERAL_ACTIONS.SET_FORMDATA_PROGRESS]: async ({ commit }, percentage) => {
      commit(GENERAL_MUTATIONS.SET_FORMDATA_PROGRESS, percentage);
    },
  },
  mutations: {
    [GENERAL_MUTATIONS.SET_FORMDATA_PROGRESS](state, val) {
      state.formDataProgress = val;
    },
    [GENERAL_MUTATIONS.SET_PAGINATION](state, info) {
      state.pagination[info.tableName] = info.pageInfo;
    },
    [GENERAL_MUTATIONS.SET_DEVICE_TYPE]: (state, val) => {
      Vue.set(state, "deviceType", val);
    },
    [GENERAL_MUTATIONS.SET_LOADING]: (state, val) => {
      state.loading = val;
    },
    [GENERAL_MUTATIONS.SET_STATS]: (state, val) => {
      Vue.set(state, "stats", val);
    },
    [GENERAL_MUTATIONS.ADD_NOTIFY](state, notify) {
      Vue.set(state, "notify", notify);
    },
    [GENERAL_MUTATIONS.RESET_NOTIFY](state) {
      state.notify = [];
    },
    [GENERAL_MUTATIONS.SET_AXIOS_SOURCE](state) {
      const axiosSource = axios.CancelToken.source();

      state.axiosSource = axiosSource;
    },
    [GENERAL_MUTATIONS.SET_OPERATION_RUNNING_STATUS]: (state, value) => {
      Vue.set(state, "operationRunningStatus", value);
    },
  },
};
