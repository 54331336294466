<template>
  <svg
    id="custom-models-pencil-ruler"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="20" height="20" stroke="none" />
      <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
    </g>
    <path
      id="Path_pencil-ruler"
      data-name="Path / pencil-ruler"
      d="M2.732,14.607v3.125H5.857l9.217-9.225L11.948,5.382,2.732,14.607m16.342.925-3.542,3.542L11.2,14.74l1.475-1.475.833.833,2.058-2.067,1.183,1.183L15.532,14.4l.883.833L17.6,14.065l1.475,1.467M5.74,9.257,1.39,4.932,4.932,1.39,6.4,2.865,4.34,4.932l.892.883,2.05-2.067L8.465,4.932,7.282,6.107l.833.833L5.74,9.257M17.49,6.065a.83.83,0,0,0,0-1.175L15.54,2.973a.83.83,0,0,0-1.175,0L12.832,4.5l3.125,3.125Z"
      transform="translate(-0.557 -0.557)"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
