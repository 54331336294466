import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { TAG_ACTIONS, TAG_GETTERS, TAG_MUTATIONS } from "./types";
import { GENERAL_MUTATIONS } from "../General/types";
import i18n from "../../../../i18n";

export const tagStore = {
    state: {
      tags: [],
    },
    getters: {
      [TAG_GETTERS.GET_TAGS]: (state) => {
        return state.tags;
      },
    },
    mutations: {
      [TAG_MUTATIONS.SET_TAGS](state, val) {
        Vue.set(state, "tags", val);
      },
    },
    actions: {
      [TAG_ACTIONS.FETCH_TAGS]: async ({ commit }, params) => {
        const { type, page = 1, count = 100 } = params;
        const allParams = type ? { type, page, count } : { page, count}
        const urlSearchParams = new URLSearchParams(allParams);
  
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.tags,
          serviceMethodSub: serviceMethodSub.getTagList,
          queryParam: urlSearchParams.toString(),
          successMutation: TAG_MUTATIONS.SET_TAGS,
          resultSelector: (result) => result.data?.data,
        });
      },
      [TAG_ACTIONS.CREATE_TAG]: async ({ commit, dispatch }, params) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.tags,
          serviceMethodSub: serviceMethodSub.createTag,
          bodyParam: params,
          successCallback: async () => {
            await dispatch(TAG_ACTIONS.FETCH_TAGS, {});
          }
        });
      },
      [TAG_ACTIONS.UPDATE_TAG]: async ({ commit, dispatch }, params) => {
        const { tag_id, ...others } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.tags,
          serviceMethodSub: serviceMethodSub.updateTag,
          queryParam: tag_id,
          bodyParam: others,
          successCallback: async () => {
            await dispatch(TAG_ACTIONS.FETCH_TAGS, {});
          }
        });
      },
      [TAG_ACTIONS.DELETE_TAG]: async ({ commit, dispatch }, { tag_id, requestComp }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.tags,
          serviceMethodSub: serviceMethodSub.deleteTag,
          queryParam: tag_id,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successCallback: async (result) => {
            if(result?.data?.number_of_linked_projects) {
              commit(GENERAL_MUTATIONS.ADD_NOTIFY, { message: i18n.t("tagCantBeDeletedWhenLinkedToProject", [result?.data?.number_of_linked_projects]), requestComp, type: "warning", title: "Warning" })
            } else {
              commit(GENERAL_MUTATIONS.ADD_NOTIFY, { requestComp, status: 200 })
              await dispatch(TAG_ACTIONS.FETCH_TAGS, {});
            }
          }
        });
      },
    }
}