import i18n from "../i18n";
export const parseMessageWithParameters = (message, parameters) => {
    let i = 0;
    return message.replace(/\{}/g, function() {
      return parameters[i++];
    });
  }

export const getNotifyMessage = (notify) => {
 return notify.parameters?.length ? parseMessageWithParameters((notify.code ? i18n.t(`automl.errorCode.${notify.code}`) : notify.message ), notify.parameters) : (notify.code ? i18n.t(`automl.errorCode.${notify.code}`) : '');
} 