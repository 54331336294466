<template>
  <div>
    <span>{{ $t("logExport.exportSelectTitle") }}</span>
    <div>
      <div class="main-div">
        <div>
          <div>{{ $t("logExport.dates") }}</div>
          <el-date-picker
            v-model="logExportsDatetimeValue"
            type="datetimerange"
            align="left"
            :start-placeholder="$t('commons.startDate')"
            :end-placeholder="$t('commons.endDate')"
            :default-time="['12:00:00', '08:00:00']"
          >
          </el-date-picker>
        </div>
        <div>
          <div>{{ $t("logExport.podNames") }}</div>
          <el-select
            v-model="selectedPodNames"
            :placeholder="$t('commons.select')"
            multiple
          >
            <el-option
              v-for="item in podNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            />
          </el-select>
        </div>

        <button
          class="aml-btn eds-button.basic search-btn"
          @click="searchLogs()"
        >
          {{ $t("logExport.export") }}
        </button>
      </div>
      <div
        class="table-container"
        v-loading="loading"
        :element-loading-text="$t('helper.loading_data')"
      >
        <vue-good-table
          :columns="columns"
          :rows="rows"
          max-height="330px"
          :fixed-header="true"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'status'">
              <div class="statusContainer">
                {{ $t(ACTION_STATUS[props.row.status].LABEL) }}
                <el-popover
                  :visible-arrow="false"
                  placement="bottom-start"
                  trigger="hover"
                >
                  <div
                    v-if="props.row.status === ACTION_STATUS.FAILED.KEY"
                    slot="reference"
                  >
                    <i
                      class="
                        mdi mdi-information-outline 
                        dtp-color-light-blue
                        dtp-cursor-pointer
                        dtp-mr--0
                      "
                      style="font-size: 12px"
                    ></i>
                  </div>
                  <span class="popOverMessage">
                    {{ $t(`${props.row.message}`) }}
                  </span>
                </el-popover>
              </div>
            </span>
            <span
              v-else-if="props.column.field == 'actions'"
              style="display: flex; gap: 12px;"
            >
              <div
                v-if="props.row.status == ACTION_STATUS.FAILED.KEY"
                slot="reference"
              >
                <el-tooltip v-if="isExportLogRetryButtonAvailable" :content="$t('Retry')" placement="top" effect="light">
                  <img
                    src="../../../assets/images/icons/refreshicon.svg"
                    @click="retryLogExport(props.row)"
                    class="dtp-cursor-pointer dtp-vgt-actions-icon"
                    alt="refreshIcon"
                  />
                </el-tooltip>
              </div>
              <div
                v-if="props.row.status == ACTION_STATUS.SUCCESS.KEY"
                slot="reference"
              >
                <el-tooltip
                  v-if="isExportLogDownloadButtonAvailable"
                  :content="$t('tooltip.download')"
                  placement="top"
                  effect="light"
                >
                  <img
                    src="../../../assets/images/icons/dtpdownloadicon-black.svg"
                    alt=""
                    class="dtp-vgt-actions-icon"
                    @click="checkExportStatus(props.row.id)"
                  />
                </el-tooltip>
              </div>
              <div v-if="props.row.status !== ACTION_STATUS.IN_PROGRESS.KEY">
                <el-tooltip
                  v-if="isExportLogDeleteButtonAvailable"
                  :content="$t('tooltip.delete')"
                  placement="top"
                  effect="light"
                >
                  <img
                    src="../../../assets/images/icons/automldeleteicon.svg"
                    @click="callDeleteLogExport(props.row.id)"
                    class="dtp-cursor-pointer dtp-vgt-actions-icon"
                    alt="deleteIcon"
                  />
                </el-tooltip>
              </div>
              <div
                v-if="props.row.status == ACTION_STATUS.IN_PROGRESS.KEY"
                slot="reference"
              >
                <el-tooltip v-if="isExportLogRetryButtonAvailable" :content="$t('Refresh Table')" placement="top" effect="light">
                  <img
                    src="../../../assets/images/dtprefreshicon-black.svg"
                    alt=""
                    style="height: 11px"
                    @click="checkExportStatus(props.row.id)"
                  />
                </el-tooltip>
              </div>
            </span>

            <span v-else-if="props.column.field == 'start_date'">
              {{ callFormatDate(props.row.start_date) }}
            </span>
            <span v-else-if="props.column.field == 'end_date'">
              {{ callFormatDate(props.row.end_date) }}
            </span>
            <span v-else>
              {{ props.row[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          @change="changePage"
          @update:pageSize="changePageSize"
        />
      </div>
      <div class="cancel-btn-container">
        <button
          class="aml-btn eds-button.basic dtp-mt--1 cancel-btn"
          @click="$emit('closed')"
        >
          {{ $t("commons.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { podNames } from "./select";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import CustomPagination from "../CustomPagination.vue";
import { formatDate } from '../../../helper/format';
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../store/modules/AutoML/Models/types';
import componentAvailabilityCheck from '../../../mixins/AutoML/componentAvailabilityCheck';
import { PRIVILEGES } from '../../../helper/authorization';
import notify from '../../../mixins/AutoML/notify';

export default {
  mixins: [notify, componentAvailabilityCheck],
  components: {
    VueGoodTable,
    CustomPagination,
  },
  data() {
    return {
      logExportsDatetimeValue: "",
      exportLogsPopupVisible: false,
      selectedPodNames: [],
      podNameOptions: podNames,
      columns: [
        { label: this.$t("commons.startDate"), field: "start_date" },
        { label: this.$t("commons.endDate"), field: "end_date" },
        { label: this.$t("logExport.podName"), field: "pod_names" },
        { label: this.$t("logExport.createdBy"), field: "started_by" },
        { label: this.$t("logExport.status"), field: "status" },
        { label: this.$t("logExport.actions"), field: "actions" },
      ],

      rows: [],
      ACTION_STATUS: {
        SUCCESS: {
          KEY: "SUCCESS",
          LABEL: "logExport.success",
        },
        FAILED: {
          KEY: "FAILED",
          LABEL: "logExport.failed",
        },
        IN_PROGRESS: {
          KEY: "IN_PROGRESS",
          LABEL: "logExport.inProgress",
        },
      },
      requestComp: "logExportPopup",
      pageSize: 20,
      searchName: "",
      currentPage: 1,
    };
  },
  async mounted() {
    this.logExportsDatetimeValue = [
      this.subtractHours(1).toString(),
      new Date().toString(),
    ];
    await this.collectLogExport();
  },
  computed: {
    ...mapGetters({
      getLogExport: MODELS_GETTERS.GET_LOG_EXPORTS,
      loading: GENERAL_GETTERS.GET_LOADING,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
    }),
    lastPage() {
      return this.pagination.logExports?.last_page ?? 1;
    },
    isExportLogDownloadButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DOWNLOAD
      )
    },
    isExportLogDeleteButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DELETE
      )
    },
    isExportLogRetryButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_RETRY
      )
    },
  },

  methods: {
    exportLogsClicked() {
      this.exportLogsPopupVisible = true;
    },
    subtractHours(numOfHours, date = new Date()) {
      date.setHours(date.getHours() - numOfHours);

      return date;
    },
    ...mapActions({
      fetchLogExport: MODELS_ACTIONS.FETCH_LOG_EXPORT,
      postLogExport: MODELS_ACTIONS.POST_LOG_EXPORT,
      deleteLogExport: MODELS_ACTIONS.DELETE_LOG_EXPORT,
      getLogExportStatus: MODELS_ACTIONS.GET_LOG_EXPORT_STATUS,
    }),
    async searchLogs() {
      await this.postLogExport({
        dateRange: this.logExportsDatetimeValue,
        selectedPodNames: this.selectedPodNames,
        requestComp: this.requestComp,
      });
      await this.collectLogExport();
    },
    async collectLogExport() {
      await this.fetchLogExport({
        requestComp: this.requestComp,
        count: this.pageSize,
        page: this.currentPage,
      });
      this.rows = this.getLogExport;
    },
    callFormatDate(date) {
      return formatDate(date, "DD.MM.YYYY HH:mm");
    },
    callRetryCustomModel(actions) {
      this.retryCustomModel({
        requestComp: this.requestComp,
        actions,
      });
    },
    async retryLogExport(row) {
      await this.postLogExport({
        dateRange: [row.start_date, row.end_date],
        selectedPodNames: row.pod_names.split(","),
        requestComp: this.requestComp,
      });
      await this.deleteLogExport({
        requestComp: this.requestComp,
        id: row.id,
      });
      await this.collectLogExport();
    },
    async checkExportStatus(id) {
      const selectedRow = this.getLogExport?.find((i) => i.id === id);

      if (!selectedRow) return;

      if (selectedRow.path) {
        this.downloadLogs(selectedRow);
      } else {
        await this.getLogExportStatus({ id, requestComp: this.requestComp });
        this.rows = this.getLogExport;
        const rowToDownload = this.getLogExport?.find((i) => i.id === id);
        if (rowToDownload) {
          this.downloadLogs(rowToDownload);
        }
      }
    },
    async callDeleteLogExport(rowId) {
      await this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t("log record")]),
        this.$t("confirmation.Delete Log Export"),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
          callback: async (confirm) => {
            if (confirm == "confirm") {
              await this.deleteLogExport({
                requestComp: this.requestComp,
                id: rowId,
              });
              await this.collectLogExport();
            }
          },
        }
      );
    },
    async changePage(page) {
      this.currentPage = page;
      await this.collectLogExport();
    },
    async changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
      await this.collectLogExport();
    },
    downloadLogs(row) {
      if (!row) return;

      const aTag = document.createElement("a");
      aTag.download = "Log Export " + row.start_date;
      aTag.href = row.path;
      aTag.click();
    }
  },
};
</script>
<style scoped>
.main-div {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}
.cancel-btn-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.table-container {
  margin-top: 34px;
}
.search-btn {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: unset;
  display: flex;
  align-items: center;
  background-color: #1d4e91;
  color: white;
  height: 38px;
  margin-top: 20px;
}
p {
  margin-bottom: 0px;
  color: black;
  font-weight: 500;
}
.cancel-btn {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: unset;
  display: flex;
  align-items: center;
  margin-left: auto;
  border: none;
  color: gray;
  background-color: white;
}
::v-deep .el-input__inner {
  border-radius: 0px;
}
::v-deep .el-date-editor .el-range-separator {
  width: auto;
}
.statusContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popOverMessage {
  font-size: 12px;
}
</style>