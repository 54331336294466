export const OperationType = {
    MathOperation: 'MathOperation',
    StringOperation: 'StringOperation',
    LogicalOperation: 'LogicalOperation',
    DatetimeOperation: 'DatetimeOperation',
    LogicalExpression: 'LogicalExpression'
};

export const Operators = [
    { label: "SUM", type: OperationType.MathOperation, paramsCount: 2, }, //min max <= >=
    { label: "SUBTRACT", type: OperationType.MathOperation, paramsCount: 2 },
    { label: "MULTIPLY", type: OperationType.MathOperation, paramsCount: 2 },
    { label: "DIVIDE", type: OperationType.MathOperation, paramsCount: 2 },
    { label: "POWER", type: OperationType.MathOperation, paramsCount: 2 },
    { label: "LN", type: OperationType.MathOperation, paramsCount: 1 },
    { label: "ABS", type: OperationType.MathOperation, paramsCount: 1 },
    { label: "LOG2", type: OperationType.MathOperation, paramsCount: 1 },
    { label: "LOG10", type: OperationType.MathOperation, paramsCount: 1 },
    { label: "TO_LOWER", type: OperationType.StringOperation, paramsCount: 1 },
    { label: "TO_UPPER", type: OperationType.StringOperation, paramsCount: 1 },
    { label: "REPLACE", type: OperationType.StringOperation, paramsCount: 2 },
    { label: "CONCAT", type: OperationType.StringOperation, paramsCount: 2 },
    { label: "IF", type: OperationType.LogicalOperation, paramsCount: 3 },
    { label: "AND", type: OperationType.LogicalExpression, paramsCount: 2 },
    { label: "OR", type: OperationType.LogicalExpression, paramsCount: 2 },
    { label: "GREATER_THAN", type: OperationType.LogicalOperation, paramsCount: 2 },
    { label: "LESS_THAN", type: OperationType.LogicalOperation, paramsCount: 2 },
    { label: "NOT_EQUAL", type: OperationType.LogicalOperation, paramsCount: 2 },
    { label: "EQUAL", type: OperationType.LogicalOperation, paramsCount: 2 },
    { label: "STARTS_WITH", type: OperationType.LogicalOperation, paramsCount: 2 },
    { label: "DAY", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "DAY_OF_WEEK", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "DAY_OF_YEAR", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "DAYS_IN_MONTH", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "HOUR", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "MINUTE", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "MONTH", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "SECOND", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "WEEKDAY", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "YEAR", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_LEAP_YEAR", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_MONTH_START", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_MONTH_END", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_QUARTER_START", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_QUARTER_END", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_YEAR_START", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_YEAR_END", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "QUARTER", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "STRFTIME", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_WEEKEND", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "SEASON", type: OperationType.DatetimeOperation, paramsCount: 2 },
    { label: "IS_HOLIDAY", type: OperationType.DatetimeOperation, paramsCount: 2 },
    // { label: "SHIFT_BYDATE", type: OperationType.DatetimeOperation, paramsCount: 2 },
    // { label: "ROLLING", type: OperationType.DatetimeOperation, paramsCount: 3 },
    // { label: "RESAMPLE", type: OperationType.DatetimeOperation, paramsCount: 4 },
];

export const parseFormula = function (str, columns) {
    var compiled = str.replace(/([a-zA-Z0-9_%/ :çğıöşüÇĞİÖŞÜ'".-]+)\s*(\W)/g, function (match, name, token) {
        if (token == "(")
            return "q(\"" + name + "\",";
        else
            return "value(\"" + name + "\")" + token;
    }).replace(/,\s*\)/g, ")");
    function q(name) {
        const nameTrimmed = name.trim();

        try {
            const { type, paramsCount } = Operators.find(o => o.label === nameTrimmed);
            const params = Array.prototype.slice.call(arguments, 1);
            if (params.length !== paramsCount) {
                console.log("Operator " + nameTrimmed + " requires exactly " + paramsCount + " parameters.")
            }
            return {
                type,
                operator: nameTrimmed,
                params: Array.prototype.slice.call(arguments, 1)
            };
        }
        catch (e) {
            console.log(nameTrimmed + " is not an operator")
        }
    }
    function value(name) {
        let nameParsed = name;
        if (!isNaN(name)) {
            nameParsed = Number(name)
        }
        return {
            type: columns.includes(name) ? "column" : "constant",
            value: nameParsed
        };
    }
    const singleQuotesReplaced = compiled.replace(/'/g, '');
    var f = eval("(function (){return " + singleQuotesReplaced + ";})");
    return f();
};