<template>
  <div class="bar-container">
    <div v-if="title" class="dtp-panel-formbox dtp-translateY-3">
      <span class="dtp-aml-page-title">{{ title }}</span>
    </div>
    <!-- CALCULATE -->
    <div class="dtp-panel-formbox" v-if="calculateview">
      <el-tooltip
        :content="$t('Create New Feature')"
        placement="top"
        effect="light"
      >
        <button
          @click="calculate = !calculate"
          class="aml-btn aml-btn-primary-soft"
        >
          <img src="../../../assets/images/SettingsIconAml.svg" alt="" />
        </button>
      </el-tooltip>
      <div v-if="calculate || tableCalculate">
        <DataCalculate
          :explorations="explorations"
          :operators="operators"
          :columns="columns"
          :rows="rows"
          :newCol="newCol"
          :isOpen="calculate || tableCalculate"
          @saveFormula="(params) => $emit('saveFormula', params)"
          @saveBinning="(params) => $emit('saveBinning', params)"
          @updateFormula="(params) => $emit('updateFormula', params)"
          @onClose="
            {
              $emit('closeFeatureGen', false);
              calculate = false;
            }
          "
        />
      </div>
    </div>
    <!-- SEARCH -->
    <el-tooltip
      v-if="activeSearch"
      :content="$t('searchByName', [$t(tooltipSearchName)])"
      placement="top"
      effect="light"
    >
      <div
        class="dtp-panel-formbox"
        :class="{ 'dtp-active-search': toggleSearch }"
      >
        <i
          @click="toggleSearch"
          class="el-icon-search aml-el-icon-search"
        ></i>
        <input
          @input="searchToggle()"
          type="search"
          class="dtp-form-input-search dtp-aml-form-input-search"
          :placeholder="$t('commons.search')"
          v-model="searchText"
        />
      </div>
    </el-tooltip>

    <slot name="bar-left"></slot>

    <div v-if="filterPopups">
      <FilterPopup
        :isOpen="filterPopups"
        :headerArray="headerArray"
        @filterSaved="filterSaved"
        @onClose="filterPopups = false"
      />
    </div>

    <div class="bar-right">

      <slot name="bar-right"></slot>

      <div v-if="activeFilters && filterList.length">
          <div
            v-for="(item, index) in filterList"
            :key="index"
            class="dtp-aml-filter-box"
          >
            {{ item.column_name }} {{ item.conditional_operator }} {{ item.value }}
            <i
              v-if="deletableFilter"
              @click="deleteFilter(item)"
              class="el-icon-error"
              aria-hidden="true"
            ></i>
          </div>
      </div>

      <el-tooltip
        v-if="activeFilters"
        :content="$t('tooltip.filters')"
        placement="top"
        effect="light"
      >
        <button
          class="aml-icon-btn"
          @click="filterPopups = !filterPopups"
        >
          <Icon icon="filter"></Icon>
          <div
            v-if="filterList && filterList.length > 0"
            class="dtp-filter-info"
          >
            {{ filterList.length }}
          </div>
        </button>
      </el-tooltip>

      <a 
        v-if="helperLink"
        :href="createHelperLink(helperLink)"
        rel="noopener noreferrer"
        target="_blank"
      >
        <el-tooltip :content="$t('Helper')" placement="top" effect="light">
          <button class="aml-icon-btn">
            <Icon icon="info"></Icon>
          </button>
        </el-tooltip>
      </a>
    </div>
  </div>
</template>
<script>
import FilterPopup from "../Popup/FilterPopup";
import DataCalculate from "../DataCalculate/DatacalculationPopup";
import notify from "../../../mixins/AutoML/notify";
import { serviceMethodSub } from '../../../api/ApiConstants';
import Icon from "../../Icons/Icon.vue";
import helperLink from '../../../mixins/AutoML/helperLink';

export default {
  mixins: [notify, helperLink],
  components: {
    FilterPopup,
    DataCalculate,
    Icon
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    routerLink: {
      type: String,
      default: null,
    },
    // isImportEnabled: {
    //   type: Boolean,
    //   required: true,
    // },
    activeSearch: {
      type: Boolean,
      default: false,
    },
    activeFilters: {
      type: Boolean,
      default: false,
    },
    headerArray: {
      type: Array,
      default: () => [],
    },
    filterList: {
      type: Array,
      default: () => [],
    },
    createBtn: {
      type: Boolean,
      default: false,
    },
    operators: {
      type: Array,
    },
    columns: {
      type: Array,
    },
    rows: {
      type: Array,
    },
    calculateview: {
      type: Boolean,
    },
    feautureGeneration: {
      type: Boolean,
      default: false,
    },
    tooltipSearchName: {
      type: String,
      default: "",
    },
    tableCalculate: {
      type: Boolean,
    },
    newCol: {
      type: Object,
    },
    deletableFilter: {
      type: Boolean,
      default: true,
    },
    explorations: {
      type: Array,
    },
    helperLink: {
      type: String
    }
  },
  data() {
    return {
      filterPopups: false,
      calculate: false,
      searchText: "",
      toggleSearch: true,
    };
  },
  mounted() {
    this.requestComp = "pageviewBar";
  },
  methods: {
    deleteFilter(value) {
      this.$emit("deleteFiltered", value);
    },
    searchToggle() {
      this.$emit("changedSearch", this.searchText);
    },
    filterSaved(filters) {
      this.$emit("filterSaved", filters);
    },
  },
  watch: {
    apiResponse: {
      deep: true,
      handler(val) {
        if (val.type === "success" && val.serviceType === serviceMethodSub.dataFilter) {
          this.filterPopups = false;
        } else if (
          val.type === "success" &&
          (val.serviceType === serviceMethodSub.dataFeatureGeneration ||
          val.serviceType === serviceMethodSub.dataUpdateFeatureGeneration)
        ) {
          this.calculate = false;
        }
      },
    },
  },
};
</script>
<style>
.aml-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
}
.aml-btn i {
  font-size: 18px;
}
.aml-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 14px;
  width: 14px;
}
.aml-btn-primary {
  background-color: #426da9;
  border-color: #805fbc;
}
.aml-btn-primary-inverse {
  background-color: #f1eaff;
  border-color: #805fbc;
}
.aml-btn-primary-soft {
  background-color: #f2f8ff;
  border-color: #436da9;
}
.dtp-aml-page-title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.dtp-aml-filter-box {
  position: relative;
  display: inline-block;
  width: auto;
  margin-right: 12px;
  padding: 2px 7px;
  font-size: 10px;
  color: #805FBC;
  background-color: #F1EAFF;
  border: 1px solid #805FBC;
  border-radius: 3px;
}
.dtp-aml-filter-box i {
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
}
</style>
<style scoped>
.bar-container {
  display: flex;
  flex: 1;
}
.bar-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
</style>