<template>
  <div class="dtp-row">
    <div
      v-for="inputKey in Object.keys(numericValueHolder)"
      :key="inputKey"
      class="dtp-col dtp-col-4"
    >
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          class="eds-input_#input"
          type="number"
          :placeholder="numericValueHolder[inputKey].placeholder"
          v-model="numericValueHolder[inputKey].value"
        />
      </div>
    </div>
    <div
      v-if="hasValidationError"
      class="dtp-col dtp-col-12"
      style="color: red"
    >
      {{ $t("validation.You should fill all fields") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resetKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      numericValueHolder: {
        lowerBound: {
          key: "lower_bound",
          placeholder: "Min",
          value: "",
        },
        upperBound: {
          key: "upper_bound",
          placeholder:this.$t("Max"),
          value: "",
        },
        increment: {
          key: "increment",
          placeholder: "H",
          value: "",
        },
      },
    };
  },
  watch: {
    numericValueHolder: {
      handler() {
        this.emitChanges();
      },
      deep: true,
    },
    resetKey() {
      this.resetChanges();
    },
  },
  computed: {
    hasValidationError() {
      let emptyValuesCount = 0;

      Object.keys(this.numericValueHolder).forEach((i) => {
        if (this.numericValueHolder[i].value === "") {
          emptyValuesCount++;
        }
      });

      if (emptyValuesCount === Object.keys(this.numericValueHolder).length) {
        return false;
      } else {
        return emptyValuesCount !== 0;
      }
    },
  },
  methods: {
    emitChanges() {
      let changedValues = {};

      Object.keys(this.numericValueHolder).forEach((i) => {
        changedValues = Object.assign(changedValues, {
          [this.numericValueHolder[i].key]: this.numericValueHolder[i].value,
        });
      });

      this.$emit("inputsChanged", changedValues);
    },
    resetChanges() {
      Object.keys(this.numericValueHolder).forEach((i) => {
        this.$set(this.numericValueHolder[i], "value", "");
      });

      this.$emit("resetFinished");
    },
  },
};
</script>