<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      id="Group_16994"
      data-name="Group 16994"
      transform="translate(450.884 -222.113)"
    >
      <path
        id="Path_9923"
        data-name="Path 9923"
        d="M-403.884,222.113h-44a3,3,0,0,0-3,3v44a3,3,0,0,0,3,3h44a3,3,0,0,0,3-3v-44A3,3,0,0,0-403.884,222.113Zm-1,46h-42v-42h42Z"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5584"
        data-name="Rectangle 5584"
        width="12"
        height="4"
        transform="translate(-423.884 235.113)"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5585"
        data-name="Rectangle 5585"
        width="12"
        height="4"
        transform="translate(-423.884 245.113)"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5586"
        data-name="Rectangle 5586"
        width="12"
        height="4"
        transform="translate(-423.884 255.113)"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5587"
        data-name="Rectangle 5587"
        width="12"
        height="4"
        transform="translate(-440.884 235.113)"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5588"
        data-name="Rectangle 5588"
        width="12"
        height="4"
        transform="translate(-440.884 245.113)"
        fill="#53AF33"
      />
      <rect
        id="Rectangle_5589"
        data-name="Rectangle 5589"
        width="12"
        height="4"
        transform="translate(-440.884 255.113)"
        fill="#53AF33"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
