export const navigationMappings = {
    "2": {
        routes: [
            {
                to: "1",
                warning: true
            }
        ]
    },
    "3_1": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            }
        ]
    },
    "3_2": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            }
        ]
    },
    "3_3": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            },
            {
                to: "3_2",
                warning: true
            }
        ]
    },
    "3_4": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            },
            {
                to: "3_2",
                warning: true
            },
            {
                to: "3_3",
                warning: true
            }
        ]
    },
    "3_5": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            },
            {
                to: "3_2",
                warning: true
            },
            {
                to: "3_3",
                warning: true
            },
            {
                to: "3_4",
                warning: true
            }
        ]
    },
    "4": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            },
            {
                to: "3_2",
                warning: true
            },
            {
                to: "3_3",
                warning: true
            },
            {
                to: "3_4",
                warning: true
            },
            {
                to: "3_5",
                warning: true
            }
        ]
    },
    "5_1": {
        routes: [
            {
                to: "1",
                warning: true
            },
            {
                to: "2",
                warning: false
            },
            {
                to: "3_1",
                warning: true
            },
            {
                to: "3_2",
                warning: true
            },
            {
                to: "3_3",
                warning: true
            },
            {
                to: "3_4",
                warning: true
            },
            {
                to: "3_5",
                warning: true
            },
            {
                to: "4",
                warning: true
            }
        ]
    },
    "5_2": {
        routes: [
            {
                to: "5_1"
            }
        ]
    },
    "6": {
        routes: [
        ]
    },
    "7": {
        routes: [
            {
                to: "6",
                warning: false
            }
        ]
    },
}