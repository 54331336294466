import { sortFnNumber } from "../../../../helper/vueGoodTableSortFunctions";

export const COLUMNS = [
    {
        label: "Status",
        field: "importance",
        width: "100px",
        sortable: false,
    },
    {
        label: "Name",
        field: "column_name",
    },
    {
        label: "Correlation",
        field: "correlation",
    },
    {
        label: "Variance",
        field: "variance",
		sortFn: sortFnNumber
    },
    {
        label: "Missing",
        field: "missing",
		sortFn: sortFnNumber
    },

]