export const callPost = (service) => async (path, params) => {
    let promise = new Promise((resolve, reject) => {
        service.post(path, params, (response) => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
    return promise;
}

export const callGet = (service) => async (path, queryParam = "") => {
    let promise = new Promise((resolve, reject) => {
        service.get(path + queryParam, (response) => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
    return promise;
}

export const callDelete = (service) => async (path, queryParam = "") => {
    let promise = new Promise((resolve, reject) => {
        service.delete(path + queryParam, (response) => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
    return promise;
}

export const callPut = (service) => async (path, queryParam, params) => {
    let promise = new Promise((resolve, reject) => {
        service.put(path + queryParam, params, (response) => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
    return promise;
}

export const callPostFormData = (service) => async (path, params) => {
    let promise = new Promise((resolve, reject) => {
        service.postFormData(path, params, (response) => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
    return promise;
}