<template>
  <el-dialog
    width="450px"
    :visible.sync="dialogVisible"
    @closed="$emit('onClose')"
  >
    <div slot="title">
      <span style="font-weight: bold; text-align. center">{{
        $t("tooltip.retest")
      }}</span>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12 dtp-chart-box-20 dtp-pa--0">
        <span class="componentLabels">{{ $t("Data Set") }}</span>
        <div class="dtp-aml-el-select">
          <el-select
            v-model="form.dataset_id"
            filterable
            :placeholder="$t('Data Set')"
            v-loadmore="callLoadMoreDatasets"
          >
            <el-option
              v-for="item in filterDataSets"
              :key="item.id"
              :label="`${item.name}  (${item.username}  - ${callFormatDate(
                item.created_at
              )})`"
              :value="item.id"
            >
              <span
                v-html="
                  styleDataSet(
                    item.name,
                    item.username,
                    callFormatDate(item.created_at)
                  )
                "
              ></span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="dtp-text--right dtp-mt--2">
      <button
        class="eds-button eds-button.basic dtp-mr--1"
        @click="
          $emit('onClose');
          dialogVisible = false;
        "
      >
        {{ $t("commons.cancel") }}
      </button>
      <button class="eds-button eds-button.basic" @click="onConfirm">
        {{ $t("commons.confirm") }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "../../../../helper/format";
import {
  DATASET_ACTIONS,
  DATASET_GETTERS,
} from "../../../../store/modules/AutoML/Dataset/types";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      datasetCount: 100,
      form: {
        dataset_id: null,
      },
      dialogVisible: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
  },
  mounted() {
    this.fetchDatasets({ page: 1, count: this.datasetCount });
  },
  computed: {
    ...mapGetters({
      dataSetList: DATASET_GETTERS.GET_DATASETS,
    }),
    filterDataSets() {
      return this.dataSetList.filter((x) => x.status == "READY");
    },
  },
  methods: {
    ...mapActions({
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
    }),
    callLoadMoreDatasets() {
      this.datasetCount += 100;
      this.fetchDatasets({ page: 1, count: this.datasetCount });
    },
    callFormatDate(date) {
      return formatDate(date, "DD/MM/YYYY HH:mm");
    },
    styleDataSet(name, username, date) {
      return (
        name +
        " " +
        "<span style='color:#416ca8; font-weight:bold;'>" +
        username +
        " " +
        "</span><span style='color:#43c984; font-weight:bold;'>" +
        date +
        "</span>"
      );
    },
    onConfirm() {
      this.$emit("onConfirm", this.form);
    },
  },
};
</script>

<style scoped>
.componentLabels {
  display: block;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-top: 10px;
}
</style>
