
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from "../../store/modules/AutoML/Projects/types";
import { navigationMappings } from "./navigationMappings";
export default {
    data() {
        return {
            lastStepDetailList: [],
            lastStep: "",
            routingName: "",
            linkCount: 0,
            steps: {
                feature_engineering_phase_1: "1",
                feature_engineering_phase_2: "2",
                algorithm_configuration: "3",
                tuning: "4",
            },
            routingSteps: {
                "problem_configuration": {
                    "route": "DataPreparation",
                    "linkCount": 2
                },
                "basic_transformation": {
                    "route": "DataPreparation",
                    "linkCount": 2
                },
                "feature_generation": {
                    "route": "Datacalculation",
                    "linkCount": 2
                },
                "missing_value_imputation": {
                    "route": "MissingList",
                    "linkCount": 2
                },
                "encoding": {
                    "route": "EncodingList",
                    "linkCount": 2
                },
                "scaling": {
                    "route": "ScalingList",
                    "linkCount": 2
                },
                "feature_selection": {
                    "route": "FeatureList",
                    "linkCount": 3
                },
                "algorithm_configuration": {
                    "route": "ModelSelection",
                    "linkCount": 4
                },


            },
            pipelineMainStep: {
                "basic_transformation": "feature_engineering_phase_1",
                "feature_generation": "feature_engineering_phase_1",
                "missing_value_imputation": "feature_engineering_phase_2",
                "encoding": "feature_engineering_phase_2",
                "scaling": "feature_engineering_phase_2",
                "feature_selection": "feature_engineering_phase_2"
            },
            urlMapping: {
                "DataPreview": {
                    "order": "1",
                    "menuname": this.$t("automl.dataPreperation"),
                    "pipeline_name": "problem_configuration"
                },
                "ExploreData": {
                    "order": "2",
                    "menuname": this.$t("automl.exploreData"),
                    "pipeline_name": ""
                },
                "DataPreparation": {
                    "order": "3_1",
                    "menuname": this.$t("automl.basicTransformationTitle"),
                    "pipeline_name": "basic_transformation"
                },
                "Datacalculation": {
                    "order": "3_2",
                    "menuname": this.$t("automl.featureGeneration"),
                    "pipeline_name": "feature_generation"
                },
                "MissingList": {
                    "order": "3_3",
                    "menuname": this.$t("automl.missingValueTitle"),
                    "pipeline_name": "missing_value_imputation"
                },
                "EncodingList": {
                    "order": "3_4",
                    "menuname": this.$t("automl.encodingTitle"),
                    "pipeline_name": "encoding"
                },
                "ScalingList": {
                    "order": "3_5",
                    "menuname": this.$t("automl.scalingTitle"),
                    "pipeline_name": "scaling"
                },
                "FeatureList": {
                    "order": "4",
                    "menuname": this.$t("automl.featureListTitle"),
                    "pipeline_name": "feature_selection"
                },
                "ModelSelection": {
                    "order": "5_1",
                    "menuname": this.$t("automl.model")
                },
                "ModelRunning": "5_2",
                "ModelResults": {
                    "order": "6",
                    "pipeline_name": ""
                },
                "TuneModel": {
                    "order": "7"
                },
            },
            navigationRules: navigationMappings
        };
    },
    watch: {

    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            lastPipeline: PROJECT_GETTERS.GET_PIPELINE,
        }),
        getProblemType() {
            return this.lastPipeline?.feature_engineering_phase_1?.[0]?.type;
        },
        problem_conf() {
            return this.lastPipeline?.feature_engineering_phase_1?.[0];
        },
    },
    methods: {
        getUrlNumber(url) {
            return this.urlMapping[url].order;
        },
        getUrlName(url) {
            return this.urlMapping[url].menuname;
        },
        getPipelineName(url) {
            return this.urlMapping[url].pipeline_name;
        },
        ...mapActions({
            fetchPipeline: PROJECT_ACTIONS.FETCH_PIPELINE,
        }),
        async getPipeline(experimentid, projectid) {
            if (experimentid && projectid) {
                await this.fetchPipeline({
                    project_id: projectid,
                    experiment_id: experimentid,
                });
                this.parsePipeLine(this.lastPipeline);
            }
        },
        parsePipeLine(plObj) {
            let lastStep = 0, stepName = "";
            Object.entries(plObj).forEach(([key, value]) => {
                if (value.length > 0) {
                    if (this.steps[key] > lastStep) {
                        lastStep = this.steps[key]
                        stepName = key;
                    }

                }
            })

            //Find Last Step
            const detailPipeLineStep = _.last(plObj[stepName]).pipeline_step;

            //Find Detai Step
            let detailList = []
            plObj[stepName].forEach((element) => {
                if (element.pipeline_step == detailPipeLineStep) {
                    detailList.push(element)
                }
            })
            this.lastStepDetailList = detailList;
            this.lastStep = detailPipeLineStep ? detailPipeLineStep : stepName;
            this.routingName = this.routingSteps[this.lastStep].route;
            this.linkCount = this.routingSteps[this.lastStep].linkCount;
        },
    },
};
