/*
import {analytics} from './modules/analytics';
import {analyticsAuthorization} from './modules/analyticsAuthorization';
const Store = {analytics,analyticsAuthorization}
export default Store;
*/

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

import modules from './modules';

export default new Vuex.Store({
    //for insert local storage
    plugins: [createPersistedState({
        key: 'navigation',
        paths: ['navigation'],
    })], 
    strict: true,
    modules,
});
