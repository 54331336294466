<template>
  <div class="dtp-section">
      <div class="dtp-row">
        <div class="aml-section-header dtp-align-center">
          <div class="dtp-panel-formbox">
            <div class="dtp-page-title">
              <span>
                <Icon icon="modelDeployment" size="xl"></Icon>
              </span>
              {{ $t("build.title") }}
            </div>
          </div>
          <PageViewBar
            :title="''"
            :activeFilters="true"
            :activeSearch="false"
            :tooltipSearchName="'Search Build'"
            :headerArray="filterArray"
            @filterSaved="saveFilter"
            @deleteFiltered="deleteFilter"
            :filterList="filterList"
            :createBtn="false"
            :calculateview="false"
            @changedSearch="(t) => (searchText = t)"
          >
          <el-tooltip :content="$t('Refresh Table')" placement="top" effect="light" slot="bar-right">
            <button
              class="aml-btn eds-button.primary"
              :loading="loading"
              @click="init()"
            >
              <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
            </button>
          </el-tooltip>
          </PageViewBar>
        </div>
      </div>
    <div class="dtp-row">
      <el-dialog
        width="450px"
        :visible.sync="dialogVisible"
        @closed="closeDialog"
      >
        <div slot="title">
          <span style="font-weight: bold; text-align. center">{{
            "Deploy Model"
          }}</span>
        </div>
        <div class="dtp-row dtp-align-center">
          <div class="dtp-col dtp-col-12 dtp-text--right">
            <div style="font-size: 15px; overflow: auto; padding-bottom: 20px">
              <div
                class="
                  eds-field_#control eds-field_.eds-input
                  eds-input
                  dtp-selectbox
                "
              >
                <select
                  v-model="selectedCluster"
                  class="eds-input_#input eds-input_#input.dropdown"
                >
                  <option selected disabled value="">{{ $t("cluster.Choose a Cluster") }}</option>
                  <option
                    v-for="item in clusters"
                    :key="item.id"
                    :value="item.id"
                    :title="
                      item.needsRepair
                        ? 'Cluster inactive! You can repair from Infrastructure Page. '
                        : ''
                    "
                    :style="item.needsRepair ? 'color: #E68A1F' : ''"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="dtp-col dtp-col-12 dtp-text--right">
            <button
              @click="confirmDeployModel"
              class="eds-button eds-button.basic"
            >
              {{ $t("commons.confirm") }}
            </button>
          </div>
        </div>
      </el-dialog>
      <BuildListTable
        :requestComp="requestComp"
        :portfolioOptions="portfolioOptions"
        :usecaseOptions="usecaseOptions"
        :rows="buildListRows"
        :columns="buildListColumns"
        :loading="loading"
        :isDeployBuildAvailable="isDeployBuildAvailable"
        :isDeleteBuildAvailable="isDeleteBuildAvailable"
        :isSampleApiRequestAvailable="isSampleApiRequestAvailable"
        :isModelMonitoringAvailable="isModelMonitoringAvailable"
        @deleteBuild="deleteBuild"
        @onDeployModel="deployModel"
        @onModelMonitoring="callModelMonitoring"
      />
      <CustomPagination
        class="buildListPagination"
        :lastPage="lastPage"
        :pageSize.sync="pageSize"
        :showPageSizeSelect="true"
        :totalItem="totalItem"
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { COLUMNS as BUILD_COLUMNS } from "./data/builds";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import preferences from "../../../mixins/AutoML/preferences";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import BuildListTable from "./BuildListTable.vue";
import PageViewBar from "../Helper/Pageviewbar";
import CustomPagination from "../CustomPagination";
import { mergeById } from "./data/utils";
import { PRIVILEGES } from "../../../helper/authorization";
import { TagTypes } from "./types";
import { TAG_ACTIONS, TAG_GETTERS } from '../../../store/modules/AutoML/Tags/types';
import { BUILD_ACTIONS, BUILD_GETTERS } from '../../../store/modules/AutoML/Build/types';
import { CLUSTER_ACTIONS, CLUSTER_GETTERS } from '../../../store/modules/AutoML/Clusters/types';
import { mapFilterResult } from '../Popup/filterPopupTypes';
import Icon from "../../Icons/Icon.vue";

export default {
  mixins: [notify, preferences, componentAvailabilityCheck],
  components: {
    BuildListTable,
    PageViewBar,
    CustomPagination,
    Icon
  },
  data() {
    return {
      dialogVisible: false,
      portfolioCreatePopup: false,
      searchText: "",
      filterList: [],
      filterArray: [],
      buildListColumns: BUILD_COLUMNS,
      selectedRowIdForUpdate: null,
      filterables: [
        { label: "Portfolio", key: "portfolio", type: "input" },
        { label: "Use Case", key: "useCase", type: "input" },
        { label: "Experiment Name", key: "experimentName", type: "input" },
        { label: "Version", key: "version", type: "input" },
        { label: "Deployed By", key: "deployedBy", type: "input" },
        { label: "Started By", key: "startedBy", type: "input" },
        { label: "Build Date", key: ["buildDateLowerBound", "buildDateUpperBound"], type: "dateRange" },
        { label: "Build Deployment Date", key: ["buildDeploymentDateLowerBound", "buildDeploymentDateUpperBound"], type: "dateRange" },
        { label: "Deployment Status", key: "deployStatus",type: "select", values: ["Live", "Draft", "Ready_to_live"] },
      ],
      pageSize: 20,
      currentPage: 1,
      selectedCluster: "",
    };
  },
  watch: {
    pageSize: {
      handler() {
        this.init();
      },
    },
    filterList: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  async mounted() {
    this.requestComp = "buildList";
    this.initFilterArray();
    await this.init();
  },
  computed: {
    ...mapGetters({
      tags: TAG_GETTERS.GET_TAGS,
      clusters: CLUSTER_GETTERS.GET_CLUSTERS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      buildList: BUILD_GETTERS.GET_BUILD_LIST,
      deploymentStatusOfBuilds: BUILD_GETTERS.GET_DEPLOYMENT_STATUS_OF_BUILDS,
    }),
    portfolioOptions() {
      return this.tags.filter((t) => t.type === TagTypes.PORTFOLIO);
    },
    usecaseOptions() {
      return this.tags.filter((t) => t.type === TagTypes.USE_CASE);
    },
    lastPage() {
      return this.pagination.builds?.lastPage ?? 1;
    },
    totalItem() {
      return this.pagination.builds?.totalItem;
    },
    buildListRows() {
      return mergeById(this.buildList, this.deploymentStatusOfBuilds);
    },
    isDeployBuildAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DEPLOY_BUILD
      );
    },
    isDeleteBuildAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DELETE_BUILD
      );
    },
    isSampleApiRequestAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_SAMPLE_REQ_API_BUTTON
      );
    },
    isModelMonitoringAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_MONITORING
      );
    },
    tagTypes() {
      return TagTypes;
    },
  },
  methods: {
    ...mapActions({
      fetchTags: TAG_ACTIONS.FETCH_TAGS,
      fetchBuilds: BUILD_ACTIONS.FECTH_BUILDS,
      deleteBuild: BUILD_ACTIONS.DELETE_BUILD,
      fetchClusters: CLUSTER_ACTIONS.FETCH_CLUSTERS,
      deployBuild: BUILD_ACTIONS.DEPLOY_BUILD,
    }),
    closeDialog() {
      this.dialogVisible = false;
      this.selectedCluster = "";
    },
    async confirmDeployModel() {
      const res = await this.deployBuild({
        buildId: this.selectedBuildId,
        clusterId: this.selectedCluster,
      });

      if (res) {
        this.closeDialog();

        setTimeout(() => {
          window.open(res, "_blank");
        }, 1000);
      }
    },
    async init() {
      let filters = {};
      this.filterList.forEach((f) => {
        filters = { ...filters, [f.column_name]: f.value };
      });
      await this.fetchBuilds({
        ...filters,
        page: this.currentPage,
        count: this.pageSize,
      });
      await this.fetchTags({});
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
    async deployModel(buildId) {
      this.selectedBuildId = buildId;
      await this.fetchClusters({ requestComp: this.requestComp });
      this.dialogVisible = true;
    },
    callModelMonitoring() {},

    saveFilter(filters) {
      const mappedFilters = mapFilterResult(filters);
      this.filterList = [];

      Object.keys(mappedFilters || {}).forEach((key) => {
          this.filterList.push({
            column_name: key,
            conditional_operator: "equal",
            value: mappedFilters[key],
          });
      });
    },
    deleteFilter(params) {
      this.filterList = this.filterList.filter(
        (f) => f.column_name !== params.column_name
      );

      this.filterArray.forEach((fa) => {
        if (fa.name === params.column_name) { 
          fa.isActive = false;
          fa.data = "";
          fa.operator = "";
        }
      });
    },
    initFilterArray() {
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
  },
};
</script>

<style scoped>
.buildListPagination {
  margin-left: 15px;
  margin-right: 15px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
  padding: 10px 20px 10px 20px;
}
</style>
