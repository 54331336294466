<template>
  <svg
    id="abacus"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      id="Boundary"
      fill="#1d4e91"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="22" height="22" stroke="none" />
      <rect x="0.5" y="0.5" width="21" height="21" fill="none" />
    </g>
    <path
      id="Path_abacus"
      data-name="Path / abacus"
      d="M4.75,4.833H6.583v5.5H4.75v-5.5m4.583,0H7.5v5.5H9.333v-5.5M4.75,17.667H6.583v-5.5H4.75v5.5m4.583-5.5H7.5v5.5H9.333V15.833h4.583V14H9.333V12.167M2,19.5H3.833V3H2V19.5M18.5,3V6.667H12.083V4.833H10.25v5.5h1.833V8.5H18.5V14H16.667V12.167H14.833v5.5h1.833V15.833H18.5V19.5h1.833V3Z"
      transform="translate(-0.167 -0.25)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
