import { mapActions, mapGetters, mapMutations } from "vuex";
import { GENERAL_GETTERS, GENERAL_MUTATIONS } from "../../store/modules/AutoML/General/types";
import { formatBytes } from "../../helper/format";
import { getExperimentStatus } from "../../api/AutoMlServices/pipeline"
import {
  OperatorKey,
} from "../../components/AutoMl/Popup/filterPopupTypes";
import { ASYNC_PROCESS_ACTIONS, checkIfOperationStatusIsAvailable } from "../../helper/asyncStatusChecker";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from "../../store/modules/AutoML/Projects/types";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from "../../store/modules/AutoML/Experiments/types";
import { DATASET_ACTIONS, DATASET_GETTERS } from "../../store/modules/AutoML/Dataset/types";
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from "../../store/modules/AutoML/Data/types";
import { MODELS_MUTATIONS } from "../../store/modules/AutoML/Models/types";
import { NAVIGATION_MUTATIONS } from "../../store/modules/AutoML/Navigation/types";

export default {
  data() {
    return {
      pageSize: 20,
      currentPage: 1,
      requestComp: "",
      pipelineMainStep: {
        basic_transformation: "feature_engineering_phase_1",
        feature_generation: "feature_engineering_phase_1",
        missing_value_imputation: "feature_engineering_phase_2",
        encoding: "feature_engineering_phase_2",
        scaling: "feature_engineering_phase_2",
        feature_selection: "feature_engineering_phase_2"
      },
      pollRequstCount: 0,
      liveDataPollStatus: true,
      isPaginationVisible: true,
      callAsyncStatus: true,
      experimentStatusPollingCount: 0
    }
  },
  watch: {
    pageSize: {
      handler(val) {
        this.fetchData({
          requestComp: this.requestComp,
          experiment_id: this.experimentId,
          page: this.currentPage,
          count: val,
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      datasetName: DATASET_GETTERS.GET_DATASET_NAME,
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      rawData: DATA_GETTERS.GET_DATA,
      datasetData: DATASET_GETTERS.GET_DATASET,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      experimentPipeLine: PROJECT_GETTERS.GET_PIPELINE,
      expStatus: DATA_GETTERS.GET_STATUS,
    }),
    filterHistory() {
      const node = this.pipelineMainStep[this.requestComp];
      const filterHist = [];
      if (this.experimentPipeLine[node]) {
        const result = this.experimentPipeLine[node].filter(p => p.pipeline_operation === "filter" && p.pipeline_step == this.requestComp)
        result.forEach(element => {
          filterHist.push(...element.expression.expressions)
        });
        return (filterHist && filterHist.length > 0) ? filterHist : [];
      }
    },
    experimentId() {
      return this.$route.query.experimentId;
    },

    dataColumns() {
      let result = this.explorations.map((exp) => ({
        label: exp.name,
        field: exp.name,
      }));
      return result;
    },
    lastPage() {
      return Math.ceil((this.pagination.dataList?.total_item ?? 1) / this.pageSize);
    },
    totalItem() {
      return this.pagination.dataList?.total_item;
    },
    filterArray() {
      return this.explorations.map((e) => {
        return {
          alias: e.name,
          name: e.name,
          type: e.type,
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        };
      });
    },
  },
  methods: {
    async onSortChange(experimentId, val) {
      if (!val || !val.length) {
        return;
      }

      const column = val[0].field;
      const ascending = val[0].type === 'asc';

      await this.dataSort({
        experimentId,
        requestComp: this.requestComp, 
        payload: {
          columns: [
            column
          ],
          ascending
        }
      })

      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.sort
        },
        () => this.initLiveData(this.requestComp),
        { callAsyncStatus: this.callAsyncStatus }
      )
    },
    routeByStatus() {
      if (this.selectedExperiment[0].status == "TRAINED") {
        this.setNavigationCount(5);
        this.$router.push({
          name: "ModelResults",
          query: { experimentId: this.experimentId },
        });
      } else if (this.selectedExperiment[0].status == "COMPLETED") {
        this.setNavigationCount(4);
        this.$router.push({
          name: "ModelSelection",
          query: { experimentId: this.experimentId },
        });
      } else if (this.selectedExperiment[0].status == "TRAINING") {
        this.setNavigationCount(4);
        this.$router.push({
          name: "ModelRunning",
          query: { experimentId: this.experimentId },
        });
      } else if (this.selectedExperiment[0].status == "TUNING") {
        this.setNavigationCount(4);
        this.$router.push({
          name: "ModelRunning",
          query: { experimentId: this.experimentId },
        });
      } else if (this.selectedExperiment[0].status == "TUNED") {
        this.setNavigationCount(6);
        this.$router.push({
          name: "TuneModel",
          query: { experimentId: this.experimentId },
        });
      }
    },
    async initLiveData(requestComp) {
      this.requestComp = requestComp;
      await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
      if (this.selectedExperiment && this.selectedExperiment[0] && this.selectedExperiment[0].project_id) {
        this.setLoading(true);

        const result = await getExperimentStatus(this.selectedExperiment[0].project_id, this.experimentId)
        if (result?.data?.data?.ready) {
            this.routeByStatus();
            this.setData([]);
  
            await Promise.all([
              await this.pollExpStatus(),
              this.fetchExplorations({ requestComp: requestComp, experimentId: this.experimentId, withLoading: false }),
              this.fetchData({
                requestComp: requestComp,
                experiment_id: this.experimentId,
                page: 1,
                count: this.pageSize,
                withLoading: false
              }),
              this.getDatasetById({ dataset_id: this.selectedExperiment[0].dataset_id, withLoading: false }),
              await this.fetchPipeline({ requestComp: this.requestComp, experiment_id: this.experimentId, project_id: this.selectedExperiment[0].project_id, withLoading: false })
            ]);
  
            if (this.experimentPipeLine?.feature_engineering_phase_1?.[0]) {
              const problemConf = this.experimentPipeLine?.feature_engineering_phase_1?.[0];
              this.setProblemConf({ ...problemConf, problem_type: problemConf.type });
            }
  
            this.setLoading(false);
        }
        else {
          this.experimentStatusPollingCount++;
          
          setTimeout(() => {
            if (this.liveDataPollStatus && this.experimentStatusPollingCount < 120)
              this.initLiveData(requestComp);
            else 
              this.setLoading(false);
          }, 4000);
        }
      }
    },
    async pollExpStatus() {
      await this.getStatus({ experiment_id: this.experimentId, withLoading: false });
      if(this.expStatus.status == "UNAVAILABLE"){
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("automl.errorCode." + this.expStatus.detail),
          type: "warning",
        });
      }
      else if (this.expStatus.status != "AVAILABLE") {
        this.pollRequstCount++;
        
        if (this.pollRequstCount < 10) {
          await new Promise(r => setTimeout(r, 4000));
          this.pollExpStatus();
        }
      }
    },
    formatSize(size) {
      return formatBytes(size);
    },
    ...mapActions({
      fetchExplorations: DATA_ACTIONS.FETCH_EXPLORATIONS,
      fetchData: DATA_ACTIONS.FETCH_DATA,
      getDatasetById: DATASET_ACTIONS.GET_DATASET_BY_ID,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      fetchPipeline: PROJECT_ACTIONS.FETCH_PIPELINE,
      filterData: DATA_ACTIONS.FILTER_DATA,
      dataSort: DATA_ACTIONS.DATA_SORT,
      getStatus: DATA_ACTIONS.GET_STATUS,
    }),
    ...mapMutations({
      setProblemConfigurationParams: MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION_PARAMS,
      setData: DATA_MUTATIONS.SET_DATA,
      setProblemConf: MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION,
      setNavigationCount: NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST,
      setLoading: GENERAL_MUTATIONS.SET_LOADING,
      setExplorations: DATA_MUTATIONS.SET_EXPLORATIONS,
    }),
    async filterSaved(val) {
      const activeFilters = val.filter((v) => v.isActive);
      const expressions = activeFilters.map((af) => {
        let data = af.data;

        if (af.operator === OperatorKey.IN || af.operator === OperatorKey.NOT_IN) {
          try {
            data = JSON.parse("[" + af.data + "]");
          }
          catch (e) {
            return;
          }
        }

        return {
          conditional_operator: af.operator,
          column_name: af.alias,
          value: data,
        };
      });

      const payload =
        expressions && expressions.length > 1
          ? {
            expression: {
              logical_operator: "AND",
              expressions,
            },
          }
          : {
            expression: {
              expressions,
            },
          };

      await this.filterData({
        experiment_id: this.experimentId,
        pipeline_step: this.requestComp,
        requestComp: this.requestComp,
        payload,
      });

     await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.filter
        },
        () => this.initLiveData(this.requestComp),
        { callAsyncStatus: this.callAsyncStatus }
      )
    },

    changePage(page) {
      this.fetchData({
        experiment_id: this.experimentId,
        page: page,
        count: this.pageSize,
      });
      this.currentPage = page;
    },
    changePageSize(size) {
      this.pageSize = size;
    },
    panelWidthControl() {
      let width = this.$refs.leftPanel.clientWidth;
      if (width < 495) {
        this.isPaginationVisible = false;
      } else if (width > 495) {
        this.isPaginationVisible = true;
      }
    },
  },
  destroyed() {
    this.setExplorations([]);
    this.setData([]);
    this.liveDataPollStatus = false;
    this.callAsyncStatus = false;
  }
};
