import IconAdd from "./Components/IconAdd.vue";
import IconCustomModels from "./Components/IconCustomModels.vue";
import IconDataSet from "./Components/IconDataSet.vue";
import IconExperiments from "./Components/IconExperiments.vue";
import IconFilter from "./Components/IconFilter.vue";
import IconHamburger from "./Components/IconHamburger.vue";
import IconInfo from "./Components/IconInfo.vue";
import IconInfrastructure from "./Components/IconInfrastructure.vue";
import IconLogout from "./Components/IconLogout.vue";
import IconModelDeployment from "./Components/IconModelDeployment.vue";
import IconModelGovernance from "./Components/IconModelGovernance.vue";
import IconProject from "./Components/IconProject.vue";
import IconRefresh from "./Components/IconRefresh.vue";
import IconTableColumn from "./Components/IconTableColumn.vue";
import IconDriftReport from "../Icons/Components/IconDriftReport"
import IconInterpret from "./Components/IconInterpret";
import IconBuild from "./Components/IconBuild";
import IconMakePredictionWithNewDataset from "./Components/IconMakePredictionWithNewDataset";
import IconDownload from "./Components/IconDownload";
import IconAbacus from "./Components/IconAbacus";
import IconPipeline from "./Components/IconPipeline";
import IconLog from "./Components/IconLog";
import IconEquation from "./Components/IconEquation";
import IconKebabMenu from "./Components/IconKebabMenu";
import IconModelReport from "./Components/IconModelReport";
import IconWrench from "./Components/IconWrench"

export const iconComponentMap = {
    add: IconAdd,
    customModels: IconCustomModels,
    dataset: IconDataSet,
    experiments: IconExperiments,
    filter: IconFilter,
    hamburger: IconHamburger,
    info: IconInfo,
    infrastructure: IconInfrastructure,
    logout: IconLogout,
    modelDeployment: IconModelDeployment,
    modelGovernance: IconModelGovernance,
    project: IconProject,
    refresh: IconRefresh,
    tableColumn: IconTableColumn,
    driftReport: IconDriftReport,
    interpret: IconInterpret,
    build: IconBuild,
    makePredictionWithNewDataset: IconMakePredictionWithNewDataset,
    download: IconDownload,
    abacus: IconAbacus,
    pipeline: IconPipeline,
    log: IconLog,
    equation: IconEquation,
    kebabMenu: IconKebabMenu,
    modelReport: IconModelReport,
    wrench: IconWrench
}