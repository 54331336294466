<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("challengerModel.Add Challenger Model") }}
      <div
        @click="$emit('closePopup')"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12 dtp-mb--0">
          <span class="componentLabels">{{
            $t("input.new_experiment_name")
          }}</span>
        </div>
        <div class="dtp-col dtp-col-12 dtp-text--center">
          <div
            class="eds-field_#control eds-field_.eds-input eds-input dtp-mb--1"
          >
            <input
              type="text"
              v-model="experimentName"
              :placeholder="$t('input.new_experiment_name')"
              class="eds-input_#input"
              @keydown.space.prevent
            />
          </div>
        </div>

        <div class="dtp-col dtp-col-12 dtp-mb--0">
          <span class="componentLabels">{{ $t("dataset.Dataset") }}</span>
        </div>
        <div class="dtp-col dtp-col-12 dtp-text--center dtp-mb--1">
          <DatasetFiltering
            @onSelected="callSelectedId" />
        </div>
        <div class="dtp-col dtp-col-12">
          <div class="dtp-text--right dtp-mt--2">
            <button
              class="eds-button eds-button.basic dtp-mr--1"
              @click="$emit('closePopup')"
            >
              {{ $t("commons.cancel") }}
            </button>
            <button
              class="eds-button eds-button.basic"
              @click="callAddChallengerModel"
            >
              {{ $t("commons.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DATASET_ACTIONS } from '../../../store/modules/AutoML/Dataset/types';
import  DatasetFiltering  from "../utils/DatasetFiltering.vue"
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  components:{
    DatasetFiltering,
  },
  data() {
    return {
      datasetCount: 100,
      selectedDatasetId: null,
      experimentName: "",
    };
  },
  mounted() {
    this.fetchDatasets({ page: 1, count: this.datasetCount });
  },
  methods: {
    ...mapActions({
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
    }),
    callAddChallengerModel() {
      this.$emit("createChallengerModel", {
        experiment_name: this.experimentName,
        dataset_id: this.selectedDatasetId,
      });
      this.$emit("closePopup");
    },
    callSelectedId(value){
      this.selectedDatasetId= value;
    }
  },
};
</script>

<style scoped>
.componentLabels {
  display: block;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-top: 10px;
}
.dtp-create-popup-absolute {
  top: 40px !important;
  left: 0 !important;
}
</style>