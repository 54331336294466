<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("autoMlWelcome.add_dataset") }}
      <div
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <DataSetCreateContent />
    </div>
  </div>
</template>
<script>
import DataSetCreateContent from "./DataSetCreateContent";

export default {
  components: {
    DataSetCreateContent,
  },
};
</script>
<style scoped>

</style>
