<template>
  <svg
    id="projects-rocket-launch-outline-blue"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="MDI_rocket-launch-outline" data-name="MDI / rocket-launch-outline">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_rocket-launch-outline"
        data-name="Path / rocket-launch-outline"
        d="M13.13,22.19,11.5,18.36a21.048,21.048,0,0,0,4.4-2.27l-2.77,6.1M5.64,12.5,1.81,10.87,7.91,8.1a21.048,21.048,0,0,0-2.27,4.4M19.22,4a3.353,3.353,0,0,1,.74.05c.17,1.39-.02,4.25-3.3,7.53a16.935,16.935,0,0,1-6.01,3.89L8.5,13.37a17.714,17.714,0,0,1,3.92-6.03C15.18,4.58,17.64,4,19.22,4m0-2c-1.98,0-4.98.69-8.22,3.93a19.816,19.816,0,0,0-4.35,6.71,2.019,2.019,0,0,0,.46,2.13l2.13,2.12a2.026,2.026,0,0,0,1.42.61,1.957,1.957,0,0,0,.7-.15A19.1,19.1,0,0,0,18.07,13c5.66-5.66,3.54-10.61,3.54-10.61A6.59,6.59,0,0,0,19.22,2M14.54,9.46a2,2,0,1,1,2.83,0,2,2,0,0,1-2.83,0M8.88,16.53,7.47,15.12l1.41,1.41M6.24,22l3.64-3.64a3.056,3.056,0,0,1-.97-.45L4.83,22H6.24M2,22H3.41l4.77-4.76L6.76,15.83,2,20.59V22m0-2.83,4.09-4.08a2.958,2.958,0,0,1-.45-.97L2,17.76Z"
        transform="translate(0.19)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
