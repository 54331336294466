<template>
  <svg
    id="Kebap_Menu"
    data-name="Kebap Menu"
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="16"
    viewBox="0 0 4 16"
  >
    <circle
      id="Ellipse_1032"
      data-name="Ellipse 1032"
      cx="2"
      cy="2"
      r="2"
      fill="#1d4e91"
    />
    <circle
      id="Ellipse_1033"
      data-name="Ellipse 1033"
      cx="2"
      cy="2"
      r="2"
      transform="translate(0 6)"
      fill="#1d4e91"
    />
    <circle
      id="Ellipse_1034"
      data-name="Ellipse 1034"
      cx="2"
      cy="2"
      r="2"
      transform="translate(0 12)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
