var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dtp-section"},[_c('div',{staticClass:"dtp-pageviewtopbar"},[_c('div',{staticClass:"dtp-row dtp-align-center"},[_c('div',{staticClass:"dtp-col dtp-col-3"},[_c('div',{staticClass:"dtp-panel-formbox"},[_c('span',{staticClass:"dtp-aml-page-title"},[_vm._v(_vm._s(_vm.$t("automl.modelRunningTitle")))])])]),_c('div',{staticClass:"dtp-col dtp-col-9 dtp-text--right"})])]),_c('div',{staticClass:"dtp-row"},[_c('div',{staticClass:"dtp-col dtp-col-5"},[_c('div',{staticClass:"dtp-aml-modelSelection-card dtp-aml-h-760 dtp-bgcolor-FFFFFF"},[_c('div',{staticClass:"dtp-aml-modelSelection-card-title"},[_c('span',{staticClass:"dtp-aml-font-medium"},[_vm._v(_vm._s(_vm.$t("automl.currentProcess")))])]),_c('div',[_c('progress-circle',{attrs:{"size":300,"title":_vm.$t('automl.processInfo'),"timerNumber":_vm.progress}})],1),_c('div',{staticClass:"dtp-row dtp-mt--3 dtp-ml--3 dtp-align-center"},[_c('div',{staticClass:"dtp-col dtp-col-12"},[_c('span',{staticClass:"dtp-aml-font-medium"},[_vm._v(_vm._s(_vm.$t("automl.startTime"))+" : ")]),_vm._v(_vm._s(_vm.today)+" ")]),_c('div',{staticClass:"dtp-col dtp-col-12 dtp-mt--1"},[_c('span',{staticClass:"dtp-aml-font-medium"},[_vm._v(_vm._s(_vm.$t("automl.elapseTime"))+" : ")]),_vm._v(_vm._s(_vm.elapsetime)+" ")]),_c('div',{staticClass:"dtp-col dtp-col-12 dtp-mt--3 dtp-text--center"},[(_vm.progress < 100)?_c('router-link',{attrs:{"to":{
                name: 'ModelSelection',
                query: { experimentId: this.experimentId },
              }}},[_c('button',{staticClass:"eds-button eds-button.basic",on:{"click":function($event){_vm.stopTraining(_vm.experimentId);
                  _vm.back = true;}}},[_vm._v(" "+_vm._s(_vm.$t("automl.killProcess"))+" ")])]):_c('router-link',{attrs:{"to":{
                name: 'ModelResults',
                query: { experimentId: this.experimentId },
              }}},[_c('button',{staticClass:"eds-button eds-button.basic"},[_vm._v(" "+_vm._s(_vm.$t("automl.gotoResults"))+" ")])])],1)])])]),_c('div',{staticClass:"dtp-col dtp-col-7"},[_c('div',{staticClass:"dtp-aml-modelSelection-card dtp-aml-h-760 dtp-bgcolor-2E3138"},[_c('div',{staticClass:"dtp-aml-modelSelection-card-title dtp-text--left"},[_c('span',{staticClass:"dtp-aml-font-medium dtp-ml--6"},[_vm._v(_vm._s(_vm.$t("automl.progressLogs")))])]),_c('div',{staticClass:"dtp-aml-modelSelection-card-content"},[_c('div',{staticClass:"dtp-aml-log-content dtp-scrollbar"},[_c('div',{staticClass:"dtp-row"},[_vm._l((_vm.logs),function(log,index){return _c('div',{key:index,staticClass:"dtp-col dtp-col-12 dtp-pl--0"},[_c('log-item',{staticClass:"dtp-mb--3",class:{
                    'dtp-aml-logItemRed': log.level == 'ERROR',
                    'dtp-aml-logItemGreen': log.level != 'ERROR',
                  },attrs:{"message":log.message,"date":log.date,"level":log.level}})],1)}),(_vm.progress < 100)?_c('p',{staticClass:"dtp-progres-loading-icon"},[_vm._v(" Loading "),_c('span'),_c('span'),_vm._v(" "),_c('span')]):_vm._e()],2)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }