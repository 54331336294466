<template>
  <div class="dtp-row dtp-mt--2" v-if="selectedPlotList.length > 0">
    <div :style="{ display: isPlotStatusNotAvailable(item.plot_status) ? 'none' : 'block' }"
      v-for="(item, index) in selectedPlotList"
      :key="`plot-${item}-${index}`"
      class="dtp-col dtp-col-6 dtp-col-s12 dtp-pl--0"
    >
      <div v-if="item.plot_path != ''">
        <p class="dtp-aml-page-title">
          {{ getModelName(item.model_id) }}
        </p>
        <!-- <img class="feature-image" :src="getImgUrl(item.plot_path)" alt="" /> -->
        <iframe
          v-if="isIFrameType(item)"
          class="plotImageIframe"
          :src="getPlotPath(item)"
          title="description"
        ></iframe>
        <img
          v-else
          class="feature-image"
          :src="getPlotPath(item)"
          alt="Image Not Found"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { PLOT_STATUS } from "../../AutoMl/Builds/data/builds"
export default {
  props: {
    selectedPlotList: {
      type: Array,
      required: true,
    },
    models: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      iframeTypes: ["tsne", "distribution", "cluster"],
    };
  },
  methods: {
    isIFrameType(item) {
      return this.iframeTypes.includes(item.plot_type);
    },
    getModelName(id) {
      return this.models.find((m) => m.model_id === id)?.model ?? id;
    },
    getPlotPath(item) {
      if (item.isMock) {
        var images = require.context("../Mocks/", false, /\.png$/);

        return images("./" + item.plot_path);
      }
      return item.plot_path;
    },
    isPlotStatusNotAvailable(plotStatus) {
      return (
        plotStatus === PLOT_STATUS.FAILED ||
        plotStatus === PLOT_STATUS.NOT_APPLICABLE ||
        plotStatus === PLOT_STATUS.NOT_AVAILABLE
      );
    },
  },
};
</script>

<style scoped>
.plotImageIframe {
  width: 100%;
  height: 500px;
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%231D4F90" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="16" text-anchor="middle">Plot is loading...</text></svg>')
    0px 0px no-repeat;
}
</style>