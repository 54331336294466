import { GENERAL_MUTATIONS } from "../General/types";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { objectToQueryString, sortControl } from "../../../../api/AutoMlServices/utils";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { MODELS_ACTIONS, MODELS_GETTERS, MODELS_MUTATIONS } from "./types";
import cloneDeep from "clone-deep";
import dayjs from "dayjs";
import { ASYNC_PROCESS_ACTIONS } from "../../../../helper/asyncStatusChecker";
import { postModelPlotsMock } from "../../../../api/AutoMlServices/mocks/postModelPlotsMock";
import { NAVIGATION_MUTATIONS } from "../Navigation/types";
import { FOLD_STRATEGIES } from "../../../../models/dataPreparation";

export const modelsStore = {
    state: {
      models: [],
      modelResults: [],
      predictionlink: "",
      modellink: "",
      modelTuning: { algorithms: [], metric: "" },
      predictions: [],
      modelEquation: {},
      modelResultsMain: [],
      modelExplanationSamples: [],
      audit: [],
      tTestResult: [],
      binomialTestResult: [],
      modelValidations: [],
      customModels: [],
      modelRequestInfo: {},
      challengerModelResults: [],
      challengerExperimentInfo: [],
      challengerModelStatus: [],
      logExports: [],
      problemConfigurationParams: {
        problem_type: "classification",
        target: "",
        stratify: true,
        train_ratio: 80,
        cross_validation_number_of_folds: 3,
        fold_strategy: "",
        date_column: ""
      },
      modelPlots: {},
      missingListEmpty: {},
      encodingListEmpty: false,
      balanceConfiguration: {},
      modelGovernanceFilter: {},
      customModelsFilter: {},
    },
    getters: {
      [MODELS_GETTERS.GET_MODELS]: (state) => {
        return state.models;
      },
      [MODELS_GETTERS.GET_MODEL_RESULTS]: (state) => {
        return state.modelResults;
      },
      [MODELS_GETTERS.GET_PREDICTION_LINK]: (state) => {
        return state.predictionlink;
      },
      [MODELS_GETTERS.GET_MODEL_LINK]: (state) => {
        return state.modellink;
      },
      [MODELS_GETTERS.GET_MODEL_TUNING]: (state) => {
        return state.modelTuning;
      },
      [MODELS_GETTERS.GET_PREDICTIONS]: (state) => {
        return state.predictions;
      },
      [MODELS_GETTERS.GET_MODEL_EQUATION]: (state) => {
        return state.modelEquation;
      },
      [MODELS_GETTERS.GET_MODEL_RESULTS_MAIN]: (state) => {
        return state.modelResultsMain;
      },
      [MODELS_GETTERS.GET_MODEL_EXPLANATION_SAMPLES]: (state) => {
        return state.modelExplanationSamples;
      },
      [MODELS_GETTERS.GET_AUDIT]: (state) => {
        return state.audit;
      },
      [MODELS_GETTERS.GET_T_TEST_RESULT]: (state) => {
        return state.tTestResult;
      },
      [MODELS_GETTERS.GET_BINOMIAL_TEST_RESULT]: (state) => {
        return state.binomialTestResult;
      },
      [MODELS_GETTERS.GET_MODEL_VALIDATIONS]: (state) => {
        return state.modelValidations;
      },
      [MODELS_GETTERS.GET_CUSTOM_MODELS]: (state) => {
        return state.customModels;
      },
      [MODELS_GETTERS.GET_MODEL_REQUEST_INFO]: (state) => {
        return state.modelRequestInfo;
      },
      [MODELS_GETTERS.GET_CHALLENGER_MODEL_RESULTS]: (state) => {
        return state.challengerModelResults;
      },
      [MODELS_GETTERS.GET_CHALLENGER_EXPERIMENT_INFO]: (state) => {
        return state.challengerExperimentInfo;
      },
      [MODELS_GETTERS.GET_CHALLENGER_MODEL_STATUS]: (state) => {
        return state.challengerModelStatus;
      },
      [MODELS_GETTERS.GET_LOG_EXPORTS]: (state) => {
        return state.logExports;
      },
      [MODELS_GETTERS.GET_PROBLEM_CONFIGURATION_PARAMS]: (state) => {
        return state.problemConfigurationParams;
      },
      [MODELS_GETTERS.GET_MODEL_PLOTS]: (state) => {
        return state.modelPlots;
      },
      [MODELS_GETTERS.GET_MISSING_LIST_EMPTY]: (state) => {
        return state.missingListEmpty;
      },
      [MODELS_GETTERS.GET_ENCODING_LIST_EMPTY]: (state) => {
        return state.encodingListEmpty;
      },
      [MODELS_GETTERS.GET_BALANCE_CONFIGURATION]: (state) => {
        return state.balanceConfiguration;
      },
      [MODELS_GETTERS.GET_MODEL_GOVERNANCE_FILTER]: (state) => {
        return state.modelGovernanceFilter;
      },
      [MODELS_GETTERS.GET_CUSTOM_MODELS_FILTER]: (state) => {
        return state.customModelsFilter;
      },
    },
    mutations: {
      [MODELS_MUTATIONS.SET_MODELS]: (state, val) => {
        Vue.set(state, "models", val);
      },
      [MODELS_MUTATIONS.UPDATE_MODELS]: (state, val) => {
        if (val && val.length) {
          const singleVal = val[0];
          const modelsClone = JSON.parse(JSON.stringify(state.models))
          const foundIndex = modelsClone?.findIndex(v => v.model_id === singleVal.model_id);
          modelsClone[foundIndex] = singleVal;
  
          Vue.set(state, "models", modelsClone);
        }
      },
      [MODELS_MUTATIONS.SET_MODELS_RESULT]: (state, val) => {
        Vue.set(state, "modelResults", val);
      },
      [MODELS_MUTATIONS.SET_PREDICTION_LINK]: (state, val) => {
        state.predictionlink = val;
      },
      [MODELS_MUTATIONS.SET_MODEL_LINK]: (state, val) => {
        state.modellink = val;
      },
      [MODELS_MUTATIONS.RESET_MODEL_TUNING](state) {
        Vue.set(state, "modelTuning", { algorithms: [], metric: "" });
      },
      [MODELS_MUTATIONS.SET_PREDICTIONS]: (state, val) => {
        Vue.set(state, "predictions", val);
      },
      [MODELS_MUTATIONS.SET_MODEL_EQUATION]: (state, val) => {
        state.modelEquation = val;
      },
      [MODELS_MUTATIONS.SET_MODELS_RESULTS_MAIN]: (state, val) => {
        Vue.set(state, "modelResultsMain", val);
      },
      [MODELS_MUTATIONS.SET_MODEL_EXPLANATION_SAMPLES]: (state, val) => {
        Vue.set(state, "modelExplanationSamples", val);
      },
      [MODELS_MUTATIONS.SET_AUDIT](state, val) {
        Vue.set(state, "audit", val);
      },
      [MODELS_MUTATIONS.SET_T_TEST_RESULT]: (state, val) => {
        Vue.set(state, "tTestResult", val);
      },
      [MODELS_MUTATIONS.SET_BINOMIAL_TEST_RESULT]: (state, val) => {
        Vue.set(state, "binomialTestResult", val);
      },
      [MODELS_MUTATIONS.SET_MODEL_VALIDATIONS]: (state, val) => {
        Vue.set(state, "modelValidations", val);
      },
      [MODELS_MUTATIONS.SET_CUSTOM_MODELS](state, val) {
        Vue.set(state, "customModels", val);
      },
      [MODELS_MUTATIONS.SET_MODEL_REQUEST_INFO]: (state, val) => {
        Vue.set(state, "modelRequestInfo", val);
      },
      [MODELS_MUTATIONS.SET_CHALLENGER_MODEL_RESULTS]: (state, val) => {
        Vue.set(state, "challengerModelResults", val);
      },
      [MODELS_MUTATIONS.SET_CHALLENGER_EXPERIMENT_INFO]: (state, val) => {
        Vue.set(state, "challengerExperimentInfo", val);
      },
      [MODELS_MUTATIONS.SET_CHALLENGER_MODEL_STATUS]: (state, val) => {
        Vue.set(state, "challengerModelStatus", val);
      },
      [MODELS_MUTATIONS.SET_LOG_EXPORTS]: (state, val) => {
        Vue.set(state, "logExports", val);
      },
      [MODELS_MUTATIONS.SET_MODEL_TUNING]: (state, info) => {
        Vue.set(state, "modelTuning", info);
      },
      [MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION_PARAMS]: (state, info) => {
        if (info.field == "fold_strategy" && info.value !== FOLD_STRATEGIES.TIME_SERIES) {
          const problemConfigurations = state.problemConfigurationParams;
          delete problemConfigurations.date_column;
          Vue.set(state, "problemConfigurationParams", problemConfigurations);
        }
        Vue.set(state.problemConfigurationParams, [info.field], info.value);
      },
      [MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION]: (state, info) => {
        Vue.set(state, "problemConfigurationParams", info);
      },
      [MODELS_MUTATIONS.RESET_PROBLEM_CONFIGURATION]: (state) => {
        Vue.set(state, "problemConfigurationParams", {
          problem_type: "classification",
          target: "",
          stratify: true,
          train_ratio: 80,
          cross_validation_number_of_folds: 3,
          fold_strategy: "",
          date_column: ""
        });
      },
      [MODELS_MUTATIONS.SET_MODELS_PLOTS]: (state, val) => {
        Vue.set(state, "modelPlots", val);
      },
      [MODELS_MUTATIONS.SET_MISSING_LIST_EMPTY](state, val) {
        Vue.set(state, "missingListEmpty", val);
      },
      [MODELS_MUTATIONS.SET_ENCODING_LIST_EMPTY](state, val) {
        Vue.set(state, "encodingListEmpty", val);
      },
      [MODELS_MUTATIONS.SET_BALANCE_CONFIGURATION](state, obj) {
        Vue.set(state, "balanceConfiguration", obj);
      },
      [MODELS_MUTATIONS.SET_MODEL_GOVERNANCE_FILTER](state, filterObj) {
        Vue.set(state, "modelGovernanceFilter", filterObj);
      },
      [MODELS_MUTATIONS.SET_CUSTOM_MODELS_FILTER](state, filterObj) {
        Vue.set(state, "customModelsFilter", filterObj);
      },
    },
    actions: {
      [MODELS_ACTIONS.FETCH_MODELS]: async ({ commit }, params) => {
        const controlledParams = sortControl({ ...params })
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelsList,
          queryParam: controlledParams.toString(),
          withSuccessNotify: false,
          successCallback: async (result) => {
            result?.data?.data?.forEach(element => {
              element.monitoring_date = new Date("1999-01-01T01:01:01.084000");
            });
    
            await commit(MODELS_MUTATIONS.SET_MODELS, result.data?.data);
    
            await commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "models",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [MODELS_ACTIONS.FETCH_SINGLE_MODEL]: async ({ commit }, params) => {
        const { requestComp, ...otherParams } = params;
        const controlledParams = sortControl(otherParams);
        
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelsList,
          queryParam: controlledParams.toString(),
          withSuccessNotify: false,
          loadingComponent: requestComp,
          successCallback: async (result) => {
            result?.data?.data?.forEach(element => {
              element.monitoring_date = new Date("1999-01-01T01:01:01.084000");
            });
    
            await commit(MODELS_MUTATIONS.UPDATE_MODELS, result.data?.data);
          }
        });
      },
      [MODELS_ACTIONS.FETCH_MODELS_BY_EXPERIMENT_ID]: async ({ commit }, { page, experimentId, count }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelsListByExperimentId,
          queryParam: `?experiment_id=${experimentId}&page=${page}&count=${count}`,
          successMutation: MODELS_MUTATIONS.SET_MODELS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "models",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [MODELS_ACTIONS.FETCH_MODELS_PLOTS]: async ({ commit }, { requestComp, model_ids, plot_types, experimentId }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.postModelPlots,
          queryParam: experimentId,
          bodyParam: { model_ids, plot_types },
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successCallback: (result) => {
            result?.data?.data?.list?.push(postModelPlotsMock);
            commit(MODELS_MUTATIONS.UPDATE_MODELS, result.data?.data);
          }
        });
      },
      [MODELS_ACTIONS.MAKE_PREDICTION]: async ({ commit, dispatch }, params) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.makePredicNewData,
          queryParam: params.model_id,
          bodyParam: params.data,
          loadingComponent: params.requestComp,
          successCallback: async () => {
            await dispatch(MODELS_ACTIONS.FETCH_MODELS);
          }
        });
      },
      [MODELS_ACTIONS.FETCH_MODELS_RESULT]: async ({ commit }, { problemType, requestComp, experimentId, datasetName }) => {
        commit(MODELS_MUTATIONS.SET_MODELS_RESULT, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelResulsList,
          queryParam: experimentId,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_MODELS_RESULT,
          resultSelector: (result) => result.data?.data
        });
      },
      [MODELS_ACTIONS.DOWNLOAD_PREDICTION_RESULTS]: async (
        { commit, dispatch }, { requestComp, data }
      ) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.downloadPredictionResults,
          queryParam: data.artifact_uri,
          loadingComponent: requestComp,
          successMutation: MODELS_MUTATIONS.SET_PREDICTION_LINK,
          resultSelector: (result) => result.data?.path,
          successCallback: async () => {
            await dispatch(MODELS_ACTIONS.FETCH_MODELS);
          }
        });
      },
      [MODELS_ACTIONS.RUN_MODELS]: async ({ commit }, { requestComp, experimentId, data }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.runModels,
          queryParam: experimentId,
          bodyParam: data,
          loadingComponent: requestComp,
        });
      },
      async [MODELS_ACTIONS.UPDATE_PROBLEM_CONFIGURATION](
        { commit, state, rootState },
        { requestComp, experimentId }
      ) {
        const { problem_type, target, stratify, train_ratio, cross_validation_number_of_folds, fold_strategy, date_column } = state.problemConfigurationParams;
        let necessaryParams = {};
    
        if (problem_type === "clustering") {
          necessaryParams = { problem_type };
        }
        else {
          necessaryParams = { problem_type, target, stratify: problem_type === "classification" ? stratify : false, train_ratio, cross_validation_number_of_folds, fold_strategy, date_column }
        }
    
        const copyOfData = Object.assign({}, necessaryParams);
      
        if (copyOfData.cross_validation_number_of_folds <= 1) {
          copyOfData.cross_validation_number_of_folds = null;
        }
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.problemConfiguration,
          queryParam: experimentId,
          bodyParam: copyOfData,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successCallback: (result) => {
            commit(NAVIGATION_MUTATIONS.SET_ON_GOING_OPERATION, {
              sectionName: rootState.navigation.routeName, 
              action: ASYNC_PROCESS_ACTIONS.updateProblemConfiguration, 
              operationId: result?.data?.data?.operation_id,
              experimentId
            });
          }
        });
      },
      [MODELS_ACTIONS.STOP_MODEL_TRAINING]: async ({ commit }, experimentId) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.deleteModelTraining,
          queryParam: experimentId,
          withSuccessNotify: false,
        });
      },
      [MODELS_ACTIONS.MODEL_DOWNLOAD]: async (
        { commit, dispatch }, { requestComp, data }
      ) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.modelDownload,
          queryParam: { artifactUri: data.artifact_uri, experimentId: data.experiment_id },
          successMutation: MODELS_MUTATIONS.SET_MODEL_LINK,
          loadingComponent: requestComp,
          resultSelector: (result) => result.data?.path,
          successCallback: async () => {
            await dispatch(MODELS_ACTIONS.FETCH_MODELS);
          }
        });
      },
      [MODELS_ACTIONS.PUT_MODEL_TUNING]: async ({ commit }, params) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.modelTuning,
          queryParam: params.experimentId,
          bodyParam: params.data,
          successMutation: MODELS_MUTATIONS.RESET_MODEL_TUNING,
          loadingComponent: params.requestComp,
        });
      },
      [MODELS_ACTIONS.FETCH_PREDICTIONS]: async ({ commit }, { page, count, requestComp, modelId }) => {
        const query = new URLSearchParams({
          model_id: modelId,
          page,
          count,
        });
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchPredictions,
          queryParam: query.toString(),
          successMutation: MODELS_MUTATIONS.SET_PREDICTIONS,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "predictions",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [MODELS_ACTIONS.FETCH_MODELS_BY_MODEL_ID]: async ({ commit }, { page, model_id, count }) => {
        const query = `?model_id=${model_id}&page=${page}&count=${count}`;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelsListByModelId,
          queryParam: query,
          successMutation: MODELS_MUTATIONS.SET_MODELS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "models",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [MODELS_ACTIONS.UPDATE_DESCRIPTION_BY_MODEL_ID]: async ({ commit }, { requestComp, modelId, descriptionData }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.updateDescriptionByModelId,
          queryParam: modelId,
          bodyParam: descriptionData,
          loadingComponent: requestComp
        });
      },
      [MODELS_ACTIONS.FETCH_MODEL_EQUATION]: async ({ commit }, modelId) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelEquation,
          queryParam: modelId,
          successMutation: MODELS_MUTATIONS.SET_MODEL_EQUATION,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
        });
      },
      [MODELS_ACTIONS.FETCH_MODELS_RESULT_MAIN]: async ({ commit }, { requestComp, experimentId }) => {
        commit(MODELS_MUTATIONS.SET_MODELS_RESULTS_MAIN, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchModelResultsMain,
          queryParam: experimentId,
          loadingComponent: requestComp,
          successMutation: MODELS_MUTATIONS.SET_MODELS_RESULTS_MAIN,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
        });
      },
      [MODELS_ACTIONS.FETCH_MODEL_EXPLANATION_STATUS]: async ({ commit }, { requestComp, model_id }) => {
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchModelExplanationStatus,
          queryParam: model_id,
          loadingComponent: requestComp,
          withSuccessNotify: false,
        });
      },
      [MODELS_ACTIONS.FETCH_MODEL_EXPLANATION_SAMPLES]: async ({ commit }, { requestComp, model_id }) => {
        commit(MODELS_MUTATIONS.SET_MODEL_EXPLANATION_SAMPLES, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchModelExplanationSamples,
          queryParam: model_id,
          loadingComponent: requestComp,
          successMutation: MODELS_MUTATIONS.SET_MODEL_EXPLANATION_SAMPLES,
          withSuccessNotify: false,
          resultSelector: (result) => result.data,
        });
      },
      [MODELS_ACTIONS.POST_MODEL_EXPLANATION_PLOT]: async ({ commit }, { requestComp, model_id, plot_type, params }) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.postModelExplanationPlot,
          queryParam: model_id,
          bodyParam: { plot_type, params },
          loadingComponent: requestComp
        });
    
        return result?.data?.data || false;
      },
      [MODELS_ACTIONS.FETCH_MODELS_PLOT_MAIN]: async ({ commit }, { requestComp, experimentId, params }) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchModelPlotMain,
          queryParam: experimentId,
          bodyParam: params,
          loadingComponent: requestComp,
          withSuccessNotify: false,
        });
        return result?.data?.data?.list || [];
      },
      [MODELS_ACTIONS.UPLOAD_AUDIT]: async ({ commit }, params) => {
        const { modelId, formData, requestComp } = params;
    
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.uploadAudit,
          queryParam: modelId,
          bodyParam: formData,
          loadingComponent: requestComp,
        });
        
        return result;
      },
      [MODELS_ACTIONS.FETCH_AUDIT]: async ({ commit }, { modelId }) => {
        commit(MODELS_MUTATIONS.SET_AUDIT, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchAudit,
          queryParam: modelId,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_AUDIT,
          resultSelector: (result) => result.data?.data
        });
      },
      [MODELS_ACTIONS.MAKE_MODEL_VALIDATIONS]: async ({ commit }, { model_id, data, requestComp }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.makeModelValidations,
          queryParam: model_id,
          bodyParam: data,
          loadingComponent: requestComp
        });
      },
      [MODELS_ACTIONS.FETCH_T_TEST_RESULT]: async ({ commit }, { model_id, validation_id, requestComp }) => {
        commit(MODELS_MUTATIONS.SET_T_TEST_RESULT, []);
    
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getTTestResult,
          queryParam: { modelId: model_id, validationId: validation_id },
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_T_TEST_RESULT,
          resultSelector: (result) => result.data
        });
    
        return result;
      },
      [MODELS_ACTIONS.FETCH_BINOMIAL_TEST_RESULT]: async ({ commit }, { model_id, validation_id, requestComp }) => {
        commit(MODELS_MUTATIONS.SET_BINOMIAL_TEST_RESULT, []);
    
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getBinomialTestResult,
          queryParam: { modelId: model_id, validationId: validation_id },
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_BINOMIAL_TEST_RESULT,
          resultSelector: (result) => result.data
        });
        
        return result.data || result;
      },
      [MODELS_ACTIONS.FETCH_MODEL_VALIDATIONS]: async ({ commit }, { model_id, requestComp }) => {
        commit(MODELS_MUTATIONS.SET_MODEL_VALIDATIONS, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getModelValidations,
          queryParam: model_id,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_MODEL_VALIDATIONS,
          resultSelector: (result) => result.data?.data
        });
      },
      [MODELS_ACTIONS.FETCH_CUSTOM_MODELS]: async ({ commit }, { requestComp, params }) => {
        commit(MODELS_MUTATIONS.SET_CUSTOM_MODELS, []);
        const query = `?page=${params?.page || 1}&count=${params?.count || 20}`;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchCustomModels,
          queryParam: query,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_CUSTOM_MODELS,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "customModels",
              pageInfo: result.data?.paginationInfo,
            });
          }
        });
      },
      [MODELS_ACTIONS.DEFINE_CUSTOM_MODEL]: async ({ commit, dispatch }, params) => {
        const { requestComp, formData } = params;
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.defineCustomModel,
          bodyParam: formData,
          loadingComponent: requestComp,
        });
      },
      [MODELS_ACTIONS.RETRY_CUSTOM_MODEL]: async ({ commit, dispatch }, params) => {
        const { requestComp, id } = params;
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.retryCustomModel,
          queryParam: id,
          bodyParam: {},
          loadingComponent: requestComp,
        });
      },
      [MODELS_ACTIONS.DELETE_CUSTOM_MODEL]: async ({ commit, dispatch }, params) => {
        const { requestComp, id } = params;
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.deleteCustomModel,
          queryParam: id,
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(MODELS_ACTIONS.FETCH_CUSTOM_MODELS, { requestComp });
          }
        });
      },
      [MODELS_ACTIONS.FETCH_MODEL_REQUEST_INFO]: async ({ commit }, { requestComp, model_id }) => {
        commit(MODELS_MUTATIONS.SET_MODEL_REQUEST_INFO, {});
    
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchModelRequestInfo,
          queryParam: model_id,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_MODEL_REQUEST_INFO,
          resultSelector: (result) => result.data,
        });
      },
      [MODELS_ACTIONS.RETRAIN_CUSTOM_MODEL]: async ({ commit, dispatch }, params) => {
        const { requestComp, id, bodyParams } = params;
    
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.retrainCustomModel,
          queryParam: id,
          bodyParam: bodyParams,
          loadingComponent: requestComp,
          successMutation: MODELS_MUTATIONS.SET_MODEL_REQUEST_INFO,
          resultSelector: (result) => result.data,
        });
      },
      [MODELS_ACTIONS.RETEST_CUSTOM_MODEL]: async ({ commit, dispatch }, params) => {
        const { requestComp, id, bodyParams } = params;
    
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.retestCustomModel,
          queryParam: id,
          bodyParam: bodyParams,
          loadingComponent: requestComp,
          successMutation: MODELS_MUTATIONS.SET_MODEL_REQUEST_INFO,
          resultSelector: (result) => result.data,
        });
      },
      [MODELS_ACTIONS.FETCH_CHALLENGER_MODEL_RESULTS]: async ({ commit }, { requestComp, experimentId }) => {
        commit(MODELS_MUTATIONS.SET_CHALLENGER_MODEL_RESULTS, []);
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchChallengerModelResults,
          queryParam: experimentId,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successCallback: async (result) => {
            commit(MODELS_MUTATIONS.SET_CHALLENGER_MODEL_RESULTS, result?.data?.data)
            commit(MODELS_MUTATIONS.SET_CHALLENGER_EXPERIMENT_INFO, result?.data?.experiment_info)
          }
        });
      },
      [MODELS_ACTIONS.CREATE_CHALLENGER_MODEL]: async ({ commit, dispatch }, { requestComp, experiment_id, params }) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.createChallengerModel,
          queryParam: experiment_id,
          bodyParam: params,
          loadingComponent: requestComp,
          successCallback: () => {
            dispatch(MODELS_ACTIONS.FETCH_CHALLENGER_MODEL_RESULTS, { requestComp, experimentId: experiment_id });
          }
        });
    
        return result?.data?.data || false;
      },
      [MODELS_ACTIONS.FETCH_CHALLENGER_STATUS]: async ({ commit }, { requestComp, experimentId }) => {
        commit(MODELS_MUTATIONS.SET_CHALLENGER_MODEL_STATUS, []);
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchChallengerModelStatus,
          queryParam: experimentId,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_CHALLENGER_MODEL_STATUS,
          resultSelector: (result) => result.data?.data
        });
      },
      [MODELS_ACTIONS.FETCH_LOG_EXPORT]: async ({ commit}, params) => {
        const query = `?page=${params?.page || 1}&count=${params?.count || 20}`;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.fetchLogExports,
          queryParam: query,
          loadingComponent: params.requestComp,
          withSuccessNotify: false,
          successMutation: MODELS_MUTATIONS.SET_LOG_EXPORTS,
          resultSelector: (result) => result?.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "logExports",
              pageInfo: result?.data?.pagination_info,
            });
          }
        });
      },
      [MODELS_ACTIONS.POST_LOG_EXPORT]: async ({ commit }, { requestComp, dateRange, selectedPodNames }) => {
        const body = {
          start_process_time: dayjs(dateRange[0]).format("YYYY-MM-DD HH:mm:ss.000000"),
          end_process_time: dayjs(dateRange[1]).format("YYYY-MM-DD HH:mm:ss.000000"),
          pod_names: selectedPodNames.toString() 
        }
    
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.postLogExport,
          bodyParam: body,
          loadingComponent: requestComp,
          withSuccessNotify: false
        });
      },
      [MODELS_ACTIONS.DELETE_LOG_EXPORT]: async ({ commit }, { id, requestComp }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.deleteLogExport,
          queryParam: id,
          loadingComponent: requestComp,
        });
      },
      [MODELS_ACTIONS.GET_LOG_EXPORT_STATUS]: async ({ commit, getters }, { id, requestComp }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.checkLogExportStatus,
          queryParam: id,
          loadingComponent: requestComp,
          successCallback: (result) => {
            const list = cloneDeep(getters[MODELS_GETTERS.GET_LOG_EXPORTS]);
            const selectedExport = list.find((i)=> i.id === result.data.id);
            if (selectedExport) {
              selectedExport.status = result.data.status;
              selectedExport.path = result.data.path;
    
              commit(MODELS_MUTATIONS.SET_LOG_EXPORTS, list);
            }
          }
        });
      },
      [MODELS_ACTIONS.GET_DOWNLOAD_AUDIT]: async ({ commit}, { filePath, requestComp }) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getDownloadAudit,
          queryParam: filePath,
          loadingComponent: requestComp,
        });
        return result?.data?.path;
      },
      [MODELS_ACTIONS.GET_GENERIC_MODELS]: async ({ commit }, { custom_model_id, set_to }) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.models,
          serviceMethodSub: serviceMethodSub.getGenericModels,
          queryParam: { id: custom_model_id, query: set_to },
          withSuccessNotify: false,
        });
        return result;
      },
    }
}