<template>
  <div class="dtp-mt--1">
    <div class="dtp-row dtp-align-center">
      <div class="aml-section-header dtp-align-center">
        <span class="tableAreaHeader use-case-title">{{ $t("Use Case") }}</span>
        <div>
          <el-tooltip
            :content="$t('Create New Use Case')"
            placement="top"
            effect="light"
          >
            <button
              @click="() => (usecaseCreatePopup = true)"
              class="aml-icon-btn"
            >
              <Icon icon="add"></Icon>
            </button>
          </el-tooltip>
          <CreateTag
            v-if="usecaseCreatePopup"
            title="Use Case"
            @create="callCreateUsecase"
            @closePopup="() => (usecaseCreatePopup = false)"
          />
        </div>
        <PageViewBar
          :title="''"
          :activeFilters="true"
          :activeSearch="true"
          :tooltipSearchName="'Use Case'"
          :headerArray="filterArray"
          @filterSaved="saveFilter"
          @deleteFiltered="deleteFilter"
          :filterList="filterList"
          :createBtn="false"
          :calculateview="false"
          @changedSearch="(t) => (searchText = t)"
        />
      </div>
      <TagTable
        :rows="usecaseRows"
        :columns="usecaseColumns"
        :refName="tagTypes.USE_CASE"
        :loading="loading"
        @deleteTag="(tagId) => callDeleteTag(tagId, 'Use Case')"
        @updateDescription="updateDescription"
      />
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { COLUMNS as USECASE_COLUMNS } from "./data/usecase";
import { mapActions, mapGetters } from "vuex";
import notify from "../../../../mixins/AutoML/notify";
import preferences from "../../../../mixins/AutoML/preferences";
import { TagTypes } from "./types";
import TagTable from "./TagTable.vue";
import CreateTag from "./CreateTag.vue";
import PageViewBar from "../../Helper/Pageviewbar";
import {
  TAG_ACTIONS,
  TAG_GETTERS,
} from "../../../../store/modules/AutoML/Tags/types";
import Icon from "../../../Icons/Icon.vue";

export default {
  mixins: [notify, preferences],
  components: {
    TagTable,
    PageViewBar,
    CreateTag,
    Icon,
  },
  data() {
    return {
      usecaseCreatePopup: false,
      searchText: "",
      filterList: [],
      filterArray: [],
      usecaseColumns: USECASE_COLUMNS,
      selectedRowIdForUpdate: null,
      filterables: [
        { label: "Name", key: "name", type: "input" },
        { label: "Description", key: "description", type: "input" },
        { label: "User", key: "created_by", type: "input" },
      ],
      requestComp: "portfolioAndUseCase",
    };
  },
  mounted() {
    this.fetchTags({});
    this.initFilterArray();
  },
  computed: {
    ...mapGetters({
      tags: TAG_GETTERS.GET_TAGS,
    }),
    filteredTags() {
      return this.tags
        .filter((t) => {
          let includes = true;

          this.filterList.forEach((f) => {
            this.filterables.forEach((fable) => {
              if (f.column_name === fable.key) {
                includes &= t?.[fable.key]
                  ?.toLowerCase()
                  ?.includes(f?.value?.toLowerCase());
              }
            });
          });

          return includes;
        })
        .filter((tf) => {
          return tf?.name.toLowerCase().includes(this.searchText.toLowerCase());
        });
    },
    usecaseRows() {
      return this.filteredTags.filter((t) => t.type === TagTypes.USE_CASE);
    },
    tagTypes() {
      return TagTypes;
    },
  },
  methods: {
    ...mapActions({
      fetchTags: TAG_ACTIONS.FETCH_TAGS,
      deleteTag: TAG_ACTIONS.DELETE_TAG,
      updateTag: TAG_ACTIONS.UPDATE_TAG,
      createTag: TAG_ACTIONS.CREATE_TAG,
    }),
    callDeleteTag(tag_id, deleteItem) {
      this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t(deleteItem)]),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
          callback: (confirm) => {
            if (confirm == "confirm") {
              this.deleteTag({ tag_id, requestComp: this.requestComp });
            }
          },
        }
      );
    },
    callCreateUsecase(params) {
      this.createTag({ ...params, type: this.tagTypes.USE_CASE });
      this.usecaseCreatePopup = false;
    },

    saveFilter(params) {
      this.filterList = [];

      params.forEach((p) => {
        if (p.data)
          this.filterList.push({
            column_name: p.name,
            conditional_operator: "equal",
            value: p.data,
          });
      });
    },
    deleteFilter(params) {
      this.filterList = this.filterList.filter(
        (f) => f.column_name !== params.column_name
      );

      this.filterArray.forEach((fa) => {
        if (fa.name === params.column_name) {
          fa.isActive = false;
          fa.data = "";
          fa.operator = "";
        }
      });
    },
    initFilterArray() {
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    updateDescription(r) {
      this.selectedRowIdForUpdate = null;
      this.updateTag({
        tag_id: r.id,
        description: r.description,
        name: r.name,
        type: r.type,
      });
    },
  },
};
</script>

<style scoped>
.tableAreaHeader {
  letter-spacing: 0px;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin-left: 15px;
}
.use-case-title {
  margin-left: 2px;
  color: var(--color-primary);
  font-size: 18px;
}
</style>
