import Vue from 'vue'
import VueRouter from 'vue-router'
import Modules from '../views/Modules.vue'

/* import CsvUpload from '../components/Settings/CsvUpload' */
import { LICENSES, PAGE_PRIVILEGES } from "./../helper/authorization"
import AutoMl from '../views/AutoML/Main.vue'
import CreateProject from '../components/AutoMl/Projects/CreateProject.vue'
import ProjectDetails from '../components/AutoMl/Projects/ProjectDetails.vue'
import Projects from '../components/AutoMl/Projects/Projects.vue'
import Experiments from '../components/AutoMl/ExperimentList.vue'
import DataSets from '../components/AutoMl/DataSets/DataSetList.vue'
import ExploreData from '../components/AutoMl/ExploreData/ExploreDataTable.vue'
import DataPreview from '../components/AutoMl/DataPreview/DataPreview.vue'
import DataPreparation from '../components/AutoMl/DataPreparation/DataPreparation.vue'
import Datacalculation from '../components/AutoMl/DataCalculate/Datacalculation.vue'
import MissingList from '../components/AutoMl/MissingValue/MissingList.vue'
import EncodingList from '../components/AutoMl/Encoding/EncodingList.vue'
import ScalingList from '../components/AutoMl/Scaling/ScalingList.vue'
import FeatureList from '../components/AutoMl/Feature/FeatureList.vue'
import ModelSelection from '../components/AutoMl/ModelSelection/ModelSelection.vue'
import ModelResults from '../components/AutoMl/MultiTable/ModelResults.vue'
import ModelResultsMain from '../components/AutoMl/ModelResultsMain/ModelResultsMain.vue'
import ModelRunning from '../components/AutoMl/ModelRunning/ModelRunning.vue'
import ModelGovernance from '../components/AutoMl/ModelGovernance/ModelGovernance.vue'
import CustomModels from '../components/AutoMl/CustomModel/CustomModels.vue'
import Predictions from '../components/AutoMl/Predictions/Predictions.vue'
import AutoMlNewEditViewQuery from '../components/AutoMl/DataDiscovery/NewEditViewQuery.vue'
import CreateDataSource from '../components/AutoMl/DataDiscovery/CreateDataSource.vue'
import AutoMlCsvUpload from '../components/AutoMl/Csv/CsvUpload'
import TuneModel from '../components/AutoMl/TuneModel/TuneModel.vue'
import BuildList from '../components/AutoMl/Builds/BuildList.vue'
import Interpret from '../components/AutoMl/ModelGovernance/Interpret/Interpret.vue'
import Infrastructure from '../components/AutoMl/Infrastructure/Infrastructure.vue'
import ChallengerModelResults from '../components/AutoMl/ChallengerModelResults/ChallengerModelResults.vue'
import Unauthorized from '../components/Unauthorized.vue'
import { NAVIGATION_MUTATIONS } from '../store/modules/AutoML/Navigation/types'
import Applications from "../components/AutoMl/ModelGovernance/Applications.vue"
import GenericModels from "../components/AutoMl/CustomModel/GenericModels.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/modules',
    name: 'Modules',
    meta: {
      layout: false, title: 'Modules - Data Plateau',
      public: true,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODULE,
      license: LICENSES.ALL
    },
    component: Modules
  },
  //AUTO ML ROUTER
  {
    path: '/',
    name: 'AutoMl',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_AUTOML,
      license: LICENSES.AUTO_ML
    },
    component: AutoMl
  },
  {
    path: '/create_project',
    name: 'CreateProject',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_PROJECTS,
      license: LICENSES.AUTO_ML
    },
    component: CreateProject
  },
  {
    path: '/project-details',
    name: 'ProjectDetails',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_PROJECTS,
      license: LICENSES.AUTO_ML
    },
    component: ProjectDetails
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_PROJECTS,
      license: LICENSES.AUTO_ML
    },
    component: Projects
  },
  {
    path: '/experiments',
    name: 'Experiments',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
      license: LICENSES.AUTO_ML
    },
    component: Experiments
  },
  {
    path: '/datasets',
    name: 'DataSets',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_DATASETS,
      license: LICENSES.AUTO_ML
    },
    component: DataSets
  },
  {
    path: '/data-preparation',
    name: 'DataPreview',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_DATA_PREPARATION,
      license: LICENSES.AUTO_ML
    },
    component: DataPreview
  },
  {
    path: '/explore-data',
    name: 'ExploreData',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'ExploreData',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_EXPLORE_DATA,
      license: LICENSES.AUTO_ML
    },
    component: ExploreData
  },

  {
    path: '/basic-transformation',
    name: 'DataPreparation',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'FeatureEngineering',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_BASIC_TRANSFORMATION,
      license: LICENSES.AUTO_ML
    },
    component: DataPreparation
  },
  {
    path: '/feauture-generation',
    name: 'Datacalculation',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'FeatureEngineering',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_FEATURE_GENERATION,
      license: LICENSES.AUTO_ML
    },
    component: Datacalculation
  },
  {
    path: '/missing-value-imputation',
    name: 'MissingList',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'FeatureEngineering',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MISSING_VALUE,
      license: LICENSES.AUTO_ML
    },
    component: MissingList
  },
  {
    path: '/encoding',
    name: 'EncodingList',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'FeatureEngineering',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_ENCODING,
      license: LICENSES.AUTO_ML
    },
    component: EncodingList
  },
  {
    path: '/scaling',
    name: 'ScalingList',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'FeatureEngineering',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_SCALING,
      license: LICENSES.AUTO_ML
    },
    component: ScalingList
  },
  {
    path: '/feature-reduction',
    name: 'FeatureList',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_FEATURE_REDUCTION,
      license: LICENSES.AUTO_ML
    },
    component: FeatureList
  },
  {
    path: '/model-selection',
    name: 'ModelSelection',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'ModelSelection',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_SELECTION,
      license: LICENSES.AUTO_ML
    },
    component: ModelSelection
  },
  {
    path: '/model-running',
    name: 'ModelRunning',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu', navigationLink: 'ModelSelection',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_RUNNING,
      license: LICENSES.AUTO_ML
    },
    component: ModelRunning
  },
  {
    path: '/model-results',
    name: 'ModelResults',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_RESULTS,
      license: LICENSES.AUTO_ML
    },
    component: ModelResults
  },
  {
    path: '/tune-model',
    name: 'TuneModel',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex', view: 'projectMenu',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_TUNE_MODEL,
      license: LICENSES.AUTO_ML
    },
    component: TuneModel
  },

  {
    path: '/jdbc-resources',
    name: 'NewEditViewquery',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_JDBC_RESOURCES,
      license: LICENSES.AUTO_ML
    },
    component: AutoMlNewEditViewQuery
  },
  {
    path: '/create-datasource',
    name: 'CreateDataSource',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_CREATE_DATASOURCE,
      license: LICENSES.AUTO_ML
    },
    component: CreateDataSource
  },
  {
    path: '/model-governance',
    name: 'ModelGovernance',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
      license: LICENSES.AUTO_ML
    },
    component: ModelGovernance,
  },
  {
    path: '/model-governance/applications',
    name: 'Applications',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.APPLICATIONS,
      license: LICENSES.AUTO_ML
    },
    component: Applications,
  },
  {
    path: '/custom-models/:id/generic_config',
    name: 'GenericModels',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_GENERIC_MODELS,
      license: LICENSES.AUTO_ML
    },
    component: GenericModels,
  },
  {
    path: '/custom-models',
    name: 'CustomModels',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_CUSTOM_MODEL,
      license: LICENSES.AUTO_ML
    },
    component: CustomModels,
  },
  {
    path: '/model-governance/model-results',
    name: 'ModelResultsGovernance',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
      license: LICENSES.AUTO_ML,
      from: "ModelGovernance"
    },
    component: ModelResultsMain
  },
  {
    path: '/experiments/challenger-model-results',
    name: 'ChallengerModelResults',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
      license: LICENSES.AUTO_ML,
      from: "Experiments"
    },
    component: ChallengerModelResults
  },
  {
    path: '/experiments/model-results',
    name: 'ModelResultsExperiments',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
      license: LICENSES.AUTO_ML,
      from: "Experiments"
    },
    component: ModelResultsMain
  },
  {
    path: '/model-governance/interpret',
    name: 'Interpret',
    component: Interpret,
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
      license: LICENSES.AUTO_ML
    },
  },
  {
    path: '/model-deployment',
    name: 'BuildList',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_DEPLOYMENT,
      license: LICENSES.AUTO_ML
    },
    component: BuildList
  },
  {
    path: '/infrastructure',
    name: 'Infrastructure',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_INFRASTRUCTURE,
      license: LICENSES.AUTO_ML
    },
    component: Infrastructure
  },
  {
    path: '/predictions',
    name: 'Predictions',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_PREDICTIONS,
      license: LICENSES.AUTO_ML
    },
    component: Predictions
  },
  {
    path: '/csvupload',
    name: 'CsvUploadAmL',
    meta: {
      layout: 'AutoMlLayout', title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_CSV_UPLOAD,
      license: LICENSES.AUTO_ML,
      source: 'Automl'
    },
    component: AutoMlCsvUpload
  },
  {
    path: '/401',
    name: 'unauthorized',
    meta: {
      title: 'Experian Convex',
      public: false,
      pagePrivilege: PAGE_PRIVILEGES.PAGE_AUTOML,
      license: LICENSES.AUTO_ML,
      source: 'Automl'
    },
    component: Unauthorized
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  var favicon = document.getElementById('favicon')
  to.meta.layout == 'AutoMlLayout' ? favicon.href = "/experianlogo.ico" : favicon.href = "/softtechLogo.ico";
  to.meta && to.meta.title && (window.document.title = to.meta.title)
  if (to.path === '/401' || to.path === '/404') {
    next();
    return;
  }

  if (to.matched.length === 0) {
    return next('/404')
  }

  const store = await import('@/store');  //await the store 
  const isPublic = to.matched.some(record => record.meta.public)
  const pagePrivilege = to.meta.pagePrivilege
  const currentUserPagePrivileges = await store.default.getters["Security/Get/Keycloak/PagePrivileges"]
  const hasAccessToCurrentPage = pagePrivilege && currentUserPagePrivileges.includes(pagePrivilege);

  const pageLicenses = to.meta.license
  let customerLicenses = store.default.getters["Security/Get/Licenses"]

  if (customerLicenses.length === 0) {   // customerLiscense içerisi set edilmediyse license servisine call atar
    customerLicenses = await licenceFunction(store.default);
    
    if (customerLicenses === false) return;
  }
  const hasAccessToCurrentLicenses = pageLicenses && customerLicenses.includes(pageLicenses);
  store.default.commit(NAVIGATION_MUTATIONS.SET_ROUTE_NAME, to.name);

  if (!isPublic) {
    if (hasAccessToCurrentPage && hasAccessToCurrentLicenses) {
      next();
    }
    else {
      return next('/401')
    }
  }
  else {
    next();
  }
})
const licenceFunction = async (store) => {
  try {
    await store.dispatch("Security/Action/Licenses/FetchLicenseInfo");
    return store.getters["Security/Get/Licenses"];
  } catch (error) {
    return false;
  }
}
export default router
