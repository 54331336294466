<template>
  <div
    class="dtp-section"
    @mouseover="panelWidthControl()"
  >
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="aml-section-header aml-p-0">
            <PageViewBar
              :title="$t('automl.encodingTitle')"
              :createBtn="false"
              :activeFilters="true"
              :activeSearch="false"
              :deletableFilter="false"
              :filterList="filterHistory"
              :headerArray="filterArray"
              @filterSaved="filterSaved"
            />
          </div>
          <data-preparation-table
            v-loading="loading"
            :dataColumns="dataColumns"
            :rawData="rawData"
            :lastPage="lastPage"
            :pageSize="pageSize"
            :isPaginationVisible="isPaginationVisible"
            @changePage="changePage"
            @changePageSize="changePageSize"
            @onSortChange="(val) => onSortChange(experimentId, val)"
          />
        </div>
      </pane>

      <pane min-size="20">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="aml-section-header">
            <div>
                <div
                  v-if="bulkButtonsVisible"
                  class="eds-field_#control eds-field_.eds-input eds-input"
                >
                  <select
                    v-model="selectedBulk"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="changeBulk()"
                  >
                    <option value="" selected disabled>
                      {{ $t("automl.chooseEncodeFunction") }}
                    </option>
                    <option
                      :value="item.value"
                      v-for="item in bulkActionsList"
                      :key="item.id"
                    >
                      {{ $t(`${item.label}`) }}
                    </option>
                  </select>
                </div>
            </div>
            <div class="aml-horizontal-flex-end">
                <button
                  @click.stop="applyChanges()"
                  class="eds-button eds-button.basic"
                  :disabled="!applyButtonEnabled"
                >
                  {{ $t("automl.applyChanges") }}
                </button>
                <RefreshTable
                  :loading="loading"
                  @refreshTable="refreshTable"
                />
            </div>
          </div>
          <div class="dtp-row">
            <div class="dtp-col" v-loading="loading">
              <vue-good-table
                @on-selected-rows-change="selectionChanged"
                ref="EncodingTable"
                :columns="optionsColumn"
                :rows="optionsRows"
                :select-options="{
                  enabled: true,
                  disableSelectInfo: true,
                  selectOnCheckboxOnly: true,
                }"
                class="vgt-min-w"
              >
                <template slot="table-row" slot-scope="props">
                  <span
                    class="aml-table-row-radioInput"
                    v-if="props.column.field == 'encodingOptions'"
                  >
                    <div
                      class="
                        eds-field_#control eds-field_.eds-input
                        eds-input
                        encodingTypeSelect
                      "
                    >
                      <select
                        v-model="props.row.encoding"
                        class="eds-input_#input eds-input_#input.dropdown"
                        @change="
                          (e) => applyEncoding(props.row.index, e.target.value)
                        "
                      >
                        <option value="" selected="selected">{{ $t("commons.none") }}</option>
                        <option
                          v-for="option in encodingOptions"
                          :key="`${props.row.index}-encoding-${option.key}`"
                          :value="option.key"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="props.row.encoding === encodingType.ORDINAL"
                      class="ordinalEditButton"
                      @click="() => editOrdinalEncoding(props.row.index)"
                    >
                      <i class="el-icon-edit" style="font-weight: bold"></i>
                      {{ $t("Edit Values") }}
                    </div>
                  </span>
                  <span v-else-if="props.column.field == 'actions'">
                    <img
                      class="dtp-cursor-pointer dtp-vgt-actions-icon"
                      src="../../../assets/images/icons/automl-delete-icon.svg"
                      alt=""
                      @click.stop="deleteColumn(props.row)"
                    />
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template slot="table-column" slot-scope="props">
                  <span>
                    {{ $t("table.header." + props.column.label) }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </pane>
    </splitpanes>
    <OrdinalEncodingPopup
      :row="ordinalEncodingPopupRow"
      :visible="ordinalEncodingPopupVisible"
      @modalClosed="() => (ordinalEncodingPopupVisible = false)"
      @save="saveOrdinalEncoding"
    />
  </div>
</template>
<script>
/* eslint-disable */
import DataPreparationTable from "../DataPreparation/DataPreparationTable";
import PageViewBar from "../Helper/Pageviewbar";
import RefreshTable from "../Helper/RefreshTable";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { optionsColumn, bulkActionsList } from "./Data/data.js";
import { Splitpanes, Pane } from "splitpanes";
import notify from "../../../mixins/AutoML/notify";
import pipeline from "../../../mixins/AutoML/pipeline";
import liveData from "../../../mixins/AutoML/liveData";
import "splitpanes/dist/splitpanes.css";
import "vue-good-table/dist/vue-good-table.css";
import OrdinalEncodingPopup from "./OrdinalEncodingPopup";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from '../../../helper/asyncStatusChecker';
import { EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [notify, liveData, pipeline],
  components: {
    OrdinalEncodingPopup,
    VueGoodTable,
    Splitpanes,
    Pane,
    DataPreparationTable,
    PageViewBar,
    RefreshTable,
  },
  data() {
    return {
      ordinalEncodingPopupVisible: false,
      optionsColumn: optionsColumn,
      selectedBulk: "",
      bulkActionsList: bulkActionsList,
      typeCategorical: "Categorical",
      optionsRows: [],
      optionsRowsDefault: [],
      encodingType: {
        ONE_HOT: "one-hot",
        LABEL: "label",
        ORDINAL: "ordinal",
      },
      ordinalEncodingPopupRow: {},
    };
  },
  async mounted() {
    this.requestComp = "encoding";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )
      
    this.setOptionsRows();
  },
  watch: {
    explorations: {
      handler() {
        this.setOptionsRows();
      },
      deep: true,
    },
    optionsRows: {
      handler() {
        this.checkAndResetSelectedBulk();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      rawData: DATA_GETTERS.GET_DATA,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      datasetData: DATASET_GETTERS.GET_DATASET,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      loading: GENERAL_GETTERS.GET_LOADING
    }),
    encodingOptions() {
      return [
        {
          key: this.encodingType.ONE_HOT,
          label: this.$t("automl.oneHot"),
        },
        {
          key: this.encodingType.LABEL,
          label: this.$t("automl.labelEncoding"),
        },
        {
          key: this.encodingType.ORDINAL,
          label: this.$t("automl.ordinalEncoding"),
        },
      ];
    },
    bulkButtonsVisible() {
      return this.filteredBySelectedRows.length >= 2;
    },
    experimentId() {
      return this.$route.query.experimentId;
    },
    targetVal() {
      return this.problem_conf?.target;
    },
    applyButtonEnabled() {
      let isActiveApplyButton = true;

      if (this.filteredBySelectedRows.length === 0) return false;

      this.filteredBySelectedRows.forEach((element) => {
        if (element.encoding == "") {
          isActiveApplyButton = false;
        }
      });

      return isActiveApplyButton;
    },
    filteredBySelectedRows() {
      return this.optionsRows.filter((r) => r.vgtSelected);
    },
  },
  methods: {
    ...mapActions({
      deleteColumns: DATA_ACTIONS.DELETE_COLUMNS,
      encoding: DATA_ACTIONS.ENCODING,
    }),
    ...mapMutations({
      setApplyChanges: DATA_MUTATIONS.SET_APPLY_CHANGES,
      setData: DATA_MUTATIONS.SET_DATA,
      setEncodingListEmpty: MODELS_MUTATIONS.SET_ENCODING_LIST_EMPTY,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    checkAndResetSelectedBulk() {
      let hasOneHot = false;
      let hasLabel = false;

      this.filteredBySelectedRows.forEach((sr) => {
        if (sr.encoding === "one-hot") hasOneHot = true;
        else if (sr.encoding === "label") hasLabel = true;
      });

      hasOneHot && hasLabel ? (this.selectedBulk = "") : null;
    },
    setOptionsRows() {
      let updatedRows = [];

      updatedRows = this.explorations.map((exp, index) => ({
        index: index,
        field: exp.name,
        label: exp.name,
        column: exp.name,
        type: exp.type,
        encoding: "",
        unique_values: exp.unique_values,
        unique_keys: exp.unique_keys,
      }));

      if (
        updatedRows.filter(
          (x) => x.type == this.typeCategorical && x.label != this.targetVal
        ).length == 0
      ) {
        this.setEncodingListEmpty({ isEmpty: true, rows: null });
      } else {
        let convertObj = [];
        updatedRows
          .filter(
            (x) => x.type == this.typeCategorical && x.label != this.targetVal
          )
          .forEach((dc) => {
            convertObj.push({
              column: dc.field,
              method: "label",
              prefix: `col_${dc.field}`,
              prefix_separator: "_",
              consider_null: false,
            });
          });
        this.setEncodingListEmpty({ isEmpty: false, rows: convertObj });
      }

      this.optionsRows = updatedRows.filter(
        (x) => x.type == this.typeCategorical && x.label != this.targetVal
      );

      this.optionsRowsDefault = JSON.parse(JSON.stringify(this.optionsRows));
    },
    selectionChanged({ selectedRows }) {
      this.optionsRows = JSON.parse(JSON.stringify(this.optionsRowsDefault));

      selectedRows.forEach((sr) => {
        const foundIndex = this.optionsRows.findIndex(
          (or) => or.index === sr.index
        );
        this.$set(this.optionsRows, foundIndex, { ...sr });
      });
    },

    async init() {
      this.setData([]);
      this.fetchExplorations({
        requestComp: this.requestComp,
        experimentId: this.experimentId,
      });
      this.fetchData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        page: 1,
        count: this.pageSize,
      });
      this.currentCount = this.pageSize;
      await this.fetchExperimentByExperimentId(this.experimentId);
      this.getDatasetById(this.selectedExperiment[0].dataset_id);
    },
    pageChanged(pageObject) {
      this.fetchData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        page: pageObject.page,
        count: pageObject.count,
      });
    },
    async deleteColumn(row) {
      let data = {};
      data.requestComp = this.requestComp;
      data.experiment_id = this.experimentId;
      data.names = row.field;
      data.pipeline_step = this.requestComp;
      await this.deleteColumns(data);
      this.initLiveData(this.requestComp);
    },
    applyEncoding(index, value) {
      const row = this.optionsRows.find((x) => x.index == index);

      delete row.ordinal_values;
      this.$set(row, "encoding", value);
      this.$set(row, "vgtSelected", true);
      this.setApplyChanges(true);

      if (value === this.encodingType.ORDINAL) {
        this.$set(row, "encoding", value);
        this.$set(row, "ordinal_values", {});
        for (let i = 0; i < row?.unique_keys?.length; i++) {
          this.$set(row.ordinal_values, row.unique_keys[i], "");
        }
        this.ordinalEncodingPopupVisible = true;
        this.ordinalEncodingPopupRow = row;
      }
    },

    editOrdinalEncoding(index) {
      const row = this.optionsRows.find((x) => x.index == index);
      this.ordinalEncodingPopupVisible = true;
      this.ordinalEncodingPopupRow = row;
    },

    saveOrdinalEncoding(val) {
      const row = this.optionsRows.find((x) => x.index == val.index);
      this.$set(row, "ordinal_values", val.ordinal_values);
      this.ordinalEncodingPopupVisible = false;
      this.ordinalEncodingPopupRow = {};
    },
    changeBulk() {
      this.filteredBySelectedRows.forEach((element) => {
        element.encoding = this.selectedBulk;
      });
    },
    getApplyPayload(filterLessThan20) {
      let convertObj = [];
      const uniqueArray = [];
      this.filteredBySelectedRows
        .filter((s) => (filterLessThan20 ? s.unique_values <= 20 : true))
        .forEach((sr) => {
          if (sr.encoding != "") {
            const obj = {
              column: sr.field,
              method:
                sr.encoding === this.encodingType.ORDINAL
                  ? this.encodingType.LABEL
                  : sr.encoding,
              prefix: `col_${sr.field}`,
              prefix_separator: "_",
              consider_null: false,
            };

            if (sr.encoding === this.encodingType.ORDINAL) {
              obj.ordinal_values = sr.ordinal_values;
            }

            convertObj.push(obj);

            if (sr.encoding === "one-hot" && sr.unique_values > 20)
              uniqueArray.push({
                columnName: sr.field,
                uniqueCount: sr.unique_values,
              });
          }
        });
      let reqObj = {};
      reqObj.encoding = {};
      reqObj.encoding.encoding = convertObj;
      reqObj.experiment_id = this.experimentId;
      reqObj.requestComp = this.requestComp;

      return { reqObj, uniqueArray };
    },
    async applyChanges() {
      const { reqObj, uniqueArray } = this.getApplyPayload(false);
      const uniqueMessages = uniqueArray
        .map((ua) => {
          return `The <span style='font-weight: bold'>${ua.columnName}</span> has <span style='font-weight: bold'>${ua.uniqueCount}</span> unique categories.`;
        })
        .join("<br>");

      if (uniqueArray.length)
        await this.$confirm(
          this.$t("confirmation.One-Hot Encoding is not recommended for the columns have more than 20 unique categories", [uniqueMessages]),
          this.$t("confirmation.Too much unique values for one-hot encoding"),
          {
            confirmButtonText: this.$t("confirmation.Keep Encoding"),
            cancelButtonText: this.$t("commons.cancel"),
            dangerouslyUseHTMLString: true,
            callback: async (confirm) => {
              if (confirm == "confirm") {
                const applyPayloadTrue = this.getApplyPayload(true);
                
                if (applyPayloadTrue?.reqObj?.encoding?.encoding?.length) {
                  await this.callEncoding(applyPayloadTrue.reqObj);
                }
              }
            },
          }
        );
      else {
        await this.callEncoding(reqObj);
      }
    },
    async callEncoding(reqObj) {
      await this.encoding(reqObj);
      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.encoding
        },
        () => {
          this.initLiveData(this.requestComp)
          this.setApplyChanges(false)
        },
        { callAsyncStatus: this.callAsyncStatus }
      );
    }
  },
};
</script>
<style scoped>
::v-deep .vgt-table thead tr .vgt-checkbox-col {
  background: #b2c4dc !important;
}
/* .vgt-min-w .vgt-table tbody tr td:nth-child(3) {
  min-width: 350px;
} */
.encodingTypeSelect {
  width: 150px !important;
  display: inline-flex;
}
.ordinalEditButton {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  color: #436da9;
  font-weight: bold;
  cursor: pointer;
}
.aml-section-header > div {
  min-width: 175px;
}
</style>
