<template>
  <div>
    <!-- Code editing, highlight the content of the completion dialog box -->
    <div class="in-coder-panel" style="margin-bottom: 10px">
      <textarea ref="textarea"></textarea>
    </div>
    <span style="color: red; font-weight: 500">{{ validation }}</span>
  </div>
</template>

<script>
// Introduce global instance
import CodeMirror from "codemirror";
// Core style
import "codemirror/lib/codemirror.css";
// After the theme is introduced, you need to specify the theme in options to take effect
import "codemirror/theme/idea.css";
import "codemirror/mode/sql/sql.js";

//Code completion prompt
import "codemirror/addon/hint/anyword-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/sql-hint.js";
import jsep from "jsep";
import { parseFormula } from "./formulaParser";

export default {
  name: "CodeMirrorFormula",
  props: {
    clearCodeMirror: {
      type: Boolean,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
    formula: {
      type: String,
      required: true,
    },
    operators: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      code: "",
      mode: "shell",
      coder: null,
      options: {
        tabSize: 4,
        theme: "idea",
        lineNumbers: false,
        line: true,
        extraKeys: { Ctrl: "autocomplete" },
        lineWrapping: false,
      },
      formulaOperationHintList: {
        sum: "sum(col_name_1, col_name_2, col_name_N) \nsum(col_name_1, constant)",
        subtract:
          "subtract(col_name_1, col_name_2, col_name_N) \nsubtract(col_name_1, constant)",
        multiply:
          "multiply(col_name_1, col_name_2, col_name_N) \nmultiply(col_name, constant)",
        divide: "divide(col_name, col_name_2) \ndivide(col_name, constant)",
        pow: "pow(col_name, constant)",
        ln: "ln(col_name)",
        log2: "log2(col_name)",
        log10: "log10(col_name)",
        abs: "abs(col_name)",
        if: "if(expression)",
        then: "then(expression)",
        else: "else(expression)",
        to_lower: "to_lower(col_name)",
        to_upper: "to_upper(col_name)",
        replace: 'replace(col_name, "old_value", "new_value")',
        concat: "concat(col_name_1, col_name_2, col_name_N)",
        shift_bydate: "shift_bydate(col_name,shift_amount_integer)",
        rolling: "rolling.mean(col_name,date_col,window_amount_integer) \nrolling.sum(col_name,date_col,window_amount_integer) \nrolling.min(col_name,date_col,window_amount_integer) \nrolling.max(col_name,date_col,window_amount_integer) \nrolling.count(col_name,date_col,window_amount_integer)",
        resample: "resample(date_col,col_name,frequency_interval,'mean') \nresample(date_col,col_name,frequency_interval,'count') \nresample(date_col,col_name,frequency_interval,'sum') \nresample(date_col,col_name,frequency_interval,'asfreq') \nfrequency_interval: B, D, W, M, Q, H, T, S"
      },
      formulaOperationHintStr: [],
      validation: "",
    };
  },
  watch: {
    clearCodeMirror: {
      handler(val) {
        if (val) {
          this.coder.setValue("");
          this.coder.clearHistory();
        }
        this.$emit("resetClearCodeMirror");
      },
    },
    formulaOperationHintStr: {
      handler(val) {
        this.$emit("formulaOperationHintStr", val);
      },
    },
  },
  mounted() {
    this._initialize();
    this.setTableHint();
  },
  computed: {
    columnNames() {
      return this.columns.map((keyword) => keyword.field);
    },
  },
  methods: {
    changeMode(val) {
      // Modify the syntax configuration of the editor
      this.coder.setOption("mode", `text/${val}`);
    },
    _initialize() {
      let self = this;
      CodeMirror.defineMode("sql", function () {
        return {
          token: function (stream, state) {
            const regexOperator = new RegExp(
              "(?<![\\w\\d])" +
                `(${self.operators.join("|")})` +
                "(?![\\w\\d])",
              "i"
            );
            const regexColumn = new RegExp(
              "(?<![\\w\\d])" +
                `(${self.columnNames.join("|")})` +
                "(?![\\w\\d])",
              ""
            );
            
            if (stream.match(regexOperator)) {
              const foundHint =
                self.formulaOperationHintList[
                  stream.string.toLowerCase().substring(stream.start, stream.pos)
                ];
                
              if (!self.formulaOperationHintStr.includes(foundHint))
                self.formulaOperationHintStr.push(foundHint);
              return "style1";
            } else if (stream.match(regexColumn)) {
              return "style2";
            } else {
              stream.next();
              return null;
            }
          },
        };
      });
      this.coder = CodeMirror.fromTextArea(this.$refs.textarea, this.options);
      this.coder.setValue(this.formula || this.code);

      this.coder.on("change", (coder) => {
        this.code = coder.getValue();
        this.formulaOperationHintStr = [];
        this.updateFormula(this.code);
      });

      this.changeMode("x-sql");
    },
    setTableHint() {
      const orig = CodeMirror.hint.sql;

      CodeMirror.hint.sql = (cm) => {
        let inner = orig(cm) || {
          from: cm.getCursor(),
          to: cm.getCursor(),
          list: [],
        };

        const operatorList = this.operators.map((keyword) => {
          return {
            text: keyword,
            className: "CodeMirror-hint-keyword cm-keyword",
          };
        });
        const columnList = this.columns.map((keyword) => {
          return { text: keyword.field, className: "CodeMirror-hint" };
        });

        const snippets = [...operatorList, ...columnList];
        const currentWord = cm.getTokenAt(cm.getCursor()).string;
        const list = snippets.filter(function (item) {
          return item.text.indexOf(currentWord) >= 0;
        });

        inner.list = list.length ? list : snippets;

        return inner;
      };
    },
    updateFormula(val) {
      this.$emit("update:formula", val);
      try {
        this.validation = "";
        jsep.addBinaryOp("=");
        const parsedFormula = parseFormula(this.code, this.columnNames);
        this.$emit("formulaParsed", parsedFormula);
      } catch (e) {
        this.validation = e;
      }
    },
  },
};
</script>
<style>
.cm-style1 {
  color: #000080 !important;
  font-weight: bold;
}
.cm-style2 {
  color: rgb(216, 86, 0) !important;
  font-weight: bold;
}
</style>
