<template>
  <div>
    <ul class="dtp-automl-options">
      <router-link :to="{ name: 'Projects', query: { name: selectedProject.name } }">
        <li>
          <svg width="33.2" height="31.597" viewBox="0 0 33.2 31.597">
            <path
              d="M.3,13.267H4.487a.116.116,0,0,1,.116.116V27.159a3.473,3.473,0,0,0,3.473,3.473H24.283a3.473,3.473,0,0,0,3.473-3.473V13.383a.116.116,0,0,1,.116-.116h4.192a.116.116,0,0,0,.073-.205L16.914.557a1.158,1.158,0,0,0-1.47,0L.222,13.062a.116.116,0,0,0,.073.205Z"
              transform="translate(0.421 0.365)"
              stroke-linecap="round"
              stroke-width="1.2"
            />
          </svg>
          <span class="dtp-cursor-pointer">Projects</span>
        </li>
      </router-link>
      <!-- PROJECT ROUTER -->
      <router-link :to="{ name: 'Experiments' }">
        <li>
          <svg width="33.2" height="23.2" viewBox="0 0 33.2 23.2">
            <g transform="translate(-877.4 -514.4)">
              <g transform="translate(878 515)">
                <path
                  d="M1.143,0H30.857A1.079,1.079,0,0,1,32,1V8a1.079,1.079,0,0,1-1.143,1H1.143A1.079,1.079,0,0,1,0,8V1A1.079,1.079,0,0,1,1.143,0Z"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(878 528)">
                <path
                  d="M1.143,0H30.857A1.079,1.079,0,0,1,32,1V8a1.079,1.079,0,0,1-1.143,1H1.143A1.079,1.079,0,0,1,0,8V1A1.079,1.079,0,0,1,1.143,0Z"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(882 532.5)">
                <path
                  d="M0,.5H2"
                  transform="translate(0 -0.5)"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(882 519.5)">
                <path
                  d="M0,.5H2"
                  transform="translate(0 -0.5)"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(882 524)">
                <path
                  d="M.5,0V4"
                  transform="translate(-0.5)"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(906 524)">
                <path
                  d="M.5,0V4"
                  transform="translate(-0.5)"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
            </g></svg
          ><span class="dtp-cursor-pointer">Experiements</span>
        </li>
      </router-link>
      <!-- EXPERIMENTS ROUTER -->
      <router-link :to="{ name: 'DataSets' }">
        <li>
          <svg width="35.2" height="26.448" viewBox="0 0 35.2 26.448">
            <g transform="translate(-876.4 -583.4)">
              <g transform="translate(877 584)">
                <path
                  d="M18.286,22H3.429A3.236,3.236,0,0,1,0,19V3A3.236,3.236,0,0,1,3.429,0H28.571A3.236,3.236,0,0,1,32,3v7.5h0"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(878 590)">
                <path
                  d="M0,.5H30"
                  transform="translate(0 -0.5)"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(898 596)">
                <path
                  d="M6.5,13A6.5,6.5,0,1,0,0,6.5,6.5,6.5,0,0,0,6.5,13Z"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
              <g transform="translate(909 607.5)">
                <path
                  d="M0,0,1.5,1.5"
                  stroke-linecap="round"
                  stroke-width="1.2"
                />
              </g>
            </g></svg
          ><span class="dtp-cursor-pointer">Data Sets</span>
        </li>
      </router-link>
      <!-- DATASETS ROUTER -->
      <!-- <li>
        <svg width="33.2" height="33.2" viewBox="0 0 33.2 33.2">
          <g transform="translate(-877.4 -655.4)">
            <g transform="translate(878 656)">
              <path
                d="M16,32A16,16,0,1,0,0,16,16,16,0,0,0,16,32Z"
                stroke-linecap="round"
                stroke-width="1.2"
              />
            </g>
            <g transform="translate(887 656)">
              <path
                d="M7,32c3.866,0,7-7.163,7-16S10.866,0,7,0,0,7.163,0,16,3.134,32,7,32Z"
                stroke-linecap="round"
                stroke-width="1.2"
              />
            </g>
            <g transform="translate(880.5 679.078)">
              <path
                d="M0,2Q5.087,0,13.5,0T27,2"
                stroke-linecap="round"
                stroke-width="1.2"
              />
            </g>
            <g transform="translate(880.5 662.922)">
              <path
                d="M0-2Q5.087,0,13.5,0T27-2"
                transform="translate(0 2)"
                stroke-linecap="round"
                stroke-width="1.2"
              />
            </g>
          </g></svg
        ><span class="dtp-cursor-pointer">Dashboards</span>
      </li> -->
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';

export default {
  data() {
    return {
      List: [
        { name: "Projects", svg: "" },
        { name: "Experiments" },
        { name: "Data Sets" },
        { name: "Dashboards" },
      ],
    };
  },
  computed: {
    ...mapGetters({ selectedProject: PROJECT_GETTERS.GET_SELECTED_PROJECT }),
  },
};
</script>
<style>
.dtp-automl-options li {
  position: relative;
  padding: 20px 30px 20px 20px;
  border-bottom: 1px solid rgba(207, 205, 209, 0.15);
  font-size: 16px;
  transition: all 0.3s ease;
}
.dtp-automl-options li svg {
  margin-right: 25px;
  vertical-align: bottom;
  fill: transparent;
  stroke: #ffffff;
  transition: all 0.3s ease;
}
.dtp-automl-options li:hover svg {
  stroke: #8bd6cb !important;
}
.dtp-automl-options li:hover {
  color: #8bd6cb;
}
</style>
