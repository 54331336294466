<template>
  <div>
    <div class="dtp-visualize-popup">
      <div class="dtp-visualize-popup-title">
        <div class="dtp-row">
          <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1 dtp-visualize-popup-accItem">
            <span>{{ datasetName }}</span>
          </div>
          <div class="dtp-visualize-popup-titlesecond">
            <span class="dtp-visualize-popup-accItemspan" v-if="size>75000"> {{ $t("Graphs are plotted with randomly selected observations") }} </span>
          </div>
        </div>
        <div @click="$emit('closePopup', null)" class="dtp-popup-close-icon">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="dtp-visualize-popup-content dtp-scrollbar">
        <div
          v-for="(item, index) in Object.keys(filteredVisualizeList)"
          :key="`popup-collapse-${index}`"
          class="dtp-visualize-popup-accItem"
          :class="{ 'dtp-visualize-popup-accItem-active': activeId == index }"
        >
          <div
            @click="activeAcc(index)"
            class="dtp-visualize-popup-accItem-title"
          >
            <span>{{ $t(filteredVisualizeList[item].title) }}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <div class="dtp-visualize-popup-accItem-seperator"></div>
          <div class="dtp-visualize-popup-accItem-content">
            <div class="dtp-row">
              <div
                v-for="(it, index) in filteredVisualizeList[item].items"
                :key="`images-${index}`"
                class="dtp-col dtp-col-6"
              >
                <div v-if="it.column_name.includes(searchText)">
                  {{ it.column_name }}
                  <div :class="{ 'big-image-box': columnindex == index }">
                    <i
                      v-if="columnindex == index"
                      @click="mouseLeave()"
                      class="el-icon-close"
                      aria-hidden="true"
                    ></i>
                    <img
                      :src="it.plot_path"
                      alt=""
                      @click="mouseOver(index)"
                      class="dtp-vgt-chart"
                      :class="{ 'big-image': columnindex == index }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ACC-ITEM -->
      </div>
      <!-- POPUP-CONTENT -->
      <div class="dtp-popup-footer-search">
        <div class="dtp-row dtp-align-center">
          <div class="dtp-col dtp-col-1 dtp-mb--0">
            <span> {{ $t("commons.search") }} </span>
          </div>
          <div class="dtp-col dtp-col-3 dtp-mb--0">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="search"
                class="eds-input_#input"
                v-model="searchText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- POPUP -->
  </div>
</template>

<script>
export default {
  props: {
    datasetName: {
      type: String,
      required: true,
    },
    visualizeList: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchText: "",
      activeId: null,
      columnindex: null,
    };
  },
  computed: {
    filteredVisualizeList() {
      const filtered = {};

      Object.keys(this.visualizeList).forEach((vl) => {
        this.visualizeList[vl].items.forEach((i) => {
          if (i.column_name.includes(this.searchText)) {
            filtered[vl] = Object.assign({}, this.visualizeList[vl]);
          }
        });
      });
      return filtered;
    },
  },
  methods: {
    activeAcc(index) {
      if (index == this.activeId) {
        this.activeId = null;
      } else {
        this.activeId = index;
      }
    },
    mouseOver(value) {
      this.columnindex = value;
    },
    mouseLeave() {
      this.columnindex = null;
    },
  },
};
</script>
<style scoped>
.dtp-visualize-popup {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1100px;
  height: 745px;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
}
.dtp-vgt-chart {
  height: 70px;
  width: 70px;
  vertical-align: middle;
  transform: translateX(30px);
}
.big-image {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 1000px;
  height: 500px;
  box-shadow: 0px 0px 7px 4px rgb(119 130 123 / 65%);
}
.big-image-box {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 1000px;
  height: 500px;
}
.big-image-box > i {
  position: absolute;
  top: 35px;
  right: 15px;
  z-index: 101;
}
.dtp-visualize-popup-title {
  position: relative;
  width: 100%;
  padding: 15px 10px 15px 30px;
  margin-bottom: 50px;
  background-color: #f5f7f7;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
}
.dtp-visualize-popup-title > span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.dtp-visualize-popup-titlesecond > span {
  font-size: 12px;
  color: #9b9b9b;
  font-weight: bold;
}
.dtp-visualize-popup-content {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 30px;
  height: 576px;
  overflow: auto;
}
.dtp-visualize-popup-accItem {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}
.dtp-visualize-popup-accItem-title {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dtp-visualize-popup-accItem-seperator {
  position: relative;
  width: 100%;
  margin: 15px 0 25px 0;
  padding: 0;
  height: 1px;
  background-color: #d6d6d6;
}
.dtp-visualize-popup-accItem span,
.dtp-visualize-popup-accItem i {
  font-size: 16px;
  color: #9b9b9b;
  font-weight: 600;
  transition: all 0.3s ease;
}
.dtp-visualize-popup-accItem-content {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  display: none;
}
.dtp-visualize-popup-accItem-content img {
  width: 100%;
}
.dtp-visualize-popup-accItem-active .dtp-visualize-popup-accItem-content {
  display: block;
}
.dtp-visualize-popup-accItem-active span,
.dtp-visualize-popup-accItem-active i {
  color: #383838 !important;
}
.dtp-visualize-popup-accItem-active i {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.dtp-visualize-popup-accItem-active .dtp-visualize-popup-accItem-seperator {
  background-color: #707070;
}
</style>
