import { GENERAL_MUTATIONS } from "../General/types";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { sortControl } from "../../../../api/AutoMlServices/utils";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { DATASET_ACTIONS, DATASET_GETTERS, DATASET_MUTATIONS } from "./types";

export const datasetStore = {
    state: {
      datasets: [],
      visualization: [],
      dataset: {},
      datasetsFilter: {},
      datasetName: "",
    },
    getters: {
      [DATASET_GETTERS.GET_DATASETS]: (state) => {
        return state.datasets;
      },
      [DATASET_GETTERS.GET_VISUALIZATION]: (state) => {
        return state.visualization;
      },
      [DATASET_GETTERS.GET_DATASET]: (state) => {
        return state.dataset;
      },
      [DATASET_GETTERS.GET_DATASETS_FILTER]: (state) => {
        return state.datasetsFilter;
      },
      [DATASET_GETTERS.GET_DATASET_NAME]: (state) => {
        return state.datasetName;
      },
    },
    mutations: {
      [DATASET_MUTATIONS.SET_DATASETS]: (state, val) => {
        Vue.set(state, "datasets", val);
      },
      [DATASET_MUTATIONS.SET_VISUALIZATION]: (state, val) => {
        Vue.set(state, "visualization", val);
      },
      [DATASET_MUTATIONS.GET_DATASET_BY_ID]: (state, val) => {
        Vue.set(state, "dataset", val);
      },
      [DATASET_MUTATIONS.RESET_DATASET]: (state) => {
        Vue.set(state, "dataset", {});
      },
      [DATASET_MUTATIONS.SET_DATASETS_FILTER](state, filterObj) {
        Vue.set(state, "datasetsFilter", filterObj);
      },
      [DATASET_MUTATIONS.SET_DATASET_NAME]: (state, val) => {
        state.datasetName = val;
      },
    },
    actions: {
      [DATASET_ACTIONS.FETCH_DATASETS]: async ({ commit }, params) => {
        const controlledParams = sortControl(params);
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.getDatasetsList,
          queryParam: controlledParams,
          withSuccessNotify: false,
          successMutation: DATASET_MUTATIONS.SET_DATASETS,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "datasets",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      async [DATASET_ACTIONS.FETCH_VISUALIZATION](
        { commit },
        { requestComp, datasetId, visualizationType }
      ) {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.getVisualization,
          queryParam: { datasetId, visualizationType },
          loadingComponent: requestComp,
          successMutation: DATASET_MUTATIONS.SET_VISUALIZATION,
          resultSelector: (result) => result.data?.data
        });
      },
      async [DATASET_ACTIONS.DELETE_DATASET_BY_ID]({ commit, dispatch }, { requestComp, id, page, count }) {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.deleteDataset,
          queryParam: id,
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(DATASET_ACTIONS.FETCH_DATASETS, { page, count });
          }
        });
      },
      async [DATASET_ACTIONS.GET_DATASET_BY_ID]({ commit }, { dataset_id, withLoading }) {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.getDatasetById,
          queryParam: dataset_id,
          withLoading,
          successMutation: DATASET_MUTATIONS.GET_DATASET_BY_ID,
          resultSelector: (result) => result.data?.data
        });
      },
      [DATASET_ACTIONS.UPLOAD_CSV]: async ({ commit }, params) => {
        const { requestComp, formData } = params;
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.uploadCsv,
          bodyParam: formData,
          loadingComponent: requestComp,
        });
      },
      [DATASET_ACTIONS.UPLOAD_PREVIEW_CSV]: async ({ commit }, params) => {
        const { requestComp, formData } = params;
        return await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.datasets,
          serviceMethodSub: serviceMethodSub.uploadPreviewCsv,
          bodyParam: formData,
          loadingComponent: requestComp,
          withSuccessNotify: false,
          withErrorNotify: false
        });
      },
      [DATASET_ACTIONS.SET_DATASET_NAME]: ({ commit }, val) => {
        try {
          if (process.env.VUE_APP_AUTOML_TEST_ENV == "true") {
            /* Call SET_DATASET_NAME API */
          } else {
            commit(DATASET_MUTATIONS.SET_DATASET_NAME, val);
          }
        } catch (error) {
          commit(GENERAL_MUTATIONS.ADD_NOTIFY, error);
        }
      },
    }
}