<template>
  <svg
    id="exit-to-app"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <path
      id="Path_exit-to-app"
      data-name="Path / exit-to-app"
      d="M19.889,3H5.111A2.1,2.1,0,0,0,3,5.111V9.333H5.111V5.111H19.889V19.889H5.111V15.667H3v4.222A2.111,2.111,0,0,0,5.111,22H19.889A2.111,2.111,0,0,0,22,19.889V5.111A2.111,2.111,0,0,0,19.889,3M10.473,16.279l1.5,1.5L17.25,12.5,11.972,7.222l-1.5,1.488,2.734,2.734H3v2.111H13.207Z"
      transform="translate(-3 -3)"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
