<template>
  <div
    :class="{
      'dtp-aml': compName == 'app-automllayout',
      'dtp-aml-section': $route.meta.view == 'projectMenu',
    }"
  >
    <component
      v-bind:is="compName"
      @showVersionPopup="versionInfoPopupVisible = true"
      @showExportLogsPopup="exportLogsClicked"
    ></component>
    <router-view />

    <!-- License info popup -->
    <el-dialog
      id="automl-license-info-popup"
      :visible.sync="versionInfoPopupVisible"
      @closed="versionInfoPopupVisible = false"
    >
      <div slot="title">
        <span style="font-weight: bold">{{ $t("Version Information") }}</span>
      </div>
      <div>
        <span class="popupItemSubtitle">{{ $t("layout.version") }}:</span
        >{{ versionNumber }}
      </div>
    </el-dialog>

    <!-- Export Logs Popup -->
    <el-dialog
      id="automl-export-logs-popup"
      :visible.sync="exportLogsPopupVisible"
      @closed="exportLogsPopupVisible = false"
      
    >
      <div slot="title">
        <span style="font-weight: bold">{{ "Export Logs" }}</span>
      </div>
        <LogExportPopup
      @closed="exportLogsPopupVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import AutoMlLayout from "./components/Layout/AutoMlLayout";
import LogExportPopup from "./components/AutoMl/Popup/LogExportPopup.vue"

export default {
  components: {
    "app-automllayout": AutoMlLayout,
    LogExportPopup,
  },
  watch: {
    exportLogsPopupVisible() {
      this.logExportsDatetimeValue = [
        this.subtractHours(1).toString(),
        new Date().toString(),
      ];
    },
  },
  data() {
    return {
      logExportsDatetimeValue: "",
      versionInfoPopupVisible: false,
      exportLogsPopupVisible: false,
    };
  },
  mounted() {},

  computed: {
    versionNumber() {
      return process.env.VUE_APP_AUTOML_VERSION ?? "N/A";
    },
    compName() {
      let compName = "";
      if (this.$route.meta.layout == "AutoMlLayout") {
        compName = "app-automllayout";
      }
      return compName;
    },
  },
  methods: {
    subtractHours(numOfHours, date = new Date()) {
      date.setHours(date.getHours() - numOfHours);

      return date;
    },
    exportLogsClicked() {
      this.exportLogsPopupVisible = true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

.dtp-aml * {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.dtp-aml-section {
  padding-right: 230px;
}

.dtp-fcolor-333333 {
  color: #333333;
}
</style>

<style lang="scss" scoped>
::v-deep #automl-license-info-popup .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
  padding: 10px 20px;
}
::v-deep #automl-license-info-popup .el-dialog__body {
  padding: 20px;
}

::v-deep #automl-license-info-popup .el-dialog__headerbtn {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
}
::v-deep #automl-license-info-popup .el-dialog {
  width: 450px;
}
.popupItemSubtitle {
  font: normal normal normal 14px/36px Roboto;
  letter-spacing: 0px;
  color: #8c8c8c;
  width: 110px;
  display: inline-block;
}
::v-deep #automl-export-logs-popup .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
  padding: 10px 20px;
}
::v-deep #automl-export-logs-popup .el-dialog__body {
  padding: 20px;
}

::v-deep #automl-export-logs-popup .el-dialog__headerbtn {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
}
::v-deep #automl-export-logs-popup .el-dialog {
  max-width: 75%;
  min-height: 500px;
  min-width: 75%;
 
}
</style>

