<template>
  <div v-loading="loading">
    <vue-good-table
      :columns="tableCol"
      :rows="enhancedExperiments"
      :row-style-class="rowStyleClassFn"
    >
      <!-- for header localization -->
      <template slot="table-column" slot-scope="props">
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <el-tooltip
            :content="$t('tooltip.start')"
            placement="top"
            effect="light"
            v-if="props.row.active != true && props.row.status != 'COMPLETED'"
          >
            <img
              src="../../../../assets/images/icons/automl-memory-icon.svg"
              alt="visualize"
              @click.prevent="start(props.row.project_id, props.row.id)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-memory-icon"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.play')"
            placement="top"
            effect="light"
            v-if="
              props.row.state.type == 'success' &&
                props.row.started_by == username
            "
          >
            <img
              @click.stop="exploreData(props.row)"
              src="../../../../assets/images/icons/automl-play-icon.svg"
              alt="door"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-play-icon"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.stop')"
            placement="top"
            effect="light"
            v-if="props.row.active == true && props.row.status != 'COMPLETED'"
          >
            <img
              src="../../../../assets/images/icons/automl-memory-stop-icon.svg"
              alt="visualize"
              @click.prevent="stop(props.row.project_id, props.row.id, 'dtl')"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-memory-stop-icon"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('buttons.clear')"
            placement="top"
            effect="light"
          >
            <img
              v-if="
                props.row.status == 'CREATED' ||
                  props.row.status == 'PREPROCESSING' ||
                  props.row.status == 'TRAINING' ||
                  props.row.active == false
              "
              src="../../../../assets/images/icons/automl-clear-icon.svg"
              @click.prevent="
                clear({
                  requestComp: requestComp,
                  experimentId: props.row.id,
                  type: 'dtl',
                })
              "
              alt="visualize"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-clear-icon"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.delete')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../../assets/images/icons/automl-delete-icon.svg"
              @click.prevent="
                deleteX(props.row.project_id, props.row.id, currentPage, 'dtl')
              "
              alt="visualize"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-delete-icon"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.model_governance')"
            placement="top"
            effect="light"
          >
            <img
              v-if="
                props.row.status == 'TRAINED' ||
                  props.row.status == 'TUNED' ||
                  props.row.status == 'COMPLETED'
              "
              src="../../../../assets/images/icons/automlExperiment5.svg"
              @click.prevent="goGovernance(props.row)"
              alt="visualize"
              class="dtp-cursor-pointer dtp-vgt-actions-icon experiments-governance-icon"
            />
          </el-tooltip>
        </span>
        <span
          class="dtp-display-flex"
          v-else-if="props.column.field == 'status'"
        >
          <!-- <el-badge is-dot :type="props.row.state.type"></el-badge> -->
          <div class="dtp-table-status-icon"></div>
          <span v-if="props.row.state.type == 'success'"> ({{ $t("status.Ready") }}) </span>
          <span v-else-if="props.row.state.type == 'warning'"> ({{ $t("status.Loading") }}) </span>
          <span v-else-if="props.row.state.type == 'danger'"> ({{ $t("status.Error") }}) </span>
          <span v-else> ({{ $t("status.Created") }}) </span>
        </span>
      </template>
    </vue-good-table>
    <CustomPagination
      :lastPage="lastPage"
      :pageSize.sync="pageSize"
      :showPageSizeSelect="true"
      @change="changePage"
    />
  </div>
</template>
<script>
/* eslint-disable */
import notify from "../../../../mixins/AutoML/notify";
import { VueGoodTable } from "vue-good-table";
import { experimentColumns } from "../data/experiments";
import {
  ACTION,
  GENERAL_GETTERS,
  GETTER,
  MUTATION,
} from "../../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomPagination from "../../CustomPagination";
import { formatDate } from "../../../../helper/format";
import preferences from "../../../../mixins/AutoML/preferences";
import pipeline from "../../../../mixins/AutoML/pipeline";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../../store/modules/AutoML/Projects/types';
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS, EXPERIMENT_MUTATIONS } from '../../../../store/modules/AutoML/Experiments/types';
import { DATASET_ACTIONS } from '../../../../store/modules/AutoML/Dataset/types';
import { MODELS_MUTATIONS } from '../../../../store/modules/AutoML/Models/types';
import { NAVIGATION_MUTATIONS } from '../../../../store/modules/AutoML/Navigation/types';

export default {
  props: {
    projectid: {
      required: true,
    },
  },
  mixins: [notify, preferences, pipeline],
  components: {
    VueGoodTable,
    CustomPagination,
  },
  data() {
    return {
      statusTypes: {
        RUNNING: {
          type: "success",
          key: "RUNNING",
        },
        TERMINATED: {
          type: "danger",
          key: "TERMINATED",
        },
        FAILURE: {
          type: "danger",
          key: "FAILURE",
        },
        WAITING: {
          type: "warning",
          key: "WAITING",
        },
        INACTIVE: {
          type: "primary",
          key: "INACTIVE",
        },
      },
      pageSize: 10,
      currentPage: 1,
      tableCol: experimentColumns,
    };
  },
  watch: {
    pageSize: {
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
    ...mapActions({
      fetchExperimentSessionStatus: EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_SESSION_STATUS,
      fetchExperimentsByProjectId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_BY_PROJECT_ID,
      startSession: PROJECT_ACTIONS.START_SESSION,
      setDatasetName: DATASET_ACTIONS.SET_DATASET_NAME,
      deleteExperiment: PROJECT_ACTIONS.DELETE_EXPERIMENTS,
      clearExperiment: EXPERIMENT_ACTIONS.CLEAR_EXPERIMENTS,
      stopSession: PROJECT_ACTIONS.STOP_SESSION,
      fetchPipeline: PROJECT_ACTIONS.FETCH_PIPELINE,
    }),
    ...mapMutations({
      setExperimentSessionStatus: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_SESSION_STATUS,
      clearExpList: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS,
      resetNavigationList: NAVIGATION_MUTATIONS.RESET_NAVIGATION_LIST,
      setNavigationCount: NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST,
      resetProblemConf: MODELS_MUTATIONS.RESET_PROBLEM_CONFIGURATION,
    }),
    async start(projectId, experimentId) {
      await this.startSession({
        requestComp: this.requestComp,
        projectId,
        experimentId,
      });
      this.init();
    },
    async init() {
      const payload = {
        page: this.currentPage,
        pid: this.projectid,
        count: this.pageSize,
      };
      await this.fetchExperimentsByProjectId(payload);
      this.fetchSessionStatus();
    },
    fetchSessionStatus() {
      const identifiers = this.experiments
        .filter((ex) => ex.active)
        .map((e) => e.id);

      if (identifiers.length)
        this.fetchExperimentSessionStatus({
          identifiers,
        });
    },
    goGovernance(data) {
      this.$router.push({
        name: "ModelGovernance",
        query: { experimentName: data.name },
      });
    },
    async exploreData(data) {
      this.resetNavigationList();
      this.resetProblemConf();
      //this.routePage("DataPreview", data.id);
      if (data.status == "CREATED") {
        this.routePage("DataPreview", data.id);
      } else if (data.status == "PREPROCESSING") {
        await this.getPipeline(data.id, data.project_id);
        this.setNavigationCount(this.linkCount);
        this.routePage(this.routingName, data.id);
      } else if (data.status == "TRAINED") {
        this.setNavigationCount(5);
        this.routePage("ModelResults", data.id);
      } else if (data.status == "COMPLETED") {
        this.setNavigationCount(4);
        this.routePage("ModelSelection", data.id);
      } else if (data.status == "TRAINING") {
        this.setNavigationCount(4);
        this.routePage("ModelRunning", data.id);
      } else if (data.status == "TUNING") {
        this.setNavigationCount(4);
        this.routePage("ModelRunning", data.id);
      } else if (data.status == "TUNED") {
        this.setNavigationCount(6);
        this.routePage("TuneModel", data.id);
      }
    },
    routePage(pageName, id) {
      this.$router.push({
        name: pageName,
        query: {
          experimentId: id,
        },
      });
    },
    async deleteX(projectId, experimentId, page, type) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      await this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t("Experiment")]),
        this.$t("confirmation.Delete Experiment"),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      await this.deleteExperiment({
        requestComp: this.requestComp,
        projectId,
        experimentId,
        page,
        type,
      });
      this.init();
    },
    async clear(payload) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      await this.$confirm(
        this.$t("confirmation.resetExperimentWarning"),
        this.$t("confirmation.Reset Experiment"),
        {
          confirmButtonText: this.$t("confirmation.Reset Experiment"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      await this.clearExperiment(payload);
      this.init();
    },
    async stop(project_id, id, type) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      await this.stopSession({
        requestComp: this.requestComp,
        projectId: project_id,
        experimentId: id,
        type: type,
      });
      this.init();
    },

    rowStyleClassFn(row) {
      return row.state.type == "success"
        ? "successColor"
        : row.state.type == "warning"
        ? "warningColor"
        : row.state.type == "danger"
        ? "dangerColor"
        : "infoColor";
    },
  },
  mounted() {
    this.requestComp = "experimentList";
    this.clearExpList([]);
    this.init();
  },
  computed: {
    ...mapGetters({
      experiments: EXPERIMENT_GETTERS.GET_EXPERIMENTS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      experimentsSessionStatus: EXPERIMENT_GETTERS.GET_EXPERIMENTS_SESSION_STATUS,
      pipeline: PROJECT_GETTERS.GET_PIPELINE,
    }),
    lastPage() {
      return this.pagination.experiments?.last_page ?? 1;
    },
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    enhancedExperiments() {
      const activeExperimentsCount = this.experiments.filter((e) => e.active);
      if (activeExperimentsCount.length == 0)
        this.setExperimentSessionStatus([]);
      return this.experiments.map((ex) => {
        const found = this.experimentsSessionStatus.find(
          (ess) => ess.identifier === ex.id
        );
        return {
          ...ex,
          state: found
            ? this.statusTypes[found.status.state]
            : this.statusTypes.INACTIVE,
          formatted_created_at: formatDate(
            ex.created_at,
            this.preferences.longDateFormat
          ),
        };
      });
    },
  },
};
</script>
