<template>
  <div>
    <el-dropdown trigger="click" class="dtp-item-center margin-top-8">
      <Icon icon="kebabMenu" size="sm"></Icon>
      <el-dropdown-menu slot="dropdown">
        <span class="dropdown-title"> {{ $t("General Actions") }}</span>
        <el-dropdown-item
          class="action-title"
          v-for="(action, index) in getGeneralActionsList"
          :key="`general-action-${index}`"
        >
          <div
            @click.prevent="
              !isExperiementCompleted(row.experiment_status)
                ? null
                : action.onClick(row)
            "
          >
            <Icon :icon="action.srcImagePath" size="md"></Icon>
            <span class="margin-left">{{
              $t(getActionToolTipText(action.contentName, row))
            }}</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item divided></el-dropdown-item>
        <span v-if="row.experiment_type !== 'CUSTOM'" class="dropdown-title">
          {{ $t("ModelDiagnostic") }}
        </span>
        <el-dropdown-item
          class="action-title"
          v-for="(action, index) in getModelDiagnosticsList"
          :key="`model-diagnostic-${index}`"
        >
          <div
            @click.prevent="
              !isExperiementCompleted(row.experiment_status)
                ? null
                : action.onClick(row)
            "
          >
            <Icon :icon="action.srcImagePath" size="md"></Icon>
            <span class="margin-left">{{
              $t(getActionToolTipText(action.contentName, row))
            }}</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import "vue-good-table/dist/vue-good-table.css";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  actionsType,
  modelStatus as StatusDef,
} from "./modelTypes";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import { PRIVILEGES } from "../../../helper/authorization";
import {
  MODELS_ACTIONS,
  MODELS_GETTERS,
  MODELS_MUTATIONS,
} from "../../../store/modules/AutoML/Models/types";
import Icon from "../../Icons/Icon.vue";

export default {
  mixins: [componentAvailabilityCheck],
  props: {
    row: {
      type: Object,
      required: true,
    },
    requestComp: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      summaryPlotResult: null,
      selectedRow: {},
      buildStatusTypes: {
        FINISHED: "FINISHED",
        IN_PROGRESS: "IN_PROGRESS",
        FAILED: "FAILED",
      },
      ALGORITHM_CODE_MAP: {
        classification: {
          RANDOM_FOREST: 100,
          XGBOOST: 101,
          LOGISTIC_REGRESSION: 102,
        },
        regression: {
          RANDOM_FOREST: 200,
          XGBOOST: 201,
          LINEAR_REGRESSION: 202,
        },
        clustering: { KMEANS: 300, DBSCAN: 301 },
      },
      INTERPRET_STATUS: {
        SUCCESS: "SUCCESS",
        FAILED: "FAILED",
        IN_PROGRESS: "IN_PROGRESS",
        PENDING: "PENDING",
      },
      interpretStatus: null,
    };
  },
  computed: {
    modelStatus() {
      return StatusDef;
    },
    ...mapGetters({
      modellink: MODELS_GETTERS.GET_MODEL_LINK,
      predictionLink: MODELS_GETTERS.GET_PREDICTION_LINK,
    }),
    getGeneralActionsList() {
      return [
        ...(this.isPredictionAvailable
          ? [
              {
                contentName: actionsType.Predict,
                srcImagePath: "makePredictionWithNewDataset",
                onClick: (row) => this.makePredictions(row),
              },
            ]
          : []),
        ...(!this.predictResultVisibleCodes.includes(this.row.algorithm_code) &&
        this.isDownloadModelObjectAvailable
          ? [
              {
                contentName: actionsType.PredictResult,
                srcImagePath: "download",
                onClick: (row) => this.downloadPredictionResult(row),
              },
            ]
          : []),
        ...(this.isDLTestResultButtonAvailable
          ? [
              {
                contentName: actionsType.Download,
                srcImagePath: "download",
                onClick: (row) => this.modelDownload(row),
              },
            ]
          : []),
        ...(this.isAuditAvailable
          ? [
              {
                contentName: actionsType.ModelAuditLog,
                srcImagePath: "abacus",
                onClick: (row) => this.$emit("auditLogClicked", row),
              },
            ]
          : []),
      ];
    },
    getModelDiagnosticsList() {
      return [
        ...(this.isModelDiagnosticsButtonAvailable &&
        this.row.experiment_type !== "CUSTOM" &&
        this.equationVisibleCodes.includes(this.row.algorithm_code)
          ? [
              {
                contentName: actionsType.Equation,
                srcImagePath: "equation",
                onClick: (row) => {
                  this.$emit("equationClicked", {
                    experiment: row.experiment,
                    model: row.model,
                  });
                  this.fetchModelEquation(row.model_id);
                },
              },
            ]
          : []),
        ...(this.isModelDiagnosticsButtonAvailable &&
        this.row.experiment_type !== "CUSTOM"
          ? [
              {
                contentName: actionsType.Logs,
                srcImagePath: "log",
                onClick: (row) => {
                  this.$emit("logsClicked", {
                    title: row.experiment + " - " + row.model,
                    experimentId: row.experiment_id,
                    selectedRow: row,
                  });
                },
              },
            ]
          : []),
        ...(this.isModelDiagnosticsButtonAvailable &&
        this.row.experiment_type !== "CUSTOM"
          ? [
              {
                contentName: actionsType.Pipeline,
                srcImagePath: "pipeline",
                onClick: (row) => {
                  this.$emit("pipelineClicked", {
                    experiment: row.experiment,
                    model: row.model,
                    selectedRow: row,
                  });
                  this.pipelineVisible = true;
                },
              },
            ]
          : []),
      ];
    },
    equationVisibleCodes() {
      return [
        this.ALGORITHM_CODE_MAP.classification.LOGISTIC_REGRESSION,
        this.ALGORITHM_CODE_MAP.regression.LINEAR_REGRESSION,
      ];
    },
    predictResultVisibleCodes() {
      return [
        this.ALGORITHM_CODE_MAP.clustering.KMEANS,
        this.ALGORITHM_CODE_MAP.clustering.DBSCAN,
      ];
    },
    isDownloadModelObjectAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DOWNLOAD_MODEL_OBJECT
      );
    },
    isPredictionAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MAKE_PREDICTION_BUTTON
      );
    },
    isDLTestResultButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DL_TEST_RESULT_BUTTON
      );
    },
    isModelDiagnosticsButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_DIAGNOSTICS_BUTTON
      );
    },
    isAuditAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON
      );
    },
  },
  methods: {
    ...mapMutations({
      setModelLink: MODELS_MUTATIONS.SET_MODEL_LINK,
      setPredictionLink: MODELS_MUTATIONS.SET_PREDICTION_LINK,
    }),
    ...mapActions({
      downloadPrediction: MODELS_ACTIONS.DOWNLOAD_PREDICTION_RESULTS,
      downloadModel: MODELS_ACTIONS.MODEL_DOWNLOAD,
      fetchModelEquation: MODELS_ACTIONS.FETCH_MODEL_EQUATION,
      postModelExplanationPlot: MODELS_ACTIONS.POST_MODEL_EXPLANATION_PLOT,
      fetchModelExplanationStatus:
        MODELS_ACTIONS.FETCH_MODEL_EXPLANATION_STATUS,
    }),
    isExperiementCompleted(expStatus) {
      return expStatus === this.modelStatus.Completed;
    },

    async checkSummary(model_id) {
      this.interpretStatus = this.INTERPRET_STATUS.PENDING;

      return await this.postModelExplanationPlot({
        requestComp: this.requestComp,
        model_id,
        plot_type: "shap_summary",
        params: {},
      });
    },
    getActionToolTipText(actionType, row) {
      if (actionType == actionsType.ModelDiagnostic) {
        return "ModelDiagnostic";
      }
      if (actionType == actionsType.Build) {
        return "Build";
      }
      if (actionType == actionsType.ModelAuditLog) {
        return "ModelAuditLog";
      }
      if (actionType == actionsType.Pipeline) {
        return "Pipeline";
      }
      if (actionType == actionsType.Logs) {
        return "Logs";
      }
      if (actionType == actionsType.Equation) {
        return "Equation";
      }

      if (row.experiment_status != this.modelStatus.Completed) {
        return "FinishExperiment";
      } else {
        if (actionType === actionsType.Predict) {
          return "MakePredict";
        } else if (actionType === actionsType.Download) {
          return "DownloadModel";
        } else if (actionType == actionsType.PredictResult) {
          return "PredictResult";
        } else if (actionType == actionsType.DownloadScores) {
          return "DownloadScores";
        }
      }
    },
    downloadScores(data) {
      //backend bekleniyor
    },
    modelMonitor(data) {
      //backend bekleniyor
    },
    makePredictions(data) {
      if (data.experiment_status == this.modelStatus.Completed) {
        const experiment_id = data.experiment_id;
        const model_id = data.model_id;
        this.$router.push({
          name: "Predictions",
          query: { experiment_id, model_id },
        });
      }
    },
    clearLinks() {
      this.setModelLink("");
      this.setPredictionLink("");
    },
    async downloadPredictionResult(data) {
      if (data.experiment_status == this.modelStatus.Completed) {
        const objProject = {
          requestComp: this.requestComp,
          data: {
            artifact_uri: data.artifact_uri,
          },
        };
        await this.downloadPrediction(objProject);

        if (this.predictionLink) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.download = "Model";
          a.href = this.predictionLink;
          a.click();
        }
        this.clearLinks();
      }
    },
    async modelDownload(data) {
      if (data.experiment_status == this.modelStatus.Completed) {
        const objProject = {
          requestComp: this.requestComp,
          data: {
            artifact_uri: data.artifact_uri,
            experiment_id: data.experiment_id,
          },
        };
        await this.downloadModel(objProject);
        if (this.modellink) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.download = "Model";
          a.href = this.modellink;
          a.click();
        }
        this.clearLinks();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-title {
  color: var(--color-primary);
  font-size: 14px;
}
.dropdown-title {
  font-size: 12px;
  margin-left: 20px;
}
.margin-left {
  margin-left: 15px;
}
.margin-top-8 {
  margin-top: 8px;
}
</style>
