<template>
  <el-dialog
    width="450px"
    :visible.sync="dialogVisible"
    @closed="
      $emit('closed');
      dialogVisible = false;
    "
  >
    <div slot="title">
      <span class="popupTitle">{{ $t("modelGovernance.build") }}</span>
    </div>
    <div class="dtp-row dtp-align-center">
      <div class="dtp-col dtp-col-12 dtp-text--right">
        <div class="buildRow">
          <div
            class="
              eds-field_#control eds-field_.eds-input
              eds-input
              dtp-selectbox
            "
          >
            <select
              v-model="selectedApplication"
              class="eds-input_#input eds-input_#input.dropdown"
            >
              <option selected disabled value="">{{ $t("Select Application") }}</option>
              <option
                v-for="item in applications"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="dtp-col dtp-col-12 dtp-text--center">
        <div class="buildRow">
          <span> {{ $t("or") }}  </span>
        </div>
      </div>
      <div class="dtp-col dtp-col-12 dtp-text--center">
        <div class="buildRow">
          <router-link to="/model-governance/applications" class="dtp-fcolor-436DA9">
          + {{ $t("Create New Application") }} 
          </router-link>
        </div>
      </div>
      <div class="dtp-col dtp-col-12 dtp-text--right">
        <button @click="confirmBuild" class="eds-button eds-button.basic">
          {{  $t("commons.confirm") }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    applications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedApplication: "",
    };
  },
  methods: {
    confirmBuild() {
      if (this.selectedApplication) {
        this.$emit("buildApplication", this.selectedApplication);
      }
    },
  },
};
</script>
<style scoped>
.buildRow {
  font-size: 15px;
  overflow: auto;
  padding-bottom: 20px;
}
.popupTitle {
  font-weight: bold;
  text-align: center;
}
</style>