<template>
  <div>
    <div v-if="!dataset" class="dtp-section">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-8">
          <PageViewBar
            :title="project.name"
            :routerLink="''"
            :activeFilters="false"
            :activeSearch="true"
            :tooltipSearchName="'Experiment'"
            :headerArray="filterArray"
            @getFilter="getFilter"
            :filterList="filterList"
            :createBtn="false"
            :calculateview="false"
            @changedSearch="changedSearch"
          />
        </div>
        <div class="dtp-col dtp-col-4 dtp-text--right">
          <button
            class="aml-btn eds-button.primary"
            :loading="loading"
            @click="refresh()"
          >
            <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12">
          <div class="dtp-table-header dtp-bgcolor-F1F8FF">
            <span
              class="
                dtp-aml-font-bold dtp-fcolor-333333 dtp-fontsize-14
                dtp-mr--2
              "
              >{{ $t("experiment.Experiments") }}</span
            >

            <div class="dtp-panel-formbox">
              <el-tooltip
                :content="$t('tooltip.createexp')"
                placement="top"
                effect="light"
              >
                <button
                  @click="
                    isExperimentCreatePopupShown = !isExperimentCreatePopupShown
                  "
                  class="aml-icon-btn"
                >
                  <Icon icon="add"></Icon>
                </button>
              </el-tooltip>
              <div
                v-if="isExperimentCreatePopupShown"
                class="dtp-create-popup dtp-create-popup-absolute"
              >
                <div class="dtp-create-popup-title dtp-text--center">
                  {{ $t("experiment.Create Experiment") }}
                  <div
                    @click="isExperimentCreatePopupShown = false"
                    class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
                  >
                    <span class="el-icon-close"></span>
                  </div>
                </div>
                <div class="dtp-create-popup-content">
                  <div class="dtp-row">
                    <div
                      v-if="!isDatasetCreateContentShown"
                      class="dtp-col dtp-col-12 dtp-text--center"
                    >
                      <div
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                          dtp-mb--1
                        "
                      >
                        <input
                          type="text"
                          :value="experimentName"
                          @change="
                            (value) => setExperimentName(value.target.value)
                          "
                          :placeholder="$t('input.experiment_name')"
                          class="eds-input_#input"
                          @keydown.space.prevent
                        />
                      </div>
                    </div>

                    <div
                      v-if="!isDatasetCreateContentShown"
                      class="dtp-col dtp-col-10 dtp-text--center dtp-mb--1"
                    >
                      <DatasetFiltering
                        @onSelected="callSelectedId"
                      />
                    </div>

                    <div
                      v-if="!isDatasetCreateContentShown"
                      class="dtp-col dtp-col-2 dtp-text--center dtp-mb--2"
                    >
                      <el-tooltip
                        :content="$t('Add New Data Set')"
                        placement="top"
                        effect="light"
                      >
                        <button
                          @click="
                            isDatasetCreateContentShown =
                              !isDatasetCreateContentShown
                          "
                          class="aml-btn eds-button.basic"
                        >
                          <Icon icon="add"></Icon>
                        </button>
                      </el-tooltip>
                    </div>

                    <div
                      v-if="
                        !isDatasetCreateContentShown && selectDeviceTypeVisible
                      "
                      class="dtp-col dtp-col-12 dtp-text--center"
                    >
                      <div class="dtp-aml-el-select">
                        <el-select
                          v-model="formSelectedDeviceType"
                          filterable
                          :placeholder="$t('placeholder.Device Type')"
                        >
                          <el-option
                            v-for="item in deviceTypes"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!isDatasetCreateContentShown"
                    class="dtp-col dtp-col-12 dtp-text--right dtp-mt--1"
                  >
                    <button
                      @click="callCreateExperiment"
                      class="eds-button eds-button.basic"
                    >
                      {{ $t("commons.create") }}
                    </button>
                  </div>

                  <DataSetCreateContent
                    :showBackButton="true"
                    v-if="isDatasetCreateContentShown"
                    @back="
                      isDatasetCreateContentShown = !isDatasetCreateContentShown
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <Experiments
            ref="experimentComponentRef"
            :projectid="selectedProjectId"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import UseDataSetList from "../Projects/Components/UseDataSetList";
import Experiments from "../Projects/Components/Experiments";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DataSetCreateContent from "../DataSets/Components/DataSetCreateContent";
import notify from "../../../mixins/AutoML/notify";
import PageViewBar from "../../AutoMl/Helper/Pageviewbar";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';
import { EXPERIMENT_ACTIONS, EXPERIMENT_MUTATIONS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_ACTIONS } from '../../../store/modules/AutoML/Dataset/types';
import { DATASET_STATUSES } from "../../../models/dataset";
import Icon from "../../Icons/Icon.vue"
import DatasetFiltering from "../utils/DatasetFiltering.vue"

export default {
  components: {
    DataSetCreateContent,
    Experiments,
    PageViewBar,
    Icon,
    DatasetFiltering,
  },
  mixins: [notify],
  data() {
    return {
      deviceTypes: [
        {
          id: "CPU",
          label: "CPU",
        },
        {
          id: "GPU",
          label: "GPU",
        },
      ],
      formSelectedDeviceType: "",
      selectedDatasetId: "",
      experimentName: "",
      fakedatasetList: [],
      dataset: false,
      isExperimentCreatePopupShown: false,
      isDatasetCreateContentShown: false,
      searchName: "",
      filterList: [],
      selectDeviceTypeVisible: true,
      filterArray: [
        {
          alias: "table.header.name",
          name: "name",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.target",
          name: "target",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.dataset",
          name: "dataset",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.scorer",
          name: "scorer",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.testscore",
          name: "testscore",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.status",
          name: "status",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
        {
          alias: "table.header.totaltime",
          name: "totaltime",
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        },
      ],
      datasetCount: 100,
      searchDatasetInput: ""
    };
  },
  watch: {
    deviceType: {
      handler() {
        this.checkDeviceType();
      },
      deep: true,
    },
  },
  mounted() {
    this.requestComp = "projectDetails";
    this.changedSearch("");
    this.getProjectByProjectId(this.selectedProjectId);
    this.callFetchDatasets();
    this.checkDeviceType();
  },
  computed: {
    ...mapGetters({
      project: PROJECT_GETTERS.GET_SELECTED_PROJECT,
      deviceType: GENERAL_GETTERS.GET_DEVICE_TYPE,
    }),
    selectedProjectId() {
      return this.$route.query.selectedProjectId;
    },
  },
  methods: {
    ...mapActions({
      fetchExperimentsByProjectId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_BY_PROJECT_ID,
      getProjectByProjectId: PROJECT_ACTIONS.GET_PROJECT_BY_PROJECT_ID,
      createExperiments: PROJECT_ACTIONS.CREATE_EXPERIMENTS,
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
    }),
    ...mapMutations({
      setFilter: EXPERIMENT_MUTATIONS.SET_FILTER,
    }),
    checkDeviceType() {
      if (this.deviceType === this.deviceTypes[0].id) {
        this.formSelectedDeviceType = this.deviceTypes[0].id;
        this.selectDeviceTypeVisible = false;
      }
    },
    callFetchDatasets() {
      this.fetchDatasets({ page: 1, count: this.datasetCount, status: DATASET_STATUSES.READY, name: this.searchDatasetInput });
    },
    getFilter() {
      this.filterList = this.filterArray.filter((x) => x.isActive);
      //TODO boyle filter kullanmayalım degistirelim
      this.filterList.forEach((item) => {
        var test = this.table.data.filter((x) => x[item.name] == item.data);
        this.table.data = test;
      });
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      const filterArray = [{ name: this.searchName }];
      this.setFilter({ filterName: "experiments", filterDetail: filterArray });
      this.refresh();
    },
    refresh() {
      this.$refs.experimentComponentRef.init();
    },
    async callCreateExperiment() {
      if (this.experimentName == "" || this.experimentName == null) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Experiment name field can not be empty"),
          type: "warning",
        });
      } else if (!this.selectedDatasetId) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Dataset field can not be empty"),
          type: "warning",
        });
      } else if (!this.formSelectedDeviceType) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Device type field can not be empty"),
          type: "warning",
        });
      } else {
        await this.createExperiments({
          requestComp: this.requestComp,
          projectId: this.selectedProjectId,
          data: {
            experiment_name: this.experimentName,
            dataset_id: this.selectedDatasetId,
            device: this.formSelectedDeviceType,
          },
        });
        this.refresh();
        this.experimentName = null;
        this.selectedDatasetId = "";
        this.formSelectedDeviceType = "";
      }
      this.isExperimentCreatePopupShown = false;
    },
    setExperimentName(experimentName) {
      this.experimentName = experimentName;
    },
    callSelectedId(value){
      this.selectedDatasetId= value;
    },
  },
};
</script>

<style scoped>
.componentLabels {
  display: block;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-top: 10px;
}
</style>
