<template>
  <div class="dtp-section">
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-2 dtp-col-xl3">
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title">{{
              $t("automl.featureListTitle")
            }}</span>
          </div>
        </div>
        <div class="dtp-col dtp-col-2 dtp-col-xl3 dtp-aml-multi-select">
          <multiselect
            v-model="selectedStatus"
            :options="availableOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            :placeholder="$t('placeholder.Choose Columns')"
            label="name"
            track-by="name"
            :preselect-first="false"
            @input="changeStatus()"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} {{ $t("automl.statusSelected") }}</span
              ></template
            >
          </multiselect>
        </div>
        <div class="dtp-col dtp-col-6 dtp-col-xl6 dtp-pa--0">
          <button
            @click="excludeFeatures()"
            :disabled="!isActive"
            class="eds-button eds-button.basic"
          >
            {{ $t("automl.excludefeatures") }}
          </button>
        </div>
        <div class="dtp-col dtp-col-2 dtp-text--right">
            <RefreshTable
            :loading="loading"
            @refreshTable="refreshTable"
          />
        </div>
      </div>
      <!--ROW -->
    </div>
    <div class="dtp-row">
      <div
        class="dtp-col dtp-col-12 dtp-freeze-header"
        v-loading="loading"
        :element-loading-text="$t('helper.loading_data')"
      >
        <vue-good-table
          @on-selected-rows-change="selectionChanged"
          :columns="featureColsProcessed"
          :rows="featureRow"
          :row-style-class="rowStyleClassFn"
          :select-options="{
            enabled: true,
            disableSelectInfo: true,
            selectOnCheckboxOnly: true,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <span>{{ $t("table.header." + props.column.label) }}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'importance'">
              <span>
                <div
                  v-if="
                    props.row.importance != 'HIGH' &&
                    props.row.importance != 'LOW' &&
                    props.row.importance != 'MEDIUM'
                  "
                  class="dtp-table-status-icon"
                ></div>
                <!-- </el-tooltip> -->
                <div v-else>
                  <div class="dtp-table-status-icon"></div>
                </div>
              </span>
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { COLUMNS } from "./Data/coldefs";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import Multiselect from "vue-multiselect";
import notify from "../../../mixins/AutoML/notify";
import pipeline from "../../../mixins/AutoML/pipeline";
import { featureStatus, featureStatusColors } from "./featureTypes";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from "../../../helper/asyncStatusChecker";
import RefreshTable from "../Helper/RefreshTable";
import { DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { NAVIGATION_GETTERS } from '../../../store/modules/AutoML/Navigation/types';
import cloneDeep from "clone-deep";

export default {
  mixins: [notify, pipeline],
  components: {
    VueGoodTable,
    Multiselect,
    RefreshTable
  },
  data() {
    return {
      problemTypes: {
        Classification: "classification",
        Regression: "regression",
        Clustering: "clustering",
      },
      featureCol: COLUMNS,
      featureRow: [],
      options: [
        {
          value: featureStatus.HIGH.key,
          name: this.$t("experiment.Select All Green"),
        },
        {
          value: featureStatus.MEDIUM.key,
          name: this.$t("experiment.Select All Yellow"),
        },
        {
          value: featureStatus.LOW.key,
          name: this.$t("experiment.Select All Red"),
        },
        {
          value: featureStatus.DISABLED.key,
          name: this.$t("experiment.Select All Gray"),
        },
      ],
      availableOptions: [],
      importanceArray: [
        featureStatus.HIGH.key,
        featureStatus.MEDIUM.key,
        featureStatus.LOW.key,
      ],
      selectedStatus: [],
      number: 0,
      featureRowDefault: [],
      callAsyncStatus: true
    };
  },
  computed: {
    ...mapGetters({
      datasetName: DATASET_GETTERS.GET_DATASET_NAME,
      importances: DATA_GETTERS.GET_IMPORTANCES,
      loading: GENERAL_GETTERS.GET_LOADING,
      featureImportanceOperationId: NAVIGATION_GETTERS.GET_FEATURE_IMPORTANCE_OPERATION_ID,
    }),

    isCorrelationAvailable() {
      return this.getProblemType === this.problemTypes.Regression;
    },
    featureColsProcessed() {
      return this.isCorrelationAvailable
        ? this.featureCol
        : this.featureCol.filter((x) => x.field !== "correlation");
    },
    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
    isActive() {
      return this.featureRow.filter((x) => x.vgtSelected).length > 0;
    },
  },
  watch: {
    importances: {
      deep: true,
      handler: function () {
        this.enhanceList();
      },
    },
  },
  async mounted() {
    this.requestComp = "feature_selection";

    await this.postFeatureImportance({
      requestComp: this.requestComp,
      experiment_id: this.experimentId,
    });

    await this.init(this.requestComp);
  },
  methods: {
    ...mapActions({
      featureImportancesGet: DATA_ACTIONS.FEATURE_IMPORTANCES_GET,
      getOperationStatus: DATA_ACTIONS.GET_OPERATION_STATUS,
      deleteColumns: DATA_ACTIONS.DELETE_COLUMNS,
      postFeatureImportance: DATA_ACTIONS.FEATURE_IMPORTANCES_POST,
    }),
    ...mapMutations({
      resetImportances: DATA_MUTATIONS.RESET_IMPORTANCES,
      setGrayFields: DATA_MUTATIONS.SET_GRAY_FIELDS,
    }),
    async refreshTable() {
      await refreshOperationStatus(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        }, 
        () => this.getImportances(), 
        { callOperationStatus: false }
      );
    },
    async init() {
      this.setGrayFields(true);
      this.resetImportances();
      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.featureImportances
        },
        () => {
          this.getImportances();
        },
        { callReason: CALL_REASONS.REFRESH, callOperationStatus: false, callAsyncStatus: this.callAsyncStatus }
      )
    },
    enhanceList() {
      this.setGrayFields(true);
      this.featureRow = [];

      if (this.importances && this.importances.length) {
        this.featureRow = this.importances.map((imp, index) => ({
          id: index,
          index,
          importance: !this.importanceArray.includes(imp.importance)
            ? featureStatus.DISABLED.key
            : imp.importance,
          column_name: imp.column_name,
          correlation: imp.correlation,
          variance: imp.variance,
          missing: imp.missing,
          vgtSelected: false,
        }));
      }

      if (this.featureRow.length > 0) {
        let grayFields =
          this.featureRow.filter(
            (item) => item.importance == featureStatus.DISABLED.key
          ).length > 0;

        this.setGrayFields(grayFields);
      }

      let importances = [
        ...new Set(this.featureRow.map((item) => item.importance)),
      ];
      this.availableOptions = this.options.filter((item) =>
        importances.includes(item.value)
      );

      this.featureRowDefault = cloneDeep(this.featureRow);
    },
    async checkOperationStatus() {
      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.featureImportances
        },
        () => {
          this.getImportances();
        },
        { callOperationStatus: false, callAsyncStatus: this.callAsyncStatus }
      )
    },
    
    async getImportances() {
      await this.featureImportancesGet({
        experiment_id: this.experimentId,
        requestComp: this.requestComp,
      });
    },
    changeStatus() {
      this.featureRow.forEach((item) => (item.vgtSelected = false));
      this.selectedStatus.forEach((item) => {
        this.featureRow.forEach((elem) => {
          if (elem.importance == item.value) elem.vgtSelected = true;
        });
      });
    },

    async excludeFeatures() {
      let data = {
        names: this.featureRow
          .filter((item) => item.vgtSelected)
          .map((key) => key.column_name)
          .join(),
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        pipeline_step: this.requestComp,
      };

      await this.deleteColumns(data);

      await this.postFeatureImportance({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
      });

      await this.checkOperationStatus();

      this.selectedStatus = null;

      this.init();
    },
    selectionChanged({ selectedRows }) {
      this.featureRow = cloneDeep(this.featureRowDefault);
      
      selectedRows.forEach((sr) => {
        const foundIndex = this.featureRow.findIndex(
          (or) => or.index === sr.index
        );
        this.$set(this.featureRow, foundIndex, { ...sr });
      });
    },
    rowStyleClassFn(row) {
      return featureStatusColors[row.importance].color;
    },
  },
  destroyed() {
    this.callAsyncStatus = false;
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
::v-deep .vgt-table thead tr .vgt-checkbox-col {
  background: #b2c4dc !important;
}
</style>
