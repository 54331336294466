export const featureStatus = {
    HIGH: {
        key: "HIGH",
    },
    MEDIUM: {
        key: "MEDIUM",
    },
    LOW: {
        key: "LOW",
    },
    DISABLED: {
        key: "DISABLED",
    }
}

export const featureStatusColors = {
    [featureStatus.HIGH.key]: {
        color: "successColor"
    },
    [featureStatus.MEDIUM.key]: {
        color: "warningColor"
    },
    [featureStatus.LOW.key]: {
        color: "dangerColor"
    },
    [featureStatus.DISABLED.key]: {
        color: "infoColor"
    }
}
