export const domain = "AUTOML/DATA";

export const DATA_ACTIONS = {
  FETCH_EXPLORATIONS: `${domain}/fetchExplorations`,
  FETCH_DATA: `${domain}/fetchData`,
  RENAME_COLUMN: `${domain}/renameColumn`,
  CONVERT_API: `${domain}/convertApi`,
  DELETE_COLUMNS: `${domain}/deleteColumns`,
  MISSING_VALUE: `${domain}/missingValue`,
  ENCODING: `${domain}/encoding`,
  SCALING: `${domain}/scaling`,
  REMOVE_NA_ROWS: `${domain}/removeNaRows`,
  FILTER_DATA: `${domain}/filterData`,
  POST_FEATURE_GENERATION: `${domain}/postFeatureGeneration`,
  UPDATE_FEATURE_GENERATION: `${domain}/updateFeatureGeneration`,
  DATA_VERSIONING: `${domain}/dataVersioning`,
  FEATURE_IMPORTANCES_POST: `${domain}/featureImportancesPost`,
  FEATURE_IMPORTANCES_GET: `${domain}/featureImportancesGet`,
  DATA_SORT: `${domain}/dataSort`,
  GET_STATUS: `${domain}/getStatus`,
  GET_OPERATION_STATUS: `${domain}/getOperationStatus`,
  INSPECT_IMBALANCE: `${domain}/imbalanceInspection`,
  BALANCE_CONFIGURATION: `${domain}/balanceConfiguration`,
  PUT_DATA_BINNING: `${domain}/putDataBinning`,
  GET_OPERATION_STATUS_BY_ID: `${domain}/getOperationStatusById`,
  PREVIOUS_PIPELINE_STEP: `${domain}/previousPipelineStep`,
  FETCH_APPLY_CHANGES:`${domain}/fetchApplyChanges`,
};

export const DATA_MUTATIONS = {
  SET_EXPLORATIONS: `${domain}/setExplorations`,
  SET_DATA: `${domain}/setData`,
  SET_IMPORTANCES: `${domain}/setImportances`,
  SET_STATUS: `${domain}/setStatus`,
  SET_OPERATION_STATUS: `${domain}/setOperationStatus`,
  INSPECT_IMBALANCE: `${domain}/imbalanceInspection`,
  RESET_IMBALANCE: `${domain}/resetImbalance`,
  BALANCE_CONFIGURATION_RESULT: `${domain}/balanceConfigurationResult`,
  RESET_EXPLORATIONS: `${domain}/resetExplorations`,
  RESET_IMPORTANCES: `${domain}/resetImportances`,
  SET_PREVIOUS_PIPELINE_STEP: `${domain}/setPreviousPipelineStep`,
  SET_APPLY_CHANGES: `${domain}/setApplyChanges`,
  SET_GRAY_FIELDS: `${domain}/setGrayFields`,
  SET_SELECTED_MODELS: `${domain}/setSelectedModels`,
  SET_PROGRESS: `${domain}/setProgress`,
};

export const DATA_GETTERS = {
  GET_EXPLORATIONS: `${domain}/getExplorations`,
  GET_DATA: `${domain}/getData`,
  GET_IMPORTANCES: `${domain}/getImportances`,
  GET_STATUS: `${domain}/getStatus`,
  GET_BALANCE_CONFIGURATION_RESULT: `${domain}getBalanceConfigurationResult`,
  GET_IMBALANCE_INSPECTION: `${domain}/getImbalanceInspection`,
  GET_RESET_IMBALANCE_COUNT: `${domain}/getResetImbalanceCount`,
  GET_APPLY_CHANGES: `${domain}/getApplyChanges`,
  GET_GRAY_FIELDS: `${domain}/getGrayFields`,
  GET_SELECTED_MODELS: `${domain}/getSelectedModels`,
  GET_PROGRESS: `${domain}/getProgress`,
};
