<template>
  <div class="dtp-row" style="width: 100%">
    <div v-if="tableData" class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1">
      <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column
          prop="group"
          :label="$t('table.header.Group')"
          width="150"
          column-key="group"
        >
        </el-table-column>
        <el-table-column :label="$t('table.header.Model Development Sample')">
          <el-table-column
            :label="$t('table.header.Samples')"
            width="150"
            prop="samples"
          >
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Scores')"
            prop="score"
            width="150"
          >
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Average Score')"
            prop="model_deployment_sample_average_prediction"
            column-key="model_deployment_sample_average_prediction"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('table.header.New Sample')">
          <el-table-column :label="$t('table.header.Observed Bad Rate')"
            ><el-table-column
              prop="new_sample_bad_rate"
              label="%"
              column-key="new_sample_bad_rate"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('table.header.Observed Bads')"
            ><el-table-column prop="new_sample_bads" label="#">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('table.header.Observed Goods')"
            ><el-table-column prop="new_sample_goods" label="#">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('table.header.Total')"
            ><el-table-column prop="new_sample_total" label="#">
            </el-table-column>
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('table.header.Binomial Test')">
          <el-table-column :label="$t('table.header.95% Confidence Level')"
            ><el-table-column
              prop="binomial_test_95_critical_value"
              :label="$t('table.header.Critical value of')"
            >
            </el-table-column
            ><el-table-column
              prop="binomial_test_95_binomial_test"
              :label="$t('table.header.Binomial Test')"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    $t(
                      "modelGovernance." +
                        scope.row.binomial_test_95_binomial_test
                    )
                  }}
                </span>
              </template>
            </el-table-column> </el-table-column
          ><el-table-column label="99% Confidence Level"
            ><el-table-column
              prop="binomial_test_99_critical_value"
              :label="$t('table.header.Critical value of')"
            >
            </el-table-column
            ><el-table-column
              prop="binomial_test_99_binomial_test"
              :label="$t('table.header.Binomial Test')"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    $t(
                      "modelGovernance." +
                        scope.row.binomial_test_99_binomial_test
                    )
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <!--<div v-else class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1 centerAll">
      <span
        style="
          color: #333333;
          font-size: 26px;
          font-weight: 500;
          display: block;
        "
        >Binomial test Failed</span
      >
    </div>
    <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1 centerAll">
      <span style="color: #4c4c4d; font-size: 22px; display: block"
        >Log info for failing test or try again.</span
      >
    </div>
    <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--1 centerAll">
      <button
        @click="binomialTestTryAgain()"
        class="eds-button eds-button.basic"
      >
        Try Again
      </button>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    binomialTestData: {
      type: Object,
    },
  },
  computed: {
    tableData() {
      return (
        this.binomialTestData?.score_index?.map((n, index) => {
          return {
            group: index,
            score: this.binomialTestData?.min_max_rate_list?.[index],
            samples: this.binomialTestData?.n_samples?.[index],
            model_deployment_sample_average_prediction:
              this.binomialTestData?.estimated_bad_rate?.[index],
            new_sample_bad_rate:
              this.binomialTestData?.observed_bad_rate?.[index],
            new_sample_bads: this.binomialTestData?.observed_bad_count?.[index],
            new_sample_goods:
              this.binomialTestData?.observed_good_count?.[index],
            new_sample_total: this.binomialTestData?.observed_totals?.[index],
            binomial_test_95_critical_value:
              this.binomialTestData?.critical_values_95?.[index],
            binomial_test_95_binomial_test:
              this.binomialTestData?.test_results_95?.[index],
            binomial_test_99_critical_value:
              this.binomialTestData?.critical_values_99?.[index],
            binomial_test_99_binomial_test:
              this.binomialTestData?.test_results_99?.[index],
          };
        }) ?? null
      );
    },
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (column.columnKey === "group") {
          sums[index] = this.$t("table.header.Total");
        } else if (
          column.columnKey === "model_deployment_sample_average_prediction"
        ) {
          sums[index] = this.binomialTestData?.avg_estimated_bad_rate;
        } else if (column.columnKey === "new_sample_bad_rate") {
          sums[index] = this.binomialTestData?.avg_observed_bad_rate;
        } else {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped>
.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep thead tr th {
  background-color: #b2c4dc !important;
  border: 1px solid #dcdfe6 !important;
}

::v-deep thead tr th .cell {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
}
</style>
