<template>
  <div>
    <router-link :to="{ name: 'CreateProject' }">
      <button @click="addClassName()" class="dtp-autoMl-button">
        {{ $t("autoMlWelcome.create_project") }}
      </button></router-link
    >
    <router-link :to="{ name: 'Projects' }">
      <button @click="addClassName()" class="dtp-autoMl-button">
        {{ $t("autoMlWelcome.open_existing_project") }}
      </button>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    addClassName() {
      const el = document.body;
      el.classList.add("elementMsgAlert");
    },
  },
};
</script>
<style scoped>
.dtp-autoMl-button {
  position: relative;
  padding: 10px 40px;
  margin: 0 20px;
  border: none;
  border-radius: 3px;
  background-color: #1d4f91;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
}
.dtp-autoMl-button:hover {
  background-color: #4a71a6;
}
</style>
