<template>
  <div>
    <div class="dtp-autoMl-welcome">
      <AddProject
        @createProject="createProject"
        @changeName="setProjectName"
        @changePortfolio="setPortfolio"
        @changeUsecase="setUsecase"
        @changeDesc="setDescription"
        :projectName="projectName"
        :description="description"
      />
    </div>
  </div>
</template>
<script>
import AddProject from "./Components/AddProject.vue";
import { mapActions, mapGetters } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import preferences from "../../../mixins/AutoML/preferences";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';

export default {
  mixins: [notify, preferences],
  components: {
    AddProject,
  },
  mounted() {
    this.requestComp = "createProject";
  },
  data() {
    return {
      projectName: "",
      portfolio: "",
      usecase: "",
      description: "",
    };
  },

  watch: {
    selectedProjectId: {
      deep: true,
      handler(val) {
        if (val !== 0) this.redirect(val);
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedProjectId: PROJECT_GETTERS.GET_SELECTED_PROJECT_ID,
    }),
  },
  methods: {
    ...mapActions({
      createProjects: PROJECT_ACTIONS.CREATE_PROJECT,
    }),
    setProjectName(value) {
      this.projectName = value;
    },
    setPortfolio(value) {
      this.portfolio = value;
    },
    setUsecase(value) {
      this.usecase = value;
    },
    setDescription(value) {
      this.description = value;
    },
    redirect(id) {
      this.$router.push({
        name: "ProjectDetails",
        query: {
          selectedProjectId: id,
        },
      });
    },
    createProject() {
      if (this.projectName == "") {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Project name field can not be empty"),
          type: "warning",
        });
      } else {
        const objProject = {
          requestComp: this.requestComp,
          data: {
            project_name: this.projectName,
            description: this.description,
          },
        };

        if (this.portfolio) {
          objProject.data.portfolio_id = this.portfolio;
        }

        if (this.usecase) {
          objProject.data.use_case_id = this.usecase;
        }

        this.createProjects(objProject);
      }
    },
  },
};
</script>
<style>
.dtp-create-popup {
  position: relative;
  width: 436px;
  height: auto;
  margin: 0;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  color: #515f67;
}
.dtp-create-popup-absolute {
  position: absolute;
  left: 40px;
  top: 0;
  z-index: 10;
}
.dtp-create-popup-title {
  position: relative;
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  background-color: #f5f7f7;
  border-bottom: 1px solid #d6d6d6;
}
.dtp-create-popup-content {
  position: relative;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 15px;
  font-size: 12px;
  color: #515f67;
}
.dtp-create-popup-content > div > div > p {
  font-size: 12px;
  line-height: 20px;
  color: #515f67;
}
.el-message-box .el-button {
  border-radius: 3px !important;
}
</style>
