<template>
  <el-dialog :visible.sync="isOpen" :before-close="handleClose">
    <div class="dtp-calculate-popup" v-loading="loading" :element-loading-text="$t('helper.loading_data')">
        <div class="dtp-row calculatePopupContainer">
          <div
            class="dtp-col dtp-col-3 dtp-pa--0 dtp-mb--0 borderRightCalculateBox"
          >
            <div class="dtp-calculate-title">{{ $t("dataCalculation.Functions") }}</div>
            <div
              class="
                dtp-calculate-content dtp-scrollbar
                calculatePopupFunctionsAndColumnsContent
              "
            >
              <div id="switcherBinning">
                <div class="eds-field_#label dtp-fcolor-0C77FF">
                  {{ $t("dataCalculation.Binning Methods") }}
                </div>
                <div :class="['flexCenter', isColumnEditMode ? 'dtp-cursor-not' : 'dtp-cursor-pointer']">
                  <span class="boldSpan">{{ $t("dataCalculation.Adaptive") }}</span>
                  <div style="margin-left: auto">
                    <label :class="['switch', isColumnEditMode ? 'dtp-cursor-not' : 'dtp-cursor-pointer']">
                      <input
                        id="adaptiveCheck"
                        type="checkbox"
                        :disabled="isColumnEditMode"
                        @change="
                          (e) =>
                            selectBinningMethod(
                              binnings.adaptive,
                              e.target.checked
                            )
                        "
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
                <div :class="['flexCenter', isColumnEditMode ? 'dtp-cursor-not' : 'dtp-cursor-pointer']">
                  <span class="boldSpan">{{ $t("dataCalculation.Fixed-width") }}</span>
                  <div style="margin-left: auto">
                    <label :class="['switch', isColumnEditMode ? 'dtp-cursor-not' : 'dtp-cursor-pointer']">
                      <input
                        id="fixedWidthCheck"
                        type="checkbox"
                        :disabled="isColumnEditMode"
                        @change="
                          (e) =>
                            selectBinningMethod(
                              binnings.fixedWidth,
                              e.target.checked
                            )
                        "
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-for="oah in operatorsAndHeaders"
                :key="`op-${oah.header}`"
                class="dtp-calculate-box"
              >
                <div class="eds-field_#label dtp-fcolor-0C77FF">
                  {{ $t(`${oah.header}`) }}
                </div>

                <el-tag
                  v-for="(o, index) in oah.operators"
                  :key="`${oah.header}-o-${index}`"
                  size="mini"
                  effect="plain"
                  type="info"
                  style="margin: 2px"
                  >{{ o }}</el-tag
                >
              </div>
            </div>
          </div>
          <!--COL-->
          <div
            v-show="!isBinningFixedWidthSelected && !isBinningAdaptiveSelected"
            class="dtp-col dtp-col-3 dtp-mb--0 dtp-pa--0 borderRightCalculateBox"
          >
            <div class="dtp-calculate-title">{{ $t('table.columns') }}</div>
            <div
              class="
                dtp-calculate-content
                calculatePopupFunctionsAndColumnsContent
              "
            >
              <div class="dtp-calculate-box">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <input
                    type="text"
                    :placeholder="$t('commons.search')"
                    class="eds-input_#input"
                    v-model="search"
                  />
                </div>
              </div>
              <div class="dtp-calculate-box">
                <ul class="dtp-scrollbar" style="max-height: 590px">
                  <li
                    v-for="(column, index) in searchList"
                    :key="index"
                    :value="column.label"
                  >
                    {{ column.label }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="dtp-col dtp-col-6 dtp-pa--0 borderRightCalculateBox">
            <div class="dtp-calculate-title dtp-flex-space">
              <span>{{ $t("dataCalculation.Formula") }}</span>
            </div>
            <div class="dtp-row" style="max-height: 680px; overflow: auto">
              <div
                :class="['dtp-col', isBinningMethodSelected ? 'dtp-col-12 dtp-pa--0' : 'dtp-col-6 dtp-pa--0']"
              >
                <div class="dtp-calculate-content" style="border: none">
                  <div
                    v-show="
                      !isBinningFixedWidthSelected && !isBinningAdaptiveSelected
                    "
                    class="eds-field_#control eds-field_.eds-input eds-input"
                    style="width: 220px"
                  >
                    <input
                      type="text"
                      :placeholder="$t('table.Column Name')"
                      class="eds-input_#input"
                      v-model="$v.columnName.$model"
                      @change="$v.columnName.$touch"
                    />
                  </div>
                  <span
                    class="error"
                    v-if="$v.columnName.$dirty && !$v.columnName.required"
                  >
                    {{ $t("dataCalculation.Column name field is required") }}
                  </span>
                  <span
                    class="error"
                    v-if="$v.columnName.$dirty && columnNameNotExistError"
                  >
                    {{ $t("dataCalculation.Column name should be in the column list and numeric") }}
                  </span>

                  <div
                    v-show="
                      isBinningFixedWidthSelected || isBinningAdaptiveSelected
                    "
                    class="flexCenter dtp-mb--1 dtp-mt--1"
                  >
                    <span class="boldSpan">{{ $t("table.Column Name") }}</span>
                    <div
                      class="
                        eds-field_#control eds-field_.eds-input
                        eds-input
                        inputsWithLabel
                      "
                    >
                      <select
                        v-model="binningColumnName"
                        class="eds-input_#input eds-input_#input.dropdown"
                      >
                        <option value="" disabled>{{ $t("table.Select Column Name") }}</option>

                        <option
                          v-for="item in columns"
                          :key="item.field"
                          :value="item.field"
                        >
                          {{ item.field }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div v-show="isBinningAdaptiveSelected">
                    <div class="flexCenter dtp-mb--1 dtp-mt--1">
                      <span class="boldSpan">n_bins</span>
                      <div
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                          inputsWithLabel
                        "
                      >
                        <input
                          :value="binningAdaptiveForm.n_bins"
                          type="number"
                          onwheel="return false;"
                          :min="2"
                          placeholder="n_bins"
                          class="eds-input_#input"
                          @input="(e) => updateAdaptiveNBins(e.target.value)"
                        />
                      </div>
                    </div>
                    <div class="flexCenter dtp-mb--1 dtp-mt--1">
                      <span class="boldSpan">{{ $t("encode") }}</span>
                      <div
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                          inputsWithLabel
                        "
                      >
                        <select
                          v-model="binningAdaptiveForm.encode"
                          class="eds-input_#input eds-input_#input.dropdown"
                        >
                          <option
                            v-for="item in encodeOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="flexCenter dtp-mb--1 dtp-mt--1">
                      <span class="boldSpan">{{ $t("strategy") }}</span>
                      <div
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                          inputsWithLabel
                        "
                      >
                        <select
                          v-model="binningAdaptiveForm.strategy"
                          class="eds-input_#input eds-input_#input.dropdown"
                        >
                          <option
                            v-for="item in strategyOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div v-show="isBinningFixedWidthSelected">
                    <div class="flexCenter dtp-mb--1 dtp-mt--1">
                      <span class="boldSpan">n_bins</span>
                      <div
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                          inputsWithLabel
                        "
                      >
                        <input
                          :value="binningFixedForm.n_bins"
                          type="number"
                          onwheel="return false;"
                          :min="2"
                          placeholder="n_bins"
                          class="eds-input_#input"
                          @input="(e) => updateLabelsObject(e.target.value)"
                        />
                      </div>
                    </div>
                    <div
                      v-for="(label, index) in Object.keys(
                        binningFixedForm.labels
                      )"
                      :key="label"
                      class="flexCenter dtp-mb--1 dtp-mt--1 dtp-display-flex"
                      style="column-gap: 10px"
                    >
                      <div style="flex: 1">
                        <span v-if="index === 0" class="boldSpan">{{ $t("dataCalculation.Labels") }}</span>
                        <div
                          class="
                            eds-field_#control eds-field_.eds-input
                            eds-input
                            inputsWithLabel
                          "
                        >
                          <input
                            v-model="binningFixedForm.labels[label]"
                            type="text"
                            :placeholder="label"
                            class="eds-input_#input"
                          />
                        </div>
                      </div>
                      <div style="flex: 0.5">
                        <span v-if="index === 0" class="boldSpan">Min</span>
                        <div
                          v-if="index === 0"
                          class="
                            eds-field_#control eds-field_.eds-input
                            eds-input
                            inputsWithLabel
                          "
                        >
                          <input
                            :value="fixedWidthMin"
                            type="number"
                            onwheel="return false;"
                            :min="2"
                            placeholder="min"
                            class="eds-input_#input"
                            @input="(e) => (fixedWidthMin = e.target.value)"
                          />
                        </div>
                      </div>
                      <div style="flex: 0.5">
                        <span v-if="index === 0" class="boldSpan">{{ $t("Max") }}</span>
                        <div
                          class="
                            eds-field_#control eds-field_.eds-input
                            eds-input
                            inputsWithLabel
                          "
                        >
                          <input
                            :value="fixedWidthMaxArray[index]"
                            type="number"
                            onwheel="return false;"
                            :min="2"
                            placeholder="max"
                            class="eds-input_#input"
                            @input="
                              (e) => (fixedWidthMaxArray[index] = e.target.value)
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label class="eds-checkbox">
                        <span class="boldSpan dtp-mr--1">include_lowest</span>
                        <input
                          type="checkbox"
                          class="eds-checkbox_#input"
                          v-model="binningFixedForm.include_lowest"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="!isBinningMethodSelected"
                class="dtp-col dtp-col-6 dtp-pa--0 dtp-text--right"
              >
                <div class="dtp-calculate-content" style="border: none">
                  <button v-if="false" class="eds-button eds-button.basic mr-8">
                    {{ $t("dataCalculation.Validate") }}
                  </button>
                  <button
                    @click="clear()"
                    class="eds-button eds-button.basic ml-8 mr-0"
                  >
                    {{ $t("dataCalculation.Clear All") }}
                  </button>
                </div>
              </div>
              <div class="dtp-col dtp-col-12 dtp-pa--0">
                <div class="dtp-calculate-content" style="border: none">
                  <div
                    :style="isBinningMethodSelected ? 'display :none' : ''"
                    class="dtp-calculate-box"
                  >
                    <div class="dtp-calculate-in-card">
                      <code-mirror-formula
                        :clearCodeMirror="clearCodeMirror"
                        :operators="allOperations"
                        :columns="columns"
                        :formula.sync="formula"
                        @resetClearCodeMirror="clearCodeMirror = false"
                        @codeChange="(param) => (saveItem = param)"
                        @formulaParsed="(param) => (formulaParsed = param)"
                        @formulaOperationHintStr="
                          (params) => (formulaOperationHintStr = params)
                        "
                      ></code-mirror-formula>
                    </div>
                  </div>
                  <div
                    v-if="isBinningFixedWidthSelected"
                    class="dtp-calculate-box dtp-text--right"
                  >
                    <button
                      v-loading="previewFixedWidthLoading"
                      v-if="Object.keys(newCol).length < 1"
                      @click="previewFixedWidth()"
                      class="eds-button eds-button.basic ml-8"
                    >
                      {{ $t("dataCalculation.Preview") }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="dtp-col dtp-col-12 dtp-pa--0">
                <div class="dtp-calculate-content" style="border: none">
                  <span
                    style="white-space: pre-line"
                    v-for="(str, index) in formulaOperationHintStr"
                    :key="index"
                  >
                    {{ str }}
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="isBinningFixedWidthSelected || isBinningAdaptiveSelected"
            class="dtp-col dtp-col-3 dtp-mb--0 dtp-pa--0"
          >
            <div class="dtp-calculate-title">{{ $t("dataCalculation.Statistics") }}</div>
            <div
              class="
                dtp-calculate-content
                dtp-calculate-content-static
                calculatePopupFunctionsAndColumnsContent
              "
            >
              <el-carousel
                indicator-position="outside"
                :autoplay="false"
                class="dtp-carousel"
              >
                <el-carousel-item>
                  <h3>{{ $t("dataCalculation.Column Statistics") }}</h3>
                  <div
                    v-if="typeof binningStatisticResult === 'object'"
                    class="dtp-calculate-box"
                  >
                    <div
                      v-for="statistic in Object.keys(binningStatisticResult)"
                      :key="statistic"
                    >
                      <span class="boldSpan"
                        >{{ capitalizeFirstLetter(statistic) }}: &nbsp;</span
                      >
                      <span>{{ binningStatisticResult[statistic] }}</span>
                    </div>
                  </div>
                  <div v-else class="dtp-calculate-box">
                    {{ binningStatisticResult }}
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <h3>{{ $t("dataCalculation.Target Distribution Statistics") }}</h3>
                  <div
                    v-if="
                      previewFixedWidthStatistics &&
                      typeof previewFixedWidthStatistics === 'object' &&
                      !isBinningFixedClassification
                    "
                    class="dtp-calculate-box"
                  >
                    <div
                      v-for="(statistic, index) in previewFixedWidthStatistics"
                      :key="index"
                      class="dtp-mb--1"
                    >
                      <div v-for="s in Object.keys(statistic)" :key="s">
                        <span class="boldSpan">Label {{ s }}: &nbsp;</span
                        ><span
                          v-if="typeof statistic[s] === 'string'"
                          class="boldSpan"
                          >{{ statistic[s] }}</span
                        >

                        <div
                          v-else
                          class="dtp-ml--1"
                          v-for="itemInS in Object.keys(statistic[s])"
                          :key="itemInS"
                        >
                          <span
                            v-if="
                              !fixedWidthStatisticsMoreValuesOpen &&
                              capitalizeFirstLetter(itemInS) !== 'Mean'
                            "
                          ></span>
                          <div v-else>
                            <span class="boldSpan"
                              >{{ capitalizeFirstLetter(itemInS) }}: &nbsp;</span
                            >
                            <span>{{ statistic[s][itemInS] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <a
                      @click="
                        fixedWidthStatisticsMoreValuesOpen =
                          !fixedWidthStatisticsMoreValuesOpen
                      "
                      style="color: #3a93ff; cursor: pointer; float: right"
                      >{{
                        !fixedWidthStatisticsMoreValuesOpen
                          ? "More Values >"
                          : "Less Values >"
                      }}</a
                    >
                  </div>
                  <div
                    v-if="
                      previewFixedWidthStatistics &&
                      typeof previewFixedWidthStatistics === 'object' &&
                      isBinningFixedClassification
                    "
                    class="dtp-calculate-box"
                  >
                    <div
                      v-for="(statistic, index) in previewFixedWidthStatistics"
                      :key="index"
                      class="dtp-mb--1"
                    >
                      <div v-for="s in Object.keys(statistic)" :key="s">
                        <span class="boldSpan dtp-display-block"
                          >{{ $t("dataCalculation.Label") }} {{ s }}: &nbsp;</span
                        >
                        <span class="boldSpan dtp-ml--1"
                          >{{ $t("dataCalculation.Label Count") }}: &nbsp;</span
                        >
                        <span>{{ statistic[s].count }}</span
                        ><span class="boldSpan dtp-display-block dtp-ml--1"
                          >{{ $t("dataCalculation.Ratios") }}: &nbsp;</span
                        >
                        <div
                          class="dtp-ml--2"
                          v-for="itemInS in Object.keys(statistic[s].params)"
                          :key="itemInS"
                        >
                          <div>
                            <span class="boldSpan"
                              >{{ capitalizeFirstLetter(itemInS) }}: &nbsp;</span
                            >
                            <span>{{ statistic[s].params[itemInS] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !(
                        previewFixedWidthStatistics &&
                        typeof previewFixedWidthStatistics === 'object'
                      )
                    "
                    class="dtp-calculate-box"
                  >
                    {{ $t("Please select Labels - Min - Max values and click 'Preview' to see target distribution") }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>

        <div class="dtp-calculate-popup-footer-actions dtp-pb--1">
          <div class="dtp-calculate-box dtp-text--right dtp-pr--2">
            <button
              @click="handleClose"
              class="eds-button eds-button.basic mr-8"
            >
              {{ $t("commons.cancel") }}
            </button>

            <button
              v-if="Object.keys(newCol).length < 1"
              @click="save()"
              class="eds-button eds-button.basic ml-8"
            >
              {{ $t("commons.save") }}
            </button>
            <button
              v-else-if="Object.keys(newCol).length > 0"
              @click="update()"
              class="eds-button eds-button.basic ml-8"
            >
              {{ $t("commons.update") }}
            </button>
          </div>
        </div>
    </div>
  </el-dialog>
</template>
<script>
import CodeMirrorFormula from "./CodeMirrorFormula";
import { required } from "vuelidate/lib/validators";
import { OperationType, Operators } from "./formulaParser";
import { mapActions, mapGetters } from "vuex";
import { previewFixedWidthField } from "../../../api/AutoMlServices/data";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { GENERAL_GETTERS } from '../../../store/modules/AutoML/General/types';

export default {
  components: {
    CodeMirrorFormula,
  },
  props: {
    rows: {
      type: Array,
    },
    columns: {
      type: Array,
    },
    newCol: {
      type: Object,
    },
    explorations: {
      type: Array,
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  validations: {
    columnName: {
      required,
    },
  },
  data() {
    return {
      fixedWidthStatisticsMoreValuesOpen: false,
      fixedWidthMin: 0,
      fixedWidthMaxArray: [0, 0],
      formulaParsed: {},
      clearCodeMirror: false,
      formula: this.newCol.formula_text || "",
      search: "",
      operator: null,
      colons: [],
      columnName: this.newCol.generated_column_name,
      colorInd: 0,
      formulaOperationHintStr: [],
      binningColumnName: "",
      isBinningAdaptiveSelected: false,
      isBinningFixedWidthSelected: false,
      binnings: {
        adaptive: "adaptive",
        fixedWidth: "fixedWidth",
      },
      encodeOptions: ["onehot", "onehot-dense", "ordinal"],
      strategyOptions: ["uniform", "quantile", "kmeans"],
      binningAdaptiveFormDefaults: {
        encode: "onehot",
        strategy: "quantile",
        n_bins: 2,
        method: "adaptive",
      },
      binningFixedFormDefaults: {
        include_lowest: false,
        method: "fixed",
        n_bins: 2,
        labels: {
          label1: "",
          label2: "",
        },
      },
      binningAdaptiveForm: {
        encode: "onehot",
        strategy: "quantile",
        n_bins: 2,
        method: "adaptive",
      },
      binningFixedForm: {
        include_lowest: false,
        method: "fixed",
        n_bins: 2,
        labels: {
          label1: "",
          label2: "",
        },
      },
      columnNameNotExistError: false,
      previewFixedWidthLoading: false,
      previewFixedWidthStatistics: null,
    };
  },
  watch: {
    isBinningMethodSelected: {
      handler(val) {
        this.columnName = this.newCol.generated_column_name;
        this.binningColumnName = "";
        this.$v.$reset();
        if (!val) this.columnNameNotExistError = false;
      },
    },
    binningFixedFormNBins: {
      handler(val) {
        if (val < this.fixedWidthMaxArray.length) {
          this.fixedWidthMaxArray.length = val;
        } else {
          for (let i = this.fixedWidthMaxArray.length; i < val; i++) {
            this.fixedWidthMaxArray[i] = 0;
          }
        }
      },
    },
  },
  mounted() {
    this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
  },
  computed: {
    ...mapGetters({
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      loading: GENERAL_GETTERS.GET_LOADING
    }),
    isBinningFixedClassification() {
      const firstItem = this.previewFixedWidthStatistics?.[0];

      if (typeof firstItem === "object") {
        const keys = Object.keys(firstItem);

        if (keys?.length) {
          if (typeof firstItem?.[keys?.[0]]?.params === "object") return true;
        }
      }

      return false;
    },
    isColumnEditMode() {
      return this.newCol?.formula_text?.length > 0;
    },
    binningStatisticResult() {
      return (
        this.explorations?.find((ex) => ex.name === this.binningColumnName)
          ?.statistics ?? this.$t("Please select column to show statistics.")
      );
    },
    binningFixedFormNBins() {
      return this.binningFixedForm.n_bins;
    },
    isColumnListExistInList() {
      return this.columns.filter((c) => c.field === this.columnName).length > 0;
    },
    isBinningMethodSelected() {
      return this.isBinningAdaptiveSelected || this.isBinningFixedWidthSelected;
    },
    allOperations() {
      return Operators.map((o) => o.label);
    },
    operatorsAndHeaders() {
      return [
        {
          header: "Mathematical Operations",
          operators: this.getOperatorLabels(OperationType.MathOperation),
          addParanthesis: true,
        },
        {
          header: "Logical(Bitwise) Operations",
          operators: [
            ...this.getOperatorLabels(OperationType.LogicalOperation),
            ...this.getOperatorLabels(OperationType.LogicalExpression),
          ],
          addParanthesis: true,
        },
        {
          header: "String Operations",
          operators: this.getOperatorLabels(OperationType.StringOperation),
          addParanthesis: true,
        },
        {
          header: "Date Operations",
          operators: this.getOperatorLabels(OperationType.DatetimeOperation),
          addParanthesis: true,
        },
      ];
    },
    searchList() {
      if (this.search) {
        return this.columns.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.label.toLowerCase().includes(v));
        });
      } else {
        return this.columns;
      }
    },
    experimentId() {
      return this.$route.query.experimentId;
    },
  },
  methods: {
    ...mapActions({
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
    }),
    handleClose() {
      this.$emit("onClose");
    },
    async previewFixedWidth() {
      this.previewFixedWidthLoading = true;
      const result = await previewFixedWidthField({
        payload: this.getFixedWidthBinningPayload().binning[0],
        experimentId: this.experimentId,
      });
      this.previewFixedWidthStatistics = result?.data?.data ?? [];
      this.previewFixedWidthLoading = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getOperatorLabels(operationType) {
      return Operators.filter((i) => i.type === operationType).map(
        (o) => o.label
      );
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    updateAdaptiveNBins(newLength) {
      if (this.isNumeric(newLength)) {
        newLength = Number(newLength);
        this.$set(this.binningAdaptiveForm, "n_bins", newLength);
      } else {
        this.$set(this.binningAdaptiveForm, "n_bins", 2);
      }
    },
    updateLabelsObject(newLength) {
      const labelsLength = Object.keys(this.binningFixedForm.labels).length;

      if (this.isNumeric(newLength)) {
        newLength = Number(newLength);
        this.$set(this.binningFixedForm, "n_bins", newLength);

        if (newLength < labelsLength) {
          for (let i = newLength; i < labelsLength; i++) {
            delete this.binningFixedForm.labels["label" + (i + 1)];
          }
        } else {
          for (let i = labelsLength; i < newLength; i++) {
            this.binningFixedForm.labels["label" + (i + 1)] = "";
          }
        }
      } else {
        this.$set(this.binningFixedForm, "n_bins", 2);
      }
    },
    selectBinningMethod(type, checked) {
      const adaptiveCheck = document.getElementById("adaptiveCheck");
      const fixedWidthCheck = document.getElementById("fixedWidthCheck");

      if (type === this.binnings.adaptive) {
        this.isBinningAdaptiveSelected = checked;
        this.binningAdaptiveForm = JSON.parse(
          JSON.stringify(this.binningAdaptiveFormDefaults)
        );

        if (checked) {
          this.isBinningFixedWidthSelected = false;
          fixedWidthCheck.checked = false;
          this.binningFixedForm = JSON.parse(
            JSON.stringify(this.binningFixedFormDefaults)
          );
        }
      } else if (type === this.binnings.fixedWidth) {
        this.isBinningFixedWidthSelected = checked;
        this.binningFixedForm = JSON.parse(
          JSON.stringify(this.binningFixedFormDefaults)
        );
        if (checked) {
          this.isBinningAdaptiveSelected = false;
          adaptiveCheck.checked = false;
          this.binningAdaptiveForm = JSON.parse(
            JSON.stringify(this.binningAdaptiveFormDefaults)
          );
        }
      }
    },
    clear() {
      this.clearCodeMirror = true;
    },
    save() {
      if (!this.isBinningMethodSelected) {
        this.$v.columnName.$touch();
        this.$emit("saveFormula", {
          generated_column_name: this.columnName,
          formula_text: this.formula,
          formula: this.formulaParsed,
        });
      } else {
        const binningData = this.isBinningAdaptiveSelected
          ? this.binningAdaptiveForm
          : this.binningFixedForm;

        if (this.binningColumnName) {
          if (this.isBinningAdaptiveSelected) {
            this.$emit("saveBinning", {
              binning: [
                {
                  ...binningData,
                  column_name: this.binningColumnName,
                },
              ],
            });
          } else {
            const binsRangeNumber = [Number(this.fixedWidthMin)];

            for (let i = 0; i < this.fixedWidthMaxArray.length; i++) {
              binsRangeNumber.push(Number(this.fixedWidthMaxArray[i]));
            }
            this.$emit("saveBinning", this.getFixedWidthBinningPayload());
          }
        }
      }
    },
    getFixedWidthBinningPayload() {
      const binsRangeNumber = [Number(this.fixedWidthMin)];

      for (let i = 0; i < this.fixedWidthMaxArray.length; i++) {
        binsRangeNumber.push(Number(this.fixedWidthMaxArray[i]));
      }
      return {
        binning: [
          {
            ...this.binningFixedForm,
            column_name: this.binningColumnName,
            bins_range: binsRangeNumber,
          },
        ],
      };
    },
    update() {
      if (!this.formulaParsed) {
        this.formulaParsed = { formula: this.newCol.formula };
      }
      this.$v.columnName.$touch();
      this.$emit("updateFormula", {
        generated_column_name: this.columnName,
        old_column_name: this.newCol.generated_column_name,
        old_formula: this.newCol.formula_text,
        formula_text: this.formula,
        formula: this.formulaParsed,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tag--plain.el-tag--info {
  font-size: 10px;
}
.dtp-calculate-popup {
  height: auto;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px #0000001d;
  z-index: 15;
}
.dtp-calculate-title {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 30px;
  background-color: #f5f7f7;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.dtp-calculate-content {
  position: relative;
  width: 100%;
  padding: 20px 30px 0 30px;
}
.dtp-calculate-content-static {
  padding: 0px;
}
.dtp-calculate-box {
  position: relative;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}
.dtp-caltulate-columns-ul {
  height: 267px;
}

.dtp-calculate-box ul li {
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.dtp-calculate-box ul li:hover {
  background-color: #f5f7f7;
}
.dtp-caltulate-function-ul {
  position: relative;
  width: 100%;
  border-left: 2px solid #707070;
  padding-left: 14px;
  margin-left: 20px;
}

.dtp-calculate-in-card {
  position: relative;
  width: 100%;
  height: 250px;
  padding: 14px 20px;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
}

.dtp-active-selected {
  color: #0c77ff;
}
.error {
  color: red;
  font-weight: 500;
  font-size: 12px;
}

.boldSpan {
  font-size: 12px;
  font-weight: bold;
}
#switcherBinning {
  margin-bottom: 20px;
  
  .flexCenter {
    display: flex;
    align-items: center;
  }

  .inputsWithLabel {
    width: 70%;
    margin-left: auto;
  }

  .switch input {
    display: none;
  }

  .switch {
    display: inline-block;
    width: 35px; /*=w*/
    height: 20px; /*=h*/
    margin: 5px 3px 5px 0;
    position: relative;
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 1px #9e9e9e, 0 0 2px #9e9e9e;
    border: 3px solid transparent;
    overflow: hidden;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #9e9e9e;
    border-radius: 30px;
    transform: translateX(-15px); /*translateX(-(w-h))*/
    transition: 0.4s;
  }

  input:checked + .slider:before {
    transform: translateX(15px); /*translateX(w-h)*/
    background-color: #436da9;
  }
}

.calculatePopupContainer {
  height: 730px !important;
}

.calculatePopupFunctionsAndColumnsContent {
  height: 685px !important;
}

.borderRightCalculateBox {
  border-right: 1px solid #d6d6d6;
  height: inherit;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}
.el-carousel__item {
  overflow-x: hidden;
  overflow-y: auto; 
}
.dtp-carousel {
  height: 100%;
  padding: 20px 0px 20px 20px;
}
::v-deep .el-carousel__container{
  height: 90%;
}
::v-deep .el-carousel__arrow--left {
    left: -15px;
}
::v-deep .el-carousel__arrow--right {
    right: 4px;
}
::v-deep .el-dialog__body,
::v-deep .el-dialog__header {
  padding: 0 !important;
}
::v-deep .el-dialog {
  min-width: 75%;
  max-width: 75%;
}
</style>

