import axios from 'axios';
import store from '../store';
import { GENERAL_ACTIONS, GENERAL_GETTERS } from '../store/modules/AutoML/General/types';

export class Service {
    constructor(endpoint) {
        let service = axios.create();
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        service.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${store.getters["Security/Get/Keycloak/Token"]}`;
            return config;
        });
        this.service = service;
        this.API_ENDPOINT = endpoint;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        return Promise.reject(error?.response)
    }

    handleApiError = (error, errorCallBack) => {
        const result = {
            status: error?.status,
            code: error?.data?.code,
            message: error?.data?.message || error?.data?.detail,
            errors: error?.data?.errors,
            parameters: error?.data?.parameters
        }
        errorCallBack(result)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    get(path, callback, errorCallBack) {
        let result = {};
        return this.service.get(this.API_ENDPOINT + path).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
                
                callback(result)
            }

        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    patch(path, payload, callback) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    post(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.post(this.API_ENDPOINT + path, data).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
                
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    getWithData(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.get(this.API_ENDPOINT + path, data).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    put(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.put(this.API_ENDPOINT + path, data).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data,
                    message: response.data.message
                }
                
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    delete(path, callback, errorCallBack) {
        let result = {};
        return this.service.delete(this.API_ENDPOINT + path).then(
            (response) => {
                
                result = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data,
                }
                
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    postFormData(path, data, callback, errorCallBack) {
        let result = {};
        const uploadHandler = {
            cancelToken: store.getters[GENERAL_GETTERS.GET_AXIOS_SOURCE]?.token, onUploadProgress: progressEvent => store.dispatch(GENERAL_ACTIONS.SET_FORMDATA_PROGRESS, (progressEvent.loaded / progressEvent.total).toFixed(2) * 100)
        };

        return this.service.post(this.API_ENDPOINT + path, data, uploadHandler).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
                callback(result)
            }
        ).catch(
            (error) => {
                if (error) {
                    this.handleApiError(error, errorCallBack)
                }
            }
        )
    }
}