export const domain = "AUTOML/MODELS";

export const MODELS_ACTIONS = {
  FETCH_MODELS: `${domain}/fetchModels`,
  FETCH_SINGLE_MODEL: `${domain}/fetchSingleModel`,
  FETCH_MODELS_BY_EXPERIMENT_ID: `${domain}/fetchModelsByExperimentId`,
  FETCH_MODELS_PLOTS: `${domain}/fetchModelPlots`,
  MAKE_PREDICTION: `${domain}/makePredicNewData`,
  FETCH_MODELS_RESULT: `${domain}/fetchModelResults`,
  DOWNLOAD_PREDICTION_RESULTS: `${domain}/downloadPredictionResults`,
  RUN_MODELS: `${domain}/runModels`,
  UPDATE_PROBLEM_CONFIGURATION: `${domain}/updateProblemConfiguration`,
  STOP_MODEL_TRAINING: `${domain}/stopModelTraining`,
  MODEL_DOWNLOAD: `${domain}/modelDownload`,
  PUT_MODEL_TUNING: `${domain}/putModelTuning`,
  FETCH_PREDICTIONS: `${domain}/fetchPredictions`,
  FETCH_MODELS_BY_MODEL_ID: `${domain}/fetchModelsByModelId`,
  UPDATE_DESCRIPTION_BY_MODEL_ID: `${domain}/updateModelDescription`,
  FETCH_MODEL_EQUATION: `${domain}/fetchModelEquation`,
  FETCH_MODELS_RESULT_MAIN: `${domain}/fetchModelResultsMain`,
  FETCH_MODEL_EXPLANATION_STATUS: `${domain}/FETCH_MODEL_EXPLANATION_STATUS`,
  FETCH_MODEL_EXPLANATION_SAMPLES: `${domain}/FETCH_MODEL_EXPLANATION_SAMPLES`,
  POST_MODEL_EXPLANATION_PLOT: `${domain}/POST_MODEL_EXPLANATION_PLOT`,
  FETCH_MODELS_PLOT_MAIN: `${domain}/fetchModelPlotsMain`,
  UPLOAD_AUDIT: `${domain}/UPLOAD_AUDIT`,
  FETCH_AUDIT: `${domain}/FETCH_AUDIT`,
  MAKE_MODEL_VALIDATIONS: `${domain}/MAKE_MODEL_VALIDATIONS`,
  FETCH_T_TEST_RESULT: `${domain}/FETCH_T_TEST_RESULT`,
  FETCH_BINOMIAL_TEST_RESULT: `${domain}/FETCH_BINOMIAL_TEST_RESULT`,
  FETCH_MODEL_VALIDATIONS: `${domain}/FETCH_MODEL_VALIDATIONS`,
  FETCH_CUSTOM_MODELS: `${domain}/FETCH_CUSTOM_MODELS`,
  DEFINE_CUSTOM_MODEL: `${domain}/DEFINE_CUSTOM_MODEL`,
  RETRY_CUSTOM_MODEL: `${domain}/RETRY_CUSTOM_MODEL`,
  DELETE_CUSTOM_MODEL: `${domain}/DELETE_CUSTOM_MODEL`,
  FETCH_MODEL_REQUEST_INFO: `${domain}/FETCH_MODEL_REQUEST_INFO`,
  RETRAIN_CUSTOM_MODEL: `${domain}/RETRAIN_CUSTOM_MODEL`,
  RETEST_CUSTOM_MODEL: `${domain}/RETEST_CUSTOM_MODEL`,
  FETCH_CHALLENGER_MODEL_RESULTS: `${domain}/FETCH_CHALLENGER_MODEL_RESULTS`,
  CREATE_CHALLENGER_MODEL: `${domain}/CREATE_CHALLENGER_MODEL`,
  FETCH_CHALLENGER_STATUS: `${domain}/FETCH_CHALLENGER_STATUS`,
  FETCH_LOG_EXPORT: `${domain}/fetchLogExport`,
  POST_LOG_EXPORT: `${domain}/POST_LOG_EXPORT`,
  DELETE_LOG_EXPORT: `${domain}/deleteLogExport`,
  GET_LOG_EXPORT_STATUS: `${domain}/getLogExportStatus`,
  GET_DOWNLOAD_AUDIT: `${domain}/getDownloadAudit`,
  GET_GENERIC_MODELS: `${domain}/getGenericModels`
};

export const MODELS_MUTATIONS = {
  SET_MODELS: `${domain}/setModels`,
  UPDATE_MODELS: `${domain}/updateModels`,
  SET_MODELS_RESULT: `${domain}/setModelResults`,
  SET_PREDICTION_LINK: `${domain}/setPredictionLink`,
  SET_MODEL_LINK: `${domain}/setModelLink`,
  RESET_MODEL_TUNING: `${domain}/resetModelTuning`,
  SET_PREDICTIONS: `${domain}/setPredictions`,
  SET_MODEL_EQUATION: `${domain}/setModelEquation`,
  SET_MODELS_RESULTS_MAIN: `${domain}/setModelResultsMain`,
  SET_MODEL_EXPLANATION_SAMPLES: `${domain}/SET_MODEL_EXPLANATION_SAMPLES`,
  SET_AUDIT: `${domain}/setAudit`,
  SET_T_TEST_RESULT: `${domain}/SET_T_TEST_RESULT`,
  SET_BINOMIAL_TEST_RESULT: `${domain}/SET_BINOMIAL_TEST_RESULT`,
  SET_MODEL_VALIDATIONS: `${domain}/SET_MODEL_VALIDATIONS`,
  SET_CUSTOM_MODELS: `${domain}/SET_CUSTOM_MODELS`,
  SET_MODEL_REQUEST_INFO: `${domain}/SET_MODEL_REQUEST_INFO`,
  SET_CHALLENGER_MODEL_RESULTS: `${domain}/SET_CHALLENGER_MODEL_RESULTS`,
  SET_CHALLENGER_EXPERIMENT_INFO: `${domain}/SET_CHALLENGER_EXPERIMENT_INFO`,
  SET_CHALLENGER_MODEL_STATUS: `${domain}/SET_CHALLENGER_MODEL_STATUS`,
  SET_LOG_EXPORTS: `${domain}/setLogExport`,
  SET_MODEL_TUNING: `${domain}/setModelTuning`,
  SET_PROBLEM_CONFIGURATION_PARAMS: `${domain}/setProblemConfigurationDefaultParams`,
  SET_PROBLEM_CONFIGURATION: `${domain}/setProblemConfiguration`,
  RESET_PROBLEM_CONFIGURATION: `${domain}/resetProblemConfiguration`,
  SET_MODELS_PLOTS: `${domain}/setModelPlots`,
  SET_MISSING_LIST_EMPTY: `${domain}/setMissingListEmpty`,
  SET_ENCODING_LIST_EMPTY: `${domain}/setEncodingListEmpty`,
  SET_BALANCE_CONFIGURATION: `${domain}/setBalanceConfiguration`,
  SET_MODEL_GOVERNANCE_FILTER: `${domain}/setModelGovernanceFilter`,
  SET_CUSTOM_MODELS_FILTER: `${domain}/SET_CUSTOM_MODELS_FILTER`,
};

export const MODELS_GETTERS = {
    GET_MODELS: `${domain}/getModels`,
    GET_MODEL_RESULTS: `${domain}/getModelResults`,
    GET_PREDICTION_LINK: `${domain}/getPredictionLink`,
    GET_MODEL_LINK: `${domain}/getModelLink`,
    GET_MODEL_TUNING: `${domain}/getModelTuning`,
    GET_PREDICTIONS: `${domain}/getPredictions`,
    GET_MODEL_EQUATION: `${domain}/getModelEquation`,
    GET_MODEL_RESULTS_MAIN: `${domain}/getModelResultsMain`,
    GET_MODEL_EXPLANATION_SAMPLES: `${domain}/GET_MODEL_EXPLANATION_SAMPLES`,
    GET_AUDIT: `${domain}/getAudit`,
    GET_T_TEST_RESULT: `${domain}/GET_T_TEST_RESULT`,
    GET_BINOMIAL_TEST_RESULT: `${domain}/GET_BINOMIAL_TEST_RESULT`,
    GET_MODEL_VALIDATIONS: `${domain}/GET_MODEL_VALIDATIONS`,
    GET_CUSTOM_MODELS: `${domain}/GET_CUSTOM_MODELS`,
    GET_MODEL_REQUEST_INFO: `${domain}/GET_MODEL_REQUEST_INFO`,
    GET_CHALLENGER_MODEL_RESULTS: `${domain}/GET_CHALLENGER_MODEL_RESULTS`,
    GET_CHALLENGER_EXPERIMENT_INFO: `${domain}/GET_CHALLENGER_EXPERIMENT_INFO`,
    GET_CHALLENGER_MODEL_STATUS: `${domain}/GET_CHALLENGER_MODEL_STATUS`,
    GET_LOG_EXPORTS:`${domain}/getLogExport`,
    GET_PROBLEM_CONFIGURATION_PARAMS: `${domain}/getProblemConfigurationParams`,
    GET_MODEL_PLOTS: `${domain}/getModelPlots`,
    GET_MISSING_LIST_EMPTY: `${domain}/getMissingListEmpty`,
    GET_ENCODING_LIST_EMPTY: `${domain}/getEncodingListEmpty`,
    GET_BALANCE_CONFIGURATION: `${domain}getBalanceConfiguration`,
    GET_MODEL_GOVERNANCE_FILTER: `${domain}/getModelGovernanceFilter`,
    GET_CUSTOM_MODELS_FILTER: `${domain}/GET_CUSTOM_MODELS_FILTER`,
};
