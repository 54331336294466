var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.meta.view == 'projectMenu')?_c('div',{staticClass:"dtp-navigation-menu"},[_c('div',{staticClass:"dtp-navigation-menu-btn-box"},[_c('button',{on:{"click":_vm.stopExperiment}},[_vm._v(" "+_vm._s(_vm.$t("automl.stopExperiment"))+" ")]),_c('div',{staticClass:"dtp-aml-info primary",on:{"click":function($event){_vm.info = !_vm.info}}},[_vm._v("i")]),(_vm.info)?_c('InfoPopup',{attrs:{"selectedExperiment":_vm.selectedExperiment,"InfoList":_vm.InfoList},on:{"closePopup":function($event){_vm.info = $event}}}):_vm._e()],1),_c('ul',{staticClass:"dtp-navigation-menu-list",class:{ 'dtp-navigation-none-desc': !_vm.value1 }},[_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.name == _vm.pages.DATA_PREVIEW,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 1,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.DATA_PREVIEW)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-Data.svg"),"alt":"DataPreview"}}),_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.$t("automl.dataPreperation")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 1,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == _vm.pages.DATA_PREVIEW),expression:"$route.name == pages.DATA_PREVIEW"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-s"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("navigation.Target column selection and problem type determination"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.dataConfigurationLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),_c('button',{attrs:{"disabled":_vm.navigationsList.length >= 1 || _vm.isDataPreparationParamsInvalid || _vm.routeHasOnGoingOperation(_vm.pages.DATA_PREVIEW)},on:{"click":function($event){return _vm.callUpdateProblemConfiguration()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.continueExploreData"))+" ")])]),_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.meta.navigationLink == _vm.pages.EXPLORE_DATA,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 2,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.EXPLORE_DATA)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-ExploreData.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("automl.exploreData")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 2,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == _vm.pages.EXPLORE_DATA),expression:"$route.name == pages.EXPLORE_DATA"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-s"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("navigation.Basic statistics and visualisations for high level exploration"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.exploreDataLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),_c('button',{attrs:{"disabled":_vm.navigationsList.length >= 2 || _vm.routeHasOnGoingOperation(_vm.pages.EXPLORE_DATA)},on:{"click":function($event){return _vm.continueExploreData()}}},[_vm._v(" "+_vm._s(_vm.lastMenu.route ? _vm.$t(`${_vm.getUrlName(_vm.lastMenu.route)}`) : _vm.$t("automl.continueFeatureEng"))+" ")])]),_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.meta.navigationLink == _vm.pages.FEATURE_ENGINEERING,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 3,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){_vm.toggleSubMenu = !_vm.toggleSubMenu}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-FeatureEngineering.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("automl.featureEngineering")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 3,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.$route.meta.navigationLink == _vm.pages.FEATURE_ENGINEERING ||
          _vm.toggleSubMenu
        ),expression:"\n          $route.meta.navigationLink == pages.FEATURE_ENGINEERING ||\n          toggleSubMenu\n        "}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-l"},_vm._l((_vm.FeatureEngineeringsubLink),function(item,index){return _c('div',{key:index},[_c('li',{staticClass:"dtp-cursor-pointer",class:{
              'dtp-navitaion-menu-sub-list-active': item.name == _vm.$route.name,
            },on:{"click":function($event){return _vm.navigate(item.name)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`${item.text}`)))]),_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.name == _vm.$route.name),expression:"item.name == $route.name"}]},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("navigation." + item.paragraf))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.createHelperLink(item.helperName),"target":"_blank"}},[_vm._v("more "),_c('i',{staticClass:"el-icon-right"})])]),(
                _vm.$route.name == _vm.pages.MISSING_LIST ||
                _vm.$route.name == _vm.pages.BASIC_TRANSFORMATION ||
                _vm.$route.name == _vm.pages.ENCODING_LIST
              )?_c('button',{attrs:{"id":"data_mis_enc","disabled":_vm.routeHasOnGoingOperation(_vm.$route.name)},on:{"click":function($event){return _vm.goForward(item.route)}}},[_vm._v(" "+_vm._s(_vm.$t("automl.continue"))+" "),_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e(),(_vm.$route.name == _vm.pages.DATA_DATACALCULATION)?_c('button',{attrs:{"id":"basic","disabled":_vm.routeHasOnGoingOperation(_vm.pages.DATA_DATACALCULATION)},on:{"click":function($event){return _vm.goMissingList(item.route)}}},[_vm._v(" "+_vm._s(_vm.$t("automl.continue"))+" "),_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e(),(_vm.$route.name == _vm.pages.SCALING)?_c('button',{attrs:{"id":"scaling","disabled":_vm.routeHasOnGoingOperation(_vm.pages.SCALING)},on:{"click":function($event){return _vm.goFeatureReduction()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.continue"))+" "),_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e()])])}),0),_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.name == _vm.pages.FEATURE_LIST,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 4,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.FEATURE_LIST)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-FeatureList.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("automl.featureListTitle")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 4,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == _vm.pages.FEATURE_LIST),expression:"$route.name == pages.FEATURE_LIST"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-s"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("navigation.Selection of the final list of model features and visualization of their importance"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.featureSelectionLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),_c('button',{attrs:{"disabled":_vm.navigationsList.length >= 4 || _vm.routeHasOnGoingOperation(_vm.pages.FEATURE_LIST)},on:{"click":function($event){return _vm.goToModel()}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.Continue Model"))+" ")])]),_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.name == _vm.pages.MODEL_SELECTION,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 4,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.MODEL_SELECTION)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-Model.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("automl.model")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 4,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.navigationLink == _vm.pages.MODEL_SELECTION),expression:"$route.meta.navigationLink == pages.MODEL_SELECTION"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-l"},[_c('router-link',{attrs:{"to":{ name: _vm.pages.MODEL_SELECTION, query: _vm.query }}},[_c('li',{class:{
              'dtp-navitaion-menu-sub-list-active':
                _vm.$route.name == _vm.pages.MODEL_SELECTION,
            }},[_c('span',[_vm._v(_vm._s(_vm.$t("automl.modelConfig")))]),_c('i',{staticClass:"el-icon-caret-bottom"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pages.MODEL_SELECTION == _vm.$route.name),expression:"pages.MODEL_SELECTION == $route.name"}]},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("automl.selectionText"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.modelConfigurationLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),_c('button',{attrs:{"disabled":_vm.selectedModels.length == 0},on:{"click":function($event){return _vm.runModels({
                requestComp: _vm.requestComp,
                experimentId: _vm.experimentId,
                data: _vm.selectedModels,
              })}}},[_vm._v(" "+_vm._s(_vm.$t("automl.run"))+" "),_c('i',{staticClass:"el-icon-caret-right"})])]),_c('router-link',{attrs:{"to":{ name: _vm.pages.MODEL_RUNNING, query: _vm.query }}},[_c('li',{class:{
              'dtp-navitaion-menu-sub-list-active':
                _vm.$route.name == _vm.pages.MODEL_RUNNING,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("automl.modelRunning")))]),_c('i',{staticClass:"el-icon-caret-bottom"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pages.MODEL_RUNNING == _vm.$route.name),expression:"pages.MODEL_RUNNING == $route.name"}]},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("automl.modelRuntimeVis"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.modelRunningLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),_c('router-link',{attrs:{"to":{ name: _vm.pages.MODEL_RESULTS, query: _vm.query }}},[_c('button',{attrs:{"id":"mod_res","disabled":_vm.getProgress < 100},on:{"click":function($event){return _vm.setNavigationCount(5)}}},[_vm._v(" "+_vm._s(_vm.$t("automl.continue"))+" "),_c('i',{staticClass:"el-icon-caret-right"})])])],1)],1),_c('li',{staticClass:"dtp-navigation-menu-link",class:{
          'dtp-navigation-menu-list-active':
            _vm.$route.name == _vm.pages.MODEL_RESULTS,
          'dtp-navigation-line-active': _vm.navigationsList.length >= 5,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.MODEL_RESULTS)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Layout-Model-Inter.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.$t("automl.modelInterpreter")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 5,
          }})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == _vm.pages.MODEL_RESULTS),expression:"$route.name == pages.MODEL_RESULTS"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-l"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("automl.visualizationText"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.modelResultsLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),(_vm.getProblemType != 'clustering')?_c('button',{on:{"click":function($event){return _vm.continueTuneModel()}}},[_vm._v(" "+_vm._s(_vm.$t("navigation." + _vm.modelbtnName))+" ")]):_vm._e(),(_vm.getProblemType == 'clustering')?_c('button',{on:{"click":function($event){return _vm.completeExperiment()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.finishExperiment"))+" ")]):_vm._e()]),(_vm.getProblemType != 'clustering')?_c('li',{staticClass:"dtp-navigation-menu-link dtp-navigation-menu-link-end",class:{
          'dtp-navigation-menu-list-active': _vm.$route.name == _vm.pages.TUNE_MODEL,
        }},[_c('div',{staticClass:"dtp-cursor-pointer",on:{"click":function($event){return _vm.navigate(_vm.pages.TUNE_MODEL)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/AutoMl-Navigation-Tune-Model.svg"),"alt":""}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("automl.tuneModel")))])]),_c('i',{staticClass:"el-icon-circle-check dtp-link-check",class:{
            'dtp-display-block': _vm.navigationsList.length >= 6,
          }})]):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == _vm.pages.TUNE_MODEL),expression:"$route.name == pages.TUNE_MODEL"}],staticClass:"dtp-navigation-menu-sub-list dtp-navigation-line-s"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("automl.hyperparameterText"))+" "),_c('a',{staticClass:"aml-navigation-helperLink",attrs:{"href":_vm.tuneModelLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("automl.more"))+" "),_c('i',{staticClass:"el-icon-right"})])]),(_vm.hasTuneModelAlgorithm)?_c('button',{on:{"click":function($event){return _vm.tuneModel()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.tuneModels"))+" ")]):_vm._e(),(!_vm.hasTuneModelAlgorithm)?_c('button',{on:{"click":function($event){return _vm.completeExperiment()}}},[_vm._v(" "+_vm._s(_vm.$t("automl.finishExperiment"))+" ")]):_vm._e()])]),_c('div',{staticClass:"dtp-navigation-switch"},[_c('label',{staticClass:"eds-checkbox mr-16"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value1),expression:"value1"}],staticClass:"eds-checkbox_#input",attrs:{"type":"checkbox","checked":""},domProps:{"checked":Array.isArray(_vm.value1)?_vm._i(_vm.value1,null)>-1:(_vm.value1)},on:{"change":function($event){var $$a=_vm.value1,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value1=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value1=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value1=$$c}}}}),_c('span',{staticClass:"eds-checkbox_#label"},[_vm._v(_vm._s(_vm.$t("navigation.Show Instructions")))])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }