<template>
  <el-dialog :visible.sync="isOpen" :before-close="handleClose">
    <div class="header section-spacing">
      <div class="title">{{ $t("filter.Add Filter") }}</div>
    </div>
    <div class="section-spacing search-column-input-container">
      <div
        class="eds-field_#control eds-field_.eds-input eds-input dtp-aml-w-300"
      >
        <input
          type="search"
          v-model="search"
          :placeholder="$t('filter.Search in columns')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div
      class="sub-titles-container dtp-row dtp-scrollbar dtp-scrollbar-scroll"
    >
      <div class="dtp-col dtp-col-3 dtp-pa--0 dtp-ma--0">
        <div class="sub-title" style="border-right: 1px solid #d6d6d6">
          <span>{{ $t("filter.columnNames") }}</span>
        </div>

        <div class="section-spacing row-item-container">
          <label
            class="eds-checkbox row-item"
            v-for="(item, index) in filteredList"
            :key="'columnName_' + index"
          >
            <input
              type="checkbox"
              name="foo"
              class="eds-checkbox_#input"
              checked=""
              v-model="item.isActive"
            />
            <span class="eds-checkbox_#label">{{
              $t("table.header." + item.alias)
            }}</span>
          </label>
        </div>
      </div>
      <div class="dtp-col dtp-col-4 dtp-pa--0 dtp-ma--0">
        <div class="sub-title" style="border-right: 1px solid #d6d6d6">
          <span>{{ $t("filter.operations") }}</span>
        </div>

        <div class="section-spacing row-item-container">
          <div
            class="eds-field_#control eds-field_.eds-input eds-input row-item"
            v-for="(item, index) in filteredList"
            :key="'operations_' + index"
          >
            <select
              v-model="item.operator"
              class="eds-input_#input eds-input_#input.dropdown"
              :disabled="!item.isActive"
            >
              <option
                v-for="operator in operators[item.type]"
                :key="`${item.type}-${operator.key}`"
                :value="operator.key"
              >
                {{ $t("filter." + operator.label) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="dtp-col dtp-col-5 dtp-pa--0 dtp-ma--0">
        <div class="sub-title">
          <span>{{ $t("filter.data") }}</span>
        </div>

        <div class="section-spacing row-item-container">
          <div
            class="dtp-filterPopup-box row-item"
            v-for="(item, index) in filteredList"
            :key="'data_' + index"
          >
            <div
              v-if="
                operatorsFlat &&
                operatorsFlat.input &&
                operatorsFlat.input.includes(item.operator)
              "
            >
              <div
                v-if="item.type === 'Numeric'"
                class="eds-field_#control eds-field_.eds-input eds-input"
              >
                <input
                  type="number"
                  v-model.number="item.data"
                  class="eds-input_#input"
                  :placeholder="getOperatorTooltip(item.type, item.operator)"
                />
              </div>
              <div
                v-else-if="item.type === 'dateRange'"
                class="eds-field_#control eds-field_.eds-input eds-input"
              >
                <el-date-picker
                  v-model="item.data"
                  class="eds-input_#input"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="-"
                  :start-placeholder="$t('commons.startDate')"
                  :end-placeholder="$t('commons.endDate')"
                >
                </el-date-picker>
              </div>
              <div
                v-else
                class="eds-field_#control eds-field_.eds-input eds-input"
              >
                <input
                  type="text"
                  v-model="item.data"
                  class="eds-input_#input"
                  :placeholder="getOperatorTooltip(item.type, item.operator)"
                />
              </div>
            </div>
            <div
              v-if="
                operatorsFlat &&
                operatorsFlat.select &&
                operatorsFlat.select.includes(item.operator)
              "
              class="eds-field_#control eds-field_.eds-input eds-input"
            >
              <select
                v-model="item.data"
                class="eds-input_#input eds-input_#input.dropdown"
              >
                <option
                  v-for="value in item.values"
                  :key="`select-${index}-${value}`"
                  :value="value"
                >
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer section-spacing header">
      <button @click="handleClose" class="eds-button eds-button.basic">
        {{ $t("commons.cancel") }}
      </button>
      <button @click="saveFilter" class="eds-button eds-button.basic">
        {{ $t("commons.search") }}
      </button>
    </div>
  </el-dialog>
</template>
<script>
import _ from "lodash";
import {
  NumericOperators,
  CategoricalOperators,
  OperatorKey,
  DateRangeOperators,
} from "./filterPopupTypes";

export default {
  props: {
    headerArray: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filteredList: [],
      search: "",
      operatorKeys: OperatorKey,
      operators: {
        Numeric: NumericOperators,
        Categorical: CategoricalOperators,
        dateRange: DateRangeOperators,
        input: [{ label: "Equal", key: "equal_input", type: "input" }],
        select: [{ label: "Equal", key: "equal_select", type: "select" }],
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    search: {
      handler(val) {
        this.searched(val);
      },
    },
    filteredList: {
      handler() {
        this.clearUncheckedFilters();
      },
      deep: true,
    },
  },
  computed: {
    operatorsFlat() {
      let arr = [];

      Object.keys(this.operators).forEach((key) => {
        arr = arr.concat(this.operators[key]);
      });

      return _.mapValues(_.groupBy(arr, "type"), (clist) =>
        clist.map((car) => car.key)
      );
    },
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    getOperatorTooltip(type, operator) {
      let result = "";

      if (type === "Numeric") {
        result =
          NumericOperators.find((no) => no.key === operator)?.tooltip ?? "";
      } else {
        result =
          CategoricalOperators.find((co) => co.key === operator)?.tooltip ?? "";
      }

      return result ? "For example; " + result : "";
    },
    init() {
      this.filteredList = this.headerArray.filter((x) => x.isShow);
    },
    saveFilter() {
      this.$emit("filterSaved", this.filteredList);
      this.handleClose();
    },
    searched(val) {
      this.init();
      this.filteredList = this.filteredList.filter((item) => {
        return val
          .toLowerCase()
          .split(" ")
          .every((v) => item.alias.toLowerCase().includes(v));
      });
    },
    clearUncheckedFilters() {
      this.filteredList.forEach((item) => {
        if (!item.isActive) {
          item.data = "";
          item.operator = "";
        }
      });
    },
  },
};
</script>
<style scoped>
.dtp-test-filter {
  position: relative;
  width: 100%;
  height: 364px;
  padding: 0 !important;
  margin: 0 !important;
}

.dtp-aml-w-300 {
  width: 300px;
}

::v-deep .el-dialog__body,
::v-deep .el-dialog__header {
  padding: 0 !important;
}

.header {
  background-color: rgb(245, 247, 247);
}

.title {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
}

.sub-title {
  background-color: rgb(245, 247, 247);
  color: var(--main-title-color);
  padding: 10px 20px;
  border-bottom: 1px solid #d6d6d6;
}

.section-spacing {
  padding: 14px 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.search-column-input-container {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.sub-titles-container {
  border-bottom: 1px solid #d6d6d6;
  max-height: 500px;
}

.row-item-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-right: 1px solid #d6d6d6;
}

.row-item {
  height: 32px;
}

.eds-checkbox_\#label {
  color: var(--main-title-color);
  font-weight: 400;
}
</style>
