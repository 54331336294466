export const optionsColumn = [
    { label: null, field: "check", sortable: false },
    { label: "Column", field: "column", width: "100px" },
    { label: "Data Type", field: "type", width: '130px' },
	{
		label: "Missing Ratio",
		field: "missing",
		sortable: false,
		width: "150px"
	},
    { label: "Fill Missing Value Options", field: "missingValue", width: "440px" },
    { label: "Actions", field: "actions", width: "100px" },
];

export const conversionMapping = {
    "Categorical": [
        { label: "Most Frequent Value", value: "Most Frequent Value" },
        { label: "Constant", value: "Constant" },
    ],
    "Numeric": [
        { label: "Mean", value: "Mean" },
        { label: "Median", value: "Median" },
        { label: "Constant", value: "Constant" },
    ],
    "Date": [
        { label: "Categorical", value: "Categorical" },
        { label: "Numeric", value: "Numeric" },
    ],
}