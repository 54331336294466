import { InputTypes, Algorithms } from './types'

export const SECONDARY_COLUMNS_GPU_CLASSIFICATION_DEFAULT_VALUES = [
    {
        ...Algorithms.LogisticRegression,
        check: false,
        penalty: "l1",
        C: 1.0,
        max_iter: 1000,
        fit_intercept: true,
        tol: 1e-4,
        class_weight: "balanced",
        linesearch_max_iter: 50,
        l1_ratio: 0
    },
    {
        ...Algorithms.XGBoost,
        check: false,
        eta: 0.3,
        gamma: 0,
        max_depth: 6,
        min_child_weight: 1,
        max_delta_step: 0,
        subsample: 1,
        sampling_method: "uniform",
        lambda: 1,
        alpha: 0,
        scale_pos_weight: 1,
        process_type: "default",
        grow_policy: "depthwise",
        max_leaves: 0,
        max_bin: 256,
        num_parallel_tree: 1,
        colsample_bytree: 1
    },
    {
        ...Algorithms.RandomForest,
        check: false,
        split_criterion: 0,
        max_depth: 16,
        max_leaves: -1,
        min_samples_split: 2,
        min_samples_leaf: 1,
        max_features: "auto",
        n_bins: 128,
        bootstrap: true,
        n_estimators: 100,
        max_samples: 1.0,
        n_streams: 4,
        min_impurity_decrease: 0.0,
        max_batch_size: 4096
    },
    {
        ...Algorithms.CatBoost,
        check: false,
        n_estimators: 100,
        eta: 0.03,
        l2_leaf_reg: 3,
        depth: 6,
        grow_policy: "SymmetricTree",
        min_child_samples: 1,
        max_leaves: 31,
        nan_mode: "Min",
    },
    {
        ...Algorithms.LightGBM,
        check: false,
        n_estimators: 100,
        learning_rate: 0.1,
        num_leaves: 31,
        feature_fraction: 1,
        max_depth: -1,
        num_iterations: 100,
        min_data_in_leaf: 20,
    },
];

export const SECONDARY_COLUMNS_GPU_REGRESSION_DEFAULT_VALUES = [
    {
        ...Algorithms.XGBoost,
        check: false,
        eta: 0.3,
        gamma: 0,
        max_depth: 6,
        min_child_weight: 1,
        max_delta_step: 0,
        subsample: 1,
        sampling_method: "uniform",
        lambda: 1,
        alpha: 0,
        scale_pos_weight: 1,
        process_type: "default",
        grow_policy: "depthwise",
        max_leaves: 0,
        max_bin: 256,
        num_parallel_tree: 1,
        colsample_bytree: 1
    },
    {
        ...Algorithms.RandomForest,
        check: false,
        split_criterion: 2,
        max_depth: 16,
        max_leaves: -1,
        min_samples_split: 2,
        min_samples_leaf: 1,
        max_features: "auto",
        n_bins: 128,
        bootstrap: true,
        n_estimators: 100,
        max_samples: 1.0,
        n_streams: 4,
        min_impurity_decrease: 0.0
    },
    {
        ...Algorithms.LinearRegression,
        check: false,
        fit_intercept: true,
        normalize: false,
        algorithm: "eig",
    },
    {
        ...Algorithms.CatBoost,
        check: false,
        n_estimators: 100,
        eta: 0.03,
        l2_leaf_reg: 3,
        depth: 6,
        grow_policy: "SymmetricTree",
        min_child_samples: 1,
        max_leaves: 31,
        nan_mode: "Min",
    },
    {
        ...Algorithms.LightGBM,
        check: false,
        n_estimators: 100,
        learning_rate: 0.1,
        num_leaves: 31,
        feature_fraction: 1,
        max_depth: -1,
        min_child_samples: 20,
        num_iterations: 100,
    },
];

export const SECONDARY_COLUMNS_GPU_CLUSTERING_DEFAULT_VALUES = [
    {
        ...Algorithms.Kmeans,
        check: false,
        num_clusters: 8,
        max_iter: 300,
        tol: 1e-4,
        n_init: 1,
        init: "scalable-k-means++",
        oversampling_factor: 2.0,
        max_samples_per_batch: 32768
    },
    {
        ...Algorithms.DBScan,
        check: false,
        eps: 0.5,
        min_samples: 5
    },
];

export const SECONDARY_COLUMNS_GPU_CLASSIFICATION_CONFIG = {
    [Algorithms.LogisticRegression.model]: [{
        label: "Penalty",
        key: "penalty",
        values: [{
            key: "none",
            label: "None"
        }, {
            key: "l1",
            label: "l1"
        }, {
            key: "l2",
            label: "l2"
        }, {
            key: "elasticnet",
            label: "elasticnet"
        }],
        type: InputTypes.SelectBox
    }, {
        label: "Tol",
        key: "tol",
        type: InputTypes.NumberField,
        validation: {
            max: 5
        }
    }, {
        label: "C",
        key: "C",
        type: InputTypes.NumberField,
        validation: {
            min: 0.1
        }
    }, {
        label: "Fit Intercept",
        key: "fit_intercept",
        type: InputTypes.CheckBox
    },
    {
        label: "Class Weight",
        key: "class_weight",
        type: InputTypes.TextField,
        validate: (row) => {
            const val = row.class_weight;
            const values = ["balanced", "None"];

            if (!values.includes(val)) {
                try {
                    JSON.parse(val);
                    return true;
                } catch (e) {
                    return false;
                }
            }
            else {
                return true;
            }
        },
    }, {
        label: "Max Iteration",
        key: "max_iter",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 2000
        }
    }, {
        label: "Linesearch Max Iter",
        key: "linesearch_max_iter",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 100
        }
    },
    {
        label: "L1 Ratio",
        key: "l1_ratio",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    }],

    [Algorithms.XGBoost.model]: [{
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Gamma",
        key: "gamma",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Min Child Weight",
        key: "min_child_weight",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Delta Step",
        key: "max_delta_step",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Subsample",
        key: "subsample",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    {
        label: "Sampling Method",
        key: "sampling_method",
        values: [{
            label: "Uniform",
            key: "uniform"
        }, {
            label: "Gradient Based",
            key: "gradient_based"
        },
        ],
        type: InputTypes.SelectBox
    },
    {
        label: "Lambda",
        key: "lambda",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Alpha",
        key: "alpha",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Scale Pos Weight",
        key: "scale_pos_weight",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Grow Policy",
        key: "grow_policy",
        values: [{
            label: "depthwise",
            key: "depthwise"
        }, {
            label: "lossguide",
            key: "lossguide"
        },
        ],
        type: InputTypes.SelectBox,
    },
    {
        label: "Max Leaves",
        key: "max_leaves",
        type: InputTypes.NumberField,
        validation: {},
        invisible: (row) => {
            if (row.grow_policy === 'lossguide')
                return false
            return true
        },
    },
    {
        label: "Max Bin",
        key: "max_bin",
        type: InputTypes.NumberField,
        validation: {},
    },
    {
        label: "Num Parallel Tree",
        key: "num_parallel_tree",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Col Sample By Tree",
        key: "colsample_bytree",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    ],

    [Algorithms.RandomForest.model]: [
        {
            label: "N Estimators",
            key: "n_estimators",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
                max: 200
            }
        },
        {
            label: "Split Criterion",
            key: "split_criterion",
            values: [{
                label: "Gini",
                key: 0
            }, {
                label: "Entropy",
                key: 1
            },
            ],
            type: InputTypes.SelectBox
        },
        {
            label: "Max Samples",
            key: "max_samples",
            type: InputTypes.NumberField,
            validation: {
                min: 0,
            }
        },
        {
            label: "Max Depth",
            key: "max_depth",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            }
        }, {
            label: "Max Leaves",
            key: "max_leaves",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Max Features",
            key: "max_features",
            validate: (row) => {
                const val = row.max_features;
                const values = ["auto", "sqrt", "log2"];

                if (isNaN(parseFloat(val))) {
                    if (!values.includes(val)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (!isNaN(parseFloat(val))) {
                    return parseFloat(val) > 1
                }
            },
            type: InputTypes.TextField
        }, {
            label: "Number of Bins",
            key: "n_bins",
            type: InputTypes.NumberField,
            validation: {
                min: 1
            }
        }, {
            label: "N Streams",
            key: "n_streams",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Sample Leaf",
            key: "min_samples_leaf",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Samples Split",
            key: "min_samples_split",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Impurity Decrease",
            key: "min_impurity_decrease",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Max Batch Size",
            key: "max_batch_size",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Bootstrap",
            key: "bootstrap",
            type: InputTypes.CheckBox
        }],

    [Algorithms.CatBoost.model]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 49,
            max: 1001
        }
    }, {
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1.00000000001
        }
    }, {
        label: "L2 Leaf Regularizer",
        key: "l2_leaf_reg",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Depth",
        key: "depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 17
        }
    },
    {
        label: "Grow Policy",
        key: "grow_policy",
        values: [{
            label: "Depthwise",
            key: "Depthwise"
        }, {
            label: "Symmetric Tree",
            key: "SymmetricTree"
        }, {
            label: "Lossguide",
            key: "Lossguide"
        },
        ],
        type: InputTypes.SelectBox,
    },
    {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 41,
        }
    },
    {
        label: "Max Leaves",
        key: "max_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 65,
        },
        invisible: (row) => {
            if (row.grow_policy === 'Lossguide')
                return false
            return true
        },
    },
    {
        label: "Nan Mode",
        key: "nan_mode",
        values: [{
            label: "Forbidden",
            key: "Forbidden"
        }, {
            label: "Min",
            key: "Min"
        }, {
            label: "Max",
            key: "Max"
        },
        ],
        type: InputTypes.SelectBox,
    },
    ],

    [Algorithms.LightGBM.model]: [{
        label: "Learning Rate",
        key: "learning_rate",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1
        }
    }, {
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Num Leaves",
        key: "num_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 9,
            max: 51
        }
    }, {
        label: "Feature Fraction",
        key: "feature_fraction",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1.000000001
        }
    }, {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {}
    }, {
        label: "Num Iterations",
        key: "num_iterations",
        type: InputTypes.NumberField,
        validation: {
            min: 99,
            max: 1001
        }
    }, {
        label: "Min Data In Leaf",
        key: "min_data_in_leaf",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 31
        }
    },],
};

export const SECONDARY_COLUMNS_GPU_REGRESSION_CONFIG = {
    [Algorithms.XGBoost.model]: [{
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Gamma",
        key: "gamma",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Min Child Weight",
        key: "min_child_weight",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Delta Step",
        key: "max_delta_step",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Subsample",
        key: "subsample",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    {
        label: "Sampling Method",
        key: "sampling_method",
        values: [{
            label: "Uniform",
            key: "uniform"
        }, {
            label: "Gradient Based",
            key: "gradient_based"
        },
        ],
        type: InputTypes.SelectBox
    },
    {
        label: "Lambda",
        key: "lambda",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Alpha",
        key: "alpha",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Scale Pos Weight",
        key: "scale_pos_weight",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Grow Policy",
        key: "grow_policy",
        values: [{
            label: "depthwise",
            key: "depthwise"
        }, {
            label: "lossguide",
            key: "lossguide"
        },
        ],
        type: InputTypes.SelectBox,
    },
    {
        label: "Max Leaves",
        key: "max_leaves",
        type: InputTypes.NumberField,
        validation: {},
        invisible: (row) => {
            if (row.grow_policy === 'lossguide')
                return false
            return true
        },
    },
    {
        label: "Max Bin",
        key: "max_bin",
        type: InputTypes.NumberField,
        validation: {},
    },
    {
        label: "Num Parallel Tree",
        key: "num_parallel_tree",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Col Sample By Tree",
        key: "colsample_bytree",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    ],

    [Algorithms.RandomForest.model]: [
        {
            label: "N Estimators",
            key: "n_estimators",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
                max: 200
            }
        },
        {
            label: "Split Criterion",
            key: "split_criterion",
            values: [{
                label: "MSE",
                key: 2
            },
            ],
            type: InputTypes.SelectBox
        },
        {
            label: "Max Samples",
            key: "max_samples",
            type: InputTypes.NumberField,
            validation: {
                min: 0,
            }
        },
        {
            label: "Max Depth",
            key: "max_depth",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            },
            validate: (row) => {
                const val = row.max_depth;

                if (val == -1 || val > 1) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }, {
            label: "Max Leaves",
            key: "max_leaves",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Max Features",
            key: "max_features",
            validate: (row) => {
                const val = row.max_features;
                const values = ["auto", "sqrt", "log2"];

                if (isNaN(parseFloat(val))) {
                    if (!values.includes(val)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (!isNaN(parseFloat(val))) {
                    return parseFloat(val) >= 1
                }
            },
            type: InputTypes.TextField
        }, {
            label: "Number of Bins",
            key: "n_bins",
            type: InputTypes.NumberField,
            validation: {
                min: 1
            }
        }, {
            label: "N Streams",
            key: "n_streams",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Sample Leaf",
            key: "min_samples_leaf",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Samples Split",
            key: "min_samples_split",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Impurity Decrease",
            key: "min_impurity_decrease",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Bootstrap",
            key: "bootstrap",
            type: InputTypes.CheckBox
        }],

    [Algorithms.LinearRegression.model]: [{
        label: "Fit Intercept",
        key: "fit_intercept",
        type: InputTypes.CheckBox
    }, {
        label: "Normalize",
        key: "normalize",
        type: InputTypes.CheckBox
    }, {
        label: "Algorithm",
        key: "algorithm",
        values: [{
            label: "Eig",
            key: "eig"
        }, {
            label: "Svd",
            key: "svd"
        }],
        type: InputTypes.SelectBox
    }],

    [Algorithms.CatBoost.model]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 49,
            max: 1001
        }
    }, {
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1.00000000001
        }
    }, {
        label: "L2 Leaf Regularizer",
        key: "l2_leaf_reg",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Depth",
        key: "depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 17
        }
    },
    {
        label: "Grow Policy",
        key: "grow_policy",
        values: [{
            label: "Depthwise",
            key: "Depthwise"
        }, {
            label: "Symmetric Tree",
            key: "SymmetricTree"
        }, {
            label: "Lossguide",
            key: "Lossguide"
        },
        ],
        type: InputTypes.SelectBox,
    },
    {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 41,
        }
    },
    {
        label: "Max Leaves",
        key: "max_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 65,
        },
        invisible: (row) => {
            if (row.grow_policy === 'Lossguide')
                return false
            return true
        },
    },
    {
        label: "Nan Mode",
        key: "nan_mode",
        values: [{
            label: "Forbidden",
            key: "forbidden"
        }, {
            label: "Min",
            key: "Min"
        }, {
            label: "Max",
            key: "Max"
        },
        ],
        type: InputTypes.SelectBox,
    },
    ],

    [Algorithms.LightGBM.model]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Learning Rate",
        key: "learning_rate",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1
        }
    }, {
        label: "Num Leaves",
        key: "num_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 9,
            max: 51
        }
    }, {
        label: "Feature Fraction",
        key: "feature_fraction",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1.000000001
        }
    }, {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {}
    }, {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 41
        }
    }, {
        label: "Num Iterations",
        key: "num_iterations",
        type: InputTypes.NumberField,
        validation: {
            min: 99,
            max: 1001
        }
    },],
};

export const SECONDARY_COLUMNS_GPU_CLUSTERING_CONFIG = {
    [Algorithms.Kmeans.model]: [{
        label: "N Clusters",
        key: "num_clusters",
        type: InputTypes.NumberField,
        validation: {
            min: 2,
            max: 10
        }
    }, {
        label: "Max Iter",
        key: "max_iter",
        type: InputTypes.NumberField,
        validation: {
            min: 1
        }
    }, {
        label: "Tol",
        key: "tol",
        type: InputTypes.NumberField,
        validation: {
            min: 0
        }
    }, {
        label: "N Init",
        key: "n_init",
        type: InputTypes.NumberField,
        validation: {
            min: 0
        }
    }, {
        label: "Init",
        key: "init",
        values: [{
            key: "scalable-k-means++",
            label: "scalable-k-means++"
        }, {
            key: "k-means||",
            label: "k-means||"
        }, {
            key: "random",
            label: "random"
        }],
        type: InputTypes.SelectBox
    }, {
        label: "Oversampling Factor",
        key: "oversampling_factor",
        type: InputTypes.NumberField,
        validation: {}
    }, {
        label: "Max Sample Per Batch",
        key: "max_samples_per_batch",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 60000
        }
    }],
    [Algorithms.DBScan.model]: [{
        label: "EPS",
        key: "eps",
        type: InputTypes.NumberField,
        validation: {}
    }, {
        label: "Min Samples",
        key: "min_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
        }
    }]
};