<template>
  <div>
    <div class="tab-container">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('project.title')" name="projects">
          <div class="dtp-mt--1">
            <div>
              <div class="dtp-row dtp-align-center">
                <div class="aml-section-header dtp-align-center">
                  <div class="dtp-panel-formbox">
                    <div class="dtp-page-title">
                      <span>
                        <Icon icon="project" size="xl"></Icon>
                      </span>
                      {{ $t("project.title") }}
                    </div>
                  </div>
                  <div class="dtp-panel-formbox">
                    <el-tooltip
                      :content="$t('tooltip.addProject')"
                      placement="top"
                      effect="light"
                    >
                      <button
                        @click="projectAdd = !projectAdd"
                        class="aml-icon-btn"
                      >
                        <Icon icon="add"></Icon>
                      </button>
                    </el-tooltip>
                    <AddProject
                      v-if="isAddProjectButtonAvailable && projectAdd"
                      @createProject="addProject"
                      @changeName="setProjectName"
                      @changePortfolio="setPortfolio"
                      @changeUsecase="setUsecase"
                      @changeDesc="setDesc"
                      @closePopup="projectAdd = $event"
                      :projectName="projectName"
                      :description="description"
                      :projectPage="true"
                    />
                  </div>
                  <PageViewBar
                    :title="''"
                    :activeFilters="true"
                    :activeSearch="true"
                    :tooltipSearchName="'Project'"
                    :headerArray="filterArray"
                    helperLink="list-projects"
                    @filterSaved="filterSaved"
                    @deleteFiltered="deleteFilter"
                    :filterList="filterList"
                    :createBtn="false"
                    :calculateview="false"
                    @changedSearch="changedSearch"
                  >
                  </PageViewBar>
                </div>
              </div>
            </div>
            <div class="dtp-row">
              <div
                v-loading="loading"
                class="dtp-col dtp-col-12 dtp-freeze-header"
              >
                <vue-good-table
                  mode="remote"
                  ref="MyCoolTable"
                  :columns="columns"
                  :rows="enhancedProjectSetList"
                  @on-cell-click="detailProject"
                  class="vgt-cursor-cell"
                  @on-sort-change="(params) => onSortChange(params, init)"
                >
                  <template slot="table-column" slot-scope="props">
                    <span>{{ $t("table.header." + props.column.label) }}</span>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'description'">
                      <EditableText
                        :row="props.row"
                        :cellPlaceholder="$t('placeholder.Choose Portfolio')"
                        fieldName="description"
                        idName="id"
                        inputType="text"
                        @applyClicked="changeDescName"
                      >
                      </EditableText> </span
                    ><span
                      v-else-if="
                        props.column.field == 'portfolio_id' &&
                        isPortfolioAndUseCaseButtonAvailable
                      "
                    >
                      <EditableSelectbox
                        :row="props.row"
                        :selectboxItems="portfolioItems"
                        fieldName="portfolio_id"
                        :cellPlaceHolder="$t('placeholder.Choose Portfolio')"
                        idName="id"
                        :selectedRowId="tablePortfolioId"
                        selectboxType="portfolio_id"
                        @applyClicked="editPortfolio"
                        @rowEditClicked="(value) => (tablePortfolioId = value)"
                      >
                      </EditableSelectbox>
                    </span>
                    <span
                      v-else-if="
                        props.column.field == 'use_case_id' &&
                        isPortfolioAndUseCaseButtonAvailable
                      "
                    >
                      <EditableSelectbox
                        :row="props.row"
                        :selectboxItems="useCaseItems"
                        :cellPlaceHolder="$t('placeholder.Choose Use Case')"
                        fieldName="use_case_id"
                        idName="id"
                        :selectedRowId="tableUsecaseId"
                        selectboxType="use_case_id"
                        @applyClicked="editUsecase"
                        @rowEditClicked="(value) => (tableUsecaseId = value)"
                      >
                      </EditableSelectbox>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                      <el-tooltip
                        v-if="isDeleteProjectButtonAvailable"
                        :content="$t('tooltip.delete')"
                        placement="top"
                        effect="light"
                      >
                        <img
                          src="../../../assets/images/icons/automldeleteicon.svg"
                          @click.stop="
                            deleteProject({
                              id: props.row.id,
                              page: currentPage,
                              count: pageSize,
                            })
                          "
                          class="dtp-cursor-pointer dtp-vgt-actions-icon"
                          alt="deleteIcon"
                        />
                      </el-tooltip>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
                <CustomPagination
                  :lastPage="lastPage"
                  :pageSize.sync="pageSize"
                  :showPageSizeSelect="true"
                  :totalItem="totalItem"
                  @change="changePage"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('Portfolio')">
          <div id="portfolioAndUseCasePage">
            <Portfolio></Portfolio>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('Use Case')">
          <UseCase></UseCase>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import AddProject from "./Components/AddProject";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { COLUMNS } from "./data/projects";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import CustomPagination from "../CustomPagination";
import { formatDate } from "../../../helper/format";
import preferences from "../../../mixins/AutoML/preferences";
import PageViewBar from "../Helper/Pageviewbar";
import EditableText from "../EditableCell/EditableText.vue";
import remoteDataSort from "../../../mixins/AutoML/remoteDataSort";
import { PRIVILEGES } from "../../../helper/authorization";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import Portfolio from "./PortfolioAndUseCase/Portfolio.vue";
import EditableSelectbox from "../EditableCell/EditableSelectbox.vue";
import UseCase from "./PortfolioAndUseCase/UseCase.vue";

import {
  PROJECT_ACTIONS,
  PROJECT_GETTERS,
  PROJECT_MUTATIONS,
} from "../../../store/modules/AutoML/Projects/types";
import {
  TAG_ACTIONS,
  TAG_GETTERS,
} from "../../../store/modules/AutoML/Tags/types";
import { mapFilterResult } from "../Popup/filterPopupTypes";
import Icon from "../../Icons/Icon.vue";

export default {
  mixins: [notify, preferences, remoteDataSort, componentAvailabilityCheck],
  components: {
    VueGoodTable,
    AddProject,
    CustomPagination,
    PageViewBar,
    EditableText,
    Portfolio,
    EditableSelectbox,
    Icon,
    UseCase,
  },
  data() {
    return {
      TagTypes: {
        USE_CASE: "USE_CASE",
        PORTFOLIO: "PORTFOLIO",
      },

      filterArray: [],
      filterables: [
        { label: "Project Name", key: "project_name", type: "input" },
        { label: "User", key: "user", type: "input" },
        {
          label: "Created At",
          key: ["created_at_lower_bound", "created_at_upper_bound"],
          type: "dateRange",
        },
      ],
      columns: COLUMNS,
      changeColor: "#0000ff",
      fontSize: "14",
      fontWeight: "400",
      projectAdd: false,
      projectName: "",
      portfolio: "",
      usecase: "",
      description: "",
      tableDescId: null,
      id: 5,
      pageSize: 20,
      currentPage: 1,
      searchName: "",
      tableUsecaseId: null,
      tablePortfolioId: null,
      activeName: "projects",
    };
  },
  mounted() {
    this.requestComp = "projects";
    this.currentPage = 1;
    this.init();
    this.initFilterArray();
  },
  methods: {
    ...mapActions({
      fetchProjects: PROJECT_ACTIONS.FETCH_PROJECTS,
      deleteProjectById: PROJECT_ACTIONS.DELETE_PROJECT_BY_ID,
      setSelectedProject: PROJECT_ACTIONS.SET_SELECTED_PROJECT,
      createProject: PROJECT_ACTIONS.CREATE_PROJECT,
      updateDescriptionProject:
        PROJECT_ACTIONS.UPDATE_DESCRIPTION_BY_PROJECT_ID,
      fetchTags: TAG_ACTIONS.FETCH_TAGS,
    }),
    ...mapMutations({
      setProjectsFilter: PROJECT_MUTATIONS.SET_PROJECTS_FILTER,
    }),
    editPortfolio(row) {
      const { id, portfolio_id } = row;
      this.updateDescriptionProject({
        requestComp: this.requestComp,
        projectId: id,
        descriptionData: { portfolio_id },
      });
      this.tablePortfolioId = null;
      this.init();
      this.initFilterArray();
    },
    editUsecase(row) {
      const { id, use_case_id } = row;
      this.updateDescriptionProject({
        requestComp: this.requestComp,
        projectId: id,
        descriptionData: { use_case_id },
      });
      this.tableUsecaseId = null;
      this.init();
      this.initFilterArray();
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
    async init() {
      this.fetchTags({});
      await this.fetchProjects({
        page: this.currentPage,
        count: this.pageSize,
        project_name: this.searchName,
        ...this.sortConfig,
        ...this.projectsFilter,
      });
    },
    filterSaved(filters) {
      const mappedFilters = mapFilterResult(filters);

      if (Object.keys(mappedFilters).length > 0) {
        this.setProjectsFilter(mappedFilters);
        this.currentPage = 1;
        this.init();
      } else {
        this.$alert("Please enter a search condition!", "Warning", {
          confirmButtonText: "OK",
        });
      }
    },
    initFilterArray() {
      const alreadyFiltered = Object.keys(this.projectsFilter);
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        if (alreadyFiltered.includes(fs.key)) {
          x = {
            isActive: true,
            data: this.projectsFilter[fs.key],
            operator: fs.type === "input" ? "equal_input" : "equal_select",
          };
        }

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
    async deleteProject(project) {
      await this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t("Project")]),
        this.$t("confirmation.Delete Project"),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
          callback: async (confirm) => {
            if (confirm == "confirm") {
              await this.deleteProjectById({
                ...project,
                requestComp: this.requestComp,
              });
              this.deleteAllFilters();
            }
          },
        }
      );
    },
    setProjectName(value) {
      this.projectName = value;
    },
    setPortfolio(value) {
      this.portfolio = value;
    },
    setUsecase(value) {
      this.usecase = value;
    },
    setDesc(value) {
      this.description = value;
    },
    addProject() {
      if (this.projectName == "") {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Project name field can not be empty"),
          type: "warning",
        });
        return;
      }

      const objProject = {
        requestComp: this.requestComp,
        data: {
          project_name: this.projectName,
          description: this.description,
        },
        page: this.currentPage,
        count: this.pageSize,
      };

      if (this.portfolio) {
        objProject.data.portfolio_id = this.portfolio;
      }

      if (this.usecase) {
        objProject.data.use_case_id = this.usecase;
      }

      this.createProject(objProject);
      this.projectAdd = false;
      this.projectName = "";
      this.description = "";
      this.usecase = "";
      this.portfolio = "";

      this.deleteAllFilters();
    },

    detailProject(project) {
      //Backend bağlandığında Id'ye göre istek atılacak.
      if (project.column.field == "name") {
        this.setSelectedProject(project.row);
        this.$router.push({
          name: "ProjectDetails",
          query: {
            selectedProjectId: project.row.id,
            selectedProjectName: project.row.name,
          },
        });
      }
    },
    //TODO backend entegrasyon store ile
    changeDescName(row) {
      this.tableDescId = null;
      this.updateDescriptionProject({
        requestComp: this.requestComp,
        projectId: row.id,
        descriptionData: { description: row.description },
      });
    },
    deleteFilter(value) {
      const x = Object.assign({}, this.projectsFilter);
      delete x[value.column_name];
      this.setProjectsFilter(x);
      this.filterArray.forEach((element) => {
        if (element.name == value.column_name) {
          element.operator = "";
          element.data = "";
          element.isActive = false;
        }
      });

      this.currentPage = 1;
      this.init();
    },
    deleteAllFilters() {
      this.setProjectsFilter({});
      this.filterArray.forEach((element) => {
        element.operator = "";
        element.data = "";
        element.isActive = false;
      });

      this.currentPage = 1;
      this.init();
    },
  },
  watch: {
    pageSize: {
      handler() {
        this.currentPage = 1;
        this.init();
      },
    },
  },
  computed: {
    ...mapGetters({
      projects: PROJECT_GETTERS.GET_PROJECTS,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      projectsFilter: PROJECT_GETTERS.GET_PROJECTS_FILTER,
      tags: TAG_GETTERS.GET_TAGS,
    }),
    portfolioItems() {
      var portfolioArray = this.tags.filter(
        (t) => t.type === this.TagTypes.PORTFOLIO
      );
      portfolioArray.map((obj) => {
        obj.label = obj.name;
        obj.value = obj.name;
      });
      return portfolioArray;
    },
    useCaseItems() {
      var usecaseArray = this.tags.filter(
        (t) => t.type === this.TagTypes.USE_CASE
      );
      usecaseArray.map((obj) => {
        obj.label = obj.name;
        obj.value = obj.name;
      });
      return usecaseArray;
    },
    lastPage() {
      return this.pagination.projects?.last_page ?? 1;
    },
    totalItem() {
      return this.pagination.projects?.total_item;
    },
    filterList() {
      let list = [];
      Object.keys(this.projectsFilter).forEach((mdf) => {
        list.push({
          column_name: mdf,
          conditional_operator: "equal",
          value: this.projectsFilter[mdf],
        });
      });
      return list;
    },
    enhancedProjectSetList: {
      get() {
        //For prevent store mutate problem
        let list = JSON.parse(JSON.stringify(this.projects));
        list.map((obj) => {
          obj.created_at = formatDate(
            obj.created_at,
            this.preferences.longDateFormat
          );
        });
        return list;
      },
    },
    isPortfolioAndUseCaseButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON
      );
    },
    isAddProjectButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_ADD_PROJECT);
    },
    isDeleteProjectButtonAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_DELETE_PROJECT
      );
    },
  },
};
</script>
<style scoped>
.tab-container {
  padding: 70px 0px 10px 74px;
}
</style>
