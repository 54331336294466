export const domain = "AUTOML/APPLICATION";

export const APPLICATION_ACTIONS = {
  CREATE_APPLICATION: `${domain}/createApplication`,
  FETCH_APPLICATIONS: `${domain}/fetchApplications`,
  BUILD_APPLICATION: `${domain}/buildApplication`,
  DELETE_APPLICATION: `${domain}/deleteApplication`
};

export const APPLICATION_MUTATIONS = {
  SET_APPLICATIONS: `${domain}/setApplications`,
};

export const APPLICATION_GETTERS = {
  GET_APPLICATIONS: `${domain}/getApplications`,
}