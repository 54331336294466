<template>
  <div
    v-loading="loading"
    style="min-height: 300px"
    :class="
      !inModal ? 'predictions-table-in-page' : 'predictions-table-in-modal'
    "
  >
    <div class="dtp-panel-formbox dtp-mb--3">
      <ul class="dtp-aml-tabLink">
        <li
          @click="selectTab(tabs.tTest)"
          :class="{
            active: activeTab == tabs.tTest,
          }"
        >
          <span>{{ $t("modelGovernance.T-Test") }}</span>
        </li>
        <li
          @click="selectTab(tabs.binomialTest)"
          :class="{
            active: activeTab == tabs.binomialTest,
          }"
        >
          <span>{{ $t("modelGovernance.Binomial Test") }}</span>
        </li>
      </ul>
    </div>
    <div v-if="!loading">
      <div v-if="activeTab == tabs.tTest" class="centerAll dtp-mt--3 dtp-mb--3">
        <div v-if="tTestReturnedError">
          <span style="font-size: 15px; font-weight: 700"
            >{{ $t("modelGovernance.T-test could not be applied because of one of the following reasons") }}:</span
          >
          <br />
          <br />
          <ul>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The problem is not binary classification or regression") }}
            </li>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The row count of the data is less than 250") }}
            </li>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The target column is missing in the new data") }}
            </li>
          </ul>
        </div>
        <div v-else-if="!tTestReturnedError && tTestMessage">
          <p>{{ tTestMessage }} <button class="dtp-link-btn" @click="callFetchTTestResult">{{ $t("Click here to refresh") }}</button></p>
        </div>
        <TTestContainer
          v-else
          :tTestData="tTestResultComputed"
        />
      </div>
      <div
        v-if="activeTab == tabs.binomialTest"
        class="centerAll dtp-mt--3 dtp-mb--3"
      >
        <div v-if="binomialTestReturnedError">
          <span style="font-size: 15px; font-weight: 700"
            >{{ $t("modelGovernance.Binomial test could not be applied because of one of the following reasons") }}:</span
          >
          <br />
          <br />
          <ul>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The problem is not binary classification") }}
            </li>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The row count of the data is less than 250") }}
            </li>
            <li class="errorlistprediction">
              {{ $t("modelGovernance.The target column is missing in the new data") }}
            </li>
          </ul>
        </div>
        <div v-else-if="!binomialTestReturnedError && binomialTestMessage">
          <p>{{ binomialTestMessage }} <button class="dtp-link-btn" @click="callFetchBinomialTestResult">{{ $t("Click here to refresh") }}</button></p>
        </div>
        <BinomialTestContainer
          v-else
          :binomialTestData="binomialTestResultComputed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TTestContainer from "./TTestContainer";
import BinomialTestContainer from "./BinomialTestContainer";
import { mapActions, mapGetters } from "vuex";
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../store/modules/AutoML/Models/types';
import { GENERAL_GETTERS } from '../../../store/modules/AutoML/General/types';

export default {
  components: { TTestContainer, BinomialTestContainer },
  props: {
    row: {
      type: Object,
      required: true,
    },
    validations: {
      type: Array,
      required: true,
    },
    inModal: {
      type: Boolean,
      default: true,
    },
    dataType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      binomialTestReturnedError: false,
      tTestReturnedError: false,
      tabs: {
        tTest: "tTest",
        binomialTest: "binomialTest",
      },
      activeTab: null,
      methods: {
        binomial_test: "binomial_test",
        t_test: "t_test",
      },
      binomialTestMessage: null,
      tTestMessage: null
    };
  },
  watch: {
    activeTab: {
      handler(val) {
        if (val === this.tabs.tTest) {
          this.callFetchTTestResult();
        } else {
          this.callFetchBinomialTestResult();
        }
      },
    },
  },
  mounted() {
    this.activeTab = this.tabs.tTest;
  },
  computed: {
    ...mapGetters({
      tTestResult: MODELS_GETTERS.GET_T_TEST_RESULT,
      binomialTestResult: MODELS_GETTERS.GET_BINOMIAL_TEST_RESULT,
      loading: GENERAL_GETTERS.GET_LOADING
    }),
    tTestResultComputed() {
      return this?.tTestResult?.t_test_result?.[0];
    },
    binomialTestResultComputed() {
      return this?.binomialTestResult?.binomial_test_result?.[0];
    },
  },
  methods: {
    ...mapActions({
      fetchTTestResult: MODELS_ACTIONS.FETCH_T_TEST_RESULT,
      fetchBinomialTestResult: MODELS_ACTIONS.FETCH_BINOMIAL_TEST_RESULT,
    }),
    selectTab(value) {
      this.activeTab = value;
    },
    binomialTestTryAgain() {},
    async callFetchTTestResult() {
      const validationIdOfTTest = this.validations?.find(
        (x) => x.method === this.methods.t_test && (x.validation_type || x.type) === this.dataType
      );
      
      const res = await this.fetchTTestResult({
        model_id: this.row.model_id,
        validation_id: validationIdOfTTest?.validation_id,
        requestComp: null,
      });

      if (res.status === 400) {
        this.tTestReturnedError = true;
      } else if (res.status === 202) {
        this.tTestMessage = res.data?.message;
        this.tTestReturnedError = false;
      } else if (res.status === 200) {
        this.tTestReturnedError = false;
        this.tTestMessage = null;
      }
    },
    async callFetchBinomialTestResult() {
      const validationIdOfBinomialTest = this.validations?.find(
        (x) => x.method === this.methods.binomial_test && (x.validation_type || x.type) === this.dataType
      );

      const res = await this.fetchBinomialTestResult({
        model_id: this.row.model_id,
        validation_id: validationIdOfBinomialTest?.validation_id || this.validations?.[0]?.validation_id, //! prediction sonucu binom validation'ın prediction_id değeri null geldiği için ttest'in id si kullanılacak.
        requestComp: null,
      });

      if (res.status == 400) {
        this.binomialTestReturnedError = true;
      } else if (res.status === 202) {
        this.binomialTestMessage = res.data?.message;
        this.binomialTestReturnedError = false;
      } else if (res.status === 200) {
        this.binomialTestReturnedError = false;
        this.binomialTestMessage = null;
      }
    },
  },
};
</script>

<style scoped>
.dtp-aml-tabLink {
  position: relative;
  width: auto;
  height: auto;
  padding: 3px;
  margin: 0;
  background-color: #eaeaea;
  border-radius: 3px;
}
.dtp-aml-tabLink li {
  position: relative;
  padding: 7px 14px;
  margin: 0;
  display: inline-block;
  color: #333333;
  background-color: transparent;
  border-radius: 3px;
}
.dtp-aml-tabLink li span {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.dtp-aml-tabLink li.active {
  background-color: #416ca8;
  color: #ffffff;
}
.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorlistprediction {
  list-style: unset !important;
}
.predictions-table-in-modal {
  padding: 20px 100px;
}
.predictions-table-in-page {
  padding: 20px;
}
</style>
