<template>
  <el-popover
    :visible-arrow="true"
    placement="bottom-start"
    width="fit-content"
    trigger="click"
  >
    <div class="dtp-ma--0">
      <div
        v-for="(d, i) in deployments"
        :key="'deployments' + i"
        class="info-box"
      >
        <div> <span class="dtp-aml-font-bold"> {{ $t("deploymentInfo.Cluster") }}: </span> {{ d.cluster }} </div>
        <div> <span class="dtp-aml-font-bold"> {{ $t("deploymentInfo.Deployed By") }}: </span> {{ d.syncUser }} </div>
        <div> <span class="dtp-aml-font-bold"> {{ $t("deploymentInfo.Live Date") }}: </span> {{ formatSyncDate(d.syncDate) }} </div>
        <div> <span class="dtp-aml-font-bold"> {{ $t("deploymentInfo.Healty") }}: </span> <span :class="{'healthRed': !d.healthy, 'healthGreen': d.healthy}" >{{d.healthy ? 'Healthy' : 'Failed'}}</span> </div>

      </div>
    </div>
    <div slot="reference">
      <el-tooltip :content="$t('deploymentInfo.Deployment Info')" placement="top" effect="light">
        <i
          class="mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0"
          style="font-size: 12px"
        ></i>
      </el-tooltip>
    </div>
  </el-popover>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    deployment: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({ preferences: "Settings/Get/Preference" }),
    items() {
      return this.deployment?.deployments?.map((d) => [
        { title: "Cluster", content: d.cluster },
        {
          title: "User",
          content: d.syncUser,
        },
        {
          title: "Date",
          content: d.syncDate,
        },
      ]);
    },
    deployments() {
      return this.deployment?.deployments;
    },
  },
  methods: {
    formatSyncDate(value) {
      return value?.split(" ")[0];
    },
  },
};
</script>
<style scoped>
.healthRed {
  color: red;
}
.healthGreen {
  color: green;
}
.info-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dtp-modelgovernance-title {
  font-size: 14px;
  font-weight: bold;
  color: #4968a2;
  margin-bottom: 4px;
}
.dtp-modelgovernance-content {
  font-size: 12px;
  color: #515f67;
  line-height: 25px;
}
.mdi-information-outline:hover {
  color: #8c9fc3;
}

::v-deep .el-popover__reference-wrapper {
  float: right !important;
}
</style>
