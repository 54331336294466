export const serviceMethodParent = {
    projects: "projects",
    experiments: "experiments",
    datasets: "datasets",
    models: "models",
    data: "data",
    dataSource: "dataSource",
    pipeline: "pipeline",
    stats: "stats",
    tags: "tags",
    build: "build",
    clusters: "clusters",
    applications: "applications",
    device: "device",
    license: "license"
}

export const serviceMethodSub = {
    //projects
    getProjectList: "getProjectList",
    createProject: "createProject",
    deleteProject: "deleteProject",
    getProjectByProjectId: "getProjectByProjectId",
    updateDescriptionByProjectId: "updateDescriptionByProjectId",
    createExperiment: "createExperiment",
    getPipeline: "getPipeline",
    deleteExperiment: "deleteExperiment",
    startSession: "startSession",
    stopSession: "stopSession",
    completeExperiment: "completeExperiment",
    //experiments
    getExperiments: "getExperiments",
    getExperimentsByProjectId: "getExperimentsByProjectId",
    getExperimentsById: "getExperimentsById",
    getAllTrainingsByExperimentId: "getAllTrainingsByExperimentId",
    getTrainingLogs: "getTrainingLogs",
    clearExperiments: "clearExperiments",
    getExperimentSessionStatus: "getExperimentSessionStatus",
    trainedStatusUpdate: "trainedStatusUpdate",
    //datasets
    getDatasetsList: "getDatasetsList",
    deleteDataset: "deleteDataset",
    getVisualization: "getVisualization",
    getDatasetById: "getDatasetById",
    createDataset: "createDataset",
    uploadCsv: "uploadCsv", 
    uploadPreviewCsv: "uploadPreviewCsv",
    //models
    getModelsList: "getModelsList",
    getModelsListByExperimentId: "getModelsListByExperimentId",
    postModelPlots: "postModelPlots",
    makePredicNewData: "makePredicNewData",
    getModelResulsList: "getModelResulsList",
    downloadPredictionResults: "downloadPredictionResults",
    runModels: "runModels",
    problemConfiguration: "problemConfiguration",
    deleteModelTraining: "deleteModelTraining",
    modelDownload: "modelDownload",
    modelTuning: "modelTuning",
    fetchPredictions: "fetchPredictions",
    getModelsListByModelId: "getModelsListByModelId",
    updateDescriptionByModelId: "updateDescriptionByModelId",
    getModelEquation: "getModelEquation",
    fetchModelResultsMain: "fetchModelResultsMain",
    fetchModelExplanationStatus: "fetchModelExplanationStatus",
    fetchModelExplanationSamples: "fetchModelExplanationSamples",
    postModelExplanationPlot: "postModelExplanationPlot",
    fetchModelPlotMain: "fetchModelPlotMain",
    uploadAudit: "uploadAudit",
    fetchAudit: "fetchAudit",
    makeModelValidations: "makeModelValidations",
    getTTestResult: "getTTestResult",
    getBinomialTestResult: "getBinomialTestResult",
    getModelValidations: "getModelValidations",
    fetchCustomModels: "fetchCustomModels",
    defineCustomModel: "defineCustomModel",
    retryCustomModel: "retryCustomModel",
    deleteCustomModel: "deleteCustomModel",
    fetchModelRequestInfo: "fetchModelRequestInfo",
    retrainCustomModel: "retrainCustomModel",
    fetchChallengerModelResults: "fetchChallengerModelResults",
    createChallengerModel: "createChallengerModel",
    fetchChallengerModelStatus: "fetchChallengerModelStatus",
    fetchLogExports: "fetchLogExports",
    postLogExport: "postLogExport",
    deleteLogExport: "deleteLogExport",
    checkLogExportStatus: "checkLogExportStatus",
    getDownloadAudit: "getDownloadAudit",
    retestCustomModel: "retestCustomModel",
    getGenericModels: "getGenericModels",
    //data
    getExplorationAllList: "getExplorationAllList",
    getData: "getData",
    renameColumn: "renameColumn",
    convertApi: "convertApi",
    deleteColumns: "deleteColumns",
    missingValue: "missingValue",
    encoding: "encoding",
    scaling: "scaling",
    removeNaRows: "removeNaRows",
    dataFilter: "dataFilter",
    dataFeatureGeneration: "dataFeatureGeneration",
    dataUpdateFeatureGeneration: "dataUpdateFeatureGeneration",
    dataVersioning: "dataVersioning",
    featureImportancesPost: "featureImportancesPost",
    featureImportancesGet: "featureImportancesGet",
    dataSort: "dataSort",
    getStatus: "getStatus",
    getOperationStatus: "getOperationStatus",
    getOperationStatusById: "getOperationStatusById",
    imbalanceInspection: "imbalanceInspection",
    balanceConfiguration: "balanceConfiguration",
    dataBinning: "dataBinning",
    //datasource
    createAutoml: "createAutoml",
    fetchDataSource: "fetchDataSource",
    deleteDataSource: "deleteDataSource",
    createDataSource: "createDataSource",
    uploadRdbPreview: "uploadRdbPreview",
    uploadRdb: "uploadRdb",
    //pipeline
    previousPipelineStep: "previousPipelineStep",
    //stats
    getStats: "getStats",
    //tags
    createTag: "createTag", 
    deleteTag: "deleteTag", 
    getTagList: "getTagList", 
    updateTag: "updateTag",
    //build
    deleteBuild: "deleteBuild",
    deployBuild: "deployBuild", 
    fetchBuildById: "fetchBuildById", 
    fetchBuilds: "fetchBuilds", 
    fetchDeploymentStatusByBuilds: "fetchDeploymentStatusByBuilds",
    //clusters
    createCluster: "createCluster", 
    deleteCluster: "deleteCluster", 
    fetchClusters: "fetchClusters", 
    repairCluster: "repairCluster", 
    updateCluster: "updateCluster",
    //applications
    buildApplication: "buildApplication", 
    createApplication: "createApplication", 
    fetchApplications: "fetchApplications",
    deleteApplication:"deleteApplication",
    //device
    fetchDeviceType: "fetchDeviceType",
    //license
    fetchLicenseInfo: "fetchLicenseInfo"
}