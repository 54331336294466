<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')">
    <div slot="title">
      {{ `${experiment} - ${model}` }}
      <span style="font-weight: bold">{{ "/ " + $t("Equation") }}</span>
    </div>
    <div style="font-size: 15px; overflow: auto; padding-bottom: 20px">
      <div v-for="e in Object.keys(equation)" :key="e" style="display: flex; margin-bottom: 10px">
        <span style="flex: 0 0 auto; margin-right: 10px">{{
          uppercasedStr(e)
        }}</span>
        <math-jax :latex="equation[e]" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { MathJax } from "mathjax-vue";

export default {
  components: {
    MathJax,
  },
  props: {
    visible: {
      type: Boolean,
    },
    equation: {
      type: Object,
    },
    experiment: {
      type: String,
    },
    model: {
      type: String,
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    uppercasedStr(e) {
      return e.charAt(0).toUpperCase() + e.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.bigger {
  font-size: 50px;
  padding: 90px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
</style>