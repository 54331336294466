<template>
  <svg
    id="plus"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="MDI_plus" data-name="MDI / plus">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="20" height="20" stroke="none" />
        <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
      </g>
      <path
        id="Path_plus"
        data-name="Path / plus"
        d="M16.667,11.667h-5v5H10v-5H5V10h5V5h1.667v5h5Z"
        transform="translate(-0.833 -0.833)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
