<template>
  <svg
    id="pipe"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g id="MDI_pipe" data-name="MDI / pipe">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="18" height="18" stroke="none" />
        <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
      </g>
      <path
        id="Path_pipe"
        data-name="Path / pipe"
        d="M17,11H15.5v1.5H11V10.25h1.5V8.75H11V5A1.5,1.5,0,0,0,9.5,3.5h-6V2H2V8H3.5V6.5H8V8.75H6.5v1.5H8V14a1.5,1.5,0,0,0,1.5,1.5h6V17H17"
        transform="translate(-0.5 -0.5)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
