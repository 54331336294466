<template>
  <div>
    <welcome />
  </div>
</template>

<script>
import AutoMlWelcome from "../../components/Welcome/AutoMlWelcome";
export default {
  data() {
    return {};
  },
  components: {
    welcome: AutoMlWelcome,
  },
};
</script>
<style></style>
