<template>
  <div class="dtp-section">
    <div class="aml-section-header">
      <PageViewBar
          :isImportEnabled="false"
          :title="$t('Statistics')"
          :tooltipSearchName="$t('column')"
          :routerLink="''"
          :activeFilters="false"
          :headerArray="[]"
          :filterList="[]"
          :createBtn="false"
          :activeSearch="true"
          @changedSearch="changedSearch"
      />
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12 dtp-freeze-header">
        <vue-good-table
          ref="MyCoolTable"
          :columns="columns"
          :rows="searchList"
          :sort-options="{
            enabled: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 10,
            perPageDropdownEnabled: false,
            setCurrentPage: 1,
              nextLabel: this.$t('Previous'),
              prevLabel: this.$t('Next'),
          }"
          class="vgt-custom-checkbox-column"
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field == 'collapse'">
              <i
                @click="expandRow()"
                :class="{
                  'dtp-rotate-180deg': toggleRow,
                }"
                class="
                  el-icon-arrow-down
                  dtp-aml-font-bold dtp-cursor-pointer dtp-fcolor-333333
                "
              ></i>
            </span>
            <span v-else>
              {{ $t("table.header." + props.column.label) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'statistics'">
              <div v-if="props.row.type === rowTypes.Categorical" class="dtp-row">
                <div class="dtp-col dtp-col-4">
                  <b>{{ $t(`${statisticsLabels.Least}`) }}</b> <br />{{ props.row.statistics.least.count }}({{
                    props.row.statistics.least.value
                  }})
                </div>
                <div class="dtp-col dtp-col-4">
                  <b>{{ $t(`${statisticsLabels.Most}`) }}</b> <br />{{ props.row.statistics.most.count }}({{
                    props.row.statistics.most.value
                  }})
                </div>
              </div>
              <div v-if="props.row.type === rowTypes.Numeric" class="dtp-row">
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.Min}}</b> <br />{{ props.row.statistics.min }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{ $t(`${statisticsLabels.Max}`) }}</b> <br />{{ props.row.statistics.max }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.Std}}</b> <br />{{ props.row.statistics.std }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{ $t(`${statisticsLabels.Mean}`) }}</b> <br />{{ props.row.statistics.mean }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.quantile25}}</b> <br />{{ props.row.statistics['25%'] }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.quantile50}}</b> <br />{{ props.row.statistics.median }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.quantile75}}</b> <br />{{ props.row.statistics['75%'] }}
                </div>
              </div>
              <div v-if="props.row.type === rowTypes.Date" class="dtp-row">
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.Min}}</b> <br />{{ formatDate(props.row.statistics.min) }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.Max}}</b> <br />{{ formatDate(props.row.statistics.max) }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.Mean}}</b> <br />{{ formatDate(props.row.statistics.mean) }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.quantile25}}</b> <br />{{ formatDate(props.row.statistics['25%']) }}
                </div>
                <div class="dtp-col dtp-col">
                  <b>{{statisticsLabels.quantile75}}</b> <br />{{ formatDate(props.row.statistics['75%']) }}
                </div>
              </div>
            </span>
            <span v-else-if="props.column.field === 'collapse'">
              <i
                @click="collapseRow(props.row, props.row.collapseOpen)"
                :class="{
                  'dtp-rotate-180deg': props.row.collapseOpen,
                }"
                class="
                  el-icon-arrow-down
                  dtp-aml-font-bold dtp-cursor-pointer dtp-fcolor-436DA9
                "
              ></i>
            </span>
            <span v-else-if="props.column.field === 'type'">
              {{ $t(`${props.formattedRow[props.column.field]}`) }}
              <img
                v-if="props.row.collapseOpen && props.row.chart"
                :src="props.row.chart"
                @mouseover="mouseOver(props.row.name)"
                @mouseleave="mouseLeave()"
                alt="Example"
                class="dtp-vgt-chart"
                :class="{ 'big-image': columnName == props.row.name }"
              />
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { COLUMNS } from "./data/coldefs";
import PageViewBar from "../Helper/Pageviewbar";
import notify from "../../../mixins/AutoML/notify";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_ACTIONS, DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS } from '../../../store/modules/AutoML/Data/types';

export default {
  mixins: [notify],
  components: {
    VueGoodTable,
    PageViewBar,
  },
  data() {
    return {
      experimentId: this.$route.query.experimentId,
      columns: COLUMNS,
      filterArray: [],
      toggleBtnName: "Expand All",
      toggleRow: false,
      search: "",
      dataList: [],
      columnName: "",
      statisticsLabels: {
        Least: 'Least',
        Most: 'Most',
        Min: 'Min',
        Max: 'Max',
        Std: 'Std',
        Mean: 'Mean',
        quantile25: '25%',
        quantile50: '50%',
        quantile75: '75%',
      },
      rowTypes: {
        Categorical: 'Categorical',
        Numeric: 'Numeric',
        Date: 'Date'
      }
    };
  },
  async mounted() {
    this.requestComp = "statistics";
    this.fetchExplorations({
      requestComp: this.requestComp,
      experimentId: this.experimentId,
    });
    await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
    await this.fetchVisualization({
      datasetId: this.selectedExperiment[0].dataset_id,
      visualizationType: "STATISTICS",
    }); //2
  },
  watch: {
    mergedRow: {
      handler(val) {
        this.dataList = val;
      },
    },
  },
  computed: {
    ...mapGetters({
      datasetName: DATASET_GETTERS.GET_DATASET_NAME,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      visualization: DATASET_GETTERS.GET_VISUALIZATION,
    }),
    searchList() {
      if (this.search) {
        return this.dataList.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.dataList;
      }
    },
    mergedRow() {
      /* const result = Object.assign([], this.explorations); */
      const result = JSON.parse(JSON.stringify(this.explorations));
      //es6
      result.forEach((r) => {
        const filteredVisualization = this.visualization.filter(
          (v) => v.column_name === r.name
        );
        r.chart =
          filteredVisualization &&
          filteredVisualization.length &&
          filteredVisualization[0].plot_path;
        r.collapseOpen = false;
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      fetchExplorations: DATA_ACTIONS.FETCH_EXPLORATIONS,
      fetchVisualization: DATASET_ACTIONS.FETCH_VISUALIZATION,
    }),
    changedSearch(search) {
      this.search = search;
    },
    formatDate(d) {
      return d?.replace("T", " ")?.substring(0, 19) ?? "-";
    },
    getColorStyle(color) {
      return `background-color: ${color}`;
    },
    collapseRow(row, status) {
      this.$set(row, "collapseOpen", status ? false : true);
    },
    expandRow() {
      this.dataList.forEach((element) => {
        this.collapseRow(element, this.toggleRow);
      });
      this.toggleRow = !this.toggleRow;
    },
    mouseOver(value) {
      this.columnName = value;
    },
    mouseLeave () {
      this.columnName = "";
    },
  },
};
</script>

<style scoped>
.dtp-vgt-chart {
  height: 70px;
  width: 70px;
  vertical-align: middle;
  transform: translateX(30px);
}
.big-image {
  position: absolute;
  z-index: 99;
  width: 500px;
  height: 500px;
  box-shadow:0px 0px 7px 4px rgb(119 130 123 / 65%)
}
</style>
