export const datasetCol = [
    {
        label: "Data Set Name",
        field: "name",
    },
    {
        label: "Size",
        field: "size",
    },
    {
        label: "Row Count",
        field: "row",
    },
    {
        label: "Column Count",
        field: "column",
    },
    {
        label: "Creation Date",
        field: "created_at",
    },
    {
        label: "User",
        field: "username",
    },
    {
        label: "Visualization Status",
        field: "visualization_status",
    },
    {
        label: "Actions",
        field: "actions",
        sortable: false,
    },
]