<template>
  <div>
    <model-results-main :goBackAvailable="false" />
  </div>
</template>

<script>
import ModelResultsMain from "./../ModelResultsMain/ModelResultsMain.vue"

export default {
  components: {
    ModelResultsMain,
  },
};
</script>