import Vue from "vue";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";
import { objectToQueryString } from "../../../../api/AutoMlServices/utils";
import {
  DATASOURCE_ACTIONS,
  DATASOURCE_GETTERS,
  DATASOURCE_MUTATIONS,
} from "./type";

export const dataSourceStore = {
  state: {
    dataSources: [],
  },
  getters: {
    [DATASOURCE_GETTERS.GET_DATASOURCE]: (state) => {
      return state.dataSources;
    },
  },
  mutations: {
    [DATASOURCE_MUTATIONS.SET_DATASOURCE]: (state, val) => {
      Vue.set(state, "dataSources", val);
    },
  },
  actions: {
    [DATASOURCE_ACTIONS.FETCH_DATASOURCE]: async (
      { commit },
      { requestComp, ...others }
    ) => {
      const queryParam = objectToQueryString(others);
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.dataSource,
        serviceMethodSub: serviceMethodSub.fetchDataSource,
        queryParam,
        loadingComponent: requestComp,
        successMutation: DATASOURCE_MUTATIONS.SET_DATASOURCE,
        resultSelector: (result) => result.data?.data || [],
      });
    },
    [DATASOURCE_ACTIONS.DELETE_DATASOURCE]: async (
      { commit },
      { requestComp, id }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.dataSource,
        serviceMethodSub: serviceMethodSub.deleteDataSource,
        queryParam: id,
        loadingComponent: requestComp,
      });
    },
    [DATASOURCE_ACTIONS.CREATE_DATASOURCE]: async ({ commit }, params) => {
      const { requestComp, ...otherParams } = params;
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.dataSource,
        serviceMethodSub: serviceMethodSub.createDataSource,
        bodyParam: otherParams,
        loadingComponent: requestComp,
      });

      return result;
    },
    [DATASOURCE_ACTIONS.UPLOAD_RDB_PREVIEW]: async ({ commit }, params) => {
      const { requestComp, ...otherParams } = params;
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.dataSource,
        serviceMethodSub: serviceMethodSub.uploadRdbPreview,
        bodyParam: otherParams,
        loadingComponent: requestComp,
      });
    },
    [DATASOURCE_ACTIONS.UPLOAD_RDB]: async (
      { commit },
      { requestComp, data }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.dataSource,
        serviceMethodSub: serviceMethodSub.uploadRdb,
        bodyParam: data,
        loadingComponent: requestComp,
      });
    },
  },
};
