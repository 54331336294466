export const customModelsColDef = [
    {
        label: "Model Name",
        field: "name",
    },
    {
        label: "Dataset Name",
        field: "datasetName",
    },
    {
        label: "Description",
        field: "description",
    },
    {
        label: "Creation Date",
        field: "createdTime",
    },
    {
        label: "Status",
        field: "status",
    },
    {
        label: "Actions",
        field: "actions",
        sortable: false,
    },
]