<template>
  <el-tooltip :content="$t('Refresh Table')" placement="top" effect="light">
    <button
      class="aml-btn eds-button.primary dtp-ml--2"
      :disabled="loading"
      @click="refreshTable()"
    >
      <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
    </button>
  </el-tooltip>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
