// Bu mixin VUE_APP_AUTOML_ENVIRONMENT env değişkenine göre component gizlememize yardımcı olacak
import { HiddenComponentsByEnv } from "../../../componentVisualizationConfig.js"
import { PRIVILEGES } from "../../helper/authorization.js";
import privileges from "../privileges.js";

export default {
    mixins: [
        privileges,
    ],
    methods: {
        /**
         * 
         * @param {*} componentName 
         */
        isComponentAvailable(componentName) {
            const roles = this.$store.getters["Security/Get/Keycloak/Roles"];
            if (roles.includes("Logo_Role") && componentName === PRIVILEGES.AUTOML_CUSTOMER_LOGO) {
                return true;
            }

            const privilegeCheck = this.$store.getters["Security/Get/Keycloak/Privileges"].includes(componentName);
            const environmentCheck = HiddenComponentsByEnv[process.env.VUE_APP_AUTOML_ENVIRONMENT].includes(componentName);

            return !environmentCheck && privilegeCheck
        }
    },
};
