<template>
  <div>
    <div v-if="!isNullOrUndefined(benchmark)">
      {{ benchmarkFormatted }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    benchmark: {
      type: Number,
    },
  },
  computed: {
    benchmarkFormatted() {
      return this.benchmark ?? 0;
    },
  },
  methods: {
    isNullOrUndefined(variable) {
      return typeof variable === "undefined" || variable === null;
    },
  },
};
</script>