<template>
  <div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">
        {{ $t("dataSource.Data Source Name") }}
      </div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          type="text"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
          v-model="formDataLocal.name"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">
        {{ $t("dataSource.Data Source Type") }}
      </div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <select
          v-model="formDataLocal.type"
          class="eds-input_#input eds-input_#input.dropdown"
        >
          <option value="" disabled>
            {{ $t("dataSource.Please Choose a Data Source Type") }}
          </option>
          <option v-for="item in options" :key="item.value" :value="item.value">
            {{ $t(item.label) }}
          </option>
        </select>
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">{{ $t("user.Username") }}</div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          v-model="formDataLocal.username"
          type="text"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">{{ $t("user.Password") }}</div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          v-model="formDataLocal.password"
          type="password"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">{{ $t("dataSource.Host") }}</div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          v-model="formDataLocal.host"
          type="text"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">{{ $t("dataSource.Port") }}</div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          v-model="formDataLocal.port"
          type="text"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20">
      <div class="eds-field_#label">
        {{ $t("dataSource.DSN Service Name") }}
      </div>
      <div class="eds-field_#control eds-field_.eds-input eds-input">
        <input
          v-model="formDataLocal.serviceName"
          type="text"
          :placeholder="$t('placeholder.Type Here')"
          class="eds-input_#input"
        />
      </div>
    </div>
    <div class="dtp-chart-box dtp-chart-box-20 dtp-text--right">
      <button
        :class="{
          'eds-button-error': errorTestConnection,
        }"
        class="eds-button eds-button.basic dtp-mr--2"
        @click="testConnection(formDataLocal)"
      >
        <img
          v-if="successTestConnection"
          src="../../../../assets/images/icons/automljdbctestcheckicon.svg"
          alt="Success"
        />
        <img
          v-if="errorTestConnection"
          src="../../../../assets/images/icons/automljdbctesterroricon.svg"
          alt=""
        />
        {{ $t("dataSource.Test Connection") }}
      </button>
      <button
        :disabled="!saveBtn"
        class="eds-button eds-button.basic"
        @click="save(formDataLocal)"
      >
        {{ $t("commons.save") }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { areFieldsValid, trimFormData } from "../../../..//helper/validation";
import notify from "../../../../mixins/AutoML/notify";
import { DATASOURCE_ACTIONS } from "../../../../store/modules/AutoML/DataSource/type";

export default {
  mixins: [notify],
  props: {
    formData: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errorTestConnection: false,
      successTestConnection: false,
      saveBtn: false,
      formDataLocal: {},
    };
  },
  mounted() {
    this.$set(this, "formDataLocal", { ...this.formData });
  },
  methods: {
    ...mapActions({
      createDataSource: DATASOURCE_ACTIONS.CREATE_DATASOURCE,
    }),
    areFieldsValid(formData, passwordRequired) {
      const requiredFields = [
        { value: "name", label: "panel.new.name" },
        { value: "type", label: "data_source.new.type" },
        { value: "userName", label: "data_source.input.user_name" },
        { value: "host", label: "data_source.input.host" },
        { value: "port", label: "data_source.input.port" },
        { value: "serviceName", label: "data_source.input.dsn_service_name" },
      ];
      if (passwordRequired) {
        requiredFields.push({
          value: "password",
          label: "data_source.input.password",
        });
      }

      return areFieldsValid(requiredFields, formData);
    },
    async save(formData) {
      const loading = this.$loading({
        lock: true,
        text: this.$i18n.t("data_source.testing"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      trimFormData(formData);
      const passwordRequired = formData.password ? true : false;
      if (this.areFieldsValid(formData, passwordRequired)) {
        let response = await this.createDataSource({
          save: true,
          ...this.formDataLocal,
          requestComp: this.requestComp,
        });
        if (response.status == 201 || response.status == 200) {
          this.formDataLocal.name = null;
          this.formDataLocal.username = null;
          this.formDataLocal.type = [];
          this.formDataLocal.password = null;
          this.formDataLocal.host = null;
          this.formDataLocal.port = null;
          this.formDataLocal.serviceName = null;
          this.$emit("editDataSource", "query-DataSource");
        }
      }
      loading.close();
    },
    async testConnection(formData) {
      const loading = this.$loading({
        lock: true,
        text: this.$i18n.t("data_source.testing"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        trimFormData(formData);
        const passwordRequired = formData.password ? true : false;
        if (this.areFieldsValid(formData, passwordRequired)) {
          let response = await this.createDataSource({
            save: false,
            ...this.formDataLocal,
            requestComp: this.requestComp,
          });
          if (response.status == 200) {
            this.saveBtn = true;
            this.successTestConnection = true;
            this.errorTestConnection = false;
          } else {
            this.saveBtn = false;
            this.successTestConnection = false;
            this.errorTestConnection = true;
          }
        }
        loading.close();
      } catch (err) {
        loading.close();
      }

      loading.close();
    },
  },
};
</script>
<style scoped>
.eds-button {
  gap: 10px;
}
</style>
