import { GENERAL_MUTATIONS } from "../General/types";
import { vuexActionHandler } from "../../../../utils/storeHelper";
import { sortControl } from "../../../../api/AutoMlServices/utils";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { EXPERIMENT_ACTIONS, EXPERIMENT_MUTATIONS, EXPERIMENT_GETTERS } from "./types";

export const experimentsStore = {
    state: {
      experiments: [],
      selectedExperiment: [],
      experimentTrainings: [],
      trainingLogs: [],
      experimentsSessionStatus: [],
      experimentsFilter: {},
      filter: {
        experiments: []
      },
    },
    getters: {
      [EXPERIMENT_GETTERS.GET_EXPERIMENTS]: (state) => {
        return state.experiments;
      },
      [EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT]: (state) => {
        return state.selectedExperiment;
      },
      [EXPERIMENT_GETTERS.GET_EXPERIMENT_TRAININGS]: (state) => {
        return state.experimentTrainings;
      },
      [EXPERIMENT_GETTERS.GET_TRAINING_LOGS]: (state) => {
        return state.trainingLogs;
      },
      [EXPERIMENT_GETTERS.GET_EXPERIMENTS_SESSION_STATUS]: (state) => {
        return state.experimentsSessionStatus;
      },
      [EXPERIMENT_GETTERS.GET_EXPERIMENTS_FILTER]: (state) => {
        return state.experimentsFilter;
      },
      [EXPERIMENT_GETTERS.GET_FILTER]: (state) => {
        return state.filter;
      },
    },
    mutations: {
      [EXPERIMENT_MUTATIONS.SET_EXPERIMENTS]: (state, val) => {
        Vue.set(state, "experiments", val);
      },
      [EXPERIMENT_MUTATIONS.SET_SELECTED_EXPERIMENT]: (state, val) => {
        Vue.set(state, "selectedExperiment", val);
      },
      [EXPERIMENT_MUTATIONS.SET_EXPERIMENT_TRAININGS]: (state, val) => {
        Vue.set(state, "experimentTrainings", val);
      },
      [EXPERIMENT_MUTATIONS.SET_TRAINING_LOGS]: (state, val) => {
        Vue.set(state, "trainingLogs", val);
      },
      [EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_SESSION_STATUS]: (state, val) => {
        Vue.set(state, "experimentsSessionStatus", val);
      },
      [EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_FILTER](state, filterObj) {
        Vue.set(state, "experimentsFilter", filterObj);
      },
      [EXPERIMENT_MUTATIONS.SET_FILTER](state, info) {
        state.filter[info.filterName] = info.filterDetail;
      },
    },
    actions: {
      [EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS]: async ({ commit }, params) => {
        const controlledParams = sortControl(params);
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getExperiments,
          queryParam: controlledParams,
          successMutation: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "experiments",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_BY_PROJECT_ID]: async ({ commit, state }, { page = 1, pid, count }) => {
        let filterText = "";
        state.filter?.experiments?.forEach(element => {
          filterText += "&" + Object.keys(element) + "=" + Object.values(element);
        });
        const queryParam = `?project_id=${pid}&page=${page}&count=${count}${filterText}`;
        
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getExperimentsByProjectId,
          queryParam,
          successMutation: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "experiments",
              pageInfo: result.data?.pagination_info,
            });
          }
        });
      },
      [EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID]: async (
        { commit },
        { experimentId, withLoading }
      ) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getExperimentsById,
          queryParam: experimentId,
          successMutation: EXPERIMENT_MUTATIONS.SET_SELECTED_EXPERIMENT,
          withLoading,
          resultSelector: (result) => result.data?.data,
        });
      },
      [EXPERIMENT_ACTIONS.FETCH_TRAININGS_BY_EXPERIMENT_ID]: async (
        { commit },
        experimentId
      ) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getAllTrainingsByExperimentId,
          queryParam: experimentId,
          successMutation: EXPERIMENT_MUTATIONS.SET_EXPERIMENT_TRAININGS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
        });
      },
      [EXPERIMENT_ACTIONS.FETCH_TRAINING_LOGS]: async (
        { commit },
        { experimentId, trainID }
      ) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getTrainingLogs,
          queryParam: { trainId: trainID, experimentId },
          successMutation: EXPERIMENT_MUTATIONS.SET_TRAINING_LOGS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
        });
      },
      [EXPERIMENT_ACTIONS.CLEAR_EXPERIMENTS]: async ({ commit }, params) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.clearExperiments,
          queryParam: params.experimentId,
          loadingComponent: params.requestComp,
        });
      },
      [EXPERIMENT_ACTIONS.FETCH_EXPERIMENTS_SESSION_STATUS]: async ({ commit }, payload) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.getExperimentSessionStatus,
          bodyParam: payload,
          successMutation: EXPERIMENT_MUTATIONS.SET_EXPERIMENTS_SESSION_STATUS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
        });
      },
      [EXPERIMENT_ACTIONS.TRAINED_STATUS_UPDATE]: async ({ commit }, { requestComp, experimentId }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.experiments,
          serviceMethodSub: serviceMethodSub.trainedStatusUpdate,
          queryParam: experimentId,
          bodyParam: {},
          loadingComponent: requestComp,
        });
      },
    }
}