import Service from "../AutomlService";
let service = new Service(process.env.VUE_APP_AUTOML_MAIN_API_ENDPOINT);
let serviceLive = new Service(process.env.VUE_APP_AUTOML_LIVE_API_ENDPOINT);


export async function createDataset(data) {
  let promise = new Promise((resolve, reject) => {
    service.post(
      `/datasets`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function deleteDataset(dataset_id) {
  let promise = new Promise((resolve, reject) => {
    service.delete(
      `datasets/${dataset_id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getExplorationAllList(experiment_id) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${experiment_id}/data/exploration`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getData(experiment_id, page = 1, count = 10) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${experiment_id}/data?page=${page}&count=${count}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function renameColumn(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experinentInfo.experiment_id}/data/columns/${data.experinentInfo.columnname}`,
      data.name,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function convertApi(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/type-conversion-temp`,
      data.convert_data_type,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function deleteColumns(data) {
  let promise = new Promise((resolve, reject) => {
    const encoded = encodeURIComponent(data.names);

    serviceLive.delete(
      `/experiments/${data.experiment_id}/data/columns?names=${encoded}&pipeline_step=${data.pipeline_step}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function missingValue(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/missing-value-temp`,
      data.fill_missing_values,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function encoding(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/encoding-temp`,
      data.encoding,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function scaling(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/scaling-temp`,
      data.scaling,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function removeNaRows(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/remove-na-rows-temp`,
      data.scaling,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function imbalanceInspection(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/data/imbalance-inspection`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function balanceConfiguration(data) {
  const { experimentId, requestComp, ...others } = data;

  //this will be removed when backend ready
  if (others.type === "undersampling") {
    others.params = { ...others.params, sampling_strategy: "majority" }
  }

  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${experimentId}/data/balance-configuration`,
      others,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function featureImportancesPost(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/data/feature-importances`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function featureImportancesGet(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${data.experiment_id}/data/feature-importances`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function dataFilter(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/data/filter?pipeline_step=${data.pipeline_step}`,
      data.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function dataFeatureGeneration(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/data/feature-generation-temp`,
      data.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function dataUpdateFeatureGeneration(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${data.experiment_id}/data/feature-generation-temp`,
      data.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function dataBinning(params) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${params.experimentId}/data/binning`,
      params.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function previewFixedWidthField(params) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${params.experimentId}/data/pre-binning-target-ratio`,
      params.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function dataSort(params) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${params.experimentId}/data/sort-temp`,
      params.payload,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getStatus(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${data.experiment_id}/status`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getOperationStatus(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${data.experiment_id}/status?operation_id=${data.operation_id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getOperationStatusById(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(
      `/experiments/${data.experiment_id}/operation/status?operation_id=${data.operation_id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function dataVersioning(data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${data.experiment_id}/data/versioning`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
