<template>
  <div class="dtp-row">
    <div
      v-loading="loading"
      :element-loading-text="$t('helper.saving_data')"
      class="dtp-col dtp-col-12 vgt-custom-colon dtp-freeze-header"
    >
      <vue-good-table
        :columns="dataColumns"
        :rows="rawData"
        mode="remote"
        @on-sort-change="(val) => $emit('onSortChange', val)"
        ><template slot="table-column" slot-scope="props">
          <span v-if="props.column.isNewCol">
            <i
              class="el-icon-setting dtp-cursor-pointer"
              @click="openFeatureGeneration(props.column.newCol)"
            ></i>
            {{ props.column.label }}
          </span>
        </template>
        <template slot="table-row"> </template>
      </vue-good-table>
      <CustomPagination
        :lastPage="lastPage"
        :pageSize="pageSize"
        :showPageSizeSelect="true"
        @update:pageSize="(size) => $emit('changePageSize', size)"
        @change="(page) => $emit('changePage', page)"
      />
    </div>
  </div>
</template>

<script>
import privileges from "../../../mixins/privileges";
import checkAuth from "../../../mixins/authorization";
import notify from "../../../mixins/AutoML/notify";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import CustomPagination from "../CustomPagination";

export default {
  methods: {
    openFeatureGeneration(newCol) {
      this.$emit("openFeatureGen", newCol);
    },
  },
  props: {
    dataColumns: {
      type: Array,
      required: true,
    },
    rawData: {
      type: Array,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
  },
  mixins: [checkAuth, privileges, notify],
  components: {
    VueGoodTable,
    CustomPagination,
  },
};
</script>

<style></style>
