<template>
  <multiselect
    v-model="selectValueHolder"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="false"
    :preserve-search="true"
    :placeholder="$t('Select Option')"
    :select-label="$t('commons.select')"
    label="label"
    track-by="key"
    openDirection="top"
  >
    <template slot="selection" slot-scope="{ values, isOpen }"
      ><span class="multiselect__single" v-if="values.length && !isOpen">{{
        getValuesAsString(values)
      }}</span></template
    >
  </multiselect>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    resetKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectValueHolder: [],
    };
  },
  watch: {
    selectValueHolder: {
      handler() {
        this.emitChanges();
      },
      deep: true,
    },
    resetKey() {
      this.resetChanges();
    },
  },
  methods: {
    resetChanges() {
      this.selectValueHolder = [];
    },
    emitChanges() {
      this.$emit(
        "inputsChanged",
        this.selectValueHolder.map((i) => i.key)
      );
    },
    getValuesAsString(values) {
      return values?.map((x) => x.label)?.join(",");
    },
  },
};
</script>