import { formatDate } from "../../../../helper/format";
import store from '../../../../store';

export const COLUMNS = [
	{
		label: "Name",
		field: "name",
		width: "200px",
		sortable: false,
	},
	{
		label: "IP",
		field: "server",
		width: "100px",
		sortable: false,
	},
	{
		label: "User",
		field: "createdBy",
		width: "150px",
		sortable: false,
	},
	{
		label: "Creation Date",
		field: "createdTime",
		formatFn: value => formatDate(
			value,
			store.getters["Settings/Get/Preference"].longDateFormat
		),
		width: "150px",
		sortable: false,
	},
	{
		label: "Domain",
		field: "host",
		width: "100px",
		sortable: false,
	},
	{
		label: "Token",
		field: "bearerToken",
		width: "200px",
		sortable: false,
	},
	{
		label: "Certificate",
		field: "caData",
		width: "200px",
		sortable: false,
	},
	{
		label: "Actions",
		field: "actions",
		sortable: false,
		width: "150px"
	},
];