import dayjs from "dayjs";
import numeral from "numeral";
import "numeral/locales";

const numberFields = [
  "long",
  "float",
  "double",
  "integer",
  "scaled_float",
  "number",
  "int",
  "numeric",
];
export const dateFormats = [
  { label: "gg.aa.yyyy", value: "DD.MM.YYYY" },
  { label: "gg/aa/yyyy", value: "DD/MM/YYYY" },
  { label: "gg-aa-yyyy", value: "DD-MM-YYYY" },

  { label: "gg.aa.yyyy ss:dd", value: "DD.MM.YYYY HH:mm" },
  { label: "gg/aa/yyyy ss:dd", value: "DD/MM/YYYY HH:mm" },
  { label: "gg-aa-yyyy ss:dd", value: "DD-MM-YYYY HH:mm" },

  { label: "gg.aa.yyyy ss:dd:sn", value: "DD.MM.YYYY HH:mm:ss" },
  { label: "gg/aa/yyyy ss:dd:sn", value: "DD/MM/YYYY HH:mm:ss" },
  { label: "gg-aa-yyyy ss:dd:sn", value: "DD-MM-YYYY HH:mm:ss" },

  { label: "yyyy.aa.gg", value: "YYYY.MM.DD" },
  { label: "yyyy/aa/gg", value: "YYYY/MM/DD" },
  { label: "yyyy-aa-gg ss:dd:sn", value: "YYYY-MM-DD HH:mm:ss" },
];

export const isNumberField = (fieldType) => {
  let index = numberFields.indexOf(fieldType);
  return index >= 0 ? true : false;
};
export function formatNumeric(num, config, fieldType, defaultFormat) {
  if (num == "Infinity") return num;
  else if (isNaN(num)) return num;
  else {
    let format, decimalLenght;
    try {
      format = config.numericFormat;
      decimalLenght = config.decimal;
    } catch (err) {
      format = defaultFormat;
      decimalLenght = "0";
    }
    if (num != "" && num != null) {
      if (!decimalLenght) decimalLenght = 0;

      if (format == "dot" || !format) format = "tr";
      else if (format == "comma") format = "en";

      let lang = format ? String(format).toLowerCase() : defaultFormat;
      numeral.locale(lang);
      const decimal = 0;
      if (fieldType && fieldType == "float") {
        return numeral(num).format(`0,${decimal.toFixed(decimalLenght)}`);
      } else {
        return Number.isInteger(num)
          ? numeral(num).format("0,0")
          : numeral(num).format(`0,${decimal.toFixed(decimalLenght)}`);
      }
    }
    return num;
  }
}

export function formatNumberFlat(num, defaultFormat) {
  numeral.locale(defaultFormat);
  return numeral(num).format("0");
}

export function numberShortener(v) {
  if (v >= 1000 && v < 1000000) {
    if (v % 1000 > 10) {
      return (
        (v / 1000)
          .toFixed(2)
          .toString()
          .replace(".", ",") + "K"
      );
    } else return (v / 1000).toFixed(0).toString() + "K";
  } else if (v >= 1000000 && v < 1000000000) {
    if (v % 1000000 > 10) {
      return (
        (v / 1000000)
          .toFixed(2)
          .toString()
          .replace(".", ",") + "M"
      );
    } else return (v / 1000000).toFixed(0).toString() + "M";
  } else if (v >= 1000000000 && v < 1000000000000) {
    if (v % 1000000000 > 10) {
      return (
        (v / 1000000000)
          .toFixed(2)
          .toString()
          .replace(".", ",") + "B"
      );
    } else return (v / 1000000000).toFixed(0).toString() + "B";
  } else if (v >= 1000000000000 && v < 1000000000000000) {
    if (v % 1000000000000 > 10) {
      return (
        (v / 1000000000000)
          .toFixed(2)
          .toString()
          .replace(".", ",") + "T"
      );
    } else return (v / 1000000000000).toFixed(0).toString() + "T";
  } else {
    if (v % 1 != 0) {
      return v
        .toFixed(2)
        .toString()
        .replace(".", ",");
    } else return v ? v.toFixed(0).toString() : v;
  }
}

export const formatDate = (stringDate, format) => {
  let result = stringDate;
  try {
    if (stringDate != "" && stringDate != null && format != "undefined") {
      result = dayjs(stringDate, { utc: true }).format(format);
      /*
            let date = new Date(stringDate)
            let dd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
            let mm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
            let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
            let hh = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(date)
            let mi = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date)
            let ss = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date)

            switch (format) {
                case "DD.MM.YYYY":
                    stringDate = `${dd}.${mm}.${year}`;
                    break;
                case "DD/MM/YYYY":
                    stringDate = `${dd}/${mm}/${year}`;
                    break;
                case "YYYY-MM-DD HH:mm:ss":
                    stringDate = `${year}-${mm}-${dd} ${hh}:${mi}:${ss}`;
                    break;
                case "YYYY.MM.DD":
                    stringDate = `${year}.${mm}.${dd}`;
                    break;
                case "YYYY/MM/DD":
                    stringDate = `${year}/${mm}/${dd}`;
                    break;
                case "DD-MM-YYYY HH:mm:ss":
                    stringDate = `${dd}-${mm}-${year} ${hh}:${mi}:${ss}`;
                    break;
                   
            }
            */
    }
  } catch (err) {
    console.log(err);
  }
  return result;
};

export const formatString = (string, format) => {
  let result = string;
  try {
    if (string != "" && string != null && format != "undefined") {
      if (format == "Uppercase") result = string.toUpperCase();
      else if (format == "Lowercase") result = string.toLowerCase();
    }
  } catch (err) {
    console.log(err);
  }
  return result;
};

export const formatBytes = (kb, decimals = 2) => {
  if (kb === 0) return '0 Kbs';
  const bytes = kb * 1024;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}