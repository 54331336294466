export const VALIDATION_DATA_TYPES = {
    TEST_DATA: "test_data",
    NEW_DATA: "new_data"
}

export const VALIDATION_STATUSES = {
    IN_PROGRESS: "IN_PROGRESS", 
    SUCCESS: "SUCCESS", 
    FAILED: "FAILED",
    FAILURE: "FAILURE"
}