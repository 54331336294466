import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLogger from 'vuejs-logger';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Vuelidate from 'vuelidate'
// import AgGridVue from '../node_modules/ag-grid-community/src/example/index'


import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/kgfd-icons.css'
/* Material Css */
import './assets/css/materialdesignicons.min.css'
/* Genel Css */
import './assets/css/datap.css'
/* CodeMirror Custom Css */
import './assets/css/codemirror-custom.css';

import '@experian/eds-styles/dist/eds-all.css'

// import '../node_modules/ag-grid-community/dist/ag-theme-eds.css'
import './assets/css/amlExperian.css'

import "splitpanes/dist/splitpanes.css";
import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);
Vue.use(Vuelidate)

import loadMore from './directive/loadMore'
Vue.use(loadMore);

import MathJax, { initMathJax, renderByMathjax } from "mathjax-vue";

function onMathJaxReady() {
  const el = document.getElementById("hello");
  renderByMathjax(el);
}

initMathJax({}, onMathJaxReady);

Vue.use(MathJax)

/* import Layout from "./components/Layout.vue"; */
import i18n from './i18n'

import Keycloak from 'keycloak-js'
import { getPrivilegesByRole, getPagePrivilegesByRole } from "./helper/authorization"
import { getLicenseInfo } from "./api/license/license";
import { MessageBox } from "element-ui";
import { getPreferenceList } from "./api/management/preference.js";
export const eventBus = new Vue();
/* Vue.component('Layout', Layout) */
Vue.config.productionTip = false

const options = {
  isEnabled: true,
  logLevel: Vue.config.productionTip ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);
Vue.use(ElementUI, { locale });


if (process.env.VUE_APP_KEYCLOAK_ENABLED === "true") {
  // Keycloak init options
  let initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_ENDPOINT,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
    onLoad: 'login-required'
  }

  let keycloak = new Keycloak(initOptions);
  Vue.prototype.$keycloak = keycloak

  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {

    if (!auth) {
      window.location.reload();
    } else {
      Vue.$log.info("Authenticated");
    }

    if (window.location.pathname !== "/") {
      /**
       * License check
       * Redirects users to login page after clicked 'OK'
       * Runs every page except home
       * Check Modules.vue as well if you have update license check
       */
      store.dispatch("Security/Action/Licenses/FetchLicenseInfo");
    }

    setKeyCloackVariables();

    //TODO will be updated removing dependency of Elastic Search
    let preferenceList = {
      "shortDateFormat": "DD.MM.YYYY",
      "longDateFormat": "DD.MM.YYYY HH:mm:ss",
      "thousandFormat": "tr",
      "decimalPlace": "2",
      "colorPalette": "#000000"
    };
    setPreferenceVariables(preferenceList);
    
    /*getPreferenceList().then(response => {
      let preferenceList = JSON.parse(JSON.parse(response.data[0].prefstate).prefstate);
      setPreferenceVariables(preferenceList);
      //  console.log("preferenceList")
      //  console.log(preferenceList)
      //  console.log("preferenceList.dateformat")
      //  console.log(preferenceList.dateFormat)
    }).catch((err) => {
      //console.log(err)
    });*/

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')

    //console.log("keycloak:", keycloak)
    //console.log("keycloak.idTokenParsed:", keycloak.idTokenParsed)
    //console.log("keycloak.idTokenParsed.preferred_username:", keycloak.idTokenParsed.preferred_username)

    const refreshInterval = (keycloak.idTokenParsed.exp - keycloak.idTokenParsed.iat) * 1000;

    setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          Vue.$log.debug('Token refreshed' + refreshed);
          setKeyCloackVariables();
        } else {
          Vue.$log.warn('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        Vue.$log.error('Failed to refresh token');
      });


    }, refreshInterval)

    function setKeyCloackVariables() {
      store.dispatch("Security/Set/Keycloak/Token", keycloak.token);
      store.dispatch("Security/Set/Keycloak/RefreshToken", keycloak.refreshToken);
      store.dispatch("Security/Set/Keycloak/Username", keycloak.idTokenParsed.preferred_username);
      store.dispatch("Security/Set/Keycloak/LogoutFunc", keycloak.logout);
      store.dispatch("Security/Set/Keycloak/Privileges", getPrivilegesByRole(keycloak.realmAccess.roles));
      store.dispatch("Security/Set/Keycloak/PagePrivileges", getPagePrivilegesByRole(keycloak.realmAccess.roles));
      store.dispatch("Security/Set/Keycloak/Roles", keycloak.realmAccess.roles);
    }
    function setPreferenceVariables(preferenceList) {
      store.dispatch("Settings/Set/Preference", preferenceList);
      store.dispatch("Settings/Set/Preference/ShortDateFormat", preferenceList.shortDateFormat);
      store.dispatch("Settings/Set/Preference/LongDateFormat", preferenceList.longDateFormat);
      store.dispatch("Settings/Set/Preference/DecimalPlace", preferenceList.decimalPlace);
      store.dispatch("Settings/Set/Preference/ThousandFormat", preferenceList.thousandFormat);
      store.dispatch("Settings/Set/Preference/ColorPalette", preferenceList.colorPalette);
    }

  }).catch(() => {
    Vue.$log.error("Authenticated Failed");
  });

} else {

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
