export const CUSTOM_MODEL_STATUSES = {
    IMPORTED: "IMPORTED",
    BUILD_FAILED: "BUILD_FAILED",
    INITIATE_FAILED: "INITIATE_FAILED",
    IMPORT_CANCELLED: "IMPORT_CANCELLED",
    VALIDATION_FAILED: "VALIDATION_FAILED",
    IMPORT_FAILED: "IMPORT_FAILED",
    BUILDING: "BUILDING",
    INITIATING: "INITIATING",
    IMPORTING: "IMPORTING",
    INITIATED: "INITIATED",
  };