export const InputTypes = {
    SelectBox: 'selectbox',
    TextField: 'textfield',
    NumberField: 'numberfield',
    NotEditableText: 'noteditabletext',
    CheckBox: 'checkbox'
}

export const Algorithms = {
    LogisticRegression: {
        id: 1,
        model: "Logistic Regression",
        name: "logistic_regression",
    },
    XGBoost: {
        id: 3,
        model: "XGBoost",
        name: "xgboost",
    },
    RandomForest: {
        id: 4,
        model: "Random Forest",
        name: "random_forest",
    },
    LinearRegression: {
        id: 5,
        model: "Linear Regression",
        name: "linear_regression",
    },
    Kmeans: {
        id: 6,
        model: "Kmeans",
        name: "kmeans",
    },
    DBScan: {
        id: 7,
        model: "DBScan",
        name: "dbscan",
    },
    CatBoost: {
        id: 8,
        model: "CatBoost",
        name: "catboost",
    },
    LightGBM: {
        id: 9,
        model: "LightGBM",
        name: "light_gbm",
    },
}