<template>
  <div class="dtp-section">
      <div class="dtp-row">
        <div class="aml-section-header dtp-align-center">
          <div class="dtp-panel-formbox">
            <div class="dtp-page-title">
              <span>
                <Icon icon="dataset" size="xl"></Icon>
              </span>
              {{ $t("Data Sets") }}
            </div>
          </div>
          <div class="dtp-panel-formbox">
            <el-tooltip
              :content="$t('tooltip.addDataSet')"
              placement="top"
              effect="light"
            >
              <button
                @click="datasetCreate = !datasetCreate"
                class="aml-icon-btn"
              >
                <Icon icon="add"></Icon>
              </button>
            </el-tooltip>
            <DataSetCreate
              v-if="isAddDatasetButtonAvailable && datasetCreate"
              @closePopup="datasetCreate = $event"
            />
          </div>
          <!-- CREATE -->
          <PageViewBar
            :title="''"
            :activeFilters="true"
            :activeSearch="true"
            :tooltipSearchName="'Data Set'"
            :headerArray="filterArray"
            helperLink="list-datasets"
            @filterSaved="filterSaved"
            @deleteFiltered="deleteFilter"
            :filterList="filterList"
            :createBtn="false"
            :calculateview="false"
            @changedSearch="changedSearch"
          >
          <el-tooltip 
            :content="$t('Refresh Table')"
            placement="top" 
            effect="light"
            slot="bar-right">
            <button
              class="aml-btn eds-button.primary"
              :loading="loading"
              @click="init()"
            >
              <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
            </button>
          </el-tooltip>
          </PageViewBar>
        </div>
      </div>


    <div class="dtp-row">
      <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="enhancedDataSetList"
          @on-sort-change="(params) => onSortChange(params, init)"
        >
          <template slot="table-column" slot-scope="props">
            <span>{{ $t("table.header." + props.column.label) }}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'size'">
              <span>
                {{ formatSize(props.row.size) }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'actions'">
              <el-tooltip
                v-if="props.row.status == 'Processing'"
                :content="$t('Data is loading')"
                placement="top"
                effect="light"
              >
                <i class="el-icon-loading" aria-hidden="true"></i>
              </el-tooltip>
              <el-tooltip
                v-if="isVisualizeDatasetButtonAvailable && props.row.status != 'Processing'"
                :content="$t('Visualize Dataset')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/automlwindowicon.svg"
                  alt="visualize"
                  @click="getVisualizeByDatasetId(props.row.id, props.row.name, props.row.size)"
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                />
              </el-tooltip>
              <el-tooltip
                v-if="isDeleteDatasetButtonAvailable && props.row.status != 'Processing'"
                :content="$t('Delete Dataset')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/automldeleteicon.svg"
                  @click.prevent="
                    deleteDataset({
                      requestComp: requestComp,
                      id: props.row.id,
                      page: currentPage,
                      count: pageSize,
                    })
                  "
                  class="dtp-cursor-pointer dtp-vgt-actions-icon dtp-mr--0"
                  alt="deleteIcon"
                />
              </el-tooltip>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>

        <DataSetPopup
          v-if="ready"
          :visualizeList="visualizeList"
          :datasetName="dsName"
          :size="selectedDatasetSize"
          @closePopup="ready = $event"
        />
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          :totalItem="totalItem"
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import DataSetPopup from "./DataSetPopup.vue";
import DataSetCreate from "./Components/DataSetCreate.vue";
import privileges from "../../../mixins/privileges";
import checkAuth from "../../../mixins/authorization";
import { datasetCol } from "../Services/data/dataset";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import preferences from "../../../mixins/AutoML/preferences";
import { formatDate, formatBytes } from "../../../helper/format";
import CustomPagination from "../CustomPagination";
import PageViewBar from "../Helper/Pageviewbar.vue";
import remoteDataSort from "../../../mixins/AutoML/remoteDataSort";
import helperLink from "../../../mixins/AutoML/helperLink";
import { serviceMethodSub } from '../../../api/ApiConstants';
import { PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';
import { DATASET_ACTIONS, DATASET_GETTERS, DATASET_MUTATIONS } from '../../../store/modules/AutoML/Dataset/types';
import Icon from "../../Icons/Icon.vue";
import componentAvailabilityCheck from '../../../mixins/AutoML/componentAvailabilityCheck';
import { PRIVILEGES } from '../../../helper/authorization';

export default {
  mixins: [
    checkAuth,
    privileges,
    notify,
    helperLink,
    preferences,
    remoteDataSort,
    componentAvailabilityCheck
  ],
  data() {
    return {
      selectedDatasetSize: null,
      pageSize: 20,
      currentPage: 1,
      filterPopup: false,
      columns: datasetCol,
      ready: false,
      datasetCreate: false,
      dsName: "",
      searchName: "",
      filterArray: [],
      filterables: [
        { label: "Data Set Name", key: "name", type: "input" },
        { label: "Size", key: "size", type: "input" },
        { label: "Row Count", key: "row", type: "input" },
        { label: "Column Count", key: "column", type: "input" },
        { label: "Creation Date", key: "created_at", type: "input" },
        { label: "User", key: "username", type: "input" },
        {
          label: "Status",
          key: "status",
          type: "select",
          values: ["READY", "PROCESSING"],
        },
      ],
    };
  },
  components: {
    VueGoodTable,
    DataSetPopup,
    DataSetCreate,
    CustomPagination,
    PageViewBar,
    Icon
  },
  mounted() {
    this.requestComp = "datasetList";
    this.init();
    this.initFilterArray();
  },
  watch: {
    pageSize: {
      handler(val) {
        this.fetchDatasets({
          page: this.currentPage,
          count: val,
        });
      },
    },
    apiResponse: {
      deep: true,
      handler(val) {
        this.ready =
          val.type === "success" && val.serviceType === serviceMethodSub.getVisualization ? true : false;
      },
    },
  },
  methods: {
    ...mapActions({
      setDatasetName: DATASET_ACTIONS.SET_DATASET_NAME,
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
      deleteDatasetById: DATASET_ACTIONS.DELETE_DATASET_BY_ID,
      fetchVisualization: DATASET_ACTIONS.FETCH_VISUALIZATION,
    }),
    ...mapMutations({
      setVisualize: DATASET_MUTATIONS.SET_VISUALIZATION,
      setDatasetsFilter: DATASET_MUTATIONS.SET_DATASETS_FILTER,
    }),
    async init() {
      const pageObject = {
        page: this.currentPage,
        count: this.pageSize,
        name: this.searchName,
        ...this.sortConfig,
        ...this.datasetsFilter,
      };
      await this.fetchDatasets(pageObject);
    },
    initFilterArray() {
      const alreadyFiltered = Object.keys(this.datasetsFilter);
      this.filterArray = this.filterables.map((fs) => {
        let x = {
          isActive: false,
          data: "",
          operator: "",
        };

        if (alreadyFiltered.includes(fs.key)) {
          x = {
            isActive: true,
            data: this.datasetsFilter[fs.key],
            operator: fs.type === "input" ? "equal_input" : "equal_select",
          };
        }

        return {
          alias: fs.label,
          name: fs.key,
          type: fs.type,
          isShow: true,
          values: fs.values,
          ...x,
        };
      });
    },
    filterSaved(val) {
      var filter = val.filter((x) => x.data && x.operator);
      if (filter.length > 0) {
        const otherFilters = {};
        val
          .filter((v) => v.isActive)
          .forEach((af) => {
            otherFilters[af.name] = af.data;
          });
        this.setDatasetsFilter(otherFilters);
        this.currentPage = 1;
        this.init();
      } else {
        this.$alert("Please enter a search condition!", "Warning", {
          confirmButtonText: "OK",
        });
      }
    },
    deleteFilter(value) {
      const x = Object.assign({}, this.datasetsFilter);
      delete x[value.column_name];
      this.setDatasetsFilter(x);
      this.filterArray.forEach((element) => {
        if (element.name == value.column_name) {
          element.operator = "";
          element.data = "";
          element.isActive = false;
        }
      });
      this.currentPage = 1;
      this.init();
    },
    formatSize(size) {
      return formatBytes(size);
    },
    changePage(page) {
      const pageObject = {
        page: page,
        count: this.pageSize,
        name: this.searchName,
        ...this.sortConfig,
        ...this.datasetsFilter,
      };
      this.fetchDatasets(pageObject);
      this.currentPage = page;
    },
    getVisualizeByDatasetId(datasetId, dsName,size) {
      this.dsName = dsName;
      this.selectedDatasetSize = size;
      this.setVisualize([]);
      this.fetchVisualization({
        requestComp: this.requestComp,
        datasetId,
        visualizationType: "EDA",
      });
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.init();
    },
    /*     add(data) {
      let experimentModel = {
        projectName: this.selectedProject.name,
        name: "",
        datasetname: data.name,
        target: "",
        performanceMetric: "",
        status: "",
        scorer: "",
        trainscore: null,
        testscore: null,
        totaltime: null,
        createdUser: "",
      };
      this.addExperiments(experimentModel);
      this.setDatasetName(data.name);
      this.$store.dispatch(types.ACTION_SET_USER_DATASETS, data);
      this.$router.push({
        name: "ProjectDetails",
        query: { name: this.selectedProject.name },
      });
    }, */
    async deleteDataset(dataset) {
      await this.$confirm(
        this.$t("confirmation.deletionWarning", ["Data Set"]),
        this.$t("confirmation.Delete Data Set"),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      await this.deleteDatasetById(dataset);
    },
  },
  computed: {
    ...mapGetters({
      dataSetList: DATASET_GETTERS.GET_DATASETS,
      selectedProject: PROJECT_GETTERS.GET_SELECTED_PROJECT,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      visualization: DATASET_GETTERS.GET_VISUALIZATION,
      datasetsFilter: DATASET_GETTERS.GET_DATASETS_FILTER,
    }),
    filterList() {
      let list = [];
      Object.keys(this.datasetsFilter).forEach((mdf) => {
        list.push({
          column_name: mdf,
          conditional_operator: "equal",
          value: this.datasetsFilter[mdf],
        });
      });
      return list;
    },
    visualizeList() {
      const initialList = {
        DISTRIBUTION: {
          title: "Distribution Plots For Numeric Columns",
          items: [],
        },
        VIOLIN: {
          title: "Violin Plots For Numeric Columns",
          items: [],
        },
        COUNT: {
          title: "Count Plots For Categoric Columns",
          items: [],
        },
        HEATMAP: {
          title: "Correlation Matrix",
          items: [],
        },
      };

      this.visualization.forEach((v) => {
        initialList[v.plot_type].items.push({
          column_name: v.column_name,
          plot_path: v.plot_path,
        });
      });

      return initialList;
    },
    userPrivileges: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Privileges"];
      },
    },
    enhancedDataSetList: {
      get() {
        //For prevent store mutate problem
        let list = JSON.parse(JSON.stringify(this.dataSetList));
        list.map((obj) => {
          const lower = obj.status.toLowerCase();
          obj.status = obj.status.charAt(0).toUpperCase() + lower.slice(1);
          obj.created_at = formatDate(
            obj.created_at,
            this.preferences.longDateFormat
          );
        });
        return list;
      },
    },
    lastPage() {
      return this.pagination.datasets?.last_page ?? 1;
    },
    totalItem() {
      return this.pagination.datasets?.total_item;
    },
    isAddDatasetButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_ADD_DATASET_BUTTON);
    },
    isVisualizeDatasetButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_VISUALIZE_DATASET_BUTTON);
    },
    isDeleteDatasetButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_DELETE_DATASET_BUTTON);
    },
  },
};
</script>

<style scoped></style>
