<template>
  <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
    <vue-good-table :columns="columns" :rows="rows" class="vgt-cursor-cell">
      <template slot="table-column" slot-scope="props">
        <span>{{ $t("table.header." + props.column.label) }}</span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <el-tooltip v-if="isEditClusterButtonAvailable" :content="$t('Edit Cluster')" placement="top" effect="light">
            <img
              v-if="!editState"
              @click.stop="editState = true"
              src="../../../assets/images/icons/automl-edit-icon.svg"
              class="dtp-vgt-actions-icon"
              :class="'dtp-cursor-pointer'"
              alt="editIcon"
            />
            <span
              v-else
              class="dtp-cursor-pointer dtp-font-bold dtp-span dtp-mr--1"
              @click.stop="updateFields(props.row)"
              >{{ $t("alert.ok") }}</span
            >
          </el-tooltip>
          <el-tooltip
            v-if="isDeleteClusterButtonAvailable"
            :content="$t('Delete Cluster')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../assets/images/icons/automldeleteicon.svg"
              @click.stop="$emit('onDeleteCluster', props.row.id)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
            />
          </el-tooltip>
          <el-tooltip
            v-if="isRepairClusterButtonAvailable && props.row.needsRepair"
            :content="$t('Repair Cluster')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../assets/images/icons/repair.svg"
              @click.stop="$emit('onRepairCluster', props.row.id)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
            />
          </el-tooltip>
        </span>
        <span v-else-if="props.column.field == 'bearerToken'">
          <input
            class="dtp-vgt-table-form-input dtp-input"
            type="text"
            :placeholder="$t('placeholder.Type Here')"
            :class="{
              'dtp-vgt-table-form-input-active': editState,
            }"
            :disabled="!editState"
            v-model="props.row.bearerToken"
            required
            @click.stop
          />
        </span>

        <span v-else-if="props.column.field == 'caData'"
          ><input
            class="dtp-vgt-table-form-input dtp-input"
            type="text"
            :placeholder="$t('placeholder.Type Here')"
            :class="{
              'dtp-vgt-table-form-input-active': editState,
            }"
            :disabled="!editState"
            v-model="props.row.caData"
            required
            @click.stop
          />
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { PRIVILEGES } from '../../../helper/authorization';
import componentAvailabilityCheck from '../../../mixins/AutoML/componentAvailabilityCheck';

export default {
  components: {
    VueGoodTable,
  },
  mixins: [componentAvailabilityCheck],
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editState: false,
    };
  },
  computed: {
    isEditClusterButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_EDIT_CLUSTER);
    },
    isDeleteClusterButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_DELETE_CLUSTER);
    },
    isRepairClusterButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_REPAIR_CLUSTER);
    },
  },
  methods: {
    updateFields(r) {
      const { id, bearerToken, caData } = r;
      this.$emit("onUpdateCluster", { cluster_id: id, bearerToken, caData });
      this.editState = false;
    },
  },
};
</script>

<style scoped>
::v-deep .vgt-table tbody tr td {
  vertical-align: middle;
}
</style>
