import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { APPLICATION_ACTIONS, APPLICATION_GETTERS, APPLICATION_MUTATIONS } from "./types";
import { GENERAL_MUTATIONS } from "../General/types";

export const applicationStore = {
    state: {
      applications: [],
    },
    getters: {
      [APPLICATION_GETTERS.GET_APPLICATIONS]: (state) => {
        return state.applications;
      },
    },
    mutations: {
      [APPLICATION_MUTATIONS.SET_APPLICATIONS](state, val) {
        Vue.set(state, "applications", val);
      },
    },
    actions: {
      [APPLICATION_ACTIONS.BUILD_APPLICATION]: async ({ commit }, params) => {
        const { requestComp, app_id, ...otherParams } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.applications,
          serviceMethodSub: serviceMethodSub.buildApplication,
          queryParam: app_id,
          bodyParam: otherParams,
          loadingComponent: requestComp
        });
      },
      [APPLICATION_ACTIONS.CREATE_APPLICATION]: async ({ commit }, params) => {
        const { requestComp, ...otherParams } = params;
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.applications,
          serviceMethodSub: serviceMethodSub.createApplication,
          bodyParam: otherParams,
          loadingComponent: requestComp
        });
  
        return result && true;
      },
      [APPLICATION_ACTIONS.FETCH_APPLICATIONS]: async ({ commit }, data) => {
        const { page = 1, count = 100, ...others } = data;
        const urlSearchParams = new URLSearchParams({ page, count, ...others })
  
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.applications,
          serviceMethodSub: serviceMethodSub.fetchApplications,
          queryParam: urlSearchParams.toString(),
          successMutation: APPLICATION_MUTATIONS.SET_APPLICATIONS,
          withSuccessNotify: false,
          resultSelector: (result) => result.data?.data,
          successCallback: (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "applications",
              pageInfo: result?.data?.paginationInfo,
            });
          }
        });
      },
      [APPLICATION_ACTIONS.DELETE_APPLICATION]: async ({ commit }, params) => {
        const { requestComp, app_id } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.applications,
          serviceMethodSub: serviceMethodSub.deleteApplication,
          queryParam: app_id,
          loadingComponent: requestComp,
        });
      },
    }
}