export const domain = "AUTOML/TAG";

export const TAG_ACTIONS = {
  FETCH_TAGS: `${domain}/fetchTags`,
  DELETE_TAG: `${domain}/deleteTag`,
  CREATE_TAG: `${domain}/createTag`,
  UPDATE_TAG: `${domain}/updateTag`,
};

export const TAG_MUTATIONS = {
  SET_TAGS: `${domain}/setTags`,
};

export const TAG_GETTERS = {
  GET_TAGS: `${domain}/getTags`,
}