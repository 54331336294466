<template>
  <div v-if="visibleDropdownKeys.length">
    <el-dropdown trigger="click" class="dtp-item-center margin-top-8">
      <el-tooltip :content="$t('ModelReport')" placement="top" effect="light">
        <Icon icon="modelReport" :class="modelReportActionImage" size="xl" />
      </el-tooltip>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="item in visibleDropdownKeys" :key="item.label">
          <div @click="item.onClick(row)">{{ $t(`${item.label}`) }}</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { modelStatus } from "./modelTypes";
import { PRIVILEGES } from "../../../helper/authorization";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import Icon from "../../Icons/Icon.vue";
import { VALIDATION_STATUSES } from "../../../models/modelGovernance";

export default {
  mixins: [componentAvailabilityCheck],
  components: {
    Icon,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visibleDropdownKeys: [],
      dropdownItems: [
        {
          label: "Generate Model Report",
          onClick: (row) => {
            this.modelReportGeneration(row);
          },
        },
        {
          label: "Generate Validation Report",
          onClick: (row) => {
            this.$emit("generateValidationReportClicked", row);
          },
        },
        {
          label: "Generating Validation Report",
          onClick: (row) => {
            this.$emit("generatingValidationReportClicked", row);
          },
        },
        {
          label: "Failed Validation Report",
          onClick: (row) => {
            this.$emit("showValidationReportClicked", row);
          },
        },
        {
          label: "Show Validation Report",
          onClick: (row) => {
            this.$emit("showValidationReportClicked", row);
          },
        },
      ],
    };
  },
  mounted() {
    this.setVisibleDropdownKeys(this.row);
  },
  watch: {
    row: {
      handler(val) {
        this.setVisibleDropdownKeys(val);
      },
      deep: true,
    },
  },
  computed: {
    hasValidationStatusInProgressAndTestData() {
      return this.row?.validations?.find(
        (x) =>
          x.validation_status === VALIDATION_STATUSES.IN_PROGRESS &&
          x.validation_type === "test_data"
      );
    },
    hasValidationStatusFailureAndTestData() {
      return this.row?.validations?.find(
        (x) =>
          x.validation_status === VALIDATION_STATUSES.FAILURE &&
          x.validation_type === "test_data"
      );
    },
    hasValidationStatusSuccessAndTestData() {
      return this.row?.validations?.find(
        (x) =>
          x.validation_status === VALIDATION_STATUSES.SUCCESS &&
          x.validation_type === "test_data"
      );
    },
    isValidationReportOnlySuccess() {
      return (
        this.hasValidationStatusSuccessAndTestData &&
        !this.hasValidationStatusFailureAndTestData &&
        !this.hasValidationStatusInProgressAndTestData
      );
    },
    isValidationReportOnlyFailed() {
      return (
        this.hasValidationStatusFailureAndTestData &&
        !this.hasValidationStatusSuccessAndTestData &&
        !this.hasValidationStatusInProgressAndTestData
      );
    },
    isValidationReportFailedAndSuccess() {
      return (
        this.hasValidationStatusFailureAndTestData &&
        this.hasValidationStatusSuccessAndTestData
      );
    },
    interpretInvisibleAlgorithmCodes() {
      return [300, 301];
    },
    isModelGovernanceValidationReportAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_REPORTS_BUTTON
      );
    },
    modelReportActionImage() {
      if (
        this.isValidationReportOnlySuccess ||
        this.isValidationReportFailedAndSuccess
      ) {
        return "green";
      } else if (this.isValidationReportOnlyFailed) {
        return "red";
      } else {
        return "primary";
      }
    },
  },

  methods: {
    async modelReportGeneration(data) {
      if (data.experiment_status == modelStatus.Completed) {
        if (data.report_path) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.download =
            data.experiment_id +
            "_" +
            data.model +
            "_" +
            data.creation_date +
            ".docx";
          a.href = data.report_path;
          a.click();
        }
      }
    },
    setVisibleDropdownKeys(row) {
      const visible = [];

      if (row.report_path) {
        visible.push("Generate Model Report");
      }

      if (
        !this.interpretInvisibleAlgorithmCodes.includes(row.algorithm_code) &&
        this.isModelGovernanceValidationReportAvailable
      ) {
        if (row?.validations && row.validations.length) {
          if (
            this.isValidationReportOnlySuccess ||
            this.isValidationReportFailedAndSuccess
          ) {
            visible.push("Show Validation Report");
          } else {
            visible.push("Generate Validation Report");
          }
        } else {
          if (this.row.isLoadingState) {
            visible.push("Generating Validation Report");
          } else {
            visible.push("Generate Validation Report");
          }
        }
        if (this.isValidationReportOnlyFailed)
          visible.push("Failed Validation Report");
      }

      this.visibleDropdownKeys = this.dropdownItems.filter((d) =>
        visible.includes(d.label)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.green {
  color: var(--color-green);
}
.red {
  color: var(--color-red);
}
.primary {
  color: var(--color-primary);
}
.margin-top-8 {
  margin-top: 8px;
}
</style>
