<template>
  <div
    @mouseover="panelWidthControl()"
    class="dtp-section"
  >
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="aml-section-header aml-p-0">
            <PageViewBar
              :title="$t('automl.missingValueTitle')"
              :createBtn="false"
              :activeFilters="true"
              :deletableFilter="false"
              :activeSearch="false"
              :filterList="filterHistory"
              :headerArray="filterArray"
              @filterSaved="filterSaved"
            />
          </div>

          <data-preparation-table
            v-loading="loading"
            :dataColumns="dataColumns"
            :rawData="rawData"
            :lastPage="lastPage"
            :pageSize="pageSize"
            :isPaginationVisible="isPaginationVisible"
            @changePage="changePage"
            @changePageSize="changePageSize"
            @onSortChange="(val) => onSortChange(experimentId, val)"
          />
        </div>
      </pane>

      <pane min-size="20">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="aml-section-header">
            <div>
                <div
                  v-if="optionsRows.length > 0"
                  class="eds-field_#control eds-field_.eds-input eds-input"
                >
                  <select
                    v-model="selectedColumnType"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="onBulkSelectedColumnTypeChanged()"
                  >
                    <option value="" disabled>
                      {{ $t("automl.chooseDataColumnType") }}
                    </option>
                    <option
                      :value="item.value"
                      v-for="item in columnTypes"
                      :key="item.id"
                    >
                      {{ $t("experiment." + item.label) }}
                    </option>
                  </select>
                </div>
            </div>
            <div>
                <div
                  v-if="
                    selectedColumnType &&
                    selectedColumnType != 'None' &&
                    optionsRows.length > 0
                  "
                  class="eds-field_#control eds-field_.eds-input eds-input"
                >
                  <select
                    v-model="selectedBulk"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="onBulkSelectedMethodChanged()"
                  >
                    <option value="" selected disabled> {{ $t("Choose a Method") }} </option>
                    <option
                      :value="item.value"
                      v-for="item in bulkActionsList"
                      :key="item.id"
                    >
                      {{ $t("experiment." + item.label) }}
                    </option>
                  </select>
                </div>
            </div>
            <div>
                <div
                  v-if="selectedBulk == 'Constant' && optionsRows.length > 0"
                  class="
                    eds-field_#control eds-field_.eds-input
                    eds-input
                    dtp-display-inline-block
                  "
                >
                  <input
                    type="text"
                    placeholder="999"
                    class="eds-input_#input"
                    v-model="allConstantValue"
                    @keyup="
                      (x) => onBulkSelectedConstantChanged(x.target.value)
                    "
                  />
                </div>
            </div>
            <div class="aml-horizontal-flex-end">
                <button
                  v-if="optionsRows.length > 0"
                  @click.stop="applyChanges()"
                  class="eds-button eds-button.basic"
                  :disabled="!canApplyChanges"
                >
                  {{ $t("automl.applyChanges") }}
                </button>
                <RefreshTable
                  :loading="loading"
                  @refreshTable="refreshTable"
                />
            </div>
          </div>
          <div class="dtp-row">
            <div class="dtp-col dtp-freeze-header" v-loading="loading">
              <vue-good-table
                ref="MyCoolTable"
                :columns="optionsColumn"
                :rows="optionsRows"
                :class="{ 'vgt-custom-checkbox-column': (optionsRows || []).length }"
              >
                <template slot="table-row" slot-scope="props">
                  <label v-if="props.column.field == 'check'" class="eds-checkbox">
                    <input
                      type="checkbox"
                      :value="props.row.check"
                      v-model="props.row.check"
                      @change="resetValue(props.row.index)"
                      :disabled="props.row.fillMissingValue == ''"
                    />
                  </label>
                  <span
                    class="aml-table-row-radioInput"
                    v-else-if="props.column.field == 'missingValue'"
                  >
                    <label
                      v-if="props.formattedRow.type == 'Numeric'"
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.fillMissingValue"
                        value="Mean"
                        class="eds-radio_#input"
                        v-bind:disabled="!props.row.isMissing"
                        @click="
                          (e) => checkRadio(props.row.index, e.target.value)
                        "
                      />
                      <span class="eds-radio_#label">
                        {{ $t("automl.mean") }}({{
                          props.formattedRow.missingValue.mean
                        }})</span
                      >
                    </label>
                    <label
                      v-if="props.formattedRow.type == 'Numeric'"
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.fillMissingValue"
                        value="Median"
                        class="eds-radio_#input"
                        v-bind:disabled="!props.row.isMissing"
                        @click="
                          (e) => checkRadio(props.row.index, e.target.value)
                        "
                      />
                      <span class="eds-radio_#label">
                        {{ $t("automl.median") }}({{
                          props.formattedRow.missingValue.median
                        }})</span
                      >
                    </label>

                    <label
                      v-else-if="props.formattedRow.type == 'Categorical'"
                      class="eds-radio"
                    >
                      <input
                        type="radio"
                        v-model="props.row.fillMissingValue"
                        value="Most Frequent Value"
                        class="eds-radio_#input"
                        v-bind:disabled="!props.row.isMissing"
                        @click="
                          (e) => checkRadio(props.row.index, e.target.value)
                        "
                      />
                      <span class="eds-radio_#label">
                        {{ $t("automl.mostFrequentValue") }}({{
                          props.formattedRow.missingValue.most.value
                        }})</span
                      >
                    </label>

                    <label class="eds-radio">
                      <input
                        type="radio"
                        v-model="props.row.fillMissingValue"
                        value="Constant"
                        class="eds-radio_#input"
                        v-bind:disabled="!props.row.isMissing"
                        @click="
                          (e) => checkRadio(props.row.index, e.target.value)
                        "
                      />
                      <span class="eds-radio_#label">{{
                        $t("automl.constant")
                      }}</span>
                    </label>
                    <div
                      v-if="props.row.fillMissingValue == 'Constant'"
                      class="
                        eds-field_#control eds-field_.eds-input
                        eds-input
                        dtp-display-inline-block
                      "
                    >
                      <input
                        type="text"
                        placeholder="999"
                        v-model="props.row.constantvalue"
                        class="eds-input_#input"
                        @change="
                          () =>
                            setConstant(
                              props.row.index,
                              props.row.constantvalue
                            )
                        "
                      />
                    </div>
                  </span>

                  <span v-else-if="props.column.field == 'missing'">
                    <el-tooltip
                      :content="$t('x out of y', [props.row.isMissing ?? 0, totalItem])"
                      placement="top"
                      effect="light"
                    >
                      <div class="container">
                        <div
                          class="percentage"
                          :style="`width: ${calculateMissingRatio(props.row)}%`"
                        ></div>
                        <div class="text-in-progress">
                          {{ calculateMissingRatio(props.row) }}%
                        </div>
                      </div>
                    </el-tooltip>
                  </span>
                  <span v-else-if="props.column.field == 'actions'">
                    <img
                      class="dtp-cursor-pointer dtp-vgt-actions-icon"
                      src="../../../assets/images/icons/automl-delete-icon.svg"
                      alt=""
                      @click.stop="deleteColumn(props.row)"
                    />
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <template slot="table-column" slot-scope="props">
                  <span>
                    {{ $t("table.header." + props.column.label) }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>
<script>
/* eslint-disable */
import DataPreparationTable from "../DataPreparation/DataPreparationTable";
import PageViewBar from "../Helper/Pageviewbar";
import RefreshTable from "../Helper/RefreshTable";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { Splitpanes, Pane } from "splitpanes";
import { optionsColumn, conversionMapping } from "./Data/data.js";
import notify from "../../../mixins/AutoML/notify";
import liveData from "../../../mixins/AutoML/liveData";
import "splitpanes/dist/splitpanes.css";
import "vue-good-table/dist/vue-good-table.css";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from '../../../helper/asyncStatusChecker';
import { EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';
import { typeConversionOptions } from '../DataPreparation/data/data';
export default {
  mixins: [notify, liveData],
  components: {
    VueGoodTable,
    Splitpanes,
    Pane,
    DataPreparationTable,
    PageViewBar,
    RefreshTable,
  },
  data() {
    return {
      optionsColumn: optionsColumn,
      selectedColumnType: "",
      selectedBulk: "",
      columnTypes: typeConversionOptions,
      bulkActionsList: [],
      allConstantValue: "",
      optionsRows: [],
    };
  },
  watch: {
    explorations: {
      handler() {
        this.setOptionsRows();
      },
      deep: true,
    },
    optionsRows: {
      handler() {
        this.setApplyChanges(
          this.optionsRows.filter((item) => item.check && item.fillMissingValue)
            .length > 0
        );
      },
      deep: true,
    },
  },
  async mounted() {
    this.requestComp = "missing_value_imputation";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )
  },
  computed: {
    ...mapGetters({
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      rawData: DATA_GETTERS.GET_DATA,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      datasetData: DATASET_GETTERS.GET_DATASET,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      canApplyChanges: DATA_GETTERS.GET_APPLY_CHANGES,
    }),

    targetVal() {
      return this.problem_conf?.target;
    },

    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
  },
  methods: {
    ...mapActions({
      deleteColumns: DATA_ACTIONS.DELETE_COLUMNS,
      missingValue: DATA_ACTIONS.MISSING_VALUE,
    }),
    ...mapMutations({
      setMissingListEmpty: MODELS_MUTATIONS.SET_MISSING_LIST_EMPTY,
      setApplyChanges: DATA_MUTATIONS.SET_APPLY_CHANGES,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    calculateMissingRatio(row) {
      return ((row?.missingValue?.missing_ratio ?? 0) * 100).toFixed(3);
    },
    setOptionsRows() {
      this.optionsRows = this.explorations
        .filter((x) => x.missing_values > 0 && x.name != this.targetVal)
        .map((exp, index) => ({
          index: index,
          field: exp.name,
          label: exp.name,
          column: exp.name,
          type: exp.type,
          isMissing: exp.missing_values,
          missingValue: exp.statistics,
          fillMissingValue: "",
          constantvalue: "",
          check: false,
        }));

      if (this.optionsRows.length == 0) {
        this.setMissingListEmpty(true);
      } else {
        this.setMissingListEmpty(false);
      }
    },
    checkRadio(index, value) {
      let row = this.optionsRows.find((x) => x.index == index);

      this.$set(row, "check", true);
      this.$set(row, "fillMissingValue", value);

      this.setApplyChanges(true);
    },
    setConstant(index, value) {
      let row = this.optionsRows.find((x) => x.index == index);

      this.$set(row, "constantvalue", value);
    },
    resetValue(index) {
      let row = this.optionsRows.find((x) => x.index == index);

      this.$set(row, "check", !row.check);
      this.$set(row, "fillMissingValue", "");
    },

    async deleteColumn(row) {
      const payload = {
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        names: row.field,
        pipeline_step: this.requestComp,
      };

      await this.deleteColumns(payload);
      await this.initLiveData(this.requestComp);
    },

    onBulkSelectedColumnTypeChanged() {
      this.optionsRows.forEach((element) => {
        this.$set(element, "check", false);
        this.$set(element, "fillMissingValue", "");
        this.$set(element, "constantvalue", "");

        if (element.type == this.selectedColumnType) {
          this.$set(element, "check", true);
        }
      });
      this.bulkActionsList = conversionMapping[this.selectedColumnType];
    },
    onBulkSelectedMethodChanged() {
      this.optionsRows.forEach((element) => {
        this.$set(element, "constantvalue", "");

        if (element.type == this.selectedColumnType) {
          this.$set(element, "fillMissingValue", this.selectedBulk);
        }
      });
    },
    onBulkSelectedConstantChanged(value) {
      this.optionsRows.forEach((element) => {
        if (element.type == this.selectedColumnType) {
          this.$set(element, "constantvalue", value);
        }
      });
    },
    async applyChanges() {
      let convertObj = [];
      this.optionsRows.forEach((dc) => {
        let req = {};
        if (dc.fillMissingValue && dc.isMissing > 0) {
          req.column_name = dc.field;
          req.method = dc.fillMissingValue;
          if (dc.fillMissingValue == "Constant") req.value = dc.constantvalue;
          convertObj.push(req);
        }
      });
      let reqObj = {};
      reqObj.fill_missing_values = {};
      reqObj.fill_missing_values.fill_missing_values = convertObj;
      reqObj.experiment_id = this.experimentId;
      reqObj.requestComp = this.requestComp;
      await this.missingValue(reqObj);
      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.updateMissingValue
        },
        () => {
          this.initLiveData(this.requestComp);
          this.selectedColumnType = "";
          this.selectedBulk = "";
          this.setApplyChanges(false);
        },
        { callAsyncStatus: this.callAsyncStatus }
      );
    },
  },
};
</script>
<style scoped>
.container {
  background-color: rgb(192, 192, 192);
  width: 100%;
  height: 20px;
}

.percentage {
  background-color: rgb(255 66 66);
  padding: 1%;
  height: 20px;
}

.text-in-progress {
  font-size: 13px;
  position: relative;
  top: -18px;
  left: 40px;
  font-weight: bold;
  color: black;
}
.aml-section-header > div {
  min-width: 175px;
}
</style>
