<template>
  <div class="dtp-section">
    <div class="dtp-row dtp-align-center">
      <div class="aml-section-header">
        <PageViewBar
          :title="$t('automl.featureGeneration')"
          :explorations="explorations"
          :createBtn="false"
          :activeFilters="true"
          :activeSearch="false"
          :filterList="filterHistory"
          :headerArray="filterArray"
          :feautureGeneration="false"
          :columns="columnNames"
          :tableCalculate="tableCalculate"
          :calculateview="true"
          :newCol="newCol"
          :deletableFilter="false"
          @filterSaved="filterSaved"
          @saveFormula="saveFormula"
          @saveBinning="saveBinning"
          @updateFormula="updateFormula"
          @closeFeatureGen="
            {
              newCol = {};
              tableCalculate = $event;
            }
          "
        >
          <div slot="bar-right">
            <RefreshTable
              :loading="loading"
              @refreshTable="refreshTable"
            />
          </div>
        </PageViewBar>
      </div>
    </div>
    <DataPreviewTable
      :dataColumns="dataCalculationColumns"
      :rawData="rawData"
      :lastPage="lastPage"
      :pageSize="pageSize"
      @changePage="changePage"
      @changePageSize="changePageSize"
      @onSortChange="(val) => onSortChange(experimentId, val)"
      @openFeatureGen="openFeatureGen"
    />
  </div>
</template>
<script>
import PageViewBar from "../Helper/Pageviewbar";
import RefreshTable from "../Helper/RefreshTable";
import "vue-good-table/dist/vue-good-table.css";
import DataPreviewTable from "../DataPreview/DataPreviewTable";
import liveData from "../../../mixins/AutoML/liveData";
import notify from "../../../mixins/AutoML/notify";
import { mapActions } from "vuex";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from '../../../helper/asyncStatusChecker';
import { DATA_ACTIONS } from '../../../store/modules/AutoML/Data/types';

export default {
  mixins: [liveData, notify],
  components: {
    PageViewBar,
    RefreshTable,
    DataPreviewTable,
  },
  async mounted() {
    this.requestComp = "feature_generation";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )
  },
  data() {
    return {
      filterList: [],
      tableCalculate: false,
      newCol: {},
    };
  },
  computed: {
    styledNewColumns() {
      const styledCols = [];
      for (const value of Object.values(this.experimentPipeLine)) {
        value.forEach((v) => {
          if (v.generated_column_name) {
            styledCols.push({
              generated_column_name: v.generated_column_name,
              formula_text: v.formula_text,
              formula: v.formula,
            });
          }
        });
      }
      return styledCols;
    },
    dataCalculationColumns() {
      let result = this.explorations.map((exp) => {
        const newCol = JSON.parse(JSON.stringify(this.styledNewColumns))
          .reverse()
          .find((element) => element.generated_column_name == exp.name);
        return {
          label: exp.name,
          field: exp.name,
          newColon: false,
          isNewCol: newCol ? true : false,
          thClass: newCol ? "vgt-table-feature" : "",
          newCol: newCol,
        };
      });
      return result;
    },
    columnNames() {
      return this.explorations.map((e) => {
        return { label: e.name, field: e.name, type: e.type };
      });
    },
    filterArray() {
      return this.explorations.map((e) => {
        return {
          alias: e.name,
          name: e.name,
          type: e.type,
          data: "",
          operator: "",
          isActive: false,
          isShow: true,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      postFeatureGeneration: DATA_ACTIONS.POST_FEATURE_GENERATION,
      updateFeatureGeneration: DATA_ACTIONS.UPDATE_FEATURE_GENERATION,
      renameColumn: DATA_ACTIONS.RENAME_COLUMN,
      putDataBinning: DATA_ACTIONS.PUT_DATA_BINNING,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    async saveFormula(params) {
     await this.postFeatureGeneration({
        experiment_id: this.experimentId,
        payload: params,
        requestComp: this.requestComp,
      });
      await checkIfOperationStatusIsAvailable({
               experiment_id: this.experimentId,
               requestComp: this.requestComp,
               action: ASYNC_PROCESS_ACTIONS.postFeatureGeneration,
      },
      () =>  this.initLiveData(this.requestComp),
      { callAsyncStatus: this.callAsyncStatus }
      )
    },
    async saveBinning(payload) {
      await this.putDataBinning({
        experimentId: this.experimentId,
        payload,
        requestComp: this.requestComp,
      });

      await checkIfOperationStatusIsAvailable(
        {
         experiment_id: this.experimentId,
         requestComp: this.requestComp,
         action: ASYNC_PROCESS_ACTIONS.dataBinning
        },
        () => this.initLiveData(this.requestComp),
        { callAsyncStatus: this.callAsyncStatus }
      )
    },
    async updateFormula(params) {
      const data = {
        experiment_id: this.experimentId,
        payload: {
          formula: params.formula,
          formula_text: params.formula_text,
          generated_column_name: params.generated_column_name,
        },
        requestComp: this.requestComp,
      };

      if (
        params.formula_text != params.old_formula &&
        params.generated_column_name == params.old_column_name
      ) {
        await this.updateFeatureGenerationAsync(data);
      } else if (
        params.formula_text != params.old_formula &&
        params.generated_column_name !== params.old_column_name
      ) {
        data.payload.old_column_name = params.old_column_name;
        await this.updateFeatureGenerationAsync(data);
      } else if (params.generated_column_name != params.old_column_name) {
        data.payload.old_column_name = params.old_column_name;
        await this.updateFeatureGenerationAsync(data);
      } else {
        this.initLiveData(this.requestComp);
      }

    },
    async updateFeatureGenerationAsync(data){
      await this.updateFeatureGeneration({ ...data });

      await checkIfOperationStatusIsAvailable(
        {
               experiment_id: this.experimentId,
               requestComp: this.requestComp,
               action: ASYNC_PROCESS_ACTIONS.updateFeatureGeneration
        },
        () => this.initLiveData(this.requestComp),
        { callAsyncStatus: this.callAsyncStatus }
      )
    },
    openFeatureGen(newCol) {
      this.tableCalculate = true;
      const foundItem = this.dataCalculationColumns.find(
        (d) => d?.newCol?.generated_column_name === newCol.generated_column_name
      );
      this.newCol = foundItem.newCol;
    },
  },
};
</script>
<style scoped></style>
