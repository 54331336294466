<template>
  <div class="dtp-section">
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-3">
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title">{{
              $t("automl.modelRunningTitle")
            }}</span>
          </div>
        </div>
        <div class="dtp-col dtp-col-9 dtp-text--right">
          <!-- <router-link :to="{ name: 'ModelResults' }">
            <button class="eds-button eds-button.basic">
              {{ $t("buttons.continue") }}
            </button>
          </router-link> -->
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-5">
        <div
          class="dtp-aml-modelSelection-card dtp-aml-h-760 dtp-bgcolor-FFFFFF"
        >
          <div class="dtp-aml-modelSelection-card-title">
            <span class="dtp-aml-font-medium">{{
              $t("automl.currentProcess")
            }}</span>
          </div>
          <div>
            <progress-circle
              :size="300"
              :title="$t('automl.processInfo')"
              :timerNumber="progress"
            />
          </div>

          <div class="dtp-row dtp-mt--3 dtp-ml--3 dtp-align-center">
            <div class="dtp-col dtp-col-12">
              <span class="dtp-aml-font-medium"
                >{{ $t("automl.startTime") }} : </span
              >{{ today }}
            </div>
            <div class="dtp-col dtp-col-12 dtp-mt--1">
              <span class="dtp-aml-font-medium"
                >{{ $t("automl.elapseTime") }} : </span
              >{{ elapsetime }}
            </div>
            <div class="dtp-col dtp-col-12 dtp-mt--3 dtp-text--center">
              <!-- <button
                v-if="number < 10"
                class="eds-button eds-button.basic"
                @click="killProcess(experimentId)"
              >
                {{ $t("automl.killProcess") }}
              </button> -->

              <router-link
                v-if="progress < 100"
                :to="{
                  name: 'ModelSelection',
                  query: { experimentId: this.experimentId },
                }"
              >
                <button
                  class="eds-button eds-button.basic"
                  @click="
                    stopTraining(experimentId);
                    back = true;
                  "
                >
                  {{ $t("automl.killProcess") }}
                </button>
              </router-link>

              <router-link
                v-else
                :to="{
                  name: 'ModelResults',
                  query: { experimentId: this.experimentId },
                }"
              >
                <button class="eds-button eds-button.basic">
                  {{ $t("automl.gotoResults") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="dtp-col dtp-col-7">
        <div
          class="dtp-aml-modelSelection-card dtp-aml-h-760 dtp-bgcolor-2E3138"
        >
          <div class="dtp-aml-modelSelection-card-title dtp-text--left">
            <span class="dtp-aml-font-medium dtp-ml--6">{{
              $t("automl.progressLogs")
            }}</span>
          </div>
          <div class="dtp-aml-modelSelection-card-content">
            <div class="dtp-aml-log-content dtp-scrollbar">
              <div class="dtp-row">
                <div
                  v-for="(log, index) in logs"
                  :key="index"
                  class="dtp-col dtp-col-12 dtp-pl--0"
                >
                  <log-item
                    class="dtp-mb--3"
                    :class="{
                      'dtp-aml-logItemRed': log.level == 'ERROR',
                      'dtp-aml-logItemGreen': log.level != 'ERROR',
                    }"
                    :message="log.message"
                    :date="log.date"
                    :level="log.level"
                  />
                </div>
                <p v-if="progress < 100" class="dtp-progres-loading-icon">
                  Loading
                  <span></span><span></span> <span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogItem from "./LogItem";
import ProgressCircle from "./ProgressCircle";
import { getModelTrainingProgress } from "../../../api/AutoMlServices/models";
import { mapActions, mapMutations } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import { MODELS_ACTIONS } from '../../../store/modules/AutoML/Models/types';
import { DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
export default {
  components: {
    ProgressCircle,
    LogItem,
  },
  mixins: [notify],
  data() {
    return {
      today: "",
      elapsetime: 0 + " s",
      elapseTimeCounter: 0,
      progress: 0,
      experimentId: this.$route.query.experimentId,
      logs: [],
      back: false,
      startTime: 0,
      timer: null,
    };
  },
  mounted() {
    this.getNow();
    this.counter();
  },

  beforeDestroy() {
    this.elapseTimeCounter = 4000;
    clearTimeout(this.timer);
  },
  methods: {
    ...mapActions({ stopTraining: MODELS_ACTIONS.STOP_MODEL_TRAINING }),
    ...mapMutations({ setProgress: DATA_MUTATIONS.SET_PROGRESS }),
    counter() {
      if (this.elapseTimeCounter < 3600 && !this.back) {
        this.timer = setTimeout(() => {
          let _this = this;
          getModelTrainingProgress(this.experimentId)
            .then((response) => {
              this.progress = response.data.data.progress;
              this.logs = response.data.data.logs;
              this.setProgress(this.progress);
              if (
                this.progress < 100
                // && response.data.data.experiment_status == "TRAINING"
              ) {
                this.elapseTimeCounter++;
                this.elapsetime = this.elapseTimeCounter + " s";
                this.counter();
              }
              // else if (
              //   response.data.data.experiment_status == "PREPROCESSING"
              // ) {
              //   this.$router.push({
              //     name: "ModelSelection",
              //     query: { experimentId: this.experimentId },
              //   });
              // }
              //  else if (response.data.data.experiment_status == "TRAINED") {
              //   this.$router.push({
              //     name: "ModelResults",
              //     query: { experimentId: this.experimentId },
              //   });
              // }
            })
            .catch(function (error) {
              _this.elapseTimeCounter++;
              _this.counter();
            });
        }, 1000);
      }
    },
    getNow() {
      const today = new Date();

      const date =
        today.getMonth() +
        1 +
        "." +
        today.getDate() +
        "." +
        today.getFullYear();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.today = dateTime;
    },
  },
};
</script>
<style scoped>
.dtp-aml-h-760 {
  height: 760px;
}

.dtp-aml-h-370 {
  height: 372px;
  min-height: 372px;
}

.dtp-aml-logItemBlue {
  color: #1275ff;
}
.dtp-aml-logItemGreen {
  color: #69ff9d;
}
.dtp-aml-logItemRed {
  color: #ff5858;
}
.dtp-progres-loading-icon {
  color: #fff;
  font-size: 14px;
}
.dtp-progres-loading-icon span {
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 15px;
  border-radius: 100%;
  background-color: #fff;
  border: none;
  transition: all 0.3s ease;
  display: inline-block;
}
.dtp-progres-loading-icon span:nth-child(1) {
  animation: loading 3s infinite;
}
.dtp-progres-loading-icon span:nth-child(2) {
  animation: loading 2s infinite;
}
.dtp-progres-loading-icon span:nth-child(3) {
  animation: loading 1s infinite;
}
@keyframes loading {
  25% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(214, 214, 214);
  }
  75% {
    background-color: rgb(148, 148, 148);
  }
  100% {
    background-color: rgb(114, 114, 114);
  }
}
</style>
