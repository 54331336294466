export const domain = "AUTOML/PROJECTS"

export const PROJECT_ACTIONS = {
    FETCH_PROJECTS: `${domain}/FETCH_PROJECTS`,
    DELETE_PROJECT_BY_ID: `${domain}/DELETE_PROJECT_BY_ID`,
    CREATE_PROJECT: `${domain}/CREATE_PROJECT`,
    FETCH_PIPELINE: `${domain}/FETCH_PIPELINE`,
    CREATE_EXPERIMENTS: `${domain}/CREATE_EXPERIMENTS`,
    GET_PROJECT_BY_PROJECT_ID: `${domain}/GET_PROJECT_BY_PROJECT_ID`,
    UPDATE_DESCRIPTION_BY_PROJECT_ID: `${domain}/UPDATE_DESCRIPTION_BY_PROJECT_ID`,
    DELETE_EXPERIMENTS: `${domain}/DELETE_EXPERIMENTS`,
    START_SESSION: `${domain}/START_SESSION`,
    STOP_SESSION: `${domain}/STOP_SESSION`,
    COMPLETE_EXPERIMENT: `${domain}/COMPLETE_EXPERIMENT`,
    SET_SELECTED_PROJECT: `${domain}/SET_SELECTED_PROJECT`
}

export const PROJECT_MUTATIONS = {
    SET_PROJECTS: `${domain}/SET_PROJECTS`,
    SET_SELECTED_PROJECT_ID: `${domain}/SET_SELECTED_PROJECT_ID`,
    SET_PIPELINE: `${domain}/SET_PIPELINE`,
    SET_SELECTED_PROJECT: `${domain}/SET_SELECTED_PROJECT`,
    SET_PROJECTS_FILTER: `${domain}/SET_PROJECTS_FILTER`
}

export const PROJECT_GETTERS = {
    GET_PROJECTS: `${domain}/GET_PROJECTS`,
    GET_SELECTED_PROJECT_ID: `${domain}/GET_SELECTED_PROJECT_ID`,
    GET_PIPELINE: `${domain}/GET_PIPELINE`,
    GET_SELECTED_PROJECT: `${domain}/GET_SELECTED_PROJECT`,
    GET_PROJECTS_FILTER: `${domain}/GET_PROJECTS_FILTER`
}