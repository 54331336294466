export const ImbalanceAlgorithmsTypes = {
    Undersampling: "undersampling",
    Oversampling: "oversampling",
    ClassWeight: "class_weighting"
}

export const ImbalanceAlgorithmPropertiesOverSampling = {
    "sampling_strategy": {
        "description": "Specify the class targeted by the resampling. majority cannot be used for oversampling methods, likewise, minority cannot be used for undersampling methods.",
        "type": "string",
        "enum": [
            "minority",
            "not minority",
            "not majority",
            "all",
            "auto"
        ]
    },
    "k_neighbors": {
        "type": "number",
        "description": "number of nearest neighbours to used to construct synthetic samples",
        "minimum": 1,
        "maximum": 10
    },
    "kmeans_estimator": {
        "type": "number",
        "description": "The number of clusters to be used.",
        "minimum": 2,
        "maximum": 15
    },
    "kind": {
        "type": "string",
        "enum": [
            "borderline-1",
            "borderline-2"
        ]
    },
    "m_neighbors": {
        "type": "number",
        "description": "Number of nearest neighbours to use to determine if a minority sample is in danger.",
        "minimum": 1,
        "maximum": 20
    },
    "n_neighbors": {
        "type": "number",
        "description": "Size of the neighbourhood to consider to compute the nearest neighbors",
        "minimum": 1,
        "maximum": 10
    },
    "out_step": {
        "type": "number",
        "description": "Step size when extrapolating.",
        "minimum": 0.25,
        "maximum": 1
    },
    "n_seeds_S": {
        "type": "number",
        "description": "Number of samples to extract in order to build the set S.",
        "minimum": 1,
        "maximum": 10
    },
    "kind_sel": {
        "type": "string",
        "enum": [
            "all",
            "mode"
        ],
        "description": "Strategy to use in order to exclude samples."
    },
    "cluster_centroids_k": {
        "type": "number",
        "description": "number of nearest neighbours",
        "minimum": 1,
        "maximum": 10
    },
    "instance_hardness_estimator": {
        "type": "string",
        "enum": [
            "knn",
            "decision-tree",
            "random-forest",
            "adaboost",
            "gradient-boosting",
            "linear-svm"
        ]
    },
    "threshold_cleaning": {
        "type": "number",
        "description": "Threshold used to whether consider a class or not during the cleaning after applying ENN.",
        "minimum": 0.1,
        "maximum": 0.9
    },
    "replacement": {
        "type": "boolean",
        "description": "Whether the sample is with or without replacement."
    },
    "max_iter": {
        "type": "number",
        "description": "Maximum number of iterations of the edited nearest neighbours algorithm for a single run.",
        "minimum": 50,
        "maximum": 200
    }
}

export const ImbalanceAlgorithmPropertiesUnderSampling = {
    "sampling_strategy": {
        "description": "Specify the class targeted by the resampling. majority cannot be used for oversampling methods, likewise, minority cannot be used for undersampling methods.",
        "type": "string",
        "enum": [
            "majority",
            "not minority",
            "not majority",
            "all",
            "auto"
        ]
    },
    "k_neighbors": {
        "type": "number",
        "description": "number of nearest neighbours to used to construct synthetic samples",
        "minimum": 1,
        "maximum": 10
    },
    "kmeans_estimator": {
        "type": "number",
        "description": "The number of clusters to be used.",
        "minimum": 2,
        "maximum": 15
    },
    "kind": {
        "type": "string",
        "enum": [
            "borderline-1",
            "borderline-2"
        ]
    },
    "m_neighbors": {
        "type": "number",
        "description": "Number of nearest neighbours to use to determine if a minority sample is in danger.",
        "minimum": 1,
        "maximum": 20
    },
    "n_neighbors": {
        "type": "number",
        "description": "Size of the neighbourhood to consider to compute the nearest neighbors",
        "minimum": 1,
        "maximum": 10
    },
    "out_step": {
        "type": "number",
        "description": "Step size when extrapolating.",
        "minimum": 0.25,
        "maximum": 1
    },
    "n_seeds_S": {
        "type": "number",
        "description": "Number of samples to extract in order to build the set S.",
        "minimum": 1,
        "maximum": 10
    },
    "kind_sel": {
        "type": "string",
        "enum": [
            "all",
            "mode"
        ],
        "description": "Strategy to use in order to exclude samples."
    },
    "cluster_centroids_k": {
        "type": "number",
        "description": "number of nearest neighbours",
        "minimum": 1,
        "maximum": 10
    },
    /*"instance_hardness_estimator": {
        "type": "string",
        "enum": [
            "knn",
            "decision-tree",
            "random-forest",
            "adaboost",
            "gradient-boosting",
            "linear-svm"
        ]
    },*/
    "threshold_cleaning": {
        "type": "number",
        "description": "Threshold used to whether consider a class or not during the cleaning after applying ENN.",
        "minimum": 0.1,
        "maximum": 0.9
    },
    "replacement": {
        "type": "boolean",
        "description": "Whether the sample is with or without replacement."
    },
    "max_iter": {
        "type": "number",
        "description": "Maximum number of iterations of the edited nearest neighbours algorithm for a single run.",
        "minimum": 50,
        "maximum": 200
    }
}

export const ImbalanceAlgorithms = [
    {
        "id": "under_cluster_centroids",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "cluster_centroids",
        "params": {
            "sampling_strategy": "auto",
            "cluster_centroids_k": 8
        }
    },
    /*{
        "id": "under_condensed_nearest_neighbour",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "condensed_nearest_neighbour",
        "params": {
            "sampling_strategy": "auto",
            "n_neighbors": 3,
            "n_seeds_S": 1
        }
    },*/
    {
        "id": "under_edited_nearest_neighbours",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "edited_nearest_neighbours",
        "params": {
            //"sampling_strategy": "auto",
            "kind_sel": "all",
            "n_neighbors": 3
        }
    }, {
        "id": "under_repeated_edited_nearest_neighbours",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "repeated_edited_nearest_neighbours",
        "params": {
            //"sampling_strategy": "auto",
            "kind_sel": "all",
            "n_neighbors": 3,
            "max_iter": 100
        }
    }, {
        "id": "under_all_kNN",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "all_kNN",
        "params": {
            //"sampling_strategy": "auto",
            "kind_sel": "all",
            "n_neighbors": 3
        }
    }, {
        "id": "under_instance_hardness_threshold",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "instance_hardness_threshold",
        "params": {
            //"sampling_strategy": "auto",
        }
    }, {
        "id": "under_near_miss",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "near_miss",
        "params": {
            //"sampling_strategy": "auto",
            "n_neighbors": 3
        }
    }, {
        "id": "under_neighbourhood_cleaning_rule",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "neighbourhood_cleaning_rule",
        "params": {
            //"sampling_strategy": "auto",
            "n_neighbors": 3,
            "kind_sel": "all",
            "threshold_cleaning": 0.5
        }
    },/*  {
    "id": "under_one_sided_selection",
    "type": ImbalanceAlgorithmsTypes.Undersampling,
    "method": "one_sided_selection",
    "params": {
        "sampling_strategy": "auto",
        "n_neighbors": 3,
        "n_seeds_S": 1
    }
}, */  {
        "id": "under_random_under_sampler",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "random_under_sampler",
        "params": {
            //"sampling_strategy": "auto",
            "replacement": false
        }
    }, {
        "id": "under_tomek_links",
        "type": ImbalanceAlgorithmsTypes.Undersampling,
        "method": "tomek_links",
        "params": {
            //"sampling_strategy": "auto"
        }
    }, {
        "id": "over_smote",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "smote",
        "params": {
            "sampling_strategy": "auto",
            "k_neighbors": 5
        }
    }, {
        "id": "over_random_over_sampler",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "random_over_sampler",
        "params": {
            "sampling_strategy": "auto"
        }
    }, {
        "id": "over_adasyn",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "adasyn",
        "params": {
            "sampling_strategy": "auto",
            "n_neighbors": 3
        }
    }, {
        "id": "over_borderline_smote1",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "borderline_smote",
        "params": {
            "sampling_strategy": "auto",
            "k_neighbors": 5,
            "m_neighbors": 10,
            "kind": "borderline-1"
        }
    }, {
        "id": "over_kmeans_smote",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "kmeans_smote",
        "params": {
            "sampling_strategy": "auto",
            "k_neighbors": 5,
            "kmeans_estimator": 8
        }
    }, {
        "id": "over_svm_smote",
        "type": ImbalanceAlgorithmsTypes.Oversampling,
        "method": "svm_smote",
        "params": {
            "sampling_strategy": "auto",
            "k_neighbors": 5,
            "m_neighbors": 10,
            "out_step": 0.5
        }
    }, {
        "id": "class_weight",
        "type": ImbalanceAlgorithmsTypes.ClassWeight,
        "method": "manual",
        "params": {
            "weights": {}
        }
    }]