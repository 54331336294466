<template>
  <div>
    <span> {{ formattedDate }} - {{ level }} - {{ message }}</span>
  </div>
</template>

<script>
import { formatDate } from "../../../helper/format";
import store from '../../../store';

export default {
  name: "ProgressCircle",
  props: {
    level: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate(
        this.date,
        store.getters["Settings/Get/Preference"].longDateFormat
      );
    },
  },
};
</script>

<style scoped></style>
