<template>
  <div
    class="dtp-create-popup"
    :class="{ 'dtp-create-popup-absolute': projectPage }"
  >
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("autoMlWelcome.create_project") }}
      <div
        v-if="projectPage"
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <div class="dtp-row">
        <div class="dtp-col dtp-col-12 dtp-chart-box-20">
          <div class="dtp-chart-box dtp-chart-box-20">
            <div class="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="text"
                @change="setProjectName()"
                :placeholder="$t('input.project_name')"
                class="eds-input_#input"
                maxlength="99"
                v-model="newProjectName"
              />
            </div>
            <div
              v-if="!$v.newProjectName.maxLength"
              class="eds-field_#assistive-text eds-field_#assistive-text.error"
            >
              {{ $t("validation.x can not be longer than y characters", [$t("project.Project Name"), $v.newProjectName.$params.maxLength.max + 1]) }}
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div v-if="isPortfolioAndUsecaseAvailable" class="eds-field_#control eds-field_.eds-input eds-input">
              <select
                v-model="selectedPortfolio"
                @change="setPortfolio()"
                class="eds-input_#input eds-input_#input.dropdown"
              >
                <option selected value="" disabled>
                  {{ $t("portfolioAndUseCase.Choose a Portfolio") }}
                </option>
                <option
                  v-for="item in portfolioItems"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="dtp-chart-box dtp-chart-box-20">
            <div v-if="isPortfolioAndUsecaseAvailable" class="eds-field_#control eds-field_.eds-input eds-input">
              <select
                v-model="selectedUsecase"
                @change="setUsecase()"
                class="eds-input_#input eds-input_#input.dropdown"
              >
                <option selected value="" disabled>
                  {{ $t("portfolioAndUseCase.Choose a Use Case") }}
                </option>
                <option
                  v-for="item in useCaseItems"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="eds-field_#control eds-field_.eds-input eds-input h-128">
            <textarea
              :placeholder="$t('Description')"
              class="eds-input_#input"
              @change="setDescription()"
              maxlength="500"
              v-model="newDescription"
            ></textarea>
          </div>
          <div
            v-if="!$v.newDescription.maxLength"
            class="eds-field_#assistive-text eds-field_#assistive-text.error"
          >
            {{ $t("validation.x can not be longer than y characters", [$t("Description"), $v.newDescription.$params.maxLength.max + 1]) }}
          </div>
        </div>
        <div class="dtp-col dtp-col-12 dtp-text--right">
          <button
            @click="$emit('createProject')"
            class="eds-button eds-button.basic"
          >
            {{ $t("buttons.create") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="vuelidate/dist/vuelidate.min.js"></script>
<script>
import { maxLength } from "vuelidate/lib/validators";
import {
  projectFields as fields,
} from "../projectTypes";
import { ACTION, GETTER } from "../../../../store/modules/AutoML/General/types";
import { mapActions, mapGetters } from "vuex";
import componentAvailabilityCheck from "../../../../mixins/AutoML/componentAvailabilityCheck";
import { PRIVILEGES } from "../../../../helper/authorization";
import { TAG_ACTIONS, TAG_GETTERS } from '../../../../store/modules/AutoML/Tags/types';
export default {
  props: {
    projectName: {
      type: String,
      required: true,
    },
    portfolio: {
      type: String,
    },
    usecase: {
      type: String,
    },
    description: {
      type: String,
      required: true,
    },
    projectPage: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [componentAvailabilityCheck],
  validations: {
    newProjectName: {
      maxLength: maxLength(98),
    },
    newDescription: {
      maxLength: maxLength(499),
    },
  },
  data() {
    return {
      newProjectName: "",
      newDescription: "",
      selectedPortfolio: "",
      selectedUsecase: "",
      filterables: [{ label: "Name", key: "name", type: "input" }],
      TagTypes: {
        USE_CASE: "USE_CASE",
        PORTFOLIO: "PORTFOLIO",
      },
    };
  },
  mounted() {
    this.fetchTags({});
  },
  computed: {
    ...mapGetters({
      tags: TAG_GETTERS.GET_TAGS,
    }),
    portfolioItems() {
      return this.tags.filter((t) => t.type === this.TagTypes.PORTFOLIO);
    },
    isPortfolioAndUsecaseAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON
      );
    },
    useCaseItems() {
      return this.tags.filter((t) => t.type === this.TagTypes.USE_CASE);
    },
    projectFields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      fetchTags: TAG_ACTIONS.FETCH_TAGS,
    }),
    setProjectName() {
      this.$emit("changeName", this.newProjectName);
    },
    setPortfolio() {
      this.$emit("changePortfolio", this.selectedPortfolio);
    },
    setUsecase() {
      this.$emit("changeUsecase", this.selectedUsecase);
    },
    setDescription() {
      this.$emit("changeDesc", this.newDescription);
    },
  },
};
</script>
