import { serviceMethodParent, serviceMethodSub } from "../../api/ApiConstants";
import { vuexActionHandler } from "../../utils/storeHelper";
import router from '../../router';

export const security = {
  state: {
    keycloak: {
      token: "",
      refreshToken: "",
      username: "",
      logoutFunc: "",
      privileges: [],
      pagePrivileges: [],
      roles: []
    },
    licenses: [],
    error: ""
  },
  mutations: {
    "Security/Mutate/SetError": (state, val) => {
      state.error = val;
    },
    "Security/Mutate/Licenses": (state, val) => {
      state.licenses = val;
    },
    "Security/Mutate/Keycloak/Reset": (state) => {
      state.keycloak.token = ""
      state.keycloak.refreshToken = ""
      state.keycloak.username = ""
      state.keycloak.logoutFunc = ""
      state.keycloak.privileges = []
      state.keycloak.roles = []
    },
    "Security/Mutate/Keycloak/Token": (state, val) => {
      state.keycloak.token = val
    },
    "Security/Mutate/Keycloak/RefreshToken": (state, val) => {
      state.keycloak.refreshToken = val
    },
    "Security/Mutate/Keycloak/Username": (state, val) => {
      state.keycloak.username = val
    },
    "Security/Mutate/Keycloak/LogoutFunc": (state, val) => {
      state.keycloak.logoutFunc = val
    },
    "Security/Mutate/Keycloak/Privileges": (state, val) => {
      state.keycloak.privileges = val
    },
    "Security/Mutate/Keycloak/PagePrivileges": (state, val) => {
      state.keycloak.pagePrivileges = val
    },
    "Security/Mutate/Keycloak/Roles": (state, val) => {
      state.keycloak.roles = val
    },

  },
  actions: {
    "Security/Set/Keycloak/Reset": ({ commit }) => {
      commit("Security/Mutate/Keycloak/Reset")
    },
    "Security/Set/Keycloak/Token": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/Token", val)
    },
    "Security/Set/Keycloak/RefreshToken": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/RefreshToken", val)
    },
    "Security/Set/Keycloak/Username": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/Username", val)
    },
    "Security/Set/Keycloak/LogoutFunc": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/LogoutFunc", val)
    },
    "Security/Set/Keycloak/Privileges": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/Privileges", val)
    },
    "Security/Set/Keycloak/PagePrivileges": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/PagePrivileges", val)
    },
    "Security/Set/Keycloak/Roles": ({ commit }, val) => {
      commit("Security/Mutate/Keycloak/Roles", val)
    },
    "Security/Action/Licenses/FetchLicenseInfo": async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.license,
        serviceMethodSub: serviceMethodSub.fetchLicenseInfo,
        withErrorNotify: false,
        withSuccessNotify: false,
        withLoading: false,
        successCallback: (res) => {
          let licensesArray = [];
          for (const [key, value] of Object.entries(res.data)) {
            if ((value.enabled) === true) {
              licensesArray.push(key)
            }
          }
          
          commit("Security/Mutate/Licenses", licensesArray);
        },
        errorCallback: () => {
          commit("Security/Mutate/SetError", "license.active_users_limit_exceeded");

          if (!router.currentRoute.path.includes("401"))
            router.push('/401');
        } 
      })
    },
  },
  getters: {
    "Security/Get/GetError": (state) => {
      return state.error;
    },
    "Security/Get/Licenses": (state) => {
      return state.licenses;
    },
    "Security/Get/Keycloak": (state) => {
      return state.keycloak
    },
    "Security/Get/Keycloak/Token": (state) => {
      return state.keycloak.token
    },
    "Security/Get/Keycloak/RefreshToken": (state) => {
      return state.keycloak.refreshToken
    },
    "Security/Get/Keycloak/Username": (state) => {
      return state.keycloak.username
    },
    "Security/Get/Keycloak/LogoutFunc": (state) => {
      return state.keycloak.logoutFunc
    },
    "Security/Get/Keycloak/Privileges": (state) => {
      return state.keycloak.privileges
    },
    "Security/Get/Keycloak/PagePrivileges": (state) => {
      return state.keycloak.pagePrivileges
    },
    "Security/Get/Keycloak/Roles": (state) => {
      return state.keycloak.roles
    },
  }
}
