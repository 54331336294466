<template>
  <div class="dtp-section">
    <div class="dtp-pageviewtopbar">
      <div class="title">
        <span> Set to </span>
      </div>
      <div class="switch-container">
        <span> False </span>
        <el-switch v-model="isTrue"> </el-switch>
        <span> True </span>
      </div>
      <div>
        <button
          class="aml-btn eds-button.basic requestButton"
          @click="genericModels"
        >
          {{ $t("commons.save") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { MODELS_ACTIONS } from "../../../store/modules/AutoML/Models/types";
export default {
  data() {
    return {
      isTrue: false,
    };
  },
  methods: {
    ...mapActions({
      getGenericModels: MODELS_ACTIONS.GET_GENERIC_MODELS,
    }),
    genericModels() {
      this.getGenericModels({
        custom_model_id: this.$route.params.id,
        set_to: this.isTrue,
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-switch.is-checked .el-switch__core {
  background-color: var(--color-primary) !important;
}
::v-deep .el-switch.is-checked .el-switch__core::after {
  background-color: var(--disabled-color) !important;
}
::v-deep .el-switch__core::after {
  background-color: var(--color-primary) !important;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: var(--color-primary) !important;
}
.switch-container {
  display: flex;
  gap: 16px;
}
.title {
  margin: 10px 50px;
}
.requestButton {
  width: 80px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: unset;
  margin: 20px 28px;
}
</style>
