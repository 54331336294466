<template>
  <div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-8 dtp-pa--0">
        <div class="dtp-chart-box dtp-chart-box-20">
          <el-checkbox v-model="cumulative">{{$t('mapOption.cumulative')}}</el-checkbox>
        </div>
      </div>
     </div>
  </div>
</template>
<script>
export default {
  computed: {
    cumulative: {
      get() {
        // formdata.cumulative is retrieved from nifi as string.
        // nifi variables are string. so, needed to convert to boolean
        if (this.$store.state.query.formData.cumulative == "true" || this.$store.state.query.formData.cumulative == true ){
          return true;
        }
        else{
          return false;
        }
      },
      set(value) {
        return this.$store.dispatch("Query/Set/FormData/Cumulative", value);
      },
    },
  },
};
</script>
