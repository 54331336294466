<template>
  <svg
    id="MDI_wrench-outline"
    data-name="MDI / wrench-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g
      id="Boundary"
      fill="#1d4e91"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="17" height="17" stroke="none" />
      <rect x="0.5" y="0.5" width="16" height="16" fill="none" />
    </g>
    <path
      id="Path_wrench-outline"
      data-name="Path / wrench-outline"
      d="M16.307,13.752,9.875,7.314A4.6,4.6,0,0,0,2.884,1.895L5.6,4.622,4.6,5.607l-2.713-2.7A4.6,4.6,0,0,0,7.226,9.92l6.453,6.453a.705.705,0,0,0,1,0l1.629-1.629a.7.7,0,0,0,0-.992m-2.125,1.126-6.7-6.7a3.1,3.1,0,0,1-1.417.581A3.177,3.177,0,0,1,2.416,5.437L4.6,7.625l3-3L5.42,2.419a3.24,3.24,0,0,1,2.437.942,3.165,3.165,0,0,1,.878,2.805,3.081,3.081,0,0,1-.623,1.388l6.7,6.694Z"
      transform="translate(-0.29 -0.3)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
