var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{ref:"modelResultsTable",staticClass:"dtp-modelresults-table",staticStyle:{"width":"100%"},attrs:{"id":"challenger-table","data":_vm.rows,"cell-class-name":_vm.getCellClassName,"row-class-name":"no-hover","row-key":"model_id"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","reserve-selection":true,"selectable":_vm.isRowSelectable}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Experiment Name'),"prop":"experiment_name","width":"150"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Model'),"prop":"model","width":"150"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Model Status'),"prop":"model_status","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"dtp-display-flex",staticStyle:{"column-gap":"10px","align-items":"center","justify-content":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$t("status." + `${scope.row.model_status}`)))]),(scope.row.failure_cause)?_c('el-popover',{attrs:{"visible-arrow":false,"placement":"bottom-start","width":"314","trigger":"hover"}},[_c('div',{staticClass:"dtp-ma--1"},[_vm._v(_vm._s(scope.row.failure_cause))]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.$emit('init')}}})])]):_vm._e()],1)]}}])}),(!_vm.isCluster)?_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Scores'),"align":"center"}},_vm._l((_vm.scoreNames),function(scoreName){return _c('el-table-column',{key:scoreName,attrs:{"label":_vm.metricLabels[scoreName],"prop":"scores","align":"center"}},[_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Train'),"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Train_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Train')}}),_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Test'),"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Test_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Test')}})],1)}),1):_c('el-table-column',{attrs:{"label":_vm.$t('table.header.Scores'),"align":"center"}},_vm._l((_vm.scoreNames),function(scoreName){return _c('el-table-column',{key:scoreName,attrs:{"label":_vm.metricLabels[scoreName],"prop":"scores","align":"center"}},[_c('el-table-column',{attrs:{"align":"center","prop":JSON.stringify({
              scoreName,
              scoreHighlightType: 'Train_Highlight',
            }),"formatter":(row) => _vm.cellFormatter(row, scoreName, 'Train')}})],1)}),1),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.header.Training Time'),"prop":"trainingtime","width":"150"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }