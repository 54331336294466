<template>
  <svg
    id="infrastructure-wrench"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="21" height="21" stroke="none" />
      <rect x="0.5" y="0.5" width="20" height="20" fill="none" />
    </g>
    <path
      id="Path_wrench"
      data-name="Path / wrench"
      d="M19.982,16.762,12.019,8.8a5.616,5.616,0,0,0-1.312-6.037A5.784,5.784,0,0,0,4.232,1.625L7.994,5.387,5.369,8.012,1.519,4.25a5.639,5.639,0,0,0,1.137,6.475,5.616,5.616,0,0,0,6.038,1.313L16.657,20a.846.846,0,0,0,1.225,0l2.013-2.012A.788.788,0,0,0,19.982,16.762Z"
      transform="translate(-0.081 -0.225)"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
