import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { BUILD_ACTIONS, BUILD_GETTERS, BUILD_MUTATIONS } from "./types";
import { GENERAL_MUTATIONS } from "../General/types";

export const buildStore = {
    state: {
      buildList: [],
      deploymentStatusOfBuilds: [],
    },
    getters: {
      [BUILD_GETTERS.GET_BUILD_LIST]: (state) => {
        return state.buildList;
      },
      [BUILD_GETTERS.GET_DEPLOYMENT_STATUS_OF_BUILDS]: (state) => {
        return state.deploymentStatusOfBuilds;
      },
    },
    mutations: {
      [BUILD_MUTATIONS.SET_BUILD_LIST](state, val) {
        Vue.set(state, "buildList", val);
      },
      [BUILD_MUTATIONS.SET_DEPLOYMENT_STATUS_OF_BUILDS](state, val) {
        Vue.set(state, "deploymentStatusOfBuilds", val || []);
      },
      [BUILD_MUTATIONS.UPDATE_DEPLOYMENT_STATUS_OF_BUILDS](state, val) {
        if (val && val.length) {
          const singleVal = val[0];
          const filteredDeploymentStatuses = state.deploymentStatusOfBuilds.filter(v => v.id !== singleVal.id);
          filteredDeploymentStatuses.push(singleVal);
          Vue.set(state, "deploymentStatusOfBuilds", filteredDeploymentStatuses);
        }
      },
    },
    actions: {
      [BUILD_ACTIONS.DELETE_BUILD]: async ({ commit, dispatch }, build_id) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.deleteBuild,
          queryParam: build_id,
          loadingComponent: "buildList",
          successCallback: async () => {
            await dispatch(BUILD_ACTIONS.FECTH_BUILDS, {});
          }
        });
      },
      [BUILD_ACTIONS.DEPLOY_BUILD]: async ({ commit }, params) => {
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.deployBuild,
          queryParam: params.buildId,
          bodyParam: { clusterId: params.clusterId },
          loadingComponent: "buildList"
        });
  
        return result?.data?.data;
      },
      [BUILD_ACTIONS.FETCH_BUILD_BY_ID]: async ({ commit }, params) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.fetchBuildById,
          queryParam: params,
          loadingComponent: "buildList",
          withSuccessNotify: false
        });
      },
      [BUILD_ACTIONS.FECTH_BUILDS]: async ({ commit, dispatch }, params) => {
        const { page = 1, count = 10, status = "FINISHED", ...others } = params;
        const urlSearchParams = new URLSearchParams({ page, count, status, ...others });
  
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.fetchBuilds,
          queryParam: urlSearchParams.toString(),
          withSuccessNotify: false,
          successMutation: BUILD_MUTATIONS.SET_BUILD_LIST,
          requestComp: "buildList",
          resultSelector: (result) => result?.data?.data,
          successCallback: async (result) => {
            commit(GENERAL_MUTATIONS.SET_PAGINATION, {
              tableName: "builds",
              pageInfo: result.data.paginationInfo,
            });
  
            if (result?.data?.data?.length) {
              await dispatch(BUILD_ACTIONS.FETCH_DEPLOYMENT_STATUS_BY_BUILDS, result?.data?.data?.map(d => d.id));
            }
          }
        });
      },
      [BUILD_ACTIONS.FETCH_DEPLOYMENT_STATUS_BY_BUILDS]: async ({ commit }, buildIds) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.fetchDeploymentStatusByBuilds,
          queryParam: { identifiers: buildIds },
          successMutation: BUILD_MUTATIONS.SET_DEPLOYMENT_STATUS_OF_BUILDS,
          resultSelector: (result) => result.data.data
        });
      },
      [BUILD_ACTIONS.FETCH_DEPLOYMENT_STATUS_BY_SINGLE_BUILD]: async ({ commit }, { build_id, requestComp }) => {
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.build,
          serviceMethodSub: serviceMethodSub.fetchDeploymentStatusByBuilds,
          queryParam: { identifiers: build_id },
          loadingComponent: requestComp,
          successMutation: BUILD_MUTATIONS.UPDATE_DEPLOYMENT_STATUS_OF_BUILDS,
          resultSelector: (result) => result.data.data
        });
      },
    }
}