var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dtp-aml-el-select"},[_c('el-select',{directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.callLoadMoreDatasets),expression:"callLoadMoreDatasets"}],attrs:{"filterable":"","remote":"","remote-method":_vm.searchDatasets,"placeholder":_vm.$t('placeholder.Choose a Data Set')},on:{"visible-change":(isVisible) => (isVisible ? null : (_vm.searchDatasetInput = ''))},model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}},_vm._l((_vm.filterDataSets),function(item){return _c('el-option',{key:item.id,attrs:{"label":`${item.name}  (${item.username}  - ${_vm.callFormatDate(
        item.created_at
      )})`,"value":item.id}},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.styleDataSet(
            item.name,
            item.username,
            _vm.callFormatDate(item.created_at)
          )
        )}})])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }