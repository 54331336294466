export const domain = "AUTOML/GENERAL";

export const GENERAL_ACTIONS = {
  SET_QUERY: `${domain}/setQuery`,
  FETCH_STATS: `${domain}/fetchStats`,
  FETCH_DEVICE_TYPE: `${domain}/FETCH_DEVICE_TYPE`,
  SET_FORMDATA_PROGRESS: `${domain}/SET_FORMDATA_PROGRESS`,
}

export const GENERAL_MUTATIONS = {
  SET_PAGINATION: `${domain}/setPagination`,
  ADD_NOTIFY: `${domain}/addNotify`,
  SET_QUERY: `${domain}/setQuery`,
  SET_STATS: `${domain}/setStats`,
  SET_DEVICE_TYPE: `${domain}/SET_DEVICE_TYPE`,
  SET_LOADING: `${domain}/setLoading`,
  SET_FORMDATA_PROGRESS: `${domain}/SET_FORMDATA_PROGRESS`,
  RESET_NOTIFY: `${domain}/resetNotify`,
  SET_AXIOS_SOURCE: `${domain}/SET_AXIOS_SOURCE`,
  SET_OPERATION_RUNNING_STATUS: `${domain}/setOperationRunningStatus`,
}

export const GENERAL_GETTERS = {
  FETCH_STATS: `${domain}/fetchStats`,
  GET_FORMDATA_PROGRESS: `${domain}/GET_FORMDATA_PROGRESS`,
  GET_LOADING: `${domain}/getLoading`,
  GET_NOTIFY: `${domain}/getNotify`,
  GET_DEVICE_TYPE: `${domain}/GET_DEVICE_TYPE`,
  GET_AXIOS_SOURCE: `${domain}/GET_AXIOS_SOURCE`,
  GET_PAGINATION: `${domain}/getPagination`,
}