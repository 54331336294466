<template>
  <svg
    id="Hamburger"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
  >
    <rect
      id="Rectangle_5923"
      data-name="Rectangle 5923"
      width="20"
      height="2"
      rx="1"
      fill="#606266"
    />
    <rect
      id="Rectangle_5924"
      data-name="Rectangle 5924"
      width="16"
      height="2"
      rx="1"
      transform="translate(0 7)"
      fill="#606266"
    />
    <rect
      id="Rectangle_5925"
      data-name="Rectangle 5925"
      width="10"
      height="2"
      rx="1"
      transform="translate(0 14)"
      fill="#606266"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
