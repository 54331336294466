<template>
  <div :class="{ 'activeMenu': expanded, 'constantMenuAml': constant }">
    <!-- TOPBAR START -->
    <TopBarAutoMl
      :moduleName="moduleName"
      :TopbarColor="'AutoMl'"
      @showVersionPopup="$emit('showVersionPopup')"
      @showExportLogsPopup="$emit('showExportLogsPopup')"
    />
    <!-- TOPBAR END -->
    <NavigationMenu />
    <!-- MENUPANEL START -->
    <div
      class="dtp-leftMenu-aml dtp-bgcolor-1D4E91"
      @mouseover="openMenu()"
      @mouseleave="closeMenu()"
      v-show="$route.name != 'AutoMl'"
    >
      <div class="dtp-leftMenu-aml-flex">
        <ul class="dtp-leftMenu-menuLink">
          <li
            v-if="compName"
            v-show="$route.name != 'AutoMl'"
            @click="constant = !constant">
            <div class="menu-link-header">
              <div class="hamburger-icon">
                <Icon icon="hamburger" size="lg" />
              </div>
              <img src="../../assets/images/icons/Logo.svg" alt="">
            </div>
          </li>
          <li v-for="(item) in links" :key="item.title"
            class="dtp-leftMenu-menulink-upLink-autoMl"
            :class="{ 'dtp-activeLink-AutoMl': $route.name == item.routeName }"
          >
            <router-link
              v-if="hasPrivilage(item.pagePrivilege)"
              :to="{ name: item.routeName }"
              :class="['menu-link',!expanded && !constant && $route.name == item.routeName ? 'selected-background' : '']"
            >
              <Icon :icon="item.icon" size="xl" />
              <span class="menu-text" :class="['menu-text', (expanded || constant) ? 'expanded' : '' ]">{{ $t(item.title) }} </span>
            </router-link>
          </li>
        </ul>

        <div v-if="isLogoAvailable" class="automl-logo">
          <img
            class="automl-logo-icon-expanded"
            src="../../assets/images/havelsan-dikey-logo.png"
            alt=""
          />
          <img
            class="automl-logo-icon-not-expanded"
            src="../../assets/images/havelsan-star-logo.png"
            alt=""
          />
        </div>
        <!-- FİRST MENU -->
      </div>
    </div>
    <!-- MENUPANEL END -->
  </div>
</template>

<script>
import { PAGE_PRIVILEGES } from "./../../helper/authorization";
import TopBarAutoMl from "./AutoMlTopBar.vue";
import NavigationMenu from "./AutoMlNavigation.vue";
import componentAvailabilityCheck from "../../mixins/AutoML/componentAvailabilityCheck";
import { PRIVILEGES } from "../../helper/authorization";
import { mapActions, mapGetters } from "vuex";
import {
  GENERAL_ACTIONS,
  GENERAL_GETTERS,
} from "../../store/modules/AutoML/General/types";
import notify from "../../mixins/AutoML/notify";
import Icon from "../Icons/Icon.vue";

export default {
  mixins: [componentAvailabilityCheck, notify],
  components: {
    TopBarAutoMl,
    NavigationMenu,
    Icon
  },
  data() {
    return {
      expanded: false,
      constant: false,
      hamburgericon: "el-icon-s-unfold",
      moduleName: "AutoML",
      pagePrivileges: PAGE_PRIVILEGES,
      pages: {
        MODEL_GOVERNANCE: "ModelGovernance",
        CUSTOM_MODELS: "CustomModels",
        BUILD_LIST: "BuildList",
        INFRASTRUCTURE: "Infrastructure",
      },
    };
  },
  mounted() {
    this.requestComp = "autoMlLayout";
    this.fetchDeviceType({ requestComp: this.requestComp });
    this.faviconAndElementAddClass();
  },
  computed: {
    ...mapGetters({
      deviceType: GENERAL_GETTERS.GET_DEVICE_TYPE,
    }),
    isLogoAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_CUSTOMER_LOGO);
    },
    activeid() {
      return 2;
    },
    currentUserPagePrivileges: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/PagePrivileges"];
      },
    },
    links() {
      return [
          {
            routeName: "Projects",
            pagePrivilege: PAGE_PRIVILEGES.PAGE_PROJECTS,
            icon:  "project",
            title: "project.title"
          },
          {
            routeName: "Experiments",
            pagePrivilege: PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
            icon:  "experiments",
            title: "experiment.title"
          },
          {
            routeName: "DataSets",
            pagePrivilege: PAGE_PRIVILEGES.PAGE_DATASETS,
            icon:  "dataset",
            title: "dataset.title"
          },
          {
            routeName: this.pages.MODEL_GOVERNANCE,
            pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
            icon:  "modelGovernance",
            title: "modelGovernance.title"
          },
          {
            routeName: this.pages.CUSTOM_MODELS,
            pagePrivilege: PAGE_PRIVILEGES.PAGE_CUSTOM_MODEL,
            icon:  "customModels",
            title: "customModel.title"
          },
          {
            routeName: this.pages.BUILD_LIST,
            pagePrivilege: PAGE_PRIVILEGES.PAGE_MODEL_DEPLOYMENT,
            icon: "modelDeployment",
            title: "build.title"
          },
          {
            routeName: this.pages.INFRASTRUCTURE,
            pagePrivilege: PAGE_PRIVILEGES.PAGE_INFRASTRUCTURE,
            icon:  "infrastructure",
            title: "cluster.title"
          },
      ]
    },
    compName() {
      let compName = false;
      if (
        this.$route.meta.layout == "Layout" ||
        this.$route.meta.layout == "IngestLayout" ||
        this.$route.meta.layout == "ScenarioLayout" ||
        this.$route.meta.layout == "AutoMlLayout"
      ) {
        compName = true;
      }
      return compName;
    },
  },
  methods: {
    ...mapActions({
      fetchDeviceType: GENERAL_ACTIONS.FETCH_DEVICE_TYPE,
    }),
    faviconAndElementAddClass() {
      const el = document.body;
      el.classList.add("elementMsgAlert");
      // const favicon = document.getElementById("favicon");
      // favicon.href = "/experianlogo.ico";
    },
    logout() {
      this.$keycloak.logout();
    },
    constantToggle() {
      if (!this.constant) {
        this.constant = true;
        this.hamburgericon = "el-icon-s-fold";
      } else {
        this.constant = false;
        this.hamburgericon = "el-icon-s-unfold";
      }
    },
    openMenu() {
      this.expanded = true;
    },
    closeMenu() {
      this.expanded = false;
    },
    hasPrivilage(nav) {
      return this.currentUserPagePrivileges.includes(nav);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
</style>
<style scoped>
.dtp-altmenu {
  display: none;
}

.dtp-altmenu-second-li {
  opacity: 1;
}

.automl-logo {
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.automl-logo-icon-expanded {
  display: none;
}

.automl-logo-icon-not-expanded {
  display: block;
  height: 35px;
}

.constantMenuAml .automl-logo-icon-expanded {
  display: block;
  height: 75px;
}

.activeMenu .automl-logo-icon-expanded {
  display: block;
  height: 75px;
}

.activeMenu .automl-logo-icon-not-expanded {
  display: none;
}

.constantMenuAml .automl-logo-icon-not-expanded {
  display: none;
}
.menu-text {
  font-size: 14px;
  font-weight: 500;
  opacity: 0;
  display: none;
  white-space: nowrap;
}
.selected-background {
  background: transparent linear-gradient(45deg, #416CA8 50%, #1D4E91 90%);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 6px 0 6px;
  border-radius: 5px;
}
.menu-text.expanded {
  opacity: 1;
  display: inline-block;
}
.menu-link {
  padding: 5px 6px 6px 6px;
}
.menu-link ::v-deep .dtp-icon-wrapper {
  height: 24px;
  width: 24px;
}
.hamburger-icon {
  width: 35px;
  color: #fff;
  padding: 5px 6px;
}
.activeMenu .hamburger-icon, .constantMenuAml .hamburger-icon {
  transform: rotateY(180deg);
}
.menu-link-header {
  display: flex;
  align-items: center;
  gap: 14px;
}
.menu-link-header img {
  display: none;
}
.activeMenu .menu-link-header img, 
.constantMenuAml .menu-link-header img {
  display: block;
}
</style>
