export const domain = "AUTOML/EXPERIMENTS"

export const EXPERIMENT_ACTIONS = {
    FETCH_EXPERIMENTS: `${domain}/fetchExperiments`,
    FETCH_EXPERIMENTS_BY_PROJECT_ID: `${domain}/fetchExperimentsByProjectId`,
    FETCH_EXPERIMENT_BY_EXPERIMENT_ID: `${domain}/fetchExperimentByExperimentId`,
    FETCH_TRAININGS_BY_EXPERIMENT_ID: `${domain}/fetchTrainingByExperimentId`,
    FETCH_TRAINING_LOGS: `${domain}/fetchTrainingLogs`,
    CLEAR_EXPERIMENTS: `${domain}/clearExperiments`,
    FETCH_EXPERIMENTS_SESSION_STATUS: `${domain}/fetchExperimentsSessionStatus`,
    TRAINED_STATUS_UPDATE:`${domain}/trainedStatusUpdate`,
}

export const EXPERIMENT_MUTATIONS = {
    SET_EXPERIMENTS: `${domain}/setExperiments`,
    SET_SELECTED_EXPERIMENT: `${domain}/setSelectedExperiment`,
    SET_EXPERIMENT_TRAININGS: `${domain}/setExperimentTrainings`,
    SET_TRAINING_LOGS: `${domain}/setTrainingLogs`,
    SET_EXPERIMENTS_SESSION_STATUS: `${domain}/setExperimentsSessionStatus`,
    SET_EXPERIMENTS_FILTER: `${domain}/setExperimentsFilter`,
    SET_FILTER: `${domain}/setFilter`,
}

export const EXPERIMENT_GETTERS = {
    GET_EXPERIMENTS: `${domain}/getExperiments`,
    GET_SELECTED_EXPERIMENT: `${domain}/getSelectedExperiment`,
    GET_EXPERIMENT_TRAININGS: `${domain}/getExperimentTrainings`,
    GET_TRAINING_LOGS: `${domain}/getTrainingLogs`,
    GET_EXPERIMENTS_SESSION_STATUS: `${domain}/getExperimentsSessionStatus`,
    GET_EXPERIMENTS_FILTER: `${domain}/getExperimentsFilter`,
    GET_FILTER: `${domain}/getFilter`,
}