<template>
  <div class="dtp-autoMl-welcome">
    <div class="actions-and-title-wrapper">
      <p class="error-text dtp-fontsize-36 dtp-mb--0">
        <span>{{ $t(error || "user.Unauthorized") }} </span>
      </p>
      <div class="dtp-autoMl-start-box">
        <button class="dtp-autoMl-button" @click="logout">
          {{ $t("layout.logout") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters({
      error: "Security/Get/GetError",
    }),
  },
  methods: {
    ...mapMutations({
      setError: "Security/Mutate/SetError",
    }),
    async logout() {
      this.$keycloak.logout({ redirectUri: location.origin || "/" });
    },
  },
  destroyed() {
    this.setError("");
  },
};
</script>
<style scoped>
.error-text {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #fff;
}

.dtp-autoMl-welcome {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #37334a;
  background-image: url("../assets/images/AutomlBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.actions-and-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.dtp-autoMl-start-box {
  position: relative;
  margin-top: 20px;
}

.dtp-autoMl-button {
  position: relative;
  padding: 10px 40px;
  margin: 0 20px;
  border: none;
  border-radius: 3px;
  background-color: #1d4f91;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
}

.dtp-autoMl-button:hover {
  background-color: #4a71a6;
}
</style>
