<template>
  <div class="dtp-section">
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-8 dtp-align-center">
          <el-tooltip :content="$t('commons.back')" placement="top" effect="light">
            <button class="backButton">
              <router-link to="/model-governance"
                ><img src="../../../assets/images/icons/Automlback.svg" alt=""
              /></router-link>
            </button>
          </el-tooltip>
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title"
              ><span class="applicationTitle">{{ $t('modelGovernance.title') }} /</span>
              {{ $t('applications') }}</span
            >
          </div>

          <PageViewBar
            :isImportEnabled="false"
            :routerLink="''"
            :createBtn="false"
            :activeSearch="true"
            tooltipSearchName="Application"
            @changedSearch="changedSearch"
          >
          </PageViewBar>
        </div>
        <div class="dtp-col dtp-col-4 dtp-text--right dtp-flex-horizontal-end">
          <el-tooltip
            :content="$t('Refresh Table')"
            placement="top"
            effect="light"
            class="dtp-ml--auto"
          >
            <button
              @click="refreshWithFilters()"
              class="aml-btn eds-button.primary"
            >
              <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
            </button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-mb--2">
        <span class="tableAreaHeader dtp-mr--1"> {{ $t('applications') }}</span>
        <div class="dtp-panel-formbox" v-if="isCreateApplicationAvailable">
          <el-tooltip
            :content="$t('Create New Application')"
            placement="top"
            effect="light"
          >
            <button
              @click="() => (applicationCreatePopup = true)"
              class="aml-icon-btn dtp-mr--1"
            >
              <Icon icon="add"></Icon>
            </button>
          </el-tooltip>
          <CreateApplication
            v-if="applicationCreatePopup"
            :requestComp="requestComp"
            @closePopup="() => (applicationCreatePopup = false)"
          />
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div
        v-loading="loading"
        :element-loading-text="$t('helper.loading_data')"
        class="dtp-col dtp-col-12 dtp-freeze-header"
      >
        <vue-good-table
          :columns="columns"
          :rows="nullCheckedApplications"
        >
          <template slot="table-column" slot-scope="props">
            <span>{{ $t("table.header." + props.column.label) }}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <el-tooltip
                :content="$t('tooltip.delete')"
                placement="top"
                effect="light"
              >
                <img
                  src="../../../assets/images/icons/automldeleteicon.svg"
                  @click="callDeleteApplication(props.row.id)"
                  class="dtp-cursor-pointer dtp-vgt-actions-icon"
                  alt="deleteIcon"
                />
              </el-tooltip>
            </span>
            <span v-else-if="props.column.field == 'createdTime'">
              {{ callFormatDate(props.row.createdTime) }}
            </span>
          </template>
        </vue-good-table>
        <CustomPagination
          :lastPage="lastPage"
          :pageSize.sync="pageSize"
          :showPageSizeSelect="true"
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CreateApplication from "./CreateApplication.vue";
import PageViewBar from "../Helper/Pageviewbar.vue";
import { mapGetters, mapActions } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import {
  APPLICATION_ACTIONS,
  APPLICATION_GETTERS,
} from "../../../store/modules/AutoML/Application/types";
import { formatDate } from "../../../helper/format";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { PROJECT_ACTIONS } from "../../../store/modules/AutoML/Projects/types";
import CustomPagination from "../CustomPagination.vue";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import { PRIVILEGES } from "../../../helper/authorization";
import Icon from "../../Icons/Icon.vue"
import notify from '../../../mixins/AutoML/notify';

export default {
  components: {
    CreateApplication,
    PageViewBar,
    VueGoodTable,
    CustomPagination,
    Icon
  },
  mixins: [componentAvailabilityCheck, notify],
  data() {
    return {
      applicationCreatePopup: false,
      requestComp: "applications",
      searchName: "",
      pageSize: 20,
      currentPage: 1,
      columns: [
        {
          label: "Project Name",
          field: "projectName",
          width: "300px",
        },
        {
          label: "Application Name",
          field: "name",
        },
        {
          label: "Created By",
          field: "createdBy",
        },
        {
          label: "Creation Date",
          field: "createdTime",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getApplications: APPLICATION_GETTERS.GET_APPLICATIONS,
      loading: GENERAL_GETTERS.GET_LOADING,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
    }),
    lastPage() {
      return this.pagination.applications?.lastPage ?? 1;
    },
    nullCheckedApplications() {
      return this.getApplications ?? [];
    },
    isCreateApplicationAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_CREATE_APPLICATION
      );
    },
  },
  watch: {
    pageSize: {
      handler() {
        this.currentPage = 1;
        this.init();
      },
    },
  },

  methods: {
    ...mapActions({
      fetchApplications: APPLICATION_ACTIONS.FETCH_APPLICATIONS,
      deleteApplication: APPLICATION_ACTIONS.DELETE_APPLICATION,
      fetchProjects: PROJECT_ACTIONS.FETCH_PROJECTS,
    }),
    async init() {
      await this.callFetchApplications();
      await this.fetchProjects({
        page: 1,
        count: 300,
      });
    },
    async callFetchApplications() {
      await this.fetchApplications({
        page: this.currentPage,
        count: this.pageSize,
        name: this.searchName,
      });
    },
    async callDeleteApplication(rowId) {
      await this.$confirm(
        this.$t("confirmation.deletionWarning", [this.$t("Application")]),
        {
          confirmButtonText: this.$t("commons.delete"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
          callback: async (confirm) => {
            if (confirm == "confirm") {
              await this.deleteApplication({
                app_id: rowId,
                requestComp: this.requestComp,
              });
              this.callFetchApplications();
            }
          },
        }
      );
    },

    callFormatDate(date) {
      const newDate = new Date(date);
      return formatDate(newDate, "DD.MM.YYYY HH:mm");
    },
    refreshWithFilters() {
      this.init(this.getApplications);
    },
    changedSearch(searchText) {
      this.searchName = searchText;
      this.callFetchApplications();
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
  },
};
</script>
<style scoped>
.tableAreaHeader {
  letter-spacing: 0px;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin-left: 15px;
}
.backButton {
  margin-right: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.applicationTitle {
  font: normal normal medium 16px/50px;
  letter-spacing: 0px;
  color: #8c8c8c;
  opacity: 1;
}
</style>