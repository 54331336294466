<template>
  <div>
    <div v-if="isNullOrUndefined(modelValue) || modelValue < 0"></div>
    <div v-else style="display: flex; column-gap: 10px">
      <div>{{ algorithmCodeGiniMapeFormatted }}</div>
      <div v-if="!isNullOrUndefined(healthIndex)">
        <div class="progressBar">
          <div class="gradientBar">
            <div :class="healthIndexClassFormatted"></div>
          </div>
        </div>
      </div>
      <div v-if="!isNullOrUndefined(modelValue)">
        {{ modelValueFormatted.toFixed(1) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //0 - 1
    modelValue: {
      type: Number,
    },
    //0, 1, 2
    healthIndex: {
      type: Number,
    },
    //100, 200, ...
    algorithmCode: {
      type: Number,
    },
  },
  data() {
    return {
      ALGORITHM_CODE_MAP: {
        100: "classification",
        101: "classification",
        102: "classification",
        200: "regression",
        201: "regression",
        202: "regression",
        300: "clustering",
        301: "clustering",
      },
      HEALTH_INDEX_MAP: {
        0: "circleRed",
        1: "circleYellow",
        2: "circleGreen",
      },
    };
  },
  computed: {
    modelValueFormatted() {
      return !this.isNullOrUndefined(this.modelValue)
        ? this.modelValue * 100
        : 0;
    },
    algorithmCodeGiniMapeFormatted() {
      return this.algorithmCodeFormatted === "classification"
        ? "Gini"
        : this.algorithmCodeFormatted === "regression"
        ? "MAPE"
        : "";
    },
    algorithmCodeFormatted() {
      return !this.isNullOrUndefined(this.algorithmCode)
        ? this.ALGORITHM_CODE_MAP[this.algorithmCode]
        : null;
    },
    healthIndexClassFormatted() {
      return !this.isNullOrUndefined(this.healthIndex)
        ? this.HEALTH_INDEX_MAP[this.healthIndex]
        : null;
    },
  },
  methods: {
    isNullOrUndefined(variable) {
      return typeof variable === "undefined" || variable === null;
    },
  },
};
</script>
<style scoped>
.progressBar {
}
.circleGreen {
  right: 3px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #25ba44;
  border: 1px solid #585858;
  border-radius: 50%;
  top: -2px;
}
.circleYellow {
  left: 33px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #ffc000;
  border: 1px solid #585858;
  border-radius: 50%;
  top: -2px;
}
.circleRed {
  left: 3px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #ff2000;
  border: 1px solid #585858;
  border-radius: 50%;
  top: -2px;
}
.gradientBar {
  position: relative;
  height: 16px;
  width: 80px;
  border-radius: 10px;
  background: #ff2000; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #ff2000 1%,
    #ffc000 38%,
    #ffc000 61%,
    #ffc000 61%,
    #25ba44 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #ff2000 1%,
    #ffc000 38%,
    #ffc000 61%,
    #ffc000 61%,
    #25ba44 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #ff2000 1%,
    #ffc000 38%,
    #ffc000 61%,
    #ffc000 61%,
    #25ba44 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff2000', endColorstr='#25ba44',GradientType=1 ); /* IE6-9 */
}
</style>