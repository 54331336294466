<template>
  <div class="dtp-section">
    <div class="dtp-row dtp-align-center">
      <div class="dtp-col dtp-col-2 dtp-col-l4 dtp-mb--0">
        <div class="dtp-pageview-inputBox">
          <input
            type="text"
            :placeholder="$t('placeholder.Enter Data Set Name')"
            v-model="name"
            class="dtp-pageviewtopbar-input"
            id="queryName"
            autocomplete="off"
          />
          <div
            class="dtp-pageview-inputBox-border dtp-pageview-inputBox-border-aml"
          ></div>
        </div>
      </div>
      <div class="dtp-col dtp-col-10 dtp-text--right dtp-mb--0">
        <button
          @click="saveQuery()"
          :disabled="isSaveButtonActive"
          class="eds-button eds-button.basic dtp-mr--2"
        >
          {{ $t("commons.save") }}
        </button>
        <a
          :href="relationalDatabaseConnectionLink"
          rel="noopener noreferrer"
          target="_blank"
          ><el-tooltip :content="$t('Helper')" placement="top" effect="light">
            <button class="aml-btn dtp-translateY-2 eds-button.basic">?</button>
          </el-tooltip>
        </a>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-3 dtp-col-xl4 dtp-col-l12">
        <div class="dtp-view-card">
          <ul class="dtp-aml-card-in-tab-menu">
            <li
              @click="
                chartActive(1),
                  (selectedComponent = component.QUERY_DATA_SOURCE),
                  changeFormData()
              "
              :class="{ 'dtp-aml-card-in-tab-menu-active': chartid == 1 }"
            >
              {{ $t("dataSource.Data Sources") }}
            </li>
            <li
              @click="
                chartActive(2),
                  (selectedComponent = component.DATA_CONFIGURATION)
              "
              :class="{ 'dtp-aml-card-in-tab-menu-active': chartid == 2 }"
            >
              {{ $t("dataSource.Add New Data Source") }}
            </li>
          </ul>
          <div class="dtp-view-card-content dtp-pb--0">
            <div class="dtp-seperator dtp-seperator-30"></div>
          </div>
          <!-- Component Çağırılıyor  -->
          <div class="dtp-view-card-content">
            <component
              ref="childComponentRef"
              :is="selectedComponent"
              :editMode="editMode"
              :autoMl="true"
              @nextConfiguration="nextComponent"
              @selectedDB="selectedDB"
              @selectedDbInfo="selectedDbInfo"
              @editDataSource="editDataSource"
              @remove="removeRequest"
              :options="options"
              :formData="formData"
            ></component>
          </div>
          <!-- Component Çağırılıyor Bitiş -->
        </div>
      </div>
      <div class="dtp-col dtp-col-9 dtp-col-xl8 dtp-col-l12">
        <div class="dtp-view-card dtp-view-card-large">
          <div class="dtp-row">
            <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--0">
              <div class="dtp-view-card-title">
                <h3 class="dtp-fsize--12 dtp-mb--0">
                  {{ $t("query.input.query") }}
                </h3>
              </div>
            </div>
            <div class="dtp-seperator dtp-seperator-10"></div>
            <div class="dtp-w35 dtp-ml--1-5 dtp-seperator-10">
              <div class="eds-field_#label">
                {{ $t("dataSource.Primary Column") }}
              </div>
              <div class="eds-field_#control eds-field_.eds-input eds-input">
                <input
                    :placeholder="$t('placeholder.Type Here')"
                    v-model="primaryColumn"
                    type="text"
                    class="eds-input_#input"
                />
              </div>
            </div>
            <div class="dtp-col dtp-col-12 dtp-mb--0 dtp-pa--0">
              <div class="dtp-view-card-content">
                <div class="dtp-query--card">
                  <code-mirror-sql
                    :sql.sync="sql"
                    :hints="hints"
                  ></code-mirror-sql>
                </div>
              </div>
            </div>
            <div class="dtp-col dtp-col-12 dtp-mb--0 dtp-text--right dtp-pa--0">
              <div class="dtp-view-card-content">
                <button
                  @click="execute()"
                  class="eds-button eds-button.basic eds-loading"
                >
                  <i
                    v-show="loading_btn"
                    class="el-icon-loading"
                    aria-hidden="true"
                  ></i>
                  <span>{{ $t("commons.execute") }}</span>
                </button>
              </div>
            </div>
          </div>
          <!-- dtp-view-card -->
          <el-tooltip
            :content="$t('Please choose a data source in order to type query')"
            placement="left"
            effect="light"
          >
            <div v-if="!datasourceId" class="dtp-codemirror-mask"></div>
          </el-tooltip>
        </div>

        <div
          class="dtp-col dtp-col-12 dtp-pa--0 dtp-mb--0"
          v-if="queryResult.length > 0"
        >
          <div
            class="dtp-view-card dtp-view-card-large dtp-scrollbar dtp-mt--2"
          >
            <table class="dtp-table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    v-for="(key, index) in Object.keys(queryResult[0])"
                    :key="index"
                  >
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in queryResult" :key="i">
                  <td v-for="(key, j) in Object.keys(queryResult[0])" :key="j">
                    {{ item[key] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QueryDataSource from "../../DataDiscovery/DataDiscoveryComponent/QueryDataSource";
import Options from "../../DataDiscovery/DataDiscoveryComponent/DiscoveryOptions";
import DataConfiguration from "./Components/DataDiscoveryConfiguration.vue";
import { MessageBox } from "element-ui";
import { areFieldsValid } from "../../../helper/validation";
import checkAuth from "../../../mixins/authorization";
import CodeMirrorSql from "../../DataDiscovery/DataDiscoveryComponent/CodeMirrorSql";
import _ from "lodash";
import privileges from "../../../mixins/privileges";
import { mapActions, mapGetters } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import helperLink from "../../../mixins/AutoML/helperLink";
import {
  DATASOURCE_ACTIONS,
  DATASOURCE_GETTERS,
} from "../../../store/modules/AutoML/DataSource/type";

export default {
  mixins: [checkAuth, privileges, helperLink, notify],
  data() {
    return {
      formData: {
        username: "",
        host: "",
        port: "",
        serviceName: "",
        name: "",
        password: "",
        type: "",
      },
      isSaveButtonActive: true,
      options: [
        { value: "MYSQL", label: "data_source.type.mysql" },
        { value: "ORACLE", label: "data_source.type.oracle" },
        { value: "SQLSERVER", label: "data_source.type.sqlserver" },
        { value: "POSTGRESQL", label: "data_source.type.postgresql" },
      ],
      datasourceId: "",
      primaryColumn: "",
      hints: {},
      chartid: 1,
      btnText: "",
      selectedComponent: "query-DataSource",
      dataSourceLinkName: "Add New Data Source",
      queryResult: [],
      editMode: false,
      loading_btn: false,
      dataSetObject: {
        name: "",
        size: "500KB",
        rows: null,
        columns: null,
        createtime: "",
      },
      component: {
        DATA_CONFIGURATION: "data-configuration",
        QUERY_DATA_SOURCE: "query-DataSource",
      },
      dataSource: true,
    };
  },
  components: {
    "query-DataSource": QueryDataSource,
    "discovery-options": Options,
    "data-configuration": DataConfiguration,
    CodeMirrorSql,
  },
  created() {
    this.editMode = this.$store.state.query.formData.id !== "";
  },
  mounted() {
    this.requestComp = "createQuery";
  },

  methods: {
    editDataSource(value) {
      if (value == "Edit Data Source") {
        this.chartid = 2;
        this.selectedComponent = "data-configuration";
        this.dataSourceLinkName = value;
      } else {
        this.chartid = 1;
        this.selectedComponent = "query-DataSource";
      }
    },
    ...mapActions({
      uploadRdb: DATASOURCE_ACTIONS.UPLOAD_RDB,
      uploadRdbPreview: DATASOURCE_ACTIONS.UPLOAD_RDB_PREVIEW,
      callDelete: DATASOURCE_ACTIONS.DELETE_DATASOURCE,
    }),
    selectedDB(value) {
      this.datasourceId = value;
    },
    selectedDbInfo(value) {
      this.formData.username = value.username;
      this.formData.host = value.host;
      this.formData.port = value.port;
      this.formData.serviceName = value.serviceName;
      this.formData.name = value.name;
      this.formData.password = "";
      this.formData.type = value.type;
      this.formData.id = value.id;
    },
    nextComponent(value) {
      this.chartid = 2;
      this.selectedComponent = value;
    },
    chartActive(id) {
      this.chartid = id;
    },
    changeFormData() {
      this.dataSourceLinkName = "Add New Data Source";
      this.formData.name = "";
      this.formData.username = "";
      this.formData.type = "";
      this.formData.password = "";
      this.formData.host = "";
      this.formData.port = "";
      this.formData.serviceName = "";
    },
    activeBtn(text) {
      this.btnText = text;
    },
    dateTime() {},
    save() {
      var today = new Date();
      var date =
        today.getDate() +
        "." +
        (today.getMonth() + 1) +
        "." +
        +today.getFullYear();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;
      this.dataSetObject.createtime = dateTime;
      this.dataSetObject.rows = this.queryResult.length;
      var columnLength = Object.keys(this.queryResult[0]).length;
      this.dataSetObject.columns = columnLength;
      this.$router.push({
        name: "DataSets",
      });
    },
    /**
     * This function gets current database's table names and column names
     * Will be used for hint in codemirror component
     * @return {void}
     */
    saveQuery() {
      const objProject = {
        requestComp: this.requestComp,
        data: {
          dataSourceId: this.datasourceId,
          sqlQuery: this.sql,
          datasetName: this.name,
          primary: this.primaryColumn,
        },
      };
      this.uploadRdb(objProject);
    },
    async execute() {
      this.activeBtn("execute");
      this.loading_btn = true;
      const data = {
        requestComp: this.requestComp,
        dataSourceId: this.datasourceId,
        sqlQuery: this.sql,
        datasetName: "",
        primary: this.primaryColumn,
      };

      if (this.areFieldsValidToExecute(data)) {
        const response = await this.uploadRdbPreview(data);
        if (response.status == 200) {
          this.queryResult = response.data?.data;
          this.loading_btn = false;
          this.isSaveButtonActive = false;
        } else {
          this.loading_btn = false;
          this.isSaveButtonActive = true;
        }
      }
    },
    areFieldsValidToExecute(formData) {
      const requiredFields = [
        { value: "datasourceId", label: "panel.new.data_source" },
        { value: "sqlQuery", label: "query.input.sql" },
        { value: "primary", label: "panel.new.primary_column" },
      ];

      return areFieldsValid(requiredFields, formData);
    },
    areFieldsValidToSave(formData) {
      const requiredFields = [
        { value: "name", label: "panel.new.name" },
        { value: "datasourceId", label: "panel.new.data_source" },
        { value: "sqlQuery", label: "query.input.sql" },
      ];

      return areFieldsValid(requiredFields, formData);
    },
    async removeRequest({ id }) {
      const response = await this.callDelete({
        id,
        requestComp: this.requestComp,
      });
      if (response.status === 200) {
        this.$refs.childComponentRef.initQueryDataSource();
      }
    },
  },

  computed: {
    ...mapGetters({
      dataSources: DATASOURCE_GETTERS.GET_DATASOURCE,
    }),
    name: {
      get() {
        return this.$store.getters["Query/Get/FormData/Name"];
      },
      set(value) {
        return this.$store.dispatch("Query/Set/FormData/Name", value);
      },
    },
    sql: {
      get() {
        return this.$store.getters["Query/Get/FormData/Sql"];
      },
      set(value) {
        return this.$store.dispatch("Query/Set/FormData/Sql", value);
      },
    },
    userPrivileges: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Privileges"];
      },
    },
    relationalDatabaseConnectionLink() {
      return this.createHelperLink("relational-database-connection");
    },
  },
};
</script>
<style>
.CodeMirror {
  height: 166px;
}
.CodeMirror-linenumber {
  text-align: center;
}
.dtp-query--card {
  position: relative;
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
  border: 1px solid #ced0da;
  background-color: #ffffff;
}
.dtp-query--card-title {
  position: relative;
  width: 100%;
  height: 46px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #d8d8d8;
}
.dtp-query--card-title .dtp-query--card-title-number {
  position: relative;
  height: 46px;
  width: 46px;
  background-color: #30acb1;
  line-height: 46px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}
.dtp-query--card-title-content {
  display: inline-block;
  padding-left: 20px;
  color: #2e2e2e;
}
.dtp-question-icon .el-input__suffix .el-icon-question {
  color: #ff8888;
}
.CodeMirror-scroll {
  background-color: #f4f7f8;
}
.CodeMirror-gutter {
  background-color: #ffffff;
}
.dtp-codemirror-mask {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  background-color: rgba(214, 214, 214, 0.25) !important;
  box-shadow: 0px 1px 2px #0000001d !important;
  z-index: 5;
}
</style>
