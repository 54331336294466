<template>
  <div>
    <div class="dtp-autoMl-welcome">
      <div class="actions-and-title-wrapper">
        <p class="dtp-fontsize-36 dtp-mb--0">
          <span>{{ $t("user.Welcome To Experian Convex") }} </span>
        </p>
        <div class="dtp-autoMl-start-box">
          <component
            @optionsOpen="selectedComponent = $event"
            :is="selectedComponent"
          ></component>
        </div>
      </div>

      <div v-if="isLogoAvailable" class="automl-logo">
        <img
          class="automl-logo-icon-expanded"
          src="../../assets/images/havelsan-dikey-logo-beyaz.png"
          alt=""
          width="250"
          style="margin-left: auto"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CreateOrOpen from "../AutoMl/WelcomeComponent/CreateOrOpen";
import Options from "../AutoMl/WelcomeComponent/Options";
import { PRIVILEGES } from "../../helper/authorization";
import componentAvailabilityCheck from "../../mixins/AutoML/componentAvailabilityCheck";

export default {
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      selectedComponent: "createoropen",
    };
  },
  components: {
    createoropen: CreateOrOpen,
    options: Options,
  },
  computed: {
    isLogoAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_CUSTOMER_LOGO);
    },
  },
};
</script>
<style>
.dtp-font-light {
  font-family: "ProximaNova Light", sans-serif;
}
.dtp-autoMl-welcome {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #37334a;
  color: #ffffff;
  background-image: url("../../assets/images/AutomlBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.actions-and-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.dtp-autoMl-start-box {
  position: relative;
  margin-top: 50px;
}
.automl-logo {
  margin-top: auto;
  width: 100%;
  display: flex;
}
</style>
