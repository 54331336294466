<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')">
    <div slot="title">
      <span style="font-weight: bold">{{ $t("ModelAuditLog") }}</span>
    </div>

    <!--<el-radio-group
      size="small"
      fill="#416CA8"
      v-model="notesAndAttachmentsRadio"
      style="margin-bottom: 30px"
    >
      <el-radio-button label="Notes">Notes</el-radio-button>
      <el-radio-button label="Attachments">Attachments</el-radio-button>
    </el-radio-group>-->

    <!-- notes part -->
    <div v-if="notesAndAttachmentsRadio === 'Notes'">
      <div class="dtp-chart-box" style="margin-bottom: 0">
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="$t('placeholder.Add Note')"
          v-model="noteTextArea"
        />
      </div>
      <div class="dtp-mb--1" style="height: 40px">
        <button class="aml-btn eds-button.basic addButton" style="">{{ $t("commons.add") }}</button>
      </div>
      <!--<notes-list :notes="mockNotes" />-->
    </div>

    <!-- attachment part -->
    <div v-else>
      <div
        class="dtp-chart-box dtp-chart-box-20"
        style="display: flex; align-items: center; column-gap: 10px"
      >
        <span class="boldSpan">{{ $t("modelGovernance.Audit State") }}</span>
        <div
          class="eds-field_#control eds-field_.eds-input eds-input"
          style="width: 160px"
        >
          <select
            v-model="auditState"
            class="eds-input_#input eds-input_#input.dropdown"
          >
            <option selected value="" disabled>{{ $t("modelGovernance.Audit State") }}</option>
            <option
              v-for="item in auditStateTypes"
              :key="item.key"
              :value="item.key"
            >
              {{ $t(`${item.label}`) }}
            </option>
          </select>
        </div>
      </div>
      <div class="dtp-pl--0 dtp-aml-custom-csvCard dtp-aml-csv-card">
        <form @submit.prevent="submitUpload()">
          <div class="dtp-chart-box">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :limit="1"
              action=""
              :file-list="fileList"
              :on-progress="handleProgress"
              :auto-upload="false"
            >
              <div class="el-upload-dragger-content">
                <div class="dtp-csv-filecontent">
                  <div class="el-upload__text">
                    {{ $t("csv_upload.drag") }}
                    <span class="dtp-fcolor-3F88DE dtp-font-bold">{{
                      $t("csv_upload.select")
                    }}</span>
                    {{ $t("csv_upload.file_to_upload") }}
                  </div>
                </div>
              </div>
            </el-upload>
            <div class="dtp-mt--1 dtp-text--right">
              <button
                :disabled="uploadButtonStatus"
                type="submit"
                class="eds-button eds-button.basic"
              >
                {{ $t("csv_upload.upload") }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <attachment-list :attachments="mockAttachments" :requestComp="requestComp"/>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GENERAL_GETTERS, GENERAL_MUTATIONS } from "../../../store/modules/AutoML/General/types";
//import NotesList from "./NotesList.vue";
import AttachmentList from "./AttachmentList.vue";
import { formatDate } from "../../../helper/format";
import preferences from "../../../mixins/AutoML/preferences";
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../store/modules/AutoML/Models/types';

export default {
  components: {
    //NotesList,
    AttachmentList,
  },
  props: {
    visible: {
      type: Boolean,
    },
    params: {
      type: Object,
      required: true,
    },
    requestComp: {
      type: String,
      required: true,
    },
  },
  mixins: [preferences],
  data() {
    return {
      auditState: "",
      auditStateTypes: {
        PASS: { key: "PASS", label: "Pass" },
        FAIL: { key: "FAIL", label: "Fail" },
        REVIEWING: { key: "REVIEWING", label: "Reviewing" },
        CANCELLED: { key: "CANCELLED", label: "Cancelled" },
      },
      dialogVisible: false,
      notesAndAttachmentsRadio: "Attachments",
      noteTextArea: "",
      fileList: [],
      uploading: false,
      uploadButtonStatus: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.fetchAudit({ modelId: this.params.model_id });
        }
        this.dialogVisible = val;
      },
    },
    fileList: {
      handler(val) {
        if (val.length) this.uploadButtonStatus = true;
        else this.uploadButtonStatus = false;
      },
      deep: true,
    },
    audit: {
      handler(val) {
        this.auditState = val?.audit_state ?? "";
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      formDataProgress: GENERAL_GETTERS.GET_FORMDATA_PROGRESS,
      axiosSource: GENERAL_GETTERS.GET_AXIOS_SOURCE,
      audit: MODELS_GETTERS.GET_AUDIT,
    }),

    mockAttachments() {
      if (this.audit) {
        const { file_path, file_upload_date, file_upload_username } =
          this.audit;

        return [
          {
            name: file_path?.split("/")?.pop() ?? "",
            date: formatDate(file_upload_date, this.preferences.longDateFormat),
            reviewer: file_upload_username,
            path: file_path,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      uploadAudit: MODELS_ACTIONS.UPLOAD_AUDIT,
      fetchAudit: MODELS_ACTIONS.FETCH_AUDIT,
    }),
    ...mapMutations({
      setAxiosSource: GENERAL_MUTATIONS.SET_AXIOS_SOURCE,
    }),

    handleProgress: function () {
      this.uploading = true;
    },
    async submitUpload() {
      this.setAxiosSource();
      const fd = new FormData();
      const { uploadFiles } = this.$refs.upload;

      if (uploadFiles?.length) fd.append("file", uploadFiles[0].raw);
      if (this.auditState) fd.append("audit_state", this.auditState);
      this.errorMessage = "";

      this.handleProgress();

      const result = await this.uploadAudit({
        formData: fd,
        modelId: this.params.model_id,
        requestComp: this.requestComp,
      });

      if (result?.status === 200) this.resetComponent();

      this.$emit("modalClosed");
    },

    resetComponent() {
      this.auditState = "";
      this.fileList = [];
      this.uploading = false;
      this.uploadButtonStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.disableUpload .el-upload-dragger {
  cursor: not-allowed !important;
}
.el-upload {
  display: block;
}

.el-upload-list {
  padding: 0 30px !important;
}
.dtp-aml-csv-card {
  border: 0 !important;
  box-shadow: none !important;
}
.dtp-aml-csv-card .el-upload-list__item {
  padding: 8px 5px;
  border-radius: 3px !important;
  background-color: #39a35f;
  border: 1px solid #d6d6d6;
}

.dtp-aml-csv-card .el-upload-list__item:hover {
  background-color: #39a35f;
}
.dtp-aml-csv-card .el-upload-list__item-name {
  color: #ffffff !important;
  font-size: 12px;
}
.dtp-aml-csv-card .el-upload-list__item .el-icon-close,
.dtp-aml-csv-card .el-upload-list__item .el-icon-upload-success {
  color: #ffffff !important;
}
.dtp-aml-csv-card .el-upload-list__item-name [class^="el-icon"] {
  color: #ffffff;
}
.el-progress-bar {
  display: none !important;
}
.el-upload-list__item .el-icon-close {
  top: 13px !important;
  right: 10px !important;
  display: block !important;
}
::v-deep .el-upload-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* F0A7A */
.el-upload-list__item .el-progress__text {
  position: absolute;
  right: 35px !important;
  top: -5px !important;
  color: #ffffff;
}
.el-upload-list__item-status-label {
  top: 10px !important;
}
.el-button {
  padding: 9px 10px;
  margin-left: 10px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
::v-deep .el-upload-dragger-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.addButton {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: unset;
  float: right;
  margin-top: 10px;
}

.boldSpan {
  font-size: 12px;
  font-weight: bold;
}
</style>
