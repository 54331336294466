export const COLUMNS = [
	{
		label: "Portfolio",
		field: "portfolio_id",
		tdClass: "vertical-center",
		width: "180px",
		sortable: true,
	},
	{
		label: "Use Case",
		field: "use_case_id",
		tdClass: "vertical-center",
		width: "180px",
		sortable: true,
	},
	{
		label: "Name",
		field: "name",
	},
	{
		label: "User",
		field: "username",
	},
	{
		label: "Description",
		field: "description",
	},
	{
		label: "Data Sets",
		field: "datasets",
	},
	{
		label: "Experiments",
		field: "experiments",
	},
	{
		label: "Creation Date",
		field: "created_at",
	},
	{
		label: "Actions",
		field: "actions",
		sortable: false
	},
];