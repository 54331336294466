import { formatDate } from "../../../../../helper/format";
import store from '../../../../../store';


export const COLUMNS = [
	{
		label: "Portfolio Name",
		field: "name",
		width: "250px",
		sortable: false,
	},
	{
		label: "Portfolio Description",
		field: "description",
		sortable: false,
	},
	{
		label: "Creation Date",
		field: "created_at",
		formatFn: value => formatDate(
		  value,
		  store.getters["Settings/Get/Preference"].longDateFormat
		),
		sortable: false,
		width: "200px"
	},
	{
		label: "User",
		field: "created_by",
		sortable: false,
		width: "200px"
	},
	{
		label: "Actions",
		field: "actions",
		sortable: false,
		width: "150px"
	},
];