<template>
  <div class="dtp-aml-pagination-card">
    <span v-if="totalItem" class="dtp-fcolor-333333 total-title">{{ $t('Total Record') }}: {{ totalItem }}</span>
    <ul class="dtp-aml-pagination-number">
      <li :class="paginationButtonClass(true)" @click="goToFirstPage">
        | &#60;
      </li>
      <li :class="paginationButtonClass(true)" @click="goToPreviousPage">
        &#60;
      </li>
      <li
        v-if="checkPageNoforShow(currentPage - 3)"
        @click="changePage(currentPage - 3)"
      >
        {{ currentPage - 3 }}
      </li>
      <li
        v-if="checkPageNoforShow(currentPage - 2)"
        @click="changePage(currentPage - 2)"
      >
        {{ currentPage - 2 }}
      </li>
      <li
        v-if="checkPageNoforShow(currentPage - 1)"
        @click="changePage(currentPage - 1)"
      >
        {{ currentPage - 1 }}
      </li>
      <li @click="changePage(currentPage)" :class="{ active: true }">
        {{ currentPage }}
      </li>
      <li
        v-if="checkPageNoforShow(currentPage + 1)"
        @click="changePage(currentPage + 1)"
      >
        {{ currentPage + 1 }}
      </li>
      <li
        v-if="checkPageNoforShow(currentPage + 2)"
        @click="changePage(currentPage + 2)"
      >
        {{ currentPage + 2 }}
      </li>
      <li
        v-if="checkPageNoforShow(currentPage + 3)"
        @click="changePage(currentPage + 3)"
      >
        {{ currentPage + 3 }}
      </li>
      <li :class="paginationButtonClass(false)" @click="goToNextPage">&#62;</li>
      <li :class="paginationButtonClass(false)" @click="goToLastPage">
        &#62; |
      </li>
    </ul>
    <div
      class="eds-field_#control eds-field_.eds-input eds-input pageSizeSelect"
    >
      <select
        :value="pageSize"
        class="eds-input_#input eds-input_#input.dropdown"
        @change="changePageSize"
      >
        <option
          v-for="size in pageSizes"
          :key="`pagesize-${size}`"
          :value="size"
        >
          {{ size }}
        </option>
      </select>
    </div>
    <span class="dtp-fcolor-333333 dtp-ml--1">{{ $t('Total Page') }}: {{ lastPage }}</span>
  </div>
</template>

<script>
export default {
  props: {
    lastPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    showPageSizeSelect: {
      type: Boolean,
      default: false,
    },
    totalItem: {
      type: Number
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSizes: [10, 20, 30, 40, 50, 100],
    };
  },
  watch: {
    lastPage() {
      this.currentPage = 1;
    },
  },
  computed: {
    pages() {
      const arr = [];

      for (let i = 0; i < this.lastPage; i++) {
        arr.push(i + 1);
      }

      return arr;
    },
    isPreviousButtonsClickable() {
      return this.currentPage != 1;
    },
    isNextButtonsClickable() {
      return this.currentPage != this.lastPage;
    },
  },
  methods: {
    changePageSize(event) {
      this.$emit("update:pageSize", Number(event.target.value));
    },
    checkPageNoforShow(page) {
      return page > 0 && page <= this.lastPage ? true : false;
    },
    changePage(page) {
      this.currentPage = page;
      this.$emit("change", page);
    },
    goToFirstPage() {
      if (this.isPreviousButtonsClickable) {
        this.currentPage = 1;
        this.$emit("change", this.currentPage);
      }
    },
    goToPreviousPage() {
      if (this.isPreviousButtonsClickable) {
        this.currentPage !== 1 && this.currentPage--;
        this.$emit("change", this.currentPage);
      }
    },
    goToNextPage() {
      if (this.isNextButtonsClickable) {
        this.currentPage !== this.lastPage && this.currentPage++;
        this.$emit("change", this.currentPage);
      }
    },
    goToLastPage() {
      if (this.isNextButtonsClickable) {
        this.currentPage = this.lastPage;
        this.$emit("change", this.currentPage);
      }
    },
    paginationButtonClass(isLeftButtons) {
      if (isLeftButtons) {
        return !this.isPreviousButtonsClickable ? "dtp-cursor-not" : "";
      } else {
        return !this.isNextButtonsClickable ? "dtp-cursor-not" : "";
      }
    },
  },
};
</script>

<style scoped>
.pageSizeSelect {
  width: 70px;
  display: inline-flex;
  margin: 0 20px;
}
.total-title {
  display: flex;
  flex: 1;
}
</style>
