<template>
  <svg
    id="approximately-equal"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="MDI_approximately-equal" data-name="MDI / approximately-equal">
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_approximately-equal"
        data-name="Path / approximately-equal"
        d="M18.9,9.2A5.534,5.534,0,0,1,15,11a6.417,6.417,0,0,1-3.2-.9,7.155,7.155,0,0,0-2.9-.8A4.374,4.374,0,0,0,6,10.6L5,9.1A5.823,5.823,0,0,1,8.9,7.2a7.656,7.656,0,0,1,3.2.9A7.088,7.088,0,0,0,15,9a4.448,4.448,0,0,0,2.9-1.4l1,1.6m.1,4.9A5.823,5.823,0,0,1,15.1,16a6.417,6.417,0,0,1-3.2-.9A7.088,7.088,0,0,0,9,14.2a4.448,4.448,0,0,0-2.9,1.4L5.1,14A5.823,5.823,0,0,1,9,12.1a6.417,6.417,0,0,1,3.2.9,7.155,7.155,0,0,0,2.9.8A4.448,4.448,0,0,0,18,12.4Z"
        transform="translate(0 0.8)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
