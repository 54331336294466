<template>
  <div>
    <div class="dtp-navigation-menu" v-if="$route.meta.view == 'projectMenu'">
      <div class="dtp-navigation-menu-btn-box">
        <button @click="stopExperiment">
          {{ $t("automl.stopExperiment") }}
        </button>
        <div @click="info = !info" class="dtp-aml-info primary">i</div>
        <InfoPopup
          @closePopup="info = $event"
          v-if="info"
          :selectedExperiment="selectedExperiment"
          :InfoList="InfoList"
        />
      </div>
      <ul
        class="dtp-navigation-menu-list"
        :class="{ 'dtp-navigation-none-desc': !value1 }"
      >
        <!--  <router-link :to="{ name: pages.DATA_PREVIEW, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.name == pages.DATA_PREVIEW,
            'dtp-navigation-line-active': navigationsList.length >= 1,
          }"
        >
          <div class="dtp-cursor-pointer" @click="navigate(pages.DATA_PREVIEW)">
            <img
              src="../../assets/images/icons/AutoMl-Layout-Data.svg"
              alt="DataPreview"
            />
            <span class="link"> {{ $t("automl.dataPreperation") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 1,
            }"
          ></i>
        </li>
        <!--   </router-link> -->
        <!-- DATA PREVIEW -->
        <ul
          v-show="$route.name == pages.DATA_PREVIEW"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-s"
        >
          <p>
            {{ $t("navigation.Target column selection and problem type determination") }}
            <a
              class="aml-navigation-helperLink"
              :href="dataConfigurationLink"
              target="_blank"
              >{{ $t("automl.more") }} <i class="el-icon-right"></i
            ></a>
          </p> 
          <button
            :disabled="
              navigationsList.length >= 1 || isDataPreparationParamsInvalid || routeHasOnGoingOperation(pages.DATA_PREVIEW)
            "
            @click="callUpdateProblemConfiguration()"
          >
            {{ $t("automl.continueExploreData") }}
          </button>
        </ul>
        <!-- DATA PREVIEW SUBLINK -->
        <!-- <router-link :to="{ name: pages.EXPLORE_DATA, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.meta.navigationLink == pages.EXPLORE_DATA,
            'dtp-navigation-line-active': navigationsList.length >= 2,
          }"
        >
          <div class="dtp-cursor-pointer" @click="navigate(pages.EXPLORE_DATA)">
            <img
              src="../../assets/images/icons/AutoMl-Layout-ExploreData.svg"
              alt=""
            />
            <span class="link">{{ $t("automl.exploreData") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 2,
            }"
          ></i>
        </li>
        <!-- </router-link> -->
        <!-- EXPLORE DATA -->
        <ul
          v-show="$route.name == pages.EXPLORE_DATA"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-s"
        >
          <p>
            {{ $t("navigation.Basic statistics and visualisations for high level exploration") }}
            
            <a
              class="aml-navigation-helperLink"
              :href="exploreDataLink"
              target="_blank"
              >{{ $t("automl.more") }} <i class="el-icon-right"></i
            ></a>
          </p>
          <button
            :disabled="navigationsList.length >= 2 || routeHasOnGoingOperation(pages.EXPLORE_DATA)"
            @click="continueExploreData()"
          >
            {{
              lastMenu.route
                ? $t(`${getUrlName(lastMenu.route)}`)
                : $t("automl.continueFeatureEng")
            }}
          </button>
        </ul>
        <!-- EXPLORE DATA SUBLINK -->
        <!-- <router-link :to="{ name: pages.DATA_PREPARATION, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.meta.navigationLink == pages.FEATURE_ENGINEERING,
            'dtp-navigation-line-active': navigationsList.length >= 3,
          }"
        >
          <div
            class="dtp-cursor-pointer"
            @click="toggleSubMenu = !toggleSubMenu"
          >
            <img
              src="../../assets/images/icons/AutoMl-Layout-FeatureEngineering.svg"
              alt=""
            />
            <span class="link">{{ $t("automl.featureEngineering") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 3,
            }"
          ></i>
        </li>
        <!--  </router-link> -->
        <!-- FeatureEngineering -->
        <ul
          v-show="
            $route.meta.navigationLink == pages.FEATURE_ENGINEERING ||
            toggleSubMenu
          "
          class="dtp-navigation-menu-sub-list dtp-navigation-line-l"
        >
          <div v-for="(item, index) in FeatureEngineeringsubLink" :key="index">
            <!-- <router-link :to="{ name: item.name, query }"> -->
            <li
              @click="navigate(item.name)"
              :class="{
                'dtp-navitaion-menu-sub-list-active': item.name == $route.name,
              }"
              class="dtp-cursor-pointer"
            >
              <span>{{ $t(`${item.text}`) }}</span>
              <i class="el-icon-caret-bottom"></i>
            </li>
            <!-- </router-link> -->
            <div v-show="item.name == $route.name">
              <p>
                {{ $t("navigation." + item.paragraf) }}
                <a
                  class="aml-navigation-helperLink"
                  :href="createHelperLink(item.helperName)"
                  target="_blank"
                  >more
                  <i class="el-icon-right"></i>
                </a>
              </p>
              <button
                id="data_mis_enc"
                v-if="
                  $route.name == pages.MISSING_LIST ||
                  $route.name == pages.BASIC_TRANSFORMATION ||
                  $route.name == pages.ENCODING_LIST
                "
                @click="goForward(item.route)"
                :disabled="routeHasOnGoingOperation($route.name)"
              >
                {{ $t("automl.continue") }}
                <i class="el-icon-caret-right"></i>
              </button>
              <button
                id="basic"
                v-if="$route.name == pages.DATA_DATACALCULATION"
                :disabled="routeHasOnGoingOperation(pages.DATA_DATACALCULATION)"
                @click="goMissingList(item.route)"
              >
                {{ $t("automl.continue") }}
                <i class="el-icon-caret-right"></i>
              </button>
              <button
                id="scaling"
                v-if="$route.name == pages.SCALING"
                :disabled="routeHasOnGoingOperation(pages.SCALING)"
                @click="goFeatureReduction()"
              >
                {{ $t("automl.continue") }}
                <i class="el-icon-caret-right"></i>
              </button>
            </div>
          </div>
        </ul>
        <!-- FeatureEngineering SUBLINK -->
        <!-- <router-link :to="{ name: pages.FEATURE_LIST, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.name == pages.FEATURE_LIST,
            'dtp-navigation-line-active': navigationsList.length >= 4,
          }"
        >
          <div class="dtp-cursor-pointer" @click="navigate(pages.FEATURE_LIST)">
            <img
              src="../../assets/images/icons/AutoMl-Layout-FeatureList.svg"
              alt=""
            />
            <span class="link">{{ $t("automl.featureListTitle") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 4,
            }"
          ></i>
        </li>
        <!--  </router-link> -->
        <!-- FEATURE SELECTION -->
        <ul
          v-show="$route.name == pages.FEATURE_LIST"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-s"
        >
          <p>
            {{ $t("navigation.Selection of the final list of model features and visualization of their importance") }}
            <a
              class="aml-navigation-helperLink"
              :href="featureSelectionLink"
              target="_blank"
              >{{ $t("automl.more") }} <i class="el-icon-right"></i
            ></a>
          </p>
          <button :disabled="navigationsList.length >= 4 || routeHasOnGoingOperation(pages.FEATURE_LIST)" @click="goToModel()">
            {{ $t("navigation.Continue Model") }}
          </button>
        </ul>
        <!-- FEATURE SELECTION SUBLINK-->
        <!-- <router-link :to="{ name: pages.MODEL_SELECTION, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.name == pages.MODEL_SELECTION,
            'dtp-navigation-line-active': navigationsList.length >= 4,
          }"
        >
          <div
            class="dtp-cursor-pointer"
            @click="navigate(pages.MODEL_SELECTION)"
          >
            <img
              src="../../assets/images/icons/AutoMl-Layout-Model.svg"
              alt=""
            />
            <span class="link">{{ $t("automl.model") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 4,
            }"
          ></i>
        </li>
        <!--  </router-link> -->
        <!-- MODEL -->
        <ul
          v-show="$route.meta.navigationLink == pages.MODEL_SELECTION"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-l"
        >
          <router-link :to="{ name: pages.MODEL_SELECTION, query }">
            <li
              :class="{
                'dtp-navitaion-menu-sub-list-active':
                  $route.name == pages.MODEL_SELECTION,
              }"
            >
              <span>{{ $t("automl.modelConfig") }}</span>
              <i class="el-icon-caret-bottom"></i>
            </li>
          </router-link>
          <div v-show="pages.MODEL_SELECTION == $route.name">
            <p>
              {{ $t("automl.selectionText") }}
              <a
                class="aml-navigation-helperLink"
                :href="modelConfigurationLink"
                target="_blank"
                >{{ $t("automl.more") }} <i class="el-icon-right"></i
              ></a>
            </p>
            <button
              @click="
                runModels({
                  requestComp: requestComp,
                  experimentId: experimentId,
                  data: selectedModels,
                })
              "
              :disabled="selectedModels.length == 0"
            >
              {{ $t("automl.run") }}
              <i class="el-icon-caret-right"></i>
            </button>
          </div>
          <router-link :to="{ name: pages.MODEL_RUNNING, query }">
            <li
              :class="{
                'dtp-navitaion-menu-sub-list-active':
                  $route.name == pages.MODEL_RUNNING,
              }"
            >
              <span> {{ $t("automl.modelRunning") }}</span>
              <i class="el-icon-caret-bottom"></i>
            </li>
          </router-link>
          <div v-show="pages.MODEL_RUNNING == $route.name">
            <p>
              {{ $t("automl.modelRuntimeVis") }}
              <a
                class="aml-navigation-helperLink"
                :href="modelRunningLink"
                target="_blank"
                >{{ $t("automl.more") }} <i class="el-icon-right"></i
              ></a>
            </p>
            <router-link :to="{ name: pages.MODEL_RESULTS, query }">
              <button
                id="mod_res"
                :disabled="getProgress < 100"
                @click="setNavigationCount(5)"
              >
                {{ $t("automl.continue") }} <i class="el-icon-caret-right"></i>
              </button>
            </router-link>
          </div>
        </ul>
        <!-- MODEL SUBLINK -->
        <!-- <router-link :to="{ name: pages.MODEL_RESULTS, query }"> -->
        <li
          class="dtp-navigation-menu-link"
          :class="{
            'dtp-navigation-menu-list-active':
              $route.name == pages.MODEL_RESULTS,
            'dtp-navigation-line-active': navigationsList.length >= 5,
          }"
        >
          <div
            class="dtp-cursor-pointer"
            @click="navigate(pages.MODEL_RESULTS)"
          >
            <img
              src="../../assets/images/icons/AutoMl-Layout-Model-Inter.svg"
              alt=""
            />
            <span class="link"> {{ $t("automl.modelInterpreter") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 5,
            }"
          ></i>
        </li>
        <!--  </router-link> -->
        <!-- MODEL INTERPRETER -->
        <ul
          v-show="$route.name == pages.MODEL_RESULTS"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-l"
        >
          <p>
            {{ $t("automl.visualizationText") }}
            <a
              class="aml-navigation-helperLink"
              :href="modelResultsLink"
              target="_blank"
              >{{ $t("automl.more") }} <i class="el-icon-right"></i
            ></a>
          </p>
          <button
            v-if="getProblemType != 'clustering'"
            @click="continueTuneModel()"
          >
            {{ $t("navigation." + modelbtnName) }}
          </button>
          <button
            v-if="getProblemType == 'clustering'"
            @click="completeExperiment()"
          >
            {{ $t("automl.finishExperiment") }}
          </button>
          <!--Paragraf & Button  Feature Importance-->
        </ul>
        <!-- MODEL INTERPRETER SUBLINK -->
        <!-- <router-link :to="{ name: pages.TUNE_MODEL, query }"> -->
        <li
          v-if="getProblemType != 'clustering'"
          class="dtp-navigation-menu-link dtp-navigation-menu-link-end"
          :class="{
            'dtp-navigation-menu-list-active': $route.name == pages.TUNE_MODEL,
          }"
        >
          <div class="dtp-cursor-pointer" @click="navigate(pages.TUNE_MODEL)">
            <img
              src="../../assets/images/icons/AutoMl-Navigation-Tune-Model.svg"
              alt=""
            />
            <span class="link">{{ $t("automl.tuneModel") }}</span>
          </div>
          <i
            class="el-icon-circle-check dtp-link-check"
            :class="{
              'dtp-display-block': navigationsList.length >= 6,
            }"
          ></i>
        </li>
        <!--  </router-link> -->
        <!-- TUNE MODEL -->
        <ul
          v-show="$route.name == pages.TUNE_MODEL"
          class="dtp-navigation-menu-sub-list dtp-navigation-line-s"
        >
          <p>
            {{ $t("automl.hyperparameterText") }}
            <a
              class="aml-navigation-helperLink"
              :href="tuneModelLink"
              target="_blank"
              >{{ $t("automl.more") }} <i class="el-icon-right"></i
            ></a>
          </p>
          <button v-if="hasTuneModelAlgorithm" @click="tuneModel()">
            {{ $t("automl.tuneModels") }}
          </button>
          <button v-if="!hasTuneModelAlgorithm" @click="completeExperiment()">
            {{ $t("automl.finishExperiment") }}
          </button>
        </ul>
      </ul>
      <div class="dtp-navigation-switch">
        <label class="eds-checkbox mr-16">
          <input
            type="checkbox"
            v-model="value1"
            class="eds-checkbox_#input"
            checked=""
          />
          <span class="eds-checkbox_#label">{{ $t("navigation.Show Instructions") }}</span>
        </label>
      </div>
    </div>
    <!-- NAVIGATION MENU -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import notify from "../../mixins/AutoML/notify";
import pipeline from "../../mixins/AutoML/pipeline";
import InfoPopup from "../AutoMl/Popup/infoPopup.vue";
import _ from "lodash";
import helperLink from "../../mixins/AutoML/helperLink";
import componentAvailabilityCheck from "../../mixins/AutoML/componentAvailabilityCheck";
import { PRIVILEGES } from "../../helper/authorization";
import { ASYNC_PROCESS_ACTIONS, checkIfOperationStatusIsAvailable } from '../../helper/asyncStatusChecker';
import { serviceMethodSub } from '../../api/ApiConstants';
import { PROJECT_ACTIONS } from '../../store/modules/AutoML/Projects/types';
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../store/modules/AutoML/Experiments/types';
import { MODELS_ACTIONS, MODELS_GETTERS, MODELS_MUTATIONS } from '../../store/modules/AutoML/Models/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../store/modules/AutoML/Data/types';
import { NAVIGATION_GETTERS, NAVIGATION_MUTATIONS } from '../../store/modules/AutoML/Navigation/types';

export default {
  mixins: [notify, helperLink, pipeline, componentAvailabilityCheck],
  components: {
    InfoPopup,
  },
  data() {
    return {
      pages: {
        DATA_PREVIEW: "DataPreview",
        EXPLORE_DATA: "ExploreData",
        DATA_PREPARATION: "DataPreparation",
        DATA_DATACALCULATION: "Datacalculation",
        PROBLEM_TYPE_SELECTION: "ProblemTypeSelection",
        FEATURE_ENGINEERING: "FeatureEngineering",
        FEATURE_LIST: "FeatureList",
        MODEL_SELECTION: "ModelSelection",
        MODEL_RESULTS: "ModelResults",
        MODEL_TUNNING: "ModelRunning",
        MODEL_RUNNING: "ModelRunning",
        MODEL_GOVERNANCE: "ModelGovernance",
        TUNE_MODEL: "TuneModel",
        MISSING_LIST: "MissingList",
        ENCODING_LIST: "EncodingList",
        SCALING: "ScalingList",
        BASIC_TRANSFORMATION: "DataPreparation",
      },
      FeatureEngineeringsubLink: [
        {
          name: "DataPreparation",
          text: "automl.basicTransformationTitle",
          route: "Datacalculation",
          helperName: "basic-transformation",
          paragraf:
            "Transformation of the data types, data elimination and filtering",
        },
        {
          name: "Datacalculation",
          text: "automl.featureGeneration",
          route: "MissingList",
          helperName: "feature-generation",
          paragraf:
            "New feature creation with mathematical, logical and string operations",
          nextComp: true,
        },
        {
          name: "MissingList",
          text: "automl.missingValueTitle",
          route: "EncodingList",
          helperName: "missing-value-imputation",
          paragraf:
            "Detection and handling of missing values with selected methods",
          activeRoute: false,
        },
        {
          name: "EncodingList",
          text: "automl.encodingTitle",
          route: "ScalingList",
          helperName: "encoding",
          paragraf:
            "Transformation of categorical features into operable features",
          activeRoute: false,
        },
        {
          name: "ScalingList",
          text: "automl.scalingTitle",
          route: "FeatureList",
          helperName: "scaling",
          paragraf:
            "Standardization of independent numeric features to bring their values into same magnitude",
          activeRoute: true,
        },
      ],
      info: false,
      toggleSubMenu: false,

      components: {
        TUNE_MODEL: "TuneModel",
        FEAUTURE_IMPORTANCE: "FeautureImportance",
        ROC_CURVE: "RocCurve",
        CONFUSION_MATRIX: "ConfusionMatrix",
        MISSING_LIST: "MissingList",
        RESIDUALS: "Residuals",
      },
      value1: false,
      modelbtnName: "Continue Tune Model",
      modelbtnLink: "Default",
      deactive: false,
      previousDestName: "",
      linkCount: 0,
      callAsyncStatus: true
    };
  },
  computed: {
    ...mapGetters({
      selectedModels: DATA_GETTERS.GET_SELECTED_MODELS,
      problemConfigurationParams: MODELS_GETTERS.GET_PROBLEM_CONFIGURATION_PARAMS,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      navigationsList: NAVIGATION_GETTERS.GET_NAVIGATION_LIST,
      lastMenu: NAVIGATION_GETTERS.GET_NAVIGATION_LAST_STEP,
      tuneModelData: MODELS_GETTERS.GET_MODEL_TUNING,
      grayFields: DATA_GETTERS.GET_GRAY_FIELDS,
      getApplyChanges: DATA_GETTERS.GET_APPLY_CHANGES,
      getProgress: DATA_GETTERS.GET_PROGRESS,
      getBalanceConfiguration: MODELS_GETTERS.GET_BALANCE_CONFIGURATION,
      getImbalanceInspection: DATA_GETTERS.GET_IMBALANCE_INSPECTION,
      getMissingListEmpty: MODELS_GETTERS.GET_MISSING_LIST_EMPTY,
      getEncodingListEmpty: MODELS_GETTERS.GET_ENCODING_LIST_EMPTY,
      onGoingOperations: NAVIGATION_GETTERS.GET_ON_GOING_OPERATIONS,
    }),
    hasTuneModelAlgorithm() {
      return this.tuneModelData?.algorithms?.length > 0 ? true : false;
    },
    isTuneModelParamsValid() {
      const tuneModel = JSON.parse(JSON.stringify(this.tuneModelData));
      let isValid = true;
      let message = "";

      if (!tuneModel?.metric) {
        isValid = false;
        message = this.$t("validation.Please select a metric");
      }

      tuneModel?.algorithms?.forEach((tm) => {
        if (tm.actionType === "grid") {
          if (tm.optimization?.length) {
            tm.optimization.forEach((tmop) => {
              if (Object.prototype.hasOwnProperty.call(tmop, "values")) {
                if (!tmop.values?.length) {
                  isValid = false;
                }
              } else if (
                Object.prototype.hasOwnProperty.call(
                  tmop,
                  "numeric_value_generator"
                )
              ) {
                const { increment, upper_bound, lower_bound } =
                  tmop.numeric_value_generator;

                if (
                  increment === "" ||
                  upper_bound === "" ||
                  lower_bound === ""
                ) {
                  isValid = false;
                  message = this.$t("validation.Please fill all inputs of parameter");
                }
              }
            });
          } else {
            isValid = false;
            message =
              this.$t("validation.Please enter at least 1 paramater value in order to use grid search optimization");
          }
        }
      });

      return { isValid, message };
    },
    balancingVal() {
      return this.problem_conf?.balancing;
    },
    isDataPreparationParamsInvalid() {
      const res =
        this.problemConfigurationParams?.problem_type !== "clustering"
          ? !(
              this.problemConfigurationParams?.target &&
              this.problemConfigurationParams?.train_ratio > 0
            )
          : false;

      return res;
    },

    isRevertAndBackToPreviousStepAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_NAVIGATION_REVERT_ACTION
      );
    },
    query() {
      return { experimentId: this.experimentId };
    },

    experimentId() {
      return this.$route.query.experimentId;
    },
    url() {
      return this.$route.name;
      /* return this.$route.path?.split("/").pop(); */
    },
    dataConfigurationLink() {
      return this.createHelperLink('data-configuration')
    },
    modelConfigurationLink() {
      return this.createHelperLink('model-configuration')
    },
    modelResultsLink() {
      return this.createHelperLink('model-results')
    },
    exploreDataLink() {
      return this.createHelperLink('explore-data')
    },
    featureSelectionLink() {
      return this.createHelperLink('feature-selection')
    },
    modelRunningLink() {
      return this.createHelperLink('model-running')
    },
    tuneModelLink() {
      return this.createHelperLink('tune-model')
    }
  },
  async mounted() {
    this.requestComp = "navigation";
    if (this.experimentId)
      await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
  },
  watch: {
    apiResponse: {
      deep: true,
      handler(val) {
        if (
          val.type === "success" &&
          val.serviceType === serviceMethodSub.completeExperiment
        ) {
          this.$router.push({ name: this.pages.MODEL_GOVERNANCE });
        } else if (
          val.type === "success" &&
          val.serviceType === serviceMethodSub.modelTuning
        ) {
          this.setNavigationCount(4);
          this.$router.push({
            name: this.pages.MODEL_RUNNING,
            query: this.query,
          });
        } else if (
          val.type === "success" &&
          val.serviceType === serviceMethodSub.featureImportancesPost
        ) {
          this.setNavigationCount(3);
          this.$router.push({
            name: this.pages.FEATURE_LIST,
            query: this.query,
          });
        } else if (val.type === "error" && val.serviceType === serviceMethodSub.runModels) {
          this.$alert(
            "Experiment ID :" + this.experimentId + " " + val.message,
            this.$t("Configuration Error"),
            {
              confirmButtonText: "OK",
            }
          );
        } else if (val.type === "success" && val.serviceType === serviceMethodSub.runModels) {
          this.$router.push({
            name: this.pages.MODEL_RUNNING,
            query: this.query,
          });
        }
        /* else if (
          val.type === "success" &&
          val.serviceType === "dataVersioning"
        ) {
          this.$router.push({
            name: this.pages.DATA_DATACALCULATION,
            query: this.query,
          });
        } */
      },
    },
  },
  methods: {
    goForward(route) {
      if (
        route == "EncodingList" &&
        this.balancingVal != null &&
        this.getMissingListEmpty != true &&
        (this.balancingVal.type == "oversampling" ||
          this.balancingVal.type == "undersampling")
      ) {
        this.$confirm(
          this.$t("confirmation.fixImbalanceDatasetMethodsWillNotWorkBecauseOfMissingValues"),
          this.$t("confirmation.Missing Value Detected"),
          {
            confirmButtonText: this.$t("commons.remove"),
            cancelButtonText: this.$t("commons.cancel"),
            dangerouslyUseHTMLString: true,
            callback: async (confirm) => {
              if (confirm == "confirm") {
                await this.removeNaRows({ experiment_id: this.experimentId });
                await checkIfOperationStatusIsAvailable(
                  {
                    experiment_id: this.experimentId,
                    requestComp: this.requestComp,
                    action: ASYNC_PROCESS_ACTIONS.removeNaRows
                  },
                  () => {
                    this.$router.push({
                      name: route,
                      query: this.query,
                    });
                  },
                  { intervalCount: 300, callAsyncStatus: this.callAsyncStatus }
                )
              }
            },
          }
        );
      } else if (
        route == "ScalingList" &&
        this.balancingVal != null &&
        this.getEncodingListEmpty.isEmpty != true
      ) {
        this.$confirm(
          this.$t("confirmation.fixImbalanceMethodsWillNotWorkWithCategoricTypes"),
          this.$t("confirmation.Categorical Data Type Detected"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            dangerouslyUseHTMLString: true,
            callback: (confirm) => {
              if (confirm == "confirm") {
                let reqObj = {};
                reqObj.encoding = {};
                reqObj.encoding.encoding = this.getEncodingListEmpty.rows;
                reqObj.experiment_id = this.experimentId;
                reqObj.requestComp = "encoding";
                this.encoding(reqObj);

                this.$router.push({
                  name: route,
                  query: this.query,
                });
              }
            },
          }
        );
      } else {
        if (
          route == "Datacalculation" ||
          route == "EncodingList" ||
          route == "ScalingList" ||
          route == "FeatureList"
        ) {
          if (this.getApplyChanges == true) {
            this.$confirm(
              this.$t("confirmation.applyChangesBeforeRouting"),
              this.$t("confirmation.Unapplied Changes"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                callback: (confirm) => {
                  if (confirm == "confirm") {
                    this.$router.push({
                      name: route,
                      query: this.query,
                    });
                  }
                },
              }
            );
          } else {
            this.$router.push({
              name: route,
              query: this.query,
            });
          }
        } else {
          this.$router.push({
            name: route,
            query: this.query,
          });
        }
      }
    },
    goFeatureReduction() {
      if (this.getApplyChanges == true) {
        this.$confirm(
          this.$t("confirmation.applyChangesBeforeRouting"),
          this.$t("confirmation.Unapplied Changes"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            callback: (confirm) => {
              if (confirm == "confirm") {
                this.setNavigationCount(3);
                this.$router.push({
                  name: this.pages.FEATURE_LIST,
                  query: this.query,
                });
              }
            },
          }
        );
      } else {
        this.setNavigationCount(3);
        this.$router.push({
          name: this.pages.FEATURE_LIST,
          query: this.query,
        });
      }
    },
    async goMissingList(route) {
      await this.makeDataVersion("feature_engineering");
      this.$router.push({
        name: route,
        query: this.query,
      });
    },
    async goToModel() {
      if (this.grayFields) {
        this.$alert(
          this.$t("navigation.You can not proceed to the next step because of gray fields"),
          this.$t("navigation.Improper Variables Detected"),
          {
            confirmButtonText: "OK",
          }
        );
      } else {
        await this.makeDataVersion("model");
        this.$router.push({ name: "ModelSelection", query: this.query });
        this.setNavigationCount(4);
      }
    },
    async makeDataVersion(next_step) {
      this.versionData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        next_step: next_step,
        withLoading: false
      });
    },
    async navigate(destination) {
      const dest = this.getUrlNumber(destination);
      const avaiableRoutes =
        this.navigationRules[this.getUrlNumber(this.url)]?.routes ?? [];
      const targetRoute = avaiableRoutes.find((obj) => {
        return obj.to == dest;
      });
      if (targetRoute) {
        if (targetRoute.warning) {
          if (this.isRevertAndBackToPreviousStepAvailable) {
            await this.$confirm(
              this.$t("automl.goBackMessage", {
                msg: this.$t(this.getUrlName(destination)),
              }),
              this.$t("automl.confirmGoBack"),
              {
                confirmButtonText: this.$t("alert.confirm"),
                cancelButtonText: this.$t("alert.cancel"),
                type: "warning",
              }
            );
            this.previousDestName = destination;
            this.linkCount = Number(targetRoute.to.charAt(0)) - 1;
            const pipeline = this.getPipelineName(destination);

            if (pipeline) {
              await this.goPreviousStep({
                requestComp: this.requestComp,
                experiment_id: this.experimentId,
                target_pipeline_step: pipeline,
              })

              await checkIfOperationStatusIsAvailable(
                {
                  experiment_id: this.experimentId,
                  requestComp: this.requestComp,
                  action: ASYNC_PROCESS_ACTIONS.previousPipelineStep
                },
                () => {
                  this.setNavigationCount(this.linkCount);
                  this.$router.push({ name: this.previousDestName, query: this.query });
                },
                { callAsyncStatus: this.callAsyncStatus }
              )
            }
          }
        } else {
          this.previousDestName = destination;
          this.setLastStep("1");
          this.setNavigationCount(Number(targetRoute.to.charAt(0)) - 1);
          this.$router.push({ name: destination, query: this.query });
        }
      }
    },
    ...mapActions({
      runModels: MODELS_ACTIONS.RUN_MODELS,
      updateProblemConfiguration: MODELS_ACTIONS.UPDATE_PROBLEM_CONFIGURATION,
      completeSession: PROJECT_ACTIONS.COMPLETE_EXPERIMENT,
      stopSession: PROJECT_ACTIONS.STOP_SESSION,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      goPreviousStep: DATA_ACTIONS.PREVIOUS_PIPELINE_STEP,
      putTuneModel: MODELS_ACTIONS.PUT_MODEL_TUNING,
      versionData: DATA_ACTIONS.DATA_VERSIONING,
      postBalanceConfiguration: DATA_ACTIONS.BALANCE_CONFIGURATION,
      encoding: DATA_ACTIONS.ENCODING,
      removeNaRows: DATA_ACTIONS.REMOVE_NA_ROWS,
      dataSort: DATA_ACTIONS.DATA_SORT,
    }),
    ...mapMutations({
      addLink: NAVIGATION_MUTATIONS.SET_NAVIGATION_LIST,
      resetNavigationList: NAVIGATION_MUTATIONS.RESET_NAVIGATION_LIST,
      setNavigationCount: NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST,
      setLastMenu: NAVIGATION_MUTATIONS.SET_LAST_STEP,
      setModelTuning: MODELS_MUTATIONS.SET_MODEL_TUNING,
      resetImbalance: DATA_MUTATIONS.RESET_IMBALANCE,
      inspectImbalance: DATA_MUTATIONS.INSPECT_IMBALANCE,
      setBalanceConfiguration: MODELS_MUTATIONS.SET_BALANCE_CONFIGURATION,
      clearOnGoingOperations: NAVIGATION_MUTATIONS.CLEAR_ON_GOING_OPERATIONS
    }),

    continueExploreData() {
      let dest = "";
      if (!_.isEmpty(this.lastMenu)) {
        const lastMenuItem = this.getUrlNumber(this.lastMenu.route);
        dest = this.lastMenu.route;
        this.resetNavigationList();
        this.setNavigationCount(Number(lastMenuItem.charAt(0)) - 1);
      } else {
        this.setNavigationCount(2);
        dest = "DataPreparation";
      }
      this.$router.push({ name: dest, query: this.query });
    },
    continueTuneModel() {
      this.setNavigationCount(6);
      this.$router.push({ name: this.pages.TUNE_MODEL, query: this.query });
    },
    setLastStep(name) {
      this.setLastMenu({
        name: name,
        route: this.$route.name,
      });
    },
    async callUpdateProblemConfiguration() {
      if(this.problemConfigurationParams.date_column){
        await this.dataSort({
          experimentId: this.experimentId,
          requestComp: this.requestComp, 
          payload: {
            columns: [
              this.problemConfigurationParams.date_column 
            ],
            ascending:true
          }
        });
        await checkIfOperationStatusIsAvailable(
          {
            experiment_id: this.experimentId,
            requestComp: this.requestComp,
            action: ASYNC_PROCESS_ACTIONS.sort
          },
          () => {},
          { callAsyncStatus: this.callAsyncStatus }
        )
      }
        await this.updateProblemConfiguration({
          requestComp: this.requestComp,
          experimentId: this.experimentId,
        });

        await checkIfOperationStatusIsAvailable(
          {
            experiment_id: this.experimentId,
            requestComp: this.requestComp,
            action: ASYNC_PROCESS_ACTIONS.updateProblemConfiguration
          },
          async () => {
            const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);
            if (
              !!Object.keys(this.getImbalanceInspection).length &&
              !this.getImbalanceInspection?.is_balanced
            ) {
              const { params, id, method } = this.getBalanceConfiguration;

              if (!method) {
                await this.$confirm(
                  this.$t("confirmation.Please choose one of the imbalance handling methods or switch off the Control of Data Balance to continue"),
                  this.$t("confirmation.Missing Imbalance Method Configuration"),
                  {
                    confirmButtonText: this.$t("confirmation.Switch Off"),
                    type: "warning",
                    callback: (confirm) => {
                      if (confirm == "confirm") {
                        this.resetImbalance();
                        this.inspectImbalance({});
                        this.setBalanceConfiguration({});
                      }
                    },
                  }
                );

                return;
              } else {
                if (params) {
                  console.log(JSON.stringify(params));
                  if (id === "class_weight") {
                    let classWeightTotal = !!Object.keys(params.weights).length
                      ? sumValues(params.weights)
                      : 0;

                    console.log("classWeightTotal: " + classWeightTotal);
                    if (classWeightTotal !== 100) {
                      this.$notify({
                        title: this.$t("alert.warning"),
                        message: this.$t("navigation.Sum of Class Weights should be 100%"),
                        type: "warning",
                      });
                      return;
                    }
                  }

                  const balanceConfigResult = await this.postBalanceConfiguration({
                    experimentId: this.experimentId,
                    ...this.getBalanceConfiguration,
                  });

                  if (!balanceConfigResult) return;
                  else {
                    this.pushToExploreData();
                    return;
                  }
                }
              }
            } else {
              this.pushToExploreData();
            }
          },
          { intervalCount: 300, callAsyncStatus: this.callAsyncStatus }
        )
    },

    pushToExploreData() {
      this.setNavigationCount(1);
      this.$router.push({
        name: this.pages.EXPLORE_DATA,
        query: this.query,
      });
    },
    async stopExperiment() {
      await this.$confirm(
        this.$t("confirmation.Your experiment will stop and save all process up to now"),
        this.$t("tooltip.stop"),
        {
          confirmButtonText: this.$t("tooltip.stop"),
          cancelButtonText:this.$t("commons.cancel"),
          type: "warning",
        }
      );

      await this.stopSession({
        requestComp: this.requestComp,
        projectId: this.selectedExperiment[0].project_id,
        experimentId: this.experimentId,
      });
      
      this.clearOnGoingOperations(this.experimentId);

      this.$router.push({ name: "Experiments" });
    },
    async completeExperiment() {
      await this.completeSession({
        requestComp: this.requestComp,
        projectId: this.selectedExperiment[0].project_id,
        experimentId: this.experimentId,
      });
    },

    tuneModel() {
      const { isValid, message } = this.isTuneModelParamsValid;

      if (isValid) {
        this.putTuneModel({
          experimentId: this.experimentId,
          data: {
            metric: this.tuneModelData?.metric,
            algorithms: this.tuneModelData?.algorithms?.map(
              ({ actionType, optimization, ...others }) =>
                actionType === "grid"
                  ? { ...others, optimization }
                  : { ...others }
            ),
          },
          requestComp: this.requestComp,
        });
      } else {
        this.$notify({
          title: this.$t("alert.warning"),
          message,
          type: "warning",
        });
      }
    },
    routeHasOnGoingOperation(page) {
      const opLength = this.onGoingOperations?.[this.experimentId]?.[page]?.length

      if (opLength && opLength !== 0) {
        return true;
      }

      return false;
    }
  },
  destroyed() {
    this.callAsyncStatus = false;
  }
};
</script>
<style>
.dtp-navigation-menu-btn-box {
  position: relative;
  width: 100%;
  padding: 0 20px 0 30px;
  margin: 25px 0 40px 0;
  display: flex;
  justify-content: space-between;
}
.dtp-navigation-menu-btn-box button {
  position: relative;
  width: 78%;
  padding: 8px 0;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  background-color: #e25d5d;
  border: 1px solid #ef7c7c;
  border-radius: 3px;
}
.dtp-navigation-switch {
  position: absolute;
  left: 30px;
  bottom: 90px;
}
.dtp-navigation-switch .eds-checkbox_\#label {
  color: #ffffff;
  margin-left: 10px;
}
.dtp-navigation-switch .eds-checkbox_\#input:checked,
.dtp-navigation-switch .eds-checkbox_\#input {
  background-color: #1d4f90;
  border-color: #ffffff !important;
}
.el-switch__core {
  background: #ffffff !important;
}
.el-switch__core::after {
  background-color: #1d4f90 !important;
}
.el-switch.is-checked .el-switch__core {
  border-color: transparent !important;
}
.el-switch.is-checked .el-switch__core::after {
  background-color: #37334a !important;
}
.dtp-navigation-none-desc p {
  display: none;
}
.aml-navigation-helperLink {
  text-decoration: underline;
  font-weight: bold;
}
</style>
