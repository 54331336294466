import { InputTypes, Algorithms } from './types'

export const SECONDARY_COLUMNS_CPU_CLASSIFICATION_CONFIG = {
    [Algorithms.LogisticRegression.name]: [{
        label: "Penalty",
        key: "penalty",
        values: [{
            key: "none",
            label: "None"
        }, {
            key: "l2",
            label: "l2"
        }],
        type: InputTypes.SelectBox
    }, {
        label: "C",
        key: "C",
        type: InputTypes.NumberField,
        validation: {
            min: 0
        }
    }],

    [Algorithms.XGBoost.name]: [{
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    }, {
        label: "Gamma",
        key: "gamma",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Min Child Weight",
        key: "min_child_weight",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Subsample",
        key: "subsample",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    {
        label: "Lambda",
        key: "lambda",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Alpha",
        key: "alpha",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Col Sample By Tree",
        key: "colsample_bytree",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },],

    [Algorithms.RandomForest.name]: [
        {
            label: "N Estimators",
            key: "n_estimators",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
                max: 200
            }
        },
        {
            label: "Max Depth",
            key: "max_depth",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            }
        }, {
            label: "Max Features",
            key: "max_features",
            values: [{
                key: "auto",
                label: "auto"
            }, {
                key: "log2",
                label: "log2"
            }, {
                key: "sqrt",
                label: "sqrt"
            }],
            type: InputTypes.SelectBox
        }, {
            label: "Min Sample Leaf",
            key: "min_samples_leaf",
            type: InputTypes.NumberField,
            validation: {}
        }, {
            label: "Min Samples Split",
            key: "min_samples_split",
            type: InputTypes.NumberField,
            validation: {}
        }],

    [Algorithms.CatBoost.name]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 49,
            max: 1001
        }
    }, {
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1.00000000001
        }
    }, {
        label: "L2 Leaf Regularizer",
        key: "l2_leaf_reg",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Depth",
        key: "depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 17
        }
    },
    {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 41,
        }
    },
    ],

    [Algorithms.LightGBM.name]: [{
        label: "Learning Rate",
        key: "learning_rate",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1
        }
    }, {
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Num Leaves",
        key: "num_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 9,
            max: 51
        }
    }, {
        label: "Feature Fraction",
        key: "feature_fraction",
        type: InputTypes.NumberField,
        validation: {
            min: 0.199999,
            max: 1.000000001
        }
    }, {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 19,
            max: 41
        }
    }, {
        label: "Num Iterations",
        key: "num_iterations",
        type: InputTypes.NumberField,
        validation: {
            min: 99,
            max: 1001
        }
    }, {
        label: "Min Data In Leaf",
        key: "min_data_in_leaf",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 31
        }
    },],
};

export const SECONDARY_COLUMNS_CPU_REGRESSION_CONFIG = {
    [Algorithms.XGBoost.name]: [{
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Gamma",
        key: "gamma",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Min Child Weight",
        key: "min_child_weight",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Subsample",
        key: "subsample",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },
    {
        label: "Lambda",
        key: "lambda",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Alpha",
        key: "alpha",
        type: InputTypes.NumberField,
        validation: {}
    },
    {
        label: "Col Sample By Tree",
        key: "colsample_bytree",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1
        }
    },],

    [Algorithms.RandomForest.name]: [
        {
            label: "N Estimators",
            key: "n_estimators",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
                max: 200
            }
        },
        {
            label: "Max Depth",
            key: "max_depth",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            }
        }, {
            label: "Max Features",
            key: "max_features",
            values: [{
                key: "auto",
                label: "auto"
            }, {
                key: "log2",
                label: "log2"
            }, {
                key: "sqrt",
                label: "sqrt"
            }],
            type: InputTypes.SelectBox
        }, {
            label: "Min Sample Leaf",
            key: "min_samples_leaf",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            }
        }, {
            label: "Min Samples Split",
            key: "min_samples_split",
            type: InputTypes.NumberField,
            validation: {
                min: 1,
            }
        }],

    [Algorithms.LinearRegression.name]: [{
        label: "Fit Intercept",
        key: "fit_intercept",
        values: [{
            key: "true",
            label: "true"
        }, {
            key: "false",
            label: "false"
        }],
        type: InputTypes.SelectBox
    }, {
        label: "Normalize",
        key: "normalize",
        values: [{
            key: "true",
            label: "true"
        }, {
            key: "false",
            label: "false"
        }],
        type: InputTypes.SelectBox,
        invisible: (row) => {
            if (row.fit_intercept.includes("false"))
                return true
            else {
                return false
            }
        },
    }],

    [Algorithms.CatBoost.name]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 49,
            max: 1001
        }
    }, {
        label: "Eta",
        key: "eta",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 1.00000000001
        }
    }, {
        label: "L2 Leaf Regularizer",
        key: "l2_leaf_reg",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
        }
    },
    {
        label: "Depth",
        key: "depth",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 17
        }
    },
    {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 0,
            max: 41,
        }
    },
    ],

    [Algorithms.LightGBM.name]: [{
        label: "N Estimators",
        key: "n_estimators",
        type: InputTypes.NumberField,
        validation: {
            min: 1,
            max: 200
        }
    }, {
        label: "Learning Rate",
        key: "learning_rate",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1
        }
    }, {
        label: "Num Leaves",
        key: "num_leaves",
        type: InputTypes.NumberField,
        validation: {
            min: 9,
            max: 51
        }
    }, {
        label: "Bagging Fraction",
        key: "bagging_fraction",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1.000000001
        }
    }, {
        label: "Feature Fraction",
        key: "feature_fraction",
        type: InputTypes.NumberField,
        validation: {
            min: 0.01,
            max: 1.000000001
        }
    }, {
        label: "Max Depth",
        key: "max_depth",
        type: InputTypes.NumberField,
        validation: {
            min: 19,
            max: 41
        }
    }, {
        label: "Min Child Samples",
        key: "min_child_samples",
        type: InputTypes.NumberField,
        validation: {
            min: 19,
            max: 41
        }
    },],
};