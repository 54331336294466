<template>
  <div id="dtp-topbar" class="dtp-fcolor-FFFFFF dtp-topbar-autoMl">
    <div class="flex-item bar-left">
      <span class="dtp-fsize--10" v-if="compName" style="margin-left: 8px">
        <router-link :to="{ name: 'AutoMl' }" v-if="compName">
          <img
            class="logo"
            src="../../assets/images/icons/Logo.svg"
            alt=""
          /> </router-link
      ></span>
    </div>

    <div class="flex-item dtp-flex-horizontal-end dtp-topbar-select">
      <div>
        <img
          @click="system = !system"
          class="dtp-mr--2 dtp-cursor-pointer"
          src="../../assets/images/icons/gauge.svg"
          alt=""
        />
        <SystemPopup v-if="system" />
      </div>
      <div class="dtp-mr--2 dtp-cursor-pointer" @click="logout()">
        <Icon icon="logout"></Icon>
      </div>
      <div class="dtp-name-box">
        <el-popover
          popper-class="user-dropdown"
          placement="top-start"
          trigger="hover"
        >
          <div class="welcome-title dtp-mr--1" slot="reference">
            {{ $t("login.welcome") }},
            <div class="user-title">{{ username }}!</div>
          </div>
          <div class="account-menu dtp-cursor-pointer">
            <span v-if="isLogExportPopupAvailable"
              @click="$emit('showExportLogsPopup')"
              class="dtp-display-block"
              >{{ $t("layout.export_logs") }}</span
            >

            <a
              :href="createHelperLink('')"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span class="dtp-display-block"
                >{{ $t("layout.documentation") }}
              </span>
            </a>

            <span
              @click="$emit('showVersionPopup')"
              class="dtp-display-block"
              >{{ $t("layout.version") }}</span
            >
            <div class="language-menu">
              <div>{{ $t("user.Language Preference") }}</div>
              <el-radio-group v-model="$i18n.locale">
                <el-radio
                  v-for="item in languages"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-popover>
    </div>
  </div>
  </div>
</template>
<script>
import SystemPopup from "../AutoMl/Popup/SystemPopup.vue";
import { LANGUAGE_STORAGE_KEY } from "../../locales/types";
import Icon from "../Icons/Icon.vue";
import componentAvailabilityCheck from '../../mixins/AutoML/componentAvailabilityCheck';
import { PRIVILEGES } from '../../helper/authorization';
import helperLink from '../../mixins/AutoML/helperLink';

export default {
  mixins: [componentAvailabilityCheck,helperLink],
  components: {
    SystemPopup,
    Icon,
  },
  name: "locale-changer",
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    TopbarColor: {
      type: String,
      required: true,
    },
    ConstantMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      system: false,
      modules: [
        { name: "Visualize", link: "Visualization", layout: "Layout" },
        { name: "Auto ML", link: "AutoMl", layout: "AutoMlLayout" },
        { name: "Scenario", link: "Scenario", layout: "ScenarioLayout" },
        { name: "Ingest", link: "Ingest", layout: "IngestLayout" },
      ],
      urlLink: this.$route.meta.layout,
      languages: [
        { label: "Türkçe", value: "tr" },
        { label: "English", value: "en" },
        { label: "Pусский", value: "ru" },
      ],
    };
  },
  mounted() {
    this.$i18n.locale =
      localStorage.getItem(LANGUAGE_STORAGE_KEY) || this.languages[1].value;
  },
  watch: {
    "$i18n.locale": {
      handler(val) {
        localStorage.setItem(LANGUAGE_STORAGE_KEY, val);
      },
    },
  },
  computed: {
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    compName() {
      let compName = false;
      if (
        this.$route.meta.layout == "Layout" ||
        this.$route.meta.layout == "IngestLayout" ||
        this.$route.meta.layout == "ScenarioLayout" ||
        this.$route.meta.layout == "AutoMlLayout"
      ) {
        compName = true;
      }
      return compName;
    },
    isLogExportPopupAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_LOG_EXPORT
      );
    },
  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style>
.dtp-aml-language .el-input__inner {
  font-size: 12px;
}
.dtp-aml-language .el-select .el-input .el-select__caret {
  font-size: 12px;
}
.dtp-user-image {
  position: relative;
  width: 70px;
  padding: 0;
  margin-right: 5px;
  border-radius: 100%;
  background-color: transparent;
  display: inline-block;
}
.dtp-user-image img {
  height: 100%;
  width: 100%;
}
.dtp-user-image span {
  margin-right: 5px;
}
#dtp-topbar .flex-item {
  width: 50%;
}
.dtp-language-change-box {
  min-width: 75px;
  width: 8%;
  display: inline-block;
}
.dtp-name-box {
  display: inline-block;
}
.dtp-name-box .el-input__inner {
  width: auto;
  padding: 0;
}
.dtp-topbar-select .el-input__inner {
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 0;
}

.dtp-topbar-select .el-select .el-input .el-select__caret {
  color: #ffffff;
}

.dtp-topbar-select .el-input__suffix {
  right: 30px;
}
.el-dropdown {
  display: inline-block;
  position: relative;
  color: #ffffff !important;
  font-size: 14px;
  cursor: pointer;
}
.dtp-modules-icon {
  transform: translateY(7px);
}
.dtp-modules-icon-box {
  display: inline-block;
}
.dtp-modules-icon-back {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 100%;
  background-color: #b2b2b2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dtp-modules-icon-back-active .dtp-modules-icon-back {
  background-color: #164f73;
}
.dtp-modules-icon-back-active + span {
  color: #164f73;
  font-weight: 600;
}

@media only screen and (max-width: 999px) {
  #dtp-topbar .flex-item:nth-child(1) {
    width: 60%;
  }
  #dtp-topbar .flex-item:nth-child(2) {
    width: 40%;
  }
}
</style>
<style scoped>
.account-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 13px;
}
.language-menu {
  margin-top: 10px;
}
.language-menu .el-radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  font-size: 10px !important;
}
.welcome-title {
  font-weight: 300 !important;
  font-size: 12px;
}
.user-title {
  font-size: 14px;
}
::v-deep .el-radio__label {
  font-size: 12px !important;
}
.logo {
  display: block;
}
.bar-left {
  display: flex;
  align-items: center;
  margin-left: 85px;
}
</style>
