<template>
  <svg
    id="table-column-plus-after"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="MDI_table-column-plus-after"
      data-name="MDI / table-column-plus-after"
    >
      <g
        id="Boundary"
        fill="#1d4e91"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="16" height="16" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
      </g>
      <path
        id="Path_table-column-plus-after"
        data-name="Path / table-column-plus-after"
        d="M8,2A1.333,1.333,0,0,1,9.333,3.333V14A1.333,1.333,0,0,1,8,15.333H2V2H8M3.333,7.333V10H8V7.333H3.333m0,4V14H8V11.333H3.333m0-8V6H8V3.333H3.333M10.667,8h2V6H14V8h2V9.333H14v2H12.667v-2h-2Z"
        transform="translate(-0.667 -0.667)"
        fill="#1d4e91"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
