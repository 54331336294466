import Service from "../AutomlService";
import { postModelPlotsMock } from "./mocks/postModelPlotsMock"
import { sortControl } from "./utils"

let service = new Service(process.env.VUE_APP_AUTOML_MAIN_API_ENDPOINT);
let serviceLive = new Service(process.env.VUE_APP_AUTOML_LIVE_API_ENDPOINT);

//var responsePlot = "./images/feature_importance.png";
/* var MODEL_ROWS = [
  {
    id: 1,
    check: false,
    model: "LinearRegression",
    rmse: "314.91",
    mae: "183.75",
    mse: "99165.53",
    medae: "144.57",
    r2score: "0.79",
    trainingtime: "1Min27Sec",
  },
  {
    id: 2,
    check: false,
    model: "DecisionTreeRegressor",
    rmse: "551.86",
    mae: "149.67",
    mse: "304546.37",
    medae: "30.0",
    r2score: "0.82",
    trainingtime: "2Min9Sec",
  },
  {
    id: 3,
    check: false,
    model: "XGBoostRegressor",
    rmse: "356.77",
    mae: "131.5",
    mse: "127284.31",
    medae: "30.17",
    r2score: "0.76",
    trainingtime: "1Min30Sec",
  },
]; */

/* var MODEL_ROWS1 = [
  {
    id: 1,
    check: false,
    model: "LogisticRegression",
    accuary: "0.73",
    classification: "0.27",
    auc: "0.78",
    precision: "0.56",
    recall: "0.29",
    fmeasure: "0.38",
    trainingtime: "2Min4Sec",
  },
  {
    id: 2,
    check: false,
    model: "DecisionTreeClassifier",
    accuary: "0.77",
    classification: "0.23",
    auc: "0.74",
    precision: "0.58",
    recall: "0.62",
    fmeasure: "0.6",
    trainingtime: "1Min9Sec",
  },
  {
    id: 3,
    check: false,
    model: "XGBoostClassifier",
    accuary: "0.82",
    classification: "0.18",
    auc: "0.85",
    precision: "0.7636",
    recall: "0.59",
    fmeasure: "0.65",
    trainingtime: "2Min21Sec",
  },
]; */

/* var MODEL_ROWS2 = {
  "data": [
    {
      "model": "Extreme Gradient Boosting",
      "model_id": "logistic_regression1",
      "test": {
        "accuracy": 0.87,
        "accuracy_sd": 0.01,
        "classification_error": 0.13,
        "auc": 0.8412,
        "precision": 0.588,
        "recall": 0.733,
        "f1": 0.6521,
        "kappa": 0.2672,
        "mcc": 0.2834,
        "training_time": 62,
        "highlights": [
          "auc",
          "f1"
        ]
      },
      "train": {
        "accuracy": 0.87,
        "accuracy_sd": 0.01,
        "classification_error": 0.13,
        "auc": 0.8412,
        "precision": 0.588,
        "recall": 0.733,
        "f1": 0.6521,
        "kappa": 0.2672,
        "mcc": 0.2834,
        "training_time": 62,
        "highlights": [
          "auc",
          "f1"
        ]
      }
    },
    {
      "model": "Extreme Gradient Boosting",
      "model_id": "logistic_regression1",
      "test": {
        "accuracy": 0.87,
        "accuracy_sd": 0.01,
        "classification_error": 0.13,
        "auc": 0.8412,
        "precision": 0.588,
        "recall": 0.733,
        "f1": 0.6521,
        "kappa": 0.2672,
        "mcc": 0.2834,
        "training_time": 62,
        "highlights": [
          "auc",
          "f1"
        ]
      },
      "train": {
        "accuracy": 0.87,
        "accuracy_sd": 0.01,
        "classification_error": 0.13,
        "auc": 0.8412,
        "precision": 0.588,
        "recall": 0.733,
        "f1": 0.6521,
        "kappa": 0.2672,
        "mcc": 0.2834,
        "training_time": 62,
        "highlights": [
          "auc",
          "f1"
        ]
      }
    }
  ]
} */

/* var modelPlot = {
  "data": {
    "experiment_status": "string",
    "list": [
      {
        model_id: "xgboost_0",
        plot_path: "./FeatureImportance/images/feat_importance_XGBoostRegressor.png",
        plot_type: "feature"
      },
      {
        model_id: "Decision-Fatih",
        plot_path: "feat_importance1_DecisionTreeClassifier",
        plot_type: "feature"
      },
      {
        model_id: "xgboost_0",
        plot_path: "roccurve_XGBoostClassifier",
        plot_type: "auc"
      },
      {
        model_id: "XGBoost Regressor model",
        plot_path: "feat_importance1_XGBoostClassifier",
        plot_type: "confusion_matrix"
      }
    ]
  }
} */

export async function uploadAudit(model_id, formData) {
  let promise = new Promise((resolve, reject) => {
    service.postFormData(
      `/models/${model_id}/audit`,
      formData,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });

  return promise;
}

export async function fetchAudit(modelId) {
  let promise = new Promise((resolve, reject) => {
    service.get(`/models/${modelId}/audit`, (response) => {
      resolve(response.data);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function fetchModelExplanationStatus(model_id) {
  return new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/explanation/status`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function fetchModelExplanationSamples(model_id) {
  return new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/explanation/samples`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function fetchCustomModels({ page = 1, count = 10 }) {
  return new Promise((resolve, reject) => {
    service.get(
      `/custom-models?page=${page}&count=${count}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function fetchCustomModelById(modelId) {
  return new Promise((resolve, reject) => {
    service.get(
      `/custom-models/${modelId}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function defineCustomModel(formData) {
  let promise = new Promise((resolve, reject) => {
    service.postFormData(
      `/custom-models`,
      formData,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });

  return promise;
}

export async function retryCustomModel(id) {
  return new Promise((resolve, reject) => {
    service.post(
      `/custom-models/${id}/retry`,
      {},
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function retrainCustomModel(id, params) {
  return new Promise((resolve, reject) => {
    service.post(
      `/models/${id}/retrain`,
      params,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function deleteCustomModel(id) {
  let promise = new Promise((resolve, reject) => {
    service.delete(
      `/custom-models/${id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}


/**
 * Examples:  
  {
    "plot_type": "shap_summary",
    "params": {}
  }  
  
  or 

  {
    "plot_type": "shap_correlation",
    "params": {
      "features_list": [
        "age"
      ]
    }
  }
  
  or 
  
  {
    "plot_type": "shap_reason",
    "params": {
      "rows_list": [
        100
      ]
    }
  }
 */
export async function postModelExplanationPlot({ model_id, plot_type, params }) {
  return new Promise((resolve, reject) => {
    service.post(
      `/models/${model_id}/explanation/plot`,
      { plot_type, params },
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function getModelEquation(model_id) {
  return new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/equation`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function getModelResulsList(problemType, data, experimentId) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(`/experiments/${experimentId}/model/results`, (response) => {
      resolve(response.data);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function fetchModelResultsMain(experimentId) {
  let promise = new Promise((resolve, reject) => {
    service.get(`/experiments/${experimentId}/model-results`, (response) => {
      resolve(response.data);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function fetchChallengerModelResults(experimentId) {
  let promise = new Promise((resolve, reject) => {
    service.get(`/experiments/${experimentId}/challenger-results`, (response) => {
      resolve(response.data);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function fetchChallengerModelStatus(experimentId) {
  let promise = new Promise((resolve, reject) => {
    service.get(`/experiments/${experimentId}/challenger-status`, (response) => {
      resolve(response.data);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function createChallengerModel({ experiment_id, params }) {
  return new Promise((resolve, reject) => {
    service.post(
      `/experiments/${experiment_id}/challenger`,
      params,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function fetchModelPlotMain(experimentId, params) {
  let promise = new Promise((resolve, reject) => {
    service.post(`/experiments/${experimentId}/model-plots`,
      params,
      (response) => {
        resolve(response.data);
      }, error => {
        reject(error);
      });
  });
  return promise;
}


export async function getModelsList({ page = 1, count = 10, experimentName = "", ...otherParams }) {
  const vars = {
    page,
    count,
    experiment_name: experimentName,
  };

  const allParams = { ...vars, ...otherParams }
  const params = sortControl(allParams)

  return new Promise((resolve, reject) => {
    service.get(
      `/models?${params.toString()}`,
      (response) => {
        response?.data?.data?.forEach(element => {
          element.monitoring_date = new Date("1999-01-01T01:01:01.084000");
        });
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export async function getModelsListByExperimentId(experimentId, page, count) {
  return new Promise((resolve, reject) => {
    service.get(
      `/models?experiment_id=${experimentId}&page=${page}&count=${count}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function getModelsListByModelId(model_id, page, count) {
  return new Promise((resolve, reject) => {
    service.get(
      `/models?model_id=${model_id}&page=${page}&count=${count}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export async function getModelTrainingProgress(experiment_id) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.get(`/experiments/${experiment_id}/model/training/progress`, (response) => {
      resolve(response);
    }, error => {
      reject(error);
    });
  });
  return promise;
  // return new Promise((resolve) => {
  //   resolve(MODEL_ROWS);
  // });
}

export async function problemConfiguration(experiment_id, data) {
  const copyOfData = Object.assign({}, data)

  if (copyOfData.cross_validation_number_of_folds <= 1) {
    copyOfData.cross_validation_number_of_folds = null;
  }

  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${experiment_id}/model/problem-configuration-temp`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
  // console.log("1");
  // if (data == "Income Data") {
  //   console.log("2");
  //   console.log(PROBLEM_CONF1);
  //   return new Promise((resolve) => {
  //     resolve(PROBLEM_CONF1);
  //   });
  // } else {
  //   console.log("3");
  //   return new Promise((resolve) => {
  //     resolve(PROBLEM_CONF2);
  //   });
  // }
}

export async function modelSelection(data) {
  let promise = new Promise((resolve, reject) => {
    service.put(
      `/model/training`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function deleteModelTraining(experimentId) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.delete(
      `/experiments/${experimentId}/model/training`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function runModels(experimentId, data) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${experimentId}/model/training`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function stopTraining(experimentId) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(
      `/experiments/${experimentId}/model/training/stop`, //Could not find endpoint update TODO
      {},
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function modelTuning(params) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.put(
      `/experiments/${params.experimentId}/model/tuning`,
      params.data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function downloadPredictionResults(artifact_uri) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/download/test?artifact_uri=${artifact_uri}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function makePredicNewData(model_id, data) {
  let promise = new Promise((resolve, reject) => {
    service.put(
      `/models/${model_id}/predictions`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}


export async function makeModelValidations(model_id, params) {
  return new Promise((resolve, reject) => {
    service.post(
      `/models/${model_id}/validations`,
      params,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function getModelValidations(model_id) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/validations`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getTTestResult(model_id, validation_id) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/validations/${validation_id}/t-test-results`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function getBinomialTestResult(model_id, validation_id) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/${model_id}/validations/${validation_id}/binomial-test-results`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function fetchPredictions(modelId, page, count) {
  const params = new URLSearchParams({
    model_id: modelId,
    page,
    count,
  });
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/predictions?${params.toString()}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function modelDownload(payload) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/download?artifact_uri=${payload.artifact_uri}&&experiment_id=${payload.experiment_id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function fetchModelRequestInfo(modelId) {
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/${modelId}/request-info`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}

export async function postModelPlots(data, experimentId) {
  let promise = new Promise((resolve, reject) => {
    serviceLive.post(`/experiments/${experimentId}/model/plots`, data, (response) => {
      response.data.data.list.push(postModelPlotsMock)
      resolve(response);
    }, error => {
      reject(error);
    });
  });
  return promise;
}

export async function updateDescriptionByModelId(modelId, data) {
  let promise = new Promise((resolve, reject) => {
    service.put(`/models/${modelId}`, data, (response) => {
      resolve(response)
    }, error => {
      reject(error)
    });
  })
  return promise;
}
export async function getLogExport(page=1,count=20){
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/log-export?page=${page}&count=${count}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function postLogExport(data){
  let promise = new Promise((resolve, reject) => {
    service.post(
      `/models/log-export`,
      data,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function deleteLogExport(id){
  let promise = new Promise((resolve, reject) => {
    service.delete(
      `/models/log-export/${id}`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}
export async function checkLogExportStatus(id){
  let promise = new Promise((resolve, reject) => {
    service.get(
      `/models/log-export/${id}/status`,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
  return promise;
}