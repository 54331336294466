export const domain = "AUTOML/NAVIGATION";

export const NAVIGATION_ACTIONS = {
}

export const NAVIGATION_MUTATIONS = {
  SET_ON_GOING_OPERATION: `${domain}/setOnGoingOperation`,
  SET_NAVIGATION_LIST: `${domain}/setNavigationList`,
  RESET_NAVIGATION_LIST: `${domain}/resetNavigationList`,
  SET_NAVIGATION_COUNT_LIST: `${domain}/setNavigationCountList`,
  SET_LAST_STEP: `${domain}/setLastStep`,
  SET_FEATURE_IMPORTANCE_OPERATION_ID: `${domain}/setFeatureImportanceOperationId`,
  REMOVE_ON_GOING_OPERATION: `${domain}/removeOnGoingOperation`,
  CLEAR_ON_GOING_OPERATIONS: `${domain}/clearOnGoingOperations`,
  SET_ROUTE_NAME: `${domain}/setRouteName`,
}

export const NAVIGATION_GETTERS = {
  GET_NAVIGATION_LIST: `${domain}/getNavigationList`,
  GET_NAVIGATION_LAST_STEP: `${domain}/getNavigationLastStep`,
  GET_FEATURE_IMPORTANCE_OPERATION_ID: `${domain}/getFeatureImportanceOperationId`,
  GET_ON_GOING_OPERATIONS: `${domain}/getOnGoingOperations`,
  GET_ROUTE_NAME: `${domain}/getRouteName`,
}