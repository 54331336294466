export const query = {
  state: () => ({
    formData: {
      "id": "",
      "name": "",
      "description": "",
      "datasourceId": "",
      "datasources": [],
      "sql": "",
      "cumulative": false
    }
  }),
  mutations: {
    "Query/Mutate/FormData": (state, val) => {
      state.formData = {
        id: val.id || "",
        name: val.name || "",
        description: val.description || "",
        datasourceId: val.datasourceId || "",
        datasources: val.datasources || "",
        sql: val.sql || "",
        cumulative: val.cumulative || false
      }
    },
    "Query/Mutate/FormData/Id": (state, val) => {
      state.formData.id = val
    },
    "Query/Mutate/FormData/Name": (state, val) => {
      state.formData.name = val
    },
    "Query/Mutate/FormData/Description": (state, val) => {
      state.formData.description = val
    },
    "Query/Mutate/FormData/DatasourceId": (state, val) => {
      state.formData.datasourceId = val
    },
    "Query/Mutate/FormData/Datasources": (state, val) => {
      state.formData.datasources = val
    },
    "Query/Mutate/FormData/Sql": (state, val) => {
      state.formData.sql = val
    },
    "Query/Mutate/FormData/Cumulative": (state, val) => {
      state.formData.cumulative = val
    }
  },
  actions: {
    "Query/Set/FormData": ({ commit }, val) => {
      commit("Query/Mutate/FormData", val)
    },
    "Query/Set/FormData/Id": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Id", val)
    },
    "Query/Set/FormData/Name": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Name", val)
    },
    "Query/Set/FormData/Description": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Description", val)
    },
    "Query/Set/FormData/DatasourceId": ({ commit }, val) => {
      commit("Query/Mutate/FormData/DatasourceId", val)
    },
    "Query/Set/FormData/Datasources": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Datasources", val)
    },
    "Query/Set/FormData/Sql": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Sql", val)
    },
    "Query/Set/FormData/Cumulative": ({ commit }, val) => {
      commit("Query/Mutate/FormData/Cumulative", val)
    }
  },
  getters: {
    "Query/Get/FormData": (state) => {
      return state.formData
    },
    "Query/Get/FormData/Id": (state) => {
      return state.formData.id
    },
    "Query/Get/FormData/Name": (state) => {
      return state.formData.name
    },
    "Query/Get/FormData/Description": (state) => {
      return state.formData.description
    },
    "Query/Get/FormData/DatasourceId": (state) => {
      return state.formData.datasourceId
    },
    "Query/Get/FormData/Datasources": (state) => {
      return state.formData.datasources
    },
    "Query/Get/FormData/Sql": (state) => {
      return state.formData.sql
    },
    "Query/Get/FormData/Cumulative": (state) => {
      return state.formData.cumulative
    }
  }
}
