<template>
  <div class="dtp-aml-info-card">
    <div @click="$emit('closePopup', false)" class="dtp-aml-close-icon">
      <i class="el-icon-close" aria-hidden="true"></i>
    </div>
    <div v-for="item in InfoList" :key="item" class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t(`${item.title}`) }}</span>
      <p class="dtp-aml-info-card-content">{{ item.text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    InfoList: {
      type: Array,
    },
  },

  data() {
    return {};
  },
};
</script>
<style scoped>
.dtp-aml-close-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #8c8c8c;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  z-index: 1;
}

.dtp-aml-info-card {
  position: absolute;
  right: 15px;
  top: 40px;
  width: 320px;
  height: auto;
  margin: 0;
  padding: 30px 30px 0 30px;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px #0000001d;
  z-index: 2;
}
.dtp-aml-info-card-title {
  font-size: 14px;
  font-weight: bold;
  color: #4968a2;
  margin-bottom: 4px;
}
.dtp-aml-info-card-content {
  font-size: 12px;
  color: #515f67;
  line-height: 25px;
}
</style>
