import { PRIVILEGES } from "./src/helper/authorization";

export const Environments = {
  PROD: "PROD",
  FAZ1: "FAZ1",
  TEST2: "TEST2",
  TEST: "TEST",
};

export const HiddenComponentsByEnv = {
  [Environments.PROD]: [
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON,
    PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS,
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_AUDIT,
    PRIVILEGES.AUTOML_CUSTOMER_LOGO,
   // PRIVILEGES.AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX,
   // PRIVILEGES.AUTOML_COMPONENT_MODEL_REPORTS_BUTTON,
    PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_VALIDATION_REPORT,
    PRIVILEGES.AUTOML_COMPONENT_LOG_EXPORT,
  ],
  [Environments.FAZ1]: [],
  [Environments.TEST2]: [
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON,
    PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS,
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_AUDIT,
    PRIVILEGES.AUTOML_CUSTOMER_LOGO,
    PRIVILEGES.AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX,
    PRIVILEGES.AUTOML_COMPONENT_MODEL_REPORTS_BUTTON,
  ],
  [Environments.TEST]: [
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON,
    PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS,
    // PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_AUDIT,
    PRIVILEGES.AUTOML_CUSTOMER_LOGO,
  ],
};
