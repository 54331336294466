export const datasetCol = [
    {
        label: "table.header.project_name",
        field: "project_name",
    },
    {
        label: "table.header.experiment_name",
        field: "name",
    },
    {
        label: "table.header.dataset_name",
        field: "dataset_name",
    },
    {
        label: "table.header.target",
        field: "target_column",
        sortable: false,
    },
    {
        label: "table.header.performance_metric",
        field: "scorer",
        sortable: false,
    },
    {
        label: "table.header.train_score",
        field: "train_score",
    },
    {
        label: "table.header.test_score",
        field: "test_score",
    },
    {
        label: "table.header.total_training_time",
        field: "total_training_time",
    },
    {
        label: "table.header.main_root",
        field: "challenger_root_name",
        sortable: false,
    },
    {
        label: "table.header.created_user",
        field: "username",
        sortable: false,
    },
    {
        label: "table.header.created_date",
        field: "created_at",
        width: "150px",
    },
    {
        label: "table.header.status",
        field: "status",
        sortable: false,
    },
    {
        label: "table.header.actions",
        field: "actions",
        sortable: false,
        width: "150px",
    }
]