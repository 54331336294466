<template>
  <div class="dtp-aml-info-card">
    <div @click="$emit('closePopup', false)" class="dtp-aml-close-icon">
      <i class="el-icon-close" aria-hidden="true"></i>
    </div>
    <div class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t("project." + `${ExperimentInfo[0].title}`) }}</span>
      <p class="dtp-aml-info-card-content">{{ ExperimentInfo[0].text }}</p>
    </div>
    <div class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{  $t(`${ExperimentInfo[1].title}`) }}</span>
      <p class="dtp-aml-info-card-content">{{ ExperimentInfo[1].text }}</p>
    </div>
    <div class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t("placeholder." + `${ExperimentInfo[2].title}`) }}</span>
      <p class="dtp-aml-info-card-content">{{ ExperimentInfo[2].text }}</p>
    </div>
    <div v-show="problemType" class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t("placeholder." + "Problem Type") }}</span>
      <p class="dtp-aml-info-card-content">
        {{ problemType }}
      </p>
    </div>
    <div v-show="trainRatio" class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t("Train Ratio") }}</span>
      <p class="dtp-aml-info-card-content">
        {{ trainRatio }}
      </p>
    </div>
    <div v-show="targetVal" class="dtp-chart-box">
      <span class="dtp-aml-info-card-title">{{ $t("Target Value") }}</span>
      <p class="dtp-aml-info-card-content">
        {{ targetVal }}
      </p>
    </div>
  </div>
</template>
<script>
import pipeline from "../../../mixins/AutoML/pipeline";
import notify from "../../../mixins/AutoML/notify";
export default {
  mixins: [pipeline, notify],
  props: {
    InfoList: {
      type: Array,
    },
    selectedExperiment: {
      type: Array,
    },
  },
  async mounted() {
    this.requestComp = "ExpSummary";
    await this.fetchPipeline({
      requestComp: this.requestComp,
      experiment_id: this.selectedExperiment[0].id,
      project_id: this.selectedExperiment[0].project_id,
    });
  },
  computed: {
    problemType() {
      return this.problem_conf?.type;
    },
    trainRatio() {
      return this.problem_conf?.train_ratio;
    },
    targetVal() {
      return this.problem_conf?.target;
    },
  },
  data() {
    return {
      ExperimentInfo: [
        {
          title: "Project Name",
          text: this.selectedExperiment[0].project_name,
        },
        { title: "Experiment Name", text: this.selectedExperiment[0].name },
        {
          title: "Dataset Name",
          text: this.selectedExperiment[0].dataset_name,
        },
      ],
    };
  },
};
</script>
<style scoped>
.dtp-aml-close-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #8c8c8c;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  z-index: 1;
}

.dtp-aml-info-card {
  position: absolute;
  right: 15px;
  top: 40px;
  width: 320px;
  height: auto;
  margin: 0;
  padding: 30px 30px 0 30px;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px #0000001d;
  z-index: 2;
}
.dtp-aml-info-card-title {
  font-size: 14px;
  font-weight: bold;
  color: #4968a2;
  margin-bottom: 4px;
}
.dtp-aml-info-card-content {
  font-size: 12px;
  color: #515f67;
  line-height: 25px;
}
</style>