import { vuexActionHandler } from "../../../../utils/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import Vue from "vue";
import { CLUSTER_ACTIONS, CLUSTER_GETTERS, CLUSTER_MUTATIONS } from "./types";

export const clusterStore = {
    state: {
      clusters: [],
    },
    getters: {
      [CLUSTER_GETTERS.GET_CLUSTERS]: (state) => {
        return state.clusters;
      },
    },
    mutations: {
      [CLUSTER_MUTATIONS.SET_CLUSTERS](state, val) {
        Vue.set(state, "clusters", val);
      },
    },
    actions: {
      [CLUSTER_ACTIONS.CREATE_CLUSTER]: async ({ commit, dispatch }, params) => {
        const { requestComp, ...otherParams } = params;
        const result = await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.clusters,
          serviceMethodSub: serviceMethodSub.createCluster,
          bodyParam: otherParams,
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(CLUSTER_ACTIONS.FETCH_CLUSTERS, { requestComp });
          }
        });
  
        return result && true;
      },
      [CLUSTER_ACTIONS.DELETE_CLUSTER]: async ({ commit, dispatch }, params) => {
        const { requestComp, cluster_id } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.clusters,
          serviceMethodSub: serviceMethodSub.deleteCluster,
          queryParam: cluster_id,
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(CLUSTER_ACTIONS.FETCH_CLUSTERS, { requestComp });
          }
        });
      },
      [CLUSTER_ACTIONS.FETCH_CLUSTERS]: async ({ commit }, params) => {
        const { requestComp } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.clusters,
          serviceMethodSub: serviceMethodSub.fetchClusters,
          loadingComponent: requestComp,
          successMutation: CLUSTER_MUTATIONS.SET_CLUSTERS,
          withSuccessNotify: false,
          resultSelector: (result) => result?.data?.data
        });
      },
      [CLUSTER_ACTIONS.REPAIR_CLUSTER]: async ({ commit, dispatch }, params) => {
        const { requestComp, cluster_id } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.clusters,
          serviceMethodSub: serviceMethodSub.repairCluster,
          queryParam: cluster_id,
          bodyParam: {},
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(CLUSTER_ACTIONS.FETCH_CLUSTERS, { requestComp });
          }
        });
      },
      [CLUSTER_ACTIONS.UPDATE_CLUSTER]: async ({ commit, dispatch }, params) => {
        const { requestComp, cluster_id, ...otherParams } = params;
        await vuexActionHandler({
          commit,
          serviceMethodParent: serviceMethodParent.clusters,
          serviceMethodSub: serviceMethodSub.updateCluster,
          queryParam: cluster_id,
          bodyParam: otherParams,
          loadingComponent: requestComp,
          successCallback: async () => {
            await dispatch(CLUSTER_ACTIONS.FETCH_CLUSTERS, { requestComp });
          }
        });
      },
    }
}