<template>
  <div>
    <el-table
      class="dtp-accordion-table"
      :data="rows"
      style="width: 100%"
      :cell-class-name="getCellClassName"
      row-class-name="no-hover"
      @selection-change="handleSelectionChange"
      ref="treetable"
    >
      >
      <el-table-column type="expand" width="1">
        <template slot-scope="props">
          <tune-model-expand-content
            v-if="props.row.actionType === 'grid'"
            :modelType="props.row.model"
          />
        </template>
      </el-table-column>
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column :label="$t('table.header.Model')" prop="model" width="100">
      </el-table-column>
      <el-table-column :label="$t('table.header.Actions')" width="150">
        <template slot-scope="props">
          <span class="aml-table-row-radioInput" style="display: grid">
            <el-radio-group
              :disabled="!props.row.check"
              v-model="props.row.actionType"
              @change="(val) => toggleExpand(val, props.row)"
            >
              <el-radio label="auto">{{ $t('experiment.Auto Optimize') }}</el-radio>
              <el-radio label="grid" style="margin-top: 5px"
                >{{ $t('experiment.Grid Search') }}</el-radio
              >
            </el-radio-group>
          </span></template
        >
      </el-table-column>

      <el-table-column :label="$t('table.header.Scores')" align="center">
        <el-table-column
          :label="metricLabels[scoreName]"
          prop="scores"
          align="center"
          v-for="scoreName in scoreNames"
          :key="scoreName"
        >
          <el-table-column
            :label="$t('table.header.Train')"
            align="center"
            :prop="
              JSON.stringify({
                scoreName,
                scoreHighlightType: 'Train_Highlight',
              })
            "
            :formatter="(row) => cellFormatter(row, scoreName, 'Train')"
          >
          </el-table-column>
          <el-table-column
            :label="$t('table.header.Test')"
            align="center"
            :prop="
              JSON.stringify({
                scoreName,
                scoreHighlightType: 'Test_Highlight',
              })
            "
            :formatter="(row) => cellFormatter(row, scoreName, 'Test')"
          >
          </el-table-column>
        </el-table-column>
      </el-table-column>

      <el-table-column :label="$t('table.header.Iteration Count')" prop="n_iteration" width="200">
        <template slot-scope="props">
          <EditableText
            :editable="props.row.check"
            :row="props.row"
            cellPlaceholder="10"
            :fieldName="columnFields.n_iteration"
            idName="model_id"
            inputType="number"
            @applyClicked="(row) => changeIterationCount(row)"
          >
          </EditableText></template
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
import EditableText from "../EditableCell/EditableText.vue";
import TuneModelExpandContent from "./TuneModelExpandContent.vue";
import { mapGetters, mapMutations } from "vuex";
import { metricLabels } from "./metricTypes";
import { MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  components: {
    EditableText,
    TuneModelExpandContent,
  },
  props: {
    scoreNames: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      metricLabels: metricLabels,
      tableId: null,
      columnFields: {
        n_iteration: "n_iteration",
        check: "check",
      },
      failed: "FAILED",
      openExpands: [],
    };
  },
  computed: {
    ...mapGetters({
      getTuneModel: MODELS_GETTERS.GET_MODEL_TUNING,
    }),
    allModels() {
      return this.rows.map((rs) => rs.model);
    },
  },
  methods: {
    ...mapMutations({
      setModelTuning: MODELS_MUTATIONS.SET_MODEL_TUNING,
    }),
    changeIterationCount(row) {
      let tuneModel = JSON.parse(JSON.stringify(this.getTuneModel));
      tuneModel?.algorithms?.forEach((tm) => {
        if (tm.name === row.model) {
          tm.n_iteration = Number(row.n_iteration);
        }
      });
      this.setModelTuning(tuneModel);
      this.tableId = null;
    },
    handleSelectionChange(val) {
      let tuneModel = JSON.parse(JSON.stringify(this.getTuneModel));
      const selectedModels = val.map((v) => v.model);
      this.$emit("rowChecked", selectedModels);
      this.removeExpand(selectedModels);

      tuneModel.algorithms = tuneModel.algorithms.filter((a) =>
        selectedModels.includes(a.name)
      ); //remove selection
      this.setModelTuning(tuneModel);

      tuneModel = JSON.parse(JSON.stringify(tuneModel));

      const currentAlgorithms = tuneModel.algorithms.map((a) => a.name);

      selectedModels.forEach((sm) => {
        if (!currentAlgorithms.includes(sm)) {
          tuneModel.algorithms.push({
            name: sm,
            n_iteration: 10,
            optimization: [],
            actionType: "auto",
          });
          this.setModelTuning(tuneModel);
        }
      });
    },

    updateActionTypeStore(val, model) {
      const tuneModel = JSON.parse(JSON.stringify(this.getTuneModel));
      tuneModel.algorithms.forEach((a) => {
        if (a.name === model) a.actionType = val;
      });
      this.setModelTuning(tuneModel);
    },
    removeExpand(selectedModels) {
      this.allModels.forEach((model) => {
        if (!selectedModels.includes(model)) {
          const index = this.openExpands.indexOf(model);
          if (index > -1) {
            let $table = this.$refs.treetable;
            $table.toggleRowExpansion(this.rows.find((r) => r.model === model));
            this.openExpands.splice(index, 1);
          }
        }
      });
    },
    getScoreField(props, scoreName, field) {
      return props.row?.[scoreName]?.[field];
    },

    cellFormatter(row, scoreName, scoreType) {
      return row.scores[scoreName][scoreType];
    },

    getCellClassName({ row, column }) {
      if (column.property) {
        try {
          const parsed = JSON.parse(column.property);

          if (row[parsed.scoreHighlightType]?.includes(parsed.scoreName)) {
            return "table-cell-highlighted";
          }
        } catch (e) {
          return "";
        }
      }
    },
    toggleExpand(val, row) {
      if (val === "grid") this.openExpands.push(row.model);
      else {
        const index = this.openExpands.indexOf(row.model);
        if (index > -1) {
          this.openExpands.splice(index, 1);
        }
      }

      this.updateActionTypeStore(val, row.model);

      let $table = this.$refs.treetable;
      $table.toggleRowExpansion(row);
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep .no-hover:hover > td {
  background-color: transparent;
}
::v-deep .table-cell-highlighted {
  color: #ffffff;
  background-color: #436da9 !important;
}

.dtp-accordion-table thead tr th {
  background-color: #b2c4dc !important;
  border: 1px solid #dcdfe6 !important;
}
.dtp-accordion-table .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #939393;
}
.dtp-accordion-table .el-checkbox__input.is-checked .el-checkbox__inner,
.dtp-accordion-table .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #426da9;
  border-color: #426da9;
}
.dtp-accordion-table .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
}
.dtp-accordion-table
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner::before {
  top: 7px;
}
.el-table {
  font-size: 12px !important;
  border: 1px solid #d6d6d6 !important;
}

.el-table td {
  border: 1px solid #dcdfe6 !important;
  padding: 10px 0 !important;
}
.el-table th > .cell {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.el-table th.is-leaf {
  border-bottom: none !important;
  border-top: none !important;
}
.dtp-accordion-table thead tr th div {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
}

.dtp-accordion-table .el-table__row:nth-of-type(2n) {
  background-color: rgba(246, 246, 246, 1);
}

::v-deep .el-table__expand-icon {
  display: none;
}

::v-deep .el-radio__input .is-checked + .el-radio__inner {
  border-color: #436da9 !important;
  background: #436da9 !important;
}
</style>
