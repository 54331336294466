<template>
  <svg
    id="note-text-outline"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="21.714"
    viewBox="0 0 19 21.714"
  >
    <g
      id="Boundary"
      fill="#1d4e91"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="19" height="21.714" stroke="none" />
      <rect x="0.5" y="0.5" width="18" height="20.714" fill="none" />
    </g>
    <path
      id="Path_note-text-outline"
      data-name="Path / note-text-outline"
      d="M12.048,3H4.508A1.68,1.68,0,0,0,3,4.81V17.476a1.68,1.68,0,0,0,1.508,1.81H15.063a1.68,1.68,0,0,0,1.508-1.81V8.429L12.048,3m3.016,14.476H4.508V4.81h6.786V9.333h3.77m-1.508,3.619H6.016v-1.81h7.54m-2.262,4.524H6.016v-1.81h5.278"
      transform="translate(-0.286 -0.286)"
      fill="#1d4e91"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
