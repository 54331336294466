import { ServiceContainer } from '../api/ServiceContainer';
import { GENERAL_MUTATIONS } from '../store/modules/AutoML/General/types';
import store from "../store";

const notify = (details) => {
    // TODO: implement dap's notifier logic
    store.commit(GENERAL_MUTATIONS.ADD_NOTIFY, details);
}

export const vuexActionHandler = async (
    {
        commit, //required
        serviceMethodParent, //required
        serviceMethodSub, //required
        successMutation,
        errorMutation,
        resultSelector,
        queryParam,
        bodyParam,
        withLoading = true,
        withSuccessNotify = true,
        withErrorNotify = true,
        loadingComponent,
        successCallback,
        errorCallback,
    }
) => {
    if (withLoading)
        commit(GENERAL_MUTATIONS.SET_LOADING, true);
        
    let result = null;
    
    try {
        if (queryParam && bodyParam)
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](queryParam, bodyParam);
        else if (queryParam && !bodyParam)
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](queryParam);
        else if (!queryParam && bodyParam)
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub](bodyParam);
        else
            result = await ServiceContainer.getInstance()[serviceMethodParent][serviceMethodSub]();
        
        const resultParsed = resultSelector ? resultSelector(result) : result;
        
        successMutation && commit(successMutation, resultParsed);

        successCallback && successCallback(result);

        withSuccessNotify && notify({ ...result, requestComp: loadingComponent, serviceType: serviceMethodSub });
        
        return result;
    } catch (error) {
        errorMutation && commit(errorMutation, error);

        withErrorNotify && notify({ ...error, requestComp: loadingComponent, serviceType: serviceMethodSub });

        errorCallback && errorCallback(error);

        return error;
    } finally {
        if (withLoading)
            commit(GENERAL_MUTATIONS.SET_LOADING, false);
    }
}