 <template>
  <div>
    <div
      v-if="rowData[idName] == selectedRowId"
      class="eds-field_#control eds-field_.eds-input eds-input dtp-selectbox"
    >
      <select
        v-model="rowData[fieldName]"
        class="eds-input_#input eds-input_#input.dropdown"
        :class="{
          'dtp-vgt-table-form-input-active': rowData[idName] == selectedRowId,
        }"
      >
        <option v-for="item in selectboxItems" :key="item.id" :value="item.id">
          {{ item.label }}
        </option>
      </select>
    </div>
    <div v-else class="dtp-selectbox">
      <input
        class="dtp-vgt-table-form-input dtp-input"
        type="text"
        :placeholder="choosePlaceholder"
        :class="{
          'dtp-vgt-table-form-input-active': rowData[idName] == selectedRowId,
        }"
        :disabled="rowData[idName] != selectedRowId"
        @change="(val) => (rowData[fieldName] = val)"
        :value="findLabel"
        required
        @click.stop
      />
    </div>
    <img
      v-if="rowData[idName] != selectedRowId"
      @click.stop="$emit('rowEditClicked', rowData[idName])"
      src="../../../assets/images/icons/automl-edit-icon.svg"
      class="dtp-cursor-pointer dtp-vgt-actions-icon dtp-float--right"
      alt="editIcon"
    />
    <span
      class="dtp-cursor-pointer dtp-float--right dtp-font-bold dtp-span"
      v-else
      @click.stop="$emit('applyClicked', rowData)"
      >{{ cellInfo.ok }}</span
    >
  </div>
</template>
<script>
import "vue-good-table/dist/vue-good-table.css";
import { cellInfo as cellInfos } from "./cellTypes";

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    selectboxItems: {
      type: Array,
      required: true,
    },
    selectedRowId: {
      type: [Number, String],
    },
    idName: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    selectboxType: {
      type: String,
    },
    cellPlaceHolder: {
      type: String,
    },
  },
  watch: {
    row: {
      deep: true,
      handler() {
        this.setRowData();
      },
    },
  },
  data() {
    return { rowData: {} };
  },
  mounted() {
    this.setRowData();
  },
  computed: {
    cellInfo() {
      return cellInfos;
    },
    findLabel() {
      return this.selectboxItems.find(
        (s) => s.id === this.rowData[this.fieldName]
      )?.label;
    },
    choosePlaceholder() {
      if (!this.rowData[this.selectboxType]) {
        return this.cellPlaceHolder;
      } else {
        return this.rowData[this.selectboxType];
      }
    },
  },
  methods: {
    setRowData() {
      this.$set(this, "rowData", JSON.parse(JSON.stringify(this.row)));
    },
  },
};
</script>
<style scoped>
.dtp-selectbox {
  display: inline-block;
  width: 80%;
}
.dtp-span {
  position: relative;
  top: 7px;
}
</style>