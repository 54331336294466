<template>
  <div
    @mouseover="panelWidthControl()"
    class="dtp-section"
  >
    <splitpanes>
      <pane min-size="20">
        <div id="leftPanel" ref="leftPanel" class="dtp-resize-col">
          <div class="aml-section-header aml-p-0">
            <PageViewBar
              :title="$t('automl.scalingTitle')"
              :createBtn="false"
              :activeFilters="true"
              :deletableFilter="false"
              :activeSearch="false"
              :filterList="filterHistory"
              :headerArray="filterArray"
              @filterSaved="filterSaved"
            />
          </div>
          <data-preparation-table
            v-loading="loading"
            :dataColumns="dataColumns"
            :rawData="rawData"
            :lastPage="lastPage"
            :pageSize="pageSize"
            :isPaginationVisible="isPaginationVisible"
            @changePage="changePage"
            @changePageSize="changePageSize"
            @onSortChange="(val) => onSortChange(experimentId, val)"
          />
        </div>
      </pane>

      <pane min-size="20">
        <div class="dtp-resize-col dtp-freeze-header">
          <div class="aml-section-header">
            <div>
                <div
                  v-if="bulkButtonsVisible"
                  class="eds-field_#control eds-field_.eds-input eds-input"
                >
                  <select
                    v-model="selectedBulk"
                    class="eds-input_#input eds-input_#input.dropdown"
                    @change="changeBulk()"
                  >
                    <option value="" selected disabled>
                      {{ $t("automl.chooseScalingFunction") }}
                    </option>
                    <option
                      :value="item.value"
                      v-for="item in scalingOptions"
                      :key="item.id"
                    >
                      {{ $t(`${item.label}`) }}
                    </option>
                  </select>
                </div>
            </div>
            <div class="aml-horizontal-flex-end">
                <button
                  @click.stop="applyChanges()"
                  class="eds-button eds-button.basic"
                  :disabled="!applyButtonEnabled"
                >
                  {{ $t("automl.applyChanges") }}
                </button>
                <RefreshTable
                  :loading="loading"
                  @refreshTable="refreshTable"
                />
            </div>
          </div>
          <div class="dtp-row">
            <div class="dtp-col dtp-freeze-header" v-loading="loading">
              <vue-good-table
                @on-selected-rows-change="selectionChanged"
                ref="MyCoolTable"
                :columns="optionsTableHeaders"
                :rows="optionsRows"
                :select-options="{
                  enabled: true,
                  disableSelectInfo: true,
                  selectOnCheckboxOnly: true,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'scalingOptions'">
                    <div
                      class="
                        eds-field_#control eds-field_.eds-input
                        eds-input
                        dtp-selectbox
                      "
                    >
                      <select
                        v-model="props.row.scaling"
                        class="eds-input_#input eds-input_#input.dropdown"
                        @change="
                          (e) => applyScaling(props.row.index, e.target.value)
                        "
                      >
                        <option selected disabled value="">
                          {{ $t("automl.chooseScalingFunction") }}
                        </option>
                        <option
                          v-for="(item, index) in scalingOptions"
                          :key="index"
                          :value="item.value"
                        >
                          {{ item.label }}
                        </option>
                      </select>
                    </div>
                  </span>
                  <span v-else-if="props.column.field == 'actions'">
                    <img
                      class="dtp-cursor-pointer dtp-vgt-actions-icon"
                      src="../../../assets/images/icons/automl-delete-icon.svg"
                      alt=""
                      @click.stop="deleteColumn(props.row)"
                    />
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <template slot="table-column" slot-scope="props">
                  <span>
                    {{ $t(props.column.label) }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </pane>
    </splitpanes>
  </div>
</template>
<script>
/* eslint-disable */
import DataPreparationTable from "../DataPreparation/DataPreparationTable";
import PageViewBar from "../Helper/Pageviewbar";
import RefreshTable from "../Helper/RefreshTable";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { GENERAL_GETTERS } from "../../../store/modules/AutoML/General/types";
import { Splitpanes, Pane } from "splitpanes";
import notify from "../../../mixins/AutoML/notify";
import liveData from "../../../mixins/AutoML/liveData";
import pipeline from "../../../mixins/AutoML/pipeline";
import "splitpanes/dist/splitpanes.css";
import "vue-good-table/dist/vue-good-table.css";
import { scalingOptions as options } from "./scalingTypes";
import { ASYNC_PROCESS_ACTIONS, CALL_REASONS, checkIfOperationStatusIsAvailable, refreshOperationStatus } from '../../../helper/asyncStatusChecker';
import { EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';

export default {
  mixins: [notify, liveData, pipeline],
  components: {
    VueGoodTable,
    Splitpanes,
    Pane,
    DataPreparationTable,
    PageViewBar,
    RefreshTable,
  },
  data() {
    return {
      optionsTableHeaders: [
        { label: "column", field: "column" },
        { label: "Data Type", field: "type", width: "185px" },
        { label: "scalingOptions", field: "scalingOptions" },
        { label: "Actions", field: "actions" },
      ],
      selectedBulk: "",
      scalingOptions: options,
      typeNumeric: "Numeric",
      optionsRows: [],
      optionsRowsDefault: [],
    };
  },
  async mounted() {
    this.requestComp = "scaling";

    await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp
        },
        async () => {
          await this.initLiveData(this.requestComp);
        },
        { callReason: CALL_REASONS.REFRESH, callAsyncStatus: this.callAsyncStatus }
      )

    this.setOptionsRows();
  },
  watch: {
    explorations: {
      handler() {
        this.setOptionsRows();
      },
      deep: true,
    },
    optionsRows: {
      handler() {
        this.checkAndResetSelectedBulk();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      rawData: DATA_GETTERS.GET_DATA,
      pagination: GENERAL_GETTERS.GET_PAGINATION,
      datasetData: DATASET_GETTERS.GET_DATASET,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
    }),
    bulkButtonsVisible() {
      return this.filteredBySelectedRows.length >= 2;
    },
    experimentId() {
      return this.$route.query.experimentId;
    },
    targetVal() {
      return this.problem_conf?.target;
    },
    applyButtonEnabled() {
      let isActiveApplyButton = true;

      if (this.filteredBySelectedRows.length === 0) return false;

      this.filteredBySelectedRows.forEach((element) => {
        if (element.scaling == "") {
          isActiveApplyButton = false;
        }
      });

      return isActiveApplyButton;
    },
    filteredBySelectedRows() {
      return this.optionsRows.filter((r) => r.vgtSelected);
    },
  },
  methods: {
    ...mapActions({
      deleteColumns: DATA_ACTIONS.DELETE_COLUMNS,
      scaling: DATA_ACTIONS.SCALING,
    }),
    ...mapMutations({
      setApplyChanges: DATA_MUTATIONS.SET_APPLY_CHANGES,
      setData: DATA_MUTATIONS.SET_DATA,
    }),
    async refreshTable() {
      await refreshOperationStatus({
        experiment_id: this.experimentId,
        requestComp: this.requestComp
      }, () => this.initLiveData(this.requestComp));
    },
    checkAndResetSelectedBulk() {
      let checkObj = {};
      let totalItemToCheck = 0;
      this.scalingOptions.forEach((so) => {
        checkObj[so.value] = 0;
      });

      this.filteredBySelectedRows.forEach((sr) => {
        if (sr) {
          totalItemToCheck++;
          checkObj[sr.scaling]++;
        }
      });

      !Object.values(checkObj).includes(totalItemToCheck)
        ? (this.selectedBulk = "")
        : null;
    },
    setOptionsRows() {
      let updatedRows = [];

      updatedRows = this.explorations.map((exp, index) => ({
        index: index,
        field: exp.name,
        label: exp.name,
        column: exp.name,
        type: exp.type,
        scaling: "",
      }));

      this.optionsRows = updatedRows.filter(
        (x) => x.type == this.typeNumeric && x.label != this.targetVal
      );

      this.optionsRowsDefault = JSON.parse(JSON.stringify(this.optionsRows));
    },
    resetOptionsRows() {
      this.optionsRows;
    },
    selectionChanged({ selectedRows }) {
      this.optionsRows = JSON.parse(JSON.stringify(this.optionsRowsDefault));

      selectedRows.forEach((sr) => {
        const foundIndex = this.optionsRows.findIndex(
          (or) => or.index === sr.index
        );
        this.$set(this.optionsRows, foundIndex, { ...sr });
      });
    },

    async init() {
      this.setData([]);
      this.fetchExplorations({
        requestComp: this.requestComp,
        experimentId: this.experimentId,
      });
      this.fetchData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        page: 1,
        count: this.pageSize,
      });
      this.currentCount = this.pageSize;
      await this.fetchExperimentByExperimentId(this.experimentId);
      this.getDatasetById(this.selectedExperiment[0].dataset_id);
    },
    pageChanged(pageObject) {
      this.fetchData({
        requestComp: this.requestComp,
        experiment_id: this.experimentId,
        page: pageObject.page,
        count: pageObject.count,
      });
    },
    async deleteColumn(row) {
      let data = {};
      data.requestComp = this.requestComp;
      data.experiment_id = this.experimentId;
      data.names = row.field;
      data.pipeline_step = this.requestComp;
      await this.deleteColumns(data);
      this.initLiveData(this.requestComp);
    },
    applyScaling(index, value) {
      const row = this.optionsRows.find((x) => x.index == index);
      this.$set(row, "scaling", value);
      this.$set(row, "vgtSelected", true);
      this.setApplyChanges(true);
    },

    changeBulk() {
      this.filteredBySelectedRows.forEach((element) => {
        element.scaling = this.selectedBulk;
      });
    },
    async applyChanges() {
      let convertObj = [];
      this.filteredBySelectedRows.forEach((sr) => {
        if (sr.scaling != "") {
          convertObj.push({ column: sr.field, method: sr.scaling });
        }
      });
      let reqObj = {};
      reqObj.scaling = {};
      reqObj.scaling.scaling = convertObj;
      reqObj.experiment_id = this.experimentId;
      reqObj.requestComp = this.requestComp;
      await this.scaling(reqObj);

      await checkIfOperationStatusIsAvailable(
        {
          experiment_id: this.experimentId,
          requestComp: this.requestComp,
          action: ASYNC_PROCESS_ACTIONS.scaling
        },
        () => {
          this.initLiveData(this.requestComp);
          this.setApplyChanges(false);
        },
        { callAsyncStatus: this.callAsyncStatus }
      )
    },
    
  },
};
</script>
<style scoped>
::v-deep .vgt-table thead tr .vgt-checkbox-col {
  background: #b2c4dc !important;
}
</style>
