<template>
  <div v-loading="loading" class="vgt-custom-colon dtp-freeze-header">
    <vue-good-table
      :columns="dataColumns"
      :rows="rawData"
      mode="remote"
      @on-sort-change="(val) => $emit('onSortChange', val)"
    >
      <!-- for row customization -->
      <template slot="table-row" slot-scope="props">
        <span>
          {{ getFormattedRow(props.row, props.column.field) }}
        </span>
      </template>
      <!-- for header localization -->
      <template slot="table-column" slot-scope="props">
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
    </vue-good-table>
    <CustomPagination
      v-if="isPaginationVisible"
      :lastPage="lastPage"
      :pageSize.sync="pageSize"
      :showPageSizeSelect="true"
      @update:pageSize="(size) => $emit('changePageSize', size)"
      @change="(page) => $emit('changePage', page)"
    />
  </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import CustomPagination from "../CustomPagination";
import notify from "../../../mixins/AutoML/notify";
export default {
  mixins: [notify],
  components: {
    VueGoodTable,
    CustomPagination,
  },
  props: {
    dataColumns: {
      type: Array,
      required: true,
    },
    rawData: {
      type: Array,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    isPaginationVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      changeType: "",
      currentPage: 1,
    };
  },
  methods: {
    getFormattedRow(row, field) {
      const x = row[`${field}`];
      return x;
    },
  },
  watch: {},
  computed: {},
};
</script>
<style scoped></style>
