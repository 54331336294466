<template>
  <div class="dtp-section">
    <div class="aml-section-header">
      <PageViewBar
        :title="$t('automl.modelConfigTitle')"
        :routerLink="''"
        :activeFilters="false"
        :headerArray="[]"
        :filterList="[]"
        :createBtn="false"
        :activeSearch="true"
        @changedSearch="(value) => (search = value)"
      />
    </div>
    <div class="dtp-row">
      <div
        class="dtp-col dtp-col-12"
        v-loading="loadTrainStatus"
        :element-loading-text="$t('helper.saving_data')"
      >
        <el-table
          class="dtp-accordion-table"
          :data="searchList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="dtp-row">
                <div
                  v-for="item in secondaryColumnsConfig[props.row.model]"
                  :key="item.key"
                  class="dtp-col dtp-col-3"
                >
                  <div v-if="isColumnVisible(item, props.row)">
                    <div class="dtp-row dtp-align-center">
                      <div class="dtp-col dtp-col-4 dtp-pa--0 dtp-text--right">
                        <span class="dtp-font-bold">{{ item.label }}</span>
                      </div>
                      <div class="dtp-col dtp-col-7">
                        <div class="dtp-panel-formbox" style="width: 100%">
                          <div
                            v-if="item.type != InputTypes.CheckBox"
                            class="
                              eds-field_#control eds-field_.eds-input
                              eds-input
                            "
                          >
                            <select
                              v-if="item.type === InputTypes.SelectBox"
                              v-model="props.row[item.key]"
                              class="eds-input_#input eds-input_#input.dropdown"
                              @change="
                                applyInput(
                                  item.key,
                                  props.row
                                )
                              "
                            >
                              <option
                                v-for="it in item.values"
                                :key="it.key"
                                :value="it.key"
                              >
                                {{ it.label }}
                              </option>
                            </select>

                            <div v-if="item.type === InputTypes.TextField">
                              <input
                                type="text"
                                class="eds-input_#input"
                                v-model="props.row[item.key]"
                                @change="
                                  applyInput(
                                    item.key,
                                    props.row
                                  )
                                "
                              />
                              <span style="color: red; position: absolute">{{
                                !item.validate(props.row) ? "Not valid" : ""
                              }}</span>
                            </div>
                            <input
                              v-if="item.type === InputTypes.NumberField"
                              class="eds-input_#input"
                              type="number"
                              v-model.number="props.row[item.key]"
                              :min="item.validation.min"
                              :max="item.validation.max"
                              @change="
                                applyInput(
                                  item.key,
                                  props.row
                                )
                              "
                            />
                            <input
                              v-if="item.type === InputTypes.NotEditableText"
                              disabled
                              type="text"
                              class="eds-input_#input"
                              :value="item.renderValue(props.row)"
                            />
                          </div>
                          <input
                            v-else-if="item.type === InputTypes.CheckBox"
                            type="checkbox"
                            class="eds-checkbox_#input"
                            v-model="props.row[item.key]"
                            @change="
                              applyInput(
                                item.key,
                                props.row
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column :label="$t('table.header.Model')" prop="model"> </el-table-column>
          <el-table-column :label="$t('table.header.Actions')" prop="actions" width="250">
            <template slot-scope="scope">
              <button
                :disabled="!isRowsExpandItemsChanged(scope.row.id)"
                @click="resetRowExpandItemsChanged(scope.row.id)"
                class="dtp-btn-table-in"
              >
                {{ $t("automl.useDef") }}
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from "vuex";
import PageViewBar from "../Helper/Pageviewbar";
import {
  SECONDARY_COLUMNS_GPU_CLASSIFICATION_CONFIG,
  SECONDARY_COLUMNS_GPU_REGRESSION_CONFIG,
  SECONDARY_COLUMNS_GPU_REGRESSION_DEFAULT_VALUES,
  SECONDARY_COLUMNS_GPU_CLASSIFICATION_DEFAULT_VALUES,
  SECONDARY_COLUMNS_GPU_CLUSTERING_CONFIG,
  SECONDARY_COLUMNS_GPU_CLUSTERING_DEFAULT_VALUES,
} from "./data/gpuConfig";
import {
  SECONDARY_COLUMNS_CPU_CLASSIFICATION_CONFIG,
  SECONDARY_COLUMNS_CPU_REGRESSION_CONFIG,
  SECONDARY_COLUMNS_CPU_REGRESSION_DEFAULT_VALUES,
  SECONDARY_COLUMNS_CPU_CLASSIFICATION_DEFAULT_VALUES,
  SECONDARY_COLUMNS_CPU_CLUSTERING_CONFIG,
  SECONDARY_COLUMNS_CPU_CLUSTERING_DEFAULT_VALUES,
} from "./data/cpuConfig";
import { InputTypes as InputTypeDefs } from "./data/types";
import notify from "../../../mixins/AutoML/notify";
import pipeline from "../../../mixins/AutoML/pipeline";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { DATASET_ACTIONS, DATASET_GETTERS } from '../../../store/modules/AutoML/Dataset/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { NAVIGATION_MUTATIONS } from '../../../store/modules/AutoML/Navigation/types';
import cloneDeep from "clone-deep";

export default {
  mixins: [notify, pipeline],
  components: {
    PageViewBar,
  },
  data() {
    return {
      currentSelectedRows: [],
      filterList: [],
      DataSet: this.$route.query.dataset,
      multiSelection: this.$route.query.data,
      secondaryColumnsDefaultValues: [],
      secondaryColumnsUpdatedValues: [],
      search: "",
      loadTrainStatus: true,
      problemTypes: {
        Classification: "classification",
        Regression: "regression",
        Clustering: "clustering",
      },
      statusTypes: {
        Training: "TRAINING",
        Trained: "TRAINED",
        Tuned: "TUNED",
        Available: "AVAILABLE",
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedModels: DATA_GETTERS.GET_SELECTED_MODELS,
      datasetData: DATASET_GETTERS.GET_DATASET,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
      status: DATA_GETTERS.GET_STATUS,
    }),
    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
    InputTypes() {
      return InputTypeDefs;
    },
    navigationList() {
      return [
        {
          status: this.statusTypes.Training,
          routerName: "ModelRunning",
          navigationNumber: 4,
        },
        {
          status: this.statusTypes.Trained,
          routerName: "ModelResults",
          navigationNumber: 5,
        },
        {
          status: this.statusTypes.Tuned,
          routerName: "TuneModel",
          navigationNumber: 6,
        },
      ];
    },
    isSelectedExperimentCPU() {
      return (
        this.selectedExperiment.length &&
        this.selectedExperiment[0].device === "CPU"
      );
    },
    secondaryColumnsConfig() {
      if (this.isSelectedExperimentCPU) {
        if (this.getProblemType === this.problemTypes.Classification)
          return SECONDARY_COLUMNS_CPU_CLASSIFICATION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Regression)
          return SECONDARY_COLUMNS_CPU_REGRESSION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Clustering)
          return SECONDARY_COLUMNS_CPU_CLUSTERING_CONFIG;
      } else {
        if (this.getProblemType === this.problemTypes.Classification)
          return SECONDARY_COLUMNS_GPU_CLASSIFICATION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Regression)
          return SECONDARY_COLUMNS_GPU_REGRESSION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Clustering)
          return SECONDARY_COLUMNS_GPU_CLUSTERING_CONFIG;
      }
    },
    searchList() {
      if (this.search) {
        return this.secondaryColumnsUpdatedValues.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.model.toLowerCase().includes(v));
        });
      } else {
        return this.secondaryColumnsUpdatedValues;
      }
    },
  },
  async mounted() {
    await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
    await this.getStatus({ experiment_id: this.experimentId });
    await this.checkNavigation();
    await this.getDatasetById({ dataset_id: this.selectedExperiment[0].dataset_id });
    await this.getPipeline(
      this.experimentId,
      this.selectedExperiment[0].project_id
    );
    await this.setSecondaryColumnsValues();
    await this.tryLoadTrainStatus();
  },
  methods: {
    ...mapActions({
      getDatasetById: DATASET_ACTIONS.GET_DATASET_BY_ID,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
      getStatus: DATA_ACTIONS.GET_STATUS,
    }),
    ...mapMutations({
      setSelectedModels: DATA_MUTATIONS.SET_SELECTED_MODELS,
      setNavigationCount: NAVIGATION_MUTATIONS.SET_NAVIGATION_COUNT_LIST,
    }),
    checkNavigation() {
      this.navigationList.forEach((nav) => {
        if (this.status.status === nav.status) {
          this.setNavigationCount(nav.navigationNumber);
          this.$router.push({
            name: nav.routerName,
            query: { experimentId: this.experimentId },
          });
        }
      });
    },
    /**
     * This function resets the expanded row's data
     */
    setSecondaryColumnsValues() {
      if (this.isSelectedExperimentCPU) {
        this.setCPUSecondaryColumns();
      } else {
        this.setGPUSecondaryColumns();
      }
    },
    setCPUSecondaryColumns() {
      if (this.getProblemType === this.problemTypes.Classification) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_CLASSIFICATION_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_CLASSIFICATION_DEFAULT_VALUES)
        );
      } else if (this.getProblemType === this.problemTypes.Regression) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_REGRESSION_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_REGRESSION_DEFAULT_VALUES)
        );
      } else if (this.getProblemType === this.problemTypes.Clustering) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_CLUSTERING_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_CPU_CLUSTERING_DEFAULT_VALUES)
        );
      }
    },
    setGPUSecondaryColumns() {
      if (this.getProblemType === this.problemTypes.Classification) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_CLASSIFICATION_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_CLASSIFICATION_DEFAULT_VALUES)
        );
      } else if (this.getProblemType === this.problemTypes.Regression) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_REGRESSION_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_REGRESSION_DEFAULT_VALUES)
        );
      } else if (this.getProblemType === this.problemTypes.Clustering) {
        this.secondaryColumnsDefaultValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_CLUSTERING_DEFAULT_VALUES)
        );
        this.secondaryColumnsUpdatedValues = JSON.parse(
          JSON.stringify(SECONDARY_COLUMNS_GPU_CLUSTERING_DEFAULT_VALUES)
        );
      }
    },
    resetRowExpandItemsChanged(rowId) {
      let foundIndex = this.secondaryColumnsUpdatedValues.findIndex(
        (r) => r.id == rowId
      );
      const defaultValues = this.secondaryColumnsDefaultValues.find(
        (r) => r.id == rowId
      );

      Object.keys(defaultValues).forEach((k) => {
        this.secondaryColumnsUpdatedValues[foundIndex][k] = defaultValues[k];
      });

      this.handleSelectionChange(
        this.secondaryColumnsUpdatedValues.filter((s) =>
          this.currentSelectedRows.includes(s.model)
        )
      );
    },
    isRowsExpandItemsChanged(rowId) {
      let defaultValues = this.secondaryColumnsDefaultValues.find(
        (r) => r.id == rowId
      );
      let updatedValues = this.secondaryColumnsUpdatedValues.find(
        (r) => r.id == rowId
      );

      const itemsToBeChecked = Object.keys(defaultValues);
      let changed = false;

      itemsToBeChecked.forEach((item) => {
        if (defaultValues[item] !== updatedValues[item]) {
          changed = true;
        }
      });

      return changed;
    },
    async tryLoadTrainStatus() {
      if (!this.loadTrainStatus) return;

      return await new Promise((resolve) =>
        setTimeout(() => {
          this.getStatus({ experiment_id: this.experimentId });
          if (this.status.status != this.statusTypes.Available) {
            this.tryLoadTrainStatus();
          } else {
            resolve();
            this.loadTrainStatus = false;
          }
        }, 4000)
      );
    },
    applyInput(configModelKey, row) {
      if (!row || !configModelKey) return;
      
      const { name, [configModelKey]: value, model } = row;
      
      const cloneSelectedModels = cloneDeep(this.selectedModels);
      
      const filteredSelectedModel = cloneSelectedModels.filter(
        (sm) => sm.name === name
      );

      if (filteredSelectedModel && filteredSelectedModel.length) {
        filteredSelectedModel[0].params[configModelKey] = value;
      }
      
      // remove invisible column keys
      Object.keys(cloneSelectedModels[0]?.params || {}).forEach(key => {
        const item = this.secondaryColumnsConfig[model]?.find?.(i => i.key === key);

        if (item && item.invisible?.(row)) {
          delete cloneSelectedModels[0].params[key];
        }
      })

      this.setSelectedModels(cloneSelectedModels);
    },
    isColumnVisible(item, row) {
      return (
        typeof item.invisible !== "function" ||
        (typeof item.invisible === "function" && !item.invisible(row))
      );
    },
    handleSelectionChange(val) {
      this.currentSelectedRows = val.map((v) => v.model);
      const data = [];

      val.forEach((v) => {
        const params = {};
        this.secondaryColumnsConfig[v.model].forEach((sc) => {
          if (this.isColumnVisible(sc, v)) params[sc.key] = v[sc.key];
        });
        data.push({ name: v.name, params });
      });
      this.setSelectedModels(data);
    },
  },
  destroyed() {
    this.loadTrainStatus = false;
  }
};
</script>
<style>
.dtp-accordion-table thead tr th {
  background-color: #b2c4dc !important;
  border: 1px solid #dcdfe6 !important;
}
.dtp-accordion-table .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #939393;
}
.dtp-accordion-table .el-checkbox__input.is-checked .el-checkbox__inner,
.dtp-accordion-table .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #426da9;
  border-color: #426da9;
}
.dtp-accordion-table .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
}
.dtp-accordion-table
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner::before {
  top: 7px;
}
.el-table {
  font-size: 12px !important;
  border: 1px solid #d6d6d6 !important;
}

.el-table td {
  border: 1px solid #dcdfe6 !important;
  padding: 10px 0 !important;
}
.el-table th > .cell {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.el-table th.is-leaf {
  border-bottom: none !important;
  border-top: none !important;
}
.dtp-accordion-table thead tr th div {
  color: #333333;
  font-weight: bold;
  font-size: 12px;
}

.dtp-accordion-table .el-table__row:nth-of-type(2n) {
  background-color: rgba(246, 246, 246, 1);
}
</style>
