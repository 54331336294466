<template>
  <div>
    <div class="dtp-row">
      <div
        v-for="problem in problemTypes"
        :key="problem.id"
        class="dtp-col dtp-col-s12 dtp-col-m6"
        :class="'dtp-col-4'"
      >
        <el-card
          class="box-card"
          :class="problem_type === problem.id && 'box-card-selected'"
          shadow="always"
        >
          <el-radio v-model="problem_type" :label="problem.id">{{
            $t(`${problem.label}`)
          }}</el-radio>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div class="exampleTableArea" style="width: 120px">
              <el-table :data="problem.tableData" stripe>
                <el-table-column prop="col1" label="col1"> </el-table-column
              ></el-table>
            </div>
          </div>
          <div class="dtp-row">
            <div
              v-if="problem.fields.includes(inputList.target)"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <select
                    :value="
                      getProblemConfigurationPropByProblemType(
                        problem.id,
                        'target'
                      )
                    "
                    class="eds-input_#input eds-input_#input.dropdown"
                    :disabled="problem_type !== problem.id"
                    @change="(e) => (target = e.target.value)"
                  >
                    <option selected value="" disabled>{{ $t("Target Value") }}</option>
                    <option
                      v-for="item in explorations"
                      :key="item.name"
                      :value="item.name"
                      :selected="
                        item.name === target && problem_type === problem.id
                      "
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="problem.fields.includes(inputList.trainRatio)"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <input
                    type="number"
                    :value="
                      getProblemConfigurationPropByProblemType(
                        problem.id,
                        'train_ratio'
                      )
                    "
                    class="eds-input_#input dtp-floating-input"
                    :placeholder="$t('Train Ratio')"
                    :disabled="problem_type !== problem.id"
                    @change="(e) => (train_ratio = e.target.value)"
                  />
                  <label
                    for=""
                    class="dtp-floating-label"
                    :class="
                      problem_type !== problem.id &&
                      'dtp-floating-label--disabled'
                    "
                    >{{ $t('Train Ratio') }}</label
                  >
                </div>
              </div>
            </div>
            <div
              v-if="
                problem.fields.includes(inputList.crossValidationNumberofFolds)
              "
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <el-tooltip
                    :content="$t('Cross validation should be between 0 - 10')"
                    placement="top"
                    effect="light"
                  >
                    <input
                      :min="crossValidationNumberofFoldsRange.min"
                      :max="crossValidationNumberofFoldsRange.max"
                      type="number"
                      :value="
                        getProblemConfigurationPropByProblemType(
                          problem.id,
                          'cross_validation_number_of_folds'
                        )
                      "
                      class="eds-input_#input dtp-floating-input"
                      :placeholder="$t('Cross Validation')"
                      :disabled="problem_type !== problem.id"
                      @change="
                        (e) =>
                          (cross_validation_number_of_folds = e.target.value)
                      "
                    />
                  </el-tooltip>
                  <label
                    for=""
                    class="dtp-floating-label"
                    :class="
                      problem_type !== problem.id &&
                      'dtp-floating-label--disabled'
                    "
                    >{{ $t("Cross Validation") }}</label
                  >
                </div>
              </div>
            </div>
            <div
              v-if="problem.fields.includes(inputList.stratify)"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div class="dtp-panel-formbox dtp-mr--0">
                <label class="eds-checkbox">
                  <input
                    type="checkbox"
                    class="eds-checkbox_#input"
                    v-model="stratify"
                    :disabled="problem_type !== problem.id"
                  />
                  <span class="eds-checkbox_#label">{{ $t("Stratify") }}</span>
                </label>
              </div>
            </div>
            <div
              id="switcherImbalance"
              v-if="
                problem.id === 'classification'
              "
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div class="dtp-panel-formbox dtp-mr--0">
                <el-tooltip
                  :content="
                    $t(imbalanceDataControlLoading
                      ? 'Request sent please wait.'
                      : 'Imbalance Data Control')
                  "
                  placement="top"
                  effect="light"
                  ><label class="switch">
                    <input
                      :disabled="
                        problem_type !== problem.id ||
                        imbalanceDataControlLoading
                      "
                      id="imbalanceCheck"
                      type="checkbox"
                      @click="checkImbalanceDataControl"
                    />
                    <span class="slider"></span>
                  </label>
                </el-tooltip>
                <span class="eds-checkbox_#label">{{ $t("Control of Data Balance") }}</span>

                <i
                  v-show="imbalanceDataControlLoading"
                  class="el-icon-loading"
                  id="imbalanceDataControlLoading"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div
              v-if="problem.fields.includes(inputList.fold_strategy)"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <select
                    class="eds-input_#input eds-input_#input.dropdown"
                    :disabled="problem_type !== problem.id"
                    @change="(e) => (fold_strategy = e.target.value)"
                    
                  >
                    <option selected value="" disabled>{{ $t("Fold Strategy") }}</option>
                    <option
                      v-for="item in foldStrategyOptions"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ $t(`${item.label}`) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="fold_strategy === FOLD_STRATEGIES.TIME_SERIES && problem.label == 'Regression'"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <select
                    class="eds-input_#input eds-input_#input.dropdown"
                    :disabled="problem_type !== problem.id"
                    @change="(e) => (date_column = e.target.value)"
                    
                  >
                    <option selected value="" disabled>{{ $t("Date Column") }}</option>
                    <option
                      v-for="item in explorations"
                      :key="item.name"
                      :value="item.name"
                      :selected="
                        item.name === date_column && problem_type === problem.id
                      "
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';
import { DATA_ACTIONS, DATA_GETTERS, DATA_MUTATIONS } from '../../../store/modules/AutoML/Data/types';
import { MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';
import { foldStrategyOptions, FOLD_STRATEGIES } from "../../../models/dataPreparation";

export default {
  props: {
    experimentId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      defaultProblemConfiguration: {
        stratify: true,
        train_ratio: 80,
        target: "",
        cross_validation_number_of_folds: 3,
        fold_strategy: "",
        date_column: "",
      },
      inputList: {
        target: "target",
        trainRatio: "train_ratio",
        crossValidationNumberofFolds: "cross_validation_number_of_folds",
        stratify: "stratify",
        fold_strategy: "fold_strategy",
        date_column: "date_column",
      },
      imbalanceDataControlLoading: false,
      crossValidationNumberofFoldsRange: {
        min: 0,
        max: 10,
      },
      FOLD_STRATEGIES,
      foldStrategyOptions
    };
  },
  watch: {
    imbalanceInspection: {
      handler() {
        if (this.imbalanceObjectValueCheck) {
          //imbalance finished, stop loading after 1 sec
          setTimeout(() => {
            this.imbalanceDataControlLoading = false;
            this.$emit("checkImbalanceDataControl");
          }, 2000);
        }
      },
      deep: true,
    },
    problem_type() {
      this.resetImbalanceCheck();
      this.mutateInspectImbalance({});
    },
    target() {
      this.resetImbalanceCheck();
      this.mutateInspectImbalance({});
    },
    resetImbalanceCount() {
      setTimeout(() => {
        this.imbalanceDataControlLoading = false;
        this.resetImbalanceCheck();
      }, 1000);
    },
    /*experimentPipeLine: {
      handler() {
        const balancingFromPipeline =
          this.experimentPipeLine?.feature_engineering_phase_1?.[0]?.balancing;
        debugger;
        if (balancingFromPipeline) {
          //set balancing to
          this.updateBalanceConfig(balancingFromPipeline);
        }
      },
      deep: true,
    },*/
  },
  mounted() {
    this.resetExplorations();
  },
  methods: {
    ...mapMutations({
      updateProblemConfig: MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION_PARAMS,
      resetExplorations: DATA_MUTATIONS.RESET_EXPLORATIONS,
      mutateInspectImbalance: DATA_MUTATIONS.INSPECT_IMBALANCE,
      updateBalanceConfig: MODELS_MUTATIONS.SET_BALANCE_CONFIGURATION,
      resetImbalance: DATA_MUTATIONS.RESET_IMBALANCE,
    }),
    ...mapActions({
      inspectImbalance: DATA_ACTIONS.INSPECT_IMBALANCE,
    }),
    resetImbalanceCheck() {
      const checkBox = document.getElementById("imbalanceCheck");

      if (checkBox) checkBox.checked = false;
    },
    checkImbalanceDataControl() {
      const checkBox = document.getElementById("imbalanceCheck");

      if (checkBox && checkBox.checked == true) {
        this.requestComp = "basic_transformation";
        this.inspectImbalance({
          experiment_id: this.experimentId,
          problem_type: "classification",
          target: this.target,
          requestComp: this.requestComp,
        });
        this.imbalanceDataControlLoading = true;
      } else if (checkBox && checkBox.checked == false) {
        this.resetImbalance();
        this.mutateInspectImbalance({});
        this.updateBalanceConfig({});
      }
    },
    getProblemConfigurationPropByProblemType(problemType, property) {
      if (problemType === this.problem_type)
        return this.problem_configuration[property];
      else return this.defaultProblemConfiguration[property];
    },
  },
  computed: {
    ...mapGetters({
      problem_configuration: MODELS_GETTERS.GET_PROBLEM_CONFIGURATION_PARAMS,
      explorations: DATA_GETTERS.GET_EXPLORATIONS,
      imbalanceInspection: DATA_GETTERS.GET_IMBALANCE_INSPECTION,
      resetImbalanceCount: DATA_GETTERS.GET_RESET_IMBALANCE_COUNT,
      experimentPipeLine: PROJECT_GETTERS.GET_PIPELINE,
    }),

    imbalanceObjectValueCheck() {
      return (
        this.imbalanceInspection &&
        Object.keys(this.imbalanceInspection).length !== 0
      );
    },
    problemTypes() {
      const clustering = {
        id: "clustering",
        label: "Clustering",
        fields: [],
        tableData: [
          {
            col1: this.$t("Label") + " 1" ,
          },
          {
            col1: this.$t("Label") + " 2",
          },
          {
            col1: this.$t("Label") + " 3",
          },
        ],
      };

      const initialProblemTypes = [
        {
          id: "classification",
          label: "Classification",
          fields: [
            this.inputList.target,
            this.inputList.trainRatio,
            this.inputList.crossValidationNumberofFolds,
            this.inputList.stratify,
          ],
          tableData: [
            {
              col1: this.$t("Class") + " A",
            },
            {
              col1: this.$t("Class") + " B",
            },
            {
              col1: this.$t("Class") + " C",
            },
          ],
        },
        {
          id: "regression",
          label: "Regression",
          fields: [
            this.inputList.target,
            this.inputList.trainRatio,
            this.inputList.crossValidationNumberofFolds,
            this.inputList.fold_strategy,
          ],
          tableData: [
            {
              col1: "135",
            },
            {
              col1: "343",
            },
            {
              col1: "222",
            },
          ],
        },
      ];

      initialProblemTypes.push(clustering);

      return initialProblemTypes;
    },
    problem_type: {
      get() {
        return this.problem_configuration.problem_type;
      },
      set(value) {
        this.updateProblemConfig({ field: "problem_type", value: value });
        Object.keys(this.defaultProblemConfiguration).forEach(
          (k) => (this[k] = this.defaultProblemConfiguration[k])
        );
      },
    },
    target: {
      get() {
        return this.problem_configuration.target;
      },
      set(value) {
        this.updateProblemConfig({ field: "target", value: value });
      },
    },
    train_ratio: {
      get() {
        return this.problem_configuration.train_ratio;
      },
      set(value) {
        this.updateProblemConfig({ field: "train_ratio", value: value });
      },
    },
    cross_validation_number_of_folds: {
      get() {
        return this.problem_configuration.cross_validation_number_of_folds;
      },
      set(value) {
        this.updateProblemConfig({
          field: "cross_validation_number_of_folds",
          value: value,
        });
      },
    },
    stratify: {
      get() {
        return this.problem_configuration.stratify;
      },
      set(value) {
        this.updateProblemConfig({ field: "stratify", value: value });
      },
    },
    fold_strategy: {
      get() {
        return this.problem_configuration.fold_strategy;
      },
      set(value) {
        this.updateProblemConfig({ field: "fold_strategy", value: value });
      },
    },
    date_column: {
      get() {
        return this.problem_configuration.date_column;
      },
      set(value) {
        this.updateProblemConfig({ field: "date_column", value: value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card-selected {
  border: 1px solid #436da9 !important;
}

.box-card {
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  opacity: 1;
  height: 100%;
}

::v-deep .el-radio {
  margin: 0;
  text-align: center;
  width: 100%;
}

::v-deep .el-radio__input {
  display: block;
  margin-bottom: 10px;
}

::v-deep .el-radio__label {
  display: block;
  padding: 0;
  font-weight: bold;
  color: black;
}

::v-deep .el-table__header-wrapper {
  display: none;
}

::v-deep .el-table__row td {
  padding: 0 !important;
  text-align: center;
}

.exampleTableArea {
  margin-bottom: 46px;
  margin-top: 20px;
}

#switcherImbalance {
  .switch input {
    display: none;
  }

  .switch {
    display: inline-block;
    width: 35px; /*=w*/
    height: 20px; /*=h*/
    margin: 5px 3px 5px 0;
    transform: translateY(50%);
    position: relative;
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 1px #9e9e9e, 0 0 2px #9e9e9e;
    cursor: pointer;
    border: 3px solid transparent;
    overflow: hidden;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #9e9e9e;
    border-radius: 30px;
    transform: translateX(-15px); /*translateX(-(w-h))*/
    transition: 0.4s;
  }

  input:checked + .slider:before {
    transform: translateX(15px); /*translateX(w-h)*/
    background-color: #436da9;
  }

  #imbalanceDataControlLoading {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
