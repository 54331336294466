<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('helper.loading_data')"
    class="dtp-section"
  >
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-8 dtp-align-center">
          <el-tooltip :content="$t('commons.back')" placement="top" effect="light">
            <button
              v-if="goBackAvailable"
              @click="goBack"
              style="
                margin-right: 24px;
                border: none;
                background: transparent;
                cursor: pointer;
              "
            >
              <img src="../../../assets/images/icons/Automlback.svg" alt="" />
            </button>
          </el-tooltip>
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title"
              ><span
                v-if="experimentName"
                style="
                  font: normal normal medium 16px/50px;
                  letter-spacing: 0px;
                  color: #8c8c8c;
                  opacity: 1;
                "
                >{{ experimentName }} /</span
              >
              {{ $t("Model Results") }}</span
            >
          </div>

          <div class="dtp-panel-formbox">
            <ul class="dtp-aml-tabLink">
              <li
                v-for="tab in modelResultsTabs"
                :key="tab.component"
                @click="selectTab(tab.component)"
                :class="{
                  active: activeTab == tab.component,
                }"
              >
                <span v-if="problemType === algorithmType.Classification">{{
                  $t(`${tab.problemType.classification}`)
                }}</span>
                <span v-else-if="problemType === algorithmType.Regression">{{
                  $t(`${tab.problemType.regression}`)
                }}</span>
                <span v-else-if="problemType === algorithmType.Cluster">{{
                  $t(`${tab.problemType.cluster}`)
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-12" v-loading="loading">
        <div>
          <model-results-main-table
            ref="modelResultsMainTable"
            :isCluster="isCluster"
            :scoreNames="scoreNames"
            :rows="enhancedRows"
            @selectionChanged="(val) => (tableSelections = val)"
          />
        </div>

        <!--  Plot  list -->
        <PlotImage :selectedPlotList="currentPlots" :models="enhancedRows" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import { classification, regression, cluster, tabs } from "./metricTypes";
import ModelResultsMainTable from "./ModelResultsMainTable.vue";
import PlotImage from "./PlotImage.vue";
import { MODELS_ACTIONS, MODELS_GETTERS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [notify],
  components: {
    ModelResultsMainTable,
    PlotImage,
  },
  props: {
    goBackAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      metricList: {
        classification,
        regression,
        cluster,
      },
      algorithmType: {
        Classification: "classification",
        Regression: "regression",
        Cluster: "cluster",
      },
      problemType: "",
      statusType: {
        Failed: "FAILED",
      },
      enhancedRows: [],
      scoreNames: [],
      selectedMetricList: [],
      isCluster: false,
      activeTab: null,
      tabs: tabs,
      currentPlots: [],
      tableSelections: [],
    };
  },
  watch: {
    modelResultsMain: {
      handler() {
        this.init();
      },
      deep: true,
    },
    modelResultsTabs: {
      handler(val) {
        this.activeTab = val[0].component;
      },
      deep: true,
    },
    tableSelections: {
      handler(val) {
        if (val.length) this.fetchPlot();
      },
      deep: true,
    },
  },
  async mounted() {
    this.requestComp = "ModelResultsMain";
    await this.getResults();
  },
  computed: {
    ...mapGetters({
      modelResultsMain: MODELS_GETTERS.GET_MODEL_RESULTS_MAIN,
    }),
    modelResultsTabs() {
      if (this.problemType === this.algorithmType.Classification) {
        return this.tabs.filter((x) => x.problemType.classification);
      } else if (this.problemType === this.algorithmType.Regression) {
        return this.tabs.filter((x) => x.problemType.regression);
      } else {
        return this.tabs.filter((x) => x.problemType.cluster);
      }
    },
    experimentId() {
      return this.$route.query.experimentId;
    },
    experimentName() {
      return this.$route.query.experimentName;
    },
    fromComponent() {
      return this.$route.query.fromComponent;
    },
  },
  methods: {
    ...mapActions({
      fetchModelResultsMain: MODELS_ACTIONS.FETCH_MODELS_RESULT_MAIN,
      fetchModelPlotsMain: MODELS_ACTIONS.FETCH_MODELS_PLOT_MAIN,
    }),
    async fetchPlot() {
      this.currentPlots = await this.fetchModelPlotsMain({
        requestComp: this.requestComp,
        experimentId: this.experimentId,
        params: {
          model_ids: [...this.tableSelections.map((x) => x.model_id)],
          plot_types: [this.activeTab],
        },
      });
    },
    selectTab(value) {
      this.$refs.modelResultsMainTable.clearSelection();
      this.activeTab = value;
      this.currentPlots = [];
    },
    checker(arr, target) {
      return target?.some((v) => arr.includes(v));
    },
    getFields(trainData) {
      if (this.checker(trainData, this.metricList.classification)) {
        return {
          algorithm: this.algorithmType.Classification,
          metricList: this.metricList.classification,
        };
      } else if (this.checker(trainData, this.metricList.regression)) {
        return {
          algorithm: this.algorithmType.Regression,
          metricList: this.metricList.regression,
        };
      } else if (this.checker(trainData, this.metricList.cluster)) {
        return {
          algorithm: this.algorithmType.Cluster,
          metricList: this.metricList.cluster,
        };
      }
    },
    goBack() {
      this.$router.push({
        name: this.$route.meta.from,
      });
    },
    init() {
      if (this.modelResultsMain) {
        const successResults = JSON.parse(
          JSON.stringify(this.modelResultsMain)
        ).filter((item) => item.status !== this.statusType.Failed);

        this.enhancedRows = successResults.map((item) => {
          const { model, model_id, train, test } = item;
          const trainKeys = Object.keys(train).filter(
            (x) => x !== "highlights" && x !== "training_time"
          );
          let fieldKeys = trainKeys
          if (trainKeys.length === 0) {
            fieldKeys = Object.keys(test).filter(
              (x) => x !== "highlights" && x !== "training_time"
            );
          }

          const { algorithm, metricList } = this.getFields(fieldKeys);

          this.problemType = algorithm;

          this.isCluster = this.algorithmType.Cluster === algorithm;
          let scores = {};
          this.scoreNames = [];

          metricList.forEach((sm) => {
            try {
              Object.assign(scores, {
                [sm]: {
                  Train: train?.[sm]?.toFixed(4) ?? "N/A",
                  Test: test?.[sm]?.toFixed(4) ?? "N/A",
                },
              });
              this.scoreNames.push(sm);
            } catch (e) {
              return;
            }
          });

          return {
            model,
            model_id,
            trainingtime: `${train?.training_time || 0}`,
            scores,
            Train_Highlight: train?.highlights,
            Test_Highlight: test?.highlights,
            trainKeys
          };
        });
      }
    },
    async getResults() {
      await this.fetchModelResultsMain({
        requestComp: this.requestComp,
        experimentId: this.experimentId,
      });
    },
  },
};
</script>

<style scoped>
.dtp-aml-tabLink {
  position: relative;
  width: auto;
  height: auto;
  padding: 3px;
  margin: 0;
  background-color: #eaeaea;
  border-radius: 3px;
}
.dtp-aml-tabLink li {
  position: relative;
  padding: 7px 14px;
  margin: 0;
  display: inline-block;
  color: #333333;
  background-color: transparent;
  border-radius: 3px;
}
.dtp-aml-tabLink li span {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.dtp-aml-tabLink li.active {
  background-color: #416ca8;
  color: #ffffff;
}
</style>

