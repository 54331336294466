<template>
  <div class="dtp-aml-el-select">
    <el-select
      v-model="selectedId"
      filterable
      remote
      :remote-method="searchDatasets"
      :placeholder="$t('placeholder.Choose a Data Set')"
      v-loadmore="callLoadMoreDatasets"
      @visible-change="
        (isVisible) => (isVisible ? null : (searchDatasetInput = ''))
      "
    >
      <el-option
        v-for="item in filterDataSets"
        :key="item.id"
        :label="`${item.name}  (${item.username}  - ${callFormatDate(
          item.created_at
        )})`"
        :value="item.id"
      >
        <span
          v-html="
            styleDataSet(
              item.name,
              item.username,
              callFormatDate(item.created_at)
            )
          "
        ></span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "../../../helper/format";
import { DATASET_STATUSES } from "../../../models/dataset";
import {
  DATASET_ACTIONS,
  DATASET_GETTERS,
} from "../../../store/modules/AutoML/Dataset/types";
export default {
  data() {
    return {
      searchDatasetInput: "",
      datasetCount: 100,
      selectedId: null,
    };
  },
  watch: {
    selectedId(value) {
      this.$emit("onSelected", value);
    },
  },
  mounted() {
    this.fetchDatasets({ page: 1, count: this.datasetCount });
  },
  computed: {
    ...mapGetters({
      dataSetList: DATASET_GETTERS.GET_DATASETS,
    }),
    filterDataSets() {
      return this.dataSetList.filter((x) => x.status == "READY");
    },
  },
  methods: {
    ...mapActions({
      fetchDatasets: DATASET_ACTIONS.FETCH_DATASETS,
    }),
    callLoadMoreDatasets() {
      this.datasetCount += 100;
      this.fetchDatasets({ page: 1, count: this.datasetCount });
    },
    searchDatasets(input) {
      this.searchDatasetInput = input;
      this.callFetchDatasets();
    },
    callFetchDatasets() {
      this.fetchDatasets({
        page: 1,
        count: this.datasetCount,
        status: DATASET_STATUSES.READY,
        name: this.searchDatasetInput,
      });
    },
    callFormatDate(date) {
      return formatDate(date, "DD/MM/YYYY HH:mm");
    },
    styleDataSet(name, username, date) {
      return (
        name +
        " " +
        "<span style='color:#416ca8; font-weight:bold;'>" +
        username +
        " " +
        "</span><span style='color:#43c984; font-weight:bold;'>" +
        date +
        "</span>"
      );
    },
  },
};
</script>