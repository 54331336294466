<template>
  <svg
    id="model-deployment-package-variant-closed"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Boundary"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0"
    >
      <rect width="24" height="24" stroke="none" />
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
    </g>
    <path
      id="Path_package-variant-closed"
      data-name="Path / package-variant-closed"
      d="M21,16.5a.991.991,0,0,1-.53.88l-7.9,4.44a.992.992,0,0,1-1.14,0l-7.9-4.44A.991.991,0,0,1,3,16.5v-9a.991.991,0,0,1,.53-.88l7.9-4.44a.992.992,0,0,1,1.14,0l7.9,4.44A.991.991,0,0,1,21,7.5v9M12,4.15,10.11,5.22,16,8.61,17.96,7.5,12,4.15M6.04,7.5,12,10.85l1.96-1.1L8.08,6.35,6.04,7.5M5,15.91l6,3.38V12.58L5,9.21v6.7m14,0V9.21l-6,3.37v6.71Z"
      fill="#fff"
    />
  </svg>
</template>
<script>

export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped>
</style>
