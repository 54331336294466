<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')">
    <div slot="title">
      <span style="font-weight: bold">{{ $t("Sample API Request") }}</span>
    </div>
    <div><span class="popupItemSubtitle">{{ $t("Model Id") }}:</span>{{ modelId }}</div>
    <div>
      <span class="popupItemSubtitle">{{ $t("build.Request Type") }}:</span>
      <div class="requestTypeContainer">
        <div class="switch-container">
          <span>{{ $t("build.Single") }}</span>
          <el-switch v-model="isSingleOrList" active-value = "list" inactive-value ="single"> </el-switch>
          <span>{{ $t("build.Multiple") }}</span>
        </div>
        <div class="switch-container">
          <span>HTTP</span>
          <el-switch v-model="isHttpOrHttps" active-value = "https" inactive-value ="http"> </el-switch>
          <span>HTTPS</span>
        </div>
      </div>
    </div>
    <div>
      <span class="popupItemSubtitle">URL:</span>{{ url }}
      <img
        style="margin-left: 10px !important; display: inline-flex"
        class="urlCopyBtn dtp-cursor-pointer dtp-vgt-actions-icon"
        data-clipboard-action="copy"
        src="../../../assets/images/copy-blue.svg"
        :data-clipboard-text="url"
      />
    </div>
    <div>
      <span class="popupItemSubtitle">{{ $t("build.Copy and test it") }}:</span>
    </div>
    <div class="curlTextContainer">
      <div style="display: block">{{ curlText }}</div>
      <img
        style="margin-left: auto !important"
        class="curlTextCopyBtn dtp-cursor-pointer dtp-vgt-actions-icon"
        data-clipboard-action="copy"
        src="../../../assets/images/copy-blue.svg"
        :data-clipboard-text="curlText"
      />
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Clipboard from "clipboard";
import {
  MODELS_ACTIONS,
  MODELS_GETTERS,
} from "../../../store/modules/AutoML/Models/types";

export default {
  props: {
    requestComp: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
    },
    modelId: {
      type: String,
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
    modelId: {
      handler(val) {
        if (val)
          this.fetchModelRequestInfo({
            requestComp: this.requestComp,
            model_id: this.modelId,
          });
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      isSingleOrList: "single",
      isHttpOrHttps: "http",
    };
  },
  mounted() {
    const clipboardUrlCopyButton = new Clipboard(".urlCopyBtn");
    const clipboardCurlTextCopyBtn = new Clipboard(".curlTextCopyBtn");
    clipboardUrlCopyButton.on("success", this.successFunc);
    clipboardUrlCopyButton.on("error", this.errorFunc);
    clipboardCurlTextCopyBtn.on("success", this.successFunc);
    clipboardCurlTextCopyBtn.on("error", this.errorFunc);
  },
  methods: {
    ...mapActions({
      fetchModelRequestInfo: MODELS_ACTIONS.FETCH_MODEL_REQUEST_INFO,
    }),
    successFunc(e) {
      e.clearSelection();

      this.$notify({
        title: this.$t("alert.success"),
        message: this.$t("alert.Copied to clipboard"),
        type: "success",
        showClose: false,
      });
    },
    errorFunc(e) {
      this.$notify.error({
        title: this.$t("alert.error"),
        message: this.$t("alert.operationFailed"),
        showClose: false,
      });
    },
  },

  computed: {
    curlText() {
      const initialCurlText = this.modelRequestInfo?.curl;
      const replacedFirst = initialCurlText?.replace(/'{}'/, `'${this.url}'`);
    
      if (this.isSingleOrList === "single")
        return replacedFirst?.replace(
          /'{}'/,
          `'${JSON.stringify(this.filteredItem?.payload)}'`
        );
      else {
        const payload = [JSON.parse(JSON.stringify(this.filteredItem?.payload))]
        payload?.push(payload[0])
        return replacedFirst?.replace(/'{}'/, `'${JSON.stringify(payload)}'`);
      }
    },
    ...mapGetters({
      modelRequestInfo: MODELS_GETTERS.GET_MODEL_REQUEST_INFO,
    }),
    filteredItem() {
      return this.modelRequestInfo?.data?.find(i => i.location.endsWith(this.isSingleOrList) && i.location.startsWith(this.isHttpOrHttps)) || {}
    },
    url() {
      return this.filteredItem?.location || "-"
    }
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  padding: 9px 10px;
  margin-left: 10px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
::v-deep .el-dialog__headerbtn {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
}
::v-deep .el-dialog {
  width: 650px;
}
::v-deep .el-switch.is-checked .el-switch__core {
  background-color: var(--color-primary) !important;
}
::v-deep .el-switch.is-checked .el-switch__core::after {
  background-color: var(--disabled-color) !important;
}
:v-deep .el-switch__core::after {
  background-color: var(--color-primary) !important;
}
.curlTextContainer {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 12px;
  color: #333333;
  font-size: 13px;
}
.curlTextCopyBtn {
  display: flex;
  height: 15px !important;
}
.urlCopyBtn {
  display: flex;
  height: 15px !important;
}
.popupItemSubtitle {
  font: normal normal normal 14px/36px Roboto;
  letter-spacing: 0px;
  color: #8c8c8c;
  width: 114px;
  display: inline-block;
}
.switch-container {
  display: flex;
  gap: 10px;
  color: #8c8c8c;
}
.requestTypeContainer{
  display: inline-flex;
  gap: 20px;
}
</style>