<template>
  <div class="dtp-create-popup dtp-create-popup-absolute">
    <div class="dtp-create-popup-title dtp-text--center">
      {{ $t("Add New Custom Model") }}
      <div
        @click="$emit('closePopup', false)"
        class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
      >
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="dtp-create-popup-content">
      <CustomModelCreateContent @closePopup="$emit('closePopup', false)" />
    </div>
  </div>
</template>
<script>
import CustomModelCreateContent from "./CustomModelCreateContent";

export default {
  components: {
    CustomModelCreateContent,
  },
};
</script>