export const settings = {
  state: () => ({
    preference: {
      shortDateFormat: "",
      longDateFormat: "",
      thousandFormat: "",
      decimalPlace: "",
      colorPalette: []
    }
  }),
  mutations: {
    "Settings/Mutate/Preference": (state) => {
      state.preference.shortDateFormat = ""
      state.preference.longDateFormat = ""
      state.preference.decimalPlace = ""
      state.preference.thousandFormat = ""
      state.preference.colorPalette = []
    },
    "Settings/Mutate/Preference/ShortDateFormat": (state, val) => {
      state.preference.shortDateFormat = val
    },
    "Settings/Mutate/Preference/LongDateFormat": (state, val) => {
      state.preference.longDateFormat = val
    },
    "Settings/Mutate/Preference/DecimalPlace": (state, val) => {
      state.preference.decimalPlace = val
    },
    "Settings/Mutate/Preference/ThousandFormat": (state, val) => {
      state.preference.thousandFormat = val
    },
    "Settings/Mutate/Preference/ColorPalette": (state, val) => {
      state.preference.colorPalette = val
    },
    
  },
  actions: {
    "Settings/Set/Preference": ({ commit }) => {
      commit("Settings/Mutate/Preference")
    },
    "Settings/Set/Preference/ShortDateFormat": ({ commit }, val) => {
      commit("Settings/Mutate/Preference/ShortDateFormat", val)
    },
    "Settings/Set/Preference/LongDateFormat": ({ commit }, val) => {
      commit("Settings/Mutate/Preference/LongDateFormat", val)
    },
    "Settings/Set/Preference/DecimalPlace": ({ commit }, val) => {
      commit("Settings/Mutate/Preference/DecimalPlace", val)
    },
    "Settings/Set/Preference/ThousandFormat": ({ commit }, val) => {
      commit("Settings/Mutate/Preference/ThousandFormat", val)
    },
    "Settings/Set/Preference/ColorPalette": ({ commit }, val) => {
      commit("Settings/Mutate/Preference/ColorPalette", val)
    },
  },
  getters: {
    "Settings/Get/Preference": (state) => {
      return state.preference
    },
    "Settings/Get/Preference/ShortDateFormat": (state) => {
      return state.preference.shortDateFormat
    },
    "Settings/Get/Preference/LongDateFormat": (state) => {
      return state.preference.longDateFormat
    },
    "Settings/Get/Preference/DecimalPlace": (state) => {
      return state.preference.decimalPlace
    },
    "Settings/Get/Preference/ThousandFormat": (state) => {
      return state.preference.thousandFormat
    },
    "Settings/Get/Preference/ColorPalette": (state) => {
      return state.preference.colorPalette
    },
  }
}
