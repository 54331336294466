<template>
  <div>
    <div class="dtp-row">
      <div
        v-for="(problem, index) in problemTypes"
        :key="problem.id"
        class="dtp-col dtp-col-4 dtp-col-s12 dtp-col-m6"
      >
        <el-card
          class="box-card"
          :class="imbalanceType === problem.id && 'box-card-selected'"
          shadow="always"
        >
          <el-radio
            v-model="imbalanceType"
            @change="changeCard"
            :label="problem.id"
            style="margin-bottom: 40px"
            >{{ $t(`${problem.label}`) }}</el-radio
          >
          <div class="dtp-row">
            <div
              v-if="problem.topOption"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div style="width: 100%">
                <div class="eds-field_#control eds-field_.eds-input eds-input">
                  <select
                    :value="cardSelectedValues[index]"
                    class="eds-input_#input eds-input_#input.dropdown"
                    :disabled="imbalanceType !== problem.id"
                    @change="(e) => setSelectedImbalanceMethod(e.target.value, index)"
                  >
                    <option selected value="" disabled>{{ $t("Choose a Method") }}</option>
                    <option
                      v-for="item in problem.types"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ convertSnakeCaseToSentence(item.method) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="problem.id === imbalanceType"
              class="dtp-col dtp-col-12 dtp-align-center dtp-mb--2 dtp-pa--0"
            >
              <div class="dtp-row" style="width: 100%">
                <div
                  v-for="paramKey in selectedImbalanceObjParams"
                  :key="`id-${paramKey}`"
                  class="
                    dtp-col dtp-col-12 dtp-align-center
                    dtp-mb--2
                    dtp-pa--0
                  "
                >
                  <div style="width: 100%">
                    <div v-if="paramKey === 'weights'">
                      <div>
                        <div class="classDistributionTableArea">
                          <el-table
                            style="width: 100%"
                            :data="classDistributionTableData"
                            stripe
                          >
                            <el-table-column
                              prop="class_names"
                              :label="$t('experiment.class names')"
                            >
                              <template slot-scope="scope">
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="scope.row.class_names"
                                  placement="left"
                                >
                                  <span> {{ scope.row.class_names }}</span>
                                </el-tooltip>
                              </template>
                            </el-table-column>
                            <el-table-column :label="$t('experiment.class distribution')">
                              <template slot-scope="scope">
                                <div>
                                  <input
                                    class="
                                      dtp-vgt-table-form-input
                                      dtp-vgt-table-form-input-active
                                    "
                                    type="number"
                                    :placeholder="paramKey"
                                    :value="
                                      getSelectedImbalanceObjParam(
                                        scope.row.class_names
                                      )
                                    "
                                    @change="
                                      (e) =>
                                        setSelectedImbalanceObjParam(
                                          scope.row.class_names,
                                          Number(e.target.value)
                                        )
                                    "
                                  />
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          imbalanceAlgorithmProperties[paramKey].type ===
                          'number'
                        "
                        class="
                          eds-field_#control eds-field_.eds-input
                          eds-input
                        "
                      >
                        <input
                          class="eds-input_#input dtp-floating-input"
                          type="number"
                          :placeholder="convertSnakeCaseToSentence(paramKey)"
                          :value="getSelectedImbalanceObjParam(paramKey)"
                          @change="
                            (e) =>
                              setSelectedImbalanceObjParam(
                                paramKey,
                                Number(e.target.value)
                              )
                          "
                          :min="imbalanceAlgorithmProperties[paramKey].minimum"
                          :max="imbalanceAlgorithmProperties[paramKey].maximum"
                        />
                        <label for="" class="dtp-floating-label">{{
                          convertSnakeCaseToSentence(paramKey)
                        }}</label>
                      </div>
                      <div
                        v-else-if="
                          imbalanceAlgorithmProperties[paramKey].type ===
                          'boolean'
                        "
                      >
                        <div style="display: flex">
                          <div class="dtp-pa--0 dtp-mr--1 dtp-text--right">
                            <input
                              type="checkbox"
                              class="eds-checkbox_#input"
                              :value="getSelectedImbalanceObjParam(paramKey)"
                              @change="
                                (e) =>
                                  setSelectedImbalanceObjParam(
                                    paramKey,
                                    e.target.checked
                                  )
                              "
                            />
                          </div>
                          <div>
                            <span class="dtp-font-bold">{{
                              convertSnakeCaseToSentence(paramKey)
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="
                          imbalanceAlgorithmProperties[paramKey].type ===
                          'string'
                        "
                      >
                        <span
                          style="
                            display: inline-block;
                            font-weight: bold;
                            font-size: 11px;
                            margin-bottom: 3px;
                          "
                        >
                          {{ convertSnakeCaseToSentence(paramKey) }}
                        </span>
                        <div
                          class="
                            eds-field_#control eds-field_.eds-input
                            eds-input
                          "
                        >
                          <select
                            :value="getSelectedImbalanceObjParam(paramKey)"
                            @change="
                              (e) =>
                                setSelectedImbalanceObjParam(
                                  paramKey,
                                  e.target.value
                                )
                            "
                            class="eds-input_#input eds-input_#input.dropdown"
                          >
                            <option selected value="" disabled>
                              {{ convertSnakeCaseToSentence(paramKey) }}
                            </option>
                            <option
                              v-for="it in imbalanceAlgorithmProperties[
                                paramKey
                              ].enum"
                              :key="it"
                              :value="it"
                            >
                              {{ it }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { PRIVILEGES } from "../../../helper/authorization";
import {
  ImbalanceAlgorithmsTypes,
  ImbalanceAlgorithms,
  ImbalanceAlgorithmPropertiesOverSampling,
  ImbalanceAlgorithmPropertiesUnderSampling,
} from "./data/data";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import cloneDeep from "clone-deep";
import { DATA_GETTERS } from '../../../store/modules/AutoML/Data/types';
import { MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      selectedImbalanceMethod: "",
      imbalanceType: ImbalanceAlgorithmsTypes.Oversampling,
      inputList: {
        target: "target",
        trainRatio: "train_ratio",
        crossValidationNumberofFolds: "cross_validation_number_of_folds",
      },
      imbalanceDataControlLoading: false,
      cardSelectedValues: []
    };
  },
  mounted() {
    this.updateBalanceConfigStore();
  },
  methods: {
    ...mapMutations({
      updateProblemConfig: MODELS_MUTATIONS.SET_PROBLEM_CONFIGURATION_PARAMS,
      updateBalanceConfig: MODELS_MUTATIONS.SET_BALANCE_CONFIGURATION,
    }),
    changeCard() {
      this.cardSelectedValues = [];
      this.selectedImbalanceMethod = '';
      this.updateBalanceConfigStore();
    },
    convertSnakeCaseToSentence(snakeCaseVal) {
      let snakeCaseArr = snakeCaseVal.split("_");
      for (var i = 0; i < snakeCaseArr.length; i++) {
        snakeCaseArr[i] =
          snakeCaseArr[i].charAt(0).toUpperCase() + snakeCaseArr[i].slice(1);
      }

      return snakeCaseArr.join(" ");
    },
    getSelectedImbalanceObjParam(param) {
      if (this.imbalanceType !== ImbalanceAlgorithmsTypes.ClassWeight) {
        return this.selectedImbalanceObj.params[param];
      } else {
        return this.selectedImbalanceObj.params.weights[param];
      }
    },
    setSelectedImbalanceObjParam(param, val) {
      if (this.imbalanceType !== ImbalanceAlgorithmsTypes.ClassWeight) {
        this.selectedImbalanceObj.params[param] = val;
      } else {
        this.selectedImbalanceObj.params.weights[param] = val;
      }
      this.updateBalanceConfigStore();
    },
    updateBalanceConfigStore() {
      if (this.imbalanceType !== ImbalanceAlgorithmsTypes.ClassWeight) {
        this.updateBalanceConfig(
          JSON.parse(
            JSON.stringify({
              ...this.selectedImbalanceObj,
              type: this.imbalanceType,
            })
          )
        );
      } else {
        this.selectedImbalanceMethod = "class_weight";
        if (!this.getBalanceConfiguration?.params)
          this.classDistributionTableData.forEach((cdt) => {
            this.selectedImbalanceObj.params.weights[cdt.class_names] = 0;
          });
        this.updateBalanceConfig(
          JSON.parse(
            JSON.stringify({
              ...this.selectedImbalanceObj,
              type: this.imbalanceType,
            })
          )
        );
      }
    },
    setSelectedImbalanceMethod(val, index) {
      this.cardSelectedValues[index] = val;
      this.selectedImbalanceMethod = val;
      this.updateBalanceConfigStore();
    },
  },
  computed: {
    ...mapGetters({
      problem_configuration: MODELS_GETTERS.GET_PROBLEM_CONFIGURATION_PARAMS,
      imbalanceInspection: DATA_GETTERS.GET_IMBALANCE_INSPECTION,
      getBalanceConfiguration: MODELS_GETTERS.GET_BALANCE_CONFIGURATION,
    }),

    isClusterCentroidsAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS
      );
    },
    imbalanceAlgorithmProperties() {
      const { type } = this.getBalanceConfiguration;

      if (
        type === ImbalanceAlgorithmsTypes.Oversampling ||
        type === ImbalanceAlgorithmsTypes.ClassWeight
      ) {
        return JSON.parse(
          JSON.stringify(ImbalanceAlgorithmPropertiesOverSampling)
        );
      }
      return JSON.parse(
        JSON.stringify(ImbalanceAlgorithmPropertiesUnderSampling)
      );
    },
    classDistributionTableData() {
      const res = [];

      if (this.imbalanceInspection?.class_distribution)
        Object.keys(this.imbalanceInspection.class_distribution).forEach((cd) =>
          res.push({
            class_names: cd,
            class_distribution: this.imbalanceInspection.class_distribution[cd],
          })
        );

      return res;
    },
    selectedImbalanceObjParams() {
      return this.selectedImbalanceObj.params
        ? Object.keys(this.selectedImbalanceObj.params)
        : [];
    },

    selectedImbalanceObj() {
      const foundWithImbalanceType = this.problemTypes.filter(
        (c) => c.id === this.imbalanceType
      )[0];

      const foundWithSelectedImbalanceMethod =
        foundWithImbalanceType.types.filter(
          (k) => k.id === this.selectedImbalanceMethod
        );

      if (
        foundWithSelectedImbalanceMethod &&
        foundWithSelectedImbalanceMethod.length
      ) {
        return foundWithSelectedImbalanceMethod[0];
      } else {
        return {};
      }
    },

    classWeightType() {
      return ImbalanceAlgorithmsTypes.ClassWeight;
    },
    problemTypes() {
      let undersamplingTypes = cloneDeep(ImbalanceAlgorithms).filter(
        (x) => x.type === ImbalanceAlgorithmsTypes.Undersampling
      );

      if (!this.isClusterCentroidsAvailable) {
        undersamplingTypes = undersamplingTypes.filter(
          (x) => x.method !== "cluster_centroids"
        );
      }

      return [
        {
          id: ImbalanceAlgorithmsTypes.Oversampling,
          label: "Oversampling",
          fields: [
            this.inputList.target,
            this.inputList.trainRatio,
            this.inputList.crossValidationNumberofFolds,
          ],
          types: cloneDeep(ImbalanceAlgorithms).filter(
            (x) => x.type === ImbalanceAlgorithmsTypes.Oversampling
          ),
          topOption: true,
        },
        {
          id: ImbalanceAlgorithmsTypes.Undersampling,
          label: "Undersampling",
          fields: [
            this.inputList.target,
            this.inputList.trainRatio,
            this.inputList.crossValidationNumberofFolds,
          ],
          types: undersamplingTypes,
          topOption: true,
        },
        {
          id: ImbalanceAlgorithmsTypes.ClassWeight,
          label: "Class Weight",
          fields: [this.inputList.trainRatio],
          types: cloneDeep(ImbalanceAlgorithms).filter(
            (x) => x.type === ImbalanceAlgorithmsTypes.ClassWeight
          ),
          textArea: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card-selected {
  border: 1px solid #436da9 !important;
}

.box-card {
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  opacity: 1;
  height: 100%;
}

::v-deep .el-radio {
  margin: 0;
  text-align: center;
  width: 100%;
}

::v-deep .el-radio__input {
  display: block;
  margin-bottom: 10px;
}

::v-deep .el-radio__label {
  display: block;
  padding: 0;
  font-weight: bold;
  color: black;
}

::v-deep .el-table__header-wrapper {
  display: none;
}

::v-deep .el-table_1_column_1 {
  padding: 0 !important;
}

::v-deep .el-table_2_column_2 {
  padding: 0 !important;
}

::v-deep .el-table_3_column_3 {
  padding: 0 !important;
}

::v-deep .el-table .cell {
  white-space: nowrap;
}

#tablekismi {
  margin-bottom: 46px;
  margin-top: 20px;
}

#switcherImbalance {
  .switch input {
    display: none;
  }

  .switch {
    display: inline-block;
    width: 35px; /*=w*/
    height: 20px; /*=h*/
    margin: 5px 3px 5px 0;
    transform: translateY(50%);
    position: relative;
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 1px #9e9e9e, 0 0 2px #9e9e9e;
    cursor: pointer;
    border: 3px solid transparent;
    overflow: hidden;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #9e9e9e;
    border-radius: 30px;
    transform: translateX(-15px); /*translateX(-(w-h))*/
    transition: 0.4s;
  }

  input:checked + .slider:before {
    transform: translateX(15px); /*translateX(w-h)*/
    background-color: #436da9;
  }

  #imbalanceDataControlLoading {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
