export const sortControl = (allParams) => {
    if(allParams.sort_by === "") {
        delete allParams.sort_by;
        delete allParams.ascending;
    }
    
    allParams = eliminateFalsyValues(allParams);

    return new URLSearchParams(allParams);
}

export const objectToQueryString = (obj) => {
    let objCloned = { ...obj }

    objCloned = eliminateFalsyValues(objCloned);

    return "?" + new URLSearchParams(objCloned).toString();
}

export const eliminateFalsyValues = (obj) => {
    const newObj = {};

    Object.keys(obj).forEach(key => {
        if(obj[key] !== null && obj[key] !== undefined && obj[key] !== "")
            newObj[key] = obj[key];
    });
    
    return newObj;
}