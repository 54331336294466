export const classification = [
    "Accuracy",
    "AUC",
    "Gini",
    "Recall",
    "Precision",
    "F1",
    "Kappa",
    "MCC",
];

export const regression = ["MAE", "MSE", "RMSE", "R2", "RMSLE", "MAPE"];

export const metricLabels = {
    "Accuracy": "Accuracy",
    "AUC": "AUC",
    "Recall": "Recall",
    "Precision": "Precision",
    "F1": "F-Measure",
    "Kappa": "Kappa",
    "MCC": "MCC",
    "MAE": "MAE", 
    "MSE": "MSE", 
    "RMSE": "RMSE", 
    "R2": "R2 Score", 
    "RMSLE": "RMSLE",
    "MAPE": "MAPE",
    "Gini": "Gini"
}