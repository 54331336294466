<template>
  <div>
    <div class="dtp-row">
      <div class="dtp-col dtp-col-10">
        <div class="dtp-panel-formbox">
          <span class="dtp-aml-page-title">{{ $t("model.Predictions") }}</span>
        </div>
        <div class="dtp-panel-formbox">
          <button
            @click="activePopup = !activePopup"
            class="eds-button eds-button.basic"
          >
            {{ $t("model.Make New Prediction") }}
          </button>
          <div
            v-if="activePopup"
            class="dtp-create-popup dtp-create-predict-popup-absolute"
          >
            <div class="dtp-create-popup-title dtp-text--center">
              {{ $t("automl.predictDataset") }}
              <div
                @click="activePopup = false"
                class="dtp-popup-close-icon dtp-popup-close-icon-absolute"
              >
                <i class="el-icon-close" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dtp-create-popup-content">
              <div class="dtp-row">
                <div
                  class="dtp-col dtp-col-10 dtp-chart-box-20"
                  v-if="fileUpload"
                > 
                  <DatasetFiltering
                     @onSelected="selectChangeDataSet"
                  />
                </div>
                <div v-if="fileUpload" class="dtp-col dtp-col-2">
                  <el-tooltip
                    :content="$t('Add New Data Set')"
                    placement="top"
                    effect="light"
                  >
                    <button
                      @click="fileUpload = false"
                      class="aml-icon-btn"
                    >
                      <Icon icon="add"></Icon>
                    </button>
                  </el-tooltip>
                </div>
                <div
                  class="dtp-col dtp-col-12 dtp-chart-box-20"
                  v-if="!fileUpload"
                >
                  <div
                    class="eds-field_#control eds-field_.eds-input eds-input"
                  >
                    <select
                      v-model="dataset"
                      class="eds-input_#input eds-input_#input.dropdown"
                    >
                      <option value="" disabled>
                        {{ $t("dataset.please_select_dataset") }}
                      </option>
                      <option
                        v-for="item in files"
                        :key="item.id"
                        :value="item.value"
                      >
                        {{ $t(`${item.label}`) }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  id="switcherPredictions"
                  v-if="fileUpload"
                  class="dtp-col dtp-col-12 dtp-align-center dtp-chart-box-20"
                >
                  <div
                    v-if="isValidationCheckboxAvailable"
                    class="dtp-panel-formbox dtp-mr--0"
                  >
                    <label class="switch">
                      <input v-model="switchValidationTest" type="checkbox" />
                      <span class="slider"></span>
                    </label>
                    <span class="eds-checkbox_#label">{{ $t("model.Validation Test") }}</span>
                  </div>
                </div>
                <div class="dtp-col dtp-col-12 dtp-text--right">
                  <button
                    v-if="!fileUpload"
                    @click="fileUpload = true"
                    class="eds-button eds-button.basic dtp-mr--2"
                  >
                    {{ $t("commons.back") }}
                  </button>
                  <button @click="submit()" class="eds-button eds-button.basic">
                    {{ $t("buttons.continue") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dtp-panel-formbox">
          <PageViewBar
            :activeFilters="false"
            :activeSearch="true"
            :tooltipSearchName="'Data Set'"
            :headerArray="[]"
            :filterList="[]"
            :createBtn="false"
            :calculateview="false"
            @changedSearch="changedSearch"
          />
        </div>
      </div>
      <div class="dtp-col dtp-col-2 dtp-flex-horizontal-end">
        <el-tooltip :content="$t('Refresh Table')" placement="top" effect="light">
          <button
            class="aml-btn eds-button.primary dtp-mr--2"
            @click="$emit('refresh')"
          >
            <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
          </button>
        </el-tooltip>
        <div @click="info = !info" class="dtp-aml-info primary">
          <span>i</span>
        </div>
        <Info @closePopup="info = $event" v-if="info" :InfoList="InfoList" />
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "../../../helper/format.js";
import Info from "./Components/info.vue";
import PageViewBar from "../Helper/Pageviewbar.vue";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck.js";
import { PRIVILEGES } from "../../../helper/authorization.js";
import Icon from "../../Icons/Icon.vue"
import DatasetFiltering from "../utils/DatasetFiltering.vue"

export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
    InfoList: {
      type: Array,
    },
  },
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      activePopup: false,
      fileUpload: true,
      dataset: "",
      info: false,
      switchValidationTest: false,
    };
  },
  components: { Info, PageViewBar, Icon, DatasetFiltering },
  computed: {
    isValidationCheckboxAvailable() {
      return this.isComponentAvailable(
        PRIVILEGES.AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX
      );
    },
  },
  methods: {
    submit() {
      if (!this.fileUpload && this.dataset != "") {
        if (this.dataset == "LocalFile") {
          this.$router.push({ name: "CsvUploadAmL" });
        } else {
          this.$router.push({ name: "NewEditViewquery" });
        }
      } else {
        this.$emit("predictClicked", {
          isValidationsSelected: this.switchValidationTest,
        });
        this.activePopup = false;
      }
    },
    callFormatDate(date) {
      return formatDate(date, "DD/MM/YYYY HH:mm");
    },
    selectChangeDataSet(datasetId) {
      this.$emit("selectChangeDataSet", datasetId);
    },
    changedSearch(searchText) {
      this.$emit("changedSearch", searchText);
    },
 
  },
};
</script>

<style  lang="scss" scoped>
.dtp-create-predict-popup-absolute {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 10;
}
.dtp-plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

#switcherPredictions {
  .switch input {
    display: none;
  }

  .switch {
    display: inline-block;
    width: 35px; /*=w*/
    height: 20px; /*=h*/
    margin: 5px 3px 5px 0;
    transform: translateY(50%);
    position: relative;
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 1px #9e9e9e, 0 0 2px #9e9e9e;
    cursor: pointer;
    border: 3px solid transparent;
    overflow: hidden;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #9e9e9e;
    border-radius: 30px;
    transform: translateX(-15px); /*translateX(-(w-h))*/
    transition: 0.4s;
  }

  input:checked + .slider:before {
    transform: translateX(15px); /*translateX(w-h)*/
    background-color: #436da9;
  }
}
</style>
