<template>
  <div class="dtp-section">
      <div class="dtp-row dtp-align-center">
        <div class="aml-section-header dtp-align-center">
          <div class="dtp-panel-formbox">
            <div class="dtp-page-title">
              <span>
                <Icon icon="infrastructure" size="xl"></Icon>
              </span>
              {{ $t("cluster.title") }}
            </div>
          </div>
          <div class="dtp-panel-formbox">
            <el-tooltip
              :content="$t('Create New Cluster')"
              placement="top"
              effect="light"
            >
              <button
                @click="() => (clusterCreatePopup = true)"
                class="aml-icon-btn"
              >
                <Icon icon="add"></Icon>
              </button>
            </el-tooltip>
            <CreateCluster
              v-if="isAddClusterButtonAvailable && clusterCreatePopup"
              title="Cluster"
              @create="callCreateCluster"
              @closePopup="() => (clusterCreatePopup = false)"
            />
          </div>
          <PageViewBar
            :title="''"
            :activeFilters="false"
            :activeSearch="false"
            :tooltipSearchName="'Search Cluster'"
            :headerArray="filterArray"
            :filterList="filterList"
            :createBtn="false"
            :calculateview="false"
            @changedSearch="(t) => (searchText = t)"
          />

        </div>
      </div>
    <div class="dtp-row">
      <InfrastructureTable
        :rows="nullCheckedClusters"
        :columns="columns"
        :loading="loading"
        @onRepairCluster="onRepairCluster"
        @onDeleteCluster="onDeleteCluster"
        @onUpdateCluster="onUpdateCluster"
      />
      <CustomPagination
        class="buildListPagination"
        :lastPage="lastPage"
        :pageSize.sync="pageSize"
        :showPageSizeSelect="true"
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { COLUMNS } from "./data/clusters";
import { mapActions, mapGetters } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import preferences from "../../../mixins/AutoML/preferences";
import componentAvailabilityCheck from "../../../mixins/AutoML/componentAvailabilityCheck";
import InfrastructureTable from "./InfrastructureTable.vue";
import PageViewBar from "../Helper/Pageviewbar";
import CustomPagination from "../CustomPagination";
import CreateCluster from "./CreateCluster";
import { CLUSTER_ACTIONS, CLUSTER_GETTERS } from '../../../store/modules/AutoML/Clusters/types';
import Icon from "../../Icons/Icon.vue";
import { PRIVILEGES } from '../../../helper/authorization';

export default {
  mixins: [notify, preferences, componentAvailabilityCheck],
  components: {
    InfrastructureTable,
    PageViewBar,
    CustomPagination,
    CreateCluster,
    Icon
  },
  data() {
    return {
      dialogVisible: false,
      searchText: "",
      filterList: [],
      filterArray: [],
      columns: COLUMNS,
      selectedRowIdForUpdate: null,
      filterables: [
        { label: "Portfolio", key: "portfolio", type: "input" },
        { label: "Use Case", key: "useCase", type: "input" },
        { label: "Status", key: "status", type: "input" },
      ],
      pageSize: 20,
      currentPage: 1,
      clusterCreatePopup: false,
    };
  },
  watch: {
    filterList: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  mounted() {
    this.requestComp = "infrastructure";
    this.init();
  },
  computed: {
    ...mapGetters({
      clusters: CLUSTER_GETTERS.GET_CLUSTERS,
    }),
    lastPage() {
      return 1;
    },
    nullCheckedClusters() {
      return this.clusters ?? [];
    },
    isAddClusterButtonAvailable() {
      return this.isComponentAvailable(PRIVILEGES.AUTOML_COMPONENT_CREATE_CLUSTER);
    }
  },
  methods: {
    ...mapActions({
      fetchClusters: CLUSTER_ACTIONS.FETCH_CLUSTERS,
      createCluster: CLUSTER_ACTIONS.CREATE_CLUSTER,
      deleteCluster: CLUSTER_ACTIONS.DELETE_CLUSTER,
      updateCluster: CLUSTER_ACTIONS.UPDATE_CLUSTER,
      repairCluster: CLUSTER_ACTIONS.REPAIR_CLUSTER,
    }),
    onRepairCluster(cluster_id) {
      this.repairCluster({ requestComp: this.requestComp, cluster_id });
    },
    onDeleteCluster(cluster_id) {
      this.deleteCluster({ requestComp: this.requestComp, cluster_id });
    },
    onUpdateCluster(params) {
      this.updateCluster({ requestComp: this.requestComp, ...params });
    },
    callCreateCluster(params) {
      const res = this.createCluster({
        requestComp: this.requestComp,
        ...params,
      });

      if (res) this.clusterCreatePopup = false;
    },
    init() {
      this.fetchClusters({
        page: this.currentPage,
        count: this.pageSize,
        requestComp: this.requestComp,
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.init();
    },
  },
};
</script>

<style scoped>
.buildListPagination {
  margin-left: 15px;
  margin-right: 15px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
  padding: 10px 20px 10px 20px;
}
</style>
