export const domain = "AUTOML/CLUSTERS";

export const CLUSTER_ACTIONS = {
  FETCH_CLUSTERS: `${domain}/fetchClusters`,
  CREATE_CLUSTER: `${domain}/createCluster`,
  DELETE_CLUSTER: `${domain}/deleteCluster`,
  UPDATE_CLUSTER: `${domain}/updateCluster`,
  REPAIR_CLUSTER: `${domain}/repairCluster`,
};

export const CLUSTER_MUTATIONS = {
  SET_CLUSTERS: `${domain}/setClusters`,
};

export const CLUSTER_GETTERS = {
  GET_CLUSTERS: `${domain}/getClusters`,
}