<template>
  <div v-loading="loading" class="dtp-col dtp-col-12 dtp-freeze-header">
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      class="vgt-cursor-cell"
    >
      <template slot="table-column" slot-scope="props">
        <span>{{ $t("table.header." + props.column.label) }}</span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <el-tooltip
            v-if="isDeployBuildAvailable"
            :content="$t('Deploy Model')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../assets/images/deploymodel.svg"
              @click.stop="$emit('onDeployModel', props.row.id)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
              alt="deploymodelIcon"
            />
          </el-tooltip>
          <el-tooltip
            v-if="isModelMonitoringAvailable && !isDeploymentStatusDraft(props.formattedRow['deployment'])"
            :content="$t('Model Monitoring')"
            placement="top"
            effect="light"
          >
            <a
              :href="grafanaUrl"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
              target="_blank"
            >
              <img
                src="../../../assets/images/modelmonitoring.svg"
                @click.stop="$emit('onModelMonitoring', props.row.id)"
                alt="modelmonitoringIcon"
              />
            </a>
          </el-tooltip>
          <el-tooltip
            v-if="isDeploymentStatusLive(props.formattedRow['deployment']) && isSampleApiRequestAvailable"
            :content="$t('Sample API Request')"
            placement="top"
            effect="light"
          >
            <img
              src="../../../assets/images/link.svg"
              @click.stop="showSampleAPIRequestPopup(props.row.modelId)"
              class="dtp-cursor-pointer dtp-vgt-actions-icon"
              alt="modelmonitoringIcon"
            />
          </el-tooltip>
          <el-tooltip
            v-if="isDeleteBuildAvailable"
            :content="$t(getDeleteTooltip(props.formattedRow['deployment']))"
            placement="top"
            effect="light"
          >
            <img
              src="../../../assets/images/icons/automldeleteicon.svg"
              @click.stop="
                () =>
                  isDeploymentStatusDraft(props.formattedRow['deployment'])
                    ? deleteBuild(props.row.id)
                    : null
              "
              class="dtp-vgt-actions-icon"
              :class="
                isDeploymentStatusDraft(props.formattedRow['deployment'])
                  ? 'dtp-cursor-pointer'
                  : 'dtp-cursor-not'
              "
              alt="deleteIcon"
            />
          </el-tooltip>
        </span>

        <span
          v-else-if="
            props.column.field == 'portfolioName' ||
            props.column.field == 'useCaseName'
          "
          style="display: flex; align-items: center"
        >
          <span>{{
            props.formattedRow[props.column.field]
              ? props.formattedRow[props.column.field]
              : "-"
          }}</span>
        </span>

        <span v-else-if="props.column.field == 'version'">
          <div style="display: flex">
            <span>{{ props.formattedRow[props.column.field] }}</span>
            <img
              v-if="props.formattedRow[props.column.field]"
              class="copyBtn dtp-cursor-pointer dtp-vgt-actions-icon"
              style="margin-left: auto !important"
              data-clipboard-action="copy"
              src="../../../assets/images/copy.svg"
              :data-clipboard-text="props.formattedRow[props.column.field]"
            />
          </div>
        </span>

        <span v-else-if="props.column.field == 'deployment'">
          <a
            v-if="
              isDeploymentStatusLiveOrReadyToLive(
                props.formattedRow['deployment']
              )
            "
            class="linkbutton"
            :href="createArgoCDLink(props.row)"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ $t("status." + props.formattedRow[props.column.field]) }}
          </a>
          <span v-else> {{ $t("status." + props.formattedRow[props.column.field]) }}</span>

          <DeploymentInfoPopup
            :deployment="props.row.deployment"
            v-if="!isDeploymentStatusDraft(props.formattedRow['deployment'])"
          />
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <SampleAPIRequestPopup
      :requestComp="requestComp"
      :visible="sampleAPIRequestPopupVisible"
      :modelId="selectedSampleAPIRequestPopupModelId"
      @modalClosed="() => (sampleAPIRequestPopupVisible = false)"
    />
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Clipboard from "clipboard";
import { DeploymentStatus } from "./data/builds";
import DeploymentInfoPopup from "../Popup/DeploymentInfoPopup.vue";
import SampleAPIRequestPopup from "./SampleAPIRequestPopup";
import { mapGetters } from 'vuex';
import { GENERAL_GETTERS } from '../../../store/modules/AutoML/General/types';

export default {
  
  components: {
    SampleAPIRequestPopup,
    VueGoodTable,
    DeploymentInfoPopup,
  },
  props: {
    portfolioOptions: {
      type: Array,
      required: true,
    },
    usecaseOptions: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    isDeployBuildAvailable: {
      type: Boolean,
      required: true,
    },
    isDeleteBuildAvailable: {
      type: Boolean,
      required: true,
    },
    isSampleApiRequestAvailable: {
      type: Boolean,
      required: true,
    },
    isModelMonitoringAvailable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRowIdForUpdate: null,
      editStates: {
        USECASE: "USECASE",
        PORTFOLIO: "PORTFOLIO",
      },
      selectedEditState: {
        type: null,
        id: null,
      },
      selectedSampleAPIRequestPopupModelId: "",
      sampleAPIRequestPopupVisible: false,
      requestComp: "buildList",
    };
  },
  mounted() {
    this.clipboard = new Clipboard(".copyBtn");
    this.clipboard.on("success", this.successFunc);
    this.clipboard.on("error", this.errorFunc);
  },
  computed: {
     ...mapGetters({
      loading: GENERAL_GETTERS.GET_LOADING,
    }),
    grafanaUrl() {
      return process.env.VUE_APP_GRAFANA;
    },
  },
  methods: {
    showSampleAPIRequestPopup(modelId) {
      this.selectedSampleAPIRequestPopupModelId = modelId;
      this.sampleAPIRequestPopupVisible = true;
    },
    createArgoCDLink(row) {
      return process.env.VUE_APP_AUTOML_ARGOCD_ENDPOINT + row.applicationName;
    },
    handleOk(row) {
      this.resetEditState();
    },
    resetEditState() {
      this.$set(this.selectedEditState, "type", null);
      this.$set(this.selectedEditState, "id", null);
    },
    handleEdit(editStateType, id) {
      this.$set(this.selectedEditState, "type", editStateType);
      this.$set(this.selectedEditState, "id", id);
    },
    successFunc() {
      this.$notify({
        title: this.$t("alert.success"),
        message: this.$t("alert.Copied to clipboard"),
        type: "success",
        showClose: false,
      });
    },
    errorFunc() {
      this.$notify.error({
        title: this.$t("alert.error"),
        message: this.$t("alert.operationFailed"),
        showClose: false,
      });
    },
    deleteBuild(id) {
      this.$emit("deleteBuild", id);
    },
    updateDescription(r) {
      this.selectedRowIdForUpdate = null;
      const { id, description, name, type } = r;
      this.$emit("updateDescription", { id, description, name, type });
    },
    getDeleteTooltip(deploymentStatus) {
      return deploymentStatus === DeploymentStatus.Draft
        ? "tooltip.delete"
        : "Deployed models can not be deleted";
    },
    isDeploymentStatusDraft(deploymentStatus) {
      return deploymentStatus === DeploymentStatus.Draft;
    },
    isDeploymentStatusLive(deploymentStatus) {
      return deploymentStatus === DeploymentStatus.Live;
    },
    isDeploymentStatusLiveOrReadyToLive(deploymentStatus) {
      return (
        deploymentStatus === DeploymentStatus.Live ||
        deploymentStatus === DeploymentStatus.ReadyToLive
      );
    },
  },
};
</script>

<style scoped>
::v-deep .vgt-table tbody tr td {
  vertical-align: middle;
}
</style>

<style lang="scss" scoped>
.linkbutton {
  cursor: pointer;
  text-decoration: underline;
  color: #436da9;
  &:hover {
    font-weight: bold;
  }
}
</style>