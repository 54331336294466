export const domain = "AUTOML/BUILD";

export const BUILD_ACTIONS = {
  DELETE_BUILD: `${domain}/deleteBuild`,
  DEPLOY_BUILD: `${domain}/deployBuild`,
  FETCH_BUILD_BY_ID: `${domain}/fetchBuildById`,
  FECTH_BUILDS: `${domain}/fetchBuilds`,
  FETCH_DEPLOYMENT_STATUS_BY_BUILDS: `${domain}/fetchDeploymentStatusByBuilds`,
  FETCH_DEPLOYMENT_STATUS_BY_SINGLE_BUILD: `${domain}/fetchDeploymentStatusBySingleBuild`,
};

export const BUILD_MUTATIONS = {
  SET_BUILD_LIST: `${domain}/setBuildList`,
  SET_DEPLOYMENT_STATUS_OF_BUILDS: `${domain}/setDeploymentStatusOfBuilds`,
  UPDATE_DEPLOYMENT_STATUS_OF_BUILDS: `${domain}/updateDeploymentStatusOfBuilds`,
};

export const BUILD_GETTERS = {
  GET_BUILD_LIST: `${domain}/getBuildList`,
  GET_DEPLOYMENT_STATUS_OF_BUILDS: `${domain}/getDeploymentStatusOfBuilds`,
}