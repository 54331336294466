export const PRIVILEGES = {
    PATTERN_EDIT: "PatternEdit",
    PATTERN_READ: "PatternRead",
    MAP_READ: "MapRead",
    MAP_EDIT: "MapEdit",
    ROLE_INDEX_READ: "RoleIndexRead",
    ROLE_INDEX_EDIT: "RoleIndexEdit",
    DATASOURCE_EDIT: "DataSourceEdit",
    QUERY_VIEW: "QueryView",
    DASHBOARD_READ: "DashboardRead",
    DASHBOARD_EDIT: "DashboardEdit",
    VISUALIZE_READ: "VisualizeRead",
    VISUALIZE_EDIT: "VisualizeEdit",
    SCENARIO_EDIT: "ScenarioEdit",
    DATASOURCE_READ: "DataSourceRead",
    QUERY_EDIT: "QueryEdit",

    AUTOML_COMPONENT_NAVIGATION_REVERT_ACTION: "AUTOML_COMPONENT_NAVIGATION_REVERT_ACTION",
    AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS: "AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS",
    AUTOML_CUSTOMER_LOGO: "AUTOML_CUSTOMER_LOGO",
    AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX: "AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX",
    AUTOML_COMPONENT_DOWNLOAD_MODEL_OBJECT: "AUTOML_COMPONENT_DOWNLOAD_MODEL_OBJECT",
    AUTOML_COMPONENT_ADD_PROJECT: "AUTOML_COMPONENT_ADD_PROJECT",
    AUTOML_COMPONENT_DELETE_PROJECT: "AUTOML_COMPONENT_DELETE_PROJECT",
    AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON: "AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON",
    AUTOML_COMPONENT_CHALLENGER_EXPERIMENT_BUTTON: "AUTOML_COMPONENT_CHALLENGER_EXPERIMENT_BUTTON",
    AUTOML_COMPONENT_RESET_EXPERIMENT_BUTTON: "AUTOML_COMPONENT_RESET_EXPERIMENT_BUTTON",
    AUTOML_COMPONENT_DELETE_EXPERIMENT_BUTTON: "AUTOML_COMPONENT_DELETE_EXPERIMENT_BUTTON",
    AUTOML_COMPONENT_MODEL_GOVERNANCE_BUTTON: "AUTOML_COMPONENT_MODEL_GOVERNANCE_BUTTON",
    AUTOML_COMPONENT_MODEL_LOGS_BUTTON: "AUTOML_COMPONENT_MODEL_LOGS_BUTTON",
    AUTOML_COMPONENT_DISPLAY_MODEL_RESULTS_BUTTON: "AUTOML_COMPONENT_DISPLAY_MODEL_RESULTS_BUTTON",
    AUTOML_COMPONENT_START_REFRESH_EXPERIMENT_BUTTON: "AUTOML_COMPONENT_START_REFRESH_EXPERIMENT_BUTTON",
    AUTOML_COMPONENT_ADD_DATASET_BUTTON: "AUTOML_COMPONENT_ADD_DATASET_BUTTON",
    AUTOML_COMPONENT_VISUALIZE_DATASET_BUTTON: "AUTOML_COMPONENT_VISUALIZE_DATASET_BUTTON",
    AUTOML_COMPONENT_DELETE_DATASET_BUTTON: "AUTOML_COMPONENT_DELETE_DATASET_BUTTON",
    AUTOML_COMPONENT_MAKE_PREDICTION_BUTTON: "AUTOML_COMPONENT_MAKE_PREDICTION_BUTTON",
    AUTOML_COMPONENT_APPLICATION_BUTTON: "AUTOML_COMPONENT_APPLICATION_BUTTON",
    AUTOML_COMPONENT_MODEL_REPORTS_BUTTON: "AUTOML_COMPONENT_MODEL_REPORTS_BUTTON",
    AUTOML_COMPONENT_DL_TEST_RESULT_BUTTON: "AUTOML_COMPONENT_DL_TEST_RESULT_BUTTON",
    AUTOML_COMPONENT_MODEL_DIAGNOSTICS_BUTTON: "AUTOML_COMPONENT_MODEL_DIAGNOSTICS_BUTTON",
    AUTOML_COMPONENT_INTERPRET_BUTTON: "AUTOML_COMPONENT_INTERPRET_BUTTON",
    AUTOML_COMPONENT_BUILD_BUTTON: "AUTOML_COMPONENT_BUILD_BUTTON",
    AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON: "AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON",
    AUTOML_COMPONENT_ADD_NEW_CUSTOM_MODEL: "AUTOML_COMPONENT_ADD_NEW_CUSTOM_MODEL",
    AUTOML_COMPONENT_DELETE_CUSTOM_MODEL_BUTTON: "AUTOML_COMPONENT_DELETE_CUSTOM_MODEL_BUTTON",
    AUTOML_COMPONENT_RETRAIN_CUSTOM_MODEL_BUTTON: "AUTOML_COMPONENT_RETRAIN_CUSTOM_MODEL_BUTTON",
    AUTOML_COMPONENT_DEPLOY_BUILD: "AUTOML_COMPONENT_DEPLOY_BUILD",
    AUTOML_COMPONENT_MODEL_MONITORING: "AUTOML_COMPONENT_MODEL_MONITORING",
    AUTOML_COMPONENT_DELETE_BUILD: "AUTOML_COMPONENT_DELETE_BUILD",
    AUTOML_COMPONENT_SAMPLE_REQ_API_BUTTON: "AUTOML_COMPONENT_SAMPLE_REQ_API_BUTTON",
    AUTOML_COMPONENT_CREATE_CLUSTER: "AUTOML_COMPONENT_CREATE_CLUSTER",
    AUTOML_COMPONENT_EDIT_CLUSTER: "AUTOML_COMPONENT_EDIT_CLUSTER",
    AUTOML_COMPONENT_DELETE_CLUSTER: "AUTOML_COMPONENT_DELETE_CLUSTER",
    AUTOML_COMPONENT_REPAIR_CLUSTER: "AUTOML_COMPONENT_REPAIR_CLUSTER",
    AUTOML_COMPONENT_EXPORT_LOG_DOWNLOAD: "AUTOML_COMPONENT_EXPORT_LOG_DOWNLOAD",
    AUTOML_COMPONENT_EXPORT_LOG_DELETE: "AUTOML_COMPONENT_EXPORT_LOG_DELETE",
    AUTOML_COMPONENT_EXPORT_LOG_RETRY: "AUTOML_COMPONENT_EXPORT_LOG_RETRY",
    AUTOML_COMPONENT_MODEL_GOVERNANCE_CREATE_APPLICATION: "AUTOML_COMPONENT_MODEL_GOVERNANCE_CREATE_APPLICATION",
    AUTOML_CUSTOM_MODEL_PAGE: "AUTOML_CUSTOM_MODEL_PAGE",
    AUTOML_COMPONENT_BUILD_LIST_SAMPLE_API_REQUEST: "AUTOML_COMPONENT_BUILD_LIST_SAMPLE_API_REQUEST",
    AUTOML_COMPONENT_MODEL_GOVERNANCE_VALIDATION_REPORT: "AUTOML_COMPONENT_MODEL_GOVERNANCE_VALIDATION_REPORT",
    AUTOML_COMPONENT_LOG_EXPORT: "AUTOML_COMPONENT_LOG_EXPORT"
}

//Page access
export const PAGE_PRIVILEGES = {
    PAGE_MODULE: "PageModule",

    //visualize module
    PAGE_VISUALIZE: "PageVisualize",

    PAGE_VISUALIZE_QUERY: "PageVisualizeQuery",
    PAGE_VISUALIZE_QUERY_NEW: "PageVisualizeQueryNew",
    PAGE_VISUALIZE_QUERY_EDIT: "PageVisualizeQueryEdit",

    PAGE_VISUALIZE_PANEL: "PageVisualizePanel",
    PAGE_VISUALIZE_PANEL_NEW: "PageVisualizePanelNew",
    PAGE_VISUALIZE_PANEL_EDIT: "PageVisualizePanelEdit",
    PAGE_VISUALIZE_PANEL_VIEW: "PageVisualizePanelView",

    PAGE_VISUALIZE_DASHBOARD: "PageVisualizeDashboard",
    PAGE_VISUALIZE_DASHBOARD_NEW: "PageVisualizeDashboardNew",
    PAGE_VISUALIZE_DASHBOARD_EDIT: "PageVisualizeDashboardEdit",
    PAGE_VISUALIZE_DASHBOARD_VIEW: "PageVisualizeDashboardView",

    PAGE_VISUALIZE_SETTINGS_DATASOURCELIST: "PageVisualizeSettingsDatasourceList",
    PAGE_VISUALIZE_SETTINGS_DATASOURCELIST_NEW: "PageVisualizeSettingsDatasourceListNew",

    PAGE_VISUALIZE_SETTINGS_PATTERNSLIST: "PageVisualizeSettingsPatternsList",
    PAGE_VISUALIZE_SETTINGS_PATTERNSLIST_NEW: "PageVisualizeSettingsPatternsListNew",

    PAGE_VISUALIZE_SETTINGS_MAPSLIST: "PageVisualizeSettingsMapsList",
    PAGE_VISUALIZE_SETTINGS_MAPSLIST_NEW: "PageVisualizeSettingsMapsListNew",

    PAGE_VISUALIZE_SETTINGS_VIEWINDEXLIST: "PageVisualizeSettingsViewIndexList",

    PAGE_VISUALIZE_SETTINGS_CSVUPLOAD: "PageVisualizeSettingsCsvUpload",

    PAGE_VISUALIZE_SETTINGS_LOGLIST: "PageVisualizeSettingsLogList",

    //ingest module
    PAGE_INGEST: "PageIngest",
    PAGE_INGEST_FLOWS: "PageIngestFlows",
    PAGE_INGEST_FLOW_NEW: "PageIngestFlowNew",
    PAGE_INGEST_FLOW_EDIT: "PageIngestFlowEdit",

    //scenario module
    PAGE_SCENARIO: "PageScenario",
    PAGE_SCENARIO_EVENTLIST: "PageScenarioEventlist",
    PAGE_SCENARIO_EVENT_NEW: "PageScenarioEventNew",
    PAGE_SCENARIO_EVENT_EDIT: "PageScenarioEventEdit",
    PAGE_SCENARIO_SCENARIOLIST: "PageScenarioScenariolist",
    PAGE_SCENARIO_NEW: "PageScenarioNew",
    PAGE_SCENARIO_EDIT: "PageScenarioEdit",

    //auto_ml module
    PAGE_AUTOML: "PageAutoml",
    PAGE_MODEL_DEPLOYMENT: "PAGE_MODEL_DEPLOYMENT",
    PAGE_MODEL_GOVERNANCE: "PAGE_MODEL_GOVERNANCE",
    PAGE_INFRASTRUCTURE: "PAGE_INFRASTRUCTURE",
    PAGE_PROJECTS: "PAGE_PROJECTS",
    PAGE_EXPERIMENTS: "PAGE_EXPERIMENTS",
    PAGE_DATASETS: "PAGE_DATASETS",
    PAGE_CSV_UPLOAD: "PAGE_CSV_UPLOAD",
    PAGE_DATA_PREPARATION: "PAGE_DATA_PREPARATION",
    PAGE_EXPLORE_DATA: "PAGE_EXPLORE_DATA",
    PAGE_BASIC_TRANSFORMATION: "PAGE_BASIC_TRANSFORMATION",
    PAGE_FEATURE_GENERATION: "PAGE_FEATURE_GENERATION",
    PAGE_MISSING_VALUE: "PAGE_MISSING_VALUE",
    PAGE_ENCODING: "PAGE_ENCODING",
    PAGE_SCALING: "PAGE_SCALING",
    PAGE_FEATURE_REDUCTION: "PAGE_FEATURE_REDUCTION",
    PAGE_MODEL_SELECTION: "PAGE_MODEL_SELECTION",
    PAGE_MODEL_RUNNING: "PAGE_MODEL_RUNNING",
    PAGE_MODEL_RESULTS: "PAGE_MODEL_RESULTS",
    PAGE_TUNE_MODEL: "PAGE_TUNE_MODEL",
    PAGE_PREDICTIONS: "PAGE_PREDICTIONS",
    PAGE_JDBC_RESOURCES: "PAGE_JDBC_RESOURCES",
    PAGE_CREATE_DATASOURCE: "PAGE_CREATE_DATASOURCE",
    PAGE_CUSTOM_MODEL: "PAGE_CUSTOM_MODEL",
    APPLICATIONS: "APPLICATIONS",
    PAGE_GENERIC_MODELS: "PAGE_GENERIC_MODELS"
}

export const LICENSES = {
    ALL: "all",
    VISUALIZATION: "visualization",
    INGEST: "ingest",
    SCENARIO: "scenario",
    AUTO_ML: "auto_ml"
}

export const ROLES = {
    ANALYTICS_ADMIN: "AnalyticsAdmin",
    ANALYTICS_CREATOR: "AnalyticsCreator",
    ANALYTICS_USER: "AnalyticsUser",
    ANALYTICS_DATA_DISCOVERER: "AnalyticsDataDiscoverer",
    DEVOPS: "DevOps",
    MODEL_DEVELOPER: "ModelDeveloper",
    AUTOML_USER: "AutomlUser",
    APPLICATION_READER: "ApplicationReader"
};

const rolePrivilegeIndex = {
    "rolePrivilege": [
        {
            "role": "AnalyticsAdmin",
            "privileges": [
                PRIVILEGES.DASHBOARD_READ,
                PRIVILEGES.VISUALIZE_READ,
                PRIVILEGES.VISUALIZE_EDIT,
                PRIVILEGES.DASHBOARD_EDIT,
                PRIVILEGES.PATTERN_EDIT,
                PRIVILEGES.PATTERN_READ,
                PRIVILEGES.MAP_READ,
                PRIVILEGES.MAP_EDIT,
                PRIVILEGES.ROLE_INDEX_READ,
                PRIVILEGES.ROLE_INDEX_EDIT,
                PRIVILEGES.DATASOURCE_READ,
                PRIVILEGES.DATASOURCE_EDIT,
                PRIVILEGES.QUERY_VIEW,
                PRIVILEGES.QUERY_EDIT
            ],
            "pagePrivileges": Object.values(PAGE_PRIVILEGES)
        },
        {
            "role": "AnalyticsCreator",
            "privileges": [
                PRIVILEGES.DASHBOARD_READ,
                PRIVILEGES.DASHBOARD_EDIT,
                PRIVILEGES.VISUALIZE_READ,
                PRIVILEGES.VISUALIZE_EDIT,
                PRIVILEGES.SCENARIO_EDIT,
                PRIVILEGES.PATTERN_READ,
                PRIVILEGES.MAP_READ,
                PRIVILEGES.MAP_EDIT,
                PRIVILEGES.ROLE_INDEX_READ,
                PRIVILEGES.QUERY_VIEW,
            ],
            "pagePrivileges": [
                PAGE_PRIVILEGES.PAGE_MODULE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY_EDIT,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_PANEL,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_PANEL_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_PANEL_EDIT,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_PANEL_VIEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD_EDIT,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD_VIEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_PATTERNSLIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_PATTERNSLIST_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_MAPSLIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_MAPSLIST_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_CSVUPLOAD,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_LOGLIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_VIEWINDEXLIST,

                //TODO ingest ve scenario danisilacak
                PAGE_PRIVILEGES.PAGE_INGEST,
                PAGE_PRIVILEGES.PAGE_INGEST_FLOWS,
                PAGE_PRIVILEGES.PAGE_INGEST_FLOW_NEW,
                PAGE_PRIVILEGES.PAGE_INGEST_FLOW_EDIT,

                PAGE_PRIVILEGES.PAGE_SCENARIO,
                PAGE_PRIVILEGES.PAGE_SCENARIO_EVENTLIST,
                PAGE_PRIVILEGES.PAGE_SCENARIO_EVENT_NEW,
                PAGE_PRIVILEGES.PAGE_SCENARIO_EVENT_EDIT,
                PAGE_PRIVILEGES.PAGE_SCENARIO_SCENARIOLIST,
                PAGE_PRIVILEGES.PAGE_SCENARIO_NEW,
                PAGE_PRIVILEGES.PAGE_SCENARIO_EDIT

            ]
        },
        {
            "role": "AnalyticsUser",
            "privileges": [
                PRIVILEGES.DASHBOARD_READ,
            ],
            "pagePrivileges": [
                PAGE_PRIVILEGES.PAGE_MODULE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_DASHBOARD_VIEW,
            ]
        },
        {
            "role": "AnalyticsDataDiscoverer",
            "privileges": [
                PRIVILEGES.PATTERN_READ,
                PRIVILEGES.ROLE_INDEX_READ,
                PRIVILEGES.DATASOURCE_READ,
                PRIVILEGES.DATASOURCE_EDIT,
                PRIVILEGES.QUERY_VIEW,
                PRIVILEGES.QUERY_EDIT
            ],
            "pagePrivileges": [
                PAGE_PRIVILEGES.PAGE_MODULE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_PATTERNSLIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_PATTERNSLIST_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_VIEWINDEXLIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_DATASOURCELIST,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_DATASOURCELIST_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY_NEW,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_QUERY_EDIT,
                PAGE_PRIVILEGES.PAGE_VISUALIZE_SETTINGS_LOGLIST,
            ]
        },
        {
            role: ROLES.AUTOML_USER,
            privileges: [
            ],
            pagePrivileges: [
            ]
        },
        {
            role: ROLES.DEVOPS,
            privileges: [
                PRIVILEGES.AUTOML_COMPONENT_NAVIGATION_REVERT_ACTION,
                PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS,
                PRIVILEGES.AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX,
                PRIVILEGES.AUTOML_COMPONENT_DOWNLOAD_MODEL_OBJECT,
                PRIVILEGES.AUTOML_COMPONENT_ADD_PROJECT,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_PROJECT,
                PRIVILEGES.AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_CHALLENGER_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_RESET_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_LOGS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DISPLAY_MODEL_RESULTS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_START_REFRESH_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_ADD_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_VISUALIZE_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MAKE_PREDICTION_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_APPLICATION_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_REPORTS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DL_TEST_RESULT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_DIAGNOSTICS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_INTERPRET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_BUILD_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_ADD_NEW_CUSTOM_MODEL,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_CUSTOM_MODEL_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_RETRAIN_CUSTOM_MODEL_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DEPLOY_BUILD,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_MONITORING,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_BUILD,
                PRIVILEGES.AUTOML_COMPONENT_SAMPLE_REQ_API_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_CREATE_CLUSTER,
                PRIVILEGES.AUTOML_COMPONENT_EDIT_CLUSTER,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_CLUSTER,
                PRIVILEGES.AUTOML_COMPONENT_REPAIR_CLUSTER,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DOWNLOAD,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DELETE,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_RETRY,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_CREATE_APPLICATION,
                PRIVILEGES.AUTOML_CUSTOM_MODEL_PAGE,
                PRIVILEGES.AUTOML_COMPONENT_BUILD_LIST_SAMPLE_API_REQUEST,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_VALIDATION_REPORT,
                PRIVILEGES.AUTOML_COMPONENT_LOG_EXPORT
            ],
            pagePrivileges: [
                PAGE_PRIVILEGES.PAGE_AUTOML,
                PAGE_PRIVILEGES.PAGE_PROJECTS,
                PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
                PAGE_PRIVILEGES.PAGE_DATASETS,
                PAGE_PRIVILEGES.PAGE_CSV_UPLOAD,
                PAGE_PRIVILEGES.PAGE_INFRASTRUCTURE,
                PAGE_PRIVILEGES.PAGE_DATA_PREPARATION,
                PAGE_PRIVILEGES.PAGE_MODEL_DEPLOYMENT,
                PAGE_PRIVILEGES.PAGE_EXPLORE_DATA,
                PAGE_PRIVILEGES.PAGE_BASIC_TRANSFORMATION,
                PAGE_PRIVILEGES.PAGE_FEATURE_GENERATION,
                PAGE_PRIVILEGES.PAGE_MISSING_VALUE,
                PAGE_PRIVILEGES.PAGE_ENCODING,
                PAGE_PRIVILEGES.PAGE_SCALING,
                PAGE_PRIVILEGES.PAGE_FEATURE_REDUCTION,
                PAGE_PRIVILEGES.PAGE_MODEL_SELECTION,
                PAGE_PRIVILEGES.PAGE_MODEL_RUNNING,
                PAGE_PRIVILEGES.PAGE_MODEL_RESULTS,
                PAGE_PRIVILEGES.PAGE_TUNE_MODEL,
                PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
                PAGE_PRIVILEGES.PAGE_PREDICTIONS,
                PAGE_PRIVILEGES.PAGE_JDBC_RESOURCES,
                PAGE_PRIVILEGES.PAGE_CREATE_DATASOURCE,
                PAGE_PRIVILEGES.PAGE_CUSTOM_MODEL,
                PAGE_PRIVILEGES.APPLICATIONS,
                PAGE_PRIVILEGES.PAGE_GENERIC_MODELS
            ]
        },
        {
            role: ROLES.MODEL_DEVELOPER,
            privileges: [
                PRIVILEGES.AUTOML_COMPONENT_NAVIGATION_REVERT_ACTION,
                PRIVILEGES.AUTOML_COMPONENT_IMBALANCE_CLUSTER_CENTROIDS,
                PRIVILEGES.AUTOML_NEW_PREDICTION_VALIDATION_CHECKBOX,
                PRIVILEGES.AUTOML_COMPONENT_DOWNLOAD_MODEL_OBJECT,
                PRIVILEGES.AUTOML_COMPONENT_ADD_PROJECT,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_PROJECT,
                PRIVILEGES.AUTOML_COMPONENT_PORTFOLIO_AND_USECASE_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_CHALLENGER_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_RESET_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_LOGS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DISPLAY_MODEL_RESULTS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_START_REFRESH_EXPERIMENT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_ADD_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_VISUALIZE_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_DATASET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MAKE_PREDICTION_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_APPLICATION_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_REPORTS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_DL_TEST_RESULT_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_DIAGNOSTICS_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_INTERPRET_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_BUILD_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_AUDIT_LOG_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_ADD_NEW_CUSTOM_MODEL,
                PRIVILEGES.AUTOML_COMPONENT_DELETE_CUSTOM_MODEL_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_RETRAIN_CUSTOM_MODEL_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_SAMPLE_REQ_API_BUTTON,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DOWNLOAD,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_DELETE,
                PRIVILEGES.AUTOML_COMPONENT_EXPORT_LOG_RETRY,
                PRIVILEGES.AUTOML_COMPONENT_LOG_EXPORT,
                PRIVILEGES.AUTOML_COMPONENT_MODEL_GOVERNANCE_CREATE_APPLICATION
            ],
            pagePrivileges: [
                PAGE_PRIVILEGES.PAGE_AUTOML,
                PAGE_PRIVILEGES.PAGE_PROJECTS,
                PAGE_PRIVILEGES.PAGE_EXPERIMENTS,
                PAGE_PRIVILEGES.PAGE_DATASETS,
                PAGE_PRIVILEGES.PAGE_CSV_UPLOAD,
                PAGE_PRIVILEGES.PAGE_DATA_PREPARATION,
                PAGE_PRIVILEGES.PAGE_MODEL_DEPLOYMENT,
                PAGE_PRIVILEGES.PAGE_EXPLORE_DATA,
                PAGE_PRIVILEGES.PAGE_BASIC_TRANSFORMATION,
                PAGE_PRIVILEGES.PAGE_FEATURE_GENERATION,
                PAGE_PRIVILEGES.PAGE_MISSING_VALUE,
                PAGE_PRIVILEGES.PAGE_ENCODING,
                PAGE_PRIVILEGES.PAGE_SCALING,
                PAGE_PRIVILEGES.PAGE_FEATURE_REDUCTION,
                PAGE_PRIVILEGES.PAGE_MODEL_SELECTION,
                PAGE_PRIVILEGES.PAGE_MODEL_RUNNING,
                PAGE_PRIVILEGES.PAGE_MODEL_RESULTS,
                PAGE_PRIVILEGES.PAGE_TUNE_MODEL,
                PAGE_PRIVILEGES.PAGE_MODEL_GOVERNANCE,
                PAGE_PRIVILEGES.PAGE_PREDICTIONS,
                PAGE_PRIVILEGES.PAGE_JDBC_RESOURCES,
                PAGE_PRIVILEGES.PAGE_CREATE_DATASOURCE,
                PAGE_PRIVILEGES.PAGE_CUSTOM_MODEL,
                PAGE_PRIVILEGES.APPLICATIONS,
                PAGE_PRIVILEGES.PAGE_INFRASTRUCTURE,
                PAGE_PRIVILEGES.PAGE_GENERIC_MODELS
            ]
        },
        {
            role: ROLES.APPLICATION_READER,
            privileges: [],
            pagePrivileges: []
        },
    ]
};

export const getPrivilegesByRole = (roleArray) => {
    let privileges = [];
    let uniqueArray = [];
    roleArray.forEach(element => {
        rolePrivilegeIndex.rolePrivilege.forEach(rolePrivilege => {
            if (rolePrivilege.role === element) {
                privileges = privileges.concat(rolePrivilege.privileges);
            }
        })
    });

    if (privileges.length > 0)
        uniqueArray = [...new Set(privileges)];

    return uniqueArray;
}

//gets page privileges by role
export const getPagePrivilegesByRole = (roleArray) => {
    let pagePrivileges = [];
    let uniqueArray = [];
    roleArray.forEach(element => {
        rolePrivilegeIndex.rolePrivilege.forEach(rolePrivilege => {
            if (rolePrivilege.role === element) {
                pagePrivileges = pagePrivileges.concat(rolePrivilege.pagePrivileges);
            }
        })
    });

    if (pagePrivileges.length > 0)
        uniqueArray = [...new Set(pagePrivileges)];

    return uniqueArray;
}