<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.582"
    height="15.521"
    viewBox="0 0 15.582 15.521"
  >
    <defs></defs>
    <g transform="translate(1.176 -1.8)">
      <g transform="translate(378.07 970.047)">
        <path
          class="a"
          d="M-376.211-957.89a.261.261,0,0,0-.155.031l-1.009.558a6.559,6.559,0,0,1-.85-3.225,6.762,6.762,0,0,1,6.756-6.721,7.729,7.729,0,0,1,1.457.148.5.5,0,0,0,.592-.386.5.5,0,0,0-.386-.592,7.956,7.956,0,0,0-1.665-.17,7.764,7.764,0,0,0-7.754,7.718,7.563,7.563,0,0,0,.977,3.712l-.865.479a.261.261,0,0,0-.1.354.264.264,0,0,0,.115.107l2.44,1.162h0a.258.258,0,0,0,.343-.125.244.244,0,0,0,.023-.081l.306-2.683h0A.259.259,0,0,0-376.211-957.89Z"
        />
        <path
          class="a"
          d="M-363.8-965.06a.261.261,0,0,0,.1-.354.272.272,0,0,0-.115-.108l-2.439-1.162h0a.259.259,0,0,0-.345.123.257.257,0,0,0-.023.081l-.308,2.687v0a.257.257,0,0,0,.228.283.25.25,0,0,0,.156-.03l.943-.523a6.987,6.987,0,0,1,.894,3.41,6.873,6.873,0,0,1-6.8,6.925,5.481,5.481,0,0,1-1.412-.149.5.5,0,0,0-.594.383.5.5,0,0,0,.384.594,7.693,7.693,0,0,0,1.619.172h.008a7.874,7.874,0,0,0,7.8-7.93,7.978,7.978,0,0,0-1.021-3.888Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
};
</script>
<style scoped></style>
