<template>
<div>
    <!-- TOPBAR START -->
    <TopBar :TopbarColor="'AutoMl'" />
    <!-- TOPBAR END -->
    <div class="dtp-module dtp-bgcolor-01253B autoMlbg">
        <div class="dtp-row dtp-module-content">
            <div class="dtp-col dtp-col-12 dtp-text--center">
                <!-- <span
            class="dtp-fcolor-FFFFFF dtp-fsize-3125"
            v-html="$t('modules.choose_your_module')"
          ></span> -->
                <!-- <p
            class="dtp-text--center dtp-fcolor-FFFFFF dtp-fsize--10"
            v-html="$t('modules.module_exp')"
          ></p> -->
            </div>
        </div>
        <div class="dtp-container">
            <div class="dtp-row">
                <!-- COL-3 -->
                <div class="dtp-col dtp-col-3 dtp-col-m6" :class="{
              'dtp-col-12': onlyAutoMl,
              'dtp-text--center': onlyAutoMl,
            }" @click="setModule('auto_ml', auto_ml)">
                    <div class="dtp-module-card" :class="{ 'dtp-module-card-not': !auto_ml }">
                        <div class="dtp-module-card--content">
                            <p class="dtp-fsize--22 dtp-font-bold">
                                {{ $t("modules.auto_ml") }}
                            </p>
                        </div>
                        <div class="dtp-module-foot dtp-grd-7F4A32">
                            <div class="dtp-module-foot-icon">
                                <span class="mdi mdi-arrow-right dtp-grd-7F4A32 dtp-grd-clip"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- COL-3 -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TopBar from "../components/Layout/TopBar";
//import { getLicenseInfo } from "../api/license/license";
import {
    MessageBox
} from "element-ui";
import store from "../store";

export default {
    components: {
        TopBar,
    },
    name: "Login",
    data() {
        return {
            visualization: false,
            ingest: false,
            scenario: false,
            auto_ml: false,
        };
    },
    async created() {
        try {
            const licenseInfo = await store.getters["Security/Get/Licenses"];
            licenseInfo.forEach((element) => {
                switch (element) {
                    case "visualization":
                        this.visualization = true;
                        break;
                    case "ingest":
                        this.ingest = true;
                        break;
                    case "scenario":
                        this.scenario = true;
                        break;
                    case "auto_ml":
                        this.auto_ml = true;
                        break;
                }
            });
        } catch (err) {
            //console.error(err);
            if (err.status === 401) {
                MessageBox.alert(
                    this.$t(`license.${err.errors}`),
                    this.$t("license.error_title"), {
                        confirmButtonText: "OK",
                        type: "error",
                        callback: () => window.location.reload(),
                    }
                );
            }
        }
    },
    computed: {
        onlyAutoMl() {
            return true;
        },
    },
    methods: {
        setModule(moduleType, bool) {
            if (bool) {
                if (moduleType !== "auto_ml") {
                    const el = document.body;
                    el.classList.remove("elementMsgAlert");
                }
            }
        },
    },
};
</script>

<style>
.autoMlbg {
    background: url("../assets/images/AutomlBackground.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
</style>
