<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('helper.loading_data')"
    class="dtp-section"
  >
    <div class="dtp-pageviewtopbar">
      <div class="dtp-row dtp-align-center">
        <div class="dtp-col dtp-col-8 dtp-align-center">
          <el-tooltip :content="$t('commons.back')" placement="top" effect="light">
            <button
              @click="goBack"
              style="
                margin-right: 24px;
                border: none;
                background: transparent;
                cursor: pointer;
              "
            >
              <img src="../../../assets/images/icons/Automlback.svg" alt="" />
            </button>
          </el-tooltip>
          <div class="dtp-panel-formbox">
            <span class="dtp-aml-page-title"
              ><span
                v-if="experimentName"
                style="
                  font: normal normal medium 16px/50px;
                  letter-spacing: 0px;
                  color: #8c8c8c;
                  opacity: 1;
                "
                >{{ experimentName }} /</span
              >
              {{ $t("model.Models") }}</span
            >
          </div>

          <div class="dtp-panel-formbox">
            <ul class="dtp-aml-tabLink">
              <li
                v-for="tab in modelResultsTabs"
                :key="tab.component"
                @click="selectTab(tab.component)"
                :class="{
                  active: activeTab == tab.component,
                }"
              >
                <span v-if="problemType === algorithmType.Classification">{{
                  $t(`${tab.problemType.classification}`)
                }}</span>
                <span v-else-if="problemType === algorithmType.Regression">{{
                  $t(`${tab.problemType.regression}`)
                }}</span>
                <span v-else-if="problemType === algorithmType.Cluster">{{
                   $t(`${tab.problemType.cluster}`)
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isChallengerModelResultsReady"
      class="dtp-row dtp-pageviewtopbar"
    >
      <div class="dtp-col dtp-col-12" style="display: flex">
        <div class="dtp-panel-formbox">
          <el-tooltip
            :content="$t('Add Challenger Model')"
            placement="top"
            effect="light"
          >
            <button
              @click="addChallengerModelPopupVisible = true"
              class="aml-btn eds-button.basic"
              style="
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 12px;
                font-weight: normal;
                line-height: unset;
              "
            >
              <i class="el-icon-plus" style="font-size: 12px"></i>
              {{ $t('Add Challenger Model') }}
            </button>
          </el-tooltip>
          <AddChallengerModelPopup
            v-if="addChallengerModelPopupVisible"
            :dialogVisible="addChallengerModelPopupVisible"
            @closePopup="addChallengerModelPopupVisible = false"
            @createChallengerModel="callCreateChallengerModel"
          />
        </div>
        <div class="challenger-quick-filters">
          <div style="display: flex; width: 100%; max-width: 100%">
            <el-tooltip
              :content="$t('Refresh Table')"
              placement="top"
              effect="light"
              class="dtp-ml--auto"
            >
              <button
                :loading="loading"
                @click="init(true)"
                class="aml-btn eds-button.primary"
              >
                <img src="../../../assets/images/dtprefreshicon.svg" alt="" />
              </button>
            </el-tooltip>
          </div>
          <div class="dtp-aml-el-select" style="margin-right: 20px">
            <el-select
              v-model="selectedScoreNames"
              multiple
              style="margin-left: 20px; min-width: 250px"
              :placeholder="$t('placeholder.Filter Score Names')"
            >
              <el-option
                v-for="item in scoreNames"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dtp-aml-el-select" style="margin-right: 20px">
            <el-select
              v-model="selectedModelStatusList"
              multiple
              style="margin-left: 20px; min-width: 250px"
              :placeholder="$t('placeholder.Filter Model Status')"
            >
              <el-option
                v-for="item in modelStatuses"
                :key="item.id"
                :label="$t(`${item.name}`)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dtp-aml-el-select">
            <el-select
              v-model="selectedExperimentList"
              multiple
              style="margin-left: 20px; min-width: 250px"
              :placeholder="$t('placeholder.Filter Succeed Challenger Experiments')"
            >
              <el-option
                v-for="item in experimentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isChallengerModelResultsReady" class="dtp-row">
      <div class="dtp-col dtp-col-12" v-loading="loading">
        <div>
          <ChallengerModelResultsTable
            ref="modelResultsMainTable"
            :isCluster="isCluster"
            :scoreNames="getScoreNamesProps"
            :rows="clientFilteredRows"
            @selectionChanged="(val) => (tableSelections = val)"
            @init="init"
          />
        </div>

        <!--  Plot  list -->
        <PlotImage :selectedPlotList="currentPlots" :models="enhancedRows" />
      </div>
    </div>

    <ChallengerModelTryAgain
      v-if="!isChallengerModelResultsReady"
      @tryAgain="tryGetChallengerResult"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import notify from "../../../mixins/AutoML/notify";
import { classification, regression, cluster, tabs } from "./metricTypes";
import ChallengerModelResultsTable from "./ChallengerModelResultsTable.vue";
import PlotImage from "./PlotImage.vue";
import AddChallengerModelPopup from "./AddChallengerModelPopup.vue";
import ChallengerModelTryAgain from "./ChallengerModelTryAgain.vue";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { MODELS_ACTIONS, MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  mixins: [notify],
  components: {
    ChallengerModelResultsTable,
    PlotImage,
    AddChallengerModelPopup,
    ChallengerModelTryAgain,
  },
  data() {
    return {
      metricList: {
        classification,
        regression,
        cluster,
      },
      algorithmType: {
        Classification: "classification",
        Regression: "regression",
        Cluster: "cluster",
      },
      problemType: "",
      statusType: {
        Failed: "FAILED",
      },
      enhancedRows: [],
      scoreNames: [],
      selectedMetricList: [],
      isCluster: false,
      activeTab: null,
      tabs: tabs,
      currentPlots: [],
      tableSelections: [],
      addChallengerModelPopupVisible: false,
      selectedExperimentList: [],
      selectedModelStatusList: ["ALL"],
      selectedScoreNames: [],
      isChallengerModelResultsReady: true,
      modelStatuses: [
        {
          id: "ALL",
          name: "All",
        },
        {
          id: "SUCCESS",
          name: "Success",
        },
        {
          id: "IN_PROGRESS",
          name: "In Progress",
        },
        {
          id: "FAILURE",
          name: "Failure",
        },
      ],
    };
  },
  watch: {
    selectedExperimentList: {
      handler() {
        this.fetchPlot();
      },
      deep: true,
    },
    modelResultsTabs: {
      handler(val) {
        this.activeTab = val[0].component;
      },
      deep: true,
    },
    tableSelections: {
      handler(val) {
        if (val.length) this.fetchPlot();
        else this.currentPlots = [];
      },
      deep: true,
    },
  },
  async mounted() {
    this.requestComp = "ChallengerModelResults";
    await this.init();
    this.setDefaultSelectedScoreNames();
  },
  beforeDestroy() {
    this.setChallengerModelResults([]);
    this.setChallengerModelStatus([]);
    this.setChallengerExperimentInfo([]);
  },
  computed: {
    ...mapGetters({
      challengerModelResults: MODELS_GETTERS.GET_CHALLENGER_MODEL_RESULTS,
      challengerModelStatus: MODELS_GETTERS.GET_CHALLENGER_MODEL_STATUS,
      challengerExperimentInfo: MODELS_GETTERS.GET_CHALLENGER_EXPERIMENT_INFO,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
    }),
    getScoreNamesProps() {
      if (
        this.selectedScoreNames.includes("All") ||
        this.selectedScoreNames.length === 0
      )
        return this.scoreNames?.filter((s) => s !== "All");

      return this.selectedScoreNames;
    },
    experimentList() {
      return this.challengerExperimentInfo;
    },
    modelResultsTabs() {
      if (this.problemType === this.algorithmType.Classification) {
        return this.tabs.filter((x) => x.problemType.classification);
      } else if (this.problemType === this.algorithmType.Regression) {
        return this.tabs.filter((x) => x.problemType.regression);
      } else {
        return this.tabs.filter((x) => x.problemType.cluster);
      }
    },
    experimentId() {
      return this.$route.query.experimentId;
    },
    experimentName() {
      return this.$route.query.experimentName;
    },
    fromComponent() {
      return this.$route.query.fromComponent;
    },
    clientFilteredRows() {
      const whatToShowStatus = [];

      if (
        this.selectedModelStatusList.includes(this.modelStatuses[0].id) ||
        this.selectedModelStatusList.length === 0
      ) {
        whatToShowStatus.push(this.modelStatuses[1].id);
        whatToShowStatus.push(this.modelStatuses[2].id);
        whatToShowStatus.push(this.modelStatuses[3].id);
      }
      if (this.selectedModelStatusList.includes(this.modelStatuses[1].id)) {
        whatToShowStatus.push(this.modelStatuses[1].id);
      }
      if (this.selectedModelStatusList.includes(this.modelStatuses[2].id)) {
        whatToShowStatus.push(this.modelStatuses[2].id);
      }
      if (this.selectedModelStatusList.includes(this.modelStatuses[3].id)) {
        whatToShowStatus.push(this.modelStatuses[3].id);
      }

      return this.enhancedRows?.filter(
        (er) =>
          (er.model_status === this.modelStatuses[1].id &&
            whatToShowStatus.includes(er.model_status) &&
            this.selectedExperimentList.includes(er.experiment_id)) ||
          (er.model_status !== this.modelStatuses[1].id &&
            whatToShowStatus.includes(er.model_status))
      );
    },
  },
  methods: {
    ...mapActions({
      createChallengerModel: MODELS_ACTIONS.CREATE_CHALLENGER_MODEL,
      fetchChallengerModelResults: MODELS_ACTIONS.FETCH_CHALLENGER_MODEL_RESULTS,
      fetchChallengerStatus: MODELS_ACTIONS.FETCH_CHALLENGER_STATUS,
      fetchModelPlotsMain: MODELS_ACTIONS.FETCH_MODELS_PLOT_MAIN,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
    }),
    ...mapMutations({
      setChallengerModelResults: MODELS_MUTATIONS.SET_CHALLENGER_MODEL_RESULTS,
      setChallengerModelStatus: MODELS_MUTATIONS.SET_CHALLENGER_MODEL_STATUS,
      setChallengerExperimentInfo: MODELS_MUTATIONS.SET_CHALLENGER_EXPERIMENT_INFO,
    }),
    async tryGetChallengerResult() {
      await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });

      if (this.selectedExperiment?.active_challengers?.length) {
        if (
          !this.selectedExperiment.active_challengers.find(
            (x) => x.status !== "TRAINED"
          )
        ) {
          await this.getResults();
        }
      }
    },
    async callCreateChallengerModel(params) {
      const result = await this.createChallengerModel({
        experiment_id: this.experimentId,
        requestComp: this.requestComp,
        params,
      });

      if (result) {
        await this.fetchExperimentByExperimentId({ experimentId: result });
      }

      this.init();
    },
    fetchPlot() {
      this.currentPlots = [];

      this.tableSelections.forEach(async (s) => {
        if (this.selectedExperimentList.includes(s.experiment_id)) {
          const res = await this.fetchModelPlotsMain({
            requestComp: this.requestComp,
            experimentId: s.experiment_id,
            params: {
              model_ids: [s.model_id],
              plot_types: [this.activeTab],
            },
          });

          const firstResult = res?.[0];

          if (firstResult.plot_status === "SUCCESS")
            this.currentPlots.push({
              ...firstResult,
              experiment_name: s.experiment_name,
            });
        }
      });
    },
    selectTab(value) {
      this.activeTab = value;
      this.fetchPlot();
    },
    checker(arr, target) {
      return target?.every((v) => arr.includes(v));
    },
    getFields(trainData) {
      if (this.checker(trainData, this.metricList.classification)) {
        return {
          algorithm: this.algorithmType.Classification,
          metricList: this.metricList.classification,
        };
      } else if (this.checker(trainData, this.metricList.regression)) {
        return {
          algorithm: this.algorithmType.Regression,
          metricList: this.metricList.regression,
        };
      } else if (this.checker(trainData, this.metricList.cluster)) {
        return {
          algorithm: this.algorithmType.Cluster,
          metricList: this.metricList.cluster,
        };
      }
    },
    goBack() {
      this.$router.push({
        name: this.$route.meta.from,
      });
    },
    setDefaultSelectedScoreNames() {
      if (this.problemType === this.algorithmType.Classification) {
        this.selectedScoreNames = ["Accuracy", "AUC", "F1"];
      } else if (this.problemType === this.algorithmType.Regression) {
        this.selectedScoreNames = ["MAE", "RMSE", "MAPE"];
      } else {
        this.selectedScoreNames = ["All"];
      }
    },
    async init(refresh = false) {
      await this.getResults();

      if (!refresh) {
        this.selectedExperimentList = [];

        this.experimentList.forEach((el) => {
          this.selectedExperimentList.push(el.id);
        });
      }

      await this.tryGetChallengerResult();

      if (this.challengerModelResults) {
        //challenger_root_id var mı kontrol et yoksa id ile ilerle
        const expId = this.selectedExperiment?.[0]?.challenger_root_id
          ? this.selectedExperiment?.[0]?.challenger_root_id
          : this.selectedExperiment?.[0]?.id;

        const isChallengedOrHasRootName =
          this.selectedExperiment?.[0]?.is_challenged ||
          this.selectedExperiment?.[0]?.challenger_root_name?.length;

        if (expId && isChallengedOrHasRootName) {
          await this.fetchChallengerStatus({
            requestComp: this.requestComp,
            experimentId: expId,
          });
        }

        const successResults = JSON.parse(
          JSON.stringify(this.challengerModelResults)
        ).filter((item) => item.status !== this.statusType.Failed);

        this.enhancedRows = successResults.map((item) => {
          const { model, model_id, train, test, experiment_id } = item;

          const experimentInfo = this.experimentList.find(
            (s) => s.id === experiment_id
          );

          const trainKeys = Object.keys(train).filter(
            (x) => x !== "highlights" && x !== "training_time"
          );
          const { algorithm, metricList } = this.getFields(trainKeys);

          this.problemType = algorithm;

          this.isCluster = this.algorithmType.Cluster === algorithm;
          let scores = {};
          this.scoreNames = ["All"];

          metricList.forEach((sm) => {
            try {
              Object.assign(scores, {
                [sm]: {
                  Train: train?.[sm]?.toFixed(4),
                  Test: test?.[sm]?.toFixed(4),
                },
              });
              this.scoreNames.push(sm);
            } catch (e) {
              return;
            }
          });

          const statusOfCurrentItem = this.challengerModelStatus?.find(
            (a) => a.experiment_id === experiment_id
          );

          return {
            model,
            model_id,
            experiment_id: experimentInfo.id,
            experiment_name: experimentInfo.name,
            trainingtime: `${train?.training_time}`,
            scores,
            Train_Highlight: train?.highlights,
            Test_Highlight: test?.highlights,
            model_status:
              expId === item.experiment_id
                ? this.modelStatuses[1].id
                : statusOfCurrentItem?.status,
          };
        });

        this.challengerModelStatus?.forEach((a) => {
          if (a.status !== this.modelStatuses[1].id) {
            this.enhancedRows.push({
              model: a?.status,
              model_id: a?.model_id,
              experiment_id: a?.experiment_id,
              experiment_name: a?.experiment_name,
              trainingtime: "-",
              Train_Highlight: [],
              Test_Highlight: [],
              model_status: a?.status,
              failure_cause: a?.failure_cause,
            });
          }
        });

        this.fetchPlot();
      }

    },
    async getResults() {
      await this.fetchChallengerModelResults({
        requestComp: this.requestComp,
        experimentId: this.experimentId,
      });
    },
  },
};
</script>

<style scoped>
.dtp-aml-tabLink {
  position: relative;
  width: auto;
  height: auto;
  padding: 3px;
  margin: 0;
  background-color: #eaeaea;
  border-radius: 3px;
}
.dtp-aml-tabLink li {
  position: relative;
  padding: 7px 14px;
  margin: 0;
  display: inline-block;
  color: #333333;
  background-color: transparent;
  border-radius: 3px;
}
.dtp-aml-tabLink li span {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.dtp-aml-tabLink li.active {
  background-color: #416ca8;
  color: #ffffff;
}
.challenger-quick-filters {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
}
</style>

