export const optionsTableHeaders = [
    { label: null, field: "check", sortable: false },
    { label: "Column Name", field: "field", width:'210px' },
    { label: "Data Type", field: "type" },
    { label: "Target Data Type", field: "fill_missing_value", width: '240px' },
    { label: "Actions", field: "actions" },
];

export const COLUMN_TYPES = {
    CATEGORICAL: "Categorical",
    NUMERIC: "Numeric",
    DATE: "Date"
}

export const typeConversionOptions = [
    { label: "Categoric", value: "Categorical" },
    { label: "Numeric", value: "Numeric" },
    { label: "Date", value: "Date" },
    { label: "None", value: "None" },
]

export const conversionMapping = {
    [COLUMN_TYPES.CATEGORICAL]: [
        { label: "Numeric", value: "Numeric" },
        { label: "Date", value: "Date" },
    ],
    [COLUMN_TYPES.NUMERIC]: [
        { label: "Categoric", value: "Categorical" },
        { label: "Date", value: "Date" },
    ],
    [COLUMN_TYPES.DATE]: [
        { label: "Categoric", value: "Categorical" },
        { label: "Numeric", value: "Numeric" },
    ],
}
