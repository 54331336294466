<template>
  <el-collapse
    v-model="activeCollapseItem"
    accordion
    style="margin-bottom: 30px; font-weight: bold; border: 0"
  >
    <el-collapse-item
      :title="$t('Summary Plot')"
      name="summary"
      style="margin-bottom: 20px"
    >
      <div class="dtp-row">
        <div
          v-for="(s, index) in summaryPlot"
          :key="index"
          class="dtp-col dtp-col-12"
        >
          <img :src="s.plot_path" class="dtp-vgt-chart widthFull" />
        </div>
      </div>
    </el-collapse-item>
    <el-collapse-item
      :title="$t('Correlation Graph')"
      name="correlation"
      style="margin-bottom: 20px"
    >
      <div class="dtp-display-flex dtp-mb--2 dtp-mt--2">
        <div
          class="eds-field_#control eds-field_.eds-input eds-input"
          style="width: 200px; margin-right: 20px"
        >
          <select
            v-model="correlationSelectedFeature"
            class="eds-input_#input eds-input_#input.dropdown"
          >
            <option selected disabled value="">{{ $t("Choose Feature") }}</option>
            <option
              v-for="col in correlationColumns"
              :key="`correlation-${col}`"
              :value="col"
            >
              {{ col }}
            </option>
          </select>
        </div>
        <button
          @click="applyCorrelation"
          class="aml-btn eds-button.basic applyButtons"
        >
          {{ $t("commons.apply") }}
        </button>
        <div class="dtp-ml--1">
          <i
            v-show="loading"
            class="el-icon-loading"
            aria-hidden="true"
            id="correlationLoading"
          ></i>
        </div>
      </div>

      <div class="dtp-row">
        <div
          v-for="(s, index) in correlationPlot"
          :key="index"
          class="dtp-col dtp-col-12"
        >
          <img :src="s.plot_path" class="dtp-vgt-chart widthFull" />
        </div>
      </div>
    </el-collapse-item>
    <el-collapse-item
      :title="$t('Reason Plot')"
      name="reason"
      style="margin-bottom: 20px"
    >
      <div class="dtp-display-flex dtp-mb--2 dtp-mt--2">
        <div
          class="eds-field_#control eds-field_.eds-input eds-input"
          style="width: 200px; margin-right: 20px"
        >
          <input
            class="eds-input_#input"
            type="number"
            :min="0"
            :placeholder="$t('placeholder.Observation Number')"
            v-model.number="reasonObservationNumber"
          />
        </div>
        <button
          @click="applyReason"
          class="aml-btn eds-button.basic applyButtons"
        >
          {{ $t('commons.apply') }}
        </button>
        <div class="dtp-ml--1">
          <i
            v-show="loading"
            class="el-icon-loading"
            aria-hidden="true"
            id="correlationLoading"
          ></i>
        </div>
      </div>
      <div class="dtp-row">
        <div
          v-for="(s, index) in reasonPlot"
          :key="index"
          class="dtp-col dtp-col-12"
          style="overflow: auto; position: relative; height: 300px"
        >
          <iframe
            scrolling="yes"
            class="plotImageIframe"
            :src="s.plot_path"
            title="description"
          ></iframe>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { plotTypes } from "./data/types";

export default {
  props: {
    correlationColumns: {
      type: Array,
      required: true,
    },
    summaryPlot: {
      type: Array,
      required: true,
    },
    correlationPlot: {
      type: Array,
      required: true,
    },
    reasonPlot: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      reasonObservationNumber: "",
      correlationSelectedFeature: "",
      activeCollapseItem: null,
      loading: false,
      plotTypes: plotTypes,
    };
  },
  watch: {
    activeCollapseItem() {
      this.resetCorrelationAndReason();

      if (this.activeCollapseItem === "summary") {
        this.$emit("summaryOpened");
      }
    },
  },
  methods: {
    applyCorrelation() {
      if (!this.correlationSelectedFeature) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Please select correlation feature"),
          type: "warning",
        });
        return;
      }
      this.$emit("correlationOpened", [this.correlationSelectedFeature]);
    },
    applyReason() {
      if (!this.reasonObservationNumber && this.reasonObservationNumber !== 0) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.Please enter number for observation number field"),
          type: "warning",
        });
        return;
      }
      this.$emit("reasonOpened", [this.reasonObservationNumber]);
    },
    resetCorrelationAndReason() {
      this.reasonObservationNumber = "";
      this.correlationSelectedFeature = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  background-color: transparent;
  border-bottom: 2px solid #333333;
  font-size: 14px;
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent;
  border-bottom: 2px solid #333333;
}
.widthFull {
  width: 100%;
}
.applyButtons {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: unset;
}

.plotImageIframe {
  overflow: visible;
  width: 1920px;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
</style>