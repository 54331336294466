import axios from 'axios';
import store from '../store';

class Service {
    constructor() {
        let service = axios.create();
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        service.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${store.getters["Security/Get/Keycloak/Token"]}`;
            return config;
        });
        this.service = service;
        this.API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
      //  console.log(error?.response?.status)
        //return error.response ? error.response.status == 404 ? Promise.resolve(error.response) : Promise.reject(error) : Promise.reject(error)
        return Promise.reject(error?.response)
    }

    handleApiError = (error, errorCallBack) => {
      //  console.log(error)
        const result = {
            status: error?.status,
            message: error?.data?.message,
            errors: error?.data?.errors
        }
        errorCallBack(result)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    get(path, callback, errorCallBack) {
      //  console.log(this.API_ENDPOINT)
        let result = {};
        return this.service.get(this.API_ENDPOINT + path).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
               // console.log(result)
                callback(result)
            }

        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    patch(path, payload, callback) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    post(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.post(this.API_ENDPOINT + path, data).then(
            (response) => {
               // console.log("response değeri:", response);
                result = {
                    status: response.status,
                    data: response.data
                }
               // console.log(result)
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    getWithData(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.get(this.API_ENDPOINT + path, data).then(
            (response) => {
                result = {
                    status: response.status,
                    data: response.data
                }
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    put(path, data, callback, errorCallBack) {
        let result = {};
        return this.service.put(this.API_ENDPOINT + path, data).then(
            (response) => {
                result = {
                    status: response.status,
                    message: response.data.message
                }
               // console.log(result)
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }

    delete(path, callback, errorCallBack) {
        let result = {};
        return this.service.delete(this.API_ENDPOINT + path).then(
            (response) => {
               // console.log("response değeri:", response);
                result = {
                    status: response.status,
                    message: response.data.message
                }
               // console.log(result)
                callback(result)
            }
        ).catch(
            (error) => {
                this.handleApiError(error, errorCallBack)
            }
        )
    }
}

export default new Service;