import { sortFnNumber } from "../../../../helper/vueGoodTableSortFunctions";

export const COLUMNS = [
	{
		label: null,
		field: "collapse",
		tdClass: "text-center vertical-center",
		sortable: false
	},
	{
		label: "Column Name",
		field: "name",
		tdClass: "vertical-center"
	},
	{
		label: "Data Type",
		field: "type",
		tdClass: "vertical-center"
	},
	{
		label: "Missing Value Count",
		field: "missing_values",
		tdClass: "vertical-center",
		sortFn: sortFnNumber
	},
	{
		label: "Statistics",
		field: "statistics",
		tdClass: "vertical-center",
		sortable: false
	},
	{
		label: "Unique Value Count",
		field: "unique_values",
		tdClass: "vertical-center",
		sortFn: sortFnNumber
	},
];