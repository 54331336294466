export const sqlHintExpansion = [
  "left",
  "right",
  "full",
  "inner",
  "self",
  "exists",
  "any",
  "all",
  "average",
  "sum",
  "min",
  "max",
  "top",
  "null",
  "when",
  "then",
  "case",
  "end",
  "else",
];