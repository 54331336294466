
import { query } from './query';
import { security } from './security';
import { settings } from './settings';
import { generalStore } from './AutoML/General';
import { navigation } from './AutoML/Navigation';
import { projectsStore } from "./AutoML/Projects";
import { experimentsStore } from "./AutoML/Experiments";
import { datasetStore } from "./AutoML/Dataset";
import { modelsStore } from "./AutoML/Models";
import { dataStore } from "./AutoML/Data";
import { tagStore } from "./AutoML/Tags";
import { buildStore } from "./AutoML/Build";
import { clusterStore } from "./AutoML/Clusters";
import { applicationStore } from "./AutoML/Application";
import { dataSourceStore } from "./AutoML/DataSource";

export default {
  query,
  security,
  settings,
  general: generalStore,
  navigation,
  projects: projectsStore,
  experiments: experimentsStore,
  datasets: datasetStore,
  models: modelsStore,
  data: dataStore,
  tags: tagStore,
  builds: buildStore,
  clusters: clusterStore,
  applications: applicationStore,
  dataSource: dataSourceStore,
}