<template>
  <div class="dtp-row dtp-mt--1">
    <div
      class="dtp-col dtp-col-12 dtp-mb--3"
      style="float: right; text-align: right"
    >
      <button
        @click="clearAll()"
        :disabled="!isConfigurationValuesChanged"
        class="eds-button eds-button.basic dtp-mr--2"
      >
        {{ $t("dataCalculation." + "Clear All")}}
      </button>
    </div>
    <div
      v-for="item in getSecondaryColumnConfig(modelType)"
      :key="item.key"
      class="dtp-col dtp-col-4"
    >
      <div>
        <div class="dtp-row dtp-align-center">
          <div
            class="dtp-col dtp-col-3 dtp-pa--0 dtp-text--right"
            style="padding-top: 10px !important; margin-bottom: auto"
          >
            <span class="dtp-font-bold">{{ item.label }}</span>
          </div>
          <div class="dtp-col dtp-col-9">
            <div class="dtp-panel-formbox" style="width: 100%">
              <div v-if="item.type === InputTypes.SelectBox">
                <multi-select
                  :options="item.values"
                  :resetKey="resetKey"
                  @inputsChanged="
                    (params) => handleSelectBoxChanges(item.key, params)
                  "
                />
              </div>
              <min-max-h-content
                v-if="item.type === InputTypes.NumberField"
                :resetKey="resetKey"
                @inputsChanged="
                  (params) => handleInputChanges(item.key, params)
                "
                @resetFinished="resetFinished"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SECONDARY_COLUMNS_GPU_CLASSIFICATION_CONFIG,
  SECONDARY_COLUMNS_GPU_REGRESSION_CONFIG,
} from "./data/gpuConfig";
import {
  SECONDARY_COLUMNS_CPU_CLASSIFICATION_CONFIG,
  SECONDARY_COLUMNS_CPU_REGRESSION_CONFIG,
} from "./data/cpuConfig";
import pipeline from "../../../mixins/AutoML/pipeline";
import MinMaxHContent from "./MinMaxHContent.vue";
import { InputTypes as InputTypeDefs } from "./data/types";
import MultiSelect from "./MultiSelect.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
import { MODELS_GETTERS, MODELS_MUTATIONS } from '../../../store/modules/AutoML/Models/types';

export default {
  components: {
    MinMaxHContent,
    MultiSelect,
  },
  mixins: [pipeline],
  props: {
    modelType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterList: [],
      problemTypes: {
        Classification: "classification",
        Regression: "regression",
        Clustering: "clustering",
      },
      configurationValues: {},
      resetKey: 0,
      isConfigurationValuesChanged: false,
    };
  },
  watch: {
    configurationValues: {
      handler() {
        this.isConfigurationValuesChanged =
          Object.keys(this.configurationValues).length === 0 ? false : true;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchExperimentByExperimentId({ experimentId: this.experimentId });
  },
  computed: {
    ...mapGetters({
      getTuneModel: MODELS_GETTERS.GET_MODEL_TUNING,
      selectedExperiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
    }),
    experimentId: {
      get() {
        return this.$route.query.experimentId;
      },
    },
    isSelectedExperimentCPU() {
      return (
        this.selectedExperiment.length &&
        this.selectedExperiment[0].device === "CPU"
      );
    },
    secondaryColumnsConfig() {
      if (this.isSelectedExperimentCPU) {
        if (this.getProblemType === this.problemTypes.Classification)
          return SECONDARY_COLUMNS_CPU_CLASSIFICATION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Regression)
          return SECONDARY_COLUMNS_CPU_REGRESSION_CONFIG;

        return [];
      } else {
        if (this.getProblemType === this.problemTypes.Classification)
          return SECONDARY_COLUMNS_GPU_CLASSIFICATION_CONFIG;
        else if (this.getProblemType === this.problemTypes.Regression)
          return SECONDARY_COLUMNS_GPU_REGRESSION_CONFIG;

        return [];
      }
    },
    InputTypes() {
      return InputTypeDefs;
    },
  },
  methods: {
    ...mapActions({
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
    }),
    ...mapMutations({
      setModelTuning: MODELS_MUTATIONS.SET_MODEL_TUNING,
    }),
    getSecondaryColumnConfig(modelType) {
      const algorithmType = Object.keys(this.secondaryColumnsConfig).find((f) =>
        modelType.includes(f)
      );

      return this.secondaryColumnsConfig[algorithmType];
    },
    /**
     * numeric_value_generator: Object
increment: (...)
lower_bound: (...)
upper_bound: (...)
     */
    updateTuneModelStore() {
      let tuneModel = JSON.parse(JSON.stringify(this.getTuneModel));
      tuneModel?.algorithms?.forEach((tm) => {
        if (tm.name === this.modelType) {
          tm.optimization = Object.values(this.configurationValues).filter(
            (cv) => {
              if (Object.prototype.hasOwnProperty.call(cv, "values")) {
                if (!cv.values?.length) {
                  return false;
                }
              } else if (
                Object.prototype.hasOwnProperty.call(
                  cv,
                  "numeric_value_generator"
                )
              ) {
                const { increment, upper_bound, lower_bound } =
                  cv.numeric_value_generator;

                if (
                  increment === "" &&
                  upper_bound === "" &&
                  lower_bound === ""
                ) {
                  return false;
                }
              }

              return true;
            }
          );
        }
      });

      this.setModelTuning(tuneModel);
    },
    handleInputChanges(key, params) {
      this.$set(this.configurationValues, key, {
        parameter: key,
        numeric_value_generator: params,
      });
      this.updateTuneModelStore();
    },
    handleSelectBoxChanges(key, params) {
      this.$set(this.configurationValues, key, {
        parameter: key,
        values: params.map((p) => {
          if (p === "true") {
            return true;
          } else if (p === "false") {
            return false;
          }
          return p;
        }),
      });
      this.updateTuneModelStore();
    },
    clearAll() {
      this.resetKey++;
    },
    resetFinished() {
      this.configurationValues = {};
    },
  },
};
</script>