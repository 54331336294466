export const domain = "AUTOML/DATASOURCE";

export const DATASOURCE_ACTIONS = {
  FETCH_DATASOURCE: `${domain}/fetchDataSource`,
  DELETE_DATASOURCE: `${domain}/deleteDataSource`,
  CREATE_DATASOURCE: `${domain}/createDataSource`,
  UPLOAD_RDB_PREVIEW: `${domain}/uploadRdbPreview`,
  UPLOAD_RDB: `${domain}/uploadRdb`,
};

export const DATASOURCE_MUTATIONS = {
  SET_DATASOURCE: `${domain}/setDataSource`,
};

export const DATASOURCE_GETTERS = {
  GET_DATASOURCE: `${domain}/getDataSource`,
};
