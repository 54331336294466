<template>
  <el-popover
    :visible-arrow="false"
    placement="bottom-start"
    width="314"
    @show="init"
    trigger="click"
  >
    <div class="dtp-ma--1">
      <div v-for="(item, index) in items" :key="index" class="dtp-mb--3">
        <span class="dtp-modelgovernance-title">{{ $t(`${item.title}`) }}</span>
        <p class="dtp-modelgovernance-content">{{ item.content }}</p>
      </div>
    </div>
    <div slot="reference">
      <el-tooltip v-if=prediction :content="$t('Error Log')" placement="top" effect="light">
      <i
        class="mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0"
        style="font-size: 12px"
      ></i>
      </el-tooltip>
      <i v-else
        class="mdi mdi-information-outline dtp-color-light-blue dtp-cursor-pointer dtp-mr--0"
        style="font-size: 12px"
      ></i>
    </div>
  </el-popover>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { PROJECT_ACTIONS, PROJECT_GETTERS } from '../../../store/modules/AutoML/Projects/types';
import { EXPERIMENT_ACTIONS, EXPERIMENT_GETTERS } from '../../../store/modules/AutoML/Experiments/types';
export default {
  props: {
    project_id: {
      type: Number,
    },
    experiment_id: {
      type: Number,
    },
    failure_cause: {
      type: String,
    },
    prediction: {
      type: Boolean,
    },
  },
  data() {
    return { items: [] };
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      project: PROJECT_GETTERS.GET_SELECTED_PROJECT,
      experiment: EXPERIMENT_GETTERS.GET_SELECTED_EXPERIMENT,
    }),
  },
  methods: {
    ...mapActions({
      getProjectByProjectId: PROJECT_ACTIONS.GET_PROJECT_BY_PROJECT_ID,
      fetchExperimentByExperimentId: EXPERIMENT_ACTIONS.FETCH_EXPERIMENT_BY_EXPERIMENT_ID,
    }),
    async init() {
      if (this.project_id) {
        await this.getProjectByProjectId(this.project_id);

        this.items = [
          { title: "Creation Date", content: this.project?.created_at },
          { title: "Description", content: this.project?.description },
        ];
      } else if (this.experiment_id) {
        await this.fetchExperimentByExperimentId({ experimentId: this.experiment_id });
        this.items = [
          {
            title: "Creation user",
            content: this.experiment[0]?.username,
          },
          {
            title: "Creation date",
            content: this.experiment[0]?.created_at,
          },
          {
            title: "Data Set Name",
            content: this.experiment[0]?.dataset_name,
          },
          {
            title: "Performance Metric",
            content: this.experiment[0]?.scorer,
          },
          {
            title: "Best Train Score",
            content: this.experiment[0]?.train_score,
          },
          {
            title: "Best Test Score",
            content: this.experiment[0]?.test_score,
          },
        ];
      }else if (this.failure_cause) {
        this.items = [
          {
            title: "Failure Cause",
            content: this.failure_cause,
          },
        ];
      }
    },
  },
};
</script>
<style scoped>
.dtp-modelgovernance-title {
  font-size: 14px;
  font-weight: bold;
  color: #4968a2;
  margin-bottom: 4px;
}
.dtp-modelgovernance-content {
  font-size: 12px;
  color: #515f67;
  line-height: 25px;
}
.mdi-information-outline:hover {
  color: #8c9fc3;
}

::v-deep .el-popover__reference-wrapper {
  float: right !important;
}
</style>
