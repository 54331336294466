<template>
  <el-dialog :visible.sync="dialogVisible" @closed="$emit('modalClosed')">
    <div slot="title">
      <span style="font-weight: bold">{{ "Ordinal Encoding Values" }}</span>
    </div>

    <div class="dtp-row dtp-mb--1 dtp-align-center">
      <div class="dtp-col dtp-col-6 dtp-pa--0 dtp-mb--1 dtp-font-bold">{{ $t("Key") }}</div>
      <div class="dtp-col dtp-col-6 dtp-pa--0 dtp-mb--1 dtp-font-bold">
        {{ $t("Values") }}
      </div>
    </div>

    <div
      v-for="(uq, index) in ordinalEncodingPopupRow.unique_keys"
      :key="index"
      class="dtp-row dtp-align-center"
    >
      <div class="dtp-col dtp-col-6 dtp-pa--0 dtp-font-bold">
        {{ uq }}
      </div>
      <div class="dtp-col dtp-col-6 dtp-pa--0 dtp-font-bold">
        <div class="eds-field_#control eds-field_.eds-input eds-input">
          <input
            type="number"
            :placeholder="$t('placeholder.value for x', [uq])"
            class="eds-input_#input"
            :value="ordinalEncodingPopupRow.ordinal_values[uq]"
            @change="
              (e) =>
                (ordinalEncodingPopupRow.ordinal_values[uq] = Number(
                  e.target.value
                ))
            "
          />
        </div>
      </div>
    </div>

    <div class="dtp-row dtp-mt--1">
      <div class="dtp-col dtp-col-12 dtp-pa--0 dtp-text--right">
        <button
          class="eds-button eds-button.basic"
          @click="
            $emit('save', ordinalEncodingPopupRow);
            dialogVisible = false;
          "
        >
          {{ $t("commons.save") }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import cloneDeep from "clone-deep";

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
    row: {
      handler() {
        this.ordinalEncodingPopupRow = cloneDeep(this.row);
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ordinalEncodingPopupRow: {},
    };
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.el-button {
  padding: 9px 10px;
  margin-left: 10px;
}
::v-deep .el-dialog__header {
  display: flex;
  background-color: #f5f7f7;
  border: 1px solid #d6d6d6;
}
::v-deep .el-dialog__headerbtn {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
}
::v-deep .el-dialog {
  width: 400px;
}
::v-deep .el-dialog__body {
  padding: 20px 20px 10px 20px;
}

.curlTextContainer {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 12px;
  color: #333333;
  font-size: 13px;
}
.curlTextCopyBtn {
  display: flex;
  height: 15px !important;
}
.urlCopyBtn {
  display: flex;
  height: 15px !important;
}
.popupItemSubtitle {
  font: normal normal normal 14px/36px Roboto;
  letter-spacing: 0px;
  color: #8c8c8c;
  width: 110px;
  display: inline-block;
}
</style>